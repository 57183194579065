import {
  Autocomplete,
  FormGroup,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { getSpkList } from "../../../services/SpkApi";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { MdCalendarMonth } from "react-icons/md";
import SingleCalendar from "../../common/SingleCalendar";
import { setPlat } from "../../../services/platUsageApi";
import * as yup from "yup";

const validationSchema = yup.object({
  job: yup.string().required(),
  spk_number: yup.string().required(),
  po_number: yup.string().required(),
  est_color: yup.string().required(),
  act_color: yup.string().required(),
  length: yup.number().min(1).required(),
  width: yup.number().min(1).required(),
  total: yup.number().min(1).required(),
  date: yup.string().required(),
  opening_stock: yup.number().min(1).required(),
  ending_stock: yup.number().min(1).required(),
  name: yup.string().required(),
  type: yup.string().required(),
});

const AddPlatUsage = () => {
  const navigate = useNavigate();

  const [selectedSpk, setSelectedSpk] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [platDate, setPlatDate] = useState(null);
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);

  const getJobId = () => {
    return formik.values.job === ""
      ? 0
      : formik.values.job === "N"
      ? 1
      : formik.values.job === "R"
      ? 2
      : 3;
  };

  const formik = useFormik({
    initialValues: {
      job: "",
      spk_number: null,
      po_number: "",
      est_color: "",
      act_color: "",
      length: 0,
      width: 0,
      total: 0,
      date: "",
      opening_stock: 0,
      ending_stock: 0,
      name: "",
      type: "",
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      mutatePlat(values);
    },
  });

  const { mutate: mutatePlat, isPending } = useMutation({
    mutationFn: setPlat,
    onSuccess: () => navigate(`/plat-usage`),
    onError: (err) => setErrorMessage(""),
  });

  const { mutate: mutateSpkList, data: dataSpkList } = useMutation({
    mutationFn: getSpkList,
  });

  useEffect(() => {
    formik.setFieldValue(
      "date",
      platDate ? format(platDate, "yyyy-MM-dd") : ""
    );
  }, [platDate]);

  useEffect(() => {
    if (errorMessage !== "") setErrorMessage("");
  }, [formik.values]);

  useEffect(() => {
    mutateSpkList({
      offset: 1,
      limit: 10000,
      schedule: 0,
      search: "",
      po_start: "",
      po_end: "",
      delivery_start: "",
      delivery_end: "",
      job: [],
      category: [],
      dies: [],
    });
  }, []);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Job Type</FormLabel>
          <Select
            sx={{ width: "100%" }}
            value={getJobId()}
            onChange={(e) =>
              formik.setFieldValue(
                "job",
                e.target.value === 1 ? "N" : e.target.value === 2 ? "R" : "RWC"
              )
            }
          >
            <MenuItem value={0} disabled>
              Pilih Job Type
            </MenuItem>
            <MenuItem value={1}>N</MenuItem>
            <MenuItem value={2}>R</MenuItem>
            <MenuItem value={3}>RWC</MenuItem>
          </Select>
        </FormGroup>
        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>No. SPK</FormLabel>
          <Autocomplete
            sx={{ width: "100%" }}
            disablePortal
            value={selectedSpk}
            onChange={(_, option) => {
              formik.setFieldValue("spk_number", option ? option.spk_no : null);
              formik.setFieldValue("po_number", option ? option.po_number : "");
              setSelectedSpk(option);
            }}
            options={dataSpkList && dataSpkList.data ? dataSpkList.data : []}
            getOptionLabel={(option) => option.spk_no}
            renderInput={(params) => (
              <TextField {...params} placeholder="Pilih SPK" />
            )}
          />
        </FormGroup>
        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>No PO</FormLabel>
          <TextField fullWidth disabled value={formik.values.po_number} />
        </FormGroup>
        <div className="flex gap-4 mb-4">
          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>Jumlah Warna (Est)</FormLabel>
            <TextField
              fullWidth
              value={formik.values.est_color}
              onChange={(e) =>
                formik.setFieldValue("est_color", e.target.value)
              }
            />
          </FormGroup>
          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>Jumlah Warna (Actual)</FormLabel>
            <TextField
              fullWidth
              value={formik.values.act_color}
              onChange={(e) =>
                formik.setFieldValue("act_color", e.target.value)
              }
            />
          </FormGroup>
        </div>
        <div className="flex gap-4 mb-4">
          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>Aktual Penggunaan (Panjang)</FormLabel>
            <TextField
              fullWidth
              value={formik.values.length}
              onChange={(e) =>
                formik.setFieldValue(
                  "length",
                  isNaN(parseFloat(e.target.value))
                    ? 0
                    : e.target.value.slice(-1) === "."
                    ? e.target.value
                    : parseFloat(e.target.value)
                )
              }
            />
          </FormGroup>
          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>Aktual Penggunaan (Lebar)</FormLabel>
            <TextField
              fullWidth
              value={formik.values.width}
              onChange={(e) =>
                formik.setFieldValue(
                  "width",
                  isNaN(parseFloat(e.target.value))
                    ? 0
                    : e.target.value.slice(-1) === "."
                    ? e.target.value
                    : parseFloat(e.target.value)
                )
              }
            />
          </FormGroup>
        </div>
        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Jumlah (cm2)</FormLabel>
          <TextField
            fullWidth
            value={formik.values.total}
            onChange={(e) =>
              formik.setFieldValue(
                "total",
                isNaN(parseFloat(e.target.value))
                  ? 0
                  : e.target.value.slice(-1) === "."
                  ? e.target.value
                  : parseFloat(e.target.value)
              )
            }
          />
        </FormGroup>
        <FormGroup sx={{ width: "100%" }}>
          <FormLabel>Quotation Date</FormLabel>
          <div className="mb-4 w-full border-2 rounded-md w-full flex items-center hover:bg-slate-100">
            <div
              className="flex justify-between items-center h-full cursor-pointer w-full cursor-pointer p-3.5"
              onClick={(e) => setAnchorElCalendar(e.currentTarget)}
            >
              {platDate === null ? (
                <p>----/--/--</p>
              ) : (
                <p>{format(new Date(platDate), "yyyy-MM-dd")}</p>
              )}
              <MdCalendarMonth className="w-6 h-6" />
            </div>
          </div>
        </FormGroup>
        <div className="flex gap-4 mb-4">
          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>Stok Awal</FormLabel>
            <TextField
              fullWidth
              value={formik.values.opening_stock}
              onChange={(e) =>
                formik.setFieldValue(
                  "opening_stock",
                  isNaN(parseFloat(e.target.value))
                    ? 0
                    : e.target.value.slice(-1) === "."
                    ? e.target.value
                    : parseFloat(e.target.value)
                )
              }
            />
          </FormGroup>
          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>Stok Akhir</FormLabel>
            <TextField
              fullWidth
              value={formik.values.ending_stock}
              onChange={(e) =>
                formik.setFieldValue(
                  "ending_stock",
                  isNaN(parseFloat(e.target.value))
                    ? 0
                    : e.target.value.slice(-1) === "."
                    ? e.target.value
                    : parseFloat(e.target.value)
                )
              }
            />
          </FormGroup>
        </div>
        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Nama Pemotong Plate</FormLabel>
          <TextField
            fullWidth
            value={formik.values.name}
            onChange={(e) => formik.setFieldValue("name", e.target.value)}
          />
        </FormGroup>
        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Type</FormLabel>
          <TextField
            fullWidth
            value={formik.values.type}
            onChange={(e) => formik.setFieldValue("type", e.target.value)}
          />
        </FormGroup>
        <div className="w-full">
          <p className="text-red-500 mb-4 text-right m-4">{errorMessage}</p>
          <div className="flex justify-end w-1/3 gap-2 float-right">
            <button
              type="button"
              className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
              onClick={() => navigate("/plat-usage")}
            >
              Cancel
            </button>
            <button
              className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
              disabled={!formik.isValid || isPending}
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </form>

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendar}
        setAnchorElCalendarStart={setAnchorElCalendar}
        startDate={platDate}
        setStartDate={setPlatDate}
      />
    </>
  );
};

export default AddPlatUsage;
