import { Autocomplete, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import ModalInput from "../../common/ModalInput";
import { deleteInvoiceCategory } from "../../../services/supplierInvoiceApi";

const DeleteInvoiceCategoryModal = ({
  dataCategory,
  setOpenDeleteCat,
  openDeleteCat,
  setRefetchList,
}) => {
  const [selectedCat, setSelectedCat] = useState(null);
  const [isDelete, setIsDelete] = useState(false);

  const { mutate: mutateDelete, isPending: isPendingDelete } = useMutation({
    mutationFn: deleteInvoiceCategory,
    enable: isDelete,
    onSuccess: () => {
      setOpenDeleteCat(false);
      setIsDelete(false);
      setRefetchList(true);
    },
  });

  useEffect(() => {
    setSelectedCat(null);
  }, [openDeleteCat]);

  useEffect(() => {
    if (isDelete) mutateDelete(selectedCat.id);
  }, [isDelete]);

  return (
    <>
      <ModalInput
        title="Hapus Kategori"
        subtitle="Pilih kategori yang ingin di hapus"
        content={
          <>
            <Autocomplete
              sx={{ width: "100%" }}
              disablePortal
              value={selectedCat}
              onChange={(_, option) => {
                setSelectedCat(option);
              }}
              options={
                dataCategory && dataCategory.data ? [...dataCategory.data] : []
              }
              getOptionLabel={(option) => option.category_name}
              renderInput={(params) => (
                <TextField {...params} label="Kategori" />
              )}
            />
          </>
        }
        open={openDeleteCat}
        setOpen={setOpenDeleteCat}
        hasButton={true}
        buttonText="Hapus"
        setTriggerFunc={setIsDelete}
        isDisable={!dataCategory || !selectedCat || isPendingDelete}
      />
    </>
  );
};

export default DeleteInvoiceCategoryModal;
