const SpkCardEstimateSystem = ({ curProject, isDetail }) => {
  return (
    <>
      <hr className="my-2" />
      <div className={`${isDetail ? "flex gap-2 items-start w-full" : ""}`}>
        <div className="rounded-md shadow-md w-full">
          <div className="block overflow-x-auto w-full rounded-md">
            <table className="w-full">
              <thead className="border-t border-b border-2">
                <tr className="bg-[#18479D] text-white">
                  <td colSpan={4} className="p-3 font-bold">
                    Estimated
                  </td>
                </tr>
                <tr className="bg-[#F4F5FF]">
                  <th className="p-3 text-left">No</th>
                  <th className="p-3 text-left">Run (m)</th>
                  <th className="p-3 text-left">Setup Run & Waste Run (m)</th>
                  <th className="p-3 text-left">Total (m)</th>
                </tr>
              </thead>
              <tbody>
                {curProject.system_estimate.map((mat, i) => (
                  <tr className="border-t border-l border-2" key={i}>
                    <td className="p-3">{i + 1}</td>
                    <td className="p-3">
                      {Number(parseFloat(mat.run_system).toFixed(4))}
                    </td>
                    <td className="p-3">
                      {Number(parseFloat(mat.run_waste_system).toFixed(4))}
                    </td>
                    <td className="p-3">
                      {Number(parseFloat(mat.total_system).toFixed(4))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <table className="w-full">
              <thead className="border-t border-b border-2">
                <tr className="bg-[#18479D] text-white">
                  <td colSpan={4} className="p-3 font-bold">
                    Actual
                  </td>
                </tr>
                <tr className="bg-[#F4F5FF]">
                  <th className="p-3 text-left">No</th>
                  <th className="p-3 text-left">Run (m)</th>
                  <th className="p-3 text-left">Setup Run & Waste Run (m)</th>
                  <th className="p-3 text-left">Total (m)</th>
                </tr>
              </thead>
              <tbody>
                {curProject.system_estimate.map((mat, i) => (
                  <tr className="border-t border-l border-2" key={i}>
                    <td className="p-3">{i + 1}</td>

                    <td className="p-3">
                      {Number(
                        parseFloat(
                          mat.operator_run_system ? mat.operator_run_system : 0
                        ).toFixed(4)
                      )}
                    </td>
                    <td className="p-3">
                      {Number(
                        parseFloat(
                          mat.operator_setup_system
                            ? mat.operator_setup_system
                            : 0
                        ).toFixed(4)
                      )}
                    </td>
                    <td className="p-3">
                      {Number(
                        parseFloat(
                          mat.operator_total_system
                            ? mat.operator_total_system
                            : 0
                        ).toFixed(4)
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpkCardEstimateSystem;
