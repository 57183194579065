import {
  Autocomplete,
  Chip,
  CircularProgress,
  createFilterOptions,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { MdCalendarMonth } from "react-icons/md";
import {
  getSoDetail,
  getSoListDropdown,
} from "../../../services/salesOrderApi";
import { format, subDays } from "date-fns";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import SpkProjectCard from "./SpkProjectCard";
import {
  getMCPrinting,
  setMCPrinting,
  setNewSpk,
} from "../../../services/SpkApi";
import ModalInput from "../../common/ModalInput";
import SingleCalendar from "../../common/SingleCalendar";

const validationSchema = yup.object({
  spk_no: yup.string().required(),
  so_id: yup.number().min(1).required(),
  job_type: yup.number().min(1).required(),
  delivery_date: yup.string().required(),
  mc_printing: yup.string().required(),
  category: yup.number().min(1).required(),
  die: yup.number().min(1).required(),
  note: yup.string(),
  schedule_date: yup.string().required(),
  project: yup
    .array()
    .of(
      yup.object().shape({
        detail: yup.object().shape({
          costing_id: yup.number(),
          internal_code: yup.string().required(),
          label_name: yup.string().required(),
          label_size: yup.string().required(),
          qty_order: yup.number(),
          qty_stock: yup.number(),
          qty_production: yup.number().min(1),
          finishing: yup.string().required(),
          gap_across: yup.number().min(1),
          gap_along: yup.number(),
          max_up: yup.number(),
          adjustment_up: yup.number().min(1),
          total_gap_across: yup.number(),
          total_gap_range: yup.number(),
          gear_size: yup.number().min(1),
          gear_amount: yup.number(),
          clean_counter: yup.number(),
          along_run: yup.number(),
          total_color: yup.number().min(1),
          speed: yup.number(),
          up_along: yup.number().min(1),
          eye_total: yup.number(),
          j_left: yup.number().min(1),
          j_right: yup.number().min(1),
          printing_area: yup.number(),
          size_material: yup.number(),
          adjustment_mm: yup.number(),
          tolerance_percent: yup.number(),
          tolerance_m: yup.number(),
          max_counter: yup.number(),
          max_run: yup.number(),
          difficult_waste: yup.number(),
          distortion: yup.number(),
        }),
        material: yup.array(),
        system_estimate: yup.array(),
        hr_estimate: yup.array(),
        label: yup
          .array()
          .of(
            yup.object().shape({
              lembar: yup.number(),
              ikat: yup.number(),
              pack: yup.number(),
              roll: yup.number(),
              core_dia: yup.number(),
            })
          )
          .min(1),
        qc: yup.array(),
        ink_coverage: yup.object().shape({
          cyan: yup.number(),
          magenta: yup.number(),
          yellow: yup.number(),
          black: yup.number(),
          orange: yup.number(),
          violet: yup.number(),
          green: yup.number(),
          ref_blue: yup.number(),
          p_032: yup.number(),
          varnish: yup.number(),
        }),
      })
    )
    .min(1),
});

const AddSpk = () => {
  const curDate = Date.now();
  const navigate = useNavigate();
  const defaultFilterOptions = createFilterOptions();

  const [selectedSo, setSelectedSo] = useState(null);
  const [expandArr, setExpandArr] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [spkNum, setSpkNum] = useState(
    "SPK/" + new Date().getFullYear() + "/" + curDate.toString(36).toUpperCase()
  );
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [projectArr, setProjectArr] = useState([]);
  const [newMCPrinting, setNewMCPrinting] = useState("");
  const [openAddMCPrinting, setOpenAddMCPrinting] = useState(false);
  const [isAdd, setIsAdd] = useState(false);

  const { data: dataMCPrinting } = useQuery({
    queryKey: ["mc-printing", isAdd],
    queryFn: () => getMCPrinting(),
  });

  const { mutate: mutateNewMCPrinting, isPending: isPendingCreateCat } =
    useMutation({
      mutationFn: setMCPrinting,
      onSuccess: () => {
        setOpenAddMCPrinting(false);
        setIsAdd(false);
        setNewMCPrinting("");
      },
      onError: (err) => {
        setErrorMessage(err.message.id);
        setIsAdd(false);
      },
    });

  useEffect(() => {
    if (isAdd) {
      mutateNewMCPrinting(newMCPrinting);
    }
    if (!openAddMCPrinting) {
      setNewMCPrinting("");
    }
  }, [isAdd, openAddMCPrinting]);

  const { data: dataSo, isFetching: isFetchingSo } = useQuery({
    queryKey: ["so-detail", selectedSo],
    enabled: selectedSo !== null,
    refetchOnWindowFocus: false,
    queryFn: () => getSoDetail(selectedSo.id),
  });

  const formik = useFormik({
    initialValues: {
      spk_no: spkNum,
      so_id: selectedSo ? selectedSo.id : 0,
      job_type: 0,
      delivery_date:
        selectedSo && dataSo && !isFetchingSo
          ? format(subDays(new Date(dataSo.delivery_date), 5), "yyyy-MM-dd")
          : "",
      mc_printing: "",
      category: 0,
      die: 0,
      note: "",
      schedule_date: format(new Date(), "yyyy-MM-dd"),
      project: [],
    },
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: (values) => {
      setOpen(true);
    },
  });

  const { mutate: mutateSoList, data: dataSoList } = useMutation({
    mutationFn: getSoListDropdown,
  });

  const { mutate: mutateNewSpk, isPending: isPendingAdd } = useMutation({
    mutationFn: setNewSpk,
    onSuccess: () => navigate("/spk"),
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  useEffect(() => {
    if (dataSo) {
      formik.setFieldValue(
        "job_type",
        selectedSo && !isFetchingSo ? dataSo.status_order : 0
      );
    }
  }, [dataSo, isFetchingSo, selectedSo]);

  useEffect(() => {
    setExpandArr(
      projectArr.map(() => {
        return {
          project: true,
          detail: true,
          material: true,
          estimate_system: true,
          estimate_hr: true,
          label: true,
          qc: true,
          ink_coverage: true,
        };
      })
    );

    let temp = [];

    projectArr.map((item, i) => {
      const tempIndex = formik.values.project.findIndex(
        (proj) => proj.detail.internal_code === item.code
      );

      temp.push({
        detail: {
          costing_id: item.costing_id,
          internal_code: item.code,
          label_name: item.label_name,
          label_size: item.label_size,
          qty_order: item.qty,
          qty_stock: item.qty_stock ? item.qty_stock : 0,
          qty_production:
            tempIndex < 0
              ? 0
              : formik.values.project[tempIndex].detail.qty_production,
          finishing:
            tempIndex < 0
              ? ""
              : formik.values.project[tempIndex].detail.finishing,
          gap_across:
            tempIndex < 0
              ? null
              : formik.values.project[tempIndex].detail.gap_across,
          gap_along:
            tempIndex < 0
              ? 0
              : formik.values.project[tempIndex].detail.gap_along,
          max_up:
            tempIndex < 0 ? 0 : formik.values.project[tempIndex].detail.max_up,
          adjustment_up:
            tempIndex < 0
              ? null
              : formik.values.project[tempIndex].detail.adjustment_up,
          total_gap_across:
            tempIndex < 0
              ? 0
              : formik.values.project[tempIndex].detail.total_gap_across,
          total_gap_range:
            tempIndex < 0
              ? 0
              : formik.values.project[tempIndex].detail.total_gap_range,
          gear_size:
            tempIndex < 0
              ? null
              : formik.values.project[tempIndex].detail.gear_size,
          gear_amount:
            tempIndex < 0
              ? 0
              : formik.values.project[tempIndex].detail.gear_amount,
          clean_counter:
            tempIndex < 0
              ? 0
              : formik.values.project[tempIndex].detail.clean_counter,
          along_run:
            tempIndex < 0
              ? 0
              : formik.values.project[tempIndex].detail.along_run,
          total_color:
            tempIndex < 0
              ? 0
              : formik.values.project[tempIndex].detail.total_color,
          speed:
            tempIndex < 0 ? 0 : formik.values.project[tempIndex].detail.speed,
          up_along:
            tempIndex < 0
              ? null
              : formik.values.project[tempIndex].detail.up_along,
          eye_total:
            tempIndex < 0
              ? 0
              : formik.values.project[tempIndex].detail.eye_total,
          j_left:
            tempIndex < 0
              ? null
              : formik.values.project[tempIndex].detail.j_left,
          j_right:
            tempIndex < 0
              ? null
              : formik.values.project[tempIndex].detail.j_right,
          printing_area:
            tempIndex < 0
              ? 0
              : formik.values.project[tempIndex].detail.printing_area,
          size_material:
            tempIndex < 0
              ? 0
              : typeof formik.values.project[tempIndex].detail.size_material ===
                "string"
              ? parseFloat(
                  formik.values.project[tempIndex].detail.size_material.replace(
                    ",",
                    "."
                  )
                )
              : formik.values.project[tempIndex].detail.size_material,
          adjustment_mm:
            tempIndex < 0
              ? 0
              : typeof formik.values.project[tempIndex].detail.adjustment_mm ===
                "string"
              ? parseFloat(
                  formik.values.project[tempIndex].detail.adjustment_mm.replace(
                    ",",
                    "."
                  )
                ).toFixed(3)
              : formik.values.project[tempIndex].detail.adjustment_mm.toFixed(
                  3
                ),
          tolerance_percent:
            tempIndex < 0
              ? 0
              : formik.values.project[tempIndex].detail.tolerance_percent,
          tolerance_m:
            tempIndex < 0
              ? 0
              : formik.values.project[tempIndex].detail.tolerance_m,
          crb:
            tempIndex < 0
              ? null
              : typeof formik.values.project[tempIndex].detail.crb === "string"
              ? parseFloat(
                  formik.values.project[tempIndex].detail.crb.replace(",", ".")
                ).toFixed(3)
              : formik.values.project[tempIndex].detail.crb,
          max_counter:
            tempIndex < 0
              ? 0
              : formik.values.project[tempIndex].detail.max_counter,
          max_run:
            tempIndex < 0 ? 0 : formik.values.project[tempIndex].detail.max_run,
          difficult_waste:
            tempIndex < 0
              ? 0
              : formik.values.project[tempIndex].detail.difficult_waste,
          distortion:
            tempIndex < 0
              ? 0
              : formik.values.project[tempIndex].detail.distortion,
        },
        material: item.material.map((mat) => {
          return {
            label_name: item.label_name,
            material_name: mat,
            size: 0,
            consumption: 0,
          };
        }),
        system_estimate: [
          {
            run_system:
              tempIndex < 0
                ? 0
                : formik.values.project[tempIndex].hr_estimate[0].run_system,
            run_waste_system:
              tempIndex < 0
                ? 0
                : formik.values.project[tempIndex].hr_estimate[0]
                    .run_waste_system,
            total_system:
              tempIndex < 0
                ? 0
                : formik.values.project[tempIndex].hr_estimate[0].total_system,
          },
        ],
        hr_estimate: [
          {
            run_hr:
              tempIndex < 0
                ? 0
                : formik.values.project[tempIndex].hr_estimate[0].run_hr,
            run_waste_hr:
              tempIndex < 0
                ? 0
                : formik.values.project[tempIndex].hr_estimate[0].run_waste_hr,
            total_hr:
              tempIndex < 0
                ? 0
                : formik.values.project[tempIndex].hr_estimate[0].total_hr,
          },
        ],
        label: [
          {
            lembar:
              tempIndex < 0
                ? 0
                : formik.values.project[tempIndex].label[0].lembar,
            ikat:
              tempIndex < 0
                ? 0
                : formik.values.project[tempIndex].label[0].ikat,
            pack:
              tempIndex < 0
                ? 0
                : formik.values.project[tempIndex].label[0].pack,
            roll:
              tempIndex < 0
                ? 0
                : formik.values.project[tempIndex].label[0].roll,
            core_dia:
              tempIndex < 0
                ? 0
                : formik.values.project[tempIndex].label[0].core_dia,
          },
        ],
        qc: [
          {
            sliting:
              tempIndex < 0
                ? 0
                : formik.values.project[tempIndex].qc[0].sliting,
            sheeting:
              tempIndex < 0
                ? 0
                : formik.values.project[tempIndex].qc[0].sheeting,
            cutting:
              tempIndex < 0
                ? 0
                : formik.values.project[tempIndex].qc[0].cutting,
            packing_roll:
              tempIndex < 0
                ? 0
                : formik.values.project[tempIndex].qc[0].packing_roll,
            packing_sheet:
              tempIndex < 0
                ? 0
                : formik.values.project[tempIndex].qc[0].packing_sheet,
          },
        ],
        ink_coverage: {
          cyan:
            tempIndex < 0
              ? null
              : typeof formik?.values?.project[tempIndex]?.ink_coverage[0]
                  ?.cyan === "string"
              ? parseFloat(
                  formik?.values?.project[
                    tempIndex
                  ]?.ink_coverage[0]?.cyan?.replace(",", ".")
                )
              : formik?.values?.project[tempIndex]?.ink_coverage[0]?.cyan,
          magenta:
            tempIndex < 0
              ? null
              : typeof formik?.values?.project[tempIndex]?.ink_coverage[0]
                  ?.magenta === "string"
              ? parseFloat(
                  formik?.values?.project[
                    tempIndex
                  ]?.ink_coverage[0]?.magenta?.replace(",", ".")
                )
              : formik?.values?.project[tempIndex]?.ink_coverage[0]?.magenta,
          yellow:
            tempIndex < 0
              ? null
              : typeof formik?.values?.project[tempIndex]?.ink_coverage[0]
                  ?.yellow === "string"
              ? parseFloat(
                  formik?.values?.project[
                    tempIndex
                  ]?.ink_coverage[0]?.yellow?.replace(",", ".")
                )
              : formik?.values?.project[tempIndex]?.ink_coverage[0]?.yellow,
          black:
            tempIndex < 0
              ? null
              : typeof formik?.values?.project[tempIndex]?.ink_coverage[0]
                  ?.black === "string"
              ? parseFloat(
                  formik?.values?.project[
                    tempIndex
                  ]?.ink_coverage[0]?.black?.replace(",", ".")
                )
              : formik?.values?.project[tempIndex]?.ink_coverage[0]?.black,
          orange:
            tempIndex < 0
              ? null
              : typeof formik?.values?.project[tempIndex]?.ink_coverage[0]
                  ?.orange === "string"
              ? parseFloat(
                  formik?.values?.project[
                    tempIndex
                  ]?.ink_coverage[0]?.orange?.replace(",", ".")
                )
              : formik?.values?.project[tempIndex]?.ink_coverage[0]?.orange,
          violet:
            tempIndex < 0
              ? null
              : typeof formik?.values?.project[tempIndex]?.ink_coverage[0]
                  ?.violet === "string"
              ? parseFloat(
                  formik?.values?.project[
                    tempIndex
                  ]?.ink_coverage[0]?.violet?.replace(",", ".")
                )
              : formik?.values?.project[tempIndex]?.ink_coverage[0]?.violet,
          green:
            tempIndex < 0
              ? null
              : typeof formik?.values.project[tempIndex]?.ink_coverage[0]
                  ?.green === "string"
              ? parseFloat(
                  formik?.values?.project[
                    tempIndex
                  ]?.ink_coverage[0]?.green?.replace(",", ".")
                )
              : formik?.values?.project[tempIndex]?.ink_coverage[0]?.green,
          ref_blue:
            tempIndex < 0
              ? null
              : typeof formik?.values?.project[tempIndex]?.ink_coverage[0]
                  ?.ref_blue === "string"
              ? parseFloat(
                  formik?.values?.project[
                    tempIndex
                  ]?.ink_coverage[0]?.ref_blue?.replace(",", ".")
                )
              : formik?.values?.project[tempIndex]?.ink_coverage[0]?.ref_blue,
          p_032:
            tempIndex < 0
              ? null
              : typeof formik?.values?.project[tempIndex]?.ink_coverage[0]
                  ?.p_032 === "string"
              ? parseFloat(
                  formik?.values?.project[
                    tempIndex
                  ]?.ink_coverage[0]?.p_032?.replace(",", ".")
                )
              : formik?.values?.project[tempIndex]?.ink_coverage[0]?.p_032,
          varnish:
            tempIndex < 0
              ? null
              : typeof formik?.values?.project[tempIndex]?.ink_coverage[0]
                  ?.varnish === "string"
              ? parseFloat(
                  formik?.values?.project[
                    tempIndex
                  ]?.ink_coverage[0]?.varnish?.replace(",", ".")
                )
              : formik?.values?.project[tempIndex]?.ink_coverage[0]?.varnish,
        },
      });
    });

    formik.setFieldValue("project", temp);
  }, [projectArr]);

  useEffect(() => {
    if (isSubmit) {
      setIsSubmit(false);
      setIsSave(false);
      mutateNewSpk(formik.values);
    }
  }, [isSubmit, formik.values.project]);

  useEffect(() => {
    formik.setFieldValue(
      "schedule_date",
      scheduleDate ? format(scheduleDate, "yyyy-MM-dd") : ""
    );
  }, [scheduleDate]);

  useEffect(() => {
    mutateSoList({
      search: "",
      status: 0,
      so_start: "",
      so_end: "",
      customer: 0,
      shipping: 0,
      po_start: "",
      po_end: "",
      delivery_start: "",
      delivery_end: "",
      due_start: "",
      due_end: "",
      offset: 1,
      limit: 10000,
      is_dropdown: true,
    });
  }, []);

  useEffect(() => {
    if (formik.values.mc_printing === "Tambah M/C Printing baru disini...") {
      formik.setFieldValue("mc_printing", 0);
      setOpenAddMCPrinting(true);
    }
  }, [formik.values.mc_printing]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <p className="font-bold text-xl mt-8 mb-4">General Information</p>
        <div className="my-2 flex w-full gap-2 items-end">
          <FormControl fullWidth>
            <FormLabel>No. SPK</FormLabel>
            <TextField
              fullWidth
              disabled
              value={formik.values.spk_no}
              sx={{ background: "#f1f5f9" }}
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel>Job Type</FormLabel>
            <Select value={formik.values.job_type} disabled>
              <MenuItem value={0} disabled>
                Pilh Job Type
              </MenuItem>
              <MenuItem value={1}>New</MenuItem>
              <MenuItem value={2}>Repeat</MenuItem>
              <MenuItem value={3}>Sample Request</MenuItem>
              <MenuItem value={4}>RWC</MenuItem>
              <MenuItem value={5}>New + CRB</MenuItem>
              <MenuItem value={6}>RWC + CRB</MenuItem>
              <MenuItem value={7}>Reprint</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="my-2 flex w-full gap-2 items-end">
          <FormControl fullWidth>
            <FormLabel>No. SO</FormLabel>
            <Autocomplete
              sx={{ width: "100%" }}
              disablePortal
              value={selectedSo}
              onChange={(_, option) => {
                setSelectedSo(option);
                setProjectArr([]);
              }}
              options={dataSoList && dataSoList.data ? dataSoList.data : []}
              getOptionLabel={(option) =>
                option.so_number + " - " + option.customer_name
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="Pilih No. SO" />
              )}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === "reset") {
                  setSelectedSo(null);
                  return;
                }
              }}
            />
          </FormControl>
          <div className="w-full">
            <p>Tanggal Delivery</p>
            <div className="mt-1 border-2 rounded-md w-full flex items-center bg-slate-100">
              <div className="flex justify-between items-center h-full cursor-pointer w-full p-3.5 text-gray-500">
                {selectedSo === null || !dataSo || isFetchingSo ? (
                  <p>----/--/--</p>
                ) : (
                  <p>
                    {format(
                      subDays(new Date(dataSo.delivery_date), 5),
                      "yyyy-MM-dd"
                    )}
                  </p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
          </div>
        </div>
        <FormControl sx={{ width: "100%", marginY: 1 }}>
          <FormLabel>Project</FormLabel>
          <Autocomplete
            sx={{ width: "100%" }}
            disablePortal
            disabled={!selectedSo}
            multiple
            value={projectArr}
            onChange={(_, option) => {
              setProjectArr(option);
            }}
            options={
              dataSo && dataSo.items
                ? dataSo.items.filter((item) => !item.is_spk)
                : []
            }
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option.code}
                  {...getTagProps({ index })}
                />
              ))
            }
            getOptionLabel={(option) => option.code}
            renderInput={(params) => (
              <TextField {...params} placeholder="Pilih Project" />
            )}
            onInputChange={(event, newInputValue, reason) => {
              if (reason === "reset") {
                setProjectArr([]);
                return;
              }
            }}
          />
        </FormControl>

        <div className="my-2 flex w-full gap-2 items-end">
          <FormControl fullWidth>
            <FormLabel>No. PO</FormLabel>
            <TextField
              fullWidth
              disabled
              sx={{ background: "#f1f5f9" }}
              value={dataSo && !isFetchingSo ? dataSo.po_number : ""}
            />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel>M/C Printing</FormLabel>
            <Autocomplete
              sx={{ width: "100%" }}
              disablePortal
              value={
                dataMCPrinting &&
                dataMCPrinting.find(
                  (item) => item.id === formik.values.mc_printing
                )
                  ? dataMCPrinting?.name
                  : dataMCPrinting?.name
              }
              onChange={(_, option) => {
                if (option) formik.setFieldValue("mc_printing", option.name);
              }}
              options={
                dataMCPrinting
                  ? [
                      { name: "Tambah M/C Printing baru disini..." },
                      ...dataMCPrinting,
                    ]
                  : []
              }
              getOptionLabel={(option) =>
                option.name && typeof option.name === "string"
                  ? option.name
                  : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Pilih M/C Printing"
                  onChange={(e) => setNewMCPrinting(e.target.value)}
                />
              )}
              filterOptions={(options, state) => {
                const results = defaultFilterOptions(options, state);
                if (results.length === 0) {
                  return [{ id: -1, name: "Tambah M/C Printing" }];
                }

                return results;
              }}
            />
          </FormControl>
        </div>
        <div className="my-2 flex w-full gap-2 items-end">
          <div className="w-full">
            <p>Tanggal PO</p>
            <div className="w-full mt-1 border-2 rounded-md flex items-center bg-slate-100">
              <div className="flex justify-between items-center h-full cursor-pointer w-full p-3.5 text-gray-500">
                {selectedSo === null || !dataSo || isFetchingSo ? (
                  <p>----/--/--</p>
                ) : (
                  <p>{format(dataSo.po_date, "yyyy-MM-dd")}</p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
          </div>
          <FormControl fullWidth>
            <FormLabel>Kategori</FormLabel>
            <Select
              value={formik.values.category}
              onChange={(e) => formik.setFieldValue("category", e.target.value)}
            >
              <MenuItem value={0} disabled>
                Pilih Kategori
              </MenuItem>
              <MenuItem value={1}>Prime</MenuItem>
              <MenuItem value={2}>Difficult</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="my-2 flex w-full gap-2 items-end">
          <FormControl fullWidth>
            <FormLabel>Customer</FormLabel>
            <TextField
              fullWidth
              sx={{ background: "#f1f5f9" }}
              disabled
              value={dataSo && !isFetchingSo ? dataSo.customer : ""}
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel>Dies</FormLabel>
            <Select
              value={formik.values.die}
              onChange={(e) => formik.setFieldValue("die", e.target.value)}
            >
              <MenuItem value={0} disabled>
                Pilih Die
              </MenuItem>
              <MenuItem value={1}>PB</MenuItem>
              <MenuItem value={2}>FL</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="my-2 flex w-full gap-2 items-end">
          <FormControl fullWidth>
            <FormLabel>Sales</FormLabel>
            <TextField
              disabled
              sx={{ background: "#f1f5f9" }}
              value={selectedSo ? selectedSo.user_name : ""}
            />
          </FormControl>
          <div className="w-full">
            <p>Tanggal Schedule</p>
            <div className="w-full mt-1 border-2 rounded-md flex items-center">
              <div
                className="flex justify-between items-center h-full cursor-pointer w-full p-3.5 text-gray-500"
                onClick={(e) => setAnchorElCalendar(e.currentTarget)}
              >
                {!scheduleDate || scheduleDate === "" ? (
                  <p>----/--/--</p>
                ) : (
                  <p>{format(scheduleDate, "yyyy-MM-dd")}</p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
          </div>
        </div>

        <div className="my-4">
          {formik.values.project.length > 0 ? (
            formik.values.project.map((proj, i) => (
              <div className="mb-4">
                <SpkProjectCard
                  expandArr={expandArr}
                  setExpandArr={setExpandArr}
                  formik={formik}
                  i={i}
                  project={proj}
                  setIsSubmit={setIsSubmit}
                  isSave={isSave}
                />
              </div>
            ))
          ) : (
            <p className="text-gray-300 text-center">
              Belum ada project, mohon memilih Project SO terlebih dahulu
            </p>
          )}
        </div>

        <TextField
          multiline
          rows={4}
          label="Notes"
          fullWidth
          value={formik.values.note}
          onChange={(e) => {
            formik.setFieldValue("note", e.target.value);
          }}
          sx={{ marginBottom: 2 }}
        />

        <p className="text-red-500 text-right mb-4">{errorMessage}</p>

        <div className="w-full">
          <div className="flex justify-end w-1/3 gap-2 float-right">
            <button
              type="button"
              className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
              onClick={() => navigate("/spk")}
            >
              Cancel
            </button>
            <button
              className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
              disabled={!formik.isValid}
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </form>

      <ModalInput
        title="Tambah SPK"
        subtitle="Apakah anda yakin ingin menyimpan SPK ini?"
        content={<></>}
        open={open}
        setOpen={setOpen}
        hasButton={true}
        buttonText={isPendingAdd ? <CircularProgress /> : "Simpan"}
        setTriggerFunc={() => {
          setIsSave(true);
        }}
        isDisable={isPendingAdd}
      />

      <ModalInput
        title="Tambah M/C Printing"
        content={
          <>
            <TextField
              fullWidth
              label="Nama M/C Printing..."
              value={newMCPrinting}
              onChange={(e) => setNewMCPrinting(e.target.value)}
            />
          </>
        }
        open={openAddMCPrinting}
        setOpen={setOpenAddMCPrinting}
        hasButton={true}
        buttonText="Save"
        setTriggerFunc={setIsAdd}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendar}
        setAnchorElCalendarStart={setAnchorElCalendar}
        startDate={scheduleDate}
        setStartDate={setScheduleDate}
      />
    </>
  );
};

export default AddSpk;
