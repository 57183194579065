import {
  Autocomplete,
  FormGroup,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getCustomerList } from "../../../services/customerManagementApi";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  getInternalCode,
  getNextJob,
  setMpsFlexo,
} from "../../../services/mpsApi";
import * as yup from "yup";

const validationSchema = yup.object({
  customer_id: yup.number().min(1),
  internal_code_id: yup.number().min(1),
  die_cut: yup.number().min(1),
  die_cut_status: yup.string().required(),
  gear: yup.string().required(),
  machine: yup.string().required(),
  description: "",
});

const AddMpsFlexo = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedCode, setSelectedCode] = useState(null);

  const formik = useFormik({
    initialValues: {
      customer_id: null,
      internal_code_id: 0,
      die_cut: 0,
      die_cut_status: "",
      gear: "",
      machine: "",
      description: "",
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      mutateMpsFlexo(values);
    },
  });

  const { mutate: mutateMpsFlexo, isPending } = useMutation({
    mutationFn: setMpsFlexo,
    onSuccess: () => navigate("/mps-flexo"),
    onError: (err) => setErrorMessage(err.message.id),
  });

  const { data: dataNext } = useQuery({
    queryKey: ["next-job-flexo"],
    queryFn: () => getNextJob(1), // 1: flexo, 2: flexible
  });
  
  const { data: dataInternalCode } = useQuery({
    queryKey: ["internal-code", formik.values.customer_id],
    queryFn: () => getInternalCode(formik.values.customer_id),
    enabled: formik.values.customer_id !== null,
  });

  const { mutate: mutateCustomerList, data: dataCustomer } = useMutation({
    mutationFn: getCustomerList,
  });

  useEffect(() => {
    formik.setFieldValue(
      "internal_code_id",
      selectedCode ? selectedCode.id : 0
    );
  }, [selectedCode]);

  useEffect(() => {
    mutateCustomerList({
      name: "",
      start_date: "",
      end_date: "",
      pkp: 0,
      offset: 1,
      limit: 10000,
    });
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Job</FormLabel>
          <TextField fullWidth disabled value={dataNext ? dataNext : ""} />
        </FormGroup>
        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Customer</FormLabel>
          <Autocomplete
            sx={{ width: "100%" }}
            disablePortal
            value={
              formik.values.customer_id && dataCustomer
                ? dataCustomer.data.find(
                    (item) => item.id === formik.values.customer_id
                  )
                : null
            }
            onChange={(_, option) => {
              formik.setFieldValue("customer_id", option ? option.id : null);
            }}
            options={dataCustomer && dataCustomer.data ? dataCustomer.data : []}
            getOptionLabel={(option) => option.customer_name}
            renderInput={(params) => (
              <TextField {...params} placeholder="Pilih Customer" />
            )}
          />
        </FormGroup>
        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Internal Code</FormLabel>
          <Autocomplete
            sx={{ width: "100%" }}
            disablePortal
            value={selectedCode}
            onChange={(_, option) => {
              setSelectedCode(option);
            }}
            options={dataInternalCode ? dataInternalCode : []}
            getOptionLabel={(option) => option.internal_code}
            renderInput={(params) => (
              <TextField {...params} placeholder="Pilih Internal Code" />
            )}
          />
        </FormGroup>
        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Label Name</FormLabel>
          <TextField
            fullWidth
            disabled
            value={selectedCode ? selectedCode.label_name : ""}
          />
        </FormGroup>
        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Size</FormLabel>
          <TextField
            fullWidth
            disabled
            value={selectedCode ? selectedCode.size : ""}
          />
        </FormGroup>

        <p className="font-bold text-xl mb-4">Materials</p>
        <div className="rounded-md shadow-md">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <th className="p-3 text-center">No</th>
                <th className="p-3 text-center">Nama Label</th>
                <th className="p-3 text-center">Material Name</th>
              </thead>
              <tbody>
                {selectedCode ? (
                  selectedCode.material.map((item, i) => {
                    return (
                      <tr>
                        <td className="p-3 text-center">{i + 1}</td>
                        <td className="p-3 text-center">
                          {selectedCode.label_name}
                        </td>
                        <td className="p-3 text-center">{item}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="p-3 text-center" colSpan={3}>
                      Tidak ada internal code
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Finishing</FormLabel>
          <TextField
            fullWidth
            disabled
            value={selectedCode ? selectedCode.finishing : ""}
          />
        </FormGroup>

        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Die Cut</FormLabel>
          <Select
            value={formik.values.die_cut}
            onChange={(e) => formik.setFieldValue("die_cut", e.target.value)}
          >
            <MenuItem value={0} disabled>
              Pilih Die Cut
            </MenuItem>
            <MenuItem value={1}>Flexible</MenuItem>
            <MenuItem value={2}>Flatbed</MenuItem>
          </Select>
        </FormGroup>

        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Die Cut Status</FormLabel>
          <TextField
            fullWidth
            value={formik.values.die_cut_status}
            onChange={(e) =>
              formik.setFieldValue("die_cut_status", e.target.value)
            }
          />
        </FormGroup>

        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Gear</FormLabel>
          <TextField
            fullWidth
            value={formik.values.gear}
            onChange={(e) => formik.setFieldValue("gear", e.target.value)}
          />
        </FormGroup>

        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Machine</FormLabel>
          <TextField
            fullWidth
            value={formik.values.machine}
            onChange={(e) => formik.setFieldValue("machine", e.target.value)}
          />
        </FormGroup>

        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Keterangan</FormLabel>
          <TextField
            fullWidth
            value={formik.values.description}
            onChange={(e) =>
              formik.setFieldValue("description", e.target.value)
            }
          />
        </FormGroup>

        <p className="text-red-500 mb-4 text-right m-4">{errorMessage}</p>
        <div className="w-full">
          <div className="flex justify-end w-1/3 gap-2 float-right">
            <button
              type="button"
              className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
              onClick={() => navigate("/mps-flexo")}
            >
              Cancel
            </button>
            <button
              className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
              disabled={!formik.isValid | isPending}
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddMpsFlexo;
