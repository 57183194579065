import {
  CircularProgress,
  FormControl,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import { IoPencilOutline } from "react-icons/io5";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Search } from "@mui/icons-material";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getUserList,
  updateStatusUser,
} from "../../../services/userManagementApi";
import { useNavigate } from "react-router-dom";
import ModalInput from "../../common/ModalInput";

const UserList = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openUpdateStatus, setOpenUpdateStatus] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [refetchList, setRefetchList] = useState(true);

  const getPositionStr = (pos) => {
    return pos === 1
      ? "Plate (Plate & Die Cut)"
      : pos === 2
      ? "Gudang (Paper, BOPP, Foil)"
      : pos === 3
      ? "Tinta"
      : pos === 4
      ? "Operasional"
      : pos === 5
      ? "QC"
      : pos === 6
      ? "Sales"
      : "-";
  };

  const { data, isFetching } = useQuery({
    queryKey: ["user-list", search, filter, page, fetchLimit, refetchList],
    // enabled: refetchList,
    queryFn: () =>
      getUserList({
        name: search,
        role: filter,
        offset: page,
        limit: fetchLimit,
      }),
  });

  const { mutate: mutateUpdateStatus } = useMutation({
    mutationFn: updateStatusUser,
    onSuccess: () => {
      setRefetchList(true);
      setIsUpdate(false);
      setOpenUpdateStatus(false);
    },
  });

  useEffect(() => {
    if (data) {
      setRefetchList(false);
    }
  }, [data]);

  useEffect(() => {
    if (isUpdate) {
      const body = {
        id: selectedUser.id,
        is_active: !selectedUser.is_active,
      };
      mutateUpdateStatus(body);
    }
  }, [isUpdate]);

  useEffect(() => {
    setPage(1);
  }, [fetchLimit, search, filter]);

  return (
    <div>
      <div className="flex mb-4 gap-2">
        <TextField
          fullWidth
          placeholder="Search Nama Karyawan"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <FormControl
          sx={{
            width: "100%",
          }}
        >
          <Select value={filter} onChange={(e) => setFilter(e.target.value)}>
            <MenuItem value={0}>Semua Role</MenuItem>
            <MenuItem value={6}>Sales</MenuItem>
            <MenuItem value={1}>Plate (Plate & Die Cut)</MenuItem>
            <MenuItem value={2}>Gudang (Paper, BOPP, Foil)</MenuItem>
            <MenuItem value={3}>Tinta</MenuItem>
            <MenuItem value={4}>Operasional</MenuItem>
            <MenuItem value={5}>QC</MenuItem>
          </Select>
        </FormControl>
        <button
          onClick={() => navigate("/user-management/add-user")}
          className="w-1/4 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
        >
          <p>Add User</p>
          <IoMdAddCircleOutline className="text-[18px]" />
        </button>
      </div>
      <>
        {" "}
        <div className="rounded-md shadow-md">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <th className="p-3 text-left">No</th>
                <th className="p-3 text-left">Nama Karyawan</th>
                <th className="p-3 text-left">User Role</th>
                <th className="p-3 text-left">Status</th>
                <th className="p-3 text-left w-[15%]">Action</th>
              </thead>
              <tbody>
                {data && data.data && data.data.length > 0 && !isFetching ? (
                  data.data.map((item, i) => {
                    return (
                      <tr className="border-t border-l border-2" key={i}>
                        <td className="p-3">
                          {(page - 1) * fetchLimit + (i + 1)}
                        </td>
                        <td className="p-3">{item.fullname}</td>
                        <td className="p-3">{getPositionStr(item.position)}</td>
                        <td className="p-3">
                          <Switch
                            checked={item.is_active}
                            onChange={() => {
                              setOpenUpdateStatus(true);
                              setSelectedUser(item);
                            }}
                          />
                        </td>
                        <td className="p-3 w-[15%]">
                          <div
                            onClick={() =>
                              navigate(`/user-management/edit-user/${item.id}`)
                            }
                            className="mx-auto flex items-center gap-2 border rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out w-fit"
                          >
                            <p>Edit</p>
                            <IoPencilOutline className="text-blue-500 w-[18px] h-full " />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : !isFetching ? (
                  <tr className="border-t border-l border-2">
                    <td colSpan={5} className="py-3 text-center">
                      Data user tidak ditemukan
                    </td>
                  </tr>
                ) : (
                  <tr className="border-t border-l border-2">
                    <td colSpan={5} className="py-3 text-center">
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {data && data.total > 0 ? (
            <div className="w-full flex justify-between pb-8 px-8">
              <button
                onClick={() => {
                  if (page > 1) setPage(page - 1);
                }}
                disabled={page === 1}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowLeftLong /> Previous
              </button>
              <Pagination
                hideNextButton
                hidePrevButton
                shape="rounded"
                page={page}
                count={Math.ceil(data.total / fetchLimit)}
                onChange={(e, page) => setPage(page)}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
              <button
                onClick={() => {
                  if (page < Math.ceil(data.total / fetchLimit))
                    setPage(page + 1);
                }}
                disabled={page === Math.ceil(data.total / fetchLimit)}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowRightLong /> Next
              </button>
            </div>
          ) : null}
        </div>
        {data && data.total > 0 ? (
          <div className="mt-4 text-xs flex gap-3 items-center">
            <p>Show Table</p>
            <p
              onClick={() => setFetchLimit(10)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 10 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              10
            </p>
            <p
              onClick={() => setFetchLimit(50)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 50 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              50
            </p>
            <p
              onClick={() => setFetchLimit(100)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 100 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              100
            </p>
          </div>
        ) : null}
      </>

      <ModalInput
        title="Konfirmasi"
        subtitle="Apakah anda yakin ingin mengubah status user?"
        content={<></>}
        open={openUpdateStatus}
        setOpen={setOpenUpdateStatus}
        hasButton={true}
        buttonText="Yes"
        setTriggerFunc={setIsUpdate}
        isDisable={false}
      />
    </div>
  );
};

export default UserList;
