import { Button, Popover } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { eachDayOfInterval, addDays, format } from "date-fns";

const RangeCalendar = ({
  anchorElCalendar,
  setAnchorElCalendar,
  setSelectedDates,
  searchStartDate,
  searchEndDate,
  setSearchStartDate,
  setSearchEndDate,
  exclude,
  toSide,
}) => {
  const openCalendar = Boolean(anchorElCalendar);

  const handleCloseCalendar = () => {
    setAnchorElCalendar(null);
  };

  const onChangeDate = (dates) => {
    const [start, end] = dates;
    setSearchStartDate(start);
    setSearchEndDate(end);
    setSelectedDates([
      format(new Date(start), "yyyy-MM-dd"),
      end === null ? "" : format(new Date(end), "yyyy-MM-dd"),
    ]);
  };

  const onResetDate = () => {
    setSelectedDates(["", ""]);
    setSearchStartDate(null);
    setSearchEndDate(null);
  };

  return (
    <Popover
      open={openCalendar}
      anchorEl={anchorElCalendar}
      onClose={handleCloseCalendar}
      anchorOrigin={{
        vertical: toSide ? "top" : "bottom",
        horizontal: toSide ? "left" : "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: toSide ? "right" : "center",
      }}
      sx={{ width: "100%" }}
    >
      <div className="flex flex-wrap justify-center p-2">
        <DatePicker
          selected={searchStartDate}
          onChange={onChangeDate}
          startDate={searchStartDate}
          endDate={searchEndDate}
          selectsRange
          inline
          className="w-full"
          excludeDates={
            exclude
              ? eachDayOfInterval({
                  start: addDays(new Date(), 1),
                  end: addDays(new Date(), 365),
                })
              : []
          }
        />
        <div className="flex w-full mt-2 gap-1">
          <Button fullWidth variant="outlined" onClick={onResetDate}>
            Tampilkan Semua
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={() => setAnchorElCalendar(null)}
          >
            Select
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export default RangeCalendar;
