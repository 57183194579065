import axios from "axios";
import Cookies from "js-cookie";

const token = () => Cookies.get("accessToken");

export const getReturSupplierList = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/list-retur-supplier`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getDetailReturSupplier = async (id) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/detail-retur-supplier?id=${id}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const setReturSupplier = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/retur-supplier`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const deleteReturSupplier = async (id) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/delete-retur-supplier?id=${id}`,
      method: "DELETE",
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const setMasterReturSupplier = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/master-retur`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getMasterReturSupplier = async ({ type }) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/get-master?type=${type}`,
      method: "GET",
      data: type,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getInternalCode = async (id) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/detail-so?spk_id=${id}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response;
  }
}

export const getMaterial = async (costingId) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/list-costing?costing_id=[${costingId}]`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response;
  }
}