import {
  Autocomplete,
  MenuItem,
  Select,
  TextField,
  FormControl,
  FormGroup,
  FormLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getSpkDetail, getSpkList } from "../../../services/SpkApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { secondsToHours, secondsToMinutes } from "date-fns";
import {
  getDetailScheduleBySpk,
  setNewJob,
} from "../../../services/scheduleApi";
import UpdateJobTimer from "./UpdateJobTimer";

const validationSchema = yup
  .object({
    schedule_access: yup.number(),
    spk_id: yup.number().min(1),
    internal_code: yup.string().required(),
    total_time: yup.number(),
  })


const JobQC = () => {
  const navigate = useNavigate();
  const { spkId } = useParams();
  const timerName = [
    { name: "Setup", keyName: "setup" },
    { name: "Break Down Repair", keyName: "breakdown_repair" },
    { name: "Run", keyName: "run" },
    {
      name: "Cleaning & Maintenance",
      keyName: "cleaning_maintenance",
    },
    { name: "Break", keyName: "break" },
    { name: "No Job", keyName: "no_job" },
    { name: "QC Complete", keyName: "qc_complete" },
    { name: "Rework", keyName: "rework" },
  ];
  const [spk, setSpk] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeButton, setActiveButton] = useState(null);
  const [internalCode, setInternalCode] = useState("");
  const [dataDetailSchedule, setDataDetailSchedule] = useState(null);
  const [isFetchingDetailSchedule, setIsFetchingDetailSchedule] =
    useState(false);

  useEffect(() => {
    const fetchDetailSchedule = async () => {
      if (spk?.id && internalCode) {
        setIsFetchingDetailSchedule(true);
        try {
          const data = await getDetailScheduleBySpk(spk?.id, internalCode);
          setDataDetailSchedule(data);
        } catch (error) {
          console.error("Error fetching schedule detail:", error);
        } finally {
          setIsFetchingDetailSchedule(false);
        }
      }
    };

    fetchDetailSchedule();
  }, [spk?.id]);

  const { data: dataDetailSpk, isFetching } = useQuery({
    queryKey: [
      "spk-detail",
      spk?.id,
      dataDetailSchedule?.tooling_control?.spk_id,
    ],
    queryFn: () =>
      getSpkDetail(dataDetailSchedule?.tooling_control?.spk_id || spk?.id),
    enabled: !!dataDetailSchedule || !!spk?.id,
  });

  const formik = useFormik({
    initialValues: {
      schedule_access: 5,
      spk_id: dataDetailSchedule
        ? dataDetailSchedule.tooling_control.spk_id
        : 0,
      internal_code: dataDetailSchedule
        ? dataDetailSchedule.tooling_control.internal_code
        : "",
      total_time: 0,
      setup: null,
      breakdown_repair: null,
      run: null,
      cleaning_maintenance: null,
      break: null,
      no_job: null,
      qc_complete: null,
      rework: null,
      sliting_estimate: "",
      sliting_act: "",
      sheeting_estimate: "",
      sheeting_act: "",
      cutting_estimate: "",
      cutting_act: "",
      packing_roll_estimate: "",
      packing_roll_act: "",
      packing_sheet_estimate: "",
      packing_sheet_act: "",
      note: "",
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values) => {
      mutateTakeJob(values);
    },
  });

  const { mutate: mutateTakeJob, isPending } = useMutation({
    mutationFn: setNewJob,
    onSuccess: () => navigate("/schedule"),
    onError: (err) => setErrorMessage(err.message.id),
  });

  useEffect(() => {
    const fieldsToSet = {
      schedule_access: 5,
      spk_id: spk?.id,
      internal_code: internalCode,
      total_time: calculateTotalTime(dataDetailSchedule),
      setup: Number(dataDetailSchedule?.qc_information?.setup),
      breakdown_repair: Number(
        dataDetailSchedule?.qc_information?.breakdown_repair
      ),
      run: Number(dataDetailSchedule?.qc_information?.run),
      cleaning_maintenance: Number(
        dataDetailSchedule?.qc_information?.cleaning_maintenance
      ),
      break: Number(dataDetailSchedule?.qc_information?.break),
      no_job: Number(dataDetailSchedule?.qc_information?.no_job),
      qc_complete: Number(dataDetailSchedule?.qc_information?.qc_complete),
      rework: Number(dataDetailSchedule?.qc_information?.rework),
      sliting_estimate: String(dataDetailSpk?.project[0]?.qc?.sliting),
      sliting_act: dataDetailSchedule?.qc_information?.sliting_act,
      sheeting_estimate: String(dataDetailSpk?.project[0]?.qc?.sheeting),
      sheeting_act: dataDetailSchedule?.qc_information?.sheeting_act,
      cutting_estimate: String(dataDetailSpk?.project[0]?.qc?.cutting),
      cutting_act: dataDetailSchedule?.qc_information?.cutting_act,
      packing_roll_estimate: String(
        dataDetailSpk?.project[0]?.qc?.packing_roll
      ),
      packing_roll_act: dataDetailSchedule?.qc_information?.packing_roll_act,
      packing_sheet_estimate: String(
        dataDetailSpk?.project[0]?.qc?.packing_sheet
      ),
      packing_sheet_act: dataDetailSchedule?.qc_information?.packing_sheet_act,
      note: dataDetailSchedule?.qc_information?.note,
    };

    Object.entries(fieldsToSet).forEach(([field, value]) => {
      formik.setFieldValue(field, value);
    });
  }, [dataDetailSchedule, internalCode]);

  const calculateTotalTime = (dataDetailSchedule) => {
    return (
      (Number(dataDetailSchedule?.qc_information?.setup) || 0) +
      (Number(dataDetailSchedule?.qc_information?.breakdown_repair) || 0) +
      (Number(dataDetailSchedule?.qc_information?.run) || 0) +
      (Number(dataDetailSchedule?.qc_information?.cleaning_maintenance) || 0) +
      (Number(dataDetailSchedule?.qc_information?.break) || 0) +
      (Number(dataDetailSchedule?.qc_information?.no_job) || 0) +
      (Number(dataDetailSchedule?.qc_information?.qc_complete) || 0) +
      (Number(dataDetailSchedule?.qc_information?.rework) || 0)
    );
  };

  const { mutate: mutateSpkList, data: dataSpkList } = useMutation({
    mutationFn: getSpkList,
  });

  const findAllEstimate = (code) => {
    if (dataDetailSpk) {
      const qcValues = dataDetailSpk?.project?.find(
        (proj) => proj.internal_code === code
      ).qc;
      formik.setFieldValue("sliting_estimate", qcValues?.sliting?.toString());
      formik.setFieldValue("sheeting_estimate", qcValues?.sheeting?.toString());
      formik.setFieldValue("cutting_estimate", qcValues?.cutting?.toString());
      formik.setFieldValue(
        "packing_roll_estimate",
        qcValues?.packing_roll?.toString()
      );
      formik.setFieldValue(
        "packing_sheet_estimate",
        qcValues?.packing_sheet?.toString()
      );
    }
  };

  useEffect(() => {
    mutateSpkList({
      offset: 1,
      limit: 10,
      schedule: 5,
      search: "",
      po_start: "",
      po_end: "",
      delivery_start: "",
      delivery_end: "",
      job_type: [],
      category: [],
      dies: [],
    });
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Autocomplete
          sx={{ width: "100%", marginBottom: 2 }}
          disablePortal
          disabled={spkId}
          value={
            spkId
              ? dataSpkList
                ? dataSpkList?.data?.find(
                    (item) =>
                      item.id ===
                      Number(dataDetailSchedule.tooling_control.spk_id)
                  )
                : null
              : spk
          }
          onChange={(_, option) => {
            if (!spkId) {
              setSpk(option);
              formik.setFieldValue("spk_id", option ? option.id : null);
            }
          }}
          options={dataSpkList ? dataSpkList.data : []}
          getOptionLabel={(option) =>
            option.spk_no + " - " + option.customer_name
          }
          renderInput={(params) => <TextField {...params} label="No. SPK" />}
          onInputChange={(event, newInputValue, reason) => {
            if (reason === "reset" && !spkId) {
              setSpk(null);
              formik.setFieldValue("spk_id", null);
              formik.setFieldValue("internal_code", "");
              return;
            }
          }}
        />
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <FormLabel>Internal Code</FormLabel>
          <Select
            disabled={!dataDetailSpk || (!spkId && spk === null) || spkId}
            value={formik.values.internal_code}
            onChange={(e) => {
              formik.setFieldValue("internal_code", e.target.value);
              setInternalCode(e.target.value);
            }}
          >
            <MenuItem value={""} disabled>
              Pilih Internal Code
            </MenuItem>
            {dataDetailSpk && dataDetailSpk.project ? (
              dataDetailSpk.project.map((item, i) => (
                <MenuItem value={item.internal_code} key={i}>
                  {item.internal_code}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No Internal Codes Available</MenuItem>
            )}
          </Select>
        </FormControl>
        <FormGroup>
          <FormLabel>Label Name</FormLabel>
          <TextField
            fullWidth
            sx={{ marginBottom: 2 }}
            disabled
            value={
              dataDetailSpk && formik.values.internal_code !== ""
                ? dataDetailSpk?.project?.find(
                    (proj) => proj.internal_code === formik.values.internal_code
                  )?.label_name
                : ""
            }
          />
        </FormGroup>

        <div className="my-4 grid grid-cols-4 grid-flow-row gap-2">
          {timerName.map((timer, i) => (
            <UpdateJobTimer
              timerInfo={timer}
              formik={formik}
              activeButton={activeButton}
              setActiveButton={setActiveButton}
              i={i}
            />
          ))}
        </div>

        <div className="flex items-center gap-2">
          <p className="">Total Time</p>
          <div className="py-2 px-8 bg-slate-100 rounded-md flex items-end">
            {secondsToHours(formik.values.total_time) > 0 ? (
              <>
                <p className="text-xl">
                  {secondsToHours(formik.values.total_time)}
                </p>
                <p className="mr-3">h</p>
              </>
            ) : null}
            {secondsToMinutes(formik.values.total_time) > 0 ? (
              <>
                <p className="text-xl">
                  {secondsToMinutes(formik.values.total_time)}
                </p>
                <p className="mr-3">m</p>{" "}
              </>
            ) : null}
            {formik.values.total_time > 0 ? (
              <>
                <p>{formik.values.total_time % 60}</p>
                <p>s</p>{" "}
              </>
            ) : (
              0
            )}
          </div>
        </div>

        <div className="my-4">
          <p className="font-bold">Slitting (HR)</p>
          <hr className="my-2" />
          <div className="mb-4 flex gap-2">
            <TextField
              fullWidth
              label="Estimation"
              disabled
              value={formik.values.sliting_estimate}
            />
            <TextField
              fullWidth
              label="Actual"
              value={formik.values.sliting_act}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue("sliting_act", e.target.value);
              }}
            />
          </div>
        </div>

        <div className="my-4">
          <p className="font-bold">Sheeting (HR)</p>
          <hr className="my-2" />
          <div className="mb-4 flex gap-2">
            <TextField
              fullWidth
              label="Estimation"
              disabled
              value={formik.values.sheeting_estimate}
            />
            <TextField
              fullWidth
              label="Actual"
              value={formik.values.sheeting_act}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue("sheeting_act", e.target.value);
              }}
            />
          </div>
        </div>

        <div className="my-4">
          <p className="font-bold">Cutting (HR)</p>
          <hr className="my-2" />
          <div className="mb-4 flex gap-2">
            <TextField
              fullWidth
              label="Estimation"
              disabled
              value={formik.values?.cutting_estimate}
            />
            <TextField
              fullWidth
              label="Actual"
              value={formik.values?.cutting_act}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue("cutting_act", e.target.value);
              }}
            />
          </div>
        </div>

        <div className="my-4">
          <p className="font-bold">Packing Roll (HR)</p>
          <hr className="my-2" />
          <div className="mb-4 flex gap-2">
            <TextField
              fullWidth
              label="Estimation"
              disabled
              value={formik.values?.packing_roll_estimate}
            />
            <TextField
              fullWidth
              label="Actual"
              value={formik.values?.packing_roll_act}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue("packing_roll_act", e.target.value);
              }}
            />
          </div>
        </div>

        <div className="my-4">
          <p className="font-bold">Packing Sheet (HR)</p>
          <hr className="my-2" />
          <div className="mb-4 flex gap-2">
            <TextField
              fullWidth
              label="Estimation"
              disabled
              value={formik.values?.packing_sheet_estimate}
            />
            <TextField
              fullWidth
              label="Actual"
              value={formik.values?.packing_sheet_act}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue("packing_sheet_act", e.target.value);
              }}
            />
          </div>
        </div>

        <TextField
          fullWidth
          label="Note"
          value={formik.values.note}
          onChange={(e) => formik.setFieldValue("note", e.target.value)}
          sx={{ marginBottom: 2 }}
        />

        <p className="text-right text-red-500 my-4">{errorMessage}</p>

        <div className="flex justify-end w-1/3 gap-2 float-right">
          <button
            className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
          <button
            className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
            disabled={!formik.isValid || isPending}
            type="submit"
          >
            Done
          </button>
        </div>
      </form>
    </>
  );
};

export default JobQC;
