import React from "react";
import Header from "../../components/common/Header";
import SidebarComponent from "../../components/common/SidebarComponent";
import HOC from "../../components/HOC";
import DetailReturnSupplier from "../../components/screen/returnSupplier/DetailReturnSupplier";

const EditReturnSupplierPage = () => {
  return (
    <div className="">
      <div className="flex">
        <div className="w-0 lg:w-[310px] h-screen sticky top-0 z-30">
          <SidebarComponent />
        </div>
        <div className="w-[100%] lg:w-[calc(100%-310px)] p-3 mt-4 lg:mt-0">
          <Header />
          <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
            Detail Retur Supplier
          </h1>
          <DetailReturnSupplier />
        </div>
      </div>
    </div>
  );
};

export default HOC(EditReturnSupplierPage);
