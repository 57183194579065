import { useState, useEffect } from "react";
import { Breadcrumbs, Link, TextField } from "@mui/material";
import { IoPencilSharp } from "react-icons/io5";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteImageSupplier,
  getSupplierInvoiceDetail,
  updateInvoicePaid,
  updateSupplierInvoice,
} from "../../../services/supplierInvoiceApi";
import SingleCalendar from "../../common/SingleCalendar";
import { MdCalendarMonth, MdCancel } from "react-icons/md";
import { format } from "date-fns";
import { useFormik } from "formik";
import { AiOutlineCloudUpload } from "react-icons/ai";
import ModalInput from "../../common/ModalInput";
import moment from "moment";

const InvoiceDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isEdit, setIsEdit] = useState(false);
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [anchorElCalendarInv, setAnchorElCalendarInv] = useState(null);
  const [anchorElCalendarInvReceived, setAnchorElCalendarInvReceived] =
    useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [invDate, setInvDate] = useState(null);
  const [invDateReceived, setInvDateReceived] = useState(null);
  const [errorImg, setErrorImg] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isPaid, setIsPaid] = useState(false);
  const [isDeleteImg, setIsDeleteImg] = useState(false);
  const [isInitialImage, setIsInitialImage] = useState(true);
  const [changeImage, setChangeImage] = useState(false);
  const [openPay, setOpenPay] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], data.filename, { type: mime });
  };

  const handleUpdate = () => {
    const body = {
      ...formik.values,
      file:
        typeof formik.values.file === "string"
          ? dataURLtoFile(`data:text/plain;base64,${formik.values.file}`)
          : formik.values.file,
    };
    mutateUpdate(body);
  };

  const uploadMedia = (e) => {
    const files = e.target.files[0];
    if (!files) return;
    formik.setFieldValue("file", files);
    setChangeImage(true);
    setIsInitialImage(false);
  };

  const { data, isFetching } = useQuery({
    queryKey: ["invoice-detail", id, refetch],
    queryFn: () => getSupplierInvoiceDetail(id),
  });

  const formik = useFormik({
    initialValues: {
      file: data && data.invoice_img !== "" ? data.invoice_img : null,
      due_date:
        data && data.due_date ? moment(data.due_date).format("YYYY-MM-DD") : "",
      id: id ? Number(id) : "",
      invoice_date:
        data && data.invoice_date
          ? moment(data.invoice_date).format("YYYY-MM-DD")
          : "",
      invoice_receive_date:
        data && data.invoice_receive_date
          ? moment(data.invoice_receive_date).format("YYYY-MM-DD")
          : "",
      dpp: data && data.dpp ? data.dpp : 0,
      faktur: data && data.faktur ? data.faktur : "",
    },
    enableReinitialize: true,
  });

  const { mutate: mutateUpdate } = useMutation({
    mutationFn: updateSupplierInvoice,
    onSuccess: () => {
      navigate("/sv-invoice");
    },
  });

  const { mutate: mutateUpdatePaid } = useMutation({
    mutationFn: updateInvoicePaid,
    onSuccess: () => {
      setOpenPay(false);
      setRefetch(true);
    },
  });

  const {
    data: dataDelete,
    isFetching: isPendingDelete,
    failureReason,
  } = useQuery({
    queryKey: ["delete-img", isDeleteImg],
    queryFn: () => deleteImageSupplier(data.filename, id),
    enabled: isDeleteImg,
  });

  useEffect(() => {
    if (isPaid) {
      mutateUpdatePaid(Number(id));
    }
  }, [isPaid]);

  useEffect(() => {
    dueDate
      ? formik.setFieldValue(
          "due_date",
          format(new Date(dueDate), "yyyy-MM-dd")
        )
      : formik.setFieldValue("due_date", "");
  }, [dueDate]);

  useEffect(() => {
    invDate
      ? formik.setFieldValue(
          "invoice_date",
          format(new Date(invDate), "yyyy-MM-dd")
        )
      : formik.setFieldValue("invoice_date", "");
  }, [invDate]);

  useEffect(() => {
    invDateReceived
      ? formik.setFieldValue(
          "invoice_receive_date",
          format(new Date(invDateReceived), "yyyy-MM-dd")
        )
      : formik.setFieldValue("invoice_receive_date", "");
  }, [invDateReceived]);

  useEffect(() => {
    if (dataDelete) {
      formik.setFieldValue("file", null);
      setIsDeleteImg(false);
    }
  }, [dataDelete]);

  useEffect(() => {
    if (!isEdit && data) {
      setDueDate(new Date(data.due_date));
      setInvDate(new Date(data.invoice_date));
      setInvDateReceived(new Date(data.invoice_receive_date));
    }
    if (data) {
      setRefetch(false);
      setChangeImage(data.invoice_img !== "");
    }
  }, [isEdit, data]);

  useEffect(() => {
    if (failureReason) setErrorImg("Gagal menghapus image");
  }, [failureReason]);

  useEffect(() => {
    if (isPendingDelete) setErrorImg("");
  }, [isPendingDelete]);

  useEffect(() => {
    if (changeImage && !isFetching && data) {
      var reader = new FileReader();
      var imgtag = document.getElementById("uploaded-img");

      if (!isInitialImage) {
        reader.onload = function (event) {
          imgtag.src = event.target.result;
        };

        reader.readAsDataURL(formik.values.file);
      } else {
        imgtag.src = `data:image/png;base64,${data.invoice_img}`;
      }
      setChangeImage(false);
    }
  }, [changeImage, isFetching]);

  return (
    <div className="mt-12 lg:mt-2">
      <div className="flex justify-between items-center mb-8">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/sv-invoice">
            Supplier Invoice
          </Link>
          <Link underline="hover" color="inherit">
            Detail Supplier Invoice
          </Link>
        </Breadcrumbs>
        <div className="w-1/3 float-right justify-end flex items-center gap-2">
          {isEdit ? (
            <button
              onClick={() => setIsEdit(false)}
              className="w-fit text-red-500 px-12 flex items-center justify-center gap-2 border-red-500 border-2 p-2 rounded-md hover:bg-red-100 cursor-pointer"
            >
              <IoPencilSharp />
              <p>Cancel Edit</p>
            </button>
          ) : (
            <>
              <button
                onClick={() => setIsEdit(true)}
                className="w-fit px-12 flex items-center justify-center gap-2 border-[#18479D] border-2 p-2 rounded-md text-[#18479D] hover:bg-slate-100 cursor-pointer"
              >
                <IoPencilSharp />
                <p>Edit</p>
              </button>

              <button
                onClick={() => setOpenPay(true)}
                disabled={data ? data.status === "1" : true}
                className="w-fit disabled:bg-gray-400 disabled:cursor-default px-12 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white hover:bg-[#163e87] cursor-pointer"
              >
                <p>Bayar</p>
              </button>
            </>
          )}
        </div>
      </div>
      {data && !isFetching ? (
        <>
          <TextField
            fullWidth
            variant="standard"
            label="No. Invoice"
            disabled
            value={data.invoice_number}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="No. PO"
            disabled
            value={data.po_number}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="Kategori"
            disabled
            value={data.category_name}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="DPP"
            disabled={!isEdit}
            value={formik.values.dpp}
            onChange={(e) => {
              if (!isNaN(Number(e.target.value)))
                formik.setFieldValue("dpp", Number(e.target.value));
            }}
            sx={{ marginBottom: 2 }}
          />
          <p>Tanggal Invoice</p>
          <div
            className={`mb-4 w-full border-2 rounded-md flex items-center ${
              isEdit ? "hover:bg-slate-100" : "bg-gray-100"
            }`}
          >
            <div
              className={`flex justify-between items-center h-full w-full p-2 ${
                isEdit ? "cursor-pointer text-black" : "text-gray-300"
              }`}
              onClick={(e) => {
                if (isEdit) setAnchorElCalendarInv(e.currentTarget);
              }}
            >
              {invDate === null ? (
                <p>----/--/--</p>
              ) : (
                <p>{format(new Date(invDate), "yyyy-MM-dd")}</p>
              )}
              <MdCalendarMonth className="w-6 h-6" />
            </div>
          </div>
          <p>Tanggal Terima Invoice</p>
          <div
            className={`mb-4 w-full border-2 rounded-md flex items-center ${
              isEdit ? "hover:bg-slate-100" : "bg-gray-100"
            }`}
          >
            <div
              className={`flex justify-between items-center h-full w-full p-2 ${
                isEdit ? "cursor-pointer text-black" : "text-gray-300"
              }`}
              onClick={(e) => {
                if (isEdit) setAnchorElCalendarInvReceived(e.currentTarget);
              }}
            >
              {invDateReceived === null ? (
                <p>----/--/--</p>
              ) : (
                <p>{format(new Date(invDateReceived), "yyyy-MM-dd")}</p>
              )}
              <MdCalendarMonth className="w-6 h-6" />
            </div>
          </div>

          <TextField
            fullWidth
            variant="standard"
            label="No. Faktur Pajak"
            disabled={!isEdit}
            value={formik.values.faktur}
            onChange={(e) => {
                formik.setFieldValue("faktur", e.target.value);
            }}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="Supplier/Vendor"
            disabled
            value={data.supplier_name}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            variant="standard"
            label="Grand Total"
            disabled
            value={data.grand_total}
            sx={{ marginBottom: 2 }}
          />
          <p>Tanggal Jatuh Tempo</p>
          <div
            className={`mb-4 w-full border-2 rounded-md flex items-center ${
              isEdit ? "hover:bg-slate-100" : "bg-gray-100"
            }`}
          >
            <div
              className={`flex justify-between items-center h-full w-full p-2 ${
                isEdit ? "cursor-pointer text-black" : "text-gray-300"
              }`}
              onClick={(e) => {
                if (isEdit) setAnchorElCalendar(e.currentTarget);
              }}
            >
              {dueDate === null ? (
                <p>----/--/--</p>
              ) : (
                <p>{format(new Date(dueDate), "yyyy-MM-dd")}</p>
              )}
              <MdCalendarMonth className="w-6 h-6" />
            </div>
          </div>
          <TextField
            fullWidth
            variant="standard"
            label="Status"
            value={
              data.status === "1"
                ? "Sudah Bayar"
                : data.status === "2"
                ? "Belum Bayar"
                : "Terlambat Bayar"
            }
            disabled
          />
          <div
            className={`${
              isEdit ? "" : "bg-gray-100"
            } w-full border-2 p-4 rounded-lg text-center my-4`}
          >
            {formik.values.file === "" || formik.values.file === null ? (
              <>
                <>
                  <label
                    htmlFor="dropzone-file1"
                    className={`flex flex-wrap justify-center my-8 ${
                      isEdit ? "hover:text-slate-400 cursor-pointer" : ""
                    }`}
                  >
                    <div className="flex items-center justify-center">
                      <AiOutlineCloudUpload className="text-3xl" />
                      <p className="ml-4 font-bold text-lg">Upload File</p>
                    </div>
                    <p className="text-sm rounded-md w-full mt-2 text-gray-400">
                      Klik untuk mencari file di perangkat anda
                    </p>
                    <input
                      id="dropzone-file1"
                      type="file"
                      accept="image/jpeg, image/jpg, image/png"
                      onChange={(e) => {
                        uploadMedia(e);
                      }}
                      hidden
                      disabled={!isEdit}
                    />
                  </label>
                </>
              </>
            ) : (
              <div className="flex items-center justify-center w-full relative">
                <img id="uploaded-img" className="max-h-40 object-contain" />
                <button
                  type="button"
                  className={`${
                    !isEdit
                      ? "text-gray-300"
                      : "text-red-500 hover:text-red-700"
                  }`}
                  disabled={!isEdit}
                  onClick={() => {
                    isInitialImage
                      ? setIsDeleteImg(true)
                      : formik.setFieldValue("file", null);
                  }}
                >
                  <MdCancel className="text-2xl absolute top-0 right-0" />
                </button>
              </div>
            )}
          </div>
          <p className="text-red-500 text-xs text-center mb-8">{errorImg}</p>

          <div className="flex justify-end w-1/3 gap-2 float-right">
            <button
              type="button"
              className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
              onClick={() => navigate("/sv-invoice")}
            >
              Cancel
            </button>
            <button
              className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
              disabled={false}
              onClick={handleUpdate}
            >
              Save
            </button>
          </div>
        </>
      ) : null}

      <ModalInput
        title="Bayar"
        subtitle={`Apa anda yakin ingin menyelesaikan pembayaran invoice ${
          data ? data.invoice_number : ""
        }?`}
        content={
          <>
            <p className="text-red-500">{errorMessage}</p>
          </>
        }
        open={openPay}
        setOpen={setOpenPay}
        hasButton={true}
        buttonText="Yes"
        setTriggerFunc={setIsPaid}
        isDisable={false}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendar}
        setAnchorElCalendarStart={setAnchorElCalendar}
        startDate={dueDate}
        setStartDate={setDueDate}
      />
      <SingleCalendar
        anchorElCalendarStart={anchorElCalendarInv}
        setAnchorElCalendarStart={setAnchorElCalendarInv}
        startDate={invDate}
        setStartDate={setInvDate}
      />
      <SingleCalendar
        anchorElCalendarStart={anchorElCalendarInvReceived}
        setAnchorElCalendarStart={setAnchorElCalendarInvReceived}
        startDate={invDateReceived}
        setStartDate={setInvDateReceived}
      />
    </div>
  );
};

export default InvoiceDetail;
