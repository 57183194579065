import { Search } from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  CircularProgress,
  InputAdornment,
  Pagination,
  PaginationItem,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { MdCalendarMonth } from "react-icons/md";
import RangeCalendar from "../../common/RangeCalendar";
import { useNavigate } from "react-router-dom";
import { IoMdAddCircleOutline } from "react-icons/io";
import { FaArrowLeftLong, FaArrowRightLong, FaTrash } from "react-icons/fa6";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getMaterialList } from "../../../services/materialApi";
import { IoPencilOutline } from "react-icons/io5";
import ModalInput from "../../common/ModalInput";
import {
  deleteQuotation,
  getQuotationList,
} from "../../../services/quotationApi";
import { format } from "date-fns";
import { formatDate } from "date-fns";

const QuotationList = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [selectedDates, setSelectedDates] = useState(["", ""]);
  const [searchStartDate, setSearchStartDate] = useState(null);
  const [searchEndDate, setSearchEndDate] = useState(null);
  const [page, setPage] = useState(1);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["quotation-list", search, page, fetchLimit, selectedDates[1]],
    queryFn: () =>
      getQuotationList(
        search,
        page,
        fetchLimit,
        selectedDates[0],
        selectedDates[1]
      ),
  });

  const { mutate: mutateDelete } = useMutation({
    mutationFn: deleteQuotation,
    onSuccess: () => {
      setOpenDelete(false);
      refetch();
    },
    onError: (err) => setErrorMessage(err.message.id),
  });

  return (
    <>
      <div className="flex mb-4 gap-2">
        <TextField
          fullWidth
          placeholder="Quotation number, Nama Sales, Nama Customer"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <div className="w-full border-2 rounded-md w-full flex items-center hover:bg-slate-100">
          <div
            className="flex justify-between items-center h-full cursor-pointer w-full cursor-pointer px-2"
            onClick={(e) => setAnchorElCalendar(e.currentTarget)}
          >
            {selectedDates[0] === "" ? (
              <p className="text-gray-300">----/--/--</p>
            ) : (
              <p className="text-gray-300">{selectedDates[0]}</p>
            )}
            <p className="text-gray-300"> s/d </p>
            {selectedDates[1] === "" ? (
              <p className="text-gray-300">----/--/--</p>
            ) : (
              <p className="text-gray-300">{selectedDates[1]}</p>
            )}
            <MdCalendarMonth className="w-6 h-6" />
          </div>
        </div>
        <button
          onClick={() => navigate("/quotation/add-quotation")}
          className="w-1/4 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
        >
          <p>Add Quotation</p>
          <IoMdAddCircleOutline className="text-[18px]" />
        </button>
      </div>

      <div className="rounded-md shadow-md">
        <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
          <table className="w-full">
            <thead className="bg-[#F4F5FF] border-t border-b border-2">
              <th className="p-3 text-left">No</th>
              <th className="p-3 text-left">Quotation Number</th>
              <th className="p-3 text-left">Quotation Date</th>
              <th className="p-3 text-left">Nama Sales</th>
              <th className="p-3 text-left">Customer</th>
              <th className="p-3 text-left">Ship To</th>
              <th className="p-3 text-left">Bill To</th>
              <th className="p-3 text-left">Label Name</th>
              <th className="p-3 text-left">Total</th>
              <th className="p-3 text-left">Action</th>
            </thead>
            <tbody>
              {data && data.data && data.data.length > 0 && !isFetching ? (
                data.data.map((item, i) => {
                  return (
                    <tr className="border-t border-l border-2" key={i}>
                      <td className="p-3">
                        {(page - 1) * fetchLimit + (i + 1)}
                      </td>
                      <td
                        className="p-3 text-blue-500 cursor-pointer hover:text-slate-500"
                        onClick={() => navigate(`/quotation/detail/${item.id}`)}
                      >
                        {item.quotation_number}
                      </td>
                      <td className="p-3">
                        {item.quotation_date
                          ? format(new Date(item.quotation_date), "dd-MM-yyyy")
                          : "-"}
                      </td>
                      <td className="p-3">{item.sales}</td>
                      <td className="p-3">{item.customer}</td>
                      <td className="p-3">{item.ship_to}</td>
                      <td className="p-3">{item.bill_to}</td>
                      <td className="p-3">{item.labels.join(", ")}</td>
                      <td className="p-3">
                        Rp{item.total_price.toLocaleString()}
                      </td>
                      <td className="p-3 text-center">
                        <div className="flex gap-2 items-center">
                          <FaTrash
                            onClick={() => {
                              if (!item.is_sync) {
                                setOpenDelete(true);
                                setSelectedQuote(item.id);
                              }
                            }}
                            className={`border w-[24px] h-full rounded-md p-1 ${
                              item.is_sync
                                ? "text-gray-300"
                                : "text-red-500 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                            }`}
                          />
                          <IoPencilOutline
                            onClick={() => {
                              if (item.is_sync) {
                                setOpenEdit(true);
                                setSelectedQuote(item.id);
                              } else {
                                navigate(`/quotation/edit/${item.id}`);
                              }
                            }}
                            className="text-blue-500 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : !isFetching ? (
                <tr className="border-t border-l border-2">
                  <td colSpan={10} className="py-3 text-center">
                    Data quotation tidak ditemukan
                  </td>
                </tr>
              ) : (
                <tr className="border-t border-l border-2">
                  <td colSpan={10} className="py-3 text-center">
                    <CircularProgress size={20} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {data && data.count > 0 ? (
          <div className="w-full flex justify-between pb-8 px-8">
            <button
              onClick={() => {
                if (page > 1) setPage(page - 1);
              }}
              disabled={page === 1}
              className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
            >
              <FaArrowLeftLong /> Previous
            </button>
            <Pagination
              hideNextButton
              hidePrevButton
              shape="rounded"
              page={page}
              count={Math.ceil(data.count / fetchLimit)}
              onChange={(e, page) => setPage(page)}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                />
              )}
            />
            <button
              onClick={() => {
                if (page < Math.ceil(data.count / fetchLimit))
                  setPage(page + 1);
              }}
              disabled={page === Math.ceil(data.count / fetchLimit)}
              className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
            >
              <FaArrowRightLong /> Next
            </button>
          </div>
        ) : null}
      </div>
      {data && data.count > 0 ? (
        <div className="mt-4 text-xs flex gap-3 items-center">
          <p>Show Table</p>
          <p
            onClick={() => setFetchLimit(10)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 10 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            10
          </p>
          <p
            onClick={() => setFetchLimit(50)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 50 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            50
          </p>
          <p
            onClick={() => setFetchLimit(100)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 100 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            100
          </p>
        </div>
      ) : null}

      <ModalInput
        title="Hapus Quotation"
        subtitle="Apakah anda yakin ingin menghapus quotation ini?"
        content={
          <>
            <p className="text-red-500">{errorMessage}</p>
          </>
        }
        open={openDelete}
        setOpen={setOpenDelete}
        hasButton={true}
        buttonText="Yes"
        setTriggerFunc={() => {
          mutateDelete(selectedQuote);
        }}
        isDisable={false}
      />

      <ModalInput
        title="Edit Quotation"
        subtitle="Mengubah data ini akan mempengaruhi data lainya, lanjutkan?"
        content={<></>}
        open={openEdit}
        setOpen={setOpenEdit}
        hasButton={true}
        buttonText="Lanjutkan"
        setTriggerFunc={() => navigate(`/quotation/edit/${selectedQuote}`)}
        isDisable={false}
      />

      <RangeCalendar
        anchorElCalendar={anchorElCalendar}
        setAnchorElCalendar={setAnchorElCalendar}
        setSelectedDates={setSelectedDates}
        searchStartDate={searchStartDate}
        searchEndDate={searchEndDate}
        setSearchStartDate={setSearchStartDate}
        setSearchEndDate={setSearchEndDate}
      />
    </>
  );
};

export default QuotationList;
