import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  CircularProgress,
  InputAdornment,
  Pagination,
  PaginationItem,
  TextField,
} from "@mui/material";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { IoPencilOutline } from "react-icons/io5";
import ModalInput from "../../common/ModalInput";
import { deleteCosting, getCostingList } from "../../../services/costingApi";

const CostingList = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [fetchLimit, setFetchLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedCosting, setSelectedCosting] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["costing-list", search, page, fetchLimit],
    queryFn: () => getCostingList(search, page, fetchLimit),
  });

  const { mutate: mutateDelete } = useMutation({
    mutationFn: deleteCosting,
    onSuccess: () => {
      setOpenDelete(false);
      refetch();
    },
    onError: (err) => setErrorMessage(err.message.id),
  });

  useEffect(() => {
    setPage(1);
  }, [fetchLimit, search]);

  return (
    <div>
      <div className="flex mb-4 gap-2">
        <TextField
          fullWidth
          placeholder="Search by label name"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />

        <button
          onClick={() => navigate("/costing/add-costing")}
          className="w-1/4 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
        >
          <p>Add Costing</p>
          <IoMdAddCircleOutline className="text-[18px]" />
        </button>
      </div>
      <>
        <div className="rounded-md shadow-md">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <th className="p-3 text-left">No</th>
                <th className="p-3 text-left">Label Name</th>
                <th className="p-3 text-left">Qty</th>
                <th className="p-3 text-left">Size</th>
                <th className="p-3 text-left">Material</th>
                <th className="p-3 text-left">Qty Material</th>
                <th className="p-3 text-left">Price</th>
                <th className="p-3 text-left">Costing Price Per Qty</th>
                <th className="p-3 text-left">Total</th>
                <th className="p-3 text-left">Action</th>
              </thead>
              <tbody>
                {data && data.data && data.data.length > 0 && !isFetching ? (
                  data.data.map((item, i) => {
                    return item.material.map((mat, matIndex) => {
                      return (
                        <tr className="border-t border-l border-2" key={i}>
                          {matIndex === 0 ? (
                            <td
                              className="p-3 border-t-0 border-b-0 border-2"
                              rowSpan={item.material.length}
                            >
                              {(page - 1) * fetchLimit + (i + 1)}
                            </td>
                          ) : null}
                          {matIndex === 0 ? (
                            <td
                              className="p-3 border-t-0 border-b-0 border-2 text-blue-500 cursor-pointer hover:text-slate-500"
                              rowSpan={item.material.length}
                              onClick={() =>
                                navigate(`/costing/detail/${item.id}`)
                              }
                            >
                              {item.label_name}
                            </td>
                          ) : null}
                          {matIndex === 0 ? (
                            <td
                              className="p-3 border-t-0 border-b-0 border-2"
                              rowSpan={item.material.length}
                            >
                              {item.qty}
                            </td>
                          ) : null}
                          {matIndex === 0 ? (
                            <td
                              className="p-3 border-t-0 border-b-0 border-2"
                              rowSpan={item.material.length}
                            >
                              {item.size}
                            </td>
                          ) : null}
                          <td className="p-3">{mat.material_name}</td>
                          <td className="p-3">{mat.qty}</td>
                          <td className="p-3">
                            Rp{(mat.price * mat.qty).toLocaleString()}
                          </td>
                          {matIndex === 0 ? (
                            <td
                              className="p-3 border-t-0 border-b-0 border-2"
                              rowSpan={item.material.length}
                            >
                              Rp
                              {(item.qty && item.qty > 0
                                ? Number(
                                    parseFloat(
                                      item.total_price / item.qty
                                    ).toFixed(4)
                                  )
                                : 0
                              ).toLocaleString()}
                            </td>
                          ) : null}
                          {matIndex === 0 ? (
                            <td
                              className="p-3 border-t-0 border-b-0 border-2"
                              rowSpan={item.material.length}
                            >
                              Rp{item.total_price.toLocaleString()}
                            </td>
                          ) : null}
                          {matIndex === 0 ? (
                            <td
                              className="p-3 text-center"
                              rowSpan={item.material.length}
                            >
                              <div className="flex gap-2 items-center justify-center">
                                <FaTrash
                                  onClick={() => {
                                    if (!item.is_sync) {
                                      setOpenDelete(true);
                                      setSelectedCosting(item.id);
                                    }
                                  }}
                                  className={`border w-[24px] h-full rounded-md p-1 ${
                                    item.is_sync
                                      ? "text-gray-300"
                                      : "text-red-500 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                                  }`}
                                />
                                <IoPencilOutline
                                  onClick={() => {
                                    if (item.is_sync) {
                                      setOpenEdit(true);
                                      setSelectedCosting(item.id);
                                    } else {
                                      navigate(`/costing/edit/${item.id}`);
                                    }
                                  }}
                                  className="text-blue-500 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                                />
                              </div>
                            </td>
                          ) : null}
                        </tr>
                      );
                    });
                  })
                ) : !isFetching ? (
                  <tr className="border-t border-l border-2">
                    <td colSpan={8} className="py-3 text-center">
                      Data costing tidak ditemukan
                    </td>
                  </tr>
                ) : (
                  <tr className="border-t border-l border-2">
                    <td colSpan={8} className="py-3 text-center">
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {data && data.count > 0 ? (
            <div className="w-full flex justify-between pb-8 px-8">
              <button
                onClick={() => {
                  if (page > 1) setPage(page - 1);
                }}
                disabled={page === 1}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowLeftLong /> Previous
              </button>
              <Pagination
                hideNextButton
                hidePrevButton
                shape="rounded"
                page={page}
                count={Math.ceil(data.count / fetchLimit)}
                onChange={(e, page) => setPage(page)}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
              <button
                onClick={() => {
                  if (page < Math.ceil(data.count / fetchLimit))
                    setPage(page + 1);
                }}
                disabled={page === Math.ceil(data.count / fetchLimit)}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowRightLong /> Next
              </button>
            </div>
          ) : null}
        </div>
        {data && data.count > 0 ? (
          <div className="mt-4 text-xs flex gap-3 items-center">
            <p>Show Table</p>
            <p
              onClick={() => setFetchLimit(10)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 10 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              10
            </p>
            <p
              onClick={() => setFetchLimit(50)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 50 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              50
            </p>
            <p
              onClick={() => setFetchLimit(100)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 100 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              100
            </p>
          </div>
        ) : null}
      </>

      <ModalInput
        title="Hapus Costing"
        subtitle="Apakah anda yakin ingin menghapus costing ini?"
        content={
          <>
            <p className="text-red-500">{errorMessage}</p>
          </>
        }
        open={openDelete}
        setOpen={setOpenDelete}
        hasButton={true}
        buttonText="Yes"
        setTriggerFunc={() => {
          mutateDelete(selectedCosting);
        }}
        isDisable={false}
      />

      <ModalInput
        title="Edit Costing"
        subtitle="Mengubah data ini akan mempengaruhi data lainya, lanjutkan?"
        content={<></>}
        open={openEdit}
        setOpen={setOpenEdit}
        hasButton={true}
        buttonText="Lanjutkan"
        setTriggerFunc={() => navigate(`/costing/edit/${selectedCosting}`)}
        isDisable={false}
      />
    </div>
  );
};

export default CostingList;
