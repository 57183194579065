import {
  CircularProgress,
  Collapse,
  FormControl,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  TextField,
} from "@mui/material";
import {
  FaArrowRightLong,
  FaArrowLeftLong,
  FaClipboardList,
} from "react-icons/fa6";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import { Search } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { MdCalendarMonth } from "react-icons/md";
import RangeCalendar from "../../common/RangeCalendar";
import { useNavigate } from "react-router-dom";
import { getScheduleList } from "../../../services/scheduleApi";
import { format } from "date-fns";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import moment from "moment";
import * as XLSX from "xlsx";
import { IoMdDownload } from "react-icons/io";
import PrintInternalCode from "./PrintInternalCode";
import { getMCPrinting } from "../../../services/SpkApi";

const ScheduleList = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [anchorElCalendarSpk, setAnchorElCalendarSpk] = useState(null);
  const [selectedDates, setSelectedDates] = useState(["", ""]);
  const [selectedDatesSpk, setSelectedDatesSpk] = useState(["", ""]);
  const [searchStartDate, setSearchStartDate] = useState(null);
  const [searchEndDate, setSearchEndDate] = useState(null);
  const [searchStartDateSpk, setSearchStartDateSpk] = useState(null);
  const [searchEndDateSpk, setSearchEndDateSpk] = useState(null);
  const [collapseArr, setCollapseArr] = useState([]);
  const [type, setType] = useState(0);
  const [isPressedExport, setIsPressedExport] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [selectedCode, setSelectedCode] = useState(null);
  const [filter, setFilter] = useState("");

  const handleChangeCollapse = (index, toggle) => {
    var temp = [...collapseArr];
    temp[index] = toggle;
    setCollapseArr(temp);
  };

  const { data: dataMCPrinting } = useQuery({
    queryKey: ["mc-printing"],
    queryFn: () => getMCPrinting(),
  });

  const exportXlsx = () => {
    const rows = [
      {
        schedule_date: "",
        a: "Plate",
        b: "Die Cut",
        c: "Material",
        d: "",
        e: "",
        f: "",
        g: "",
        h: "",
        i: "",
        j: "",
        k: "",
        l: "",
        m: "Qty Order",
        n: "Qty Production",
        o: "Under/Over",
        p: "Estimated",
        q: "Actual",
        r: "Estimated",
        s: "Actual",
        t: "Estimated",
        u: "Actual",
        v: "",
        w: "",
        x: "Press",
        y: "QC",
        z: "Press Action Time",
        aa: "QC Time",
        ab: "Production Note",
        ac: "QC Note",
        ad: "Material",
      },
      {
        schedule_date: "",
        a: "",
        b: "",
        c: "Paper",
        d: "BOPP",
        e: "Foil",
        f: "Tinta",
        g: "",
        h: "",
        i: "",
        j: "",
        k: "",
        l: "",
        m: "",
        n: "",
        o: "",
        p: "",
        q: "",
        r: "",
        s: "",
        t: "",
        u: "",
        v: "",
        w: "",
        x: "",
        y: "",
        z: "",
        aa: "",
        ab: "",
        ac: "",
        ad: "",
      },
    ];

    dataExport.data.map((sched) => {
      if (sched.detail)
        sched.detail.map((row, i) => {
          rows.push({
            schedule_date: format(new Date(sched.schedule_date), "dd-MM-yyyy"),
            a: row.plate ? "Ready" : "Not Ready",
            b: row.die_cut ? "Ready" : "Not Ready",
            c: row.material_paper ? "Ready" : "Not Ready",
            d: row.material_bopp ? "Ready" : "Not Ready",
            e: row.material_foil ? "Ready" : "Not Ready",
            f: row.material_tinta ? "Ready" : "Not Ready",
            g: row.customer,
            h: row.no_spk,
            i: format(Date(row.due_date), "dd-MM-yyyy"),
            j: row.isp ? format(new Date(row.isp), "dd-MM-yyyy") : "-",
            k: row.label_name,
            l: row.gear,
            m: row.quantity_order,
            n: row.quantity_production,
            o: row.quantity,
            p: row.size_est,
            q: row.size_act,
            r: row.run_est,
            s: row.run_act,
            t: row.time_est,
            u: row.time_act,
            v: row.mc_printing,
            w: row.type,
            x: row.status_press,
            y: row.status_qc,
            z: row.action_time,
            aa: row.qc_time,
            ab: row.production_note,
            ac: row.qc_note,
            ad: row.material_name.join(", "),
          });
        });
    });

    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "Daftar Schedule " + moment().format("DD-MM-YYYY")
    );

    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          "Tanggal Schedule",
          "Tooling Control",
          "",
          "",
          "",
          "",
          "",
          "Customer",
          "No. SPK",
          "Tanggal Jatuh Tempo",
          "ISP Confirmation",
          "Nama Label",
          "Gear",
          "Qty",
          "",
          "",
          "Size",
          "",
          "Run (m)",
          "",
          "Time (HR)",
          "",
          "M/C Printing",
          "Type",
          "Status",
          "",
          "Action Time",
          "",
          "Note",
          "",
          "Material",
        ],
      ],
      {
        origin: "A1",
      }
    );

    if (!worksheet["!merges"]) worksheet["!merges"] = [];
    worksheet["!merges"].push(
      XLSX.utils.decode_range("A1:A3"),
      XLSX.utils.decode_range("B1:F1"),
      XLSX.utils.decode_range("B2:B3"),
      XLSX.utils.decode_range("C2:C3"),
      XLSX.utils.decode_range("D2:G2"),
      XLSX.utils.decode_range("H1:H3"),
      XLSX.utils.decode_range("I1:I3"),
      XLSX.utils.decode_range("J1:J3"),
      XLSX.utils.decode_range("K1:K3"),
      XLSX.utils.decode_range("L1:L3"),
      XLSX.utils.decode_range("M1:M3"),
      XLSX.utils.decode_range("N1:P3"),
      XLSX.utils.decode_range("N2:N3"),
      XLSX.utils.decode_range("O2:O3"),
      XLSX.utils.decode_range("P2:P3"),
      XLSX.utils.decode_range("Q1:R1"),
      XLSX.utils.decode_range("Q2:Q3"),
      XLSX.utils.decode_range("R2:R3"),
      XLSX.utils.decode_range("S1:T1"),
      XLSX.utils.decode_range("S2:S3"),
      XLSX.utils.decode_range("T2:T3"),
      XLSX.utils.decode_range("U1:V1"),
      XLSX.utils.decode_range("U2:U3"),
      XLSX.utils.decode_range("V2:V3"),
      XLSX.utils.decode_range("W1:W3"),
      XLSX.utils.decode_range("X1:X3"),
      XLSX.utils.decode_range("Y1:Z1"),
      XLSX.utils.decode_range("Y2:Y3"),
      XLSX.utils.decode_range("Z2:Z3"),
      XLSX.utils.decode_range("AA1:AB1"),
      XLSX.utils.decode_range("AA2:AA3"),
      XLSX.utils.decode_range("AB2:AB3"),
      XLSX.utils.decode_range("AC1:AD1"),
      XLSX.utils.decode_range("AC2:AC3"),
      XLSX.utils.decode_range("AD2:AD3"),
      XLSX.utils.decode_range("AE1:AE3")
    );

    XLSX.writeFile(
      workbook,
      "Daftar Schedule " + moment().format("DD_MM_YYYY") + ".xlsx",
      { compression: true }
    );

    setIsPressedExport(false);
  };

  const { data: data, isFetching } = useQuery({
    queryKey: [
      "schedule-list",
      search,
      page,
      type,
      fetchLimit,
      selectedDates[1],
      selectedDatesSpk[1],
      filter,
    ],
    queryFn: () =>
      getScheduleList({
        search: search,
        offset: page,
        limit: fetchLimit,
        due_start: selectedDates[0],
        due_end: selectedDates[0],
        spk_date_start: selectedDatesSpk[0],
        spk_date_end: selectedDatesSpk[1],
        type: type,
        machineName: filter,
      }),
    refetchOnWindowFocus: false,
  });

  const {
    data: dataExport,
    refetch,
    isFetching: isFetchingExport,
  } = useQuery({
    queryKey: ["do-export"],
    queryFn: () =>
      getScheduleList({
        search: search,
        offset: page,
        limit: fetchLimit,
        due_start: selectedDates[0],
        due_end: selectedDates[0],
        spk_date_start: selectedDatesSpk[0],
        spk_date_end: selectedDatesSpk[1],
        type: type,
        machineName: filter,
      }),
    enabled: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data && data.count > 0) {
      setCollapseArr(Array(data.count).fill(false));
    }
  }, [data]);

  useEffect(() => {
    if (dataExport && !isFetchingExport && isPressedExport) exportXlsx();
  }, [isFetchingExport]);

  useEffect(() => {
    setPage(1);
  }, [fetchLimit, search]);

  return (
    <div>
      <div className="flex gap-3 w-full">
        <p className="w-full">Search</p>
        <p className="w-full">Tanggal Jatuh Tempo</p>
        <p className="w-full">Tanggal SPK Schedule</p>
        <p className="w-full">M/C Printing</p>
        <p className="w-full"></p>
      </div>
      <div className="flex mb-4 gap-2">
        <TextField
          fullWidth
          placeholder="Search by No. SPK, Nama Customer"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <div className="border-2 rounded-md w-full flex items-center hover:bg-slate-100">
          <div
            className="flex justify-between items-center h-full w-full cursor-pointer px-2"
            onClick={(e) => setAnchorElCalendar(e.currentTarget)}
          >
            {selectedDates[0] === "" ? (
              <p className="text-gray-300">----/--/--</p>
            ) : (
              <p className="text-gray-300">{selectedDates[0]}</p>
            )}
            <p className="text-gray-300"> s/d </p>
            {selectedDates[1] === "" ? (
              <p className="text-gray-300">----/--/--</p>
            ) : (
              <p className="text-gray-300">{selectedDates[1]}</p>
            )}
            <MdCalendarMonth className="w-6 h-6" />
          </div>
        </div>
        <div className="w-full border-2 rounded-md flex items-center hover:bg-slate-100">
          <div
            className="flex justify-between items-center h-full w-full cursor-pointer px-2"
            onClick={(e) => setAnchorElCalendarSpk(e.currentTarget)}
          >
            {selectedDatesSpk[0] === "" ? (
              <p className="text-gray-300">----/--/--</p>
            ) : (
              <p className="text-gray-300">{selectedDatesSpk[0]}</p>
            )}
            <p className="text-gray-300"> s/d </p>
            {selectedDatesSpk[1] === "" ? (
              <p className="text-gray-300">----/--/--</p>
            ) : (
              <p className="text-gray-300">{selectedDatesSpk[1]}</p>
            )}
            <MdCalendarMonth className="w-6 h-6" />
          </div>
        </div>
        <FormControl fullWidth>
          <Select
            displayEmpty
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            renderValue={(selected) => {
              if (selected === "") {
                return <p className="text-gray-400">Pilih M/C Printing</p>;
              }
              return selected;
            }}
          >
            <MenuItem value="">
              <p>Tampilkan semua</p>
            </MenuItem>
            {dataMCPrinting?.map((item, i) => (
              <MenuItem key={i} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <button
          onClick={() => navigate("/schedule/take-job")}
          className="w-full flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
        >
          <FaClipboardList className="text-[18px]" />
          <p>Take A Job</p>
        </button>
      </div>
      <div className="w-full mb-4 flex justify-end">
        <button
          className="rounded-md p-2 px-8 border-2 mb-2 cursor-pointer hover:bg-slate-100 disabled:bg-gray-300 disabled:text-white disabled:cursor-default"
          disabled={isFetchingExport || !data || (data && data.count === 0)}
          onClick={() => {
            refetch();
            setIsPressedExport(true);
          }}
        >
          {isFetchingExport ? (
            <CircularProgress size={20} />
          ) : (
            "Export Schedule"
          )}
        </button>
      </div>
      <div className="grid grid-cols-4 mb-4">
        <div
          className={`border-2 rounded-l-md border-r-0 p-2 cursor-pointer hover:bg-gray-300 hover:text-black ${
            type === 0 ? "bg-[#18479D] text-white" : ""
          }`}
          onClick={() => setType(0)}
        >
          Semua
        </div>
        <div
          className={`border-2 border-r-0 p-2 cursor-pointer hover:bg-gray-300 hover:text-black ${
            type === 1 ? "bg-[#18479D] text-white" : ""
          }`}
          onClick={() => setType(1)}
        >
          Belum Dikerjakan
        </div>
        <div
          className={`border-2 border-r-0 p-2 cursor-pointer hover:bg-gray-300 hover:text-black ${
            type === 2 ? "bg-[#18479D] text-white" : ""
          }`}
          onClick={() => setType(2)}
        >
          Sedang Dikerjakan
        </div>
        <div
          className={`border-2 rounded-r-md p-2 cursor-pointer hover:bg-gray-300 hover:text-black ${
            type === 3 ? "bg-[#18479D] text-white" : ""
          }`}
          onClick={() => setType(3)}
        >
          Selesai
        </div>
      </div>
      <>
        {data && data.count > 0 && !isFetching ? (
          data.data.map((sched, i) => (
            <div className="rounded-md shadow-md mb-4" key={i}>
              <div className="border-2 border-gray-300 rounded-md p-3.5">
                <div className="flex items-center justify-between my-4">
                  <p className="w-full font-bold">
                    {format(new Date(sched.schedule_date), "dd MMMM yyyy")}
                  </p>
                  {collapseArr[i] ? (
                    <FaChevronUp
                      className="cursor-pointer hover:text-gray-300 w-[24px] h-full"
                      onClick={() => handleChangeCollapse(i, false)}
                    />
                  ) : (
                    <FaChevronDown
                      className="cursor-pointer hover:text-gray-300 w-[24px] h-full"
                      onClick={() => handleChangeCollapse(i, true)}
                    />
                  )}
                </div>
                <Collapse in={collapseArr[i]}>
                  <div className="rounded-md shadow-md">
                    <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
                      <table className="w-full">
                        <thead>
                          <tr className="bg-[#18479D] text-white font-bold text-center">
                            <td
                              className="p-3 border-r-2 border-white"
                              colSpan={6}
                            >
                              Tooling Control
                            </td>
                            <td
                              className="p-3 border-r-2 border-white"
                              rowSpan={3}
                            >
                              Customer
                            </td>
                            <td
                              className="p-3 border-r-2 border-white"
                              rowSpan={3}
                            >
                              No. SPK
                            </td>
                            <td
                              className="p-3 border-r-2 border-white"
                              rowSpan={3}
                            >
                              Tanggal Jatuh Tempo
                            </td>
                            <td
                              className="p-3 border-r-2 border-white"
                              rowSpan={3}
                            >
                              ISP Confirmation
                            </td>
                            <td
                              className="p-3 border-r-2 border-white"
                              rowSpan={3}
                            >
                              Nama Label
                            </td>
                            <td
                              className="p-3 border-r-2 border-white"
                              rowSpan={3}
                            >
                              Gear
                            </td>
                            <td
                              className="p-3 border-r-2 border-white"
                              colSpan={3}
                            >
                              Qty
                            </td>
                            <td
                              className="p-3 border-r-2 border-white"
                              colSpan={2}
                            >
                              Size
                            </td>
                            <td
                              className="p-3 border-r-2 border-white"
                              colSpan={2}
                            >
                              Run (m)
                            </td>
                            <td
                              className="p-3 border-r-2 border-white"
                              colSpan={2}
                            >
                              Time (HR)
                            </td>
                            <td
                              className="p-3 border-r-2 border-white"
                              rowSpan={3}
                            >
                              M/C Printing
                            </td>
                            <td
                              className="p-3 border-r-2 border-white"
                              rowSpan={3}
                            >
                              Type
                            </td>
                            <td
                              className="p-3 border-r-2 border-white"
                              colSpan={2}
                            >
                              Status
                            </td>
                            <td
                              className="p-3 border-r-2 border-white"
                              colSpan={2}
                            >
                              Action Time
                            </td>
                            <td className="p-3" colSpan={2}>
                              Note
                            </td>
                          </tr>
                          <tr className="font-bold text-center">
                            <td
                              className="p-3 border-r-2 border-gray-500"
                              rowSpan={2}
                            >
                              Plate
                            </td>
                            <td
                              className="p-3 border-r-2 border-gray-500"
                              rowSpan={2}
                            >
                              Die Cut
                            </td>
                            <td
                              className="p-3 border-b-2 border-r-2 border-gray-500"
                              colSpan={4}
                            >
                              Material
                            </td>
                            <td
                              className="p-3 border-r-2 border-gray-500"
                              rowSpan={2}
                            >
                              Qty Order
                            </td>
                            <td
                              className="p-3 border-r-2 border-gray-500"
                              rowSpan={2}
                            >
                              Qty Production
                            </td>
                            <td
                              className="p-3 border-r-2 border-gray-500"
                              rowSpan={2}
                            >
                              Under/Over
                            </td>
                            <td
                              className="p-3 border-r-2 border-gray-500"
                              rowSpan={2}
                            >
                              Estimated
                            </td>
                            <td
                              className="p-3 border-r-2 border-gray-500"
                              rowSpan={2}
                            >
                              Actual
                            </td>
                            <td
                              className="p-3 border-r-2 border-gray-500"
                              rowSpan={2}
                            >
                              Estimated
                            </td>
                            <td
                              className="p-3 border-r-2 border-gray-500"
                              rowSpan={2}
                            >
                              Actual
                            </td>
                            <td
                              className="p-3 border-r-2 border-gray-500"
                              rowSpan={2}
                            >
                              Estimated
                            </td>
                            <td
                              className="p-3 border-r-2 border-gray-500"
                              rowSpan={2}
                            >
                              Actual
                            </td>
                            <td
                              className="p-3 border-r-2 border-gray-500"
                              rowSpan={2}
                            >
                              Press
                            </td>
                            <td
                              className="p-3 border-r-2 border-gray-500"
                              rowSpan={2}
                            >
                              QC
                            </td>
                            <td
                              className="p-3 border-r-2 border-gray-500"
                              rowSpan={2}
                            >
                              Press Action Time
                            </td>
                            <td
                              className="p-3 border-r-2 border-gray-500"
                              rowSpan={2}
                            >
                              QC Time
                            </td>
                            <td
                              className="p-3 border-r-2 border-gray-500"
                              rowSpan={2}
                            >
                              Production Note
                            </td>
                            <td className="p-3" rowSpan={2}>
                              QC Note
                            </td>
                          </tr>
                          <tr className="font-bold text-center">
                            <td className="p-3 border-r-2 border-gray-500">
                              Paper
                            </td>
                            <td className="p-3 border-r-2 border-gray-500">
                              BOPP
                            </td>
                            <td className="p-3 border-r-2 border-gray-500">
                              Foil
                            </td>
                            <td className="p-3">Tinta</td>
                          </tr>
                        </thead>
                        <tbody>
                          {sched.detail &&
                          sched.detail.length > 0 &&
                          !isFetching ? (
                            sched.detail.map((item, i) => {
                              return (
                                <tr
                                  className="border-t border-l border-2"
                                  key={i}
                                >
                                  <td className="p-3 text-left">
                                    {item.plate ? "Ready" : "Not Ready"}
                                  </td>
                                  <td className="p-3 text-left">
                                    {item.die_cut ? "Ready" : "Not Ready"}
                                  </td>
                                  <td className="p-3 text-left">
                                    {item.material_paper
                                      ? "Ready"
                                      : "Not Ready"}
                                  </td>
                                  <td className="p-3 text-left">
                                    {item.material_bopp ? "Ready" : "Not Ready"}
                                  </td>
                                  <td className="p-3 text-left">
                                    {item.material_foil ? "Ready" : "Not Ready"}
                                  </td>
                                  <td className="p-3 text-left">
                                    {item.material_tinta
                                      ? "Ready"
                                      : "Not Ready"}
                                  </td>
                                  <td className="p-3 text-left">
                                    {item.customer}
                                  </td>
                                  <td className="px-3">
                                    <div className="w-full items-center flex gap-2 align-middle">
                                      <p
                                        onClick={() =>
                                          navigate(
                                            `/schedule/detail/${item.id}`
                                          )
                                        }
                                        className="text-blue-500 hover:text-slate-500 cursor-pointer"
                                      >
                                        {item.no_spk}
                                      </p>
                                      <IoMdDownload
                                        onClick={() => {
                                          setIsDownload(true);
                                          setSelectedCode(item);
                                        }}
                                        className="text-blue-500 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                                      />
                                    </div>
                                  </td>
                                  <td className="p-3 text-left">
                                    {format(Date(item.due_date), "dd-MM-yyyy")}
                                  </td>
                                  <td className="p-3 text-left">
                                    {item.isp
                                      ? format(new Date(item.isp), "dd-MM-yyyy")
                                      : "-"}
                                  </td>
                                  <td className="p-3 text-left">
                                    {item.label_name}
                                  </td>
                                  <td className="p-3 text-left">{item.gear}</td>
                                  <td className="p-3 text-left">
                                    {item.quantity_order}
                                  </td>
                                  <td className="p-3 text-left">
                                    {item.quantity_production}
                                  </td>
                                  <td className="p-3 text-left">
                                    {item.quantity}
                                  </td>
                                  <td className="p-3 text-left">
                                    {Number(
                                      parseFloat(item.size_est).toFixed(4)
                                    )}
                                  </td>
                                  <td className="p-3 text-left">
                                    {Number(
                                      parseFloat(
                                        item.size_act ? item.size_act : 0
                                      ).toFixed(4)
                                    )}
                                  </td>
                                  <td className="p-3 text-left">
                                    {Number(
                                      parseFloat(item.run_est).toFixed(4)
                                    )}
                                  </td>
                                  <td className="p-3 text-left">
                                    {Number(
                                      parseFloat(
                                        item.run_act ? item.run_act : 0
                                      ).toFixed(4)
                                    )}
                                  </td>
                                  <td className="p-3 text-left">
                                    {Number(
                                      parseFloat(item.time_est).toFixed(4)
                                    )}
                                  </td>
                                  <td className="p-3 text-left">
                                    {Number(
                                      parseFloat(
                                        item.time_act ? item.time_act : 0
                                      ).toFixed(4)
                                    )}
                                  </td>
                                  <td className="p-3 text-left">
                                    {item.mc_printing}
                                  </td>
                                  <td className="p-3 text-left">{item.type}</td>
                                  <td className="p-3 text-left">
                                    {item.status_press}
                                  </td>
                                  <td className="p-3 text-left">
                                    {item.status_qc}
                                  </td>
                                  <td className="p-3 text-left">
                                    {item.action_time}
                                  </td>
                                  <td className="p-3 text-left">
                                    {item.qc_time}
                                  </td>
                                  <td className="p-3 text-left">
                                    {item.production_note}
                                  </td>
                                  <td className="p-3 text-left">
                                    {item.qc_note}
                                  </td>
                                </tr>
                              );
                            })
                          ) : !isFetching ? (
                            <tr className="border-t border-l border-2">
                              <td colSpan={29} className="py-3 text-center">
                                Data schedule tidak ditemukan
                              </td>
                            </tr>
                          ) : (
                            <tr className="border-t border-l border-2">
                              <td colSpan={29} className="py-3 text-center">
                                <CircularProgress size={20} />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          ))
        ) : isFetching ? (
          <CircularProgress size={20} />
        ) : (
          <p className="text-gray-300">Tidak ada data schedule</p>
        )}
      </>

      {data && data.count > 0 ? (
        <div className="w-full flex justify-between pb-8 px-8">
          <button
            onClick={() => {
              if (page > 1) setPage(page - 1);
            }}
            disabled={page === 1}
            className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
          >
            <FaArrowLeftLong /> Previous
          </button>
          <Pagination
            hideNextButton
            hidePrevButton
            shape="rounded"
            page={page}
            count={Math.ceil(data.count / fetchLimit)}
            onChange={(e, page) => setPage(page)}
            renderItem={(item) => (
              <PaginationItem
                slots={{
                  previous: ArrowBackIcon,
                  next: ArrowForwardIcon,
                }}
                {...item}
              />
            )}
          />
          <button
            onClick={() => {
              if (page < Math.ceil(data.count / fetchLimit)) setPage(page + 1);
            }}
            disabled={page === Math.ceil(data.count / fetchLimit)}
            className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
          >
            <FaArrowRightLong /> Next
          </button>
        </div>
      ) : null}
      {data && data.count > 0 ? (
        <div className="mt-4 text-xs flex gap-3 items-center">
          <p>Show Table</p>
          <p
            onClick={() => setFetchLimit(10)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 10 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            10
          </p>
          <p
            onClick={() => setFetchLimit(50)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 50 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            50
          </p>
          <p
            onClick={() => setFetchLimit(100)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 100 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            100
          </p>
        </div>
      ) : null}

      <RangeCalendar
        anchorElCalendar={anchorElCalendar}
        setAnchorElCalendar={setAnchorElCalendar}
        setSelectedDates={setSelectedDates}
        searchStartDate={searchStartDate}
        searchEndDate={searchEndDate}
        setSearchStartDate={setSearchStartDate}
        setSearchEndDate={setSearchEndDate}
      />
      <RangeCalendar
        anchorElCalendar={anchorElCalendarSpk}
        setAnchorElCalendar={setAnchorElCalendarSpk}
        setSelectedDates={setSelectedDatesSpk}
        searchStartDate={searchStartDateSpk}
        searchEndDate={searchEndDateSpk}
        setSearchStartDate={setSearchStartDateSpk}
        setSearchEndDate={setSearchEndDateSpk}
      />

      {selectedCode !== null ? (
        <PrintInternalCode
          data={selectedCode}
          isDownload={isDownload}
          setIsDownload={setIsDownload}
        />
      ) : null}
    </div>
  );
};

export default ScheduleList;
