const SpkCardQC = ({ curProj, isDetail }) => {
  return (
    <>
      {curProj ? (
        <>
          <hr className="my-2" />
          <div className="rounded-md shadow-md">
            <div className="block overflow-x-auto w-full rounded-md">
              <table className="w-full">
                <thead className="bg-[#18479D] text-white border-t border-b border-2">
                  <th className="p-3 text-left">QC Process</th>
                  <th className="p-3 text-left">Est (HR)</th>
                  {isDetail ? (
                    <th className="p-3 text-left">Actual (HR)</th>
                  ) : null}
                </thead>
                <tbody>
                  {curProj.label[0].roll !== 0 ? (
                    <tr className="border-t border-l border-2">
                      <td className="p-3">Sliting</td>
                      <td className="p-3">
                        {Number(
                          parseFloat(
                            isDetail
                              ? curProj.qc.sliting
                              : curProj.qc[0].sliting
                          ).toFixed(4)
                        )}
                      </td>
                      {isDetail ? (
                        <td className="p-3">
                          {Number(
                            parseFloat(curProj.qc.sliting_act).toFixed(4)
                          )}
                        </td>
                      ) : null}
                    </tr>
                  ) : null}
                  {curProj.label[0].lembar !== 0 ? (
                    <tr className="border-t border-l border-2">
                      <td className="p-3">Sheeting</td>
                      <td className="p-3">
                        {Number(
                          parseFloat(
                            isDetail
                              ? curProj.qc.sheeting
                              : curProj.qc[0].sheeting
                          ).toFixed(4)
                        )}
                      </td>
                      {isDetail ? (
                        <td className="p-3">
                          {Number(
                            parseFloat(curProj.qc.sheeting_act).toFixed(4)
                          )}
                        </td>
                      ) : null}
                    </tr>
                  ) : null}
                  <tr className="border-t border-l border-2">
                    <td className="p-3">Cutting</td>
                    <td className="p-3">
                      {Number(
                        parseFloat(
                          isDetail ? curProj.qc.cutting : curProj.qc[0].cutting
                        ).toFixed(4)
                      )}
                    </td>
                    {isDetail ? (
                      <td className="p-3">
                        {Number(parseFloat(curProj.qc.cutting_act).toFixed(4))}
                      </td>
                    ) : null}
                  </tr>
                  <tr className="border-t border-l border-2">
                    <td className="p-3">Packing Roll</td>
                    <td className="p-3">
                      {Number(
                        parseFloat(
                          isDetail
                            ? curProj.qc.packing_roll
                            : curProj.qc[0].packing_roll
                        ).toFixed(4)
                      )}
                    </td>
                    {isDetail ? (
                      <td className="p-3">
                        {Number(
                          parseFloat(curProj.qc.packing_roll_act).toFixed(4)
                        )}
                      </td>
                    ) : null}
                  </tr>
                  <tr className="border-t border-l border-2">
                    <td className="p-3">Packing Sheet</td>
                    <td className="p-3">
                      {Number(
                        parseFloat(
                          isDetail
                            ? curProj.qc.packing_sheet
                            : curProj.qc[0].packing_sheet
                        ).toFixed(4)
                      )}
                    </td>
                    {isDetail ? (
                      <td className="p-3">
                        {Number(
                          parseFloat(curProj.qc.packing_sheet_act).toFixed(4)
                        )}
                      </td>
                    ) : null}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default SpkCardQC;
