import { Autocomplete, InputAdornment, Switch, TextField } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { MdCalendarMonth } from "react-icons/md";
import SingleCalendar from "../../common/SingleCalendar";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { createCustomerInvoice } from "../../../services/customerInvoiceApi";
import { getDoDetail, getDoList } from "../../../services/deliveryOrder";
import { addDays } from "date-fns";
import { getCustomerList } from "../../../services/customerManagementApi";

const AddCustomerInvoice = () => {
  const navigate = useNavigate();

  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [anchorElCalendarDue, setAnchorElCalendarDue] = useState(null);
  const [selectedDo, setSelectedDo] = useState(null);
  const [freight, setFreight] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [invoiceNum, setInvoiceNum] = useState(
    "CUSTINV/" +
      new Date().getFullYear() +
      "/" +
      Date.now().toString(36).toUpperCase()
  );
  const [faktur, setFaktur] = useState("");
  const [exchangeDate, setExchangeDate] = useState(null);
  const [ppn, setPpn] = useState(false);
  const [top, setTop] = useState(0);
  const [formattedDiscount, setFormattedDiscount] = useState("");
  const [formattedFreightCharge, setFormattedFreightCharge] = useState("");

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleDiscountChange = (e) => {
    const value = e.target.value.replace(/,/g, "");
    if (!isNaN(Number(value))) {
      const numberValue = Number(value);
      setDiscount(numberValue);
      setFormattedDiscount(formatNumber(value));
    }
  };

  const handleFreightChargeChange = (e) => {
    const value = e.target.value.replace(/,/g, "");
    if (!isNaN(Number(value))) {
      const numberValue = Number(value);
      setFreight(numberValue);
      setFormattedFreightCharge(formatNumber(value));
    }
  };

  const getPrice = () => {
    return selectedDo.label.reduce(
      (acc, num) => acc + (num.price ? num.price : 0),
      0
    );
  };

  const { data: dataDo } = useQuery({
    queryKey: ["do-list"],
    queryFn: () =>
      getDoList({
        limit: 10000,
        offset: 1,
        search: "",
        start: "",
        end: "",
        carrier: 0,
        startDelivery: "",
        endDelivery: "",
      }),
  });

  const { mutate: mutateCustomerList } = useMutation({
    mutationFn: getCustomerList,
    onSuccess: (data) => {
      setPpn(data.data[0].is_pkp);
    },
  });

  const { data: dataDetaildo } = useQuery({
    queryKey: ["do-detail", selectedDo],
    enabled: selectedDo !== null,
    queryFn: () => getDoDetail(selectedDo.id),
  });

  const { mutate: mutateNewInvoice } = useMutation({
    mutationFn: createCustomerInvoice,
    onSuccess: () => {
      navigate("/customer-invoice");
    },
    onError: (err) => setErrorMessage(err.message.id),
  });

  const checkIsValid = () => {
    return (
      invoiceDate !== null &&
      invoiceNum !== "" &&
      selectedDo !== null &&
      top !== 0 &&
      faktur !== ""
    );
  };

  const handleSubmit = () => {
    const body = {
      invoice_date: format(new Date(invoiceDate), "yyyy-MM-dd"),
      invoice_number: invoiceNum,
      do_id: selectedDo.id,
      total_price: getPrice(),
      grand_total: (getPrice() - discount) * (ppn ? 1.11 : 1) + freight,
      freight_charge: freight,
      top: top,
      faktur: faktur,
      isPPN: ppn,
      discount: discount,
    };
    mutateNewInvoice(body);
  };

  useEffect(() => {
    if (selectedDo) {
      mutateCustomerList({
        name: selectedDo.customer_name,
        start_date: "",
        end_date: "",
        pkp: 0,
        offset: 1,
        limit: 1,
      });
    }
  }, [selectedDo]);

  return (
    <>
      <p>Tanggal Invoice</p>
      <div className="mb-4 border-2 rounded-md w-full flex items-center hover:bg-slate-100">
        <div
          className="flex justify-between items-center h-full w-full cursor-pointer p-2"
          onClick={(e) => setAnchorElCalendar(e.currentTarget)}
        >
          {invoiceDate === null ? (
            <p>----/--/--</p>
          ) : (
            <p>{format(new Date(invoiceDate), "yyyy-MM-dd")}</p>
          )}
          <MdCalendarMonth className="w-6 h-6" />
        </div>
      </div>
      <TextField
        fullWidth
        sx={{ marginBottom: 2 }}
        label="No. Invoice Customer"
        value={invoiceNum}
        disabled
      />
      <Autocomplete
        sx={{ width: "100%", marginBottom: 2 }}
        disablePortal
        value={selectedDo}
        onChange={(_, option) => {
          setSelectedDo(option);
        }}
        options={dataDo && dataDo.data ? dataDo.data : []}
        getOptionLabel={(option) =>
          option.do_number
            ? option.do_number + " - " + option.customer_name
            : "-"
        }
        renderInput={(params) => <TextField {...params} label="No. DO" />}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "reset") {
            setSelectedDo(null);
            return;
          }
        }}
      />
      <TextField
        fullWidth
        sx={{ marginBottom: 2 }}
        label="Nama Customer"
        value={dataDetaildo ? dataDetaildo.customer_name : ""}
        disabled
      />
      <TextField
        fullWidth
        sx={{ marginBottom: 2 }}
        value={dataDetaildo ? dataDetaildo.spk_no : ""}
        label="No. PO Customer"
        disabled
      />
      <TextField
        fullWidth
        sx={{ marginBottom: 2 }}
        value={faktur}
        onChange={(e) => {
          setFaktur(e.target.value);
        }}
        label="No. Faktur Pajak"
      />
      <TextField
        fullWidth
        sx={{ marginBottom: 2 }}
        value={top}
        onChange={(e) => {
          if (!isNaN(Number(e.target.value))) {
            setTop(Number(e.target.value));
          }
        }}
        label="TOP"
        InputProps={{
          endAdornment: <InputAdornment position="end">DAYS</InputAdornment>,
        }}
      />
      <div className="mb-4 flex justify-between">
        <p>Customer PPN</p>
        <Switch disabled checked={ppn} />
      </div>
      <div className="mt-2">
        <p className="text-xl font-bold">Added Label Name</p>
        <hr className="my-2" />
        <div className="rounded-md shadow-md">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <th className="p-3 text-left">No</th>
                <th className="p-3 text-left">Label Name</th>
                <th className="p-3 text-left">Qty</th>
                <th className="p-3 text-left">Price</th>
                <th className="p-3 text-left">Subtotal</th>
              </thead>
              <tbody>
                {selectedDo &&
                selectedDo.label &&
                selectedDo.label.length > 0 ? (
                  selectedDo.label.map((item, i) => (
                    <tr className="border-t-2 border-b-2" key={i}>
                      <td className="p-3 text-left">{i + 1}</td>
                      <td className="p-3 text-left">{item.label_name}</td>
                      <td className="p-3 text-left">{item.quantity}</td>
                      <td className="p-3 text-left">
                        Rp {(item.price / item.quantity).toLocaleString()}
                      </td>
                      <td className="p-3 text-left">
                        Rp {item.price.toLocaleString()}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="border-t-2 border-b-2">
                    <td className="p-3 text-center text-gray-500" colSpan={4}>
                      Belum ada label
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-wrap basis-1/3 justify-end">
        {dataDetaildo &&
        selectedDo &&
        selectedDo.label &&
        selectedDo.label.length > 0 ? (
          <>
            <div className="font-bold float-right">
              <div className="flex mb-4">
                <p className="w-1/3">Total</p>
                <p className="w-fit mr-2">:</p>
                <p className="w-64">
                  Rp
                  {getPrice().toLocaleString()}
                </p>
              </div>
              <div className="flex mb-4 items-center">
                <p className="w-1/3">Discount</p>
                <p className="w-fit mr-2">:</p>
                <TextField
                  className="w-64"
                  value={formattedDiscount}
                  disabled={!selectedDo}
                  onChange={handleDiscountChange}
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{
                          fontWeight: "bold",
                          marginRight: 0,
                          fontFamily: "Montserrat",
                        }}
                      >
                        <p className="font-bold text-black">Rp</p>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    style: {
                      fontWeight: "bold",
                      textDecoration: "underline",
                      fontFamily: "Montserrat",
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      border: "none",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottom: "none",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: "none",
                    },
                  }}
                />
              </div>
              <div className="flex mb-4">
                <p className="w-1/3">DPP</p>
                <p className="w-fit mr-2">:</p>
                <p className="w-64">
                  Rp
                  {(selectedDo && selectedDo.label.length > 0
                    ? getPrice() - discount
                    : 0
                  ).toLocaleString()}
                </p>
              </div>
              {ppn ? (
                <div className="flex mb-4">
                  <p className="w-1/3">PPN</p>
                  <p className="w-fit mr-2">:</p>
                  <p className="w-64">
                    Rp
                    {(selectedDo && selectedDo.label.length > 0
                      ? (getPrice() - discount) * 0.11
                      : 0
                    ).toLocaleString()}
                  </p>
                </div>
              ) : null}
              <div className="flex mb-4 items-center">
                <p className="w-1/3">Freight Charge</p>
                <p className="w-fit mr-2">:</p>
                <TextField
                  className="w-64"
                  value={formattedFreightCharge}
                  disabled={!selectedDo}
                  onChange={handleFreightChargeChange}
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{
                          fontWeight: "bold",
                          marginRight: 0,
                          fontFamily: "Montserrat",
                        }}
                      >
                        <p className="font-bold text-black">Rp</p>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    style: {
                      fontWeight: "bold",
                      textDecoration: "underline",
                      fontFamily: "Montserrat",
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      border: "none",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottom: "none",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: "none",
                    },
                  }}
                />
              </div>
              <div className="flex mb-4">
                <p className="w-1/3">Grand Total</p>
                <p className="w-fit mr-2">:</p>
                <p className="w-64">
                  Rp
                  {(
                    (getPrice() - discount) * (ppn ? 1.11 : 1) +
                    freight
                  ).toLocaleString()}
                </p>
              </div>
            </div>
          </>
        ) : null}
      </div>

      <p className="text-red-500 mb-4 text-right m-4">{errorMessage}</p>
      <div className="w-full">
        <div className="flex justify-end w-1/3 gap-2 float-right">
          <button
            type="button"
            className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
            onClick={() => navigate("/customer-invoice")}
          >
            Cancel
          </button>
          <button
            className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
            disabled={!checkIsValid()}
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </div>
      <SingleCalendar
        anchorElCalendarStart={anchorElCalendar}
        setAnchorElCalendarStart={setAnchorElCalendar}
        startDate={invoiceDate}
        setStartDate={setInvoiceDate}
      />
      <SingleCalendar
        anchorElCalendarStart={anchorElCalendarDue}
        setAnchorElCalendarStart={setAnchorElCalendarDue}
        startDate={exchangeDate}
        setStartDate={setExchangeDate}
      />
    </>
  );
};

export default AddCustomerInvoice;
