import axios from "axios";
import Cookies from "js-cookie";
import { GeneratePublicToken } from "../utils/GeneratePublicToken";

const token = () => Cookies.get("accessToken");

export const getReturCustomerList = async ({
  limit,
  offset,
  search,
  start,
  end,
  supplier,
}) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/list-retur-customer?limit=${limit}&offset=${offset}&supplier=${supplier}&return_no=${search}&start_date=${start}&end_date=${end}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const setNewReturCustomer = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/retur-customer`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getDetailReturCustomer = async (id) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/detail-retur-customer?id=${id}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const setMasterDataReturCustomer = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/master-retur`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getMasterDataReturCustomer = async ({ type }) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/get-master?type=${type}`,
      method: "GET",
      data: type,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getDeliveryOrder = async (spkId) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/list-do?spk_id=${spkId}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};
