import {
  CircularProgress,
  FormControl,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { FaArrowRightLong, FaArrowLeftLong, FaTrash } from "react-icons/fa6";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import { IoPencilOutline } from "react-icons/io5";
import { IoMdAddCircleOutline, IoMdPerson } from "react-icons/io";
import { Search } from "@mui/icons-material";
import { useMutation, useQuery } from "@tanstack/react-query";
import ModalInput from "../../common/ModalInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { MdCalendarMonth } from "react-icons/md";
import RangeCalendar from "../../common/RangeCalendar";
import { format } from "date-fns";
import {
  createCustomer,
  deleteCustomer,
  getCustomerList,
  updateCustomer,
} from "../../../services/customerManagementApi";
import AddCustomerModalContent from "./AddCustomerModalContent";
import EditCustomerModalContent from "./EditCustomerModalContent";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object({
  file: yup.mixed().nullable(),
  json: yup.object({
    customer_name: yup
      .string()
      .required()
      .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
    date_join: yup
      .string()
      .required()
      .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
    phone: yup
      .string()
      .required()
      .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
    is_pkp: yup.boolean(),
    npwp: yup
      .string()
      .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
    top: yup
      .string()
      .required()
      .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
    franco: yup
      .string()
      .required()
      .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
    bill_to: yup
      .string()
      .required()
      .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
    pic_name: yup
      .string()
      .required()
      .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
    pic_phone: yup
      .string()
      .required()
      .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
    pic_email: yup
      .string()
      .required()
      .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
    notes: yup
      .string()
      .required()
      .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  }),
});

const CustomerList = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(0);
  const [openAdd, setOpenAdd] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [refetchList, setRefetchList] = useState(true);
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [selectedDates, setSelectedDates] = useState(["", ""]);
  const [searchStartDate, setSearchStartDate] = useState(null);
  const [searchEndDate, setSearchEndDate] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");

  const dataURLtoFile = (dataurl) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], selectedFileName, { type: mime });
  };

  const formik = useFormik({
    initialValues: {
      file: null,
      json: {
        customer_name: "",
        date_join: "",
        phone: "",
        is_pkp: false,
        npwp: "",
        top: "",
        franco: "",
        bill_to: "",
        pic_name: "",
        pic_phone: "",
        pic_email: "",
        notes: "",
      },
    },
    validationSchema,
    validateOnMount: true,
  });

  const {
    mutate: mutateCustomerList,
    data,
    isPending: isPendingList,
  } = useMutation({
    mutationFn: getCustomerList,
    onSuccess: () => {},
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  const { mutate: mutateNewCustomer, isPending: isPendingAdd } = useMutation({
    mutationFn: createCustomer,
    onSuccess: () => {
      setIsAdd(false);
      setOpenAdd(false);
      setRefetchList(true);
    },
    onError: (err) => {
      setErrorMessage(
        err.message.id
          ? err.message.id
          : err.message === "Network Error"
          ? "Ukuran gambar terlalu besar"
          : err.message
      );
      setIsAdd(false);
    },
  });

  const { mutate: mutateUpdateCustomer, isPending: isPendingEdit } =
    useMutation({
      mutationFn: updateCustomer,
      onSuccess: () => {
        setIsEdit(false);
        setOpenDetail(false);
        setRefetchList(true);
      },
      onError: (err) => {
        setErrorMessage(
          err.message.id
            ? err.message.id
            : err.message === "Network Error"
            ? "Ukuran gambar terlalu besar"
            : err.message
        );
        setIsEdit(false);
      },
    });

  const { mutate: mutateDeleteCustomer } = useMutation({
    mutationFn: deleteCustomer,
    onSuccess: () => {
      setErrorMessage("");
      setOpenDelete(false);
      setRefetchList(true);
      setIsDelete(false);
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
      setIsDelete(false);
    },
  });

  useEffect(() => {
    if (data) {
      setRefetchList(false);
    }
  }, [data]);

  useEffect(() => {
    formik.validateForm();
  }, [formik.values]);

  useEffect(() => {
    setPage(1);
  }, [fetchLimit, search, filter]);

  useEffect(() => {
    const body = {
      name: search,
      start_date: selectedDates[0],
      end_date: selectedDates[1],
      pkp: filter,
      offset: page,
      limit: fetchLimit,
    };
    mutateCustomerList(body);
  }, [search, selectedDates, filter, page, fetchLimit, refetchList]);

  useEffect(() => {
    if (openAdd || !openDetail)
      formik.setValues({
        file: null,
        json: {
          customer_name: "",
          date_join: "",
          phone: "",
          is_pkp: false,
          npwp: "",
          top: "",
          franco: "",
          bill_to: "",
          pic_name: "",
          pic_phone: "",
          pic_email: "",
          notes: "",
        },
      });
  }, [openAdd, openDetail]);

  useEffect(() => {
    if (isAdd) {
      mutateNewCustomer(formik.values);
    }
  }, [isAdd]);

  useEffect(() => {
    if (isEdit) {
      mutateUpdateCustomer({
        file:
          typeof formik.values.file === "string"
            ? dataURLtoFile(`data:text/plain;base64,${formik.values.file}`)
            : formik.values.file,
        json: {
          id: selectedSupplier,
          customer_name: formik.values.json.customer_name,
          date_join: formik.values.json.date_join,
          phone: formik.values.json.phone,
          is_pkp: formik.values.json.is_pkp,
          npwp: formik.values.json.npwp,
          top: formik.values.json.top,
          franco: formik.values.json.franco,
          bill_to: formik.values.json.bill_to,
          pic_name: formik.values.json.pic_name,
          pic_phone: formik.values.json.pic_phone,
          pic_email: formik.values.json.pic_email,
          notes: formik.values.json.notes,
        },
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (isDelete) {
      mutateDeleteCustomer(selectedSupplier);
    }
  }, [isDelete]);

  useEffect(() => {
    setErrorMessage("");
  }, [openDelete, openAdd, openDetail]);

  return (
    <div>
      <div className="flex mb-4 gap-2">
        <TextField
          fullWidth
          placeholder="Nama Customer"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <div className="w-full border-2 rounded-md flex items-center hover:bg-slate-100">
          <div
            className="flex justify-between items-center h-full cursor-pointer w-full px-2"
            onClick={(e) => setAnchorElCalendar(e.currentTarget)}
          >
            {selectedDates[0] === "" ? (
              <p className="text-gray-300">----/--/--</p>
            ) : (
              <p className="text-gray-300">{selectedDates[0]}</p>
            )}
            <p className="text-gray-300"> s/d </p>
            {selectedDates[1] === "" ? (
              <p className="text-gray-300">----/--/--</p>
            ) : (
              <p className="text-gray-300">{selectedDates[1]}</p>
            )}
            <MdCalendarMonth className="w-6 h-6" />
          </div>
        </div>
        <FormControl
          sx={{
            width: "100%",
          }}
        >
          <Select value={filter} onChange={(e) => setFilter(e.target.value)}>
            <MenuItem value={0}>Semua Status</MenuItem>
            <MenuItem value={1}>PKP</MenuItem>
            <MenuItem value={2}>Non PKP</MenuItem>
          </Select>
        </FormControl>
        <button
          onClick={() => setOpenAdd(true)}
          className="w-1/4 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
        >
          <p>Add Customer</p>
          <IoMdAddCircleOutline className="text-[18px]" />
        </button>
      </div>
      <>
        {" "}
        <div className="rounded-md shadow-md">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <th className="p-3 text-left">No</th>
                <th className="p-3 text-left">Nama Customer</th>
                <th className="p-3 text-left">Tanggal Bergabung</th>
                <th className="p-3 text-left">No. Telpon</th>
                <th className="p-3 text-left">Status</th>
                <th className="p-3 text-left">NPWP</th>
                <th className="p-3 text-left">TOP</th>
                <th className="p-3 text-left">Franco</th>
                <th className="p-3 text-left">Bill To</th>
                <th className="p-3 text-left">Nama PIC</th>
                <th className="p-3 text-left">No. Telpon PIC</th>
                <th className="p-3 text-left">Email PIC</th>
                <th className="p-3 text-left w-[15%]">Action</th>
              </thead>
              <tbody>
                {data && data.data && data.data.length > 0 && !isPendingList ? (
                  data.data.map((item, i) => {
                    return (
                      <tr className="border-t border-l border-2" key={i}>
                        <td className="p-3">
                          {(page - 1) * fetchLimit + (i + 1)}
                        </td>
                        <td className="p-3 flex items-center gap-2">
                          {item.customer_img !== "" ? (
                            <img
                              src={`data:image/png;base64,${item.customer_img}`}
                              className="rounded-full border-gray-200 border-2 object-cover max-w-[48px] w-[48px] h-[48px]"
                              alt="Avatar"
                            />
                          ) : (
                            <div className="p-2 rounded-full border-gray-200 border-2 object-cover max-w-[48px] min-w-[48px] w-[48px] h-[48px] bg-gray-500 text-white flex justify-center items-center">
                              <IoMdPerson className="w-full h-full" />
                            </div>
                          )}
                          <p
                            className="text-blue-500 hover:text-blue-300 cursor-pointer"
                            onClick={() => navigate(`/customer-management/detail/${item.id}`)}
                          >
                            {item.customer_name}
                          </p>
                        </td>
                        <td className="p-3">
                          {format(item.date_join, "yyyy-MM-dd")}
                        </td>
                        <td className="p-3">{item.phone}</td>
                        <td className="p-3">
                          <Switch checked={item.is_pkp} disabled />
                        </td>
                        <td className="p-3">{item.npwp}</td>
                        <td className="p-3">{item.top}</td>
                        <td className="p-3">{item.franco}</td>
                        <td className="p-3">{item.bill_to}</td>
                        <td className="p-3">{item.pic_name}</td>
                        <td className="p-3">{item.pic_phone}</td>
                        <td className="p-3">{item.pic_email}</td>
                        <td className="p-3 w-[15%]">
                          <div className="flex gap-2 items-center">
                            <FaTrash
                              onClick={() => {
                                setOpenDelete(true);
                                setSelectedSupplier(item.id);
                              }}
                              className="text-red-500 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                            />
                            <IoPencilOutline
                              onClick={() => {
                                setOpenDetail(true);
                                setSelectedSupplier(item.id);
                              }}
                              className="text-blue-500 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : !isPendingList ? (
                  <tr className="border-t border-l border-2">
                    <td colSpan={14} className="py-3 text-center">
                      Data customer tidak ditemukan
                    </td>
                  </tr>
                ) : (
                  <tr className="border-t border-l border-2">
                    <td colSpan={14} className="py-3 text-center">
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {data && data.total > 0 ? (
            <div className="w-full flex justify-between pb-8 px-8">
              <button
                onClick={() => {
                  if (page > 1) setPage(page - 1);
                }}
                disabled={page === 1}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowLeftLong /> Previous
              </button>
              <Pagination
                hideNextButton
                hidePrevButton
                shape="rounded"
                page={page}
                count={Math.ceil(data.total / fetchLimit)}
                onChange={(e, page) => setPage(page)}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
              <button
                onClick={() => {
                  if (page < Math.ceil(data.total / fetchLimit))
                    setPage(page + 1);
                }}
                disabled={page === Math.ceil(data.total / fetchLimit)}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowRightLong /> Next
              </button>
            </div>
          ) : null}
        </div>
        {data && data.total > 0 ? (
          <div className="mt-4 text-xs flex gap-3 items-center">
            <p>Show Table</p>
            <p
              onClick={() => setFetchLimit(10)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 10 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              10
            </p>
            <p
              onClick={() => setFetchLimit(50)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 50 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              50
            </p>
            <p
              onClick={() => setFetchLimit(100)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 100 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              100
            </p>
          </div>
        ) : null}
      </>

      <ModalInput
        title="Tambah Customer"
        subtitle=""
        content={
          <>
            <AddCustomerModalContent formik={formik} />
            <p className="text-red-500">{errorMessage}</p>
          </>
        }
        open={openAdd}
        setOpen={setOpenAdd}
        hasButton={true}
        buttonText="Save"
        setTriggerFunc={setIsAdd}
        isDisable={
          !formik.isValid ||
          isPendingAdd ||
          (formik.values.json.is_pkp && formik.values.json.npwp === "")
        }
      />

      <ModalInput
        title="Detail Customer"
        subtitle=""
        content={
          <>
            <EditCustomerModalContent
              formik={formik}
              id={selectedSupplier}
              setSelectedFileName={setSelectedFileName}
            />
            <p className="text-red-500">{errorMessage}</p>
          </>
        }
        open={openDetail}
        setOpen={setOpenDetail}
        hasButton={true}
        buttonText="Save"
        setTriggerFunc={setIsEdit}
        isDisable={
          !formik.isValid ||
          isPendingEdit ||
          (formik.values.json.is_pkp && formik.values.json.npwp === "")
        }
      />

      <ModalInput
        title="Hapus Customer"
        subtitle="Apakah anda yakin ingin menghapus pelanggan ini?"
        content={
          <>
            <p className="text-red-500">{errorMessage}</p>
          </>
        }
        open={openDelete}
        setOpen={setOpenDelete}
        hasButton={true}
        buttonText="Yes"
        setTriggerFunc={setIsDelete}
        isDisable={false}
      />

      <RangeCalendar
        anchorElCalendar={anchorElCalendar}
        setAnchorElCalendar={setAnchorElCalendar}
        setSelectedDates={setSelectedDates}
        searchStartDate={searchStartDate}
        searchEndDate={searchEndDate}
        setSearchStartDate={setSearchStartDate}
        setSearchEndDate={setSearchEndDate}
      />
    </div>
  );
};

export default CustomerList;
