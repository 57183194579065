import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  FormControl,
  FormGroup,
  FormLabel,
  Switch,
  Select,
  MenuItem,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { getSpkDetail, getSpkList } from "../../../services/SpkApi";
import {
  getDetailScheduleBySpk,
  getMpsListSchedule,
  setNewJob,
} from "../../../services/scheduleApi";

const validationSchema = yup.object({
  schedule_access: yup.number(),
  spk_id: yup.number(),
  internal_code: yup.string(),
  plate: yup.bool(),
  die_cut: yup.bool(),
  mps: yup.string(),
  mps_id: yup.number()
});

const UpdateJobPlate = () => {
  const navigate = useNavigate();
  const { spkId } = useParams();
  const [spk, setSpk] = useState(null);
  const [mps, setMps] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { data: dataDetailSpk, refetch: refetchSpkDetail } = useQuery({
    queryKey: ["spk-detail", spkId],
    queryFn: () => getSpkDetail(spkId),
    enabled: !!spkId,
  });

  const { data: dataDetailSchedule, refetch: refetchDetailSchedule } = useQuery(
    {
      queryKey: ["schedule-detail", spkId],
      queryFn: () =>
        getDetailScheduleBySpk(spkId, dataDetailSpk?.project[0]?.internal_code),
      enabled: !!spkId && !!dataDetailSpk,
    }
  );

  const formik = useFormik({
    initialValues: {
      schedule_access: 1,
      spk_id: dataDetailSchedule?.tooling_control?.spk_id || 0,
      internal_code: dataDetailSchedule?.tooling_control?.internal_code || "",
      plate: dataDetailSchedule?.tooling_control?.plate || false,
      die_cut: dataDetailSchedule?.tooling_control?.die_cut || false,
      mps: dataDetailSchedule.tooling_control.mps || "",
      mps_id: dataDetailSchedule.tooling_control.mps_id || 0,
    },
    validationSchema,
    onSubmit: (values) => {
      mutateTakeJob(values);
    },
  });

  const { mutate: mutateTakeJob, isPending } = useMutation({
    mutationFn: setNewJob,
    onSuccess: () => navigate("/schedule"),
    onError: (err) => setErrorMessage(err.message.id),
  });

  const { mutate: mutateSpkList, data: dataSpkList } = useMutation({
    mutationFn: getSpkList,
  });

  useEffect(() => {
    if (spkId && !dataDetailSpk) {
      refetchSpkDetail();
    }
    if (spkId && dataDetailSpk && !dataDetailSchedule) {
      refetchDetailSchedule();
    }
  }, [
    spkId,
    dataDetailSpk,
    dataDetailSchedule,
    refetchSpkDetail,
    refetchDetailSchedule,
  ]);

  const { data: dataMpsList, isFetching: fetchMpsList } = useQuery({
    queryKey: ["mps-list"],
    queryFn: () => getMpsListSchedule(),
  });

  useEffect(() => {
    mutateSpkList({
      offset: 1,
      limit: 10,
      schedule: 1,
      search: "",
      po_start: "",
      po_end: "",
      delivery_start: "",
      delivery_end: "",
      job_type: [],
      category: [],
      dies: [],
    });
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Autocomplete
          sx={{ width: "100%", marginBottom: 2 }}
          disablePortal
          disabled={spkId}
          value={
            spkId && dataDetailSchedule
              ? dataSpkList
                ? dataSpkList?.data?.find(
                    (item) =>
                      item.id ===
                      Number(dataDetailSchedule.tooling_control?.spk_id)
                  )
                : null
              : spk
          }
          onChange={(_, option) => {
            if (!spkId) {
              setSpk(option);
              formik.setFieldValue("spk_id", option ? option.id : null);
            }
          }}
          options={dataSpkList ? dataSpkList.data : []}
          getOptionLabel={(option) =>
            option.spk_no + " - " + option.customer_name
          }
          renderInput={(params) => <TextField {...params} label="No. SPK" />}
          onInputChange={(event, newInputValue, reason) => {
            if (reason === "reset" && !spkId) {
              setSpk(null);
              formik.setFieldValue("spk_id", null);
              formik.setFieldValue("internal_code", "");
              return;
            }
          }}
        />
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <FormLabel>Internal Code</FormLabel>
          <TextField
            fullWidth
            disabled
            value={dataDetailSchedule?.tooling_control?.internal_code}
          />
        </FormControl>
        <FormGroup>
          <FormLabel>Label Name</FormLabel>
          <TextField
            fullWidth
            sx={{ marginBottom: 2 }}
            disabled
            value={dataDetailSchedule?.tooling_control?.label_name}
          />
        </FormGroup>

        <div className="flex items-center gap-2 my-4">
          <FormGroup>
            <FormLabel>Plate</FormLabel>
            <Switch
              checked={formik.values.plate}
              value={dataDetailSchedule?.tooling_control?.plate}
              onChange={(e, checked) =>
                formik.setFieldValue("plate", Boolean(checked))
              }
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Die Cut</FormLabel>
            <Switch
              checked={formik.values.die_cut}
              value={dataDetailSchedule?.tooling_control?.die_cut}
              onChange={(e, checked) =>
                formik.setFieldValue("die_cut", Boolean(checked))
              }
            />
          </FormGroup>
        </div>

        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <FormLabel>MPS</FormLabel>
          <Select
            value={mps}
            onChange={(e) => {
              setMps(e.target.value);
              formik.setFieldValue("mps_id", e.target.value);
            }}
          >
            <MenuItem value={""} disabled>
              Pilih MPS
            </MenuItem>
            {dataMpsList && dataMpsList.data ? (
              dataMpsList.data.map((item, i) => (
                <MenuItem value={item.id} key={i}>
                  {item.mps_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No MPS Available</MenuItem>
            )}
          </Select>
        </FormControl>

        <FormGroup>
          <FormLabel>MPS Information</FormLabel>
          <TextField
            fullWidth
            sx={{ marginBottom: 2 }}
            value={formik.values.mps}
            onChange={(e) => formik.setFieldValue("mps", e.target.value)}
          />
        </FormGroup>

        <p className="text-right text-red-500 my-4">{errorMessage}</p>

        <div className="flex justify-end w-1/3 gap-2 float-right">
          <button
            className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
            onClick={() => navigate("/schedule")}
          >
            Cancel
          </button>
          <button
            className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
            disabled={!formik.isValid || isPending}
            type="submit"
          >
            Done
          </button>
        </div>
      </form>
    </>
  );
};

export default UpdateJobPlate;
