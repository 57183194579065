import {
  CircularProgress,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { MdCalendarMonth } from "react-icons/md";
import { useParams } from "react-router-dom";
import {
  getMCPrinting,
  getSpkDetail,
  updateSpkDetail,
} from "../../../services/SpkApi";
import SpkDetailCard from "./SpkDetailCard";
import { format } from "date-fns";
import { useFormik } from "formik";
import SingleCalendar from "../../common/SingleCalendar";

const DetailSpk = ({ schedId }) => {
  const { id } = useParams();
  const [expandArr, setExpandArr] = useState([
    {
      project: true,
      detail: true,
      material: true,
      estimate_system: true,
      estimate_hr: true,
      label: true,
      qc: true,
    },
  ]);
  const [isEdit, setIsEdit] = useState(false);
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [scheduleDate, setScheduleDate] = useState(new Date());

  const handleOpenCalendar = (e, schedule) => {
    setAnchorElCalendar(e.currentTarget);
    setScheduleDate(schedule);
  };

  const { data: dataMCPrinting } = useQuery({
    queryKey: ["mc-printing"],
    queryFn: () => getMCPrinting(),
  });

  const handleSave = () => {
    isEdit ? mutateDetail(formik.values) : setIsEdit(!isEdit);
  };

  const { data, isFetching } = useQuery({
    queryKey: ["spk-detail", id, schedId],
    queryFn: () => getSpkDetail(schedId ? schedId : id),
    refetchOnWindowFocus: false,
  });

  const { mutate: mutateDetail, isPending } = useMutation({
    mutationFn: updateSpkDetail,
    onSuccess: () => setIsEdit(false),
  });

  const formik = useFormik({
    initialValues: {
      id: Number(id),
      mc_printing: "",
      schedule_date: "",
      project: [],
    },
  });

  useEffect(() => {
    if (data) {
      let temp = [];
      formik.setFieldValue("mc_printing", data.mc_printing);
      formik.setFieldValue("schedule_date", data.schedule_date);

      data.project.map((item) =>
        temp.push({
          detail: {
            id: item.id,
            qty_production: item.qty_production,
          },
        })
      );
      formik.setFieldValue("project", temp);

      setExpandArr(
        data.project.map(() => {
          return {
            project: true,
            detail: true,
            material: true,
            estimate_system: true,
            estimate_hr: true,
            label: true,
            qc: true,
            ink_coverage: true,
          };
        })
      );
    }
  }, [data]);

  return (
    <>
      {data && !isFetching ? (
        <>
          <div className="flex justify-between items-center">
            <p className="font-bold text-xl mt-8 mb-4">General Information</p>
            {!schedId ? (
              <p
                className={`p-2 px-12 rounded-md border-2 h-fit text-white ${
                  data.is_do
                    ? "bg-gray-300 cursor-default"
                    : "cursor-pointer bg-[#18479D]  hover:bg-[#163e87]"
                }`}
                onClick={() => {
                  if (!data.is_do) handleSave();
                }}
              >
                {isEdit ? (
                  isPending ? (
                    <CircularProgress size={20} />
                  ) : (
                    "Save"
                  )
                ) : (
                  "Edit"
                )}
              </p>
            ) : null}
          </div>
          <div className="my-2 flex w-full gap-2 items-end">
            <FormControl fullWidth>
              <FormLabel>No. SPK</FormLabel>
              <TextField
                fullWidth
                disabled
                value={data.spk_no}
                sx={{ background: "#f1f5f9" }}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel>Job Type</FormLabel>
              <Select value={data.job_type} disabled>
                <MenuItem value={1}>New</MenuItem>
                <MenuItem value={2}>Repeat</MenuItem>
                <MenuItem value={3}>Sample Request</MenuItem>
                <MenuItem value={4}>RWC</MenuItem>
                <MenuItem value={5}>New + CRB</MenuItem>
                <MenuItem value={6}>RWC + CRB</MenuItem>
                <MenuItem value={7}>Reprint</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="my-2 flex w-full gap-2 items-end">
            <FormControl fullWidth>
              <FormLabel>No. SO</FormLabel>
              <TextField
                fullWidth
                disabled
                value={data.so_no}
                sx={{ background: "#f1f5f9" }}
              />
            </FormControl>
            <div className="w-full">
              <p>Tanggal Delivery</p>
              <div className="mt-1 border-2 rounded-md w-full flex items-center bg-slate-100">
                <div className="flex justify-between items-center h-full cursor-pointer w-full p-3.5 text-gray-500">
                  <p>{data.delivery_date}</p>
                  <MdCalendarMonth className="w-6 h-6" />
                </div>
              </div>
            </div>
          </div>
          <div className="my-2 flex w-full gap-2 items-end">
            <FormControl fullWidth>
              <FormLabel>No. PO</FormLabel>
              <TextField
                fullWidth
                disabled
                sx={{ background: "#f1f5f9" }}
                value={data.po_number}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel>M/C Printing</FormLabel>
              <Select
                value={
                  dataMCPrinting?.find(
                    (item) => item.name === formik.values.mc_printing
                  )?.id || ""
                }
                onChange={(e) => {
                  const selectedItem = dataMCPrinting?.find(
                    (item) => item.id === e.target.value
                  );
                  formik.setFieldValue("mc_printing", selectedItem?.name || "");
                }}
                disabled={!isEdit}
                sx={{ background: isEdit ? "#fff" : "#f1f5f9" }}
                fullWidth
              >
                {dataMCPrinting?.map((item, i) => (
                  <MenuItem key={i} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="my-2 flex w-full gap-2 items-end">
            <div className="w-full">
              <p>Tanggal PO</p>
              <div className="w-full mt-1 border-2 rounded-md flex items-center bg-slate-100">
                <div className="flex justify-between items-center h-full cursor-pointer w-full p-3.5 text-gray-500">
                  <p>{data.po_date}</p>
                  <MdCalendarMonth className="w-6 h-6" />
                </div>
              </div>
            </div>
            <FormControl fullWidth>
              <FormLabel>Kategori</FormLabel>
              <Select value={data.category} disabled>
                <MenuItem value={1}>Prime</MenuItem>
                <MenuItem value={2}>Difficult</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="my-2 flex w-full gap-2 items-end">
            <FormControl fullWidth>
              <FormLabel>Customer</FormLabel>
              <TextField
                fullWidth
                sx={{ background: "#f1f5f9" }}
                disabled
                value={data.customer_name}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel>Dies</FormLabel>
              <Select value={data.die} disabled>
                <MenuItem value={1}>PB</MenuItem>
                <MenuItem value={2}>FL</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="my-2 flex w-full gap-2 items-end">
            <FormControl fullWidth>
              <FormLabel>Sales</FormLabel>
              <TextField
                disabled
                sx={{ background: "#f1f5f9" }}
                value={data.sales}
              />
            </FormControl>
            <div className="w-full">
              <p>Tanggal Schedule</p>
              <div
                className={`w-full mt-1 border-2 rounded-md flex items-center ${
                  isEdit ? "hover:bg-slate-100" : "bg-slate-100"
                }`}
              >
                <div
                  className={`flex justify-between items-center h-full w-full p-3.5 text-gray-500 ${
                    isEdit ? "cursor-pointer" : ""
                  }`}
                  onClick={(e) => {
                    if (isEdit) handleOpenCalendar(e, scheduleDate);
                  }}
                >
                  {!formik.values.schedule_date ? (
                    <p>----/--/--</p>
                  ) : (
                    <p>
                      {format(
                        new Date(formik.values.schedule_date),
                        "yyyy-MM-dd"
                      )}
                    </p>
                  )}
                  <MdCalendarMonth className="w-6 h-6" />
                </div>
              </div>
            </div>
          </div>
          <div className="my-4">
            {data && data.project.length > 0 ? (
              data.project.map((proj, i) => (
                <SpkDetailCard
                  key={i}
                  i={i}
                  isEdit={isEdit}
                  data={proj}
                  expandArr={expandArr}
                  setExpandArr={setExpandArr}
                  formik={formik}
                />
              ))
            ) : (
              <p className="text-gray-300 text-center">
                Belum ada project, mohon memilih SO terlebih dahulu
              </p>
            )}
          </div>
          <TextField
            multiline
            rows={4}
            label="Notes"
            fullWidth
            value={data.note}
            disabled
            sx={{ marginBottom: 2 }}
          />
        </>
      ) : (
        <CircularProgress />
      )}

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendar}
        setAnchorElCalendarStart={setAnchorElCalendar}
        startDate={scheduleDate}
        setStartDate={(date) => {
          setScheduleDate(date);
          formik.setFieldValue(
            "schedule_date",
            format(new Date(date), "yyyy-MM-dd")
          );
        }}
      />
    </>
  );
};

export default DetailSpk;
