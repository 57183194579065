import {
  Autocomplete,
  Chip,
  FormGroup,
  FormLabel,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { getSpkDetail, getSpkList } from "../../../services/SpkApi";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDetailReturSupplier,
  setReturSupplier,
} from "../../../services/returSupplierApi";
import * as yup from "yup";
import { getSupplierList } from "../../../services/supplierApi";
import { MdCalendarMonth } from "react-icons/md";
import { format } from "date-fns";
import SingleCalendar from "../../common/SingleCalendar";
import moment from "moment";

const validationSchema = yup.object({
  ncr_date: yup.string().required(),
  ncr_number: yup.string().required(),
  accident_date: yup.string().required(),
  spk_id: yup.number().required(),
  po_number: yup.string(),
  location: yup.string().required(),
  supplier_name: yup.string().required(),
  manufacture: yup.string().required(),
  non_conformity_id: yup.number().min(1),
  internal_code: yup.array(),
  material: yup.array().min(1),
  lot_no: yup.string(),
  problem: yup.string(),
  affected_spk: yup.string(),
  root_cause: yup.string(),
  capa: yup.string(),
  pic: yup.string(),
  due_date: yup.string().required(),
  status: yup.number().min(1),
});

const DetailReturnSupplier = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [selectedSpk, setSelectedSpk] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [anchorElCalendarNcr, setAnchorElCalendarNcr] = useState(null);
  const [anchorElCalendarAccident, setAnchorElCalendarAccident] =
    useState(null);
  const [anchorElCalendarDue, setAnchorElCalendarDue] = useState(null);
  const [ncrDate, setNcrDate] = useState(null);
  const [accidentDate, setAccidentDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [internalCode, setInternalCode] = useState([]);
  const [status, setStatus] = useState("");

  const { mutate: mutateSpkList, data: dataSpkList } = useMutation({
    mutationFn: getSpkList,
  });

  const { data: dataDetailSpk, isFetching } = useQuery({
    queryKey: ["spk-detail", selectedSpk],
    queryFn: () => getSpkDetail(selectedSpk.id),
    enabled: selectedSpk !== null,
  });

  const { data: dataSupplier, isFetching: isFetchingSupplier } = useQuery({
    queryKey: ["supplier-detail", id],
    queryFn: () => getDetailReturSupplier(id),
    enabled: id !== undefined,
  });

  const { mutate: mutateReturSupplier, isPending } = useMutation({
    mutationFn: setReturSupplier,
    onSuccess: () => navigate("/retur-supplier"),
    onError: (err) => setErrorMessage(err.message.id),
  });

  const { mutate: mutateSupplierList, data: supplierData } = useMutation({
    mutationFn: getSupplierList,
  });

  const formik = useFormik({
    initialValues: {
      id: Number(id),
      ncr_date:
        dataSupplier && dataSupplier.ncr_date
          ? moment(dataSupplier.ncr_date).format("YYYY-MM-DD")
          : "",
      ncr_number:
        dataSupplier && dataSupplier.ncr_number ? dataSupplier.ncr_number : "",
      accident_date:
        dataSupplier && dataSupplier.accident_date
          ? moment(dataSupplier.accident_date).format("YYYY-MM-DD")
          : "",
      spk_id: dataSupplier && dataSupplier.spk_id ? dataSupplier.spk_id : 0,
      po_number:
        dataSupplier && dataSupplier.po_number ? dataSupplier.po_number : "",
      location:
        dataSupplier && dataSupplier.location ? dataSupplier.location : "",
      supplier_name:
        dataSupplier && dataSupplier.supplier_name
          ? dataSupplier.supplier_name
          : "",
      manufacture:
        dataSupplier && dataSupplier.manufacture
          ? dataSupplier.manufacture
          : "",
      non_conformity_id:
        dataSupplier && dataSupplier.non_conformity_id
          ? dataSupplier.non_conformity_id
          : 0,
      internal_code:
        dataSupplier && dataSupplier.internal_code_id
          ? dataSupplier.internal_code_id
          : [],
      material:
        dataSupplier && dataSupplier.material
          ? dataSupplier.material.map((item) => ({
              material_id: item.material_id,
              quantity: item.quantity,
            }))
          : [],
      lot_no: dataSupplier && dataSupplier.lot_no ? dataSupplier.lot_no : "",
      problem: dataSupplier && dataSupplier.problem ? dataSupplier.problem : "",
      affected_spk:
        dataSupplier && dataSupplier.affected_spk
          ? dataSupplier.affected_spk
          : "",
      root_cause:
        dataSupplier && dataSupplier.root_cause ? dataSupplier.root_cause : "",
      capa: dataSupplier && dataSupplier.capa ? dataSupplier.capa : "",
      pic: dataSupplier && dataSupplier.pic ? dataSupplier.pic : "",
      due_date:
        dataSupplier && dataSupplier.due_date
          ? moment(dataSupplier.due_date).format("YYYY-MM-DD")
          : "",
      status:
        dataSupplier && dataSupplier.status_id ? dataSupplier.status_id : 0,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      mutateReturSupplier(values);
    },
  });

  useEffect(() => {
    if (dataSupplier && dataSpkList) {
      if (dataSupplier.spk_id)
        setSelectedSpk(
          dataSpkList.data.find((item) => item.id === dataSupplier.spk_id)
        );
    }

    if (dataSupplier) {
      setInternalCode(dataSupplier.internal_code);
      setStatus(dataSupplier.status);
    }
  }, [dataSupplier, dataSpkList]);

  useEffect(() => {
    if (dataSupplier) {
      setNcrDate(new Date(dataSupplier.ncr_date));
      setAccidentDate(new Date(dataSupplier.accident_date));
      setDueDate(new Date(dataSupplier.due_date));
    }
  }, [dataSupplier]);

  useEffect(() => {
    ncrDate
      ? formik.setFieldValue("ncr_date", ncrDate)
      : formik.setFieldValue("ncr_date", "");
  }, [ncrDate]);

  useEffect(() => {
    accidentDate
      ? formik.setFieldValue("accident_date", accidentDate)
      : formik.setFieldValue("accident_date", "");
  }, [accidentDate]);

  useEffect(() => {
    dueDate
      ? formik.setFieldValue("due_date", dueDate)
      : formik.setFieldValue("due_date", "");
  }, [dueDate]);

  useEffect(() => {
    mutateSpkList({
      offset: 1,
      limit: 10000,
      schedule: 0,
      search: "",
      po_start: "",
      po_end: "",
      delivery_start: "",
      delivery_end: "",
      job_type: [],
      category: [],
      dies: [],
    });

    mutateSupplierList({
      name: "",
      start_date: "",
      end_date: "",
      pkp: 0,
      offset: 1,
      limit: 10000,
    });
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex gap-4">
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Date NCR</FormLabel>
            <div className="w-full border-2 rounded-md flex items-center hover:bg-slate-100">
              <div
                className="flex justify-between items-center h-full w-full cursor-pointer p-3.5"
                onClick={(e) => setAnchorElCalendarNcr(e.currentTarget)}
              >
                {ncrDate === null ? (
                  <p>----/--/--</p>
                ) : (
                  <p>{format(new Date(ncrDate), "yyyy-MM-dd")}</p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
          </FormGroup>
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>No NCR</FormLabel>
            <TextField
              fullWidth
              value={formik.values.ncr_number}
              onChange={(e) =>
                formik.setFieldValue("ncr_number", e.target.value)
              }
            />
          </FormGroup>
        </div>

        <div className="flex gap-4">
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Tanggal Accident</FormLabel>
            <div className="w-full border-2 rounded-md flex items-center hover:bg-slate-100">
              <div
                className="flex justify-between items-center h-full w-full cursor-pointer p-3.5"
                onClick={(e) => setAnchorElCalendarAccident(e.currentTarget)}
              >
                {accidentDate === null ? (
                  <p>----/--/--</p>
                ) : (
                  <p>{format(new Date(accidentDate), "yyyy-MM-dd")}</p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
          </FormGroup>
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>SPK</FormLabel>
            <Autocomplete
              sx={{ width: "100%" }}
              disabled
              value={selectedSpk}
              onChange={(_, option) => {
                formik.setFieldValue("spk_id", option ? option.id : null);
                setSelectedSpk(option);
              }}
              options={dataSpkList && dataSpkList.data ? dataSpkList.data : []}
              getOptionLabel={(option) => option.spk_no}
              renderInput={(params) => (
                <TextField {...params} placeholder="Pilih SPK" />
              )}
            />
          </FormGroup>
        </div>

        <div className="flex gap-4">
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>PO No</FormLabel>
            <TextField
              fullWidth
              value={dataDetailSpk ? dataDetailSpk.po_number : ""}
              disabled
            />
          </FormGroup>
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Location Kejadian</FormLabel>
            <TextField
              fullWidth
              value={formik.values.location}
              onChange={(e) => formik.setFieldValue("location", e.target.value)}
            />
          </FormGroup>
        </div>

        <div className="flex gap-4">
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Supplier Name</FormLabel>
            <TextField
              fullWidth
              value={formik.values.supplier_name}
              onChange={(e) =>
                formik.setFieldValue("supplier_name", e.target.value)
              }
            />
          </FormGroup>
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Manufacture</FormLabel>
            <TextField
              fullWidth
              value={formik.values.manufacture}
              onChange={(e) =>
                formik.setFieldValue("manufacture", e.target.value)
              }
            />
          </FormGroup>
        </div>

        <div className="flex gap-4">
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Type non conformity</FormLabel>
            <TextField
              fullWidth
              disabled
              value={dataSupplier?.non_conformity}
            />
          </FormGroup>
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Internal Code</FormLabel>
            <div className="w-full border border-gray-300 rounded p-3">
              {internalCode &&
                internalCode.map((item, index) => {
                  return (
                    <Chip
                      key={index}
                      label={item}
                      sx={{ w: "fit-content", mr: "0.2rem" }}
                      onDelete={() => {}}
                    />
                  );
                })}
            </div>
          </FormGroup>
        </div>

        <div className="flex gap-4">
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Lot No</FormLabel>
            <TextField
              fullWidth
              value={formik.values.lot_no}
              onChange={(e) => formik.setFieldValue("lot_no", e.target.value)}
              placeholder="Nomor Lot"
            />
          </FormGroup>
        </div>

        <div className="rounded-md shadow-md">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-4 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <th className="p-3 text-left">No</th>
                <th className="p-3 text-left">Material Name</th>
                <th className="p-3 text-left">Qty Material</th>
              </thead>
              <tbody>
                {dataSupplier?.material ? (
                  dataSupplier?.material.map((item, i) => (
                    <tr key={i}>
                      <td className="p-3 text-left border-b-2">{i + 1}</td>
                      <td className="p-3 text-left border-b-2">
                        {item.material_name}
                      </td>
                      <td className="p-3 text-left border-b-2">
                        <TextField
                          fullWidth
                          value={
                            formik.values.material?.length > 0
                              ? formik.values.material[i]?.quantity
                              : 0
                          }
                          onChange={(e) => {
                            const newQuantity = Number(e.target.value);
                            if (!isNaN(newQuantity)) {
                              const currentMaterial = formik.values.material[i];
                              formik.setFieldValue(`material[${i}]`, {
                                material_id:
                                  currentMaterial?.material_id ||
                                  item.material_id,
                                quantity: newQuantity,
                              });
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="p-3 text-center" colSpan={6}>
                      Tidak ada material yang terpilih
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Problem</FormLabel>
          <TextField
            fullWidth
            value={formik.values.problem}
            onChange={(e) => {
              if (e.target.value.length <= 300)
                formik.setFieldValue("problem", e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {formik.values.problem.length}/300
                </InputAdornment>
              ),
            }}
          />
        </FormGroup>

        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Affected SPK</FormLabel>
          <TextField
            fullWidth
            value={formik.values.affected_spk}
            onChange={(e) => {
              if (e.target.value.length <= 200)
                formik.setFieldValue("affected_spk", e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {formik.values.affected_spk.length}/200
                </InputAdornment>
              ),
            }}
          />
        </FormGroup>

        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Root Cause</FormLabel>
          <TextField
            fullWidth
            value={formik.values.root_cause}
            onChange={(e) => {
              if (e.target.value.length <= 200)
                formik.setFieldValue("root_cause", e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {formik.values.root_cause.length}/200
                </InputAdornment>
              ),
            }}
          />
        </FormGroup>

        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>CAPA</FormLabel>
          <TextField
            fullWidth
            value={formik.values.capa}
            onChange={(e) => {
              if (e.target.value.length <= 200)
                formik.setFieldValue("capa", e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {formik.values.capa.length}/200
                </InputAdornment>
              ),
            }}
          />
        </FormGroup>

        <div className="flex gap-4">
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>PIC</FormLabel>
            <TextField
              fullWidth
              value={formik.values.pic}
              onChange={(e) => formik.setFieldValue("pic", e.target.value)}
            />
          </FormGroup>
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Due Date</FormLabel>
            <div className="w-full border-2 rounded-md flex items-center hover:bg-slate-100">
              <div
                className="flex justify-between items-center h-full w-full cursor-pointer p-3.5"
                onClick={(e) => setAnchorElCalendarDue(e.currentTarget)}
              >
                {dueDate === null ? (
                  <p>----/--/--</p>
                ) : (
                  <p>{format(new Date(dueDate), "yyyy-MM-dd")}</p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
          </FormGroup>
        </div>

        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Status</FormLabel>
          <TextField fullWidth disabled value={status} />
        </FormGroup>

        <p className="text-red-500 mb-4 text-right m-4">{errorMessage}</p>
        <div className="w-full">
          <div className="flex justify-end w-1/3 gap-2 float-right">
            <button
              type="button"
              className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
              onClick={() => navigate("/retur-supplier")}
            >
              Cancel
            </button>
            <button
              className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
              disabled={!formik.isValid}
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </form>

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendarNcr}
        setAnchorElCalendarStart={setAnchorElCalendarNcr}
        startDate={ncrDate}
        setStartDate={setNcrDate}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendarAccident}
        setAnchorElCalendarStart={setAnchorElCalendarAccident}
        startDate={accidentDate}
        setStartDate={setAccidentDate}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendarDue}
        setAnchorElCalendarStart={setAnchorElCalendarDue}
        startDate={dueDate}
        setStartDate={setDueDate}
      />
    </>
  );
};

export default DetailReturnSupplier;
