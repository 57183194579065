import {
  InputAdornment,
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getGearList } from "../../../services/gearManagementApi";

const SpkCardAdvancedDetail = ({
  proj,
  formik,
  i,
  curProject,
  setCurProject,
}) => {
  const setTempProjectValue = (keyName, val) => {
    var tempProject = JSON.parse(JSON.stringify(curProject));
    tempProject.detail[keyName] = val;
    setCurProject(tempProject);
  };

  const { data: dataLvc } = useQuery({
    queryKey: ["gear-list"],
    queryFn: () => getGearList({ search: "", offset: 1, limit: 10000 }),
  });

  return (
    <>
      <hr className="my-2" />
      <div className="my-2 flex w-full gap-2 items-end">
        <TextField
          fullWidth
          label="X1 Across(mm)"
          sx={{ background: "#f1f5f9" }}
          disabled
          value={proj.detail.label_size.split("x")[0]}
        />
        <TextField
          fullWidth
          label="Up Across"
          disabled
          value={proj.detail.adjustment_up}
          sx={{ background: "#f1f5f9" }}
        />
      </div>
      <div className="my-2 flex w-full gap-2 items-end">
        <TextField
          fullWidth
          label="X2 Along(mm)"
          disabled
          value={proj.detail.label_size.split("x")[1]}
          sx={{ background: "#f1f5f9" }}
        />
        <TextField
          fullWidth
          label="Up Along (mm)"
          value={proj.detail.up_along}
          placeholder="Isi Up Along(mm) disini..."
          onChange={(e) => {
            let value = e.target.value;
            const regex = /^\d*[.]?\d{0,3}$/;
            if (regex.test(value)) {
              const numericValue = parseFloat(value);
              formik.setFieldValue(`project[${i}].detail.up_along`, value);
              if (!isNaN(numericValue)) {
                setTempProjectValue("up_along", numericValue);
              }
            }
          }}
        />
      </div>
      <div className="my-2 flex w-full gap-2 items-end">
        <TextField
          fullWidth
          label="Gap Across(mm)"
          value={proj.detail.gap_across}
          placeholder="Isi Gap Across(mm) disini..."
          onChange={(e) => {
            let value = e.target.value;
            const regex = /^\d*[.]?\d{0,3}$/;
            if (regex.test(value)) {
              const numericValue = parseFloat(value);
              formik.setFieldValue(`project[${i}].detail.gap_across`, value);
              if (!isNaN(numericValue)) {
                setTempProjectValue("gap_across", numericValue);
              }
            }
          }}
        />
        <TextField
          fullWidth
          label="Eye Total"
          value={Number(parseFloat(curProject.detail.eye_total).toFixed(4))}
          disabled
          sx={{ background: "#f1f5f9" }}
        />
      </div>
      <div className="my-2 flex w-full gap-2 items-end">
        <TextField
          fullWidth
          label="Gap Along (mm)"
          disabled
          value={Number(parseFloat(curProject.detail.gap_along).toFixed(4))}
          sx={{ background: "#f1f5f9" }}
        />
        <TextField
          fullWidth
          label="J. Kiri"
          value={proj.detail.j_left}
          placeholder="Isi J. Kiri disini..."
          onChange={(e) => {
            let value = e.target.value;
            const regex = /^\d*[.]?\d{0,3}$/;
            if (regex.test(value)) {
              const numericValue = parseFloat(value);
              formik.setFieldValue(`project[${i}].detail.j_left`, value);
              if (!isNaN(numericValue)) {
                setTempProjectValue("j_left", numericValue);
              }
            }
          }}
        />
      </div>
      <div className="my-2 flex w-full gap-2 items-end">
        <TextField
          fullWidth
          label="Max Up (250mm)"
          value={Number(parseFloat(curProject.detail.max_up).toFixed(4))}
          disabled
          sx={{ background: "#f1f5f9" }}
        />
        <TextField
          fullWidth
          label="J. Kanan"
          value={proj.detail.j_right}
          placeholder="Isi J. Kanan disini..."
          onChange={(e) => {
            let value = e.target.value;
            const regex = /^\d*[.]?\d{0,3}$/;
            if (regex.test(value)) {
              const numericValue = parseFloat(value);
              formik.setFieldValue(`project[${i}].detail.j_right`, value);
              if (!isNaN(numericValue)) {
                setTempProjectValue("j_right", numericValue);
              }
            }
          }}
        />
      </div>
      <div className="my-2 flex w-full gap-2 items-end">
        <TextField
          fullWidth
          label="Adjustment (up)"
          value={proj.detail.adjustment_up}
          placeholder="Isi Adjustment (up) disini..."
          onChange={(e) => {
            let value = e.target.value;
            const regex = /^\d*[.]?\d{0,3}$/;
            if (regex.test(value)) {
              const numericValue = parseFloat(value);
              formik.setFieldValue(`project[${i}].detail.adjustment_up`, value);
              if (!isNaN(numericValue)) {
                setTempProjectValue("adjustment_up", numericValue);
              }
            }
          }}
        />
        <TextField
          fullWidth
          label="Printing Area (mm)"
          value={Number(parseFloat(curProject.detail.printing_area).toFixed(4))}
          disabled
          sx={{ background: "#f1f5f9" }}
        />
      </div>
      <div className="my-2 flex w-full gap-2 items-end">
        <TextField
          fullWidth
          label="Total Gap Across"
          disabled
          value={Number(
            parseFloat(curProject.detail.total_gap_across).toFixed(4)
          )}
          sx={{ background: "#f1f5f9" }}
        />
        <TextField
          fullWidth
          label="Material Size (mm)"
          value={Number(parseFloat(curProject.detail.size_material).toFixed(4))}
          disabled
          sx={{ background: "#f1f5f9" }}
        />
      </div>
      <div className="my-2 flex w-full gap-2 items-end">
        <TextField
          fullWidth
          label="Gap Total Range (mm)"
          disabled
          value={Number(
            parseFloat(curProject.detail.total_gap_range).toFixed(4)
          )}
          sx={{ background: "#f1f5f9" }}
        />
        <TextField
          fullWidth
          label="Adjustment (mm)"
          value={parseFloat(Number(curProject.detail.adjustment_mm).toFixed(4))}
          disabled
          sx={{ background: "#f1f5f9" }}
        />
      </div>
      <div className="my-2 flex w-full gap-2 items-end">
        <FormControl fullWidth>
          <FormLabel>Gear Size</FormLabel>
          <Autocomplete
            sx={{ width: "100%" }}
            disablePortal
            value={formik.values.project[i].detail.gear_size}
            onChange={(_, option) => {
              formik.setFieldValue(`project[${i}].detail.gear_size`, option);
              setTempProjectValue("gear_size", option);
            }}
            options={
              dataLvc && dataLvc.gears
                ? dataLvc.gears.map((item) => item.lvc)
                : []
            }
            renderInput={(params) => <TextField {...params} />}
          />
        </FormControl>
        <div className="flex gap-2 w-full">
          <TextField
            fullWidth
            label="Setup Tolerance (%)"
            value={Number(
              parseFloat(curProject.detail.tolerance_percent).toFixed(4)
            )}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            disabled
            sx={{ background: "#f1f5f9" }}
          />
          <TextField
            fullWidth
            label="Setup Tolerance (m)"
            value={Number(parseFloat(curProject.detail.tolerance_m).toFixed(4))}
            disabled
            sx={{ background: "#f1f5f9" }}
            InputProps={{
              endAdornment: <InputAdornment position="end">M</InputAdornment>,
            }}
          />
        </div>
      </div>
      <div className="my-2 flex w-full gap-2 items-end">
        <TextField
          fullWidth
          label="Gear Size Amount"
          disabled
          value={Number(parseFloat(curProject.detail.gear_amount).toFixed(4))}
          sx={{ background: "#f1f5f9" }}
        />
        {formik.values.job_type === 5 || formik.values.job_type === 6 ? (
          <TextField
            fullWidth
            label="CRB Allocation (m)"
            value={Number(parseFloat(curProject.detail.crb).toFixed(4))}
            disabled
            sx={{ background: "#f1f5f9" }}
          />
        ) : (
          <TextField
            fullWidth
            label="CRB Allocation (m)"
            value={proj.detail.crb}
            placeholder="Isi CRB Allocation (m) disini..."
            onChange={(e) => {
              let value = e.target.value;
              const regex = /^\d*[.]?\d{0,3}$/;
              if (regex.test(value)) {
                const numericValue = parseFloat(value);
                formik.setFieldValue(`project[${i}].detail.crb`, value);
                if (!isNaN(numericValue)) {
                  setTempProjectValue("crb", numericValue);
                }
              }
            }}
          />
        )}
      </div>
      <div className="my-2 flex w-full gap-2 items-end">
        <TextField
          fullWidth
          label="Clean Counter"
          disabled
          value={Number(parseFloat(curProject.detail.clean_counter).toFixed(4))}
          sx={{ background: "#f1f5f9" }}
        />
        <TextField
          fullWidth
          label="Max Counter"
          value={Number(parseFloat(curProject.detail.max_counter).toFixed(4))}
          disabled
          sx={{ background: "#f1f5f9" }}
        />
      </div>
      <div className="my-2 flex w-full gap-2 items-end">
        <TextField
          fullWidth
          label="Along Run"
          disabled
          value={Number(parseFloat(curProject.detail.along_run).toFixed(4))}
          sx={{ background: "#f1f5f9" }}
        />
        <TextField
          fullWidth
          label="Max Run (m)"
          value={Number(parseFloat(curProject.detail.max_run).toFixed(4))}
          disabled
          sx={{ background: "#f1f5f9" }}
        />
      </div>
      <div className="my-2 flex w-full gap-2 items-end">
        <TextField
          fullWidth
          label="Clean Run"
          disabled
          value={
            (curProject.detail.clean_counter * curProject.detail.along_run) /
            1000
              ? Number(
                  parseFloat(
                    (curProject.detail.clean_counter *
                      curProject.detail.along_run) /
                      1000
                  ).toFixed(4)
                )
              : 0
          }
          sx={{ background: "#f1f5f9" }}
        />
        <TextField
          fullWidth
          label="Difficult Waste"
          value={curProject.detail.difficult_waste}
          disabled
          sx={{ background: "#f1f5f9" }}
        />
      </div>
      <div className="my-2 flex w-full gap-2 items-end">
        <FormControl fullWidth>
          <FormLabel>Color Total</FormLabel>
          <Select
            value={proj.detail.total_color}
            onChange={(e) => {
              formik.setFieldValue(
                `project[${i}].detail.total_color`,
                Number(e.target.value)
              );
              setTempProjectValue("total_color", Number(e.target.value));
            }}
          >
            <MenuItem value={0} disabled>
              Pilih Jumlah Warna
            </MenuItem>
            {Array(10)
              .fill(null)
              .map((item, index) => {
                return <MenuItem value={index + 1}>{index + 1}</MenuItem>;
              })}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Distortion"
          value={Number(parseFloat(curProject.detail.distortion).toFixed(4))}
          disabled
          sx={{ background: "#f1f5f9" }}
        />
      </div>
      <div className="my-2 ">
        <TextField
          disabled
          label="Speed"
          sx={{
            width: "50%",
            marginRight: 1,
            background: "#f1f5f9",
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">M/HR</InputAdornment>,
          }}
          value={Number(parseFloat(curProject.detail.speed).toFixed(4))}
        />
      </div>
    </>
  );
};

export default SpkCardAdvancedDetail;
