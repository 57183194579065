import { useEffect, useRef } from "react";
import Logo from "../../../assets/logo.png";
import jsPDF from "jspdf";
import { useQuery } from "@tanstack/react-query";
import { getSupplierPoDetail } from "../../../services/supplierPoApi";
import { format } from "date-fns";

const PrintReleasePo = ({ isDownload, setIsDownload, id }) => {
  const poRef = useRef();

  const styles = {
    page: {
      // marginLeft: "12mm",
      // marginRight: "12mm",
      marginTop: "8mm",
      fontSize: 8,
      // size: "a4",
    },
    textXs: { fontSize: 6 },
  };

  const { data } = useQuery({
    queryKey: ["po-detail", id, isDownload],
    queryFn: () => getSupplierPoDetail(id),
  });

  useEffect(() => {
    if (isDownload && data) {
      const doc = new jsPDF({
        lineHeight: 1.2,
        fontSize: 11,
        format: "a4",
        unit: "px",
      });
      doc.html(poRef.current, {
        callback: function (doc) {
          doc.save("Release PO_" + data.po_detail.po_number + ".pdf");
        },
        x: 0,
        y: 0,
        autoPaging: "text",
        width: 2480,
      });

      setIsDownload(false);
    }
  }, [data]);

  return (
    <>
      {data ? (
        <div ref={poRef} style={styles.page}>
          <div className="w-96">
            <div className="w-1/2 inline-block align-top" style={styles.textXs}>
              <img src={Logo} className="w-[25mm] h-full mb-1 px-4" />
              <p className="mb-2 px-4 w-full">
                {data.po_detail.indolabel_address}
              </p>
            </div>
            <div className="w-1/2 inline-block text-right align-middle pl-4">
              <p className="w-full font-bold text-lg">Purchase Order</p>
              <p>No: {data.po_detail.po_number}</p>
            </div>
          </div>

          <hr className="my-4 " />

          <div className="mb-4 px-6">
            <p>Yang Terhormat: {data.po_detail.supplier_name}</p>
            <p className="mb-2">
              Dengan Alamat: {data.po_detail.supplier_address}
            </p>
            <p>Tanggal: {format(data.po_detail.po_date, "dd MMMM yyyy")}</p>
            <p>No SPK/PO Customer: {data.po_detail.spk_number}</p>
            {data.po_detail.arrive_date ? (
              <p>
                Tanggal Kedatangan:{" "}
                {format(data.po_detail.arrive_date, "dd MMMM yyyy")}
              </p>
            ) : null}
          </div>

          <table className="mb-4 border-collapse w-96 mx-6">
            <thead>
              <tr className="bg-blue-100">
                <th className="p-1 border border-b-0 border-black border-r-0">
                  Nama Material
                </th>
                <th className="p-1 border border-b-0 border-black border-r-0">
                  Qty
                </th>

                <th className="p-1 border border-b-0 border-black">Unit</th>
              </tr>
            </thead>
            <tbody>
              {data.material ? (
                data.material.map((item, i) => (
                  <tr key={i}>
                    <td
                      className={`p-1 border border-black border-r-0 ${
                        i === data.material.length - 1 ? "" : "border-b-0"
                      }`}
                    >
                      {item.material_name}
                    </td>
                    <td
                      className={`p-1 border border-black border-r-0 ${
                        i === data.material.length - 1 ? "" : "border-b-0"
                      }`}
                    >
                      {item.quantity}
                    </td>
                    <td
                      className={`p-1 border border-black  ${
                        i === data.material.length - 1 ? "" : "border-b-0"
                      }`}
                    >
                      {item.unit}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3}>Tidak ada material</td>
                </tr>
              )}
            </tbody>
          </table>

          <p className="px-6">Catatan: {data.po_detail.notes}</p>
        </div>
      ) : null}
    </>
  );
};

export default PrintReleasePo;
