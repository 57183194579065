import {
  Autocomplete,
  MenuItem,
  Select,
  TextField,
  FormControl,
  FormGroup,
  FormLabel,
  Switch,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getSpkDetail, getSpkList } from "../../../services/SpkApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { getDetailSchedule, setNewJob } from "../../../services/scheduleApi";

const validationSchema = yup.object({
  schedule_access: yup.number(),
  spk_id: yup.number().min(1),
  internal_code: yup.string().required(),
  ink: yup.bool().nullable(),
});

const UpdateJobTinta = () => {
  const navigate = useNavigate();
  const { spkId } = useParams();
  const [spk, setSpk] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const { data: dataDetailSchedule, isFetching: isFetchingDetailSchedule } =
    useQuery({
      queryKey: ["schedule-detail", spkId],
      queryFn: () => getDetailSchedule(spkId),
      enabled: spkId !== undefined,
    });

  const formik = useFormik({
    initialValues: {
      schedule_access: 3,
      spk_id: dataDetailSchedule
        ? dataDetailSchedule.tooling_control.spk_id
        : 0,
      internal_code: dataDetailSchedule
        ? dataDetailSchedule.tooling_control.internal_code
        : "",
      ink: dataDetailSchedule ? dataDetailSchedule.tooling_control.material_tinta : false,
    },
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values) => {
      const cleanedValues = Object.keys(values).reduce((acc, key) => {
        acc[key] =
          values[key] === "" ||
          values[key] === 0 ||
          values[key] === null ||
          values[key] === undefined ||
          values[key] === false
            ? null
            : values[key];
        return acc;
      }, {});
      mutateTakeJob(cleanedValues);
    },
  });

  const { mutate: mutateTakeJob, isPending } = useMutation({
    mutationFn: setNewJob,
    onSuccess: () => navigate("/schedule"),
    onError: (err) => setErrorMessage(err.message.id),
  });

  const { mutate: mutateSpkList, data: dataSpkList } = useMutation({
    mutationFn: getSpkList,
  });

  const { data: dataDetailSpk, isFetching } = useQuery({
    queryKey: ["spk-detail", spk, dataDetailSchedule],
    queryFn: () =>
      getSpkDetail(
        dataDetailSchedule ? dataDetailSchedule.tooling_control.spk_id : spk.id
      ),
  });

  useEffect(() => {
    mutateSpkList({
      offset: 1,
      limit: 10,
      schedule: 3,
      search: "",
      po_start: "",
      po_end: "",
      delivery_start: "",
      delivery_end: "",
      job_type: [],
      category: [],
      dies: [],
    });
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Autocomplete
          sx={{ width: "100%", marginBottom: 2 }}
          disablePortal
          disabled={spkId}
          value={
            spkId
              ? dataSpkList
                ? dataSpkList?.data?.find(
                    (item) =>
                      item.id ===
                      Number(dataDetailSchedule.tooling_control.spk_id)
                  )
                : null
              : spk
          }
          onChange={(_, option) => {
            if (!spkId) {
              setSpk(option);
              formik.setFieldValue("spk_id", option ? option.id : null);
            }
          }}
          options={dataSpkList ? dataSpkList.data : []}
          getOptionLabel={(option) =>
            option.spk_no + " - " + option.customer_name
          }
          renderInput={(params) => <TextField {...params} label="No. SPK" />}
          onInputChange={(event, newInputValue, reason) => {
            if (reason === "reset" && !spkId) {
              setSpk(null);
              formik.setFieldValue("spk_id", null);
              formik.setFieldValue("internal_code", "");
              return;
            }
          }}
        />
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <FormLabel>Internal Code</FormLabel>
          <Select
            disabled={!dataDetailSpk || (!spkId && spk === null) || spkId}
            value={formik.values.internal_code}
            onChange={(e) =>
              formik.setFieldValue("internal_code", e.target.value)
            }
          >
            <MenuItem value={""} disabled>
              Pilih Internal Code
            </MenuItem>
            {dataDetailSpk && dataDetailSpk.project ? (
              dataDetailSpk.project.map((item, i) => (
                <MenuItem value={item.internal_code} key={i}>
                  {item.internal_code}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No Internal Codes Available</MenuItem>
            )}
          </Select>
        </FormControl>
        <FormGroup>
          <FormLabel>Label Name</FormLabel>
          <TextField
            fullWidth
            sx={{ marginBottom: 2 }}
            disabled
            value={
              dataDetailSpk && formik.values.internal_code !== ""
                ? dataDetailSpk?.project?.find(
                    (proj) => proj.internal_code === formik.values.internal_code
                  ).label_name
                : ""
            }
          />
        </FormGroup>

        <div className="flex items-center gap-2">
          <FormGroup>
            <FormLabel>Tinta</FormLabel>
            <Switch
              value={formik.values.ink}
              onChange={(e, checked) =>
                formik.setFieldValue("ink", Boolean(checked))
              }
            />
          </FormGroup>
        </div>

        <p className="text-right text-red-500 my-4">{errorMessage}</p>

        <div className="flex justify-end w-1/3 gap-2 float-right">
          <button
            className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
            onClick={() => navigate("/schedule")}
          >
            Cancel
          </button>
          <button
            className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
            disabled={!formik.isValid || isPending}
            type="submit"
          >
            Done
          </button>
        </div>
      </form>
    </>
  );
};

export default UpdateJobTinta;
