import {
  Avatar,
  Breadcrumbs,
  CircularProgress,
  Link,
  Switch,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getDetailCustomer } from "../../../services/customerManagementApi";
import { IoMdPerson } from "react-icons/io";
import { format } from "date-fns";

const DetailCustomerManagement = () => {
  const { id } = useParams();

  const { data, isPending } = useQuery({
    queryKey: ["customer-detail"],
    queryFn: () => getDetailCustomer(id),
  });

  return (
    <div className="mt-12 lg:mt-2">
      <div className="flex justify-between items-center mb-8">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/customer-management">
            Customer Management
          </Link>
          <Link underline="hover" color="inherit">
            Detail Customer Management
          </Link>
        </Breadcrumbs>
      </div>
      {data && !isPending ? (
        <>
          <div className="w-full flex items-center justify-center">
            {data.customer_img !== "" ? (
              <Avatar
                alt="Remy Sharp"
                src={`data:image/png;base64,${data.customer_img}`}
                sx={{ width: 160, height: 160 }}
              />
            ) : (
              <div className="p-2 rounded-full border-gray-200 border-2 object-cover max-w-[160px] min-w-[160px] w-[160px] h-[160px] bg-gray-500 text-white flex justify-center items-center">
                <IoMdPerson className="w-full h-full" />
              </div>
            )}
          </div>
          <div className="w-full mb-4">
            <p className="text-gray-500 text-xs">Nama Customer</p>
            <p>{data.customer_name}</p>
            <hr />
          </div>
          <div className="w-full mb-4">
            <p className="text-gray-500 text-xs">Tanggal Bergabung</p>
            <p>{format(data.date_join, "yyyy-MM-dd")}</p>
            <hr />
          </div>
          <div className="w-full mb-4">
            <p className="text-gray-500 text-xs">No. Telp</p>
            <p>{data.phone}</p>
            <hr />
          </div>
          <div className="w-full mb-4 flex flex-col">
            <p className="text-gray-500 text-xs">Status PKP</p>
            <div className="flex items-center ml-[-8px]">
              <Switch disabled checked={data.is_pkp} />
              <p>{data.is_pkp ? "Aktif" : "Tidak Aktif"}</p>
            </div>
          </div>
          <div>
            {data.is_pkp ? (
              <div className="w-full mb-4">
                <p className="text-gray-500 text-xs">NPWP</p>
                <p>{data.npwp}</p>
                <hr />
              </div>
            ) : null}
          </div>
          <div className="w-full mb-4">
            <p className="text-gray-500 text-xs">Terms of Payment</p>
            <p>{data.top}</p>
            <hr />
          </div>
          <div className="w-full mb-4">
            <p className="text-gray-500 text-xs">Franco</p>
            <p>{data.franco}</p>
            <hr />
          </div>
          <div className="w-full mb-4">
            <p className="text-gray-500 text-xs">Bill To</p>
            <p>{data.bill_to}</p>
            <hr />
          </div>
          <div className="w-full mb-4">
            <p className="text-gray-500 text-xs">Nama PIC</p>
            <p>{data.pic_name}</p>
            <hr />
          </div>
          <div className="w-full mb-4">
            <p className="text-gray-500 text-xs">No. Telp PIC</p>
            <p>{data.pic_phone}</p>
            <hr />
          </div>
          <div className="w-full mb-4">
            <p className="text-gray-500 text-xs">Email PIC</p>
            <p>{data.pic_email}</p>
            <hr />
          </div>
          <div className="w-full mb-4">
            <p className="text-gray-500 text-xs">Notes</p>
            <p>{data.notes}</p>
            <hr />
          </div>
        </>
      ) : !isPending ? (
        <tr className="w-full text-center flex items-center justify-center">
          <td colSpan={14} className="py-3 text-center">
            Data customer tidak ditemukan
          </td>
        </tr>
      ) : (
        <tr className="w-full text-center flex items-center justify-center">
          <td colSpan={14} className="py-3 text-center">
            <CircularProgress size={20} />
          </td>
        </tr>
      )}

      <div className="rounded-md">
        <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8">
          <table className="w-full">
            <thead className="bg-[#F4F5FF] border-2">
              <th className="p-3 text-left border-r w-12">No</th>
              <th className="p-3 text-left border-r">Internal Code</th>
              <th className="p-3 text-left border-r">Label Name</th>
              <th className="p-3 text-left">Material Name</th>
            </thead>
            <tbody>
              {data && data.internal_code_list && !isPending && data.internal_code_list.length > 0 ? (
                data.internal_code_list.map((code, index) =>
                  code.material.map((item, rowIndex) => (
                    <tr
                      className="border-t border-l border-r border-2"
                      key={`${index}-${rowIndex}`}
                    >
                      {rowIndex === 0 && (
                        <>
                          <td
                            className="p-3 border-r"
                            rowSpan={code.material.length}
                            style={{ verticalAlign: "top" }}
                          >
                            {index + 1}
                          </td>
                          <td
                            className="p-3 border-r"
                            rowSpan={code.material.length}
                            style={{ verticalAlign: "top" }}
                          >
                            {code.internal_code}
                          </td>
                          <td
                            className="p-3 border-r"
                            rowSpan={code.material.length}
                            style={{ verticalAlign: "top" }}
                          >
                            {code.name_label}
                          </td>
                        </>
                      )}
                      <td className="p-3">{item.name}</td>
                    </tr>
                  ))
                )
              ) : (
                <tr className="border-t border-l border-r border-2">
                  <td className="p-3 text-center" colSpan={8}>
                    Customer ini belum memiliki internal code
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DetailCustomerManagement;
