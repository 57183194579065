import axios from "axios";
import Cookies from "js-cookie";
import { GeneratePublicToken } from "../utils/GeneratePublicToken";

const token = () => Cookies.get("accessToken");

export const getGearList = async ({ search, limit, offset }) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/gear-list?search=${search}&limit=${limit}&offset=${offset}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data
  }
};

export const createGear = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/gear-create`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data
  }
};

export const updateGear = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/gear-create`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data
  }
};

export const deleteGear = async (id) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/gear-delete?id=${id}`,
      method: "DELETE",
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data
  }
};
