import React from "react";
import Header from "../../components/common/Header";
import SidebarComponent from "../../components/common/SidebarComponent";
import HOC from "../../components/HOC";
import UpdateJobPlate from "../../components/screen/schedule/UpdateJobPlate";
import UpdateJobQC from "../../components/screen/schedule/UpdateJobQC";
import UpdateJobOperator from "../../components/screen/schedule/UpdateJobOperator";
import UpdateJobTinta from "../../components/screen/schedule/UpdateJobTinta";
import UpdateJobGudang from "../../components/screen/schedule/UpdateJobGudang";

const EditScheduleTakeJobPage = () => {
  const role = Number(localStorage.getItem("role"));

  return (
    <div className="">
      <div className="flex">
        <div className="w-0 lg:w-[310px] h-screen sticky top-0 z-30">
          <SidebarComponent />
        </div>
        <div className="w-[100%] lg:w-[calc(100%-310px)] p-3 mt-4 lg:mt-0">
          <Header />
          <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
            Take Job{" "}
            {role === 1
              ? "(Plate - Plate & Die Cut)"
              : role === 2
              ? "(Gudang - Paper, BOPP, Foil)"
              : role === 3
              ? "(Tinta)"
              : role === 4
              ? "(Operasional)"
              : role === 5
              ? "QC"
              : ""}
          </h1>
          {role === 1 ? (
            <UpdateJobPlate />
          ) : role === 2 ? (
            <UpdateJobGudang />
          ) : role === 3 ? (
            <UpdateJobTinta />
          ) : role === 4 ? (
            <UpdateJobOperator />
          ) : role === 5 ? (
            <UpdateJobQC />
          ) : (
            <p className="text-gray-300 text-center">
              Silahkan mengontak admin untuk mengambil job
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default HOC(EditScheduleTakeJobPage);
