import {
  CircularProgress,
  FormControl,
  FormGroup,
  FormLabel,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  TextField,
} from "@mui/material";
import { FaArrowRightLong, FaArrowLeftLong, FaTrash } from "react-icons/fa6";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import { IoMdAddCircleOutline, IoMdDownload } from "react-icons/io";
import { Search } from "@mui/icons-material";
import { useMutation, useQuery } from "@tanstack/react-query";
import ModalInput from "../../common/ModalInput";
import { MdCalendarMonth } from "react-icons/md";
import RangeCalendar from "../../common/RangeCalendar";
import { deleteSupplier, getSupplierList } from "../../../services/supplierApi";
import { format } from "date-fns";
import { getReturCustomerList } from "../../../services/returCustomerApi";
import { useNavigate } from "react-router-dom";
import PrintReturCustomer from "./PrintReturCustomer";
import { IoPencilOutline } from "react-icons/io5";

const ReturnCustomerList = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedReturn, setSelectedReturn] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [refetchList, setRefetchList] = useState(true);
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [selectedDates, setSelectedDates] = useState(["", ""]);
  const [isDownload, setIsDownload] = useState(false);
  const [supplierId, setSupplierId] = useState(0);
  const [selectedReturCustomer, setSelectedReturCustomer] = useState(null);
  const [anchorElCalendarReturn, setAnchorElCalendarReturn] = useState(null);
  const [anchorElCalendarProd, setAnchorElCalendarProd] = useState(null);
  const [selectedDatesReturn, setSelectedDatesReturn] = useState(["", ""]);
  const [selectedDatesProd, setSelectedDatesProd] = useState(["", ""]);

  const [searchStartDateReturn, setSearchStartDateReturn] = useState(null);
  const [searchEndDateReturn, setSearchEndDateReturn] = useState(null);
  const [searchStartDateProd, setSearchStartDateProd] = useState(null);
  const [searchEndDateProd, setSearchEndDateProd] = useState(null);

  useEffect(() => {
    if (!isDownload) {
      setSelectedReturn(null);
    }
  }, [isDownload]);

  const { data: data, isFetching } = useQuery({
    queryKey: [
      "return-list",
      fetchLimit,
      page,
      search,
      selectedDates[1],
      refetchList,
      supplierId,
    ],
    queryFn: () =>
      getReturCustomerList({
        limit: fetchLimit,
        offset: page,
        search: search,
        supplier: supplierId,
        start: selectedDates[0],
        end: selectedDates[1],
      }),
  });

  const { mutate: mutateDeleteSupplier } = useMutation({
    mutationFn: deleteSupplier,
    onSuccess: () => {
      setErrorMessage("");
      setOpenDelete(false);
      setRefetchList(true);
      setIsDelete(false);
    },
  });

  const { mutate: mutateSupplierList, data: dataSupplier } = useMutation({
    mutationFn: getSupplierList,
  });

  useEffect(() => {
    if (!isDownload) setSelectedReturCustomer(null);
  }, [isDownload]);

  useEffect(() => {
    const body = {
      name: "",
      start_date: "",
      end_date: "",
      pkp: 0,
      offset: 1,
      limit: 10000,
    };
    mutateSupplierList(body);
  }, [
    mutateSupplierList,
    fetchLimit,
    page
  ]);

  useEffect(() => {
    if (data) {
      setRefetchList(false);
    }
  }, [data]);

  useEffect(() => {
    if (isDelete) {
      mutateDeleteSupplier(selectedReturn);
    }
  }, [isDelete]);

  return (
    <div>
      <div className="flex mb-4 gap-2 items-stretch">
        <FormGroup sx={{ width: "100%" }}>
          <FormLabel>Date Return</FormLabel>
          <div className="w-full border-2 rounded-md flex items-center hover:bg-slate-100">
            <div
              className="flex justify-between items-center h-full w-full cursor-pointer px-2 py-3.5"
              onClick={(e) => {
                setAnchorElCalendarReturn(e.currentTarget);
              }}
            >
              {selectedDatesReturn[0] === "" ? (
                <p className="text-gray-300">----/--/--</p>
              ) : (
                <p className="text-gray-300">{selectedDatesReturn[0]}</p>
              )}
              <p className="text-gray-300"> s/d </p>
              {selectedDatesReturn[1] === "" ? (
                <p className="text-gray-300">----/--/--</p>
              ) : (
                <p className="text-gray-300">{selectedDatesReturn[1]}</p>
              )}
              <MdCalendarMonth className="w-6 h-6" />
            </div>
          </div>
        </FormGroup>

        <FormGroup sx={{ width: "100%" }}>
          <FormLabel>Prod Date</FormLabel>

          <div className="border-2 rounded-md w-full flex items-center hover:bg-slate-100">
            <div
              className="flex justify-between items-center h-full w-full cursor-pointer px-2 py-3.5"
              onClick={(e) => {
                setAnchorElCalendarProd(e.currentTarget);
              }}
            >
              {selectedDatesProd[0] === "" ? (
                <p className="text-gray-300">----/--/--</p>
              ) : (
                <p className="text-gray-300">{selectedDatesProd[0]}</p>
              )}
              <p className="text-gray-300"> s/d </p>
              {selectedDatesProd[1] === "" ? (
                <p className="text-gray-300">----/--/--</p>
              ) : (
                <p className="text-gray-300">{selectedDatesProd[1]}</p>
              )}
              <MdCalendarMonth className="w-6 h-6" />
            </div>
          </div>
        </FormGroup>
        <FormGroup sx={{ width: "100%" }}>
          <FormLabel>Search</FormLabel>
          <TextField
            fullWidth
            placeholder="Search by No. SPK, No. DO, Customer, Internal Code, atau Product Name"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </FormGroup>
        <div className="w-1/2 self-stretch flex items-stretch">
          <button
            onClick={() => navigate("/retur-customer/add-customer")}
            className="w-full flex self-stretch items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
          >
            <p>Add Retur</p>
            <IoMdAddCircleOutline className="text-[18px]" />
          </button>
        </div>
      </div>
      <>
        {" "}
        <div className="rounded-md shadow-md">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <th className="p-3 text-left">No</th>
                <th className="p-3 text-left">Return Date</th>
                <th className="p-3 text-left">Prod Date</th>
                <th className="p-3 text-left">No. SPK</th>
                <th className="p-3 text-left">No. DO</th>
                <th className="p-3 text-left">No. PO</th>
                <th className="p-3 text-left">Customer</th>
                <th className="p-3 text-left">Internal Code</th>
                <th className="p-3 text-left">Label Name</th>
                <th className="p-3 text-left">UOM</th>
                <th className="p-3 text-left">QTY</th>
                <th className="p-3 text-left">Problem</th>
                <th className="p-3 text-left">Price per Unit</th>
                <th className="p-3 text-left">Total Cost</th>
                <th className="p-3 text-left">Remark</th>
                <th className="p-3 text-left w-[15%]">Action</th>
              </thead>
              <tbody>
                {data && data.data && data.data.length > 0 && !isFetching ? (
                  data.data.map((item, i) => {
                    return (
                      <tr className="border-t border-l border-2" key={i}>
                        <td className="p-3">
                          {(page - 1) * fetchLimit + (i + 1)}
                        </td>
                        <td className="p-3">
                          {format(new Date(item.date_retur), "dd-MM-yyyy")}
                        </td>
                        <td className="p-3">
                          {format(new Date(item.prod_date), "dd-MM-yyyy")}
                        </td>
                        <td className="p-3">{item.spk_number}</td>
                        <td className="p-3">
                          {item.do_number.map((doNumber, i) => (
                            <p className="truncate" key={i}>
                              {doNumber}
                            </p>
                          ))}
                        </td>
                        <td className="p-3">{item.po_number}</td>
                        <td className="p-3">{item.customer_name}</td>
                        <td className="p-3">
                          {item.internal_code.map((item, i) => (
                            <p key={i}>{item}</p>
                          ))}
                        </td>
                        <td className="p-3">
                          {item.label_name.map((item, i) => (
                            <p key={i}>{item}</p>
                          ))}
                        </td>
                        <td className="p-3">
                          {item.uom?.map((item, i) => (
                            <p key={i}>{item}</p>
                          ))}
                        </td>
                        <td className="p-3">
                          {item.quantity?.map((item, i) => (
                            <p key={i}>{item}</p>
                          ))}
                        </td>
                        <td className="p-3">{item.problem}</td>
                        <td className="p-3">
                          {item.price_unit?.map((item, i) => (
                            <p key={i}>Rp{(item).toLocaleString()}</p>
                          ))}
                        </td>
                        <td className="p-3">
                          {item.total_cost?.map((item, i) => (
                            <p key={i}>Rp{(item).toLocaleString()}</p>
                          ))}
                        </td>
                        <td className="p-3">{item.remark}</td>
                        <td className="p-3 w-[15%]">
                          <div className="flex gap-2 items-center">
                            <IoPencilOutline
                              onClick={() => {
                                navigate(`/retur-customer/edit/${item.id}`);
                              }}
                              className="text-blue-500 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                            />
                            <FaTrash
                              onClick={() => {
                                setOpenDelete(true);
                                setSelectedReturn(item.id);
                              }}
                              className="text-red-500 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                            />
                            {isDownload && selectedReturn === item ? (
                              <CircularProgress className="text-[24px]" />
                            ) : (
                              <IoMdDownload
                                onClick={() => {
                                  setIsDownload(true);
                                  setSelectedReturCustomer(item);
                                }}
                                className="text-blue-500 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : !isFetching ? (
                  <tr className="border-t border-l border-2">
                    <td colSpan={6} className="py-3 text-center">
                      Data Retur tidak ditemukan
                    </td>
                  </tr>
                ) : (
                  <tr className="border-t border-l border-2">
                    <td colSpan={6} className="py-3 text-center">
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {data && data.count > 0 ? (
            <div className="w-full flex justify-between pb-8 px-8">
              <button
                onClick={() => {
                  if (page > 1) setPage(page - 1);
                }}
                disabled={page === 1}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowLeftLong /> Previous
              </button>
              <Pagination
                hideNextButton
                hidePrevButton
                shape="rounded"
                page={page}
                count={Math.ceil(data.count / fetchLimit)}
                onChange={(e, page) => setPage(page)}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
              <button
                onClick={() => {
                  if (page < Math.ceil(data.count / fetchLimit))
                    setPage(page + 1);
                }}
                disabled={page === Math.ceil(data.count / fetchLimit)}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowRightLong /> Next
              </button>
            </div>
          ) : null}
        </div>
        {data && data.count > 0 ? (
          <div className="mt-4 text-xs flex gap-3 items-center">
            <p>Show Table</p>
            <p
              onClick={() => setFetchLimit(10)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 10 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              10
            </p>
            <p
              onClick={() => setFetchLimit(50)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 50 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              50
            </p>
            <p
              onClick={() => setFetchLimit(100)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 100 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              100
            </p>
          </div>
        ) : null}
      </>

      <ModalInput
        title="Hapus Supplier/Vendor"
        subtitle="Apakah anda yakin ingin menghapus supplier/vendor ini?"
        content={<></>}
        open={openDelete}
        setOpen={setOpenDelete}
        hasButton={true}
        buttonText="Yes"
        setTriggerFunc={setIsDelete}
        isDisable={false}
      />

      <RangeCalendar
        anchorElCalendar={anchorElCalendarReturn}
        setAnchorElCalendar={setAnchorElCalendarReturn}
        setSelectedDates={setSelectedDatesReturn}
        searchStartDate={searchStartDateReturn}
        searchEndDate={searchEndDateReturn}
        setSearchStartDate={setSearchStartDateReturn}
        setSearchEndDate={setSearchEndDateReturn}
      />

      <RangeCalendar
        anchorElCalendar={anchorElCalendarProd}
        setAnchorElCalendar={setAnchorElCalendarProd}
        setSelectedDates={setSelectedDatesProd}
        searchStartDate={searchStartDateProd}
        searchEndDate={searchEndDateProd}
        setSearchStartDate={setSearchStartDateProd}
        setSearchEndDate={setSearchEndDateProd}
      />

      <PrintReturCustomer
        isDownload={isDownload}
        setIsDownload={setIsDownload}
        data={selectedReturCustomer}
      />
    </div>
  );
};

export default ReturnCustomerList;
