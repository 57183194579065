import axios from "axios";
import Cookies from "js-cookie";

const token = () => Cookies.get("accessToken");

export const getDoList = async ({
  limit,
  offset,
  search,
  start,
  end,
  carrier,
  startDelivery,
  endDelivery,
  status,
}) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/list-do?limit=${limit}&offset=${offset}&search=${search}&start_date=${start}&end_date=${end}&start_date_delivery=${startDelivery}&carrier=${carrier}&end_date_delivery=${endDelivery}&status=${status}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getDoExport = async () => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/list-do?search=&start_date=&end_date=&start_date_delivery=&carrier=&end_date_delivery=`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const setNewDo = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/delivery-order`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getDoDetail = async (id) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/detail-do?id=${id}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getShipmentMethod = async () => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/list-sm`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const setNewShipmentMethod = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/shipment-method`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getDataProject = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/get-project`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const setNewStatusDo = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/update-do`,
      method: "PUT",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};
