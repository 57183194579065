import {
  FormGroup,
  FormLabel,
  InputAdornment,
  Select,
  TextField,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { useFormik } from "formik";
import { IoMdAddCircleOutline } from "react-icons/io";
import ModalInput from "../../common/ModalInput";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getMaterialList } from "../../../services/materialApi";
import { FaCirclePlus, FaTrash } from "react-icons/fa6";
import { FaMinusCircle } from "react-icons/fa";
import { Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { getAllCosting } from "../../../services/costingApi";
import { getShipmentMethod } from "../../../services/deliveryOrder";
import { getCustomerList } from "../../../services/customerManagementApi";
import { getUserListActive } from "../../../services/userManagementApi";
import { MdCalendarMonth } from "react-icons/md";
import { format } from "date-fns";
import SingleCalendar from "../../common/SingleCalendar";
import { setQuotation } from "../../../services/quotationApi";

const validationSchema = yup.object({
  quotation_number: yup.string().required(),
  quotation_date: yup.string().required(),
  customer_id: yup.number().min(1),
  sales_id: yup.number().min(1),
  shipping_method: yup.number().min(1),
  ship_to: yup.string(),
  bill_to: yup.string(),
  note: yup.string(),
  label: yup.array().min(1),
  total_price: yup.number().min(1),
});

const AddQuotation = () => {
  const navigate = useNavigate();

  const [openProduct, setOpenProduct] = useState(false);
  const [search, setSearch] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [quoteDate, setQuoteDate] = useState(null);
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);

  const calculateTotalPrice = () => {
    formik.setFieldValue(
      "total_price",
      formik.values.label.reduce((acc, num) => acc + num.price * num.qty, 0)
    );
  };

  const handleAddLabel = () => {
    formik.setFieldValue("label", [
      ...formik.values.label,
      {
        label_id: 0,
        label_name: "",
        label_size: "",
        material: [],
        qty: 0,
        price: 0,
      },
    ]);
  };

  const handleDeleteMaterial = (i) => {
    formik.setFieldValue("label", [
      ...formik.values.label.slice(0, i),
      ...formik.values.label.slice(i + 1),
    ]);
  };

  const { data, isFetching } = useQuery({
    queryKey: ["material-list", search],
    queryFn: () =>
      getMaterialList({
        search: search,
        filter: "",
        offset: 1,
        limit: 10000,
      }),
  });

  const { data: dataShipmentMethod } = useQuery({
    queryKey: ["shipment-method-list"],
    queryFn: () => getShipmentMethod(),
  });

  const { data: dataCosting } = useQuery({
    queryKey: ["all-costing"],
    queryFn: () => getAllCosting(),
  });

  const formik = useFormik({
    initialValues: {
      quotation_number: "",
      quotation_date: "",
      customer_id: null,
      sales_id: null,
      shipping_method: 0,
      ship_to: "",
      bill_to: "",
      note: "",
      label: [],
      total_price: 0,
    },
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: (values) => {
      mutateQuotation(values);
    },
  });

  const { data: dataUser } = useQuery({
    queryKey: ["user-list"],
    queryFn: () =>
      getUserListActive({
        name: "",
        role: 6,
        offset: 1,
        limit: 10000,
        status: true,
      }),
  });

  const { mutate: mutateQuotation, isPending } = useMutation({
    mutationFn: setQuotation,
    onSuccess: () => navigate("/quotation"),
    onError: (err) => setErrorMessage(err.message.id),
  });

  const { mutate: mutateCustomerList, data: dataCust } = useMutation({
    mutationFn: getCustomerList,
  });

  useEffect(() => {
    calculateTotalPrice();
  }, [formik.values.label]);

  useEffect(() => {
    formik.setFieldValue(
      "quotation_date",
      quoteDate ? format(quoteDate, "yyyy-MM-dd") : ""
    );
  }, [quoteDate]);

  useEffect(() => {
    formik.setFieldValue(
      "quotation_number",
      "Q/" +
        new Date().getFullYear() +
        "/" +
        Date.now().toString(36).toUpperCase()
    );
    const body = {
      name: "",
      start_date: "",
      end_date: "",
      pkp: 0,
      offset: 1,
      limit: 10000,
    };
    mutateCustomerList(body);
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex gap-4 mb-2">
          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>No. Quotation</FormLabel>
            <TextField
              sx={{ marginBottom: 1 }}
              fullWidth
              disabled
              value={formik.values.quotation_number}
            />
            <FormLabel>Quotation Date</FormLabel>
            <div className="mb-2 border-2 rounded-md w-full flex items-center hover:bg-slate-100">
              <div
                className="flex justify-between items-center h-full w-full cursor-pointer p-3.5"
                onClick={(e) => setAnchorElCalendar(e.currentTarget)}
              >
                {quoteDate === null ? (
                  <p>----/--/--</p>
                ) : (
                  <p>{format(new Date(quoteDate), "yyyy-MM-dd")}</p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
            <FormLabel>Nama Sales</FormLabel>
            <Autocomplete
              sx={{ width: "100%" }}
              disablePortal
              value={
                formik.values.sales_id && dataUser
                  ? dataUser.data.find(
                      (item) => item.id === formik.values.sales_id
                    )
                  : null
              }
              onChange={(_, option) => {
                formik.setFieldValue("sales_id", option ? option.id : null);
              }}
              options={dataUser ? dataUser.data : []}
              getOptionLabel={(option) => option.fullname}
              renderInput={(params) => (
                <TextField {...params} placeholder="Pilih Sales" />
              )}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === "reset") {
                  formik.setFieldValue("sales_id", null);
                  return;
                }
              }}
            />
          </FormGroup>
          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>Ship To</FormLabel>
            <TextField
              fullWidth
              disabled
              sx={{ marginBottom: 2 }}
              multiline
              rows={2.75}
              value={formik.values.ship_to}
            />
            <FormLabel>Bill To</FormLabel>
            <TextField
              fullWidth
              disabled
              multiline
              rows={2.75}
              value={formik.values.bill_to}
            />
          </FormGroup>
        </div>
        <div className="flex gap-4 mb-4">
          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>Customer</FormLabel>
            <Autocomplete
              sx={{ width: "100%", marginBottom: 1 }}
              disablePortal
              value={
                formik.values.customer_id && dataCust
                  ? dataCust.data.find(
                      (item) => item.id === formik.values.customer_id
                    )
                  : null
              }
              onChange={(_, option) => {
                formik.setFieldValue("customer_id", option ? option.id : null);
                formik.setFieldValue("ship_to", option ? option.franco : "");
                formik.setFieldValue("bill_to", option ? option.bill_to : "");
              }}
              options={dataCust ? dataCust.data : []}
              getOptionLabel={(option) => option.customer_name}
              renderInput={(params) => (
                <TextField {...params} placeholder="Pilih Customer" />
              )}
            />
            <FormLabel>Metode Pengiriman</FormLabel>
            <Select
              value={formik.values.shipping_method}
              onChange={(e) =>
                formik.setFieldValue("shipping_method", e.target.value)
              }
            >
              <MenuItem value={0} disabled>
                Pilih Metode Pengiriman
              </MenuItem>
              {dataShipmentMethod && dataShipmentMethod.length > 0
                ? dataShipmentMethod.map((item) => {
                    return (
                      <MenuItem value={item.id} key={item.id}>
                        {item.shipment_name}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
          </FormGroup>
          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>Note</FormLabel>
            <TextField
              fullWidth
              multiline
              rows={4.75}
              value={formik.values.note}
              onChange={(e) => formik.setFieldValue("note", e.target.value)}
            />
          </FormGroup>
        </div>

        <p className="font-bold text-xl mb-4">Labels</p>
        <div className="rounded-md shadow-md">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <th className="p-3 text-center">No</th>
                <th className="p-3 text-center">Nama Label</th>
                <th className="p-3 text-center">Size</th>
                <th className="p-3 text-center">Material</th>
                <th className="p-3 text-center">Qty</th>
                <th className="p-3 text-center">Price</th>
              </thead>
              <tbody>
                {formik.values.label.length === 0 ? (
                  <td className="text-center p-3 text-gray-300" colSpan={6}>
                    Tidak ada label
                  </td>
                ) : (
                  formik.values.label.map((item, i) => (
                    <tr key={i}>
                      <td className="p-3 text-center">{i + 1}</td>
                      <td className="p-3 text-center">
                        <Autocomplete
                          sx={{ width: "100%" }}
                          disablePortal
                          value={
                            item.label_id && dataCosting
                              ? dataCosting.data.find(
                                  (cost) => cost.id === item.label_id
                                )
                              : null
                          }
                          onChange={(_, option) => {
                            formik.setFieldValue(
                              "label[" + i + "].label_id",
                              option ? option.id : 0
                            );
                            formik.setFieldValue(
                              "label[" + i + "].label_name",
                              option ? option.label_name : ""
                            );
                            formik.setFieldValue(
                              "label[" + i + "].label_size",
                              option ? option.size : ""
                            );
                            formik.setFieldValue(
                              "label[" + i + "].material",
                              option
                                ? option.material.map(
                                    (mat) => mat.material_name
                                  )
                                : []
                            );
                            formik.setFieldValue("label[" + i + "].qty", 0);
                            formik.setFieldValue(
                              "label[" + i + "].price",
                              option ? option.total_price / option.qty : 0
                            );
                          }}
                          options={
                            dataCosting
                              ? dataCosting.data.filter(
                                  (cost) =>
                                    !formik.values.label
                                      .map((lbl) => lbl.label_id)
                                      .includes(cost.id)
                                )
                              : []
                          }
                          getOptionLabel={(option) => option.label_name}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Pilih Label" />
                          )}
                        />
                      </td>
                      <td className="p-3 text-center">
                        {item.label_id > 0 ? item.label_size : "-"}
                      </td>
                      <td className="p-3 text-center">
                        {item.label_id > 0 ? item.material.join(", ") : "-"}
                      </td>
                      <td className="p-3 text-center">
                        <TextField
                          disabled={item.label_id === 0}
                          fullWidth
                          value={item.qty}
                          onChange={(e) => {
                            if (!isNaN(Number(e.target.value)))
                              formik.setFieldValue(
                                "label[" + i + "].qty",
                                Number(e.target.value)
                              );
                          }}
                        />
                      </td>
                      <td className="p-3 text-center">
                        <div className="flex items-center justify-between">
                          Rp
                          {(item.label_id > 0
                            ? item.price * formik.values.label[i].qty
                            : 0
                          ).toLocaleString()}
                          <FaTrash
                            onClick={() => handleDeleteMaterial(i)}
                            className="text-red-500 cursor-pointer hover:text-gray-300"
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex mb-8 justify-between items-center">
          <button
            onClick={handleAddLabel}
            type="button"
            className="px-8 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
          >
            <p>Add Label</p>
            <IoMdAddCircleOutline className="text-[18px]" />
          </button>
          <div className="basis-1/3 flex">
            <p className="w-full font-bold">Grand Total</p>
            <p className="w-fit mr-2">:</p>
            <p className="w-full">
              Rp
              {formik.values.label
                .reduce((acc, num) => acc + num.price * num.qty, 0)
                .toLocaleString()}
            </p>
          </div>
        </div>
        <div className="w-full">
          <p className="text-red-500 mb-4 text-right m-4">{errorMessage}</p>
          <div className="flex justify-end w-1/3 gap-2 float-right">
            <button
              type="button"
              className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
              onClick={() => navigate("/costing")}
            >
              Cancel
            </button>
            <button
              className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
              disabled={!formik.isValid || isPending}
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </form>

      <ModalInput
        title="Add Product"
        subtitle=""
        content={
          <>
            <TextField
              fullWidth
              placeholder="Search material"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <hr className="my-4" />
            <div className="rounded-md shadow-md">
              <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
                <table className="w-full">
                  <thead className="bg-[#F4F5FF] border-t border-b border-2">
                    <th className="p-3 text-left">No</th>
                    <th className="p-3 text-left">Nama Material</th>
                    <th className="p-3 text-center">Qty</th>
                  </thead>
                  <tbody>
                    {data &&
                    data.materials &&
                    data.materials.length > 0 &&
                    !isFetching ? (
                      data.materials.map((item, i) => (
                        <tr>
                          <td className="p-3">{i + 1}</td>
                          <td className="p-3">{item.name}</td>
                          <td className="p-3">
                            <div className="flex items-center gap-2 justify-center">
                              <button
                                // disabled={
                                //   formik.values.material.findIndex(
                                //     (mat) => mat.material_id === item.id
                                //   ) < 0 ||
                                //   formik.values.material[
                                //     formik.values.material.findIndex(
                                //       (mat) => mat.material_id === item.id
                                //     )
                                //   ].qty === 0
                                // }
                                className="text-red-500 hover:text-red-300 disabled:text-gray-300"
                                // onClick={() =>
                                //   handleMaterialChange(item, "decrease")
                                // }
                              >
                                <FaMinusCircle className="w-[24px] h-full" />
                              </button>
                              <p>
                                {/* {formik.values.material.findIndex(
                                  (mat) => mat.material_id === item.id
                                ) >= 0
                                  ? formik.values.material[
                                      formik.values.material.findIndex(
                                        (mat) => mat.material_id === item.id
                                      )
                                    ].qty
                                  : 0} */}
                                0
                              </p>
                              <button
                                // onClick={() =>
                                //   handleMaterialChange(item, "increase")
                                // }
                                // disabled={
                                //   formik.values.material.findIndex(
                                //     (mat) => mat.material_id === item.id
                                //   ) >= 0 &&
                                //   formik.values.material[
                                //     formik.values.material.findIndex(
                                //       (mat) => mat.material_id === item.id
                                //     )
                                //   ].qty === item.stock
                                // }
                                className="hover:text-blue-300 text-blue-500 disabled:text-gray-300"
                              >
                                <FaCirclePlus className="w-[24px] h-full" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="p-3 text-center" colSpan={3}>
                          Belum ada material
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        }
        open={openProduct}
        setOpen={setOpenProduct}
        hasButton={true}
        buttonText="Submit"
        setTriggerFunc={() => {
          setOpenProduct(false);
        }}
        isDisable={false}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendar}
        setAnchorElCalendarStart={setAnchorElCalendar}
        startDate={quoteDate}
        setStartDate={setQuoteDate}
      />
    </>
  );
};

export default AddQuotation;
