import {
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  Link,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import {
  deleteUser,
  getUserDetail,
  setNewUser,
} from "../../../services/userManagementApi";
import { useEffect, useState } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import ModalInput from "../../common/ModalInput";

const validationSchema = yup.object({
  id: yup.number().required(),
  fullname: yup
    .string()
    .required("Nama karyawan wajib diisi")
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  email: yup
    .string()
    .email()
    .required("Email wajib diisi")
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  position: yup
    .string()
    .required("Posisi wajib diisi")
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
});

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isAll, setIsAll] = useState(false);

  const checkAll = () => {
    setIsAll(
      formik.values.inventory.length === 3 &&
        formik.values.working_order.length === 3 &&
        formik.values.invoice.length === 1 &&
        formik.values.gear.length === 1 &&
        formik.values.die_cut.length === 1 &&
        formik.values.equipment.length === 1 &&
        formik.values.retur_customer.length === 1 &&
        formik.values.supplier.length === 1 &&
        formik.values.customer.length === 1 &&
        formik.values.user_management.length === 1 &&
        formik.values.quotation === 2 &&
        formik.values.retur_supplier === 1 &&
        formik.values.mps_flexo === 1 &&
        formik.values.mps === 1 &&
        formik.values.plate_usage === 1 &&
        formik.values.finishgood === 1
    );
  };

  const handleSelectChild = (checked, child, id) => {
    formik.setFieldValue(
      child,
      checked
        ? [...formik.values[child], id]
        : formik.values[child].filter((item) => item !== id)
    );

    checkAll();
  };

  const handleSelectAll = () => {
    isAll
      ? formik.setValues({
          ...formik.values,
          inventory: [],
          working_order: [],
          invoice: [],
          gear: [],
          die_cut: [],
          equipment: [],
          retur_customer: [],
          supplier: [],
          customer: [],
          user_management: [],
          quotation: [],
          retur_supplier: [],
          mps_flexo: [],
          mps: [],
          plate_usage: [],
          finishgood: [],
        })
      : formik.setValues({
          ...formik.values,
          inventory: [1, 2, 3],
          working_order: [1, 2, 3],
          invoice: [1, 2, 3],
          gear: [1],
          die_cut: [1],
          equipment: [1],
          retur_customer: [1],
          supplier: [1],
          customer: [1],
          user_management: [1],
          quotation: [1, 2],
          retur_supplier: [1],
          mps_flexo: [1],
          mps: [1],
          plate_usage: [1],
          fininshgood: [1],
        });
    setIsAll(!isAll);
  };

  const { mutate: mutateNewUser, isPending } = useMutation({
    mutationFn: setNewUser,
    onError: (err) => setErrorMessage(err.message.id),
    onSuccess: () => {
      navigate("/user-management");
    },
  });

  const { mutate: mutateDelete, isPending: isPendingDelete } = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      setIsDelete(false);
      setOpenDelete(false);
      navigate("/user-management");
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  const { data } = useQuery({
    queryKey: ["user-detail", id],
    queryFn: () => getUserDetail(id),
    enabled: id !== undefined && id !== 0,
  });

  const formik = useFormik({
    initialValues: {
      id: id ? Number(id) : 0,
      fullname: data ? data.fullname : "",
      email: data ? data.email : "",
      position: data && data.position ? data.position : 0,
      inventory: data && data.inventory ? data.inventory : [],
      working_order: data && data.working_order ? data.working_order : [],
      invoice: data && data.invoice ? data.invoice : [],
      gear: data && data.gear ? data.gear : [],
      die_cut: data && data.die_cut ? data.die_cut : [],
      equipment: data && data.die_cut ? data.equipment : [],
      retur_customer: data && data.retur_customer ? data.retur_customer : [],
      supplier: data && data.supplier ? data.supplier : [],
      quotation: data && data.quotation ? data.quotation : [],
      customer: data && data.customer ? data.customer : [],
      user_management: data && data.user_management ? data.user_management : [],
      retur_supplier: data && data.retur_supplier ? data.retur_supplier : [],
      mps_flexo: data && data.mps_flexo ? data.mps_flexo : [],
      mps: data && data.mps ? data.mps : [],
      plate_usage: data && data.plate_usage ? data.plate_usage : [],
      finishgood: data && data.finishgood ? data.finishgood : [],
    },
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: (values) => {
      mutateNewUser(values);
    },
  });

  useEffect(() => {
    if (isDelete) mutateDelete(Number(id));
  }, [isDelete]);

  useEffect(() => {
    if (data) checkAll();
  }, [data]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex justify-between items-center">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/user-management">
              User Management
            </Link>
            <Link underline="hover" color="inherit">
              Edit User
            </Link>
          </Breadcrumbs>
          <Button
            variant="contained"
            color="error"
            sx={{ display: "flex", gap: 2, justifyItems: "center" }}
            onClick={() => setOpenDelete(true)}
          >
            Hapus User
            <FaRegTrashAlt />
          </Button>
        </div>
        <div className="border-gray-200 border-2 rounded-md p-4 my-4">
          <p className="font-bold text-xl mb-3">User Details</p>
          <TextField
            label="Nama Karyawan"
            fullWidth
            value={formik.values.fullname}
            onChange={(e) => formik.setFieldValue("fullname", e.target.value)}
            helperText={formik.touched.fullname && formik.errors.fullname}
            error={formik.touched.fullname && Boolean(formik.errors.fullname)}
          />
          <TextField
            label="Email"
            fullWidth
            sx={{ marginY: 2 }}
            value={formik.values.email}
            onChange={(e) => formik.setFieldValue("email", e.target.value)}
            helperText={formik.touched.email && formik.errors.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
          <Select
            fullWidth
            value={formik.values.position}
            onChange={(e) => formik.setFieldValue("position", e.target.value)}
          >
            <MenuItem value={0} disabled>
              Pilih User Role
            </MenuItem>
            <MenuItem value={6}>Sales</MenuItem>
            <MenuItem value={1}>Plate (Plate & Die Cut)</MenuItem>
            <MenuItem value={2}>Gudang (Paper, BOPP, Foil)</MenuItem>
            <MenuItem value={3}>Tinta</MenuItem>
            <MenuItem value={4}>Operasional</MenuItem>
            <MenuItem value={5}>QC</MenuItem>
          </Select>
        </div>

        <div className="border-gray-200 border-2 rounded-md p-4 my-4">
          <div className="flex justify-between">
            <p className="font-bold mb-4">Izin Akses</p>
            <p
              className={`${
                isAll ? "text-red-500" : "text-blue-500"
              } cursor-pointer hover:text-gray-300 font-bold`}
              onClick={handleSelectAll}
            >
              {isAll ? "Deselect All" : "Select All"}
            </p>
          </div>
          <div className="grid grid-cols-4 items-start">
            <div>
              <div className="flex items-center gap-2">
                <Checkbox
                  checked={formik.values.inventory.length === 3}
                  onChange={(e, checked) =>
                    formik.setFieldValue("inventory", checked ? [1, 2, 3] : [])
                  }
                />
                Inventory Management
              </div>
              <div className="ml-4">
                <div className="flex items-center gap-2">
                  <Checkbox
                    checked={formik.values.inventory.includes(1)}
                    onChange={(e, checked) =>
                      handleSelectChild(checked, "inventory", 1)
                    }
                  />
                  Material
                </div>

                <div className="flex items-center gap-2">
                  <Checkbox
                    checked={formik.values.inventory.includes(2)}
                    onChange={(e, checked) =>
                      handleSelectChild(checked, "inventory", 2)
                    }
                  />
                  Delivery Order
                </div>
                <div className="flex items-center gap-2">
                  <Checkbox
                    checked={formik.values.inventory.includes(3)}
                    onChange={(e, checked) =>
                      handleSelectChild(checked, "inventory", 3)
                    }
                  />
                  Stock Adjustment
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center gap-2">
                <Checkbox
                  checked={formik.values.working_order.length === 3}
                  onChange={(e, checked) =>
                    formik.setFieldValue(
                      "working_order",
                      checked ? [1, 2, 3] : []
                    )
                  }
                />
                Working Order Management
              </div>
              <div className="ml-4">
                <div className="flex items-center gap-2">
                  <Checkbox
                    checked={formik.values.working_order.includes(1)}
                    onChange={(e, checked) =>
                      handleSelectChild(checked, "working_order", 1)
                    }
                  />
                  Sales Order
                </div>
                <div className="flex items-center gap-2">
                  <Checkbox
                    checked={formik.values.working_order.includes(2)}
                    onChange={(e, checked) =>
                      handleSelectChild(checked, "working_order", 2)
                    }
                  />
                  Surat Perintah Kerja (SPK)
                </div>
                <div className="flex items-center gap-2">
                  <Checkbox
                    checked={formik.values.working_order.includes(3)}
                    onChange={(e, checked) =>
                      handleSelectChild(checked, "working_order", 3)
                    }
                  />
                  Schedule
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center gap-2">
                <Checkbox
                  checked={formik.values.invoice.length === 3}
                  onChange={(e, checked) =>
                    formik.setFieldValue("invoice", checked ? [1, 2, 3] : [])
                  }
                />
                Invoice Bill
              </div>
              <div className="ml-4">
                <div className="flex items-center gap-2">
                  <Checkbox
                    checked={formik.values.invoice.includes(1)}
                    onChange={(e, checked) =>
                      handleSelectChild(checked, "invoice", 1)
                    }
                  />
                  Supplier PO
                </div>
                <div className="flex items-center gap-2">
                  <Checkbox
                    checked={formik.values.invoice.includes(2)}
                    onChange={(e, checked) =>
                      handleSelectChild(checked, "invoice", 2)
                    }
                  />
                  Supplier Invoice
                </div>
                <div className="flex items-center gap-2">
                  <Checkbox
                    checked={formik.values.invoice.includes(3)}
                    onChange={(e, checked) =>
                      handleSelectChild(checked, "invoice", 3)
                    }
                  />
                  Customer Invoice
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center gap-2">
                <Checkbox
                  checked={formik.values.quotation.length === 2}
                  onChange={(e, checked) =>
                    formik.setFieldValue("quotation", checked ? [1, 2] : [])
                  }
                />
                Quotation Management
              </div>
              <div className="ml-4">
                <div className="flex items-center gap-2">
                  <Checkbox
                    checked={formik.values.quotation.includes(1)}
                    onChange={(e, checked) =>
                      handleSelectChild(checked, "quotation", 1)
                    }
                  />
                  Quotation
                </div>
                <div className="flex items-center gap-2">
                  <Checkbox
                    checked={formik.values.quotation.includes(2)}
                    onChange={(e, checked) =>
                      handleSelectChild(checked, "quotation", 2)
                    }
                  />
                  Service Costing
                </div>
              </div>
            </div>
          </div>
          <div className="cols-3">
            <div className="flex items-center gap-2">
              <Checkbox
                checked={formik.values.gear.includes(1)}
                onChange={(e, checked) => handleSelectChild(checked, "gear", 1)}
              />
              Gear Management
            </div>
          </div>
          <div className="cols-3">
            <div className="flex items-center gap-2">
              <Checkbox
                checked={formik.values.die_cut.includes(1)}
                onChange={(e, checked) =>
                  handleSelectChild(checked, "die_cut", 1)
                }
              />
              Die Cut Management
            </div>
          </div>
          <div className="cols-3">
            <div className="flex items-center gap-2">
              <Checkbox
                checked={formik.values.equipment.includes(1)}
                onChange={(e, checked) =>
                  handleSelectChild(checked, "equipment", 1)
                }
              />
              Equipment Management
            </div>
          </div>
          <div className="cols-3">
            <div className="flex items-center gap-2">
              <Checkbox
                checked={formik.values.retur_customer.includes(1)}
                onChange={(e, checked) =>
                  handleSelectChild(checked, "retur_customer", 1)
                }
              />
              Retur Customer
            </div>
          </div>
          <div className="cols-3">
            <div className="flex items-center gap-2">
              <Checkbox
                checked={formik.values.retur_supplier.includes(1)}
                onChange={(e, checked) =>
                  handleSelectChild(checked, "retur_supplier", 1)
                }
              />
              Retur Supplier
            </div>
          </div>
          <div className="cols-3">
            <div className="flex items-center gap-2">
              <Checkbox
                checked={formik.values.supplier.includes(1)}
                onChange={(e, checked) =>
                  handleSelectChild(checked, "supplier", 1)
                }
              />
              Supplier/Vendor
            </div>
          </div>
          <div className="cols-3">
            <div className="flex items-center gap-2">
              <Checkbox
                checked={formik.values.quotation.includes(1)}
                onChange={(e, checked) =>
                  handleSelectChild(checked, "quotation", 1)
                }
              />
              Quotation
            </div>
          </div>
          <div className="cols-3">
            <div className="flex items-center gap-2">
              <Checkbox
                checked={formik.values.mps_flexo.includes(1)}
                onChange={(e, checked) =>
                  handleSelectChild(checked, "mps_flexo", 1)
                }
              />
              MPS Flexo
            </div>
          </div>
          <div className="cols-3">
            <div className="flex items-center gap-2">
              <Checkbox
                checked={formik.values.mps.includes(1)}
                onChange={(e, checked) => handleSelectChild(checked, "mps", 1)}
              />
              MPS Flexible & Platbet
            </div>
          </div>
          <div className="cols-3">
            <div className="flex items-center gap-2">
              <Checkbox
                checked={formik.values.plate_usage.includes(1)}
                onChange={(e, checked) =>
                  handleSelectChild(checked, "plate_usage", 1)
                }
              />
              Plat Usage
            </div>
          </div>
          <div className="cols-3">
            <div className="flex items-center gap-2">
              <Checkbox
                checked={formik.values.customer.includes(1)}
                onChange={(e, checked) =>
                  handleSelectChild(checked, "customer", 1)
                }
              />
              Customer Management
            </div>
          </div>
          <div className="cols-3">
            <div className="flex items-center gap-2">
              <Checkbox
                checked={formik.values.user_management.includes(1)}
                onChange={(e, checked) =>
                  handleSelectChild(checked, "user_management", 1)
                }
              />
              User Management
            </div>
          </div>
          <div className="cols-3">
            <div className="flex items-center gap-2">
              <Checkbox
                checked={formik.values.finishgood.includes(1)}
                onChange={(e, checked) =>
                  handleSelectChild(checked, "finishgood", 1)
                }
              />
              Finishgood Inventory
            </div>
          </div>
        </div>

        <p className="text-red-500 text-center mb-4">{errorMessage}</p>

        <div className="flex gap-2 items-center">
          <button
            className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
            onClick={() => navigate("/user-management")}
          >
            Cancel
          </button>
          <button
            className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
            type="submit"
            disabled={isPending}
          >
            {isPending ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </form>

      <ModalInput
        title="Hapus User"
        subtitle="Apakah anda ingin menghapus user ini?"
        content={
          <>
            <p className="text-red-500">{errorMessage}</p>
          </>
        }
        open={openDelete}
        setOpen={setOpenDelete}
        hasButton={true}
        buttonText="Hapus"
        setTriggerFunc={setIsDelete}
        isDisable={isPendingDelete}
      />
    </>
  );
};

export default EditUser;
