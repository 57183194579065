import {
  Autocomplete,
  MenuItem,
  Select,
  TextField,
  FormControl,
  FormGroup,
  FormLabel,
  Switch,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getSpkDetail, getSpkList } from "../../../services/SpkApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { getDetailSchedule, setNewJob } from "../../../services/scheduleApi";

const validationSchema = yup.object({
  schedule_access: yup.number(),
  spk_id: yup.number().min(1),
  internal_code: yup.string().required(),
  paper: yup.bool().nullable(),
  bopp: yup.bool().nullable(),
  foil: yup.bool().nullable(),
  material_run: yup.number(),
  material_waste_run: yup.number(),
  material_total: yup.number(),
  time_run: yup.number(),
  time_waste_run: yup.number(),
  time_total: yup.number(),
});

const UpdateJobGudang = () => {
  const navigate = useNavigate();
  const { spkId } = useParams();
  const [spk, setSpk] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const { data: dataDetailSchedule, isFetching: isFetchingDetailSchedule } =
    useQuery({
      queryKey: ["schedule-detail", spkId],
      queryFn: () => getDetailSchedule(spkId),
      enabled: spkId !== undefined,
    });
  
  const formik = useFormik({
    initialValues: {
      schedule_access: 2,
      spk_id: dataDetailSchedule
        ? dataDetailSchedule.tooling_control.spk_id
        : 0,
      internal_code: dataDetailSchedule
        ? dataDetailSchedule.tooling_control.internal_code
        : "",
      plate: dataDetailSchedule
        ? dataDetailSchedule.tooling_control.plate
        : false,
      die_cut: dataDetailSchedule
        ? dataDetailSchedule.tooling_control.die_cut
        : false,
      paper: dataDetailSchedule
        ? dataDetailSchedule.tooling_control.material_paper
        : false,
      bopp: dataDetailSchedule
        ? dataDetailSchedule.tooling_control.material_bopp
        : false,
      foil: dataDetailSchedule
        ? dataDetailSchedule.tooling_control.material_foil
        : false,
      material_run: dataDetailSchedule
        ? dataDetailSchedule.production_information.material_run
        : 0,
      material_waste_run: dataDetailSchedule
        ? dataDetailSchedule.production_information.material_waste_run
        : 0,
      material_total: dataDetailSchedule
        ? dataDetailSchedule.production_information.material_total
        : 0,
      time_run: dataDetailSchedule
        ? dataDetailSchedule.production_information.time_run
        : 0,
      time_waste_run: dataDetailSchedule
        ? dataDetailSchedule.production_information.time_waste_run
        : 0,
      time_total: dataDetailSchedule
        ? dataDetailSchedule.production_information.time_total
        : 0,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values) => {
      mutateTakeJob(values);
    },
  });

  const { mutate: mutateTakeJob, isPending } = useMutation({
    mutationFn: setNewJob,
    onSuccess: () => navigate("/schedule"),
    onError: (err) => setErrorMessage(err.message.id),
  });

  const { mutate: mutateSpkList, data: dataSpkList } = useMutation({
    mutationFn: getSpkList,
    onSuccess: (data) => {
      if (spkId) setSpk(data.data.find((item) => item.id === Number(spkId)));
    },
  });

  const { data: dataDetailSpk, isFetching } = useQuery({
    queryKey: ["spk-detail", spk, dataDetailSchedule],
    queryFn: () =>
      getSpkDetail(
        dataDetailSchedule ? dataDetailSchedule.tooling_control.spk_id : spk.id
      ),
  });

  useEffect(() => {
    mutateSpkList({
      offset: 1,
      limit: 10,
      schedule: 2,
      search: "",
      po_start: "",
      po_end: "",
      delivery_start: "",
      delivery_end: "",
      job_type: [],
      category: [],
      dies: [],
    });
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Autocomplete
          sx={{ width: "100%", marginBottom: 2 }}
          disablePortal
          disabled={spkId}
          value={
            spkId && dataDetailSchedule
              ? dataSpkList
                ? dataSpkList.data.find(
                    (item) =>
                      item.id ===
                      Number(dataDetailSchedule.tooling_control.spk_id)
                  )
                : null
              : spk
          }
          onChange={(_, option) => {
            if (!spkId) {
              setSpk(option);
              formik.setFieldValue("spk_id", option ? option.id : null);
            }
          }}
          options={dataSpkList ? dataSpkList.data : []}
          getOptionLabel={(option) =>
            option.spk_no + " - " + option.customer_name
          }
          renderInput={(params) => <TextField {...params} label="No. SPK" />}
          onInputChange={(event, newInputValue, reason) => {
            if (reason === "reset" && !spkId) {
              setSpk(null);
              formik.setFieldValue("spk_id", null);
              formik.setFieldValue("internal_code", "");
              return;
            }
          }}
        />
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <FormLabel>Internal Code</FormLabel>
          <Select
            disabled={!dataDetailSpk || (!spkId && spk === null) || spkId}
            value={formik.values.internal_code}
            onChange={(e) =>
              formik.setFieldValue("internal_code", e.target.value)
            }
          >
            <MenuItem value={""} disabled>
              Pilih Internal Code
            </MenuItem>
            {dataDetailSpk && dataDetailSpk.project ? (
              dataDetailSpk.project.map((item, i) => (
                <MenuItem value={item.internal_code} key={i}>
                  {item.internal_code}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No Internal Codes Available</MenuItem>
            )}
          </Select>
        </FormControl>
        <FormGroup>
          <FormLabel>Label Name</FormLabel>
          <TextField
            fullWidth
            sx={{ marginBottom: 2 }}
            disabled
            value={
              dataDetailSpk && formik.values.internal_code !== ""
                ? dataDetailSpk.project.find(
                    (proj) => proj.internal_code === formik.values.internal_code
                  ).label_name
                : ""
            }
          />
        </FormGroup>

        <div className="flex items-center gap-2 mb-4">
          <FormGroup>
            <FormLabel>Paper</FormLabel>
            <Switch
              checked={formik.values.paper}
              onChange={(e, checked) =>
                formik.setFieldValue("paper", Boolean(checked))
              }
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>BOPP</FormLabel>
            <Switch
              checked={formik.values.bopp}
              onChange={(e, checked) => {
                formik.setFieldValue("bopp", Boolean(checked));
              }}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Foil</FormLabel>
            <Switch
              checked={formik.values.foil}
              onChange={(e, checked) =>
                formik.setFieldValue("foil", Boolean(checked))
              }
            />
          </FormGroup>
        </div>

        <div className="flex gap-2 mb-4">
          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>Material Run</FormLabel>
            <TextField
              fullWidth
              value={formik.values.material_run}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue(
                    "material_run",
                    e.target.value.slice(-1) === "."
                      ? e.target.value
                      : Number(e.target.value)
                  );
              }}
            />
          </FormGroup>
          <FormGroup
            sx={{
              width: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <FormLabel>Material Setup Run & Waste Run</FormLabel>
            <TextField
              fullWidth
              value={formik.values.material_waste_run}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue(
                    "material_waste_run",
                    e.target.value.slice(-1) === "."
                      ? e.target.value
                      : Number(e.target.value)
                  );
              }}
            />
          </FormGroup>
          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>Material Total</FormLabel>
            <TextField
              fullWidth
              value={formik.values.material_total}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue(
                    "material_total",
                    e.target.value.slice(-1) === "."
                      ? e.target.value
                      : Number(e.target.value)
                  );
              }}
            />
          </FormGroup>
        </div>

        <div className="flex gap-2">
          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>Time Run</FormLabel>
            <TextField
              fullWidth
              value={formik.values.time_run}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue(
                    "time_run",
                    e.target.value.slice(-1) === "."
                      ? e.target.value
                      : Number(e.target.value)
                  );
              }}
            />
          </FormGroup>
          <FormGroup
            sx={{
              width: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <FormLabel>Time Setup Run & Waste Run</FormLabel>
            <TextField
              fullWidth
              value={formik.values.time_waste_run}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue(
                    "time_waste_run",
                    e.target.value.slice(-1) === "."
                      ? e.target.value
                      : Number(e.target.value)
                  );
              }}
            />
          </FormGroup>
          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>Time Total</FormLabel>
            <TextField
              fullWidth
              value={formik.values.time_total}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue(
                    "time_total",
                    e.target.value.slice(-1) === "."
                      ? e.target.value
                      : Number(e.target.value)
                  );
              }}
            />
          </FormGroup>
        </div>

        <p className="text-right text-red-500 my-4">{errorMessage}</p>

        <div className="flex justify-end w-1/3 gap-2 float-right">
          <button
            className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
          <button
            className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
            disabled={!formik.isValid || isPending}
            type="submit"
          >
            Done
          </button>
        </div>
      </form>
    </>
  );
};

export default UpdateJobGudang;
