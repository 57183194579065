import { FormControl, Select, TextField, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import SingleCalendar from "../../common/SingleCalendar";
import { format } from "date-fns";
import { MdCalendarMonth } from "react-icons/md";
import { useMutation } from "@tanstack/react-query";
import { getSupplierList } from "../../../services/supplierApi";

const AddDieCutModalContent = ({ formik, errorMessage }) => {
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [startDate, setStartDate] = useState(null);

  const curDate = Date.now();

  const {
    mutate: mutateSupplierList,
    data,
    isPending: isPendingList,
  } = useMutation({
    mutationFn: getSupplierList,
  });

  useEffect(() => {
    const body = {
      name: "",
      start_date: "",
      end_date: "",
      pkp: 0,
      offset: 1,
      limit: 10000,
    };
    mutateSupplierList(body);
  }, []);

  useEffect(() => {
    formik.setFieldValue(
      "date",
      startDate ? format(new Date(startDate), "yyyy-MM-dd") : ""
    );
  }, [startDate]);

  useEffect(() => {
    formik.setFieldValue(
      "job_bag",
      "F_" +
        curDate.toString(36).toUpperCase() +
        new Date().getDate() +
        new Date().getMonth() +
        new Date().getFullYear()
    );
  }, []);

  return (
    <>
      <p>
        No. Pisau<span className="text-red-500"> *</span>
      </p>
      <TextField
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.code}
        onChange={(e) => formik.setFieldValue("code", e.target.value)}
        helperText={formik.touched.code && formik.errors.code}
        error={formik.touched.code && Boolean(formik.errors.code)}
      />
      <p>Tanggal Masuk</p>
      <div className="mb-4  w-full border-2 rounded-md w-full flex items-center hover:bg-slate-100">
        <div
          className="flex justify-between items-center h-full cursor-pointer w-full cursor-pointer p-3.5"
          onClick={(e) => setAnchorElCalendar(e.currentTarget)}
        >
          {startDate === null ? (
            <p>----/--/--</p>
          ) : (
            <p>{format(new Date(startDate), "yyyy-MM-dd")}</p>
          )}
          <MdCalendarMonth className="w-6 h-6" />
        </div>
      </div>
      <p>
        Length (mm)<span className="text-red-500"> *</span>
      </p>
      <TextField
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.length}
        onChange={(e) => {
          isNaN(Number(e.target.value))
            ? formik.setFieldValue("length", 0)
            : formik.setFieldValue("length", Number(e.target.value));
        }}
        helperText={formik.touched.length && formik.errors.length}
        error={formik.touched.length && Boolean(formik.errors.length)}
      />
      <p>
        Width (mm)<span className="text-red-500"> *</span>
      </p>
      <TextField
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.width}
        onChange={(e) => {
          isNaN(Number(e.target.value))
            ? formik.setFieldValue("width", 0)
            : formik.setFieldValue("width", Number(e.target.value));
        }}
        helperText={formik.touched.width && formik.errors.width}
        error={formik.touched.width && Boolean(formik.errors.width)}
      />
      <p>
        Gap Along (mm)<span className="text-red-500"> *</span>
      </p>
      <TextField
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.gap_along}
        onChange={(e) => {
          isNaN(Number(e.target.value))
            ? formik.setFieldValue("gap_along", 0)
            : formik.setFieldValue("gap_along", Number(e.target.value));
        }}
        helperText={formik.touched.gap_along && formik.errors.gap_along}
        error={formik.touched.gap_along && Boolean(formik.errors.gap_along)}
      />
      <p>
        Gap Across (mm)<span className="text-red-500"> *</span>
      </p>
      <TextField
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.gap_across}
        onChange={(e) => {
          isNaN(Number(e.target.value))
            ? formik.setFieldValue("gap_across", 0)
            : formik.setFieldValue("gap_across", Number(e.target.value));
        }}
        helperText={formik.touched.gap_across && formik.errors.gap_across}
        error={formik.touched.gap_across && Boolean(formik.errors.gap_across)}
      />
      <p>
        Up Along (mm)<span className="text-red-500"> *</span>
      </p>
      <TextField
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.up_along}
        onChange={(e) => {
          isNaN(Number(e.target.value))
            ? formik.setFieldValue("up_along", 0)
            : formik.setFieldValue("up_along", Number(e.target.value));
        }}
        helperText={formik.touched.up_along && formik.errors.up_along}
        error={formik.touched.up_along && Boolean(formik.errors.up_along)}
      />
      <p>
        Up Across (mm)<span className="text-red-500"> *</span>
      </p>
      <TextField
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.up_across}
        onChange={(e) => {
          isNaN(Number(e.target.value))
            ? formik.setFieldValue("up_across", 0)
            : formik.setFieldValue("up_across", Number(e.target.value));
        }}
        helperText={formik.touched.up_across && formik.errors.up_across}
        error={formik.touched.up_across && Boolean(formik.errors.up_across)}
      />
      <p>
        Running (mm)<span className="text-red-500"> *</span>
      </p>
      <TextField
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.running_meter}
        onChange={(e) => {
          isNaN(Number(e.target.value))
            ? formik.setFieldValue("running_meter", 0)
            : formik.setFieldValue("running_meter", Number(e.target.value));
        }}
        helperText={formik.touched.running_meter && formik.errors.running_meter}
        error={
          formik.touched.running_meter && Boolean(formik.errors.running_meter)
        }
      />
      <p>Vendor/Supplier</p>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <Select
          value={formik.values.vendor}
          onChange={(e) => formik.setFieldValue("vendor", e.target.value)}
        >
          <MenuItem value="" disabled>
            Select Vendor/Supplier
          </MenuItem>
          {data && !isPendingList
            ? data.data.map((item) => {
                return (
                  <MenuItem value={item.supplier_name}>
                    {item.supplier_name}
                  </MenuItem>
                );
              })
            : null}
        </Select>
      </FormControl>
      <p>
        Material Type<span className="text-red-500"> *</span>
      </p>
      <TextField
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.material_type}
        onChange={(e) => {
          if (e.target.value.length <= 200)
            formik.setFieldValue("material_type", e.target.value);
        }}
        helperText={formik.touched.material_type && formik.errors.material_type}
        error={
          formik.touched.material_type && Boolean(formik.errors.material_type)
        }
      />
      <p>
        Jenis Pisau<span className="text-red-500"> *</span>
      </p>
      <TextField
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.type}
        onChange={(e) => {
          if (e.target.value.length <= 200)
            formik.setFieldValue("type", e.target.value);
        }}
        helperText={formik.touched.type && formik.errors.type}
        error={formik.touched.type && Boolean(formik.errors.type)}
      />{" "}
      <p>
        Repeat Pisau<span className="text-red-500"> *</span>
      </p>
      <TextField
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.repeat}
        onChange={(e) => {
          if (e.target.value.length <= 300)
            formik.setFieldValue("repeat", e.target.value);
        }}
        helperText={formik.touched.repeat && formik.errors.repeat}
        error={formik.touched.repeat && Boolean(formik.errors.repeat)}
      />
      <p>
        Job Pisau<span className="text-red-500"> *</span>
      </p>
      <TextField
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.job_bag}
        disabled
        helperText={formik.touched.job_bag && formik.errors.job_bag}
        error={formik.touched.job_bag && Boolean(formik.errors.job_bag)}
      />{" "}
      <p>
        Internal Code<span className="text-red-500"> *</span>
      </p>
      <TextField
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.internal_code}
        onChange={(e) => formik.setFieldValue("internal_code", e.target.value)}
        helperText={formik.touched.internal_code && formik.errors.internal_code}
        error={
          formik.touched.internal_code && Boolean(formik.errors.internal_code)
        }
      />
      <p>
        Deskripsi<span className="text-red-500"> *</span>
      </p>
      <TextField
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.description}
        onChange={(e) => {
          if (e.target.value.length <= 100)
            formik.setFieldValue("description", e.target.value);
        }}
        helperText={formik.touched.description && formik.errors.description}
        error={formik.touched.description && Boolean(formik.errors.description)}
      />
      <p className="text-red-500">{errorMessage}</p>
      <SingleCalendar
        anchorElCalendarStart={anchorElCalendar}
        setAnchorElCalendarStart={setAnchorElCalendar}
        startDate={startDate}
        setStartDate={setStartDate}
      />
    </>
  );
};

export default AddDieCutModalContent;
