import axios from "axios";
import Cookies from "js-cookie";

const token = () => Cookies.get("accessToken");

export const createSupplierPO = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/purchase-order`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};