import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getSpkDetail, getSpkList } from "../../../services/SpkApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { secondsToHours, secondsToMinutes } from "date-fns";
import { getDetailSchedule, setNewJob } from "../../../services/scheduleApi";
import UpdateJobTimer from "./UpdateJobTimer";

const validationSchema = yup.object({
  schedule_access: yup.number(),
  spk_id: yup.number().min(1),
  internal_code: yup.string(),
  total_time: yup.number(),
  sliting_estimate: yup.string(),
  sliting_act: yup.string(),
  sheeting_estimate: yup.string(),
  sheeting_act: yup.string(),
  cutting_estimate: yup.string(),
  cutting_act: yup.string(),
  packing_roll_estimate: yup.string(),
  packing_roll_act: yup.string(),
  packing_sheet_estimate: yup.string(),
  packing_sheet_act: yup.string(),
  note: yup.string(),
  setup: yup.number().nullable(),
  breakdown_repair: yup.number().nullable(),
  run: yup.number().nullable(),
  cleaning_maintenance: yup.number().nullable(),
  break: yup.number().nullable(),
  no_job: yup.number().nullable(),
  qc_complete: yup.number().nullable(),
  rework: yup.number().nullable(),
});

const UpdateJobQC = () => {
  const navigate = useNavigate();
  const { spkId } = useParams();
  const timerName = [
    { name: "Setup", keyName: "setup" },
    { name: "Break Down Repair", keyName: "breakdown_repair" },
    { name: "Run", keyName: "run" },
    {
      name: "Cleaning & Maintenance",
      keyName: "cleaning_maintenance",
    },
    { name: "Break", keyName: "break" },
    { name: "No Job", keyName: "no_job" },
    { name: "QC Complete", keyName: "qc_complete" },
    { name: "Rework", keyName: "rework" },
  ];
  const [spk, setSpk] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeButton, setActiveButton] = useState(null);

  const { data: dataDetailSchedule, isFetching: isFetchingDetailSchedule } =
    useQuery({
      queryKey: ["schedule-detail", spkId],
      queryFn: () => getDetailSchedule(spkId),
      enabled: spkId !== undefined,
    });

  const { data: dataDetailSpk, isFetching } = useQuery({
    queryKey: [
      "spk-detail",
      spk?.id,
      dataDetailSchedule?.tooling_control?.spk_id,
    ],
    queryFn: () =>
      getSpkDetail(dataDetailSchedule?.tooling_control?.spk_id || spk?.id),
    enabled: !!dataDetailSchedule || !!spk?.id,
  });

  const formik = useFormik({
    initialValues: {
      schedule_access: 5,
      spk_id: dataDetailSchedule
        ? dataDetailSchedule.tooling_control.spk_id
        : 0,
      internal_code: dataDetailSchedule
        ? dataDetailSchedule.tooling_control.internal_code
        : "",
      total_time: dataDetailSchedule
        ? (Number(dataDetailSchedule.qc_information.setup) || 0) +
          (Number(dataDetailSchedule.qc_information.breakdown_repair) || 0) +
          (Number(dataDetailSchedule.qc_information.run) || 0) +
          (Number(dataDetailSchedule.qc_information.cleaning_maintenance) ||
            0) +
          (Number(dataDetailSchedule.qc_information.break) || 0) +
          (Number(dataDetailSchedule.qc_information.no_job) || 0) +
          (Number(dataDetailSchedule.qc_information.qc_complete) || 0) +
          (Number(dataDetailSchedule.qc_information.rework) || 0)
        : 0,
      setup: dataDetailSchedule
        ? Number(dataDetailSchedule.qc_information.setup)
        : null,
      breakdown_repair: dataDetailSchedule
        ? Number(dataDetailSchedule.qc_information.breakdown_repair)
        : null,
      run: dataDetailSchedule
        ? Number(dataDetailSchedule.qc_information.run)
        : null,
      cleaning_maintenance: dataDetailSchedule
        ? Number(dataDetailSchedule.qc_information.cleaning_maintenance)
        : null,
      break: dataDetailSchedule
        ? Number(dataDetailSchedule.qc_information.break)
        : null,
      no_job: dataDetailSchedule
        ? Number(dataDetailSchedule.qc_information.no_job)
        : null,
      qc_complete: dataDetailSchedule
        ? Number(dataDetailSchedule.qc_information.qc_complete)
        : null,
      rework: dataDetailSchedule
        ? Number(dataDetailSchedule.qc_information.rework)
        : null,
      sliting_estimate: dataDetailSchedule
        ? String(dataDetailSpk?.project[0]?.qc?.sliting)
        : "",
      sliting_act: dataDetailSchedule
        ? dataDetailSchedule.qc_information.sliting_act
        : "",
      sheeting_estimate: dataDetailSchedule
        ? String(dataDetailSpk?.project[0]?.qc?.sheeting)
        : "",
      sheeting_act: dataDetailSchedule
        ? dataDetailSchedule.qc_information.sheeting_act
        : "",
      cutting_estimate: dataDetailSchedule
        ? String(dataDetailSpk?.project[0]?.qc?.cutting)
        : "",
      cutting_act: dataDetailSchedule
        ? dataDetailSchedule.qc_information.cutting_act
        : "",
      packing_roll_estimate: dataDetailSchedule
        ? String(dataDetailSpk?.project[0]?.qc?.packing_roll)
        : "",
      packing_roll_act: dataDetailSchedule
        ? dataDetailSchedule.qc_information.packing_roll_act
        : "",
      packing_sheet_estimate: dataDetailSchedule
        ? String(dataDetailSpk?.project[0]?.qc?.packing_sheet)
        : "",
      packing_sheet_act: dataDetailSchedule
        ? dataDetailSchedule.qc_information.packing_sheet_act
        : "",
      note: dataDetailSchedule ? dataDetailSchedule.qc_information.note : "",
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values) => {
      const cleanedValues = Object.keys(values).reduce((acc, key) => {
        acc[key] =
          values[key] === "" ||
          values[key] === 0 ||
          values[key] === null ||
          values[key] === undefined
            ? null
            : values[key];
        return acc;
      }, {});

      mutateTakeJob(cleanedValues);
    },
  });

  const { mutate: mutateSpkList, data: dataSpkList } = useMutation({
    mutationFn: getSpkList,
    onSuccess: (data) => {
      if (spkId) setSpk(data?.data?.find((item) => item.id === Number(spkId)));
    },
  });

  const { mutate: mutateTakeJob, isPending } = useMutation({
    mutationFn: setNewJob,
    onSuccess: () => navigate("/schedule"),
    onError: (err) => setErrorMessage(err.message.id),
  });

  const findAllEstimate = (code) => {
    if (dataDetailSpk) {
      const qcValues = dataDetailSpk?.project?.find(
        (proj) => proj.internal_code === code
      ).qc;
      formik.setFieldValue("sliting_estimate", qcValues?.sliting?.toString());
      formik.setFieldValue("sheeting_estimate", qcValues?.sheeting?.toString());
      formik.setFieldValue("cutting_estimate", qcValues?.cutting?.toString());
      formik.setFieldValue(
        "packing_roll_estimate",
        qcValues?.packing_roll?.toString()
      );
      formik.setFieldValue(
        "packing_sheet_estimate",
        qcValues?.packing_sheet?.toString()
      );
    }
  };

  useEffect(() => {
    if (dataDetailSchedule)
      findAllEstimate(dataDetailSchedule.tooling_control.internal_code);
  }, [dataDetailSchedule]);

  useEffect(() => {
    mutateSpkList({
      offset: 1,
      limit: 10,
      schedule: 5,
      search: "",
      po_start: "",
      po_end: "",
      delivery_start: "",
      delivery_end: "",
      job_type: [],
      category: [],
      dies: [],
    });
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          label="SPK No"
          fullWidth
          sx={{ marginBottom: 2 }}
          disabled
          value={dataDetailSchedule?.tooling_control?.no_spk || ""}
        />
        <TextField
          label="Internal Code"
          fullWidth
          sx={{ marginBottom: 2 }}
          disabled
          value={dataDetailSchedule?.tooling_control?.internal_code || ""}
        />
        <TextField
          label="Label Name"
          fullWidth
          sx={{ marginBottom: 2 }}
          disabled
          value={dataDetailSchedule?.tooling_control?.label_name || ""}
        />

        <div className="my-4 grid grid-cols-4 grid-flow-row gap-2">
          {timerName.map((timer, i) => (
            <UpdateJobTimer
              timerInfo={timer}
              formik={formik}
              activeButton={activeButton}
              setActiveButton={setActiveButton}
              i={i}
            />
          ))}
        </div>

        <div className="flex items-center gap-2">
          <p className="">Total Time</p>
          <div className="py-2 px-8 bg-slate-100 rounded-md flex items-end">
            {secondsToHours(formik.values.total_time) > 0 ? (
              <>
                <p className="text-xl">
                  {secondsToHours(formik.values.total_time)}
                </p>
                <p className="mr-3">h</p>
              </>
            ) : null}
            {secondsToMinutes(formik.values.total_time) > 0 ? (
              <>
                <p className="text-xl">
                  {secondsToMinutes(formik.values.total_time)}
                </p>
                <p className="mr-3">m</p>{" "}
              </>
            ) : null}
            {formik.values.total_time > 0 ? (
              <>
                <p>{formik.values.total_time % 60}</p>
                <p>s</p>{" "}
              </>
            ) : (
              0
            )}
          </div>
        </div>

        <div className="my-4">
          <p className="font-bold">Slitting (HR)</p>
          <hr className="my-2" />
          <div className="mb-4 flex gap-2">
            <TextField
              fullWidth
              label="Estimation"
              disabled
              value={formik.values.sliting_estimate}
            />
            <TextField
              fullWidth
              label="Actual"
              value={formik.values.sliting_act}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue("sliting_act", e.target.value);
              }}
            />
          </div>
        </div>

        <div className="my-4">
          <p className="font-bold">Sheeting (HR)</p>
          <hr className="my-2" />
          <div className="mb-4 flex gap-2">
            <TextField
              fullWidth
              label="Estimation"
              disabled
              value={formik.values.sheeting_estimate}
            />
            <TextField
              fullWidth
              label="Actual"
              value={formik.values.sheeting_act}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue("sheeting_act", e.target.value);
              }}
            />
          </div>
        </div>

        <div className="my-4">
          <p className="font-bold">Cutting (HR)</p>
          <hr className="my-2" />
          <div className="mb-4 flex gap-2">
            <TextField
              fullWidth
              label="Estimation"
              disabled
              value={formik.values?.cutting_estimate}
            />
            <TextField
              fullWidth
              label="Actual"
              value={formik.values?.cutting_act}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue("cutting_act", e.target.value);
              }}
            />
          </div>
        </div>

        <div className="my-4">
          <p className="font-bold">Packing Roll (HR)</p>
          <hr className="my-2" />
          <div className="mb-4 flex gap-2">
            <TextField
              fullWidth
              label="Estimation"
              disabled
              value={formik.values?.packing_roll_estimate}
            />
            <TextField
              fullWidth
              label="Actual"
              value={formik.values?.packing_roll_act}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue("packing_roll_act", e.target.value);
              }}
            />
          </div>
        </div>

        <div className="my-4">
          <p className="font-bold">Packing Sheet (HR)</p>
          <hr className="my-2" />
          <div className="mb-4 flex gap-2">
            <TextField
              fullWidth
              label="Estimation"
              disabled
              value={formik.values?.packing_sheet_estimate}
            />
            <TextField
              fullWidth
              label="Actual"
              value={formik.values?.packing_sheet_act}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue("packing_sheet_act", e.target.value);
              }}
            />
          </div>
        </div>

        <TextField
          fullWidth
          label="Note"
          value={formik.values.note}
          onChange={(e) => formik.setFieldValue("note", e.target.value)}
          sx={{ marginBottom: 2 }}
        />

        <p className="text-right text-red-500 my-4">{errorMessage}</p>

        <div className="flex justify-end w-1/3 gap-2 float-right">
          <button
            className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
          <button
            className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
            disabled={!formik.isValid || isPending}
            type="submit"
          >
            Done
          </button>
        </div>
      </form>
    </>
  );
};

export default UpdateJobQC;
