import {
  CircularProgress,
  FormGroup,
  FormLabel,
  TextField,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getDetailSchedule } from "../../../services/scheduleApi";
import { format } from "date-fns";
import { secondsToHours } from "date-fns";
import { secondsToMinutes } from "date-fns";
import { FaClipboardList } from "react-icons/fa6";
import { useState } from "react";
import { getSpkDetail } from "../../../services/SpkApi";
import DetailSpk from "../spk/DetailSpk";

const ScheduleDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [type, setType] = useState(0);

  const getTotalQc = () => {
    return data && data.qc_information
      ? Number(data.qc_information.setup) +
          Number(data.qc_information.breakdown_repair) +
          Number(data.qc_information.run) +
          Number(data.qc_information.cleaning_maintenance) +
          Number(data.qc_information.break) +
          Number(data.qc_information.no_job) +
          Number(data.qc_information.qc_complete) +
          Number(data.qc_information.rework)
      : 0;
  };

  const { data, isFetching, error } = useQuery({
    queryKey: ["schedule-detail", id],
    queryFn: () => getDetailSchedule(id),
  });

  const { data: dataSpk, isFetching: isFetchingSpk } = useQuery({
    queryKey: ["detail-spk-schedule"],
    queryFn: () => getSpkDetail(data.tooling_control.spk_id),
    enabled:
      data !== undefined &&
      data !== null &&
      data.tooling_control !== undefined &&
      data.tooling_control.spk_id !== undefined,
  });

  return (
    <>
      <div className="w-full flex justify-end">
        <button
          onClick={() =>
            navigate(`/schedule/take-job/${id}`)
          }
          disabled={data && !data.tooling_control.spk_id}
          type="button"
          className="mb-4 px-8 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer disabled:bg-gray-300 disabled:cursor-default"
        >
          <FaClipboardList className="text-[18px]" />
          <p>Take A Job</p>
        </button>
      </div>

      <div className="grid grid-cols-2 mb-4">
        <div
          className={`border-2 rounded-l-md border-r-0 p-2 cursor-pointer hover:bg-gray-300 hover:text-black ${
            type === 0 ? "bg-[#18479D] text-white" : ""
          }`}
          onClick={() => setType(0)}
        >
          Schedule Detail
        </div>
        <div
          className={`border-2 rounded-r-md p-2 cursor-pointer hover:bg-gray-300 hover:text-black ${
            type === 1 ? "bg-[#18479D] text-white" : ""
          }`}
          onClick={() => setType(1)}
        >
          SPK Detail
        </div>
      </div>

      <>
        {type === 0 ? (
          <>
            {" "}
            <div className="border-2 rounded-md p-4 mb-8">
              <p className="font-bold text-xl">General Information</p>
              <hr className="my-4" />
              {data && !isFetching ? (
                <>
                  <div className="flex gap-4 mb-8 items-end">
                    <div className="flex gap-2 w-full">
                      <TextField
                        label="Plate"
                        disabled
                        fullWidth
                        value={
                          data.tooling_control.plate ? "Ready" : "Not Ready"
                        }
                        variant="standard"
                      />
                      <TextField
                        label="Die Cut"
                        disabled
                        fullWidth
                        value={
                          data.tooling_control.die_cut ? "Ready" : "Not Ready"
                        }
                        variant="standard"
                      />
                    </div>
                    <TextField
                      label="Internal Code"
                      disabled
                      fullWidth
                      value={data.tooling_control.internal_code}
                      variant="standard"
                    />
                  </div>
                  <div className="flex gap-4 mb-8 items-end">
                    <FormGroup fullWidth sx={{ width: "100%" }}>
                      <FormLabel sx={{ marginBottom: 4 }}>Material</FormLabel>
                      <div className="flex gap-2 w-full">
                        <TextField
                          label="Paper"
                          disabled
                          fullWidth
                          variant="standard"
                          value={
                            data.tooling_control.material_paper
                              ? "Ready"
                              : "Not Ready"
                          }
                        />
                        <TextField
                          label="BOPP"
                          disabled
                          fullWidth
                          variant="standard"
                          value={
                            data.tooling_control.material_bopp
                              ? "Ready"
                              : "Not Ready"
                          }
                        />
                        <TextField
                          label="Foil"
                          disabled
                          fullWidth
                          variant="standard"
                          value={
                            data.tooling_control.material_foil
                              ? "Ready"
                              : "Not Ready"
                          }
                        />
                      </div>
                    </FormGroup>

                    <div className="w-full">
                      <TextField
                        label="Label Name"
                        disabled
                        fullWidth
                        variant="standard"
                        sx={{ marginBottom: 4 }}
                        value={data.tooling_control.label_name}
                      />
                      <TextField
                        label="Gear"
                        disabled
                        fullWidth
                        variant="standard"
                        value={data.tooling_control.gear}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4 mb-8 items-end">
                    <div className="w-full">
                      <TextField
                        label="Tinta"
                        disabled
                        fullWidth
                        variant="standard"
                        sx={{ marginBottom: 4 }}
                        value={
                          data.tooling_control.material_tinta
                            ? "Ready"
                            : "Not Ready"
                        }
                      />
                      <TextField
                        label="Customer"
                        disabled
                        fullWidth
                        variant="standard"
                        value={data.tooling_control.customer}
                      />
                    </div>
                    <FormGroup fullWidth sx={{ width: "100%" }}>
                      <FormLabel sx={{ marginBottom: 4 }}>Quantity</FormLabel>
                      <div className="flex gap-2 w-full">
                        <TextField
                          label="Qty Order"
                          disabled
                          fullWidth
                          variant="standard"
                          value={data.tooling_control.quantity_order}
                        />
                        <TextField
                          label="Qty Produksi"
                          disabled
                          fullWidth
                          variant="standard"
                          value={data.tooling_control.quantity_production}
                        />
                        <TextField
                          label="Under/Over"
                          disabled
                          fullWidth
                          variant="standard"
                          value={data.tooling_control.quantity}
                        />
                      </div>
                    </FormGroup>
                  </div>
                  <div className="flex gap-4 mb-8 items-end">
                    <TextField
                      label="No. SPK"
                      disabled
                      fullWidth
                      variant="standard"
                      value={data.tooling_control.no_spk}
                    />
                    <TextField
                      label="M/C Printing"
                      disabled
                      fullWidth
                      variant="standard"
                      value={data.tooling_control.mc_printing}
                    />
                  </div>
                  <div className="flex gap-4 mb-8 items-end">
                    <TextField
                      label="Tanggal Jatuh Tempo"
                      disabled
                      fullWidth
                      variant="standard"
                      value={format(
                        Date(data.tooling_control.due_date),
                        "dd MMMM yyyy"
                      )}
                    />
                    <TextField
                      label="Tipe"
                      disabled
                      fullWidth
                      variant="standard"
                      value={
                        data.tooling_control.job_type === 1
                          ? "New"
                          : data.tooling_control.job_type === 2
                          ? "Repeat"
                          : "RWC"
                      }
                    />
                  </div>
                  <TextField
                    label="ISP"
                    sx={{ width: "50%", paddingRight: 1, marginBottom: 4 }}
                    disabled
                    fullWidth
                    variant="standard"
                    value={data.tooling_control.isp}
                  />
                </>
              ) : isFetching && error === null ? (
                <CircularProgress />
              ) : (
                <p className="text-red-500 text-center">{error.message.id}</p>
              )}
            </div>
            <div className="border-2 rounded-md p-4 mb-8">
              <p className="font-bold text-xl">Production Information</p>
              <hr className="my-4" />
              {data && !isFetching ? (
                <>
                  {" "}
                  <FormGroup fullWidth sx={{ width: "100%", marginBottom: 4 }}>
                    <FormLabel sx={{ marginBottom: 2 }}>Size</FormLabel>
                    <div className="flex gap-2 w-full">
                      <TextField
                        label="Estimated"
                        disabled
                        fullWidth
                        variant="standard"
                        value={data.production_information.size_est}
                      />
                      <TextField
                        label="Actual"
                        disabled
                        fullWidth
                        variant="standard"
                        value={data.production_information.size_act}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup fullWidth sx={{ width: "100%", marginBottom: 4 }}>
                    <FormLabel sx={{ marginBottom: 2 }}>Run (M)</FormLabel>
                    <div className="flex gap-2 w-full">
                      <TextField
                        label="Estimated"
                        disabled
                        fullWidth
                        variant="standard"
                        value={data.production_information.run_est}
                      />
                      <TextField
                        label="Actual"
                        disabled
                        fullWidth
                        variant="standard"
                        value={data.production_information.run_act}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup fullWidth sx={{ width: "100%", marginBottom: 4 }}>
                    <FormLabel sx={{ marginBottom: 2 }}>Time (HR)</FormLabel>
                    <div className="flex gap-2 w-full">
                      <TextField
                        label="Estimated"
                        disabled
                        fullWidth
                        variant="standard"
                        value={data.production_information.time_est}
                      />
                      <TextField
                        label="Actual"
                        disabled
                        fullWidth
                        variant="standard"
                        value={data.production_information.time_act}
                      />
                    </div>
                  </FormGroup>
                  <p className="text-xl font-bold mb-4">Action Time Details</p>
                  <div className="rounded-md">
                    <div className="block overflow-x-auto w-full rounded-md">
                      <table className="w-full">
                        <thead className="bg-[#18479D] text-white border-t border-b border-2">
                          <th className="p-3">Action</th>
                          <th className="p-3">Time Taken (s)</th>
                        </thead>
                        <tbody>
                          <tr className="border-t-2">
                            <td className="p-3">Setup</td>
                            <td className="p-3">
                              {data.production_information.setup}
                            </td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">Acc QC/Leader</td>
                            <td className="p-3">
                              {data.production_information.acc_qc}
                            </td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">Acc Customer/Sales</td>
                            <td className="p-3">
                              {data.production_information.acc_customer}
                            </td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">Printing Defect</td>
                            <td className="p-3">
                              {data.production_information.printing_defect}
                            </td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">Change Over</td>
                            <td className="p-3">
                              {data.production_information.change_over}
                            </td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">Breakdown Repair</td>
                            <td className="p-3">
                              {data.production_information.breakdown_repair}
                            </td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">Breakdown UV Lamp</td>
                            <td className="p-3">
                              {data.production_information.breakdown_uv}
                            </td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">Run</td>
                            <td className="p-3">
                              {data.production_information.run}
                            </td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">Cleaning & Maintenance</td>
                            <td className="p-3">
                              {data.production_information.cleaning_maintenance}
                            </td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">Tunggu Material</td>
                            <td className="p-3">
                              {data.production_information.wait_material}
                            </td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">Break</td>
                            <td className="p-3">
                              {data.production_information.break}
                            </td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">No Job</td>
                            <td className="p-3">
                              {data.production_information.no_job}
                            </td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">Paper Problem</td>
                            <td className="p-3">
                              {data.production_information.paper_problem}
                            </td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">Color Adjust</td>
                            <td className="p-3">
                              {data.production_information.color_adjust}
                            </td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">Plate Problem</td>
                            <td className="p-3">
                              {data.production_information.plate_problem}
                            </td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">Screen Problem</td>
                            <td className="p-3">
                              {data.production_information.screen_problem}
                            </td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">Die Cut Problem</td>
                            <td className="p-3">
                              {data.production_information.diecut_problem}
                            </td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">Register Problem</td>
                            <td className="p-3">
                              {data.production_information.register_problem}
                            </td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">Press Complete</td>
                            <td className="p-3">
                              {data.production_information.press_complete}
                            </td>
                          </tr>
                          <tr className="border-t-2 border-b-4">
                            <td className="p-3">Rework/Reprint</td>
                            <td className="p-3">
                              {data.production_information.rework}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <p className="w-full text-right mt-3 font-bold">
                    Total Action Time:
                    {secondsToHours(data.production_information.total_time) > 0
                      ? secondsToHours(data.production_information.total_time) +
                        "h"
                      : ""}{" "}
                    {secondsToMinutes(data.production_information.total_time) >
                    0
                      ? secondsToMinutes(
                          data.production_information.total_time
                        ) + "m"
                      : ""}{" "}
                    {data.production_information.total_time > 0
                      ? (data.production_information.total_time % 60) + "s"
                      : ""}
                  </p>
                  <div className="flex gap-4 mt-8 items-end">
                    <TextField
                      label="Production Note"
                      disabled
                      fullWidth
                      variant="standard"
                      value={data.production_information.production_note}
                    />
                    <TextField
                      label="Press Completed By"
                      disabled
                      fullWidth
                      variant="standard"
                      value={data.production_information.press_by}
                    />
                  </div>
                </>
              ) : isFetching && error === null ? (
                <CircularProgress />
              ) : (
                <p className="text-red-500 text-center">{error.message.id}</p>
              )}
            </div>
            <div className="border-2 rounded-md p-4 mb-8">
              <p className="font-bold text-xl">QC Information</p>
              <hr className="my-4" />
              {data && !isFetching ? (
                <>
                  <div className="flex gap-4 mb-8 items-end">
                    <FormGroup fullWidth sx={{ width: "100%" }}>
                      <FormLabel sx={{ marginBottom: 2 }}>
                        Sliting (HR)
                      </FormLabel>
                      <div className="flex gap-2 w-full">
                        <TextField
                          label="Estimated"
                          disabled
                          fullWidth
                          variant="standard"
                          value={Number(
                            parseFloat(
                              data.qc_information.sliting_estimate
                            ).toFixed(4)
                          )}
                        />
                        <TextField
                          label="Actual"
                          disabled
                          fullWidth
                          variant="standard"
                          value={Number(
                            parseFloat(data.qc_information.sliting_act).toFixed(
                              4
                            )
                          )}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup fullWidth sx={{ width: "100%" }}>
                      <FormLabel sx={{ marginBottom: 2 }}>
                        Packing Roll (HR)
                      </FormLabel>
                      <div className="flex gap-2 w-full">
                        <TextField
                          label="Estimated"
                          disabled
                          fullWidth
                          variant="standard"
                          value={Number(
                            parseFloat(
                              data.qc_information.packing_roll_estimate
                            ).toFixed(4)
                          )}
                        />
                        <TextField
                          label="Actual"
                          disabled
                          fullWidth
                          variant="standard"
                          value={Number(
                            parseFloat(
                              data.qc_information.packing_roll_act
                            ).toFixed(4)
                          )}
                        />
                      </div>
                    </FormGroup>
                  </div>
                  <div className="flex gap-4 mb-8 items-end">
                    <FormGroup fullWidth sx={{ width: "100%" }}>
                      <FormLabel sx={{ marginBottom: 2 }}>
                        Sheeting (HR)
                      </FormLabel>
                      <div className="flex gap-2 w-full">
                        <TextField
                          label="Estimated"
                          disabled
                          fullWidth
                          variant="standard"
                          value={Number(
                            parseFloat(
                              data.qc_information.sheeting_estimate
                            ).toFixed(4)
                          )}
                        />
                        <TextField
                          label="Actual"
                          disabled
                          fullWidth
                          variant="standard"
                          value={Number(
                            parseFloat(
                              data.qc_information.sheeting_act
                            ).toFixed(4)
                          )}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup fullWidth sx={{ width: "100%" }}>
                      <FormLabel sx={{ marginBottom: 2 }}>
                        Packing Sheet (HR)
                      </FormLabel>
                      <div className="flex gap-2 w-full">
                        <TextField
                          label="Estimated"
                          disabled
                          fullWidth
                          variant="standard"
                          value={Number(
                            parseFloat(
                              data.qc_information.packing_sheet_estimate
                            ).toFixed(4)
                          )}
                        />
                        <TextField
                          label="Actual"
                          disabled
                          fullWidth
                          variant="standard"
                          value={Number(
                            parseFloat(
                              data.qc_information.packing_sheet_act
                            ).toFixed(4)
                          )}
                        />
                      </div>
                    </FormGroup>
                  </div>
                  <FormGroup fullWidth sx={{ width: "50%", paddingRight: 1 }}>
                    <FormLabel sx={{ marginBottom: 2 }}>Cutting (HR)</FormLabel>
                    <div className="flex gap-2 w-full">
                      <TextField
                        label="Estimated"
                        disabled
                        fullWidth
                        variant="standard"
                        value={Number(
                          parseFloat(
                            data.qc_information.cutting_estimate
                          ).toFixed(4)
                        )}
                      />
                      <TextField
                        label="Actual"
                        disabled
                        fullWidth
                        variant="standard"
                        value={Number(
                          parseFloat(data.qc_information.cutting_act).toFixed(4)
                        )}
                      />
                    </div>
                  </FormGroup>
                  <p className="text-xl font-bold mb-4 mt-8">
                    Action Time Details
                  </p>
                  <div className="rounded-md">
                    <div className="block overflow-x-auto w-full rounded-md">
                      <table className="w-full">
                        <thead className="bg-[#18479D] text-white border-t border-b border-2">
                          <th className="p-3">Action</th>
                          <th className="p-3">Time Taken (s)</th>
                        </thead>
                        <tbody>
                          <tr className="border-t-2">
                            <td className="p-3">Setup</td>
                            <td className="p-3">{data.qc_information.setup}</td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">Breakdown Repair</td>
                            <td className="p-3">
                              {data.qc_information.breakdown_repair}
                            </td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">Run</td>
                            <td className="p-3">{data.qc_information.run}</td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">Cleaning & Maintenance</td>
                            <td className="p-3">
                              {data.qc_information.cleaning_maintenance}
                            </td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">Break</td>
                            <td className="p-3">{data.qc_information.break}</td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">No Job</td>
                            <td className="p-3">
                              {data.qc_information.no_job}
                            </td>
                          </tr>
                          <tr className="border-t-2">
                            <td className="p-3">QC Complete</td>
                            <td className="p-3">
                              {data.qc_information.qc_complete}
                            </td>
                          </tr>
                          <tr className="border-t-2 border-b-4">
                            <td className="p-3">Rework/Reprint</td>
                            <td className="p-3">
                              {data.qc_information.rework}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <p className="w-full text-right mt-3 font-bold">
                    Total Action Time:
                    {secondsToHours(getTotalQc()) > 0
                      ? secondsToHours(getTotalQc()) + "h"
                      : ""}{" "}
                    {secondsToMinutes(getTotalQc()) > 0
                      ? secondsToMinutes(getTotalQc()) + "m"
                      : ""}{" "}
                    {getTotalQc() > 0 ? (getTotalQc() % 60) + "s" : ""}
                  </p>
                  <div className="flex gap-4 mt-8 items-end">
                    <TextField
                      label="QC Note"
                      disabled
                      fullWidth
                      variant="standard"
                      value={data.qc_information.note}
                    />
                    <TextField
                      label="QC Completed By"
                      disabled
                      fullWidth
                      variant="standard"
                      value={data.qc_information.qc_by}
                    />
                  </div>
                </>
              ) : isFetching && error === null ? (
                <CircularProgress />
              ) : (
                <p className="text-red-500 text-center">{error.message.id}</p>
              )}
            </div>
          </>
        ) : (
          <>
            {dataSpk && !isFetchingSpk ? (
              <>
                <DetailSpk schedId={data.tooling_control.spk_id} />
              </>
            ) : isFetchingSpk ? (
              <div className="my-4 flex justify-center">
                <CircularProgress size={20} />
              </div>
            ) : (
              <p className="text-red-500 w-full text-center my-4">
                Terjadi kesalahan dalam mengambil detail SPK
              </p>
            )}
          </>
        )}
      </>
    </>
  );
};

export default ScheduleDetail;
