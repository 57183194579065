import { Autocomplete, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import ModalInput from "../../common/ModalInput";
import { deleteInvoiceCategory } from "../../../services/supplierInvoiceApi";

const DeleteModalReturnSupplier = ({
  dataReturSupplier,
  setOpenDeleteModal,
  openDeleteModal,
  setRefetchList,
  type,
}) => {
  console.log("ini adalaah type", type)
  const [selectedReturSupplier, setSelectedReturSupplier] = useState(null);
  const [isDelete, setIsDelete] = useState(false);

  const { mutate: mutateDelete, isPending: isPendingDelete } = useMutation({
    mutationFn: deleteInvoiceCategory,
    enable: isDelete,
    onSuccess: () => {
      setOpenDeleteModal(false);
      setIsDelete(false);
      setRefetchList(true);
    },
  });

  useEffect(() => {
    setSelectedReturSupplier(null);
  }, [openDeleteModal]);

  useEffect(() => {
    if (isDelete) mutateDelete(selectedReturSupplier.id);
  }, [isDelete]);

  return (
    <>
      {type === "non-conformity" && (
        <ModalInput
          title="Hapus Data Type Non Conformity"
          subtitle="Pilih type non conformity yang ingin dihapus"
          content={
            <>
              <Autocomplete
                sx={{ width: "100%" }}
                disablePortal
                value={selectedReturSupplier}
                onChange={(_, option) => {
                  setSelectedReturSupplier(option);
                }}
                options={dataReturSupplier ? [...dataReturSupplier] : []}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Type Non Conformity" />
                )}
              />
            </>
          }
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          hasButton={true}
          buttonText="Hapus"
          setTriggerFunc={setIsDelete}
          isDisable={
            !dataReturSupplier || !selectedReturSupplier || isPendingDelete
          }
        />
      )}

      {type === "status" && (
        <ModalInput
          title="Hapus Data Status"
          subtitle="Pilih status yang ingin dihapus"
          content={
            <>
              <Autocomplete
                sx={{ width: "100%" }}
                disablePortal
                value={selectedReturSupplier}
                onChange={(_, option) => {
                  setSelectedReturSupplier(option);
                }}
                options={dataReturSupplier ? [...dataReturSupplier] : []}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Status" />
                )}
              />
            </>
          }
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          hasButton={true}
          buttonText="Hapus"
          setTriggerFunc={setIsDelete}
          isDisable={
            !dataReturSupplier || !selectedReturSupplier || isPendingDelete
          }
        />
      )}
    </>
  );
};

export default DeleteModalReturnSupplier;
