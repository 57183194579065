import { TextField } from "@mui/material";

const SpkCardInfo = ({ proj, formik, i, isDetail, isEdit }) => {
  return (
    <>
      {proj ? (
        <>
          <p className="font-bold mb-4">Detail Information Project</p>
          <div className="my-2 flex w-full gap-2 items-end">
            <TextField
              fullWidth
              label="Internal Code"
              disabled
              value={isDetail ? proj.internal_code : proj.detail.internal_code}
              sx={{ background: "#f1f5f9" }}
            />
            <TextField
              fullWidth
              label="Qty Stock"
              disabled
              value={isDetail ? proj.qty_stock : proj.detail.qty_stock}
              sx={{ background: "#f1f5f9" }}
            />
          </div>
          <div className="my-2 flex w-full gap-2 items-end">
            <TextField
              fullWidth
              label="Nama Label"
              disabled
              value={isDetail ? proj.label_name : proj.detail.label_name}
              sx={{ background: "#f1f5f9" }}
            />
            <TextField
              fullWidth
              label="Qty Produksi"
              sx={{
                background: isEdit !== undefined && !isEdit ? "#f1f5f9" : "white",
              }}
              disabled={isEdit !== undefined && !isEdit}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue(
                    `project[${i}].detail.qty_production`,
                    Number(e.target.value)
                  );
              }}
              value={
                formik.values.project[i]
                  ? formik.values.project[i].detail.qty_production
                  : 0
              }
            />
          </div>
          <div className="my-2 flex w-full gap-2 items-end">
            <TextField
              fullWidth
              label="Label Size"
              disabled
              value={isDetail ? proj.label_size : proj.detail.label_size}
              sx={{ background: "#f1f5f9" }}
            />
            <TextField
              fullWidth
              label="Finishing"
              disabled={isDetail}
              value={isDetail ? proj.finishing : proj.detail.finishing}
              onChange={(e) =>
                formik.setFieldValue(
                  `project[${i}].detail.finishing`,
                  e.target.value
                )
              }
            />
          </div>
          <div className="my-2">
            <TextField
              fullWidth
              label="Qty Order"
              disabled
              sx={{
                width: "50%",
                marginRight: 1,
                background: "#f1f5f9",
              }}
              value={isDetail ? proj.qty_order : proj.detail.qty_order}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default SpkCardInfo;
