import Logo from "../../../assets/logo.png";
import { useEffect } from "react";
import JsBarcode from "jsbarcode";
import jsPDF from "jspdf";
import { format } from "date-fns";

const BarcodeJobBag = ({ dieData, isDownload, setIsDownload }) => {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, dieData.job_bag);
  const barcode = canvas.toDataURL("image/png");

  useEffect(() => {
    if (isDownload && dieData) {
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [40, 100],
      });

      doc.addImage(barcode, "png", 20, 2, 60, 18);
      doc.setFontSize(4);

      doc.setLineWidth(0.3);
      doc.line(10, 21, 90, 21);

      doc.addImage(Logo, "png", 13, 24, 30, 10);
      doc.text(`No. Pisau: ${dieData.code}`, 50, 23);
      doc.text(`Tanggal Masuk: ${format(dieData.date, "yyyy-MM-dd")}`, 50, 25);
      doc.text(`Length: ${dieData.length}`, 50, 27);
      doc.text(`Width: ${dieData.width}`, 50, 29);
      doc.text(`Jenis Pisau: ${dieData.type}`, 50, 31);
      doc.text(`Repeat Pisau: ${dieData.repeat}`, 50, 33);
      doc.text(`Meter Running: ${dieData.running_meter}`, 50, 35);
      doc.text(`Gap Along: ${dieData.gap_along}`, 50, 37);
      doc.text(`Gap Across: ${dieData.gap_across}`, 50, 39);
      doc.text(`Up Along: ${dieData.up_along}`, 50, 41);
      doc.text(`Up Across: ${dieData.up_across}`, 50, 43);
      doc.text(`Vendor: ${dieData.vendor}`, 50, 45);
      doc.text(`Material type: ${dieData.material_type}`, 50, 47);

      doc.save(dieData.job_bag + ".pdf");

      setIsDownload(false);
    }
  }, [isDownload]);
  return <></>;
};

export default BarcodeJobBag;
