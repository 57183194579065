import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getSpkDetail, getSpkList } from "../../../services/SpkApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { secondsToHours, secondsToMinutes } from "date-fns";
import { getDetailSchedule, setNewJob } from "../../../services/scheduleApi";
import UpdateJobTimer from "./UpdateJobTimer";

const validationSchema = yup.object({
  schedule_access: yup.number(),
  spk_id: yup.number().min(1),
  internal_code: yup.string().required(),
  total_time: yup.number(),
  size_estimate: yup.string(),
  size_act: yup.string(),
  run_estimate: yup.string(),
  run_act: yup.string(),
  time_estimate: yup.string(),
  time_act: yup.string(),
  note: yup.string(),
  setup: yup.number(),
  acc_qc: yup.number(),
  acc_customer: yup.number(),
  printing_defect: yup.number(),
  change_over: yup.number(),
  breakdown_repair: yup.number(),
  breakdown_uv: yup.number(),
  run: yup.number(),
  cleaning_maintenance: yup.number(),
  wait_material: yup.number(),
  break: yup.number(),
  no_job: yup.number(),
  paper_problem: yup.number(),
  color_adjust: yup.number(),
  plate_problem: yup.number(),
  screen_problem: yup.number(),
  diecut_problem: yup.number(),
  register_problem: yup.number(),
  press_complete: yup.number(),
  rework: yup.number(),
});

const UpdateJobOperator = () => {
  const navigate = useNavigate();
  const { spkId } = useParams();
  const timerName = [
    { name: "Setup", keyName: "setup" },
    { name: "Acc QC/Leader", keyName: "acc_qc" },
    { name: "Acc Customer/Sales", keyName: "acc_customer" },
    { name: "Printing Defect", keyName: "printing_defect" },
    { name: "Change Over", keyName: "change_over" },
    { name: "Break Down Repair", keyName: "breakdown_repair" },
    { name: "Break Down UV Lamp", keyName: "breakdown_uv" },
    { name: "Run", keyName: "run" },
    {
      name: "Cleaning & Maintenance",
      keyName: "cleaning_maintenance",
    },
    { name: "Tunggu Material", keyName: "wait_material" },
    { name: "Break", keyName: "break" },
    { name: "No Job", keyName: "no_job" },
    { name: "Paper Problem", keyName: "paper_problem" },
    { name: "Color Adjust", keyName: "color_adjust" },
    { name: "Plate Problem", keyName: "plate_problem" },
    { name: "Screen Problem", keyName: "screen_problem" },
    { name: "Die Cut Problem", keyName: "diecut_problem" },
    { name: "Register Problem", keyName: "register_problem" },
    { name: "Press Complete", keyName: "press_complete" },
    { name: "Rework/Reprint", keyName: "rework" },
  ];
  const [spk, setSpk] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeButton, setActiveButton] = useState(null);

  const { data: dataDetailSchedule, isFetching: isFetchingDetailSchedule } =
    useQuery({
      queryKey: ["schedule-detail", spkId],
      queryFn: () => getDetailSchedule(spkId),
      enabled: spkId !== undefined,
    });

  const { data: dataDetailSpk, isFetching } = useQuery({
    queryKey: [
      "spk-detail",
      spk?.id,
      dataDetailSchedule?.tooling_control?.spk_id,
    ],
    queryFn: () =>
      getSpkDetail(dataDetailSchedule?.tooling_control?.spk_id || spk?.id),
    enabled: !!dataDetailSchedule || !!spk?.id,
  });

  const formik = useFormik({
    initialValues: {
      schedule_access: 4,
      spk_id: dataDetailSchedule
        ? dataDetailSchedule.tooling_control.spk_id
        : 0,
      internal_code: dataDetailSchedule
        ? dataDetailSchedule.tooling_control.internal_code
        : "",
      total_time: dataDetailSchedule
        ? (Number(dataDetailSchedule.production_information.setup) || 0) +
          (Number(dataDetailSchedule.production_information.acc_qc) || 0) +
          (Number(dataDetailSchedule.production_information.acc_customer) ||
            0) +
          (Number(dataDetailSchedule.production_information.printing_defect) ||
            0) +
          (Number(dataDetailSchedule.production_information.change_over) || 0) +
          (Number(dataDetailSchedule.production_information.breakdown_repair) ||
            0) +
          (Number(dataDetailSchedule.production_information.breakdown_uv) ||
            0) +
          (Number(dataDetailSchedule.production_information.run) || 0) +
          (Number(
            dataDetailSchedule.production_information.cleaning_maintenance
          ) || 0) +
          (Number(dataDetailSchedule.production_information.wait_material) ||
            0) +
          (Number(dataDetailSchedule.production_information.break) || 0) +
          (Number(dataDetailSchedule.production_information.no_job) || 0) +
          (Number(dataDetailSchedule.production_information.paper_problem) ||
            0) +
          (Number(dataDetailSchedule.production_information.color_adjust) ||
            0) +
          (Number(dataDetailSchedule.production_information.plate_problem) ||
            0) +
          (Number(dataDetailSchedule.production_information.screen_problem) ||
            0) +
          (Number(dataDetailSchedule.production_information.diecut_problem) ||
            0) +
          (Number(dataDetailSchedule.production_information.register_problem) ||
            0) +
          (Number(dataDetailSchedule.production_information.press_complete) ||
            0) +
          (Number(dataDetailSchedule.production_information.rework) || 0)
        : 0,
      setup: dataDetailSchedule
        ? Number(dataDetailSchedule.production_information.setup)
        : null,
      acc_qc: dataDetailSchedule
        ? Number(dataDetailSchedule.production_information.acc_qc)
        : null,
      acc_customer: dataDetailSchedule
        ? Number(dataDetailSchedule.production_information.acc_customer)
        : null,
      printing_defect: dataDetailSchedule
        ? Number(dataDetailSchedule.production_information.printing_defect)
        : null,
      change_over: dataDetailSchedule
        ? Number(dataDetailSchedule.production_information.change_over)
        : null,
      breakdown_repair: dataDetailSchedule
        ? Number(dataDetailSchedule.production_information.breakdown_repair)
        : null,
      breakdown_uv: dataDetailSchedule
        ? Number(dataDetailSchedule.production_information.breakdown_uv)
        : null,
      run: dataDetailSchedule
        ? Number(dataDetailSchedule.production_information.run)
        : null,
      cleaning_maintenance: dataDetailSchedule
        ? Number(dataDetailSchedule.production_information.cleaning_maintenance)
        : null,
      wait_material: dataDetailSchedule
        ? Number(dataDetailSchedule.production_information.wait_material)
        : null,
      break: dataDetailSchedule
        ? Number(dataDetailSchedule.production_information.break)
        : null,
      no_job: dataDetailSchedule
        ? Number(dataDetailSchedule.production_information.no_job)
        : null,
      paper_problem: dataDetailSchedule
        ? Number(dataDetailSchedule.production_information.paper_problem)
        : null,
      color_adjust: dataDetailSchedule
        ? Number(dataDetailSchedule.production_information.color_adjust)
        : null,
      plate_problem: dataDetailSchedule
        ? Number(dataDetailSchedule.production_information.plate_problem)
        : null,
      screen_problem: dataDetailSchedule
        ? Number(dataDetailSchedule.production_information.screen_problem)
        : null,
      diecut_problem: dataDetailSchedule
        ? Number(dataDetailSchedule.production_information.diecut_problem)
        : null,
      register_problem: dataDetailSchedule
        ? Number(dataDetailSchedule.production_information.register_problem)
        : null,
      press_complete: dataDetailSchedule
        ? Number(dataDetailSchedule.production_information.press_complete)
        : null,
      rework: dataDetailSchedule
        ? Number(dataDetailSchedule.production_information.rework)
        : null,
      size_estimate: dataDetailSpk?.project[0]?.material[0]?.size,
      size_act: dataDetailSchedule
        ? dataDetailSchedule.production_information.size_act
        : "",
      run_estimate: dataDetailSpk?.project[0]?.system_estimate[0]?.total_system,
      run_act: dataDetailSchedule
        ? dataDetailSchedule.production_information.run_act
        : "",
      time_estimate: dataDetailSpk?.project[0]?.hr_estimate[0]?.total_hr,
      time_act: dataDetailSchedule
        ? dataDetailSchedule.production_information.time_act
        : "",
      note: dataDetailSchedule
        ? dataDetailSchedule.production_information.production_note
        : "",
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values) => {
      const cleanedValues = Object.keys(values).reduce((acc, key) => {
        acc[key] =
          values[key] === "" ||
          values[key] === 0 ||
          values[key] === null ||
          values[key] === undefined
            ? null
            : values[key];
        return acc;
      }, {});

      mutateTakeJob(cleanedValues);
    },
  });

  const { mutate: mutateSpkList, data: dataSpkList } = useMutation({
    mutationFn: getSpkList,
    onSuccess: (data) => {
      if (spkId) setSpk(data?.data?.find((item) => item.id === Number(spkId)));
    },
  });

  const { mutate: mutateTakeJob, isPending } = useMutation({
    mutationFn: setNewJob,
    onSuccess: () => navigate("/schedule"),
    onError: (err) => setErrorMessage(err.message.id),
  });

  const findAllEstimate = (code) => {
    if (dataDetailSpk) {
      const project = dataDetailSpk?.project?.find(
        (proj) => proj.internal_code === code
      );
      formik.setFieldValue("size_estimate", project?.adjustment_mm?.toString());
      formik.setFieldValue(
        "run_estimate",
        project?.material[0]?.consumption?.toString()
      );
      formik.setFieldValue(
        "time_estimate",
        project?.hr_estimate
          .reduce((acc, num) => acc + num.total_hr, 0)
          .toString()
      );
    }
  };

  useEffect(() => {
    if (dataDetailSchedule)
      findAllEstimate(dataDetailSchedule.tooling_control.internal_code);
  }, [dataDetailSchedule]);

  useEffect(() => {
    mutateSpkList({
      offset: 1,
      limit: 10,
      schedule: 4,
      search: "",
      po_start: "",
      po_end: "",
      delivery_start: "",
      delivery_end: "",
      job_type: [],
      category: [],
      dies: [],
    });
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          label="SPK No"
          fullWidth
          sx={{ marginBottom: 2 }}
          disabled
          value={dataDetailSchedule?.tooling_control?.no_spk || ""}
        />
        <TextField
          label="Internal Code"
          fullWidth
          sx={{ marginBottom: 2 }}
          disabled
          value={dataDetailSchedule?.tooling_control?.internal_code || ""}
        />
        <TextField
          label="Label Name"
          fullWidth
          sx={{ marginBottom: 2 }}
          disabled
          value={dataDetailSchedule?.tooling_control?.label_name || ""}
        />

        <div className="my-4 grid grid-cols-4 grid-flow-row gap-2">
          {timerName.map((timer, i) => (
            <UpdateJobTimer
              timerInfo={timer}
              formik={formik}
              activeButton={activeButton}
              setActiveButton={setActiveButton}
              i={i}
            />
          ))}
        </div>

        <div className="flex items-center gap-2">
          <p className="">Total Time</p>
          <div className="py-2 px-8 bg-slate-100 rounded-md flex items-end">
            {secondsToHours(formik.values.total_time) > 0 ? (
              <>
                <p className="text-xl">
                  {secondsToHours(formik.values.total_time)}
                </p>
                <p className="mr-3">h</p>
              </>
            ) : null}
            {secondsToMinutes(formik.values.total_time) > 0 ? (
              <>
                <p className="text-xl">
                  {secondsToMinutes(formik.values.total_time)}
                </p>
                <p className="mr-3">m</p>{" "}
              </>
            ) : null}
            {formik.values.total_time > 0 ? (
              <>
                <p>{formik.values.total_time % 60}</p>
                <p>s</p>{" "}
              </>
            ) : (
              0
            )}
          </div>
        </div>

        <div className="my-4">
          <p className="font-bold">Size</p>
          <hr className="my-2" />
          <div className="mb-4 flex gap-2">
            <TextField
              fullWidth
              label="Estimation"
              disabled
              value={formik.values.size_estimate}
            />
            <TextField
              fullWidth
              label="Actual"
              value={formik.values.size_act}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue("size_act", e.target.value);
              }}
            />
          </div>
        </div>

        <div className="my-4">
          <p className="font-bold">Run (M)</p>
          <hr className="my-2" />
          <div className="mb-4 flex gap-2">
            <TextField
              fullWidth
              label="Estimation"
              disabled
              value={formik.values.run_estimate}
            />
            <TextField
              fullWidth
              label="Actual"
              value={formik.values.run_act}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue("run_act", e.target.value);
              }}
            />
          </div>
        </div>

        <div className="my-4">
          <p className="font-bold">Time (HR)</p>
          <hr className="my-2" />
          <div className="mb-4 flex gap-2">
            <TextField
              fullWidth
              label="Estimation"
              disabled
              value={formik.values.time_estimate}
            />
            <TextField
              fullWidth
              label="Actual"
              value={formik.values.time_act}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue("time_act", e.target.value);
              }}
            />
          </div>
        </div>

        <TextField
          fullWidth
          label="Note"
          value={formik.values.note}
          onChange={(e) => formik.setFieldValue("note", e.target.value)}
          sx={{ marginBottom: 2 }}
        />

        <p className="text-right text-red-500 my-4">{errorMessage}</p>

        <div className="flex justify-end w-1/3 gap-2 float-right">
          <button
            className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
            onClick={() => navigate("/schedule")}
            type="button"
          >
            Cancel
          </button>
          <button
            className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
            disabled={!formik.isValid || isPending}
            type="submit"
          >
            Done
          </button>
        </div>
      </form>
    </>
  );
};

export default UpdateJobOperator;
