import { Search } from "@mui/icons-material";
import {
  InputAdornment,
  MenuItem,
  Select,
  Pagination,
  PaginationItem,
  TextField,
  CircularProgress,
  FormGroup,
  FormLabel,
  Autocomplete,
} from "@mui/material";
import { useEffect, useState } from "react";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FaArrowLeftLong, FaArrowRightLong, FaTrash } from "react-icons/fa6";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IoPencilOutline } from "react-icons/io5";
import ModalInput from "../../common/ModalInput";
import {
  deletePlat,
  getPlatList,
  setPlat,
} from "../../../services/platUsageApi";
import { format } from "date-fns";
import { useFormik } from "formik";
import * as yup from "yup";
import SingleCalendar from "../../common/SingleCalendar";
import { getSpkList } from "../../../services/SpkApi";

const validationSchema = yup.object({
  id: yup.number(),
  job: yup.string().required(),
  spk_number: yup.string().required(),
  po_number: yup.string().required(),
  est_color: yup.string().required(),
  act_color: yup.string().required(),
  length: yup.number().min(1).required(),
  width: yup.number().min(1).required(),
  total: yup.number().min(1).required(),
  date: yup.string().required(),
  opening_stock: yup.number().min(1).required(),
  ending_stock: yup.number().min(1).required(),
  name: yup.string().required(),
  type: yup.string().required(),
});

const PlatUsageList = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [jobType, setJobType] = useState(0);
  const [page, setPage] = useState(1);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedPlat, setSelectedPlat] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [platDate, setPlatDate] = useState(null);
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);

  const getJobId = () => {
    return formik.values.job === ""
      ? 0
      : formik.values.job === "N"
      ? 1
      : formik.values.job === "R"
      ? 2
      : 3;
  };

  const formik = useFormik({
    initialValues: {
      id: selectedPlat ? selectedPlat.id : 0,
      job: selectedPlat && selectedPlat.job ? selectedPlat.job : "",
      spk_number:
        selectedPlat && selectedPlat.spk_number
          ? selectedPlat.spk_number
          : null,
      po_number:
        selectedPlat && selectedPlat.po_number ? selectedPlat.po_number : "",
      est_color:
        selectedPlat && selectedPlat.est_color ? selectedPlat.est_color : "",
      act_color:
        selectedPlat && selectedPlat.act_color ? selectedPlat.act_color : "",
      length: selectedPlat && selectedPlat.length ? selectedPlat.length : 0,
      width: selectedPlat && selectedPlat.width ? selectedPlat.width : 0,
      total: selectedPlat && selectedPlat.total ? selectedPlat.total : 0,
      date:
        selectedPlat && selectedPlat.date
          ? format(new Date(selectedPlat.date), "yyyy-MM-dd")
          : "",
      opening_stock:
        selectedPlat && selectedPlat.opening_stock
          ? selectedPlat.opening_stock
          : 0,
      ending_stock:
        selectedPlat && selectedPlat.ending_stock
          ? selectedPlat.ending_stock
          : 0,
      name: selectedPlat && selectedPlat.name ? selectedPlat.name : "",
      type: selectedPlat && selectedPlat.type ? selectedPlat.type : "",
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
  });

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["plat-list", page, fetchLimit, jobType, search],
    queryFn: () =>
      getPlatList(
        page,
        fetchLimit,
        jobType === 0 ? "" : jobType === 1 ? "N" : jobType === 2 ? "R" : "RWC",
        search
      ),
  });

  const { mutate: mutateDelete, isPending } = useMutation({
    mutationFn: deletePlat,
    onSuccess: () => {
      refetch();
      setOpenDelete(false);
    },
    onError: (err) => setErrorMessage(err.message.id),
  });

  const { mutate: mutatePlat, isPending: isPendingEdit } = useMutation({
    mutationFn: setPlat,
    onSuccess: () => {
      refetch();
      setOpenEdit(false);
    },
    onError: (err) => setErrorMessage(""),
  });

  const { mutate: mutateSpkList, data: dataSpkList } = useMutation({
    mutationFn: getSpkList,
  });

  useEffect(() => {
    formik.setFieldValue(
      "date",
      platDate ? format(platDate, "yyyy-MM-dd") : ""
    );
  }, [platDate]);

  useEffect(() => {
    if (errorMessage !== "") setErrorMessage("");
  }, [formik.values]);

  useEffect(() => {
    if (!openEdit) setSelectedPlat(null);
  }, [openEdit]);

  useEffect(() => {
    mutateSpkList({
      offset: 1,
      limit: 10000,
      schedule: 0,
      search: "",
      po_start: "",
      po_end: "",
      delivery_start: "",
      delivery_end: "",
      job: [],
      category: [],
      dies: [],
    });
  }, []);

  return (
    <>
      <div className="flex mb-4 gap-2">
        <TextField
          fullWidth
          placeholder="No SPK, No PO, Type"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Select
          sx={{ width: "100%" }}
          value={jobType}
          onChange={(e) => setJobType(e.target.value)}
        >
          <MenuItem value={0}>Semua Job Type</MenuItem>
          <MenuItem value={1}>N</MenuItem>
          <MenuItem value={2}>R</MenuItem>
          <MenuItem value={3}>RWC</MenuItem>
        </Select>
        <button
          onClick={() => navigate("/plat-usage/create-plat-usage")}
          className="w-1/4 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
        >
          <p>Add Plat Usage</p>
          <IoMdAddCircleOutline className="text-[18px]" />
        </button>
      </div>

      <div className="rounded-md shadow-md">
        <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
          <table className="w-full">
            <thead className="bg-[#F4F5FF] border-t border-b border-2">
              <th className="p-3 text-left">No</th>
              <th className="p-3 text-left">Job Type</th>
              <th className="p-3 text-left">No SPK</th>
              <th className="p-3 text-left">No PO</th>
              <th className="p-3 text-left">Jumlah Warna (Est)</th>
              <th className="p-3 text-left">Jumlah Warna (Act)</th>
              <th className="p-3 text-left">Panjang</th>
              <th className="p-3 text-left">Lebar</th>
              <th className="p-3 text-left">Jumlah (cm2)</th>
              <th className="p-3 text-left">Tanggal Penggunaan</th>
              <th className="p-3 text-left">Stok Awal (cm2)</th>
              <th className="p-3 text-left">Stok Akhir (cm2)</th>
              <th className="p-3 text-left">Nama Pemotong Plate</th>
              <th className="p-3 text-left">Type</th>
              <th className="p-3 text-left">Action</th>
            </thead>
            <tbody>
              {data && data.count > 0 && !isFetching ? (
                data.plates.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td className="p-3 text-left">
                        {(page - 1) * fetchLimit + (i + 1)}
                      </td>
                      <td className="p-3 text-left">{item.job}</td>
                      <td className="p-3 text-left">{item.spk_number}</td>
                      <td className="p-3 text-left">{item.po_number}</td>
                      <td className="p-3 text-left">{item.est_color}</td>
                      <td className="p-3 text-left">{item.act_color}</td>
                      <td className="p-3 text-left">{item.length}</td>
                      <td className="p-3 text-left">{item.width}</td>
                      <td className="p-3 text-left">{item.total}</td>
                      <td className="p-3 text-left">
                        {item.date
                          ? format(new Date(item.date), "yyyy-MM-dd")
                          : "-"}
                      </td>
                      <td className="p-3 text-left">{item.opening_stock}</td>
                      <td className="p-3 text-left">{item.ending_stock}</td>
                      <td className="p-3 text-left">{item.name}</td>
                      <td className="p-3 text-left">{item.type}</td>
                      <td className="p-3 text-center">
                        <div className="flex gap-2 items-center">
                          <FaTrash
                            onClick={() => {
                              setOpenDelete(true);
                              setSelectedPlat(item);
                            }}
                            className="border w-[24px] h-full rounded-md p-1 text-red-500 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                          />
                          <IoPencilOutline
                            onClick={() => {
                              setSelectedPlat(item);
                              setOpenEdit(true);
                            }}
                            className="text-blue-500 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : isFetching ? (
                <tr>
                  <td className="p-3 text-center" colSpan={15}>
                    <CircularProgress size={20} />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td className="p-3 text-center text-gray-300" colSpan={15}>
                    Tidak ada data plat
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {data && data.count > 0 ? (
          <div className="w-full flex justify-between pb-8 px-8">
            <button
              onClick={() => {
                if (page > 1) setPage(page - 1);
              }}
              disabled={page === 1}
              className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
            >
              <FaArrowLeftLong /> Previous
            </button>
            <Pagination
              hideNextButton
              hidePrevButton
              shape="rounded"
              page={page}
              count={Math.ceil(data.count / fetchLimit)}
              onChange={(e, page) => setPage(page)}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                />
              )}
            />
            <button
              onClick={() => {
                if (page < Math.ceil(data.count / fetchLimit))
                  setPage(page + 1);
              }}
              disabled={page === Math.ceil(data.count / fetchLimit)}
              className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
            >
              <FaArrowRightLong /> Next
            </button>
          </div>
        ) : null}
      </div>
      {data && data.count > 0 ? (
        <div className="mt-4 text-xs flex gap-3 items-center">
          <p>Show Table</p>
          <p
            onClick={() => setFetchLimit(10)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 10 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            10
          </p>
          <p
            onClick={() => setFetchLimit(50)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 50 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            50
          </p>
          <p
            onClick={() => setFetchLimit(100)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 100 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            100
          </p>
        </div>
      ) : null}

      <ModalInput
        title="Hapus Plat Usage"
        subtitle="Apakah anda yakin ingin menghapus plat usage ini?"
        content={
          <>
            <p className="text-red-500">{errorMessage}</p>
          </>
        }
        open={openDelete}
        setOpen={setOpenDelete}
        hasButton={true}
        buttonText="Yes"
        setTriggerFunc={() => {
          mutateDelete(selectedPlat.id);
        }}
        isDisable={isPending}
      />

      <ModalInput
        title="Edit Plat Usage"
        subtitle=""
        content={
          <>
            {/* <form onSubmit={formik.handleSubmit}> */}
            <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
              <FormLabel>Job Type</FormLabel>
              <Select
                sx={{ width: "100%" }}
                value={getJobId()}
                onChange={(e) =>
                  formik.setFieldValue(
                    "job",
                    e.target.value === 1
                      ? "N"
                      : e.target.value === 2
                      ? "R"
                      : "RWC"
                  )
                }
              >
                <MenuItem value={0} disabled>
                  Pilih Job Type
                </MenuItem>
                <MenuItem value={1}>N</MenuItem>
                <MenuItem value={2}>R</MenuItem>
                <MenuItem value={3}>RWC</MenuItem>
              </Select>
            </FormGroup>
            <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
              <FormLabel>No. SPK</FormLabel>
              <Autocomplete
                sx={{ width: "100%" }}
                disablePortal
                value={
                  dataSpkList && formik.values.spk_number
                    ? dataSpkList.data.find(
                        (item) => item.spk_no === formik.values.spk_number
                      )
                    : null
                }
                onChange={(_, option) => {
                  formik.setFieldValue(
                    "spk_number",
                    option ? option.spk_no : null
                  );
                  formik.setFieldValue(
                    "po_number",
                    option ? option.po_number : ""
                  );
                }}
                options={
                  dataSpkList && dataSpkList.data ? dataSpkList.data : []
                }
                getOptionLabel={(option) => option.spk_no}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Pilih SPK" />
                )}
              />
            </FormGroup>
            <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
              <FormLabel>No PO</FormLabel>
              <TextField fullWidth disabled value={formik.values.po_number} />
            </FormGroup>
            <div className="flex gap-4 mb-4">
              <FormGroup sx={{ width: "100%" }}>
                <FormLabel>Jumlah Warna (Est)</FormLabel>
                <TextField
                  fullWidth
                  value={formik.values.est_color}
                  onChange={(e) =>
                    formik.setFieldValue("est_color", e.target.value)
                  }
                />
              </FormGroup>
              <FormGroup sx={{ width: "100%" }}>
                <FormLabel>Jumlah Warna (Actual)</FormLabel>
                <TextField
                  fullWidth
                  value={formik.values.act_color}
                  onChange={(e) =>
                    formik.setFieldValue("act_color", e.target.value)
                  }
                />
              </FormGroup>
            </div>
            <div className="flex gap-4 mb-4 items-stretch">
              <FormGroup sx={{ width: "100%" }}>
                <FormLabel>Penggunaan (Panjang)</FormLabel>
                <TextField
                  fullWidth
                  value={formik.values.length}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "length",
                      isNaN(parseFloat(e.target.value))
                        ? 0
                        : e.target.value.slice(-1) === "."
                        ? e.target.value
                        : parseFloat(e.target.value)
                    )
                  }
                />
              </FormGroup>
              <FormGroup sx={{ width: "100%" }}>
                <FormLabel>Penggunaan (Lebar)</FormLabel>
                <TextField
                  fullWidth
                  value={formik.values.width}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "width",
                      isNaN(parseFloat(e.target.value))
                        ? 0
                        : e.target.value.slice(-1) === "."
                        ? e.target.value
                        : parseFloat(e.target.value)
                    )
                  }
                />
              </FormGroup>
            </div>
            <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
              <FormLabel>Jumlah (cm2)</FormLabel>
              <TextField
                fullWidth
                value={formik.values.total}
                onChange={(e) =>
                  formik.setFieldValue(
                    "total",
                    isNaN(parseFloat(e.target.value))
                      ? 0
                      : e.target.value.slice(-1) === "."
                      ? e.target.value
                      : parseFloat(e.target.value)
                  )
                }
              />
            </FormGroup>
            {/* <FormGroup sx={{ width: "100%" }}>
                <FormLabel>Quotation Date</FormLabel>
                <div className="mb-4 w-full border-2 rounded-md w-full flex items-center hover:bg-slate-100">
                  <div
                    className="flex justify-between items-center h-full cursor-pointer w-full cursor-pointer p-3.5"
                    onClick={(e) => setAnchorElCalendar(e.currentTarget)}
                  >
                    {platDate === null ? (
                      <p>----/--/--</p>
                    ) : (
                      <p>{format(new Date(platDate), "yyyy-MM-dd")}</p>
                    )}
                    <MdCalendarMonth className="w-6 h-6" />
                  </div>
                </div>
              </FormGroup> */}
            <div className="flex gap-4 mb-4">
              <FormGroup sx={{ width: "100%" }}>
                <FormLabel>Stok Awal</FormLabel>
                <TextField
                  fullWidth
                  value={formik.values.opening_stock}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "opening_stock",
                      isNaN(parseFloat(e.target.value))
                        ? 0
                        : e.target.value.slice(-1) === "."
                        ? e.target.value
                        : parseFloat(e.target.value)
                    )
                  }
                />
              </FormGroup>
              <FormGroup sx={{ width: "100%" }}>
                <FormLabel>Stok Akhir</FormLabel>
                <TextField
                  fullWidth
                  value={formik.values.ending_stock}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "ending_stock",
                      isNaN(parseFloat(e.target.value))
                        ? 0
                        : e.target.value.slice(-1) === "."
                        ? e.target.value
                        : parseFloat(e.target.value)
                    )
                  }
                />
              </FormGroup>
            </div>
            <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
              <FormLabel>Nama Pemotong Plate</FormLabel>
              <TextField
                fullWidth
                value={formik.values.name}
                onChange={(e) => formik.setFieldValue("name", e.target.value)}
              />
            </FormGroup>
            <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
              <FormLabel>Type</FormLabel>
              <TextField
                fullWidth
                value={formik.values.type}
                onChange={(e) => formik.setFieldValue("type", e.target.value)}
              />
            </FormGroup>
            {/* <div className="w-full">
              <p className="text-red-500 mb-4 text-right m-4">{errorMessage}</p>
              <div className="flex justify-end w-1/3 gap-2 float-right">
                <button
                  type="button"
                  className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
                  onClick={() => navigate("/plat-usage")}
                >
                  Cancel
                </button>
                <button
                  className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
                  disabled={!formik.isValid || isPending}
                  type="submit"
                >
                  Save
                </button>
              </div>
            </div> */}
            {/* </form> */}
          </>
        }
        open={openEdit}
        setOpen={setOpenEdit}
        hasButton={true}
        buttonText="Save"
        setTriggerFunc={() => {
          // mutateDelete(selectedPlat.id);
          mutatePlat(formik.values);
        }}
        isDisable={isPendingEdit}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendar}
        setAnchorElCalendarStart={setAnchorElCalendar}
        startDate={platDate}
        setStartDate={setPlatDate}
      />
    </>
  );
};

export default PlatUsageList;
