import {
  Autocomplete,
  Button,
  CircularProgress,
  FormGroup,
  FormLabel,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import ModalInput from "../../common/ModalInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useMutation } from "@tanstack/react-query";
import { getStockList } from "../../../services/stockAdjustment";

const StockAdjustmentList = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [status, setStatus] = useState("");
  const [period, setPeriod] = useState(0);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDates, setSelectedDates] = useState(["", ""]);
  const [searchStartDate, setSearchStartDate] = useState(null);
  const [searchEndDate, setSearchEndDate] = useState(null);
  const [openDetail, setOpenDetail] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [filter, setFilter] = useState("");

  const onChangeDate = (dates) => {
    const [start, end] = dates;
    setSearchStartDate(start);
    setSearchEndDate(end);
    setSelectedDates([
      format(new Date(start), "yyyy-MM-dd"),
      end === null ? "" : format(new Date(end), "yyyy-MM-dd"),
    ]);
  };

  const {
    mutate: mutateList,
    data: dataStock,
    isPending,
  } = useMutation({
    mutationFn: getStockList,
  });

  useEffect(() => {
    const body = {
      status: status,
      limit: fetchLimit,
      offset: page,
      periode: period,
      start_date: selectedDates[0],
      end_date: selectedDates[1],
    };
    mutateList(body);
  }, [status, fetchLimit, page, period, selectedDates]);

  const statusOptions = dataStock
    ? [...new Set(dataStock.data.map((item) => item.status))]
    : [];

  const filteredStatusOptions = statusOptions
    .filter((option) => option.toLowerCase().includes(filter.toLowerCase()))
    .slice(0, 5);

  return (
    <>
      <div className="flex mb-4 gap-2">
        <FormGroup sx={{ width: "100%" }}>
          <FormLabel>Status</FormLabel>
          <Autocomplete
            freeSolo
            options={filteredStatusOptions}
            inputValue={filter}
            onInputChange={(event, newInputValue) => {
              setFilter(newInputValue);
            }}
            onChange={(event, newValue) => {
              setStatus(newValue || "");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Semua status"
                disabled={isPending}
              />
            )}
          />
        </FormGroup>
        <FormGroup sx={{ width: "100%" }}>
          <FormLabel>Periode</FormLabel>
          <Select
            value={period}
            onChange={(e) => {
              if (e.target.value !== 10) {
                setPeriod(e.target.value);
                setSelectedDates(["", ""]);
                setSearchStartDate(null);
                setSearchEndDate(null);
              } else {
                setOpenCalendar(true);
                setPeriod(9);
              }
            }}
          >
            <MenuItem value={0}>Semua periode</MenuItem>
            <MenuItem value={1}>Minggu Berjalan</MenuItem>
            <MenuItem value={2}>Minggu Lalu</MenuItem>
            <MenuItem value={3}>Bulan Berjalan</MenuItem>
            <MenuItem value={4}>Bulan Lalu</MenuItem>
            <MenuItem value={5}>Tahun Berjalan</MenuItem>
            <MenuItem value={6}>Tahun Lalu</MenuItem>
            <MenuItem value={7}>Hari Ini</MenuItem>
            <MenuItem value={8}>Kemarin</MenuItem>
            <MenuItem value={10}>Pilih Tanggal</MenuItem>
            <MenuItem
              value={9}
              sx={{ display: "none" }}
              disabled
            >{`${selectedDates[0]} s/d ${selectedDates[1]}`}</MenuItem>
          </Select>
        </FormGroup>
        <button
          onClick={() => navigate(`/stock-adjustment/create-stock-adjustment`)}
          className="w-1/4 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
        >
          <p>Buat Adjustment</p>
          <IoMdAddCircleOutline className="text-[18px]" />
        </button>
      </div>

      <div className="rounded-md shadow-md">
        <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
          <table className="w-full">
            <thead className="bg-[#F4F5FF] border-t border-b border-2">
              <tr>
                <th className="p-3 text-left">No</th>
                <th className="p-3 text-left">Kode Material</th>
                <th className="p-3 text-left">Tanggal Penyesuaian</th>
                <th className="p-3 text-left">Nama Material</th>
                <th className="p-3 text-left">Status</th>
                <th className="p-3 text-left">Total Awal Stok</th>
                <th className="p-3 text-left">Total Stok Akhir</th>
              </tr>
            </thead>
            <tbody>
              {dataStock && dataStock.count > 0 && !isPending ? (
                dataStock.data.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td className="p-3">
                        {(page - 1) * fetchLimit + (i + 1)}
                      </td>
                      <td
                        className="p-3 text-left text-blue-500 cursor-pointer hover:text-gray-300"
                        onClick={() => {
                          setOpenDetail(true);
                          setSelectedDetail(item);
                        }}
                      >
                        {item.material_code}
                      </td>
                      <td className="p-3 text-left">
                        {item.adjustment_date
                          ? format(item.adjustment_date, "yyyy-MM-dd")
                          : "-"}
                      </td>
                      <td className="p-3 text-left">{item.material_name}</td>
                      <td className="p-3 text-left">{item.status}</td>
                      <td className="p-3 text-left">
                        {item.start_stock_total}
                      </td>
                      <td className="p-3 text-left">{item.end_stock_total}</td>
                    </tr>
                  );
                })
              ) : isPending ? (
                <tr>
                  <td colSpan={7} className="text-center p-3">
                    <CircularProgress size={20} />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={7} className="text-center p-3 text-gray-300">
                    Tidak ada data stock adjustment
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {dataStock && dataStock.count > 0 ? (
          <div className="w-full flex justify-between pb-8 px-8">
            <button
              onClick={() => {
                if (page > 1) setPage(page - 1);
              }}
              disabled={page === 1}
              className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
            >
              <FaArrowLeftLong /> Previous
            </button>
            <Pagination
              hideNextButton
              hidePrevButton
              shape="rounded"
              page={page}
              count={Math.ceil(dataStock.count / fetchLimit)}
              onChange={(e, page) => setPage(page)}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                />
              )}
            />
            <button
              onClick={() => {
                if (page < Math.ceil(dataStock.count / fetchLimit))
                  setPage(page + 1);
              }}
              disabled={page === Math.ceil(dataStock.count / fetchLimit)}
              className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
            >
              <FaArrowRightLong /> Next
            </button>
          </div>
        ) : null}
      </div>

      {dataStock && dataStock.count > 0 ? (
        <div className="mt-4 text-xs flex gap-3 items-center">
          <p>Show Table</p>
          <p
            onClick={() => setFetchLimit(10)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 10 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            10
          </p>
          <p
            onClick={() => setFetchLimit(50)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 50 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            50
          </p>
          <p
            onClick={() => setFetchLimit(100)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 100 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            100
          </p>
        </div>
      ) : null}

      <ModalInput
        title="Detail Stok Adjustment"
        subtitle=""
        content={
          selectedDetail ? (
            <>
              <div className="flex mb-2">
                <p className="w-1/2">Tanggal Kadaluwarsa</p>
                <p className="w-fit mx-4">:</p>
                <p>
                  {selectedDetail.expired_date
                    ? format(selectedDetail.expired_date, "yyyy-MM-dd")
                    : "-"}
                </p>
              </div>
              <div className="flex mb-2">
                <p className="w-1/2">Stok Awal</p>
                <p className="w-fit mx-4">:</p>
                <p>{selectedDetail.start_stock}</p>
              </div>
              <div className="flex mb-2">
                <p className="w-1/2">Stok Akhir</p>
                <p className="w-fit mx-4">:</p>
                <p>{selectedDetail.end_stock}</p>
              </div>
              <div className="flex mb-2">
                <p className="w-1/2">Status</p>
                <p className="w-fit mx-4">:</p>
                <p>{selectedDetail.status}</p>
              </div>
            </>
          ) : (
            <>
              <CircularProgress size={20} />
            </>
          )
        }
        open={openDetail}
        setOpen={setOpenDetail}
        hasButton={false}
        setTriggerFunc={() => {}}
        isDisable={false}
      />

      <ModalInput
        title="Pilh Tanggal Periode"
        subtitle=""
        content={
          <>
            <div className="flex flex-wrap justify-center p-2">
              <DatePicker
                selected={searchStartDate}
                onChange={onChangeDate}
                startDate={searchStartDate}
                endDate={searchEndDate}
                selectsRange
                inline
                className="w-full"
              />
            </div>
          </>
        }
        open={openCalendar}
        setOpen={setOpenCalendar}
        hasButton={false}
        setTriggerFunc={() => {}}
        isDisable={false}
      />
    </>
  );
};

export default StockAdjustmentList;
