import React, { useEffect } from "react";
import { Box, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalPopup = ({ title, subtitle, open, setOpen, icon }) => {
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  }, [open]);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            width: "30%",
            overflowY: "auto",
            overflowX: "wrap",
            maxHeight: "85vh",
            padding: 0,
          }}
          className="text-left"
        >
          <div className="flex justify-center items-center gap-2 items-center p-8 pb-0">
            <h2 id="parent-modal-title" className="font-bold text-xl mb-2">
              {title}
            </h2>
            <div>{icon}</div>
          </div>
          <p
            id="parent-modal-description"
            className="text-slate-500 mb-4 text-wrap p-8 text-center"
          >
            {subtitle}
          </p>
        </Box>
      </Modal>
    </>
  );
};

export default ModalPopup;
