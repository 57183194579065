import { Autocomplete, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { deleteMaterialCategory } from "../../../services/materialApi";
import ModalInput from "../../common/ModalInput";

const DeleteMaterialCategoryModal = ({
  dataCategory,
  setOpenDeleteCat,
  openDeleteCat,
  setRefetchList,
}) => {
  const [selectedCat, setSelectedCat] = useState(null);
  const [isDelete, setIsDelete] = useState(false);

  const { mutate: mutateDelete, isPending: isPendingDelete } = useMutation({
    mutationFn: deleteMaterialCategory,
    enable: isDelete,
    onSuccess: () => {
      setOpenDeleteCat(false);
      setIsDelete(false);
      setRefetchList(true);
    },
  });

  useEffect(() => {
    setSelectedCat(null);
  }, [openDeleteCat]);

  useEffect(() => {
    if (isDelete) mutateDelete(selectedCat.id);
  }, [isDelete]);

  return (
    <>
      <ModalInput
        title="Hapus Categori"
        subtitle="Pilih kategori yang ingin dihapus"
        content={
          <>
            <Autocomplete
              sx={{ width: "100%" }}
              disablePortal
              value={selectedCat}
              onChange={(_, option) => {
                setSelectedCat(option);
              }}
              options={
                dataCategory && dataCategory.material_categories
                  ? [...dataCategory.material_categories]
                  : []
              }
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Kategori" />
              )}
            />
          </>
        }
        open={openDeleteCat}
        setOpen={setOpenDeleteCat}
        hasButton={true}
        buttonText="Hapus" 
        setTriggerFunc={setIsDelete}
        isDisable={!dataCategory || !selectedCat || isPendingDelete}
      />
    </>
  );
};

export default DeleteMaterialCategoryModal;
