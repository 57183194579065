import {
  Breadcrumbs,
  CircularProgress,
  Link,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getDetailFinishgood } from "../../../services/finishgoodApi";
import { format } from "date-fns";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { useState, useEffect } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const DetailFinishgood = () => {
  const [fetchLimit, setFetchLimit] = useState(10);
  const [page, setPage] = useState(1);

  const { id } = useParams();

  const { data, isPending } = useQuery({
    queryKey: ["detail-finishgood", id, fetchLimit, page],
    queryFn: () => getDetailFinishgood(id, fetchLimit, page),
  });

  useEffect(() => {
    setPage(1);
  }, [fetchLimit]);

  return (
    <>
      <div className="flex justify-between items-center mb-8">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/finishgood">
            Finishgood Inventory
          </Link>
          <Link underline="hover" color="inherit">
            Detail Finishgood Inventory
          </Link>
        </Breadcrumbs>
      </div>
      <p className="text-xl font-bold mb-8">Detail Finishgood Inventory</p>
      {data && !isPending && data?.data?.length > 0 ? (
        <>
          <div className="w-full mb-4">
            <p className="text-gray-500 text-xs">Internal Code</p>
            <p>{data?.internal_code}</p>
            <hr />
          </div>
          <div className="w-full mb-4">
            <p className="text-gray-500 text-xs">Label</p>
            <p>{data?.name_label}</p>
            <hr />
          </div>
          <div className="w-full mb-4">
            <p className="text-gray-500 text-xs">Stock on Hand</p>
            <p>{data?.stock_on_hand}</p>
            <hr />
          </div>
        </>
      ) : isPending ? (
        <div className="w-full flex justify-center">
          <CircularProgress size={20} />
        </div>
      ) : (
        <p>Tidak ada data</p>
      )}

      <div className="rounded-md shadow-md mb-4">
        <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
          <table className="w-full">
            <thead className="bg-[#F4F5FF] border-t border-b border-2">
              <th className="p-3 text-left">No</th>
              <th className="p-3 text-left">Date</th>
              <th className="p-3 text-left">SPK</th>
              <th className="p-3 text-left">Name Label</th>
              <th className="p-3 text-left">Qty In</th>
              <th className="p-3 text-left">Qty Out</th>
              <th className="p-3 text-left">Stock on Hand</th>
            </thead>
            <tbody>
              {data?.data ? (
                data?.data?.map((item, i) => (
                  <tr key={i}>
                    <td className="p-3 text-left border-b-2">
                      {(page - 1) * fetchLimit + (i + 1)}
                    </td>
                    <td className="p-3 text-left border-b-2">
                      {format(new Date(item?.do_date), "yyyy-MM-dd")}
                    </td>
                    <td className="p-3 text-left border-b-2">{item?.spk_no}</td>
                    <td className="p-3 text-left border-b-2">
                      {item?.name_label}
                    </td>
                    <td className="p-3 text-left border-b-2">
                      {item?.stock_in}
                    </td>
                    <td className="p-3 text-left border-b-2">
                      {item?.stock_out}
                    </td>
                    <td className="p-3 text-left border-b-2">
                      {item?.stock_on_hand}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="p-3 text-center" colSpan={6}>
                    Tidak ada SPK
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {data && data?.count > 0 ? (
          <div className="w-full flex justify-between pb-8 px-8">
            <button
              onClick={() => {
                if (page > 1) setPage(page - 1);
              }}
              disabled={page === 1}
              className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
            >
              <FaArrowLeftLong /> Previous
            </button>
            <Pagination
              hideNextButton
              hidePrevButton
              shape="rounded"
              page={page}
              count={Math.ceil(data?.count / fetchLimit)}
              onChange={(e, page) => setPage(page)}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                />
              )}
            />
            <button
              onClick={() => {
                if (page < Math.ceil(data?.count / fetchLimit))
                  setPage(page + 1);
              }}
              disabled={page === Math.ceil(data?.count / fetchLimit)}
              className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
            >
              <FaArrowRightLong /> Next
            </button>
          </div>
        ) : null}
      </div>
      {data && data?.count > 0 ? (
        <div className="mt-4 text-xs flex gap-3 items-center">
          <p>Show Table</p>
          <p
            onClick={() => setFetchLimit(10)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 10 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            10
          </p>
          <p
            onClick={() => setFetchLimit(50)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 50 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            50
          </p>
          <p
            onClick={() => setFetchLimit(100)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 100 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            100
          </p>
        </div>
      ) : null}
    </>
  );
};

export default DetailFinishgood;
