import { useEffect, useRef } from "react";
import Logo from "../../../assets/logo.png";
import jsPDF from "jspdf";

const jobType = [
  { id: 0, name: "New" },
  { id: 1, name: "Repeat" },
  { id: 2, name: "Sample Order" },
  { id: 3, name: "RWC" },
  { id: 4, name: "New + CRB" },
  { id: 5, name: "RWC + CRB" },
  { id: 6, name: "Reprint" },
];

const category = [
  { id: 1, name: "Prime" },
  { id: 2, name: "Difficult" },
];

const PrintSpk = ({ isDownload, setIsDownload, data }) => {
  const poRef = useRef();

  const styles = {
    page: {
      marginTop: "4mm",
      fontSize: 8,
      size: "a4",
    },
    detail: {
      fontSize: 6,
    },
    textXs: { fontSize: 6 },
    textMd: { fontSize: 8 },
    textLg: { fontSize: 12 },
    terbilangContainer: {
      width: "140mm",
    },
  };

  useEffect(() => {
    if (isDownload && data) {
      const doc = new jsPDF({
        lineHeight: 1,
        fontSize: 11,
        format: "a4",
        unit: "px",
      });
      doc.html(poRef.current, {
        callback: function (doc) {
          doc.save(data?.spk_no + ".pdf");
        },
        x: 0,
        y: 0,
        autoPaging: "text",
        width: 2480,
      });

      setIsDownload(false);
    }
  }, [isDownload, data, setIsDownload]);

  const getJobTypeName = (id) => {
    const job = jobType.find((jt) => jt.id === id);
    return job ? job.name : "Unknown";
  };

  const getCagoryName = (id) => {
    const job = category.find((jt) => jt.id === id);
    return job ? job.name : "Unknown";
  };

  if (!data) return null;

  return (
    <div>
      {data ? (
        <div ref={poRef} style={styles.page}>
          <div className="w-96">
            <div className="w-4/5 inline-block align-top" style={styles.textXs}>
              <img
                src={Logo}
                className="w-[25mm] h-full mx-6"
                alt="Indolabel Logo"
              />
              <div className="flex mx-6 gap-2">
                <p className="font-bold">Keterangan:</p>
                <p></p>
              </div>
            </div>
            <div className="w-1/5 inline-block text-center align-middle pl-6">
              <div className="text-start w-36">
                <p style={styles.textMd} className="font-bold">
                  Surat Perintah Kerja
                </p>
                <p style={styles.detail}>{data?.schedule_date}</p>
              </div>
            </div>
          </div>

          <hr className="mt-2" />

          {data?.project.map((item, i) => (
            <div
              key={i}
              style={{
                marginTop: i === 0 ? 2 : 96,
              }}
            >
              <p className="font-bold mx-6 mb-4" style={styles.textMd}>
                {item?.internal_code}
              </p>
              <div className="w-full mx-6 flex mb-2">
                <div className="w-52 text-center align-middle flex">
                  <div className="text-start w-20" style={styles.detail}>
                    <p>Date Issue</p>
                    <p>Production Date</p>
                    <p>Purchase Order No</p>
                    <p>Sales Order No</p>
                    <p className="mb-2">Date PO</p>
                    <p>Customer</p>
                    <p>Internal Code</p>
                    <p className="mb-2">Label Name</p>
                    <p className="font-bold">Finishing</p>
                    <p>Size</p>
                    <p>X1 Accros</p>
                    <p>X2 Along</p>
                    <p>Gap Accros</p>
                    <p>Gap Along</p>
                    <p>Max Up (250 mm)</p>
                    <p>Penyesuaian</p>
                    <p>Total Gap Accros</p>
                    <p>Jarak Total Gap</p>
                    <p>Gear Size</p>
                    <p>Counter Bersih</p>
                    <p>Along Run</p>
                    <p>Run Bersih</p>
                    <p>Total Color</p>
                    <p>Speed</p>
                  </div>
                  <div className="text-start w-32" style={styles.detail}>
                    <p>: {data?.schedule_date}</p>
                    <p>: {data?.delivery_date}</p>
                    <p>: {data?.po_number}</p>
                    <p>: {data?.so_no}</p>
                    <p className="mb-2">: {data?.po_date}</p>
                    <p>: {data?.customer_name}</p>
                    <p>: {item?.internal_code}</p>
                    <p className="mb-2">: {item?.label_name}</p>
                    <p className="font-bold">: {item?.finishing}</p>
                    <p>: {(item?.material[0]?.size).toFixed(3)}</p>
                    <p>= {item?.label_size?.split("x")[0]} mm</p>
                    <p>= {item?.label_size?.split("x")[1]} mm</p>
                    <p>= {(item?.gap_across).toFixed(3)} mm</p>
                    <p>= {(item?.gap_along).toFixed(3)} mm</p>
                    <p>= {item?.max_up} up</p>
                    <p>= {item?.adjustment_up} up</p>
                    <p>= {item?.total_gap_across} Gap</p>
                    <p>= {item?.total_gap_range} mm</p>
                    <p>= {item?.gear_size}</p>
                    <p>= {(item?.clean_counter).toFixed(3)} Counter</p>
                    <p>= {item?.along_run} mm</p>
                    <p>
                      ={" "}
                      {((item?.clean_counter * item?.along_run) / 1000).toFixed(
                        3
                      )}{" "}
                      meter
                    </p>
                    <p>= {item?.total_color} Kg</p>
                    <p>= {item?.speed} M/HR</p>
                  </div>
                </div>
                <div className="w-52 text-center align-middle flex">
                  <div className="text-start w-20" style={styles.detail}>
                    <p>Sales Person</p>
                    <p>No SPK</p>
                    <p>Job Type</p>
                    <p>Delivery Date</p>
                    <p>M/C Printing</p>
                    <p>Category</p>
                    <p>MPS</p>
                    <p>Qty Order</p>
                    <p>Qty Stock</p>
                    <p className="mb-4">Qty Produksi</p>
                    <p>Up Accros</p>
                    <p>Up Along</p>
                    <p>Total Mata</p>
                    <p>J. Kiri</p>
                    <p>J. Kanan</p>
                    <p>Printing Area</p>
                    <p>Size Material</p>
                    <p>Penyesuaian</p>
                    <p>Add. Difficult Waste</p>
                    <p>Setup Toleransi 1%</p>
                    <p>Alokasi CRB (Meter)</p>
                    <p>Max Counter</p>
                    <p>Max Run (Meter)</p>
                    <p>Distortion</p>
                  </div>
                  <div className="text-start w-32`" style={styles.detail}>
                    <p>: {data?.sales}</p>
                    <p>: {data?.spk_no}</p>
                    <p>: {getJobTypeName(data.job_type)}</p>
                    <p>: {data?.delivery_date}</p>
                    <p>: {data?.mc_printing}</p>
                    <p>: {getCagoryName(data?.category)}</p>
                    <p>: {data?.mps}</p>
                    <p>: {item?.qty_order} pcs</p>
                    <p>: {item?.qty_stock} pcs</p>
                    <p className="mb-4">: {item?.qty_production} pcs</p>
                    <p>= {item?.adjustment_up} up</p>
                    <p>= {item?.up_along} up</p>
                    <p>= {(item?.eye_total).toFixed(3)} up</p>
                    <p>= {item?.j_left} mm</p>
                    <p>= {item?.j_right} mm</p>
                    <p>= {item?.printing_area} mm</p>
                    <p>= {(item?.size_material).toFixed(3)} mm</p>
                    <p>= {(item?.adjustment_mm).toFixed(3)} mm</p>
                    <p>= {item?.difficult_waste}</p>
                    <p>= {item?.tolerance_percent} Meter</p>
                    <p>= {item?.crb} Meter</p>
                    <p>= {(item?.max_counter).toFixed(3)} Counter</p>
                    <p>= {(item?.max_run).toFixed(3)} Meter</p>
                    <p>= {item?.distortion}%</p>
                  </div>
                </div>
              </div>

              <div className="h-80">
                <table
                  className="border-collapse w-96 mx-6 mt-2"
                  style={styles.detail}
                >
                  <thead style={styles.detail}>
                    <tr>
                      <th
                        className="pb-1 pt-0 px-2 border-l border-t border-b border-black text-center"
                        colSpan={2}
                      >
                        Material
                      </th>
                      <th className="pb-1 pt-0 px-2 border-l border-t border-b border-black text-center w-20">
                        Size
                      </th>
                      <th className="pb-1 pt-0 px-2 border-l border-t border-b border-r border-black text-center w-20">
                        Konsumsi
                      </th>
                    </tr>
                  </thead>
                  {item?.material.map((material, i) => (
                    <tbody style={styles.detail}>
                      <tr>
                        <td className="pb-1 pt-0 px-2 border-l border-b border-r border-black text-center"></td>
                        <td className="pb-1 pt-0 px-2 border-b border-black text-center w-28">
                          {material?.material_name}
                        </td>
                        <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center w-20">
                          {(material?.size).toFixed(3)}
                        </td>
                        <td className="pb-1 pt-0 px-2 border-l border-b border-r border-black text-center w-20">
                          {(material?.consumption).toFixed(3)}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>

              <table
                className="border-collapse w-96 mx-6 mt-6"
                style={styles.detail}
              >
                <thead style={styles.detail}>
                  <tr>
                    <th
                      className="pb-1 pt-0 px-2 border-l border-t border-b border-black text-center"
                      colSpan="3"
                    >
                      Estimasi by System Material (M)
                    </th>
                    <th
                      className="pb-1 pt-0 px-2 border border-black text-center"
                      colSpan="4"
                    >
                      Actual Report from Operator (Manual)
                    </th>
                  </tr>
                  <tr>
                    <th className="pb-1 pt-0 px-2 border-l border-b border-black text-center w-20">
                      Run (m)
                    </th>
                    <th className="pb-1 pt-0 px-2 border-l border-b border-black text-center w-20">
                      Setup&Run Waste (m)
                    </th>
                    <th className="pb-1 pt-0 px-2 border-l border-b border-black text-center w-20">
                      Total (m)
                    </th>
                    <th className="pb-1 pt-0 px-2 border-l border-b border-black text-center w-20">
                      Run (m)
                    </th>
                    <th className="pb-1 pt-0 px-2 border-l border-b border-black text-center w-20">
                      Setup (m)
                    </th>
                    <th className="pb-1 pt-0 px-2 border-l border-b border-black text-center w-20">
                      Total (m)
                    </th>
                    <th className="pb-1 pt-0 px-2 border-l border-b border-r border-black text-center w-20">
                      Counter
                    </th>
                  </tr>
                </thead>
                <tbody style={styles.detail}>
                  <tr>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {(item?.system_estimate[0].run_system).toFixed(3)}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {(item?.system_estimate[0].run_waste_system).toFixed(3)}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {(item?.system_estimate[0].total_system).toFixed(3)}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {item?.system_estimate[0].operator_run_system}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {item?.system_estimate[0].operator_setup_system}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {item?.system_estimate[0].operator_total_system}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-r border-black text-center"></td>
                  </tr>
                  <tr>
                    <th
                      className="pb-1 pt-0 px-2 border-l border-b border-black text-center"
                      colSpan="3"
                    >
                      Estimasi by System Time (HR)
                    </th>
                    <th
                      className="pb-1 pt-0 px-2 border-l border-b border-r border-black text-center"
                      colSpan="4"
                    >
                      Actual Report from Operator (Manual)
                    </th>
                  </tr>
                  <tr>
                    <th className="pb-1 pt-0 px-2 border-l border-b border-black text-center w-20">
                      Run (HR)
                    </th>
                    <th className="pb-1 pt-0 px-2 border-l border-b border-black text-center w-20">
                      Setup & Run waste (HR)
                    </th>
                    <th className="pb-1 pt-0 px-2 border-l border-b border-black text-center w-20">
                      Total (HR)
                    </th>
                    <th className="pb-1 pt-0 px-2 border-l border-b border-black text-center w-20">
                      Run (m)
                    </th>
                    <th className="pb-1 pt-0 px-2 border-l border-b border-black text-center w-20">
                      Setup (m)
                    </th>
                    <th className="pb-1 pt-0 px-2 border-l border-b border-black text-center w-20">
                      Total (m)
                    </th>
                    <th className="pb-1 pt-0 px-2 border-l border-b border-r border-black text-center w-20">
                      Counter
                    </th>
                  </tr>
                  <tr>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {(item?.hr_estimate[0].run_hr).toFixed(3)}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {(item?.hr_estimate[0].run_waste_hr).toFixed(3)}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {(item?.hr_estimate[0].total_hr).toFixed(3)}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {item?.hr_estimate[0].operator_run_hr}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {item?.hr_estimate[0].operator_setup_hr}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {item?.hr_estimate[0].operator_total_hr}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-r border-black text-center"></td>
                  </tr>
                </tbody>
              </table>

              <table
                style={styles.detail}
                className="border border-black w-96 mx-6 h-16 mt-6"
              >
                <tbody>
                  <tr>
                    <td className="px-2">{data?.note}</td>
                  </tr>
                </tbody>
              </table>

              <div
                className="flex justify-between mx-6 mt-6 w-96"
                style={styles.detail}
              >
                <table className="border-collapse border w-2/3 text-center">
                  <thead>
                    <tr>
                      <th
                        className="pb-1 pt-0 border border-b-0 border-r-0 border-black py-1 px-2"
                        rowSpan={2}
                      >
                        QC Process
                      </th>
                      <th
                        className="pb-1 pt-0 border border-b-0 border-r-0 border-black py-1 px-2"
                        rowSpan={2}
                      >
                        Est (Hr)
                      </th>
                      <th
                        className="pb-1 pt-0 border border-b-0 border-black py-1 px-2"
                        colspan="3"
                      >
                        Actual (Hr)
                      </th>
                    </tr>
                    <tr>
                      <th className="pb-1 pt-0 border border-b-0 border-r-0 border-black py-1 px-2">
                        Setup
                      </th>
                      <th className="pb-1 pt-0 border border-b-0 border-r-0 border-black py-1 px-2">
                        Run
                      </th>
                      <th className="pb-1 pt-0 border border-b-0 border-black py-1 px-2">
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="pb-1 pt-0 border border-b-0 border-r-0 border-black py-1">
                        Slitting
                      </td>
                      <td className="pb-1 pt-0 border border-b-0 border-r-0 border-black py-1">
                        {(item?.qc?.sliting).toFixed(3)}
                      </td>
                      <td className="pb-1 pt-0 border border-b-0 border-r-0 border-black py-1"></td>
                      <td className="pb-1 pt-0 border border-b-0 border-r-0 border-black py-1"></td>
                      <td className="pb-1 pt-0 border border-b-0 border-black py-1 pl-0">
                        {item?.qc?.sliting_act}
                      </td>
                    </tr>
                    <tr>
                      <td className="pb-1 pt-0 border border-b-0 border-r-0 border-black px-2">
                        Sheeting
                      </td>
                      <td className="pb-1 pt-0 border border-b-0 border-r-0 border-black px-2">
                        {(item?.qc?.sheeting).toFixed(3)}
                      </td>
                      <td className="pb-1 pt-0 border border-b-0 border-r-0 border-black px-2"></td>
                      <td className="pb-1 pt-0 border border-b-0 border-r-0 border-black px-2"></td>
                      <td className="pb-1 pt-0 border border-b-0 border-black px-2">
                        {item?.qc?.sheeting_act}
                      </td>
                    </tr>
                    <tr>
                      <td className="pb-1 pt-0 border border-b-0 border-r-0 border-black py-1 px-2">
                        Cutting
                      </td>
                      <td className="pb-1 pt-0 border border-b-0 border-r-0 border-black py-1 px-2">
                        {(item?.qc?.cutting).toFixed(3)}
                      </td>
                      <td className="pb-1 pt-0 border border-b-0 border-r-0 border-black py-1 px-2"></td>
                      <td className="pb-1 pt-0 border border-b-0 border-r-0 border-black py-1 px-2"></td>
                      <td className="pb-1 pt-0 border border-b-0 border-black py-1 px-2">
                        {item?.qc?.cutting_act}
                      </td>
                    </tr>
                    <tr>
                      <td className="pb-1 pt-0 border border-b-0 border-r-0 border-black py-1 px-2">
                        Packing Roll
                      </td>
                      <td className="pb-1 pt-0 border border-b-0 border-r-0 border-black py-1 px-2">
                        {(item?.qc?.packing_roll).toFixed(3)}
                      </td>
                      <td className="pb-1 pt-0 border border-b-0 border-r-0 border-black py-1 px-2"></td>
                      <td className="pb-1 pt-0 border border-b-0 border-r-0 border-black py-1 px-2"></td>
                      <td className="pb-1 pt-0 border border-b-0 border-black py-1 px-2">
                        {item?.qc?.packing_roll_act}
                      </td>
                    </tr>
                    <tr>
                      <td className="pb-1 pt-0 border border-r-0 border-black py-1 px-2">
                        Packing Sheet
                      </td>
                      <td className="pb-1 pt-0 border border-r-0 border-black py-1 px-2">
                        {(item?.qc?.packing_sheet).toFixed(3)}
                      </td>
                      <td className="pb-1 pt-0 border border-r-0 border-black py-1 px-2"></td>
                      <td className="pb-1 pt-0 border border-r-0 border-black py-1 px-2"></td>
                      <td className="pb-1 pt-0 border border-black py-1 px-2">
                        {item?.qc?.packing_sheet_act}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="w-1/3 ml-2">
                  <p className="flex justify-between">
                    Inspeksi OK : <span>pcs</span>
                  </p>
                  <p className="flex justify-between">
                    Inspeksi NG : <span>pcs</span>
                  </p>
                  <p className="flex justify-between">
                    Total Produksi : <span>pcs</span>
                  </p>
                  <p className="flex justify-between">
                    Qty Shortage : <span>pcs</span>
                  </p>
                  <p className="flex justify-between">
                    Sisa Stock : <span>pcs</span>
                  </p>
                  <table className="mt-2" style={styles.detail}>
                    <thead>
                      <tr>
                        <td></td>
                        <td></td>
                        <td className="px-2 text-center">Front</td>
                        <td className="px-2 text-center">Back</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="font-bold">Print Direction</td>
                        <td className="">:</td>
                        <td className="px-2 text-center"></td>
                        <td className="px-2 text-center"></td>
                      </tr>
                      <tr>
                        <td className=" font-bold">Roll Direction</td>
                        <td className="">:</td>
                        <td className="px-2 text-center"></td>
                        <td className="px-2 text-center"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <table
                className="border-collapse w-96 mx-6 mt-6"
                style={styles.detail}
              >
                <thead style={styles.detail}>
                  <tr>
                    <th className="pb-1 pt-0 px-2 border-l border-t border-b border-black text-center">
                      QC Process
                    </th>
                    <th className="pb-1 pt-0 px-2 border-l border-t border-b border-black text-center">
                      Est(Hr)
                    </th>
                    <th
                      className="pb-1 pt-0 px-2 border-l border-t border-b border-black text-center"
                      colSpan="2"
                    >
                      Ink Coverage (%)
                    </th>
                    <th className="pb-1 pt-0 px-2 border-l border-t border-b border-black text-center">
                      Est(kg)
                    </th>
                    <th
                      className="pb-1 pt-0 px-2 border-l border-t border-b border-black text-center"
                      colSpan="2"
                    >
                      Ink Coverage (%)
                    </th>
                    <th className="pb-1 pt-0 px-2 border-l border-t border-b border-r border-black text-center">
                      Est(kg)
                    </th>
                  </tr>
                </thead>
                <tbody style={styles.detail}>
                  <tr>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center font-bold">
                      Sliting
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {(item?.qc?.sliting).toFixed(3)}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      Cyan
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {item?.ink_coverage?.cyan}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {(
                        (4 / 10000) *
                          0.4 *
                          1.1 *
                          (item?.ink_coverage?.cyan / 100) *
                          item?.material[0].consumption *
                          (item?.material[0].size / 1000) *
                          3.175 +
                        5 / 10 +
                        0.2
                      ).toFixed(3)}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      Violet
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {item?.ink_coverage?.violet}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-r border-black text-center">
                      {(
                        (4 / 10000) *
                          0.4 *
                          1.1 *
                          (item?.ink_coverage?.violet / 100) *
                          item?.material[0].consumption *
                          (item?.material[0].size / 1000) *
                          3.175 +
                        5 / 10 +
                        0.2
                      ).toFixed(3)}
                    </td>
                  </tr>
                  <tr>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center font-bold">
                      Sheeting
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {(item?.qc?.sheeting).toFixed(3)}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      Magenta
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {item?.ink_coverage?.magenta}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {(
                        (4 / 10000) *
                          0.4 *
                          1.1 *
                          (item?.ink_coverage?.magenta / 100) *
                          item?.material[0].consumption *
                          (item?.material[0].size / 1000) *
                          3.175 +
                        5 / 10 +
                        0.2
                      ).toFixed(3)}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      Green
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {item?.ink_coverage?.green}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-r border-black text-center">
                      {(
                        (4 / 10000) *
                          0.4 *
                          1.1 *
                          (item?.ink_coverage?.green / 100) *
                          item?.material[0].consumption *
                          (item?.material[0].size / 1000) *
                          3.175 +
                        5 / 10 +
                        0.2
                      ).toFixed(3)}
                    </td>
                  </tr>
                  <tr>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center font-bold">
                      Cutting
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {(item?.qc?.cutting).toFixed(3)}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      Yellow
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {item?.ink_coverage?.yellow}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {(
                        (4 / 10000) *
                          0.4 *
                          1.1 *
                          (item?.ink_coverage?.yellow / 100) *
                          item?.material[0].consumption *
                          (item?.material[0].size / 1000) *
                          3.175 +
                        5 / 10 +
                        0.2
                      ).toFixed(3)}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      Ref.Blue
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {item?.ink_coverage?.ref_blue}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-r border-black text-center">
                      {(
                        (4 / 10000) *
                          0.4 *
                          1.1 *
                          (item?.ink_coverage?.black / 100) *
                          item?.material[0].consumption *
                          (item?.material[0].size / 1000) *
                          3.175 +
                        5 / 10 +
                        0.2
                      ).toFixed(3)}
                    </td>
                  </tr>
                  <tr>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center font-bold">
                      Packing Roll
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {(item?.qc?.packing_roll).toFixed(3)}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      Black
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {item?.ink_coverage?.black}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {(
                        (4 / 10000) *
                          0.4 *
                          1.1 *
                          (item?.ink_coverage?.black / 100) *
                          item?.material[0].consumption *
                          (item?.material[0].size / 1000) *
                          3.175 +
                        5 / 10 +
                        0.2
                      ).toFixed(3)}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      P.032
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {item?.ink_coverage?.p_032}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-r border-black text-center">
                      {(
                        (4 / 10000) *
                          0.4 *
                          1.1 *
                          (item?.ink_coverage?.p_032 / 100) *
                          item?.material[0].consumption *
                          (item?.material[0].size / 1000) *
                          3.175 +
                        5 / 10 +
                        0.2
                      ).toFixed(3)}
                    </td>
                  </tr>
                  <tr>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center font-bold">
                      Packing Sheet
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {(item?.qc?.packing_sheet).toFixed(3)}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      Orange
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {item?.ink_coverage?.orange}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {(
                        (4 / 10000) *
                          0.4 *
                          1.1 *
                          (item?.ink_coverage?.orange / 100) *
                          item?.material[0].consumption *
                          (item?.material[0].size / 1000) *
                          3.175 +
                        5 / 10 +
                        0.2
                      ).toFixed(3)}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      Varnish
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-black text-center">
                      {item?.ink_coverage?.varnish}
                    </td>
                    <td className="pb-1 pt-0 px-2 border-l border-b border-r border-black text-center">
                      {(
                        (12 / 10000) *
                          0.4 *
                          1.1 *
                          (item?.ink_coverage?.varnish / 100) *
                          item?.material[0].consumption *
                          (item?.material[0].size / 1000) *
                          3.175 +
                        5 / 10 +
                        0.2
                      ).toFixed(3)}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div
                className={`${
                  i === data?.project?.length - 1 ? "h-20" : "h-28"
                }`}
                style={{ height: i === data?.project?.length - 1 ? 0 : 28 }}
              >
                <div
                  className="flex justify-between mx-6 mt-6 w-96"
                  style={styles.detail}
                >
                  <div className="w-1/3">
                    <p className="font-bold">Label Information</p>
                    <p className="flex justify-between">
                      1 Lembar <span>= {item?.label[0].lembar} pcs</span>
                    </p>
                    <p className="flex justify-between">
                      1 Ikat <span>= {item?.label[0].ikat} pcs</span>
                    </p>
                    <p className="flex justify-between">
                      1 Pack <span>= {item?.label[0].pack} pcs</span>
                    </p>
                    <p className="flex justify-between">
                      1 Roll <span>= {item?.label[0].roll} pcs</span>
                    </p>
                    <p className="flex justify-between">
                      Dia. Cor <span>= {item?.label[0].core_dia} pcs</span>
                    </p>
                  </div>

                  <div className="w-1/2 flex gap-1">
                    <table
                      className="border-collapse border w-1/3"
                      style={styles.detail}
                    >
                      <tbody>
                        <tr>
                          <td className="border border-black h-12"></td>
                        </tr>
                        <tr>
                          <td className="pb-1 pt-0 border border-t-0 border-black h-2 text-center">
                            Prepared by
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-t-0 border-black h-3"></td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      className="border-collapse border w-1/3"
                      style={styles.detail}
                    >
                      <tbody>
                        <tr>
                          <td className="border border-black h-12"></td>
                        </tr>
                        <tr>
                          <td className="pb-1 pt-0 border border-t-0 border-black h-2 text-center">
                            Production
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-t-0 border-black h-3"></td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      className="border-collapse border w-1/3"
                      style={styles.detail}
                    >
                      <tbody>
                        <tr>
                          <td className="border border-black h-12"></td>
                        </tr>
                        <tr>
                          <td className="pb-1 pt-0 border border-t-0 border-black h-2 text-center">
                            Qc
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-t-0 border-black h-3"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default PrintSpk;
