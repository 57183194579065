import { useEffect, useRef, useState } from "react";
import Logo from "../../../assets/logo.png";
import jsPDF from "jspdf";
import { useQuery } from "@tanstack/react-query";
import { getSupplierPoDetail } from "../../../services/supplierPoApi";
import { format } from "date-fns";
import angkaTerbilangJs from "@develoka/angka-terbilang-js";

const PrintPo = ({ isDownload, setIsDownload, id }) => {
  const poRef = useRef();

  const countOrderTotal = (materialArr = []) => {
    return materialArr.reduce((acc, item) => acc + item.total, 0);
  };

  const countSubTotal = (orderTotal, discount) => {
    return orderTotal - discount;
  };

  const countTotal = (subTotal, tax) => {
    return subTotal + tax;
  };

  const countDiscount = (materialArr = []) => {
    return materialArr.reduce((acc, num) => acc + num.discount, 0);
  };

  const countTax = (materialArr = []) => {
    return materialArr.reduce((acc, num) => acc + num.ppn, 0);
  };

  const styles = {
    page: {
      marginTop: "6mm",
      fontSize: 8,
      size: "a4",
    },
    detail: {
      fontSize: 6,
    },
    textXs: { fontSize: 6 },
    textMd: { fontSize: 8 },
    textLg: { fontSize: 12 },
    terbilangContainer: {
      width: "140mm",
    },
  };

  const { data } = useQuery({
    queryKey: ["po-detail", id, isDownload],
    queryFn: () => getSupplierPoDetail(id),
  });

  useEffect(() => {
    if (isDownload && data) {
      const doc = new jsPDF({
        lineHeight: 1.2,
        fontSize: 11,
        format: "a4",
        unit: "px",
      });
      doc.html(poRef.current, {
        callback: function (doc) {
          doc.save("PO_" + data.po_detail.po_number + ".pdf");
        },
        x: 0,
        y: 0,
        autoPaging: "text",
        width: 2480,
      });

      setIsDownload(false);
    }
  }, [isDownload, data, setIsDownload]);

  if (!data) return null;

  const orderTotal = countOrderTotal(data.material);
  const discount = countDiscount(data.material);
  const subTotal = countSubTotal(orderTotal, discount);
  const tax = countTax(data.material);
  const total = countTotal(subTotal, tax);

  return (
    <div>
      {data ? (
        <div ref={poRef} style={styles.page}>
          <div className="w-96">
            <div className="w-1/2 inline-block align-top" style={styles.textXs}>
              <img src={Logo} className="w-[25mm] h-full mb-1 mx-6" />
              <p className="mx-6 w-full font-bold text-md">
                PT. INDOLABEL SURYA PRATAMA
              </p>
              <p className="mb-2 mx-6 pr-12 w-full">
                {data.po_detail.indolabel_address}
              </p>
              <p className="mx-6">www.indolabel.asia</p>
            </div>
            <div className="w-1/2 inline-block text-center align-middle pl-6">
              <p className="w-full font-bold mb-4" style={styles.textLg}>
                Purchase Order
              </p>
              <div className="text-start w-36" style={styles.detail}>
                <p className="">Date</p>
                <p>
                  : {format(new Date(data.po_detail.po_date), "dd  MMMM yyyy")}
                </p>
                <p className="">PO No</p>
                <p>: {data.po_detail.po_number}</p>
                <p className="">Delivery Date</p>
                <p>
                  :{" "}
                  <span className="text-red-600 font-bold">
                    {format(
                      new Date(data.po_detail.arrived_date),
                      "dd  MMMM yyyy"
                    )}
                  </span>
                </p>
                <p className="">Payment Term</p>
                <p>: {data.po_detail.lead_time}</p>
                <p className="">NPWP</p>
                <p>
                  : <span className="font-bold">70.785.115.0-416.000</span>
                </p>
              </div>
            </div>
          </div>

          <hr className="mt-2 mb-1" />

          <div className="w-96 mx-6 mb-1">
            <div className="w-1/2 inline-block align-top" style={styles.textXs}>
              <p className="w-full font-bold text-md">Supplier Name:</p>
              <p className="w-full font-bold text-md">
                {data.po_detail.supplier_name}
              </p>
              <p className="mb-2 pr-12 w-full">
                {data.po_detail.supplier_address}
              </p>
            </div>
            <div className="w-1/2 inline-block align-top" style={styles.textXs}>
              <p className="w-full font-bold text-md">Ship To:</p>
              <p className="w-full font-bold text-md">
                PT. INDOLABEL SURYA PRATAMA
              </p>
              <p className="mb-2 pr-12 w-full">
                Kawasan Industri Modern Cikande Blok AF No 6 Sukatani, Cikande,
                Serang Banten 42186
              </p>
            </div>
          </div>

          <table className="border-collapse w-96 mx-6">
            <thead>
              <tr>
                <th className="pb-1 pt-0 px-2 border-l border-t border-black w-1 text-center">
                  No
                </th>
                <th className="pb-1 pt-0 px-2 border-l border-t border-black text-center">
                  Material Name
                </th>
                <th className="pb-1 pt-0 px-2 border-l border-t border-black w-[47px] text-center">
                  Qty
                </th>
                <th className="pb-1 pt-0 px-2 border-l border-t border-black w-20 text-center">
                  Unit Price
                </th>
                <th className="pb-1 pt-0 px-2 border-l border-t border-r border-black w-20 text-center">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {data.material ? (
                data.material.map((item, i) => (
                  <tr key={i}>
                    <td
                      className={`pb-1 pt-0 px-2 border border-black border-r-0 text-center ${
                        i === data.material.length - 1 ? "" : "border-b-0"
                      }`}
                    >
                      {i + 1}
                    </td>
                    <td
                      className={`pb-1 pt-0 px-2 border border-black border-r-0 text-left ${
                        i === data.material.length - 1 ? "" : "border-b-0"
                      }`}
                    >
                      {item.material_name}
                    </td>
                    <td
                      className={`pb-1 pt-0 px-2 border border-black border-r-0 text-center ${
                        i === data.material.length - 1 ? "" : "border-b-0"
                      }`}
                    >
                      {item.quantity} {item.unit}
                    </td>
                    <td
                      className={`pb-1 pt-0 px-2 border border-black border-r-0 ${
                        i === data.material.length - 1 ? "" : "border-b-0"
                      }`}
                    >
                      Rp{item.price.toLocaleString().replace(",", ".")}
                    </td>
                    <td
                      className={`pb-1 pt-0 px-2 border border-black ${
                        i === data.material.length - 1 ? "" : "border-b-0"
                      }`}
                    >
                      Rp{item.total.toLocaleString().replace(",", ".")}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8}>Tidak ada material</td>
                </tr>
              )}
            </tbody>
          </table>

          <table className="border-collapse mx-6 mt-[-1.7px] ml-[200.2px]">
            <tbody className="ml-auto">
              <tr>
                <td className="pb-1 pt-0 px-2 border border-black border-r-0 w-[127px] font-bold">
                  Order Total
                </td>
                <td className="pb-1 pt-0 px-2 border border-black w-20">
                  Rp {orderTotal.toLocaleString().replace(",", ".")}
                </td>
              </tr>
              <tr>
                <td className="pb-1 pt-0 px-2 border border-black border-r-0 border-t-0 w-[127px] font-bold">
                  Discount
                </td>
                <td className="pb-1 pt-0 px-2 border border-t-0 border-black text-left w-20">
                  Rp {discount.toLocaleString().replace(",", ".")}
                </td>
              </tr>
              <tr>
                <td className="pb-1 pt-0 px-2 border border-black border-r-0 border-t-0 w-[127px] font-bold">
                  Sub Total
                </td>
                <td className="pb-1 pt-0 px-2 border border-t-0 border-black w-20">
                  Rp {subTotal.toLocaleString().replace(",", ".")}
                </td>
              </tr>
              <tr>
                <td className="pb-1 pt-0 px-2 border border-black border-r-0 border-t-0 w-[127px] font-bold">
                  Tax
                </td>
                <td className="pb-1 pt-0 px-2 border border-t-0 border-black w-20">
                  Rp {tax.toLocaleString().replace(",", ".")}
                </td>
              </tr>
              <tr>
                <td className="pb-1 pt-0 px-2 border border-black border-r-0 border-t-0 w-[127px] font-bold">
                  Total
                </td>
                <td className="pb-1 pt-0 px-2 border border-t-0 border-black w-20">
                  Rp {total.toLocaleString().replace(",", ".")}
                </td>
              </tr>
              <tr>
                <td className="pb-1 pt-0 px-2 border border-black border-r-0 border-t-0 w-[127px] font-bold">
                  Freight Charge
                </td>
                <td className="pb-1 pt-0 px-2 border border-t-0 border-black w-20">
                  Rp{" "}
                  {data.po_detail.freight_charge
                    .toLocaleString()
                    .replace(",", ".")}
                </td>
              </tr>
              <tr>
                <td className="pb-1 pt-0 px-2 border border-black border-r-0 border-t-0 w-[127px] font-bold">
                  Purchase Order Total
                </td>
                <td className="pb-1 pt-0 px-2 border border-t-0 border-black w-20 font-bold">
                  Rp{" "}
                  {data.po_detail.grand_total
                    .toLocaleString()
                    .replace(",", ".")}
                </td>
              </tr>
            </tbody>
          </table>

          <div className="px-6 my-2 flex gap-8">
            <div className="inline-block mt-[-20px]">
              <p className="font-bold inline-block mb-2">Keterangan:</p>
              <div className="border border-black w-36 h-16"></div>
              <p className="inline-block">No signature required</p>
            </div>

            <div className="flex flex-row gap-2">
              <div className="border border-black w-16 h-20 flex flex-col">
                <div className="flex-1"></div>
                <div className="border-t border-black text-center pb-1 h-4">
                  Prepared
                </div>
                <div className="border-t border-black text-center pb-1 h-4">
                  Renny
                </div>
              </div>
              <div className="border border-black w-16 h-20 flex flex-col">
                <div className="flex-1"></div>
                <div className="border-t border-black text-center pb-1 h-4">
                  Checked
                </div>
                <div className="border-t border-black text-center pb-1 h-4"></div>
              </div>
              <div className="border border-black w-16 h-20 flex flex-col">
                <div className="flex-1"></div>
                <div className="border-t border-black text-center pb-1 h-4">
                  Confirmed
                </div>
                <div className="border-t border-black text-center pb-1 h-4"></div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PrintPo;
