import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getSupplierPoDetail } from "../../../services/supplierPoApi";
import { Breadcrumbs, Link, TextField } from "@mui/material";
import { format } from "date-fns";
import PrintPo from "./PrintPo";
import PrintReleasePo from "./PrintReleasePo";
import ModalInput from "../../common/ModalInput";
import { useState } from "react";

const DetailSupplierPo = () => {
  const { id } = useParams();

  const [downloadPo, setDownloadPo] = useState(false);
  const [downloadReleasePo, setDownloadReleasePo] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [isDownload, setIsDownload] = useState(false);

  const { data } = useQuery({
    queryKey: ["po-detail"],
    queryFn: () => getSupplierPoDetail(id),
  });

  return (
    <div className="mt-12 lg:mt-2">
      <div className="flex justify-between items-center mb-8">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/sv-po">
            Supplier/Vendor PO
          </Link>
          <Link underline="hover" color="inherit">
            Supplier/Vendor PO Detail
          </Link>
        </Breadcrumbs>
        <button
          onClick={() => setOpenDownload(true)}
          className="w-fit px-8 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
        >
          <p>Download PO</p>
        </button>
      </div>

      <div className="flex gap-4 items-center mb-4">
        <TextField
          variant="standard"
          fullWidth
          label="No. PO"
          value={data ? data.po_detail.po_number : ""}
          disabled
        />
        <TextField
          variant="standard"
          fullWidth
          label="Supplier/Vendor"
          value={data ? data.po_detail.supplier_name : ""}
          disabled
        />
      </div>
      <div className="flex gap-4 items-center mb-4">
        <TextField
          variant="standard"
          fullWidth
          label="Tanggal PO"
          value={data ? format(data.po_detail.po_date, "yyyy-MM-dd") : ""}
          disabled
        />
        <TextField
          variant="standard"
          fullWidth
          label="Notes"
          value={data ? data.po_detail.notes : ""}
          disabled
        />
      </div>

      <div className="rounded-md shadow-md">
        <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
          <table className="w-full">
            <thead className="bg-[#F4F5FF] border-t border-b border-2">
              <th className="p-3 text-left">No</th>
              <th className="p-3 text-left">Nama Material</th>
              <th className="p-3 text-left">Qty</th>
              <th className="p-3 text-left">Qty Receive</th>
              <th className="p-3 text-left">Harga</th>
              <th className="p-3 text-left">Unit</th>
              <th className="p-3 text-left">Total</th>
              <th className="p-3 text-left">PPN</th>
              <th className="p-3 text-left">Diskon</th>
              <th className="p-3 text-left">Subtotal</th>
            </thead>
            <tbody>
              {data && data.material ? (
                data.material.map((item, i) => (
                  <tr key={i}>
                    <td className="p-3">{i + 1}</td>
                    <td className="p-3">{item.material_name}</td>
                    <td className="p-3">{item.quantity}</td>
                    <td className="p-3">{item.quantity_receive}</td>
                    <td className="p-3">{item.price}</td>
                    <td className="p-3">{item.unit}</td>
                    <td className="p-3">
                      Rp{item.total.toLocaleString().replace(",", ".")}
                    </td>
                    <td className="p-3">
                      Rp{item.ppn?.toLocaleString().replace(",", ".")}
                    </td>
                    <td className="p-3">
                      Rp{item.discount?.toLocaleString().replace(",", ".")}
                    </td>
                    <td className="p-3">
                      Rp{item.subtotal?.toLocaleString().replace(",", ".")}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={9} className="text center p-3">
                    Tidak ada material
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {downloadPo ? (
        <PrintPo
          isDownload={downloadPo}
          setIsDownload={setDownloadPo}
          id={id}
        />
      ) : null}
      {downloadReleasePo ? (
        <PrintReleasePo
          isDownload={downloadReleasePo}
          setIsDownload={setDownloadReleasePo}
          id={id}
        />
      ) : null}

      <div className="w-full flex flex-wrap basis-1/3 justify-end">
        <div className="font-bold float-right w-72">
          <div className="flex mb-4 items-center">
            <p className="w-32">Freight Charge</p>
            <p className="w-fit mr-2">:</p>
            {data && data.po_detail ? (
              <p>Rp{data.po_detail.freight_charge.toLocaleString()}</p>
            ) : null}
          </div>
          <div className="flex mb-4">
            <p className="w-32">Grand Total</p>
            <p className="w-fit mr-2">:</p>
            {data && data.po_detail ? (
              <p>Rp{data.po_detail.grand_total.toLocaleString()}</p>
            ) : null}
          </div>
        </div>
      </div>

      <ModalInput
        title="Download PO"
        subtitle="Pilih dokumen yang ingin anda download"
        content={
          <div className="flex gap-2">
            <div
              className="w-full border-blue-500 text-blue-500 cursor-pointer hover:bg-slate-100 border-2 rounded-md text-center p-2"
              onClick={() => setDownloadPo(true)}
            >
              PO
            </div>
            <div
              className="w-full border-blue-500 text-blue-500 cursor-pointer hover:bg-slate-100 border-2 rounded-md text-center p-2"
              onClick={() => setDownloadReleasePo(true)}
            >
              Release PO
            </div>
          </div>
        }
        open={openDownload}
        setOpen={setOpenDownload}
        hasButton={false}
        setTriggerFunc={() => {}}
        isDisable={false}
      />
    </div>
  );
};

export default DetailSupplierPo;
