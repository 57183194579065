import { useEffect, useRef } from "react";
import Logo from "../../../assets/logo.png";
import jsPDF from "jspdf";
import { format } from "date-fns";
import angkaTerbilangJs from "@develoka/angka-terbilang-js";
import { useQuery } from "@tanstack/react-query";
import { getSupplierInvoiceDetail } from "../../../services/supplierInvoiceApi";
import { getCustomerInvoiceDetail } from "../../../services/customerInvoiceApi";

const PrintInvoice = ({ isDownload, setIsDownload, data, type }) => {
  const invoiceRef = useRef();

  const styles = {
    page: {
      // paddingLeft: "12mm",
      // paddingRight: "12mm",
      // marginTop: "8mm",
      fontSize: 8,
      // width: "210mm",
    },

    terbilangContainer: {
      width: "140mm",
    },
  };

  const {
    data: dataInvoice,
    status,
    isFetching,
  } = useQuery({
    queryKey: ["inv-detail"],
    enabled: data !== null && isDownload,
    queryFn: () =>
      type === "customer"
        ? getCustomerInvoiceDetail(data.id)
        : getSupplierInvoiceDetail(data.id),
  });

  useEffect(() => {
    if (isDownload) {
      const doc = new jsPDF({
        lineHeight: 1.2,
        fontSize: 11,
        format: "a4",
        unit: "px",
      });
      doc.html(invoiceRef.current, {
        callback: function (doc) {
          doc.save(
            (type === "customer" ? "Customer Invoice_" : "Supplier Invoice_") +
              data.invoice_number +
              ".pdf"
          );
        },
        x: 0,
        y: 0,
        // autoPaging: "text",
        // width: 2470,
      });

      setIsDownload(false);
    }
  }, [isDownload]);

  return (
    <>
      {data && dataInvoice ? (
        <div ref={invoiceRef} style={styles.page} className="">
          {type === "customer" ? (
            <>
              <div className="w-96 mt-6">
                <div
                  className="w-1/2 inline-block align-top"
                  style={styles.textXs}
                >
                  <img src={Logo} className="w-[25mm] h-full mb-1 px-4" alt="Logo" />
                  <p className="mb-2 px-4 w-full">
                    {dataInvoice.indolabel_address}
                  </p>
                </div>
                <div className="w-1/2 inline-block text-right align-middle pl-4">
                  <p className="w-full font-bold text-lg">Invoice</p>
                </div>
              </div>

              <hr className="my-4 " />

              <div className="px-4 mb-4 ">
                <div>
                  <p className="inline-block w-24">No. Invoice</p>
                  <p className="inline-block">: {dataInvoice.invoice_number}</p>
                </div>
                <div>
                  <p className="inline-block w-24">Nama Customer</p>
                  <p className="inline-block">: {dataInvoice.customer_name}</p>
                </div>
              </div>

              <table className="mb-1 border-collapse w-96 mx-4">
                <thead>
                  <tr className="bg-blue-100">
                    <th className="p-1 border border-b-0 border-black border-r-0">
                      No
                    </th>
                    <th className="p-1 border border-b-0 border-black border-r-0">
                      Keterangan
                    </th>
                    <th className="p-1 border border-b-0 border-black border-r-0">
                      Harga
                    </th>{" "}
                    <th className="p-1 border border-b-0 border-black">
                      Jumlah
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataInvoice.data_label ? (
                    dataInvoice.data_label.length > 0 ? (
                      dataInvoice.data_label.map((item, i) => (
                        <tr key={i}>
                          <td
                            className={`p-1 text-center border border-black border-r-0 ${
                              i === dataInvoice.data_label.length - 1
                                ? ""
                                : "border-b-0"
                            }`}
                          >
                            {i + 1}
                          </td>
                          <td
                            className={`p-1 text-center border border-black border-r-0 ${
                              i === dataInvoice.data_label.length - 1
                                ? ""
                                : "border-b-0"
                            }`}
                          >
                            {item.name_label}
                          </td>
                          <td
                            className={`p-1 text-center border border-black border-r-0 ${
                              i === dataInvoice.data_label.length - 1
                                ? ""
                                : "border-b-0"
                            }`}
                          >
                            {item.quantity}
                          </td>{" "}
                          <td
                            className={`p-1 text-center border border-black ${
                              i === dataInvoice.data_label.length - 1
                                ? ""
                                : "border-b-0"
                            }`}
                          >
                            Rp{item.price?.toLocaleString()}{" "}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4}>Tidak ada label</td>
                      </tr>
                    )
                  ) : null}
                </tbody>
              </table>
              <div className="px-4 mb-4 ">
                <div>
                  <p className="inline-block w-24">Total Harga</p>{" "}
                  <p className="inline-block">
                    : Rp
                    {dataInvoice.total_price?.toLocaleString()}
                  </p>
                </div>
                <div>
                  <p className="inline-block w-24">Diskon</p>{" "}
                  <p className="inline-block">
                    : Rp
                    {dataInvoice.discount?.toLocaleString()}
                  </p>
                </div>
                <div>
                  <p className="inline-block w-24">PPN</p>
                  <p className="inline-block">
                    : Rp
                    {(dataInvoice.ppn
                      ? dataInvoice.total_price * 0.11
                      : 0
                    ).toLocaleString()}
                  </p>
                </div>
                <div>
                  <p className="inline-block w-24">Freight Charge</p>
                  <p className="inline-block">
                    : Rp
                    {dataInvoice.freight_charge?.toLocaleString()}
                  </p>
                </div>
                <div>
                  <p className="inline-block w-24">Grand Total</p>
                  <p className="inline-block">
                    : Rp
                    {dataInvoice.grand_total?.toLocaleString()}
                  </p>
                </div>
              </div>
              <div className=" px-4 mb-16">
                <p className="w-48 inline-block text-center">Diterima Oleh</p>
                <p className="w-48 inline-block text-center">
                  Hormat Kami,{" "}
                  {dataInvoice.invoice_date === ""
                    ? ""
                    : format(
                        new Date(dataInvoice.invoice_date),
                        "dd MMMM yyyy"
                      )}{" "}
                </p>
              </div>
              <div className=" px-4 mb-12">
                <p className="w-48 inline-block text-center">
                  {dataInvoice.customer_name}
                </p>
                <p className="w-48 inline-block text-center">
                  PT. Indolabel Surya Pratama
                </p>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="w-96 mt-6">
                <div
                  className="w-48 inline-block align-top"
                  style={styles.textXs}
                >
                  <img src={Logo} className="w-[25mm] h-full mb-1 px-4" alt="Logo" />
                  <p className="mb-2 px-4 w-48">
                    {dataInvoice.indolabel_address}
                  </p>

                  <p className="px-4 w-full">{dataInvoice.supplier_name}</p>

                  <p className="px-4 w-full">{dataInvoice.supplier_address}</p>
                </div>
                <div className="w-48 inline-block text-right align-middle pl-4">
                  <p className="w-full font-bold text-lg mb-2">Invoice</p>
                  <div>
                    <p className="inline-block w-20">No. Invoice</p>
                    <p className="inline-block">
                      : {dataInvoice.invoice_number}
                    </p>
                  </div>
                  <div>
                    <p className="inline-block w-20">No. PO</p>
                    <p className="inline-block">: {dataInvoice.po_number}</p>
                  </div>
                  <div>
                    <p className="inline-block w-20">Jatuh Tempo</p>
                    <p className="inline-block">
                      :
                      {dataInvoice.due_date && dataInvoice.due_date === ""
                        ? ""
                        : format(dataInvoice.due_date, "dd MMMM yyyy")}
                    </p>
                  </div>
                </div>
              </div>
              <hr className="my-4 " />
              <div className="px-4 mb-4 ">
                <div>
                  <p className="inline-block w-24">Grand Total</p>
                  <p className="inline-block">
                    : Rp
                    {dataInvoice.grand_total?.toLocaleString()}
                  </p>
                </div>
              </div>
              <div className="mb-4 px-4">
                <p className="inline-block pr-4 w-24">Terbilang</p>
                <div className="inline-block bg-gray-200 px-4 py-2 w-64">
                  {angkaTerbilangJs(
                    dataInvoice.grand_total ? dataInvoice.grand_total : 0
                  ).toUpperCase()}{" "}
                  RUPIAH
                </div>
              </div>
              <div className=" px-4 mb-16">
                <p className="w-48 inline-block text-center">Diterima Oleh</p>
                <p className="w-48 inline-block text-center">
                  {dataInvoice.invoice_date === ""
                    ? ""
                    : format(
                        new Date(dataInvoice.invoice_date),
                        "dd MMMM yyyy"
                      )}
                </p>
              </div>
              <div className=" px-4 mb-12">
                <p className="w-48 inline-block text-center">
                  {dataInvoice.supplier_name}
                </p>
                <p className="w-48 inline-block text-center">
                  PT. Indolabel Surya Pratama
                </p>
              </div>
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PrintInvoice;
