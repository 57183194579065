import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import SingleCalendar from "../../common/SingleCalendar";
import { MdCalendarMonth, MdCancel } from "react-icons/md";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getUserList } from "../../../services/userManagementApi";
import { getShipmentMethod } from "../../../services/deliveryOrder";
import { AiOutlineCloudUpload } from "react-icons/ai";
import * as yup from "yup";
import {
  deleteSo,
  getSoDetail,
  setNewSo,
} from "../../../services/salesOrderApi";
import ModalInput from "../../common/ModalInput";
import { IoPencilSharp } from "react-icons/io5";
import { FaTrash } from "react-icons/fa6";
import moment from "moment";
import {
  getAllQuotation,
  getDetailQuotation,
} from "../../../services/quotationApi";
import { getCustomerList } from "../../../services/customerManagementApi";

const validationSchema = yup.object({
  file: yup.mixed().nullable(),
  json: yup.object({
    id: yup.number(),
    status_order: yup.number(),
    user_id: yup.number().min(1).required(),
    quotation_id: yup.number().min(1).required(),
    customer_id: yup.number().min(1).required(),
    so_number: yup.string().required(),
    shipping: yup.number().min(1).required(),
    po_number: yup.string().required(),
    po_date: yup.string().required(),
    due_date: yup.string().required(),
    delivery_date: yup.string(),
    bill_to: yup.string().required(),
    shipping_address: yup.array(),
  }),
});

const EditSalesOrder = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [errorMessage, setErrorMessage] = useState("");
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [typeDate, setTypeDate] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [errorMessageDelete, setErrorMessageDelete] = useState("");
  const [materialArr, setMaterialArr] = useState([]);

  const handleOpenCalendar = (e, type) => {
    setAnchorElCalendar(e.currentTarget);
    //set start date according to whats selected and its curval
    setTypeDate(type);
  };

  const uploadMedia = (e) => {
    const files = e.target.files[0];

    if (!files) return;

    formik.setFieldValue("file", files);
  };

  const changeShippingAddress = (val, i) => {
    formik.setFieldValue("json.shipping_address[" + i + "]", val);
  };

  const handleQuotationChange = () => {
    formik.setFieldValue(
      "json.shipping",
      dataDetailQuotation.shipping_method && dataShipmentMethod
        ? dataShipmentMethod.find(
            (item) => item.id === dataDetailQuotation.shipping_method
          ).id
        : 0
    );
    formik.setFieldValue(
      "json.bill_to",
      dataDetailQuotation.bill_to ? dataDetailQuotation.bill_to : ""
    );
    formik.setFieldValue(
      "json.shipping_address[0]",
      dataDetailQuotation.ship_to ? dataDetailQuotation.ship_to : ""
    );
    formik.setFieldValue(
      "json.customer_id",
      dataDetailQuotation.customer_id ? dataDetailQuotation.customer_id : ""
    );
    setMaterialArr(dataDetailQuotation.labels);
  };

  const resetAllFields = () => {
    formik.setValues({
      file: {
        file: data.file,
        name: data.filename,
      },
      json: {
        id: Number(id),
        status_order: data.status_order,
        user_id: data.user_id,
        quotation_id: data.quotation_id,
        customer_id: data.customer_id,
        so_number: data.so_number,
        shipping: data.shipping_method,
        po_number: data.po_number,
        po_date: data.po_date ? moment(data.po_date).format("YYYY-MM-DD") : "",
        due_date: data.due_date
          ? moment(data.due_date).format("YYYY-MM-DD")
          : "",
        delivery_date: data.delivery_date
          ? moment(data.delivery_date).format("YYYY-MM-DD")
          : "",
        shipping_address: data.ship_to ? data.ship_to : [""],
        bill_to: data.billing_address,
      },
    });
    setMaterialArr(data.items);
    if (dataQuotation && dataQuotation.data)
      setSelectedQuotation(
        dataQuotation.data.find((elem) => elem.id === data.quotation_id).id
      );
  };

  const formik = useFormik({
    initialValues: {
      file: null,
      json: {
        id: Number(id),
        status_order: 1,
        user_id: 0,
        quotation_id: 0,
        customer_id: 0,
        so_number: "",
        shipping: 0,
        po_number: "",
        po_date: "",
        due_date: "",
        delivery_date: "",
        shipping_address: [""],
        bill_to: "",
      },
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: () => {
      setOpenAdd(true);
    },
  });

  const { mutate: mutateNewSo } = useMutation({
    mutationFn: setNewSo,
    onSuccess: () => navigate("/sales-order"),
    onError: (err) => setErrorMessage(err.message.id),
  });

  const { mutate: mutateDeleteSo } = useMutation({
    mutationFn: deleteSo,
    onSuccess: () => navigate("/sales-order"),
    onError: (err) => setErrorMessageDelete(err.message.id),
  });

  const { data: dataQuotation } = useQuery({
    queryKey: ["quotation-list"],
    queryFn: () => getAllQuotation(""),
  });

  const { data: dataDetailQuotation } = useQuery({
    queryKey: ["detail-quotation", selectedQuotation],
    queryFn: () => getDetailQuotation(selectedQuotation),
    enabled: selectedQuotation !== null,
  });

  const { data, isFetching } = useQuery({
    queryKey: ["so-detail", id],
    enabled: id !== null,
    queryFn: () => getSoDetail(id),
  });

  const { data: dataUser } = useQuery({
    queryKey: ["user-list"],
    queryFn: () =>
      getUserList({
        name: "",
        role: 6,
        offset: 1,
        limit: 10000,
      }),
  });

  const { mutate: mutateCustomerList, data: dataCust } = useMutation({
    mutationFn: getCustomerList,
  });

  const { data: dataShipmentMethod } = useQuery({
    queryKey: ["shipment-method-list"],
    queryFn: () => getShipmentMethod(),
  });

  useEffect(() => {
    if (typeDate === "po")
      formik.setFieldValue(
        "json.po_date",
        startDate ? format(startDate, "yyyy-MM-dd") : ""
      );
    if (typeDate === "due")
      formik.setFieldValue(
        "json.due_date",
        startDate ? format(startDate, "yyyy-MM-dd") : ""
      );
    if (typeDate === "delivery")
      formik.setFieldValue(
        "json.delivery_date",
        startDate ? format(startDate, "yyyy-MM-dd") : ""
      );
    setTypeDate("");
  }, [startDate]);

  useEffect(() => {
    if (data) {
      resetAllFields();
    }
  }, [data]);

  useEffect(() => {
    if (anchorElCalendar === null && typeDate === "") setStartDate(null);
  }, [anchorElCalendar]);

  useEffect(() => {
    const body = {
      name: "",
      start_date: "",
      end_date: "",
      pkp: 0,
      offset: 1,
      limit: 10000,
    };
    mutateCustomerList(body);
  }, []);

  return (
    <>
      <div className="w-1/3 float-right justify-end flex items-center gap-2 mb-8">
        {isEdit ? (
          <button
            onClick={() => {
              setIsEdit(false);
              resetAllFields();
            }}
            disabled={data.is_spk}
            className=" disabled:bg-gray-300 disabled:text-white w-fit text-red-500 px-12 flex items-center justify-center gap-2 border-red-500 border-2 p-2 rounded-md hover:bg-red-100 cursor-pointer"
          >
            <IoPencilSharp />
            <p>Cancel Edit</p>
          </button>
        ) : (
          <>
            <button
              onClick={() => setOpenDelete(true)}
              className="w-fit px-12 flex items-center justify-center gap-2 border-red-500 border-2 p-2 rounded-md text-red-500 hover:bg-red-100 cursor-pointer"
            >
              <FaTrash />
              <p>Hapus</p>
            </button>
            <button
              disabled={
                data && data.status_progress && data.status_progress > 1
              }
              onClick={() => setIsEdit(true)}
              className="disabled:bg-gray-300 disabled:text-white disabled:cursor-default w-fit px-12 flex items-center justify-center gap-2 border-[#18479D] border-2 p-2 rounded-md text-[#18479D] hover:bg-slate-100 cursor-pointer"
            >
              <IoPencilSharp />
              <p>Edit</p>
            </button>
          </>
        )}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="my-2 flex w-full gap-2 items-end">
          <FormControl fullWidth>
            <FormLabel>Status Order</FormLabel>
            <Select
              value={formik.values.json.status_order}
              disabled={!isEdit}
              onChange={(e) =>
                formik.setFieldValue(
                  "json.status_order",
                  Number(e.target.value)
                )
              }
            >
              <MenuItem value={0} disabled>
                Pilih Status Order
              </MenuItem>
              <MenuItem value={1}>New</MenuItem>
              <MenuItem value={2}>Repeat</MenuItem>
              <MenuItem value={3}>Sample Order</MenuItem>
              <MenuItem value={4}>RWC</MenuItem>
              <MenuItem value={5}>New + CRB</MenuItem>
              <MenuItem value={6}>RWC + CRB</MenuItem>
              <MenuItem value={7}>Reprint</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <FormLabel>Metode Pengiriman</FormLabel>
            <Select
              disabled={!isEdit}
              value={formik.values.json.shipping}
              onChange={(e) =>
                formik.setFieldValue("json.shipping", e.target.value)
              }
            >
              <MenuItem value={0} disabled>
                Pilih Metode Pengiriman
              </MenuItem>
              {dataShipmentMethod && dataShipmentMethod.length > 0
                ? dataShipmentMethod.map((item) => {
                    return (
                      <MenuItem value={item.id} key={item.id}>
                        {item.shipment_name}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
          </FormControl>
        </div>
        <div className="my-2 flex w-full gap-2 items-end">
          <FormControl fullWidth>
            <FormLabel>Sales</FormLabel>
            <Select
              disabled={!isEdit}
              value={formik.values.json.user_id}
              onChange={(e) =>
                formik.setFieldValue("json.user_id", e.target.value)
              }
            >
              <MenuItem value={0} disabled>
                Pilih Sales
              </MenuItem>
              {dataUser && dataUser.data && dataUser.data.length > 0
                ? dataUser.data.map((item) => {
                    return <MenuItem value={item.id}>{item.fullname}</MenuItem>;
                  })
                : null}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <FormLabel>Nomor PO</FormLabel>
            <TextField
              disabled={!isEdit}
              value={formik.values.json.po_number}
              onChange={(e) => {
                if (e.target.value.length <= 200)
                  formik.setFieldValue("json.po_number", e.target.value);
              }}
            />
          </FormControl>
        </div>
        <div className="my-2 flex w-full gap-2 items-end">
          <FormControl fullWidth>
            <FormLabel>No. Quotation</FormLabel>
            <Select
              disabled={!isEdit}
              value={selectedQuotation}
              onChange={(e) => {
                formik.setFieldValue("json.quotation_id", e.target.value);
                setSelectedQuotation(e.target.value);
                handleQuotationChange();
              }}
            >
              <MenuItem value={0} disabled>
                Pilih Quotation
              </MenuItem>
              {dataQuotation && dataQuotation.data
                ? dataQuotation.data.map((item) => {
                    return (
                      <MenuItem value={item.id}>
                        {item.quotation_number}
                        {" - "}
                        {item.customer}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
          </FormControl>
          <div className="w-full">
            <p>Tanggal PO</p>
            <div
              className={`w-full mt-1 border-2 rounded-md flex items-center ${
                isEdit ? "hover:bg-slate-100" : "bg-slate-100"
              }`}
            >
              <div
                className={`flex justify-between items-center h-full w-full p-3.5 ${
                  isEdit ? "cursor-pointer" : ""
                }`}
                onClick={(e) => {
                  if (isEdit) handleOpenCalendar(e, "po");
                }}
              >
                {formik.values.json.po_date === "" ||
                formik.values.json.po_date === null ? (
                  <p>----/--/--</p>
                ) : (
                  <p>
                    {format(new Date(formik.values.json.po_date), "yyyy/MM/dd")}
                  </p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
          </div>
        </div>
        <div className="my-2 flex w-full gap-2 items-end">
          <FormControl fullWidth>
            <FormLabel>Nomor SO</FormLabel>
            <TextField disabled value={formik.values.json.so_number} />
          </FormControl>
          <div className="w-full">
            <p>Upload File</p>
            <div className="w-full mt-1 border-2 rounded-md flex items-center p-1.5">
              {formik.values.file &&
              (formik.values.file.file === "" ||
                formik.values.file.file === null) ? (
                <label htmlFor="dropzone-file1" className="">
                  <div
                    className={`border-2 rounded-md p-2 flex justify-between items-center gap-2 border-blue-500 text-blue-500 ${
                      isEdit
                        ? " cursor-pointer hover:bg-slate-100"
                        : "bg-slate-100"
                    }`}
                  >
                    <>
                      <AiOutlineCloudUpload /> <p>Choose File</p>
                    </>
                  </div>
                  <input
                    disabled={!isEdit}
                    id="dropzone-file1"
                    type="file"
                    accept="image/jpeg, image/jpg, image/png, .pdf, .csv, .wordx"
                    onChange={(e) => {
                      if (isEdit) uploadMedia(e);
                    }}
                    hidden
                  />
                </label>
              ) : (
                <div
                  onClick={() => {
                    if (isEdit) formik.setFieldValue("file.file", null);
                  }}
                  className={`border-2 rounded-md p-2 flex justify-between items-center gap-2 border-red-500 text-red-500 ${
                    isEdit
                      ? "cursor-pointer hover:bg-slate-100"
                      : "bg-gray-300 text-white border-white"
                  }`}
                >
                  <>
                    <MdCancel /> <p>Remove File</p>
                  </>
                </div>
              )}

              <p className="ml-4 text-gray-300">
                {formik.values.file &&
                formik.values.file.file !== null &&
                formik.values.file.file !== ""
                  ? formik.values.file.name
                  : "Pilih file dari perangkat anda"}
              </p>
            </div>
          </div>
        </div>
        <div className="my-2 flex w-full gap-2 items-end">
          <div className="w-full">
            <p>Tanggal SO</p>
            <div className="w-full mt-1 border-2 rounded-md flex items-center bg-slate-100">
              <div className="flex justify-between items-center h-full w-full p-3.5 text-gray-500">
                <p>
                  {formik.values.json.quotation_id !== 0
                    ? format(new Date(), "yyyy/MM/dd")
                    : "----/--/--"}
                </p>
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
          </div>
          <div className="w-full">
            <p>Tanggal Jatuh Tempo</p>
            <div
              className={`w-full mt-1 border-2 rounded-md flex items-center ${
                isEdit ? "hover:bg-slate-100" : "bg-slate-100"
              }`}
            >
              <div
                className={`flex justify-between items-center h-full w-full p-3.5 ${
                  isEdit ? "cursor-pointer" : ""
                }`}
                onClick={(e) => {
                  if (isEdit) handleOpenCalendar(e, "due");
                }}
              >
                {formik.values.json.due_date === "" ||
                formik.values.json.due_date === null ? (
                  <p>----/--/--</p>
                ) : (
                  <p>
                    {format(
                      new Date(formik.values.json.due_date),
                      "yyyy/MM/dd"
                    )}
                  </p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
          </div>
        </div>
        <div className="my-2 flex w-full gap-2 items-end">
          <FormControl fullWidth>
            <FormLabel>Customer</FormLabel>
            <Autocomplete
              sx={{ width: "100%" }}
              disabled={!isEdit}
              disablePortal
              value={
                formik.values.json.customer_id && dataCust
                  ? dataCust.data.find(
                      (item) => item.id === formik.values.json.customer_id
                    )
                  : null
              }
              onChange={(_, option) => {
                formik.setFieldValue(
                  "json.customer_id",
                  option ? option.id : null
                );
              }}
              options={dataCust ? dataCust.data : []}
              getOptionLabel={(option) => option.customer_name}
              renderInput={(params) => (
                <TextField {...params} placeholder="Pilih Customer" />
              )}
            />
          </FormControl>
          <div className="w-full">
            <p>ISP Confirmation</p>
            <div
              className={`w-full mt-1 border-2 rounded-md flex items-center ${
                isEdit ? "hover:bg-slate-100" : "bg-slate-100"
              }`}
            >
              <div
                className={`flex justify-between items-center h-full w-full p-3.5 ${
                  isEdit ? "cursor-pointer" : ""
                }`}
                onClick={(e) => {
                  if (isEdit) handleOpenCalendar(e, "delivery");
                }}
              >
                {formik.values.json.delivery_date === "" ||
                formik.values.json.delivery_date === null ? (
                  <p>----/--/--</p>
                ) : (
                  <p>
                    {format(
                      new Date(formik.values.json.delivery_date),
                      "yyyy/MM/dd"
                    )}
                  </p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
          </div>
        </div>

        <FormControl fullWidth sx={{ marginY: 1 }}>
          <FormLabel>Alamat Penagihan</FormLabel>
          <TextField
            multiline
            disabled={!isEdit}
            rows={4}
            onChange={(e) => {
              if (e.target.value.length <= 100)
                formik.setFieldValue("json.bill_to", e.target.value);
            }}
            value={formik.values.json.bill_to}
          />
        </FormControl>

        {formik.values.json.shipping_address.map((item, i) => (
          <FormControl fullWidth sx={{ marginY: 1 }} key={i}>
            <FormLabel>Alamat Pengiriman</FormLabel>
            <div className="flex gap-2 items-center">
              <TextField
                fullWidth
                disabled={!isEdit}
                multiline
                rows={2}
                onChange={(e) => changeShippingAddress(e.target.value, i)}
                value={formik.values.json.shipping_address[i]}
              />
              <FaTrash
                className={`${
                  i === 0 || !isEdit
                    ? "cursor-default text-gray-300"
                    : "text-red-500 hover:text-gray-300 cursor-pointer"
                }`}
                onClick={() => {
                  if (i > 0 && isEdit)
                    formik.setFieldValue("json.shipping_address", [
                      ...formik.values.json.shipping_address.slice(0, i),
                      ...formik.values.json.shipping_address.slice(i + 1),
                    ]);
                }}
              />
            </div>
          </FormControl>
        ))}
        <p
          className={`font-bold text-right ${
            !isEdit
              ? "text-gray-300 "
              : "text-blue-500 cursor-pointer hover:text-gray-300"
          }`}
          onClick={() => {
            if (isEdit)
              formik.setFieldValue("json.shipping_address", [
                ...formik.values.json.shipping_address,
                "",
              ]);
          }}
        >
          Tambah Alamat Pengiriman
        </p>

        <div className="rounded-md shadow-md mt-4">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <th className="p-3 text-left">No</th>
                <th className="p-3 text-left">Nama Label</th>
                <th className="p-3 text-left">Internal Code</th>
                <th className="p-3 text-left">Label Size</th>
                <th className="p-3 text-left">Material</th>
                <th className="p-3 text-left">Price</th>
                <th className="p-3 text-left">Qty</th>
                <th className="p-3 text-left">Total Price</th>
              </thead>
              <tbody>
                {materialArr.length > 0
                  ? materialArr.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td className="p-3 text-left">{i + 1}</td>
                          <td className="p-3 text-left">{item.label_name}</td>
                          <td className="p-3 text-left">{item.code}</td>
                          <td className="p-3 text-left">{item.label_size}</td>
                          <td className="p-3 text-left">
                            <ul>
                              {item.material?.map((mat) => {
                                return <li>{mat}</li>;
                              })}
                            </ul>
                          </td>
                          <td className="p-3 text-left">
                            {item.unit_price.toLocaleString()}
                          </td>
                          <td className="p-3 text-left">{item.qty}</td>
                          <td className="p-3 text-left">
                            {item.total_price.toLocaleString()}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>

        <p className="text-red-500 my-4 text-right">{errorMessage}</p>

        <div className="w-full">
          <div className="flex justify-end w-1/3 gap-2 float-right">
            <button
              type="button"
              className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
              onClick={() => navigate("/sales-order")}
            >
              Cancel
            </button>
            <button
              className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
              disabled={!formik.isValid || !isEdit}
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </form>

      <ModalInput
        title="Edit SO"
        subtitle={`Apakah anda ingin menyimpan data SO ini?`}
        content={
          <>
            <p className="text-red-500">{errorMessage}</p>{" "}
          </>
        }
        open={openAdd}
        setOpen={setOpenAdd}
        hasButton={true}
        buttonText="Simpan"
        setTriggerFunc={() => {
          mutateNewSo(formik.values);
        }}
        isDisable={false}
      />

      <ModalInput
        title="Hapus SO"
        subtitle={`Apakah anda yakin ingin menghapus so ini?`}
        content={
          <>
            <p className="text-red-500">{errorMessageDelete}</p>{" "}
          </>
        }
        open={openDelete}
        setOpen={setOpenDelete}
        hasButton={true}
        buttonText="Hapus"
        setTriggerFunc={() => {
          mutateDeleteSo(Number(id));
        }}
        isDisable={false}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendar}
        setAnchorElCalendarStart={setAnchorElCalendar}
        startDate={startDate}
        setStartDate={setStartDate}
      />
    </>
  );
};

export default EditSalesOrder;
