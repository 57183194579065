import {
  TextField,
  InputAdornment,
  FormGroup,
  FormLabel,
  Autocomplete,
  Chip,
  createFilterOptions,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdCalendarMonth } from "react-icons/md";
import {
  getDeliveryOrder,
  getDetailReturCustomer,
  getMasterDataReturCustomer,
  setNewReturCustomer,
} from "../../../services/returCustomerApi";
import { getSpkDetail, getSpkList } from "../../../services/SpkApi";
import SingleCalendar from "../../common/SingleCalendar";
import moment from "moment";
import { format } from "date-fns";

const DetailReturnCustomer = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const defaultFilterOptions = createFilterOptions();

  const [searchMaterial, setSearchMaterial] = useState("");
  const [fetchLimit, setFetchLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [anchorElCalendarReturn, setAnchorElCalendarReturn] = useState(null);
  const [anchorElCalendarProd, setAnchorElCalendarProd] = useState(null);
  const [returnDate, setReturnDate] = useState(null);
  const [prodDate, setProdDate] = useState(null);
  const [selectedSpk, setSelectedSpk] = useState(null);
  const [isAdd, setIsAdd] = useState(false);
  const [refetchList, setRefetchList] = useState(false);

  const { mutate: mutateSpkList, data: dataSpkList } = useMutation({
    mutationFn: getSpkList,
  });

  const { data: dataDetailSpk, isFetching: isFetchingSpk } = useQuery({
    queryKey: ["spk-detail", selectedSpk],
    queryFn: () => getSpkDetail(selectedSpk?.id),
    enabled: selectedSpk !== null,
  });

  const { mutate: mutateDeliveryOrder, data: dataDeliveryOrder } = useMutation({
    mutationFn: getDeliveryOrder,
  });

  useEffect(() => {
    if (selectedSpk) {
      mutateDeliveryOrder(selectedSpk?.id);
    }
  }, [selectedSpk]);

  const { data: dataUom } = useQuery({
    queryKey: ["uom", isAdd, refetchList],
    queryFn: () => getMasterDataReturCustomer({ type: 3 }),
  });

  const {
    data: dataDetail,
    isFetching: isFetchingDetail,
    error,
  } = useQuery({
    queryKey: ["detail-retur-customer", id],
    enabled: id !== null,
    queryFn: () => getDetailReturCustomer(id),
  });

  const { mutate: mutateNewReturn, isPending: isPendingCreate } = useMutation({
    mutationFn: setNewReturCustomer,
    onSuccess: () => navigate("/retur-customer"),
    onError: (err) => setErrorMessage(err.message.id),
  });

  const formik = useFormik({
    initialValues: {
      id: Number(id),
      date_retur:
        dataDetail && dataDetail.date_retur
          ? moment(dataDetail.date_retur).format("YYYY-MM-DD")
          : "",
      prod_date:
        dataDetail && dataDetail.prod_date
          ? moment(dataDetail.prod_date).format("YYYY-MM-DD")
          : "",
      spk_id: dataDetail ? dataDetail.spk_id : 0,
      do_id: dataDetail ? dataDetail.do_id : 0,
      customer_id: dataDetail ? dataDetail?.customer_id : 0,
      problem: dataDetail ? dataDetail.problem : "",
      items: dataDetail
        ? dataDetail.item.map((item) => ({
            internal_code_id: item.internal_code_id || 0,
            internal_code: item.internal_code || "",
            label_name: item.label_name || "",
            uom_id: item.uom_id || 0,
            uom_name: item.uom_name || "",
            quantity: item.quantity || 0,
            price_unit: item.price_unit || 0,
            total_cost: item.total_cost || 0,
          }))
        : [
            {
              internal_code_id: 0,
              internal_code: "",
              label_name: "",
              uom_id: 0,
              uom_name: "",
              quantity: 0,
              price_unit: 0,
              total_cost: 0,
            },
          ],
      remark: dataDetail ? dataDetail.remark : "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      mutateNewReturn(values);
    },
  });

  useEffect(() => {
    setPage(1);
  }, [fetchLimit, searchMaterial]);

  useEffect(() => {
    if (dataDetail && dataSpkList) {
      if (dataDetail.spk_id)
        setSelectedSpk(
          dataSpkList.data.find((item) => item.id === dataDetail.spk_id)
        );
    }
  }, [dataDetail, dataSpkList]);

  useEffect(() => {
    if (dataDetail) {
      setReturnDate(new Date(dataDetail.date_retur));
      setProdDate(new Date(dataDetail.prod_date));
    }
  }, [dataDetail]);

  useEffect(() => {
    returnDate
      ? formik.setFieldValue("date_retur", moment(returnDate).format("YYYY-MM-DD"))
      : formik.setFieldValue("date_retur", "");
  }, [returnDate]);

  useEffect(() => {
    prodDate
      ? formik.setFieldValue("prod_date", moment(prodDate).format("YYYY-MM-DD"))
      : formik.setFieldValue("prod_date", "");
  }, [prodDate]);

  useEffect(() => {
    mutateSpkList({
      offset: 1,
      limit: 10000,
      schedule: 0,
      search: "",
      po_start: "",
      po_end: "",
      delivery_start: "",
      delivery_end: "",
      job_type: [],
      category: [],
      dies: [],
    });
  }, []);

  console.log("ini adalah return date", dataDetail?.do_number);
  return (
    <div className="mt-12 lg:mt-2">
      <form onSubmit={formik.handleSubmit}>
        <div className="flex gap-4">
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Date Return</FormLabel>
            <div className="w-full border-2 rounded-md flex items-center hover:bg-slate-100">
              <div
                className="flex justify-between items-center h-full w-full cursor-pointer p-3.5"
                onClick={(e) => setAnchorElCalendarReturn(e.currentTarget)}
              >
                {returnDate === null ? (
                  <p>----/--/--</p>
                ) : (
                  <p>{format(new Date(returnDate), "yyyy-MM-dd")}</p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
          </FormGroup>
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Prod Date</FormLabel>
            <div className="w-full border-2 rounded-md flex items-center hover:bg-slate-100">
              <div
                className="flex justify-between items-center h-full w-full cursor-pointer p-3.5"
                onClick={(e) => setAnchorElCalendarProd(e.currentTarget)}
              >
                {prodDate === null ? (
                  <p>----/--/--</p>
                ) : (
                  <p>{format(new Date(prodDate), "yyyy-MM-dd")}</p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
          </FormGroup>
        </div>

        <div className="flex gap-4">
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>SPK</FormLabel>
            <Autocomplete
              sx={{ width: "100%" }}
              disabled
              value={selectedSpk}
              onChange={(_, option) => {
                formik.setFieldValue("spk_id", option ? option.id : null);
                setSelectedSpk(option);
              }}
              options={dataSpkList && dataSpkList.data ? dataSpkList.data : []}
              getOptionLabel={(option) => option.spk_no}
              renderInput={(params) => (
                <TextField {...params} placeholder="Pilih SPK" />
              )}
            />
          </FormGroup>
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>No. DO</FormLabel>
            <div className="w-full border border-gray-300 rounded p-3">
              {dataDetail?.do_number &&
                dataDetail?.do_number.map((item, index) => {
                  return (
                    <Chip
                      key={index}
                      label={item}
                      sx={{ w: "fit-content", mr: "0.2rem" }}
                      onDelete={() => {}}
                    />
                  );
                })}
            </div>
          </FormGroup>
        </div>

        <div className="flex gap-4">
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>PO No</FormLabel>
            <TextField
              fullWidth
              value={dataDetailSpk ? dataDetailSpk.po_number : ""}
              disabled
              placeholder="Nomor PO"
            />
          </FormGroup>
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Customer</FormLabel>
            <TextField
              fullWidth
              disabled
              value={dataDetailSpk?.customer_name}
              placeholder="Customer"
            />
          </FormGroup>
        </div>

        <div className="flex gap-4">
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Internal Code</FormLabel>
            <div className="w-full border border-gray-300 rounded p-3">
              {dataDetail?.item &&
                  dataDetail?.item.map((item, index) => {
                  return (
                    <Chip
                      key={index}
                      label={item.internal_code}
                      sx={{ w: "fit-content", mr: "0.2rem" }}
                      onDelete={() => {}}
                    />
                  );
                })}
            </div>
          </FormGroup>
        </div>

        <div className="rounded-md shadow-md">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-4 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <th className="p-3 text-left">No</th>
                <th className="p-3 text-left">Nama Product</th>
                <th className="p-3 text-left">Qty</th>
                <th className="p-3 text-left">UOM</th>
                <th className="p-3 text-left">Price per Unit</th>
                <th className="p-3 text-left">Total Cost</th>
              </thead>
              <tbody>
                {dataDetail?.item?.length > 0 ? (
                  dataDetail?.item.map((item, i) => (
                    <tr className="border-t border-l border-2" key={i}>
                      <td className="p-3">{i + 1}</td>
                      <td className="p-3 text-left">{item.label_name}</td>
                      <td className="p-3 text-left w-28">
                        <TextField
                          fullWidth
                          value={
                            formik.values.items &&
                            formik.values.items[i]?.quantity !== undefined
                              ? formik.values.items[i]?.quantity
                              : 0
                          }
                          onChange={(e) => {
                            const newValue = e.target.value;

                            if (!isNaN(Number(newValue)) && newValue !== "") {
                              formik.setFieldValue(
                                `items[${i}].quantity`,
                                Number(newValue)
                              );
                            } else if (newValue === "") {
                              formik.setFieldValue(`items[${i}].quantity`, 0);
                            }
                          }}
                        />
                      </td>
                      <td className="p-3 text-left">
                        <Autocomplete
                          sx={{ width: "100%" }}
                          disablePortal
                          value={
                            dataUom?.find(
                              (uom) => uom.id === formik.values.items[i]?.uom_id
                            ) || null
                          }
                          onChange={(_, option) => {
                            if (option) {
                              formik.setFieldValue(
                                `items[${i}].uom_id`,
                                option.id
                              );
                              formik.setFieldValue(
                                `items[${i}].uom_name`,
                                option.name
                              );
                            }
                          }}
                          options={
                            dataUom
                              ? [
                                  { id: -1, name: "Tambah UOM disini..." },
                                  ...dataUom,
                                ]
                              : []
                          }
                          getOptionLabel={(option) =>
                            option.name && typeof option.name === "string"
                              ? option.name
                              : ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Pilih Type Non Conformity"
                            />
                          )}
                          filterOptions={(options, state) => {
                            const results = defaultFilterOptions(
                              options,
                              state
                            );
                            if (results?.length === 0) {
                              return [{ id: -1, name: "Tambah UOM" }];
                            }
                            return results;
                          }}
                        />
                      </td>
                      <td className="p-3 text-left w-44">
                        <TextField
                          fullWidth
                          value={
                            formik.values.items &&
                            formik.values.items[i]?.price_unit !== undefined
                              ? formik.values.items[i]?.price_unit
                              : 0
                          }
                          onChange={(e) => {
                            const newValue = e.target.value;

                            if (!isNaN(Number(newValue)) && newValue !== "") {
                              formik.setFieldValue(
                                `items[${i}].price_unit`,
                                Number(newValue)
                              );
                            } else if (newValue === "") {
                              formik.setFieldValue(`items[${i}].price_unit`, 0);
                            }
                          }}
                        />
                      </td>
                      <td className="p-3 text-left w-44">
                        <TextField
                          fullWidth
                          value={
                            formik.values.items &&
                            formik.values.items[i]?.total_cost !== undefined
                              ? formik.values.items[i]?.total_cost
                              : 0
                          }
                          onChange={(e) => {
                            const newValue = e.target.value;

                            if (!isNaN(Number(newValue)) && newValue !== "") {
                              formik.setFieldValue(
                                `items[${i}].total_cost`,
                                Number(newValue)
                              );
                            } else if (newValue === "") {
                              formik.setFieldValue(`items[${i}].total_cost`, 0);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center p-3" colSpan={7}>
                      Belum ada material terpilih
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Problem</FormLabel>
          <TextField
            fullWidth
            value={formik.values.problem}
            onChange={(e) => {
              if (e.target.value.length <= 300)
                formik.setFieldValue("problem", e.target.value);
            }}
            placeholder="Tuliskan problem disini..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {formik.values.problem.length}/300
                </InputAdornment>
              ),
            }}
          />
        </FormGroup>

        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Remark</FormLabel>
          <TextField
            fullWidth
            value={formik.values.remark}
            onChange={(e) => {
              if (e.target.value.length <= 300)
                formik.setFieldValue("remark", e.target.value);
            }}
            placeholder="Tuliskan remark disini..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {formik.values.remark.length}/300
                </InputAdornment>
              ),
            }}
          />
        </FormGroup>

        <p className="text-red-500 mb-4 text-right m-4">{errorMessage}</p>
        <div className="w-full">
          <div className="flex justify-end w-1/3 gap-2 float-right">
            <button
              type="button"
              className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
              onClick={() => navigate("/retur-supplier")}
            >
              Cancel
            </button>
            <button
              className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
              disabled={!formik.isValid}
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </form>

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendarReturn}
        setAnchorElCalendarStart={setAnchorElCalendarReturn}
        startDate={returnDate}
        setStartDate={setReturnDate}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendarProd}
        setAnchorElCalendarStart={setAnchorElCalendarProd}
        startDate={prodDate}
        setStartDate={setProdDate}
      />
    </div>
  );
};

export default DetailReturnCustomer;
