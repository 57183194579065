import {
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import SingleCalendar from "../../common/SingleCalendar";
import { MdCalendarMonth } from "react-icons/md";
import { format } from "date-fns";
import { FaMinusCircle, FaTrash } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import ModalInput from "../../common/ModalInput";
import { Search } from "@mui/icons-material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getMaterialList } from "../../../services/materialApi";
import { FaCirclePlus } from "react-icons/fa6";
import { useFormik } from "formik";
import { getSupplierList } from "../../../services/supplierApi";
import { useNavigate, useParams } from "react-router-dom";
import { createSupplierPO } from "../../../services/supplierPurchaseOrderApi";
import * as yup from "yup";
import { getSupplierPoDetail } from "../../../services/supplierPoApi";

const validationSchema = yup.object({
  po_number: yup.string(),
  po_date: yup.string().required(),
  supplier_id: yup.number().min(1).required(),
  arrived_date: yup.string().required(),
  notes: yup.string().required(),
  freight_charge: yup.number().min(0),
  materials: yup
    .array()
    .of(
      yup.object().shape({
        material_id: yup.number(),
        quantity: yup.number(),
        price: yup.number().min(1),
        unit: yup.string(),
        discount: yup.number(),
      })
    )
    .min(1),
});

const EditSupplierPo = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [anchorElCalendarArrive, setAnchorElCalendarArrive] = useState(null);
  const [poDate, setPoDate] = useState(null);
  const [arriveDate, setArriveDate] = useState(null);
  const [materialArr, setMaterialArr] = useState([]);
  const [openProduct, setOpenProduct] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [search, setSearch] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { mutate: mutateSupplierList, data: dataSupplier } = useMutation({
    mutationFn: getSupplierList,
  });

  const findSupplierName = () => {
    if (dataSupplier && dataSupplier.data) {
      return dataSupplier.data.find(
        (item) => item.id === formik.values.supplier_id
      ).supplier_name;
    }
  };

  const findMaterialName = (id) => {
    if (data && data.materials) {
      return data.materials.find((item) => item.id === id)?.material_name;
    }
  };

  const findMaterialUnit = (id) => {
    if (data && data.materials) {
      return data.materials.find((item) => item.id === id)?.unit;
    }
  };

  const findPPN = (id) => {
    if (dataSupplier && dataSupplier.data) {
      return dataSupplier.data.find((item) => item.id === id)?.is_pkp;
    }
  };

  const findSubtotal = (i, id) => {
    const item = materialArr[i];
    const ppn = findPPN(id) ? 1.11 : 1;
    return item.quantity * item.price * ppn - item.discount;
  };

  const countGrandTotal = () => {
    var total = 0;
    materialArr.map(
      (item, i) =>
        (total +=
          item.quantity *
            item.price *
            (findPPN(formik.values.supplier_id) ? 1.11 : 1) -
          item.discount)
    );
    total += formik.values.freight_charge;
    return total;
  };

  const handleDeleteMaterial = (index) => {
    const temp = [
      ...materialArr.slice(0, index),
      ...materialArr.slice(index + 1),
    ];
    setMaterialArr(temp);
  };

  const handleMaterialChange = (material, type) => {
    var temp = [...materialArr];
    const indexMaterial = temp.findIndex(
      (item) => item.material_id === material.id
    );
    if (type === "increase") {
      if (indexMaterial >= 0)
        temp[indexMaterial] = {
          material_id: material.id,
          unit: material.uom,
          price: 0,
          quantity: temp[indexMaterial].quantity + 1,
          discount: 0,
        };
      else {
        temp.push({
          material_id: material.id,
          unit: material.uom,
          price: 0,
          quantity: 1,
          discount: 0,
        });
      }
    }
    if (type === "decrease") {
      if (indexMaterial >= 0 && temp[indexMaterial].quantity > 0)
        if (indexMaterial >= 0)
          temp[indexMaterial] = {
            material_id: material.id,
            unit: material.uom,
            price: 0,
            quantity: temp[indexMaterial].quantity - 1,
            discount: 0,
          };
      temp = temp.filter((item) => item.quantity > 0);
    }
    if (type === "manual") {
      if (indexMaterial >= 0)
        temp[indexMaterial] = {
          material_id: material.id,
          unit: material.uom,
          price: 0,
          quantity: temp[indexMaterial].quantity,
          discount: 0,
        };
    }

    setMaterialArr(temp);
  };

  const handleManualQuantityChange = (material, value) => {
    if (!isNaN(Number(value))) {
      const temp = [...materialArr];
      const indexMaterial = temp.findIndex(
        (item) => item.material_id === material.id
      );

      if (indexMaterial >= 0) {
        temp[indexMaterial].quantity = Number(value);
      } else {
        temp.push({
          material_id: material.id,
          unit: material.uom,
          price: 0,
          quantity: Number(value),
          discount: 0,
        });
      }

      setMaterialArr(temp);
    }
  };

  const { data: dataDetail } = useQuery({
    queryKey: ["po-detail"],
    queryFn: () => getSupplierPoDetail(id),
  });

  const formik = useFormik({
    initialValues: {
      id: id ? Number(id) : 0,
      po_number:
        dataDetail && dataDetail.po_detail.po_number
          ? dataDetail.po_detail.po_number
          : "",
      po_date:
        dataDetail && dataDetail.po_detail.po_date
          ? dataDetail.po_detail.po_date
          : "",
      supplier_id:
        dataDetail && dataDetail.po_detail.supplier_id
          ? dataDetail.po_detail.supplier_id
          : 0,
      arrived_date:
        dataDetail && dataDetail.po_detail.arrived_date
          ? dataDetail.po_detail.arrived_date
          : "",
      notes:
        dataDetail && dataDetail.po_detail.notes
          ? dataDetail.po_detail.notes
          : "",
      freight_charge:
        dataDetail && dataDetail.po_detail.freight_charge
          ? dataDetail.po_detail.freight_charge
          : "",
      materials: dataDetail && dataDetail.material ? dataDetail.material : [],
    },
    validationSchema,
    enableReinitialize: true,
    validate: () => {},
    validateOnMount: true,
    onSubmit: (values) => {
      mutateCreatePo(values);
    },
  });

  useEffect(() => {
    formik.setFieldValue("materials", materialArr);
  }, [materialArr]);

  const { mutate: mutateCreatePo, isPending } = useMutation({
    mutationFn: createSupplierPO,
    onSuccess: () => {
      navigate("/sv-po");
    },
    onError: (err) => setErrorMessage(err.message.id),
  });

  const { data, isFetching, refetch } = useQuery({
    queryKey: [
      "material-list",
      search,
      formik.values.supplier_id,
      dataSupplier,
    ],
    enabled: formik.values.supplier_id > 0 && dataSupplier !== undefined,
    queryFn: () =>
      getMaterialList({
        search: search,
        filter: findSupplierName(),
        offset: 1,
        limit: 10000,
      }),
  });

  useEffect(() => {
    if (isAdd) {
      setIsAdd(false);
    }
  }, [isAdd]);

  useEffect(() => {
    setErrorMessage("");
  }, [formik.values]);

  useEffect(() => {
    if (poDate) formik.setFieldValue("po_date", format(poDate, "yyyy-MM-dd"));
  }, [poDate]);

  useEffect(() => {
    if (arriveDate)
      formik.setFieldValue("arrived_date", format(arriveDate, "yyyy-MM-dd"));
  }, [arriveDate]);

  useEffect(() => {
    if (dataDetail) {
      setArriveDate(new Date(dataDetail.po_detail.arrived_date));
      setPoDate(new Date(dataDetail.po_detail.po_date));
      var temp = [];
      dataDetail.material.map((item) => {
        temp.push({
          material_id: item.material_id,
          material_name: item.material_name,
          unit: item.unit,
          price: item.price,
          quantity: item.quantity,
          discount: item.discount,
        });
      });
      setMaterialArr(temp);
      refetch();
    }
  }, [dataDetail]);

  useEffect(() => {
    if (anchorElCalendar && !poDate) setPoDate(new Date());
    if (anchorElCalendarArrive && !arriveDate) setArriveDate(new Date());
  }, [anchorElCalendar, anchorElCalendarArrive]);

  useEffect(() => {
    const body = {
      name: "",
      start_date: "",
      end_date: "",
      pkp: 0,
      offset: 1,
      limit: 10000,
    };
    mutateSupplierList(body);
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          label="No. PO"
          fullWidth
          sx={{ marginBottom: 2 }}
          value={formik.values.po_number}
          disabled
        />
        <p>Tanggal PO</p>
        <div className="mb-4 w-full border-2 rounded-md flex items-center hover:bg-slate-100">
          <div
            className="flex justify-between items-center h-full w-full cursor-pointer p-4"
            onClick={(e) => setAnchorElCalendar(e.currentTarget)}
          >
            {poDate === null ? (
              <p>----/--/--</p>
            ) : (
              <p>{format(new Date(poDate), "yyyy-MM-dd")}</p>
            )}
            <MdCalendarMonth className="w-6 h-6" />
          </div>
        </div>
        <p>Supplier</p>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <Select
            value={formik.values.supplier_id}
            onChange={(e) =>
              formik.setFieldValue("supplier_id", e.target.value)
            }
          >
            <MenuItem disabled value={0}>
              Pilih Supplier
            </MenuItem>
            {dataSupplier && dataSupplier.data ? (
              dataSupplier.data.map((supplier) => (
                <MenuItem value={supplier.id}>
                  {supplier.supplier_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>Tidak ada data supplier</MenuItem>
            )}
          </Select>
        </FormControl>
        <p>Tanggal Kedatangan</p>
        <div className="mb-4 border-2 rounded-md w-full flex items-center hover:bg-slate-100">
          <div
            className="flex justify-between items-center h-full w-full cursor-pointer p-4"
            onClick={(e) => setAnchorElCalendarArrive(e.currentTarget)}
          >
            {arriveDate === null ? (
              <p>----/--/--</p>
            ) : (
              <p>{format(new Date(arriveDate), "yyyy-MM-dd")}</p>
            )}
            <MdCalendarMonth className="w-6 h-6" />
          </div>
        </div>
        <TextField
          label="Notes"
          fullWidth
          sx={{ marginBottom: 2 }}
          value={formik.values.notes}
          onChange={(e) => {
            if (e.target.value.length <= 200)
              formik.setFieldValue("notes", e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {formik.values.notes.length}/200
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Freight Charge"
          fullWidth
          sx={{ marginBottom: 2 }}
          value={formik.values.freight_charge}
          onChange={(e) => {
            if (!isNaN(Number(e.target.value)))
              formik.setFieldValue("freight_charge", Number(e.target.value));
          }}
        />

        <div className="mt-8 flex items-center justify-between">
          <p className="font-bold text-xl">Added Material</p>
          <button
            type="button"
            onClick={() => setOpenProduct(true)}
            className="px-6 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
          >
            <p>Add Material</p>
            <IoMdAddCircleOutline className="text-[18px]" />
          </button>
        </div>
        <hr className="my-4" />

        {data && !isFetching ? (
          <div className="rounded-md shadow-md">
            <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
              <table className="w-full">
                <thead className="bg-[#F4F5FF] border-t border-b border-2">
                  <th className="p-3 text-left">No</th>
                  <th className="p-3 text-left">Nama Material</th>
                  <th className="p-3 text-left">Qty</th>
                  <th className="p-3 text-left">Harga</th>
                  <th className="p-3 text-left">Unit</th>
                  <th className="p-3 text-left">Total</th>
                  <th className="p-3 text-left">PPN</th>
                  <th className="p-3 text-left">Diskon</th>
                  <th className="p-3 text-left">Subtotal</th>
                  <th className="p-3 text-left">Action</th>
                </thead>
                <tbody>
                  {materialArr.length > 0 ? (
                    materialArr.map((item, i) => (
                      <tr className="border-t border-l border-2" key={i}>
                        <td className="p-3">{i + 1}</td>
                        <td className="p-3">
                          {item.material_name}
                        </td>
                        <td className="p-3">{item.quantity}</td>
                        <td className="p-3">
                          <TextField
                            value={item.price}
                            onChange={(e) => {
                              if (!isNaN(Number(e.target.value))) {
                                const temp = [...materialArr];
                                temp[i].price = Number(e.target.value);
                                setMaterialArr(temp);
                              }
                            }}
                          />
                        </td>
                        <td className="p-3">
                          {item.unit}
                        </td>
                        <td className="p-3">
                          Rp
                          {(item.quantity * item.price)
                            .toLocaleString()
                            .replace(",", ".")}
                        </td>{" "}
                        <td className="p-3">
                          {findPPN(formik.values.supplier_id) ? "11%" : "-"}
                        </td>
                        <td className="p-3">
                          {" "}
                          <TextField
                            value={item.discount}
                            onChange={(e) => {
                              if (!isNaN(Number(e.target.value))) {
                                const temp = [...materialArr];
                                temp[i].discount = Number(e.target.value);
                                setMaterialArr(temp);
                              }
                            }}
                          />
                        </td>
                        <td className="p-3">
                          Rp
                          {findSubtotal(i, formik.values.supplier_id)
                            .toLocaleString()
                            .replace(",", ".")}
                        </td>
                        <td className="p-3 w-[15%]">
                          <div className="flex gap-2 items-center">
                            <FaTrash
                              onClick={() => {
                                handleDeleteMaterial(i);
                              }}
                              className="text-red-500 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="p-3 text-center" colSpan={10}>
                        Belum ada material
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}

        <p className="font-bold text-right m-4">
          Grand Total: Rp{countGrandTotal().toLocaleString().replace(",", ".")}
        </p>

        <p className="text-red-500 mb-4 text-right m-4">{errorMessage}</p>
        <div className="flex justify-end w-1/3 gap-2 float-right">
          <button
            type="button"
            className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
            onClick={() => navigate("/sv-po")}
          >
            Cancel
          </button>
          <button
            className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
            disabled={!formik.isValid || isPending || countGrandTotal() < 0}
            type="submit"
          >
            Save
          </button>
        </div>
      </form>

      <ModalInput
        title="Add Product"
        subtitle=""
        content={
          <>
            <TextField
              fullWidth
              placeholder="Search material"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <hr className="my-4" />
            <div className="rounded-md shadow-md">
              <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
                <table className="w-full">
                  <thead className="bg-[#F4F5FF] border-t border-b border-2">
                    <th className="p-3 text-left">No</th>
                    <th className="p-3 text-left">Nama Material</th>
                    <th className="p-3 text-center">Qty</th>
                  </thead>
                  <tbody>
                    {data &&
                    data.materials &&
                    data.materials.length > 0 &&
                    !isFetching ? (
                      data.materials.map((item, i) => (
                        <tr>
                          <td className="p-3">{i + 1}</td>
                          <td className="p-3">{item.name}</td>
                          <td className="p-3">
                            <div className="flex items-center gap-2 justify-center">
                              <button
                                disabled={
                                  materialArr.findIndex(
                                    (mat) => mat.material_id === item.id
                                  ) >= 0 &&
                                  materialArr[
                                    materialArr.findIndex(
                                      (mat) => mat.material_id === item.id
                                    )
                                  ].quantity === 0
                                }
                                className="text-red-500 hover:text-red-300 disabled:text-gray-300"
                                onClick={() =>
                                  handleMaterialChange(item, "decrease")
                                }
                              >
                                <FaMinusCircle className="w-[24px] h-full" />
                              </button>
                              <input
                                type="number"
                                value={
                                  materialArr.findIndex(
                                    (mat) => mat.material_id === item.id
                                  ) >= 0
                                    ? materialArr[
                                        materialArr.findIndex(
                                          (mat) => mat.material_id === item.id
                                        )
                                      ].quantity
                                    : item.quantity
                                }
                                onChange={(e) =>
                                  handleManualQuantityChange(
                                    item,
                                    e.target.value
                                  )
                                }
                                className="w-16 text-center border border-gray-300 rounded"
                              />
                              <button
                                onClick={() =>
                                  handleMaterialChange(item, "increase")
                                }
                                className="hover:text-blue-300 text-blue-500 disabled:text-gray-300"
                              >
                                <FaCirclePlus className="w-[24px] h-full" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="p-3 text-center" colSpan={3}>
                          Belum ada material
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        }
        open={openProduct}
        setOpen={setOpenProduct}
        hasButton={true}
        buttonText="Submit"
        setTriggerFunc={() => {
          setOpenProduct(false);
        }}
        isDisable={false}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendar}
        setAnchorElCalendarStart={setAnchorElCalendar}
        startDate={poDate}
        setStartDate={setPoDate}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendarArrive}
        setAnchorElCalendarStart={setAnchorElCalendarArrive}
        startDate={arriveDate}
        setStartDate={setArriveDate}
      />
    </>
  );
};

export default EditSupplierPo;
