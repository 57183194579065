import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { CircularProgress, TextField, InputAdornment } from "@mui/material";
import Logo from "../../../assets/logo.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import HOC from "../../HOC/index";
import { AiFillEyeInvisible } from "react-icons/ai";
import { MdVisibility } from "react-icons/md";
import { BsCheckCircle, BsFillArrowRightSquareFill } from "react-icons/bs";
import ModalPopup from "../../common/ModalPopup";
import { resetPassword } from "../../../services/authApi";

const NewPassword = () => {
  const { param } = useParams();
  const navigate = useNavigate();
  const id = atob(param).split(" = ")[1];

  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);

  const handleSubmit = () => {
    const body = {
      request_id: Number(id),
      new_password: password,
    };

    if (
      password.match(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/) &&
      password.length >= 8
    )
      mutateNewPassword(body);
    else
      setErrorMessage(
        "Password baru tidak boleh sama dengan yang lama. Minimal 8 Karakter, Memiliki 1 Huruf Kapital dan 1 Karakter Khusus"
      );
  };

  const { mutate: mutateNewPassword, isPending } = useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      setOpen(true);
      setErrorMessage("");
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  useEffect(() => {
    if (open)
      setTimeout(() => {
        navigate("/login");
      }, 3500);
  }, [open]);

  return (
    <div className="bg-white ">
      <div className="flex flex-row justify-center h-screen">
        <div className="hidden md:order-first bg-cover lg:block lg:w-1/2">
          <div className="flex items-center h-full px-20 bg-[#17469E]">
            <div className="w-full my-10 flex justify-center items-center bg-white bg-opacity-40 rounded-md p-4">
              <img src={Logo} alt="" className="mt-16 lg:my-0" />
            </div>
          </div>
        </div>

        <div className="w-full lg:w-1/2 flex justify-center items-center px-12">
          <div className="border-2 border-gray-300 rounded-md p-8 w-fit h-fit">
            <img
              src={Logo}
              alt=""
              className="mt-16 lg:my-0 w-[100px] lg:w-[200px]"
            />
            <h2 className="text-3xl font-bold text-black my-10 lg:my-4">
              Change Password
            </h2>
            <p className="mb-8">Ubah password anda dengan yang baru</p>
            <div className="mt-4 flex items-center">
              <TextField
                variant="standard"
                type={showPassword ? "text" : "password"}
                label="Password"
                value={password}
                onChange={(e) => {
                  setErrorMessage("");
                  setPassword(e.target.value);
                }}
                className="w-full"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {showPassword ? (
                        <AiFillEyeInvisible
                          onClick={() => setShowPassword(!showPassword)}
                          className="cursor-pointer"
                        />
                      ) : (
                        <MdVisibility
                          onClick={() => setShowPassword(!showPassword)}
                          className="cursor-pointer"
                        />
                      )}
                    </InputAdornment>
                  ),
                  style: { textTransform: "uppercase" },
                }}
                fullWidth
              />
            </div>
            <p className="text-red-500 text-sm">{errorMessage}</p>
            <div className="mt-10 flex items-center">
              {!isPending ? (
                <button
                  className="bg-[#17469E] text-white w-full tracking-wide font-semibold p-4 rounded-lg 
                    focus:outline-none focus:shadow-outline hover:bg-indigo-600 shadow-lg transform duration-200 hover:scale-100
                    disabled:bg-slate-400"
                  disabled={isPending}
                  onClick={handleSubmit}
                >
                  <div className="absolute right-0 inset-y-0 mr-4">
                    <BsFillArrowRightSquareFill className="h-full w-6" />
                  </div>
                  <div className="inline-block">
                    {isPending ? <CircularProgress /> : <p>Change Password</p>}
                  </div>
                </button>
              ) : (
                <div className="w-full text-center">
                  <CircularProgress size={20} color="primary" />
                </div>
              )}
            </div>
            <div className="text-right font-display text-[#17469E] hover:text-indigo-800 cursor-pointer mt-4 text-xs">
              <Link to="/login">
                <p className="underline decoration-solid">Back to Login</p>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <ModalPopup
        open={open}
        setOpen={setOpen}
        title="Change Password"
        subtitle="Password anda berhasil diubah. Mengarahkan ke halaman login."
        icon={
          <>
            <BsCheckCircle className="text-3xl text-green-500" />
          </>
        }
      />
    </div>
  );
};

export default HOC(NewPassword, "Login-required");
