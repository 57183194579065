import {
  Autocomplete,
  Breadcrumbs,
  FormControl,
  Link,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SingleCalendar from "../../common/SingleCalendar";
import { useEffect, useState } from "react";
import { MdCalendarMonth } from "react-icons/md";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  getDoDetail,
  getShipmentMethod,
  setNewDo,
} from "../../../services/deliveryOrder";

const DetailDeliveryOrder = () => {
  const { id } = useParams();

  const [errorMessage, setErrorMessage] = useState("");

  const { data } = useQuery({
    queryKey: ["do-detail", id],
    queryFn: () => getDoDetail(id),
  });

  const { data: dataShipmentMethod } = useQuery({
    queryKey: ["sm-list"],
    queryFn: () => getShipmentMethod(),
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <div className="flex justify-between items-center mb-8">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/do">
            Delivery Order
          </Link>
          <Link underline="hover" color="inherit">
            Detail Delivery Order
          </Link>
        </Breadcrumbs>
      </div>
      <p className="text-xl font-bold mb-8">Detail Delivery Order</p>
      <p>Nomor Do</p>
      <TextField
        fullWidth
        value={data && data.do_number ? data.do_number : ""}
        disabled
        sx={{ marginBottom: 2 }}
      />
      <p>Delivery Order Date</p>
      <div className="mb-4  w-full border-2 rounded-md flex items-center hover:bg-slate-100">
        <div className="flex justify-between items-center h-full w-full cursor-pointer p-2">
          {data && data.do_date ? (
            <p className="text-gray-300">
              {format(new Date(data.do_date), "yyyy-MM-dd")}
            </p>
          ) : (
            <p className="text-gray-300">----/--/--</p>
          )}
          <MdCalendarMonth className="w-6 h-6" />
        </div>
      </div>
      <TextField
        fullWidth
        label="No. SO"
        value={
          data && data.so_number
            ? data.so_number +
              (data.so_customer ? " - " + data.so_customer : "")
            : ""
        }
        disabled
        sx={{ marginBottom: 2 }}
      />
      <TextField
        fullWidth
        label="No. SPK"
        value={data && data.spk_no ? data.spk_no : ""}
        disabled
        sx={{ marginBottom: 2 }}
      />
      <TextField
        fullWidth
        value={
          data && data.delivery_address ? data.delivery_address.join(", ") : ""
        }
        disabled
        label="Alamat Pengiriman"
        sx={{ marginBottom: 2 }}
      />
      <TextField
        fullWidth
        value={data && data.bill_to ? data.bill_to : ""}
        disabled
        label="Alamat Penagihan"
        sx={{ marginBottom: 2 }}
      />
      <TextField
        fullWidth
        value={data && data.customer_name ? data.customer_name : ""}
        disabled
        label="Nama Customer"
        sx={{ marginBottom: 2 }}
      />
      <p className="text-xl font-bold">Label</p>
      <div className="rounded-md shadow-md mb-4">
        <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
          <table className="w-full">
            <thead className="bg-[#F4F5FF] border-t border-b border-2">
              <th className="p-3 text-left">No</th>
              <th className="p-3 text-left">Label Name</th>
              <th className="p-3 text-left">Qty Plan</th>
              <th className="p-3 text-left">Qty Actual</th>
              <th className="p-3 text-left">Qty Deliver</th>
              {/* <th className="p-3 text-left">Stok In</th> */}
            </thead>
            <tbody>
              {data && data.label ? (
                data.label.map((item, i) => (
                  <tr key={i}>
                    <td className="p-3 text-left border-b-2">{i + 1}</td>
                    <td className="p-3 text-left border-b-2">
                      {item.label_name}
                    </td>
                    <td className="p-3 text-left border-b-2">
                      {item.qty_plan}
                    </td>
                    <td className="p-3 text-left border-b-2">
                      {item.qty_actual}
                    </td>
                    <td className="p-3 text-left border-b-2">
                      {item.quantity_delivery}
                    </td>
                    {/* <td className="p-3 text-left border-b-2">
                      {item.total_stock}
                    </td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="p-3 text-center" colSpan={6}>
                    Tidak ada SPK
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <p>Tanggal Delivery</p>
      <div className="mb-4  w-full border-2 rounded-md flex items-center hover:bg-slate-100">
        <div className="flex justify-between items-center h-full w-full cursor-pointer p-2">
          {data && data.delivery_date ? (
            <p className="text-gray-300">
              {format(new Date(data.delivery_date), "yyyy-MM-dd")}
            </p>
          ) : (
            <p className="text-gray-300">----/--/--</p>
          )}
          <MdCalendarMonth className="w-6 h-6" />
        </div>
      </div>
      <TextField
        fullWidth
        label="Metode Pengiriman"
        value={
          data && data.shipment_method && dataShipmentMethod
            ? dataShipmentMethod.find(
                (item) => item.id === data.shipment_method
              ).shipment_name
            : ""
        }
        disabled
        sx={{ marginBottom: 2 }}
      />
      <TextField
        fullWidth
        label="Petugas Pengiriman"
        value={data && data.carrier_name ? data.carrier_name : ""}
        disabled
      />
      <p className="mt-8 mb-2 text-red-500 text-right">{errorMessage}</p>
    </>
  );
};

export default DetailDeliveryOrder;
