import { Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import SingleCalendar from "../../common/SingleCalendar";
import { format } from "date-fns";
import { MdCalendarMonth } from "react-icons/md";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getDetailSupplier,
  updateSupplier,
} from "../../../services/supplierApi";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";

const validationSchema = yup.object({
  category: yup.string(),
  supplier_name: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  address_hq: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  address_manufacturer: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  phone: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  is_pkp: yup.boolean(),
  npwp: yup
    .string()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  top: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  lead_time: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  pic_name: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  pic_phone: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  pic_email: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  join_date: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
});

const DetailSupplier = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [paymentDate, setPaymentDate] = useState(null);

  const { data } = useQuery({
    queryFn: () => getDetailSupplier(id),
    queryKey: ["detail-supplier"],
  });

  const { mutate: mutateUpdateSupplier, isPending: isPendingEdit } =
    useMutation({
      mutationFn: updateSupplier,
      onSuccess: () => {
        navigate("/supplier");
      },
    });

  const formik = useFormik({
    initialValues: {
      id: id ? Number(id) : 0,
      category: data && data.category ? data.category : "",
      supplier_name: data && data.supplier_name ? data.supplier_name : "",
      address_hq: data && data.address_hq ? data.address_hq : "",
      address_manufacturer:
        data && data.address_manufacturer ? data.address_manufacturer : "",
      phone: data && data.phone ? data.phone : "",
      is_pkp: data && data.is_pkp !== undefined ? data.is_pkp : false,
      npwp: data && data.npwp ? data.npwp : "",
      top: data && data.top ? data.top : "",
      lead_time: data && data.lead_time ? data.lead_time : "",
      pic_name: data && data.pic_name ? data.pic_name : "",
      pic_phone: data && data.pic_phone ? data.pic_phone : "",
      pic_email: data && data.pic_email ? data.pic_email : "",
      join_date: data && data.join_date ? data.join_date : "",
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      mutateUpdateSupplier(values);
    },
  });

  useEffect(() => {
    if (data) {
      setPaymentDate(new Date(data.join_date));
    }
  }, [data]);

  useEffect(() => {
    if (!formik.values.is_pkp) formik.setFieldValue("npwp", "");
  }, [formik.values.is_pkp]);

  useEffect(() => {
    paymentDate
      ? formik.setFieldValue(
          "join_date",
          format(new Date(paymentDate), "yyyy-MM-dd")
        )
      : formik.setFieldValue("join_date", "");
  }, [paymentDate]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          label="Nama Supplier/Vendor"
          fullWidth
          sx={{ marginBottom: 2 }}
          value={formik.values.supplier_name}
          onChange={(e) =>
            formik.setFieldValue("supplier_name", e.target.value)
          }
          helperText={
            formik.touched.supplier_name && formik.errors.supplier_name
          }
          error={
            formik.touched.supplier_name && Boolean(formik.errors.supplier_name)
          }
        />
        <TextField
          label="Alamat Head Office"
          fullWidth
          sx={{ marginBottom: 2 }}
          value={formik.values.address_hq}
          onChange={(e) => {
            formik.setFieldValue("address_hq", e.target.value);
          }}
          helperText={formik.touched.address_hq && formik.errors.address_hq}
          error={formik.touched.address_hq && Boolean(formik.errors.address_hq)}
        />
        <TextField
          label="Alamat Manufaktur"
          fullWidth
          sx={{ marginBottom: 2 }}
          value={formik.values.address_manufacturer}
          onChange={(e) => {
            formik.setFieldValue("address_manufacturer", e.target.value);
          }}
          helperText={
            formik.touched.address_manufacturer &&
            formik.errors.address_manufacturer
          }
          error={
            formik.touched.address_manufacturer &&
            Boolean(formik.errors.address_manufacturer)
          }
        />{" "}
        <TextField
          label="No. Telpon"
          fullWidth
          sx={{ marginBottom: 2 }}
          value={formik.values.phone}
          onChange={(e) => {
            formik.setFieldValue("phone", e.target.value);
          }}
          helperText={formik.touched.phone && formik.errors.phone}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
        />{" "}
        <div className="w-full mb-4 flex items-center justify-between">
          <p className="basis">Status PKP</p>
          <div className="flex items-center">
            <Switch
              onChange={(e, checked) => formik.setFieldValue("is_pkp", checked)}
              checked={formik.values.is_pkp}
            />
            <p>{formik.values.is_pkp ? "Aktif" : "Tidak Aktif"}</p>
          </div>
        </div>
        {formik.values.is_pkp ? (
          <TextField
            label="NPWP"
            fullWidth
            sx={{ marginBottom: 2 }}
            value={formik.values.npwp}
            onChange={(e) => {
              formik.setFieldValue("npwp", e.target.value);
            }}
            helperText={formik.touched.npwp && formik.errors.npwp}
            error={formik.touched.npwp && Boolean(formik.errors.npwp)}
          />
        ) : null}
        <TextField
          label="Terms of Payment"
          fullWidth
          sx={{ marginBottom: 2 }}
          onChange={(e) => {
            formik.setFieldValue("top", e.target.value);
          }}
          value={formik.values.top}
          helperText={formik.touched.top && formik.errors.top}
          error={formik.touched.top && Boolean(formik.errors.top)}
        />{" "}
        <TextField
          label="Lead Time"
          fullWidth
          sx={{ marginBottom: 2 }}
          value={formik.values.lead_time}
          onChange={(e) => {
            formik.setFieldValue("lead_time", e.target.value);
          }}
          helperText={formik.touched.lead_time && formik.errors.lead_time}
          error={formik.touched.lead_time && Boolean(formik.errors.lead_time)}
        />
        <TextField
          label="Nama PIC"
          fullWidth
          sx={{ marginBottom: 2 }}
          value={formik.values.pic_name}
          onChange={(e) => {
            formik.setFieldValue("pic_name", e.target.value);
          }}
          helperText={formik.touched.pic_name && formik.errors.pic_name}
          error={formik.touched.pic_name && Boolean(formik.errors.pic_name)}
        />
        <TextField
          label="No. Telpon PIC"
          fullWidth
          sx={{ marginBottom: 2 }}
          value={formik.values.pic_phone}
          onChange={(e) => {
            formik.setFieldValue("pic_phone", e.target.value);
          }}
          helperText={formik.touched.pic_phone && formik.errors.pic_phone}
          error={formik.touched.pic_phone && Boolean(formik.errors.pic_phone)}
        />
        <TextField
          label="Email PIC"
          fullWidth
          sx={{ marginBottom: 2 }}
          value={formik.values.pic_email}
          onChange={(e) => {
            formik.setFieldValue("pic_email", e.target.value);
          }}
          helperText={formik.touched.pic_email && formik.errors.pic_email}
          error={formik.touched.pic_email && Boolean(formik.errors.pic_email)}
        />
        <div className=" w-full border-2 rounded-md w-full flex items-center hover:bg-slate-100 mb-4">
          <div
            className="flex justify-between items-center h-full cursor-pointer w-full cursor-pointer p-2"
            onClick={(e) => setAnchorElCalendar(e.currentTarget)}
          >
            {paymentDate === null ? (
              <p className="text-gray-300">----/--/--</p>
            ) : (
              <p className="text-gray-300">
                {format(new Date(paymentDate), "yyyy-MM-dd")}
              </p>
            )}
            <MdCalendarMonth className="w-6 h-6" />
          </div>
        </div>
        <div className="block overflow-x-auto mb-4">
          <table className="w-full">
            <thead>
              <th className="p-3 border-t border-b border-2">No</th>
              <th className="p-3 border-t border-b border-2">Kode Material</th>
              <th className="p-3 border-t border-b border-2">Nama Material</th>
              <th className="p-3 border-t border-b border-2">Tanggal Masuk</th>
              <th className="p-3 border-t border-b border-2">Length</th>
              <th className="p-3 border-t border-b border-2">Width</th>
              <th className="p-3 border-t border-b border-2">Lot Number</th>
              <th className="p-3 border-t border-b border-2">Kategori</th>
              <th className="p-3 border-t border-b border-2">UOM</th>
              <th className="p-3 border-t border-b border-2">Stok</th>
              <th className="p-3 border-t border-b border-2">Harga Jual</th>
            </thead>
            <tbody>
              {data && data.materials.length > 0 ? (
                data.materials.map((item, i) => (
                  <tr>
                    <td className="p-3 border-t border-b border-2">{i + 1}</td>
                    <td className="p-3 border-t border-b border-2">
                      {item.material_id}
                    </td>
                    <td className="p-3 border-t border-b border-2">
                      {item.name}
                    </td>
                    <td className="p-3 border-t border-b border-2">
                      {moment(item.incoming_date).format("YYYY-MM-DD")}
                    </td>
                    <td className="p-3 border-t border-b border-2">
                      {item.length}
                    </td>
                    <td className="p-3 border-t border-b border-2">
                      {item.width}
                    </td>
                    <td className="p-3 border-t border-b border-2">
                      {item.lot_number}
                    </td>
                    <td className="p-3 border-t border-b border-2">
                      {item.category}
                    </td>
                    <td className="p-3 border-t border-b border-2">
                      {item.uom}
                    </td>
                    <td className="p-3 border-t border-b border-2">
                      {item.stock}
                    </td>
                    <td className="p-3 border-t border-b border-2">
                      Rp{item.sell_price.toLocaleString().replaceAll(",", ".")}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    className="p-3 border-t border-b border-2 text-center"
                    colSpan={11}
                  >
                    Tidak ada material
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex gap-2 items-center mb-4 mx-4">
          <button
            className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
            onClick={() => navigate("/supplier")}
            type="button"
          >
            Cancel
          </button>
          <button
            className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
            type="submit"
            disabled={!formik.isValid || isPendingEdit}
          >
            Save
          </button>
        </div>
      </form>
      <SingleCalendar
        anchorElCalendarStart={anchorElCalendar}
        setAnchorElCalendarStart={setAnchorElCalendar}
        startDate={paymentDate}
        setStartDate={setPaymentDate}
      />
    </div>
  );
};

export default DetailSupplier;
