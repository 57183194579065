import axios from "axios";
import Cookies from "js-cookie";

const token = () => Cookies.get("accessToken");

export const getFinihsGoodList = async ({
  limit,
  offset,
  search,
  inStart,
  inEnd,
  outStart,
  outEnd,
}) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/list-stock-on-hand?limit=${limit}&offset=${offset}&search=${search}&date_in_start=${inStart}&date_in_end=${inEnd}&date_out_start=${outStart}&date_out_end=${outEnd}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getDetailFinishgood = async (id, limit, offset) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/detail-finishgood?id=${id}&limit=${limit}&offset=${offset}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};