import {
  Autocomplete,
  CircularProgress,
  FormControl,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Popover,
  Select,
  TextField,
} from "@mui/material";
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Search } from "@mui/icons-material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import RangeCalendar from "../../common/RangeCalendar";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { IoChevronBack, IoFilter } from "react-icons/io5";
import * as XLSX from "xlsx";
import moment from "moment";
import { getCustomerList } from "../../../services/customerManagementApi";
import { getSoList, setSoStatus } from "../../../services/salesOrderApi";
import { getShipmentMethod } from "../../../services/deliveryOrder";
import ModalInput from "../../common/ModalInput";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const SalesOrderList = () => {
  const navigate = useNavigate();

  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [selectedDates, setSelectedDates] = useState(["", ""]);
  const [searchStartDate, setSearchStartDate] = useState(null);
  const [searchEndDate, setSearchEndDate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [typeDate, setTypeDate] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [openStatus, setOpenStatus] = useState(false);
  const [statusReq, setStatusReq] = useState(null);

  const formik = useFormik({
    initialValues: {
      search: "",
      status: 0,
      so_start: "",
      so_end: "",
      customer: 0,
      shipping: 0,
      po_start: "",
      po_end: "",
      delivery_start: "",
      delivery_end: "",
      due_start: "",
      due_end: "",
      offset: 1,
      limit: 10,
      is_dropdown: false,
    },
  });

  const open = Boolean(anchorEl);

  const findShipmentMethodName = (id) => {
    return dataShipmentMethod.find((elem) => elem.id === id).shipment_name;
  };

  const handleSelectDate = (e, type) => {
    // set range based on type and formik
    setSearchStartDate(null);
    setSearchEndDate(null);
    setTypeDate(type);
    setAnchorElCalendar(e.currentTarget);
  };

  const handleExport = () => {
    mutateExportList(formik.values);
  };

  const exportXlsx = (dataExport) => {
    const rows = dataExport.data.map((row, i) => ({
      no: i + 1,
      sales: row.user_name,
      quotation_number: row.quotation_number,
      so_number: row.so_number,
      status_progress: row.status_progress,
      spk_no: row.spk_no,
      customer_name: row.customer_name,
      shipping_address: row.shipping_address,
      shipping: findShipmentMethodName(row.shipping),
      po_number: row.po_number,
      po_date: format(new Date(row.po_date), "dd-MM-yyyy"),
      due_date: format(new Date(row.due_date), "dd-MM-yyyy"),
      delivery_date: format(new Date(row.delivery_date), "dd-MM-yyyy"),
      label: row.label_name ? row.label_name.join(", ") : "-",
      code: row.internal_code ? row.internal_code.join(", ") : "-",
      size: row.size ? row.size.join(", ") : "-",
      material: row.material ? row.material.join(", ") : "-",
      qty: row.quantity,
      qty_do: row.quantity_do,
      outstanding: row.outstanding,
      created_by: row.created_by,
      status_order:
        row.status_order === 1
          ? "New"
          : row.status_order === 2
          ? "Repeat"
          : row.status_order === 3
          ? "Sample Request"
          : "RWC",
      status_so:
        row.status_so === 1
          ? "Develop"
          : row.status_so === 2
          ? "Hold"
          : "Cancel",
    }));

    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "Daftar SO " + moment().format("DD-MM-YYYY")
    );

    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          "No",
          "Nama Sales",
          "No. Quotation",
          "No. SO",
          "Status Progress",
          "No. SPK",
          "Nama Customer",
          "Alamat Pengiriman",
          "Metode Pengiriman",
          "No. PO",
          "Tanggal PO",
          "Tanggal Jatuh Tempo",
          "Tanggal Pengiriman",
          "Label",
          "Code",
          "Size",
          "Material",
          "Qty Order",
          "Qty Terkirim",
          "Outstanding",
          "Created By",
          "Status SO",
          "Status",
        ],
      ],
      {
        origin: "A1",
      }
    );

    const max_width = rows.reduce((w, r) => Math.max(w, r.no.length), 10);
    worksheet["!cols"] = [{ wch: max_width }];

    XLSX.writeFile(
      workbook,
      "Daftar SO " + moment().format("DD_MM_YYYY") + ".xlsx",
      { compression: true }
    );
  };

  useEffect(() => {
    if (typeDate !== "") {
      if (typeDate === "so") {
        formik.setFieldValue("so_start", selectedDates[0]);
        formik.setFieldValue("so_end", selectedDates[1]);
      }
      if (typeDate === "po") {
        formik.setFieldValue("po_start", selectedDates[0]);
        formik.setFieldValue("po_end", selectedDates[1]);
      }
      if (typeDate === "due") {
        formik.setFieldValue("due_start", selectedDates[0]);
        formik.setFieldValue("due_end", selectedDates[1]);
      }
      if (typeDate === "delivery") {
        formik.setFieldValue("delivery_start", selectedDates[0]);
        formik.setFieldValue("delivery_end", selectedDates[1]);
      }
    }
  }, [selectedDates]);

  useEffect(() => {
    if (!anchorElCalendar) setTypeDate("");
  }, [anchorElCalendar]);

  const {
    mutate: mutateSoList,
    isPending,
    data,
  } = useMutation({
    mutationFn: getSoList,
  });

  const { mutate: mutateExportList, isPending: isPendingExport } = useMutation({
    mutationFn: getSoList,
    onSuccess: (data) => exportXlsx(data),
  });

  const { mutate: mutateStatusSo } = useMutation({
    mutationFn: setSoStatus,
    onSuccess: () => {
      setOpenStatus(false);
      mutateSoList(formik.values);
    },
  });

  const { mutate: mutateCustomerList, data: dataCust } = useMutation({
    mutationFn: getCustomerList,
  });

  const { data: dataShipmentMethod } = useQuery({
    queryKey: ["shipment-method-list"],
    queryFn: () => getShipmentMethod(),
  });

  useEffect(() => {
    mutateSoList(formik.values);
  }, [formik.values]);

  useEffect(() => {
    formik.setFieldValue(
      "customer",
      selectedCustomer ? selectedCustomer.id : 0
    );
  }, [selectedCustomer]);

  useEffect(() => {
    formik.setFieldValue("offset", 1);
  }, [formik.values.limit, formik.values.search, formik.values.status]);

  useEffect(() => {
    const body = {
      name: "",
      start_date: "",
      end_date: "",
      pkp: 0,
      offset: 1,
      limit: 10000,
    };
    mutateCustomerList(body);
  }, []);

  return (
    <div>
      <div
        className={
          "fixed top-0 right-0 z-50 bg-white w-[100%] lg:w-[calc(100%-310px)] p-3 mt-4 lg:mt-0"
        }
      >
        <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
          Sales Order
        </h1>
        <div className="flex mb-4 items-stretch gap-2">
          <TextField
            fullWidth
            placeholder="Search by Nama Sales, No. Quotation, No. SO"
            value={formik.values.search}
            onChange={(e) => formik.setFieldValue("search", e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <FormControl fullWidth>
            <Select
              value={formik.values.status}
              onChange={(e) => formik.setFieldValue("status", e.target.value)}
            >
              <MenuItem value={0}>Semua Status SO</MenuItem>
              <MenuItem value={1}>New</MenuItem>
              <MenuItem value={2}>Repeat</MenuItem>
              <MenuItem value={3}>Sample Order</MenuItem>
              <MenuItem value={4}>RWC</MenuItem>
              <MenuItem value={5}>New + CRB</MenuItem>
              <MenuItem value={6}>RWC + CRB</MenuItem>
              <MenuItem value={7}>Reprint</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <Select
              value={formik.values.shipping}
              onChange={(e) => formik.setFieldValue("shipping", e.target.value)}
            >
              <MenuItem value={0}>Semua Metode Pengiriman</MenuItem>
              {dataShipmentMethod
                ? dataShipmentMethod.map((item) => {
                    return (
                      <MenuItem value={item.id}>{item.shipment_name}</MenuItem>
                    );
                  })
                : null}
            </Select>
          </FormControl>
          <Autocomplete
            sx={{ width: "100%" }}
            disablePortal
            value={selectedCustomer}
            onChange={(_, option) => {
              setSelectedCustomer(option);
            }}
            options={
              dataCust && dataCust.data
                ? [{ id: 0, customer_name: "Semua Customer" }, ...dataCust.data]
                : [{ id: 0, customer_name: "Semua Customer" }]
            }
            getOptionLabel={(option) => option.customer_name}
            renderInput={(params) => <TextField {...params} label="Customer" />}
            onInputChange={(event, newInputValue, reason) => {
              if (reason === "reset") {
                setSelectedCustomer(null);
                return;
              }
            }}
          />
          <div
            className="p-2 w-16 border-2 rounded-md flex items-center justify-center cursor-pointer hover:bg-slate-100"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <IoFilter />
          </div>
          <button
            onClick={() => navigate("/sales-order/create-so")}
            className="w-1/4 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
          >
            <IoMdAddCircleOutline className="text-[18px]" />
          </button>
        </div>
        <div className="w-full flex align-center items-center justify-between">
          <div className="flex gap-2">
            <p>Summary Ammount:</p>
            {data && !isPending ? (
              <p className="font-bold">
                Rp{data?.amount_summary.toLocaleString()}
              </p>
            ) : (
              <p className="font-bold">Rpxxx</p>
            )}
          </div>
          <button
            className="rounded-md p-2 px-8 border-2 float-right cursor-pointer hover:bg-slate-100 disabled:bg-gray-300 disabled:text-white disabled:cursor-default"
            disabled={isPendingExport}
            onClick={handleExport}
          >
            {isPendingExport ? <CircularProgress size={20} /> : "Export SO"}
          </button>
        </div>
      </div>
      <>
        {" "}
        <div className="rounded-md shadow-md mt-48">
          <Paper className="mb-8" sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 560 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="p-3 font-bold text-center bg-[#F4F5FF]"
                      sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                    >
                      No
                    </TableCell>
                    <TableCell
                      className="p-3 font-bold text-center bg-[#F4F5FF]"
                      sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                    >
                      Sales
                    </TableCell>
                    <TableCell
                      className="p-3 font-bold text-center bg-[#F4F5FF]"
                      sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                    >
                      No. Quotation
                    </TableCell>
                    <TableCell
                      className="p-3 font-bold text-center bg-[#F4F5FF]"
                      sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                    >
                      No. SO
                    </TableCell>
                    <TableCell
                      className="p-3 font-bold text-center bg-[#F4F5FF]"
                      sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                    >
                      Progress Status
                    </TableCell>
                    <TableCell
                      className="p-3 font-bold text-center bg-[#F4F5FF]"
                      sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                    >
                      No. SPK
                    </TableCell>
                    <TableCell
                      className="p-3 font-bold text-center bg-[#F4F5FF]"
                      sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                    >
                      Customer
                    </TableCell>
                    <TableCell
                      className="p-3 font-bold text-center bg-[#F4F5FF] border-r border-l"
                      sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                      style={{
                        position: "sticky",
                        left: 0,
                        backgroundColor: "#F4F5FF",
                        zIndex: 10,
                      }}
                    >
                      No. PO
                    </TableCell>
                    <TableCell
                      className="p-3 font-bold text-center bg-[#F4F5FF]"
                      sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                    >
                      Tanggal PO
                    </TableCell>
                    <TableCell
                      className="p-3 font-bold text-center bg-[#F4F5FF]"
                      sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                    >
                      Tanggal Jatuh Tempo
                    </TableCell>
                    <TableCell
                      className="p-3 font-bold text-center bg-[#F4F5FF]"
                      sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                    >
                      Tanggal Pengiriman
                    </TableCell>
                    <TableCell
                      className="p-3 font-bold text-center bg-[#F4F5FF]"
                      sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                    >
                      Label
                    </TableCell>
                    <TableCell
                      className="p-3 font-bold text-center bg-[#F4F5FF]"
                      sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                    >
                      Code
                    </TableCell>
                    <TableCell
                      className="p-3 font-bold text-center bg-[#F4F5FF]"
                      sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                    >
                      Qty Order
                    </TableCell>
                    <TableCell
                      className="p-3 font-bold text-center bg-[#F4F5FF]"
                      sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                    >
                      Qty Terkirim
                    </TableCell>
                    <TableCell
                      className="p-3 font-bold text-center bg-[#F4F5FF]"
                      sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                    >
                      Harga Satuan
                    </TableCell>
                    <TableCell
                      className="p-3 font-bold text-center bg-[#F4F5FF]"
                      sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                    >
                      Harga Total
                    </TableCell>
                    <TableCell
                      className="p-3 font-bold text-center bg-[#F4F5FF]"
                      sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                    >
                      Outstanding
                    </TableCell>
                    <TableCell
                      className="p-3 font-bold text-center bg-[#F4F5FF]"
                      sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                    >
                      Created By
                    </TableCell>
                    <TableCell
                      className="p-3 font-bold text-center bg-[#F4F5FF]"
                      sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                    >
                      Status SO
                    </TableCell>
                    <TableCell
                      className="p-3 font-bold text-center bg-[#F4F5FF]"
                      sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data && data.data && data.data.length > 0 && !isPending ? (
                    data.data.map((item, i) => {
                      return (
                        <TableRow
                          className="border-t border-l border-2"
                          key={i}
                        >
                          <TableCell
                            className="p-3"
                            sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                          >
                            {(formik.values.offset - 1) * formik.values.limit +
                              (i + 1)}
                          </TableCell>
                          <TableCell
                            className="p-3"
                            sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                          >
                            {item.user_name}
                          </TableCell>
                          <TableCell
                            className="p-3"
                            sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                          >
                            {item.quotation_number}
                          </TableCell>
                          <TableCell
                            className="p-3 text-blue-500 cursor-pointer hover:text-blue-700"
                            sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                            onClick={() =>
                              navigate(`/sales-order/detail/${item.id}`)
                            }
                          >
                            {item.so_number}
                          </TableCell>
                          <TableCell
                            className="p-3"
                            sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                          >
                            {item.status_progress === 1
                              ? "SO Telah Dibuat"
                              : item.status_progress === 2
                              ? "Sudah Di SPK"
                              : item.status_progress === 3
                              ? "On Progress"
                              : "Selesai"}
                          </TableCell>
                          <TableCell
                            className="p-3 text-nowrap"
                            sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                          >
                            {item.spk_no && item.spk_no !== ""
                              ? item.spk_no
                              : "-"}
                          </TableCell>
                          <TableCell
                            className="p-3"
                            sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                          >
                            {item.customer_name}
                          </TableCell>
                          <TableCell
                            className="p-3 border-r border-l"
                            sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                            style={{
                              position: "sticky",
                              left: 0,
                              backgroundColor: "white",
                            }}
                          >
                            {item.po_number}
                          </TableCell>
                          <TableCell
                            className="p-3 text-nowrap"
                            sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                          >
                            {format(new Date(item.po_date), "dd-MM-yyyy")}
                          </TableCell>
                          <TableCell
                            className="p-3 text-nowrap"
                            sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                          >
                            {format(new Date(item.due_date), "dd-MM-yyyy")}
                          </TableCell>
                          <TableCell
                            className="p-3 text-nowrap"
                            sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                          >
                            {format(new Date(item.delivery_date), "dd-MM-yyyy")}
                          </TableCell>
                          <TableCell
                            className="p-3"
                            sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                          >
                            {item.label_name ? item.label_name.join(", ") : "-"}
                          </TableCell>
                          <TableCell
                            className="p-3"
                            sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                          >
                            {item.internal_code
                              ? item.internal_code.join(", ")
                              : "-"}
                          </TableCell>
                          <TableCell
                            className="p-3"
                            sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                          >
                            {item.quantity}
                          </TableCell>
                          <TableCell
                            className="p-3"
                            sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                          >
                            {item.quantity_do}
                          </TableCell>
                          <TableCell
                            className="p-3"
                            sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                          >
                            {item.unit_price}
                          </TableCell>
                          <TableCell
                            className="p-3"
                            sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                          >
                            {item.total_price}
                          </TableCell>
                          <TableCell
                            className="p-3"
                            sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                          >
                            {item.outstanding}
                          </TableCell>
                          <TableCell
                            className="p-3"
                            sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                          >
                            {item.created_by}
                          </TableCell>
                          <TableCell
                            className="p-3"
                            sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                          >
                            {item.status_order === 1
                              ? "New"
                              : item.status_order === 2
                              ? "Repeat"
                              : item.status_order === 3
                              ? "Sample Request"
                              : "RWC"}
                          </TableCell>
                          <TableCell
                            className="p-3"
                            sx={{ fontFamily: "Montserrat", fontSize: "16px" }}
                          >
                            <Select
                              fullWidth
                              value={item.status_so}
                              onChange={(e) => {
                                // handleChangeStatus(item.id, e.target.value)
                                setStatusReq({
                                  id: item.id,
                                  status_id: e.target.value,
                                });
                                setOpenStatus(true);
                              }}
                            >
                              <MenuItem value={1}>Develop</MenuItem>
                              <MenuItem value={2}>Hold</MenuItem>
                              <MenuItem value={3}>Cancel</MenuItem>
                            </Select>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : !isPending ? (
                    <tr className="border-t border-l border-2">
                      <td colSpan={23} className="py-3 text-center">
                        Data sales order tidak ditemukan
                      </td>
                    </tr>
                  ) : (
                    <tr className="border-t border-l border-2">
                      <td colSpan={23} className="py-3 text-center">
                        <CircularProgress size={20} />
                      </td>
                    </tr>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          {data && data.count > 0 ? (
            <div className="w-full flex justify-between pb-8 px-8">
              <button
                onClick={() => {
                  if (formik.values.offset > 1)
                    formik.setFieldValue("offset", formik.values.offset - 1);
                }}
                disabled={formik.values.offset === 1}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowLeftLong /> Previous
              </button>
              <Pagination
                hideNextButton
                hidePrevButton
                shape="rounded"
                page={formik.values.offset}
                count={Math.ceil(data.count / formik.values.limit)}
                onChange={(e, page) => formik.setFieldValue("offset", page)}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
              <button
                onClick={() => {
                  if (
                    formik.values.offset <
                    Math.ceil(data.count / formik.values.limit)
                  )
                    formik.setFieldValue("offset", formik.values.offset + 1);
                }}
                disabled={
                  formik.values.offset ===
                  Math.ceil(data.count / formik.values.limit)
                }
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowRightLong /> Next
              </button>
            </div>
          ) : null}
        </div>
        {data && data.count > 0 ? (
          <div className="mt-4 text-xs flex gap-3 items-center">
            <p>Show Table</p>
            <p
              onClick={() => formik.setFieldValue("limit", 10)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                formik.values.limit === 10
                  ? "border rounded-md bg-slate-100"
                  : ""
              }`}
            >
              10
            </p>
            <p
              onClick={() => formik.setFieldValue("limit", 50)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                formik.values.limit === 50
                  ? "border rounded-md bg-slate-100"
                  : ""
              }`}
            >
              50
            </p>
            <p
              onClick={() => formik.setFieldValue("limit", 100)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                formik.values.limit === 100
                  ? "border rounded-md bg-slate-100"
                  : ""
              }`}
            >
              100
            </p>
          </div>
        ) : null}
      </>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="">
          <div
            className={`p-3 border-b-2 hover:bg-slate-100 cursor-pointer flex justify-between items-center ${
              formik.values.so_start !== "" && formik.values.so_end !== ""
                ? "bg-slate-100"
                : ""
            }`}
            onClick={(e) => handleSelectDate(e, "so")}
          >
            <IoChevronBack />
            Tanggal SO
          </div>
          <div
            className={`p-3 border-b-2 hover:bg-slate-100 cursor-pointer flex justify-between items-center ${
              formik.values.po_start !== "" && formik.values.po_end !== ""
                ? "bg-slate-100"
                : ""
            }`}
            onClick={(e) => handleSelectDate(e, "po")}
          >
            <IoChevronBack />
            Tanggal PO
          </div>
          <div
            className={`p-3 border-b-2 hover:bg-slate-100 cursor-pointer flex justify-between items-center ${
              formik.values.due_start !== "" && formik.values.due_end !== ""
                ? "bg-slate-100"
                : ""
            }`}
            onClick={(e) => handleSelectDate(e, "due")}
          >
            <IoChevronBack />
            Tanggal Jatuh Tempo
          </div>
          <div
            className={`p-3 border-b-2 hover:bg-slate-100 cursor-pointer flex justify-between items-center ${
              formik.values.delivery_start !== "" &&
              formik.values.delivery_end !== ""
                ? "bg-slate-100"
                : ""
            }`}
            onClick={(e) => handleSelectDate(e, "delivery")}
          >
            <IoChevronBack />
            Tanggal Delivery
          </div>
        </div>
      </Popover>

      <RangeCalendar
        anchorElCalendar={anchorElCalendar}
        setAnchorElCalendar={setAnchorElCalendar}
        setSelectedDates={setSelectedDates}
        searchStartDate={searchStartDate}
        searchEndDate={searchEndDate}
        setSearchStartDate={setSearchStartDate}
        setSearchEndDate={setSearchEndDate}
        toSide={true}
      />

      <ModalInput
        title="Update Status SO?"
        subtitle={`Apakah anda yakin ingin mengubah status SO menjadi ${
          statusReq
            ? statusReq.status_id === 1
              ? "Develop"
              : statusReq.status_id === 2
              ? "Hold"
              : "Cancel"
            : ""
        }?`}
        content={<></>}
        open={openStatus}
        setOpen={setOpenStatus}
        buttonText="Yes"
        hasButton={true}
        setTriggerFunc={() => mutateStatusSo(statusReq)}
        isDisable={false}
      />
    </div>
  );
};

export default SalesOrderList;
