import { FormGroup, FormLabel, InputAdornment, TextField } from "@mui/material";
import { useFormik } from "formik";
import { IoMdAddCircleOutline } from "react-icons/io";
import ModalInput from "../../common/ModalInput";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getMaterialList } from "../../../services/materialApi";
import { FaCirclePlus, FaTrash } from "react-icons/fa6";
import { FaMinusCircle } from "react-icons/fa";
import { Search } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { getDetailCosting, setCosting } from "../../../services/costingApi";

const validationSchema = yup.object({
  id: yup.number().min(1),
  label_name: yup.string().required(),
  length: yup.number().min(1),
  width: yup.number().min(1),
  material: yup.array().min(1),
  total_price: yup.number().min(1),
});

const DetailCosting = ({ isEdit }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [openProduct, setOpenProduct] = useState(false);
  const [search, setSearch] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const calculateTotalPrice = () => {
    formik.setFieldValue(
      "total_price",
      formik.values.material.reduce((acc, num) => acc + num.price * num.qty, 0)
    );
  };

  const handleMaterialChange = (material, type) => {
    const indexMaterial = formik.values.material.findIndex(
      (item) => item.material_id === material.id
    );

    if (type === "increase") {
      if (indexMaterial >= 0) {
        formik.setFieldValue("material[" + indexMaterial + "]", {
          id: formik.values.material[indexMaterial].id,
          is_delete: false,
          material_id: formik.values.material[indexMaterial].material_id,
          material_name: formik.values.material[indexMaterial].material_name,
          qty: formik.values.material[indexMaterial].qty + 1,
          price: formik.values.material[indexMaterial].price,
        });
      } else {
        formik.setFieldValue("material", [
          ...formik.values.material,
          {
            id: 0,
            is_delete: false,
            material_id: material.id,
            material_name: material.name,
            qty: 1,
            price: material.sell_price,
          },
        ]);
      }
    }

    if (type === "decrease") {
      if (indexMaterial >= 0 && formik.values.material[indexMaterial].qty > 0)
        formik.values.material[indexMaterial].qty - 1 === 0
          ? handleDeleteMaterial(indexMaterial)
          : formik.setFieldValue("material[" + indexMaterial + "]", {
              id: formik.values.material[indexMaterial].id,
              material_id: formik.values.material[indexMaterial].material_id,
              material_name:
                formik.values.material[indexMaterial].material_name,
              qty: formik.values.material[indexMaterial].qty - 1,
              price: formik.values.material[indexMaterial].price,
            });
    }
  };

  const handleDeleteMaterial = (i) => {
    formik.values.material[i].id > 0
      ? formik.setFieldValue("material", [
          ...formik.values.material.slice(0, i),
          {
            ...formik.values.material[i],
            is_delete: true,
            qty: 0,
          },
          ...formik.values.material.slice(i + 1),
        ])
      : formik.setFieldValue("material", [
          ...formik.values.material.slice(0, i),
          ...formik.values.material.slice(i + 1),
        ]);
  };

  const { data, isFetching } = useQuery({
    queryKey: ["material-list", search],
    queryFn: () =>
      getMaterialList({
        search: search,
        filter: "",
        offset: 1,
        limit: 10000,
      }),
  });

  const { data: dataCosting, isFetching: isFetchingCosting } = useQuery({
    queryKey: ["detail-costing", id],
    queryFn: () => getDetailCosting(id),
  });

  const formik = useFormik({
    initialValues: {
      id: Number(id),
      label_name:
        dataCosting && dataCosting.label_name ? dataCosting.label_name : "",
      qty: dataCosting && dataCosting.qty ? dataCosting.qty : 0,
      length: dataCosting && dataCosting.length ? dataCosting.length : 0,
      width: dataCosting && dataCosting.width ? dataCosting.width : 0,
      material: dataCosting && dataCosting.material ? dataCosting.material : [],
      total_price:
        dataCosting && dataCosting.total_price ? dataCosting.total_price : 0,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      mutateCosting(values);
    },
  });

  const { mutate: mutateCosting } = useMutation({
    mutationFn: setCosting,
    onSuccess: () => navigate("/costing"),
    onError: (err) => setErrorMessage(err.message.id),
  });

  useEffect(() => {
    calculateTotalPrice();
  }, [formik.values.material]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FormGroup sx={{ marginBottom: 2 }}>
          <FormLabel>Nama Label</FormLabel>
          <TextField
            disabled={!isEdit}
            fullWidth
            value={formik.values.label_name}
            onChange={(e) => formik.setFieldValue("label_name", e.target.value)}
          />
        </FormGroup>
        <FormGroup sx={{ marginBottom: 2 }}>
          <FormLabel>Quantity</FormLabel>
          <TextField
            fullWidth
            value={formik.values.qty}
            disabled={!isEdit}
            onChange={(e) => {
              if (!isNaN(Number(e.target.value)))
                formik.setFieldValue("qty", Number(e.target.value));
            }}
          />
        </FormGroup>
        <div className="mb-4 flex gap-4 items-center">
          <FormGroup sx={{ marginBottom: 2, width: "100%" }}>
            <FormLabel>Length</FormLabel>
            <TextField
              disabled={!isEdit}
              fullWidth
              value={formik.values.length}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue("length", Number(e.target.value));
              }}
            />
          </FormGroup>
          <FormGroup sx={{ marginBottom: 2, width: "100%" }}>
            <FormLabel>Width</FormLabel>
            <TextField
              disabled={!isEdit}
              fullWidth
              value={formik.values.width}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue("width", Number(e.target.value));
              }}
            />
          </FormGroup>
        </div>
        <div className="flex justify-between items-center mb-4">
          <p className="font-bold text-xl">Materials</p>
          <button
            type="button"
            onClick={() => setOpenProduct(true)}
            disabled={!isEdit}
            className="px-8 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer disabled:bg-gray-300 disabled:cursor-default"
          >
            <p>Add Material</p>
            <IoMdAddCircleOutline className="text-[18px]" />
          </button>
        </div>
        <div className="rounded-md shadow-md">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <th className="p-3 text-center">No</th>
                <th className="p-3 text-center">Nama Material</th>
                <th className="p-3 text-center">Qty</th>
                <th className="p-3 text-center">Price</th>
              </thead>
              <tbody>
                {formik.values.material.filter(
                  (item) =>
                    item.is_delete === undefined || item.is_delete === false
                ).length === 0 ? (
                  <td className="text-center p-3 text-gray-300" colSpan={4}>
                    Tidak ada material
                  </td>
                ) : (
                  formik.values.material.map((item, i) => {
                    return !item.is_delete ? (
                      <tr key={i}>
                        <td className="p-3 text-center">{i + 1}</td>
                        <td className="p-3 text-center">
                          {item.material_name}
                        </td>
                        <td className="p-3 text-center">{item.qty}</td>
                        <td className="p-3 text-center flex items-center gap-4">
                          <TextField
                            disabled={!isEdit}
                            fullWidth
                            value={item.price}
                            onChange={(e) => {
                              if (!isNaN(Number(e.target.value))) {
                                calculateTotalPrice();
                                formik.setFieldValue(
                                  "material[" + i + "].price",
                                  Number(e.target.value)
                                );
                              }
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  Rp
                                </InputAdornment>
                              ),
                            }}
                          />
                          {isEdit ? (
                            <FaTrash
                              onClick={() => handleDeleteMaterial(i)}
                              className="text-red-500 cursor-pointer hover:text-gray-300"
                            />
                          ) : null}
                        </td>
                      </tr>
                    ) : (
                      <></>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex mb-8 justify-end">
          <div className="basis-1/3 flex">
            <p className="w-full font-bold">Grand Total</p>
            <p className="w-fit mr-2">:</p>
            <p className="w-full">
              Rp
              {formik.values.material
                .reduce((acc, num) => acc + num.price * num.qty, 0)
                .toLocaleString()}
            </p>
          </div>
        </div>
        {isEdit ? (
          <div className="w-full">
            <p className="text-red-500 mb-4 text-right m-4">{errorMessage}</p>
            <div className="flex justify-end w-1/3 gap-2 float-right">
              <button
                type="button"
                className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
                onClick={() => navigate("/costing")}
              >
                Cancel
              </button>
              <button
                className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
                disabled={
                  !formik.isValid ||
                  !formik.values.material.every((item) => item.price > 0)
                  // || isPending
                }
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        ) : null}
      </form>

      <ModalInput
        title="Add Product"
        subtitle=""
        content={
          <>
            <TextField
              fullWidth
              placeholder="Search material"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <hr className="my-4" />
            <div className="rounded-md shadow-md h-[45vh]">
              <div className="block overflow-x-auto h-full overflow-y-scroll w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
                <table className="w-full">
                  <thead className="bg-[#F4F5FF] border-t border-b border-2">
                    <th className="p-3 text-left">No</th>
                    <th className="p-3 text-left">Nama Material</th>
                    <th className="p-3 text-center">Qty</th>
                  </thead>
                  <tbody>
                    {data &&
                    data.materials &&
                    data.materials.length > 0 &&
                    !isFetching ? (
                      data.materials.map((item, i) => (
                        <tr>
                          <td className="p-3">{i + 1}</td>
                          <td className="p-3">{item.name}</td>
                          <td className="p-3">
                            <div className="flex items-center gap-2 justify-center">
                              <button
                                type="button"
                                disabled={
                                  formik.values.material.findIndex(
                                    (mat) => mat.material_id === item.id
                                  ) < 0 ||
                                  formik.values.material[
                                    formik.values.material.findIndex(
                                      (mat) => mat.material_id === item.id
                                    )
                                  ].qty === 0
                                }
                                className="text-red-500 hover:text-red-300 disabled:text-gray-300"
                                onClick={() =>
                                  handleMaterialChange(item, "decrease")
                                }
                              >
                                <FaMinusCircle className="w-[24px] h-full" />
                              </button>
                              <p>
                                {formik.values.material.findIndex(
                                  (mat) => mat.material_id === item.id
                                ) >= 0
                                  ? formik.values.material[
                                      formik.values.material.findIndex(
                                        (mat) => mat.material_id === item.id
                                      )
                                    ].qty
                                  : 0}
                              </p>
                              <button
                                type="button"
                                onClick={() =>
                                  handleMaterialChange(item, "increase")
                                }
                                disabled={
                                  formik.values.material.findIndex(
                                    (mat) => mat.material_id === item.id
                                  ) >= 0 &&
                                  formik.values.material[
                                    formik.values.material.findIndex(
                                      (mat) => mat.material_id === item.id
                                    )
                                  ].qty === item.stock
                                }
                                className="hover:text-blue-300 text-blue-500 disabled:text-gray-300"
                              >
                                <FaCirclePlus className="w-[24px] h-full" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="p-3 text-center" colSpan={3}>
                          Belum ada material
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        }
        open={openProduct}
        setOpen={setOpenProduct}
        hasButton={true}
        buttonText="Submit"
        setTriggerFunc={() => {
          setOpenProduct(false);
        }}
        isDisable={false}
      />
    </>
  );
};

export default DetailCosting;
