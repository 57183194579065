const SpkCardDetailAdvanceDetail = ({ curProj }) => {
  return (
    <>
      <>
        <hr className="my-2" />
        <div className="w-full flex gap-2 mb-4">
          <div className="w-full flex">
            <p className="w-1/3">X1 Across (mm)</p>
            <p className="w-fit mx-4">:</p>
            <p>{curProj.label_size.split("x")[0]}</p>
          </div>
          <div className="w-full flex">
            <p className="w-1/3">Up Across</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.adjustment_up).toFixed(4))}</p>
          </div>
        </div>
        <div className="w-full flex gap-2 mb-4">
          <div className="w-full flex">
            <p className="w-1/3">X2 Across (mm)</p>
            <p className="w-fit mx-4">:</p>
            <p>{curProj.label_size.split("x")[1]}</p>
          </div>
          <div className="w-full flex">
            <p className="w-1/3">Up Along (mm)</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.up_along).toFixed(4))}</p>
          </div>
        </div>
        <div className="w-full flex gap-2 mb-4">
          <div className="w-full flex">
            <p className="w-1/3">Gap Across (mm)</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.gap_across).toFixed(4))}</p>
          </div>
          <div className="w-full flex">
            <p className="w-1/3">Eye Total (mm)</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.eye_total).toFixed(4))}</p>
          </div>
        </div>
        <div className="w-full flex gap-2 mb-4">
          <div className="w-full flex">
            <p className="w-1/3">Gap Along (mm)</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.gap_along).toFixed(4))}</p>
          </div>
          <div className="w-full flex">
            <p className="w-1/3">J. Kiri (mm)</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.j_left).toFixed(4))}</p>
          </div>
        </div>
        <div className="w-full flex gap-2 mb-4">
          <div className="w-full flex">
            <p className="w-1/3">Max Up (250mm)</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.max_up).toFixed(4))}</p>
          </div>
          <div className="w-full flex">
            <p className="w-1/3">J. Kanan (mm)</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.j_right).toFixed(4))}</p>
          </div>
        </div>
        <div className="w-full flex gap-2 mb-4">
          <div className="w-full flex">
            <p className="w-1/3">Adjustment (up)</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.adjustment_up).toFixed(4))}</p>
          </div>
          <div className="w-full flex">
            <p className="w-1/3">Printing Area (mm)</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.printing_area).toFixed(4))}</p>
          </div>
        </div>
        <div className="w-full flex gap-2 mb-4">
          <div className="w-full flex">
            <p className="w-1/3">Total Gap Across (mm)</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.total_gap_across).toFixed(4))}</p>
          </div>
          <div className="w-full flex">
            <p className="w-1/3">Material Size (mm)</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.size_material).toFixed(4))}</p>
          </div>
        </div>
        <div className="w-full flex gap-2 mb-4">
          <div className="w-full flex">
            <p className="w-1/3">Gap Total Range (mm)</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.total_gap_range).toFixed(4))}</p>
          </div>
          <div className="w-full flex">
            <p className="w-1/3">Adjustment (mm)</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.adjustment_mm).toFixed(4))}</p>
          </div>
        </div>
        <div className="w-full flex gap-2 mb-4">
          <div className="w-full flex">
            <p className="w-1/3">Gear Size</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.gear_size).toFixed(4))}</p>
          </div>
          <div className="w-full flex">
            <p className="w-1/3">Setup Tolerance (%)</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.tolerance_percent).toFixed(4))}</p>
          </div>
        </div>
        <div className="w-full flex gap-2 mb-4">
          <div className="w-full flex">
            <p className="w-1/3">Gear Size Amount</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.gear_amount).toFixed(4))}</p>
          </div>
          <div className="w-full flex">
            <p className="w-1/3">CRB Allocation (m)</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.crb).toFixed(4))}</p>
          </div>
        </div>
        <div className="w-full flex gap-2 mb-4">
          <div className="w-full flex">
            <p className="w-1/3">Clean Counter</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.clean_counter).toFixed(4))}</p>
          </div>
          <div className="w-full flex">
            <p className="w-1/3">Max Counter</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.max_counter).toFixed(4))}</p>
          </div>
        </div>
        <div className="w-full flex gap-2 mb-4">
          <div className="w-full flex">
            <p className="w-1/3">Along Run</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.along_run).toFixed(4))}</p>
          </div>
          <div className="w-full flex">
            <p className="w-1/3">Max Run (M)</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.max_run).toFixed(4))}</p>
          </div>
        </div>
        <div className="w-full flex gap-2 mb-4">
          <div className="w-full flex">
            <p className="w-1/3">Clean Run</p>
            <p className="w-fit mx-4">:</p>
            <p>
              {Number(
                parseFloat(
                  (curProj.clean_counter * curProj.along_run) / 1000
                ).toFixed(4)
              )}
            </p>
          </div>
          <div className="w-full flex">
            <p className="w-1/3">Difficult Waste</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.difficult_waste).toFixed(4))}</p>
          </div>
        </div>
        <div className="w-full flex gap-2 mb-4">
          <div className="w-full flex">
            <p className="w-1/3">Color Total</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.total_color).toFixed(4))}</p>
          </div>
          <div className="w-full flex">
            <p className="w-1/3">Distortion</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.distortion).toFixed(4))}</p>
          </div>
        </div>
        <div className="w-1/2 flex gap-2 mb-4">
          <div className="w-full flex">
            <p className="w-1/3">Speed</p>
            <p className="w-fit mx-4">:</p>
            <p>{Number(parseFloat(curProj.speed).toFixed(4))}</p>
          </div>
        </div>
      </>
    </>
  );
};

export default SpkCardDetailAdvanceDetail;
