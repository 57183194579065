import { Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import SingleCalendar from "../../common/SingleCalendar";
import { format } from "date-fns";
import { MdCalendarMonth } from "react-icons/md";

const AddSupplierModalContent = ({ formik }) => {
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [paymentDate, setPaymentDate] = useState(null);

  useEffect(() => {
    if (!formik.values.is_pkp) formik.setFieldValue("npwp", "");
  }, [formik.values.is_pkp]);

  useEffect(() => {
    paymentDate
      ? formik.setFieldValue(
          "join_date",
          format(new Date(paymentDate), "yyyy-MM-dd")
        )
      : formik.setFieldValue("join_date", "");
  }, [paymentDate]);

  return (
    <>
      <TextField
        label="Kategori"
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.category}
        onChange={(e) => formik.setFieldValue("category", e.target.value)}
        helperText={formik.touched.category && formik.errors.category}
        error={formik.touched.category && Boolean(formik.errors.category)}
      />
      <TextField
        label="Nama Supplier/Vendor"
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.supplier_name}
        onChange={(e) => formik.setFieldValue("supplier_name", e.target.value)}
        helperText={formik.touched.supplier_name && formik.errors.supplier_name}
        error={
          formik.touched.supplier_name && Boolean(formik.errors.supplier_name)
        }
      />
      <TextField
        label="Alamat Head Office"
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.address_hq}
        onChange={(e) => {
          formik.setFieldValue("address_hq", e.target.value);
        }}
        helperText={formik.touched.address_hq && formik.errors.address_hq}
        error={formik.touched.address_hq && Boolean(formik.errors.address_hq)}
      />
      <TextField
        label="Alamat Manufaktur"
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.address_manufacturer}
        onChange={(e) => {
          formik.setFieldValue("address_manufacturer", e.target.value);
        }}
        helperText={
          formik.touched.address_manufacturer &&
          formik.errors.address_manufacturer
        }
        error={
          formik.touched.address_manufacturer &&
          Boolean(formik.errors.address_manufacturer)
        }
      />{" "}
      <TextField
        label="No. Telpon"
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.phone}
        onChange={(e) => {
          formik.setFieldValue("phone", e.target.value);
        }}
        helperText={formik.touched.phone && formik.errors.phone}
        error={formik.touched.phone && Boolean(formik.errors.phone)}
      />{" "}
      <div className="w-full mb-4 flex items-center justify-between">
        <p className="basis">Status PKP</p>
        <div className="flex items-center">
          <Switch
            onChange={(e, checked) => formik.setFieldValue("is_pkp", checked)}
            checked={formik.values.is_pkp}
          />
          <p>{formik.values.is_pkp ? "Aktif" : "Tidak Aktif"}</p>
        </div>
      </div>
      {formik.values.is_pkp ? (
        <TextField
          label="NPWP"
          fullWidth
          sx={{ marginBottom: 2 }}
          value={formik.values.npwp}
          onChange={(e) => {
            formik.setFieldValue("npwp", e.target.value);
          }}
          helperText={formik.touched.npwp && formik.errors.npwp}
          error={formik.touched.npwp && Boolean(formik.errors.npwp)}
        />
      ) : null}
      <TextField
        label="Terms of Payment"
        fullWidth
        sx={{ marginBottom: 2 }}
        onChange={(e) => {
          formik.setFieldValue("top", e.target.value);
        }}
        value={formik.values.top}
        helperText={formik.touched.top && formik.errors.top}
        error={formik.touched.top && Boolean(formik.errors.top)}
      />{" "}
      <TextField
        label="Lead Time"
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.lead_time}
        onChange={(e) => {
          formik.setFieldValue("lead_time", e.target.value);
        }}
        helperText={formik.touched.lead_time && formik.errors.lead_time}
        error={formik.touched.lead_time && Boolean(formik.errors.lead_time)}
      />
      <TextField
        label="Nama PIC"
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.pic_name}
        onChange={(e) => {
          formik.setFieldValue("pic_name", e.target.value);
        }}
        helperText={formik.touched.pic_name && formik.errors.pic_name}
        error={formik.touched.pic_name && Boolean(formik.errors.pic_name)}
      />
      <TextField
        label="No. Telpon PIC"
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.pic_phone}
        onChange={(e) => {
          formik.setFieldValue("pic_phone", e.target.value);
        }}
        helperText={formik.touched.pic_phone && formik.errors.pic_phone}
        error={formik.touched.pic_phone && Boolean(formik.errors.pic_phone)}
      />
      <TextField
        label="Email PIC"
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.pic_email}
        onChange={(e) => {
          formik.setFieldValue("pic_email", e.target.value);
        }}
        helperText={formik.touched.pic_email && formik.errors.pic_email}
        error={formik.touched.pic_email && Boolean(formik.errors.pic_email)}
      />
      <p>Tanggal Bergabung</p>
      <div className=" w-full border-2 rounded-md w-full flex items-center hover:bg-slate-100">
        <div
          className="flex justify-between items-center h-full cursor-pointer w-full cursor-pointer p-2"
          onClick={(e) => setAnchorElCalendar(e.currentTarget)}
        >
          {paymentDate === null ? (
            <p className="text-gray-300">----/--/--</p>
          ) : (
            <p className="text-gray-300">
              {format(new Date(paymentDate), "yyyy-MM-dd")}
            </p>
          )}
          <MdCalendarMonth className="w-6 h-6" />
        </div>
      </div>
      <SingleCalendar
        anchorElCalendarStart={anchorElCalendar}
        setAnchorElCalendarStart={setAnchorElCalendar}
        startDate={paymentDate}
        setStartDate={setPaymentDate}
      />
    </>
  );
};

export default AddSupplierModalContent;
