import { InputAdornment, TextField } from "@mui/material";

const SpkCardInkCoverage = ({
  proj,
  formik,
  i,
  curProject,
  setCurProject,
  isDetail,
}) => {
  const changeValue = (keyName, val) => {
    var tempProject = JSON.parse(JSON.stringify(curProject));
    tempProject.ink_coverage[keyName] = val;
    setCurProject(tempProject);
  };

  return (
    <>
      <hr className="my-2" />
      <div className="rounded-md shadow-md">
        <div className="block overflow-x-auto w-full rounded-md">
          <table className="w-full">
            <thead className="bg-[#18479D] text-white border-t border-b border-2">
              <th className="p-3 text-left">Cyan</th>
              <th className="p-3 text-left">Magenta</th>
              <th className="p-3 text-left">Yellow</th>
              <th className="p-3 text-left">Black</th>
              <th className="p-3 text-left">Orange</th>
            </thead>
            <tbody>
              <tr className="border-t border-l border-2">
                <td className="p-3">
                  {isDetail ? (
                    <p>{curProject.ink_coverage.cyan} %</p>
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      label="Cyan"
                      placeholder="0"
                      value={proj.ink_coverage.cyan}
                      onChange={(e) => {
                        let value = e.target.value;
                        const regex = /^\d*[.]?\d{0,3}$/;
                        if (regex.test(value)) {
                          const numericValue = parseFloat(value);
                          formik.setFieldValue(
                            `project[${i}].ink_coverage.cyan`,
                            value
                          );
                          if (!isNaN(numericValue)) {
                            changeValue("cyan", numericValue);
                          }
                        }
                      }}
                    />
                  )}
                </td>
                <td className="p-3">
                  {isDetail ? (
                    <p>{curProject.ink_coverage.magenta} %</p>
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      label="Magenta"
                      placeholder="0"
                      value={proj.ink_coverage.magenta}
                      onChange={(e) => {
                        let value = e.target.value;
                        const regex = /^\d*[.]?\d{0,3}$/;
                        if (regex.test(value)) {
                          const numericValue = parseFloat(value);
                          formik.setFieldValue(
                            `project[${i}].ink_coverage.magenta`,
                            value
                          );
                          if (!isNaN(numericValue)) {
                            changeValue("magenta", numericValue);
                          }
                        }
                      }}
                    />
                  )}
                </td>
                <td className="p-3">
                  {isDetail ? (
                    <p>{curProject.ink_coverage.yellow} %</p>
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      label="Yellow"
                      placeholder="0"
                      value={proj.ink_coverage.yellow}
                      onChange={(e) => {
                        let value = e.target.value;
                        const regex = /^\d*[.]?\d{0,3}$/;
                        if (regex.test(value)) {
                          const numericValue = parseFloat(value);
                          formik.setFieldValue(
                            `project[${i}].ink_coverage.yellow`,
                            value
                          );
                          if (!isNaN(numericValue)) {
                            changeValue("yellow", numericValue);
                          }
                        }
                      }}
                    />
                  )}
                </td>
                <td className="p-3">
                  {isDetail ? (
                    <p>{curProject.ink_coverage.black} %</p>
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      label="Black"
                      placeholder="0"
                      value={proj.ink_coverage.black}
                      onChange={(e) => {
                        let value = e.target.value;
                        const regex = /^\d*[.]?\d{0,3}$/;
                        if (regex.test(value)) {
                          const numericValue = parseFloat(value);
                          formik.setFieldValue(
                            `project[${i}].ink_coverage.black`,
                            value
                          );
                          if (!isNaN(numericValue)) {
                            changeValue("black", numericValue);
                          }
                        }
                      }}
                    />
                  )}
                </td>
                <td className="p-3">
                  {isDetail ? (
                    <p>{curProject.ink_coverage.orange} %</p>
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      label="Orange"
                      placeholder="0"
                      value={proj.ink_coverage.orange}
                      onChange={(e) => {
                        let value = e.target.value;
                        const regex = /^\d*[.]?\d{0,3}$/;
                        if (regex.test(value)) {
                          const numericValue = parseFloat(value);
                          formik.setFieldValue(
                            `project[${i}].ink_coverage.orange`,
                            value
                          );
                          if (!isNaN(numericValue)) {
                            changeValue("orange", numericValue);
                          }
                        }
                      }}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="w-full">
            <thead className="bg-[#18479D] text-white border-t border-b border-2">
              <th className="p-3 text-left">Violet</th>
              <th className="p-3 text-left">Green</th>
              <th className="p-3 text-left">Ref. Blue</th>
              <th className="p-3 text-left">P.032</th>
              <th className="p-3 text-left">Varnish</th>
            </thead>
            <tbody>
              <tr className="border-t border-l border-2">
                <td className="p-3">
                  {isDetail ? (
                    <p>{curProject.ink_coverage.violet} %</p>
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      label="Violet"
                      placeholder="0"
                      value={proj.ink_coverage.violet}
                      onChange={(e) => {
                        let value = e.target.value;
                        const regex = /^\d*[.]?\d{0,3}$/;
                        if (regex.test(value)) {
                          const numericValue = parseFloat(value);
                          formik.setFieldValue(
                            `project[${i}].ink_coverage.violet`,
                            value
                          );
                          if (!isNaN(numericValue)) {
                            changeValue("violet", numericValue);
                          }
                        }
                      }}
                    />
                  )}
                </td>
                <td className="p-3">
                  {isDetail ? (
                    <p>{curProject.ink_coverage.green} %</p>
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      label="Green"
                      placeholder="0"
                      value={proj.ink_coverage.green}
                      onChange={(e) => {
                        let value = e.target.value;
                        const regex = /^\d*[.]?\d{0,3}$/;
                        if (regex.test(value)) {
                          const numericValue = parseFloat(value);
                          formik.setFieldValue(
                            `project[${i}].ink_coverage.green`,
                            value
                          );
                          if (!isNaN(numericValue)) {
                            changeValue("green", numericValue);
                          }
                        }
                      }}
                    />
                  )}
                </td>
                <td className="p-3">
                  {isDetail ? (
                    <p>{curProject.ink_coverage.ref_blue} %</p>
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      label="Ref. Blue"
                      placeholder="0"
                      value={proj.ink_coverage.ref_blue}
                      onChange={(e) => {
                        let value = e.target.value;
                        const regex = /^\d*[.]?\d{0,3}$/;
                        if (regex.test(value)) {
                          const numericValue = parseFloat(value);
                          formik.setFieldValue(
                            `project[${i}].ink_coverage.ref_blue`,
                            value
                          );
                          if (!isNaN(numericValue)) {
                            changeValue("ref_blue", numericValue);
                          }
                        }
                      }}
                    />
                  )}
                </td>
                <td className="p-3">
                  {isDetail ? (
                    <p>{curProject.ink_coverage.p_032} %</p>
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      label="P.032"
                      placeholder="0"
                      value={proj.ink_coverage.p_032}
                      onChange={(e) => {
                        let value = e.target.value;
                        const regex = /^\d*[.]?\d{0,3}$/;
                        if (regex.test(value)) {
                          const numericValue = parseFloat(value);
                          formik.setFieldValue(
                            `project[${i}].ink_coverage.p_032`,
                            value
                          );
                          if (!isNaN(numericValue)) {
                            changeValue("p_032", numericValue);
                          }
                        }
                      }}
                    />
                  )}
                </td>
                <td className="p-3">
                  {isDetail ? (
                    <p>{curProject.ink_coverage.varnish} %</p>
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      label="Varnish"
                      placeholder="0"
                      value={proj.ink_coverage.varnish}
                      onChange={(e) => {
                        let value = e.target.value;
                        const regex = /^\d*[.]?\d{0,3}$/;
                        if (regex.test(value)) {
                          const numericValue = parseFloat(value);
                          formik.setFieldValue(
                            `project[${i}].ink_coverage.varnish`,
                            value
                          );
                          if (!isNaN(numericValue)) {
                            changeValue("varnish", numericValue);
                          }
                        }
                      }}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SpkCardInkCoverage;
