import React, { ReactNode } from "react";
import { Box, Modal } from "@mui/material";
import { RxCross1 } from "react-icons/rx";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalInput = ({
  title,
  subtitle,
  content,
  open,
  setOpen,
  hasButton,
  buttonText,
  setTriggerFunc,
  isDisable,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            width: "60%",
            overflowY: "auto",
            overflowX: "wrap",
            maxHeight: "85vh",
            padding: 0,
          }}
          className="text-left"
        >
          <div className="flex justify-between align-center p-8 pb-0">
            <h2 id="parent-modal-title" className="font-bold text-xl">
              {title}
            </h2>
            <RxCross1
              className="inline-block cursor-pointer h-full"
              onClick={handleClose}
            />
          </div>
          <hr className="my-4" />
          <p
            id="parent-modal-description"
            className="text-slate-500 mb-4 text-wrap px-8"
          >
            {subtitle}
          </p>
          <div className="px-8 mb-4">{content}</div>
          {hasButton ? (
            <>
              <hr className="mb-4" />
              <div className="flex gap-2 items-center mb-4 mx-4">
                <button
                  className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
                  onClick={() => {
                    setTriggerFunc(true);
                  }}
                  disabled={isDisable}
                >
                  {buttonText}
                </button>
              </div>
            </>
          ) : null}
        </Box>
      </Modal>
    </>
  );
};

export default ModalInput;
