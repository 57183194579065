import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  CircularProgress,
  InputAdornment,
  Pagination,
  PaginationItem,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { MdCalendarMonth, MdCancel } from "react-icons/md";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { IoMdDownload, IoMdAddCircleOutline } from "react-icons/io";
import { format } from "date-fns";
import { setNewSupplierInvoice } from "../../../services/supplierInvoiceApi";
import PrintInvoice from "../supplierInvoice/PrintInvoice";
import ModalInput from "../../common/ModalInput";
import { useFormik } from "formik";
import * as yup from "yup";
import SingleCalendar from "../../common/SingleCalendar";
import { getSupplierPoList } from "../../../services/supplierPoApi";
import {
  getCustomerInvoiceList,
  updateListPaymentInvoice,
} from "../../../services/customerInvoiceApi";

const validationSchema = yup.object({
  file: yup.mixed().nullable(),
  json: yup.object({
    invoice_number: yup
      .string()
      .required()
      .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
    po_number: yup
      .string()
      .required()
      .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
    due_date: yup
      .string()
      .required()
      .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  }),
});

const CustomerInvoiceList = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(0);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isDownload, setIsDownload] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [addErrMessage, setAddErrMessage] = useState("");
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [selectedPo, setSelectedPo] = useState(null);
  const [refetchList, setRefetchList] = useState(false);
  const [changeImage, setChangeImage] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [selectedRows, setSelectedRows] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [openPay, setOpenPay] = useState(false);
  const [isPay, setIsPay] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const uploadMedia = (e) => {
    const files = e.target.files[0];

    if (!files) return;

    formik.setFieldValue("file", files);
    setChangeImage(true);
  };

  const { mutate: mutateNewInvoice, isPending } = useMutation({
    mutationFn: setNewSupplierInvoice,
    onSuccess: () => {
      setOpenAdd(false);
      setRefetchList(true);
    },
    onError: (err) => setAddErrMessage(err.message.id),
  });

  const { mutate: mutateUpdatePaymentInvoice } = useMutation({
    mutationFn: updateListPaymentInvoice,
    onSuccess: () => {
      setIsPay(false);
      setOpenPay(false);
      setRefetchList(true);
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
      setIsPay(false);
    },
  });

  const { data, isFetching } = useQuery({
    queryKey: ["invoice-list", search, filter, page, fetchLimit, refetchList],
    queryFn: () =>
      getCustomerInvoiceList({
        search: search,
        status: filter,
        offset: page,
        limit: fetchLimit,
      }),
  });

  const { data: dataPo, isFetching: isFetchingList } = useQuery({
    queryKey: ["po-list"],
    queryFn: () =>
      getSupplierPoList({
        inv: true,
        search: "",
        start: "",
        end: "",
        offset: 1,
        limit: 10000,
      }),
  });

  const formik = useFormik({
    initialValues: {
      file: null,
      json: {
        invoice_number: "",
        po_number: "",
        due_date: "",
      },
    },
    validationSchema,
    validateOnMount: true,
  });

  useEffect(() => {
    if (changeImage) {
      var reader = new FileReader();
      var imgtag = document.getElementById("uploaded-img");

      reader.onload = function (event) {
        imgtag.src = event.target.result;
      };

      reader.readAsDataURL(formik.values.file);
      setChangeImage(false);
    }
  }, [changeImage]);

  useEffect(() => {
    if (!isDownload) setSelectedInvoice(null);
  }, [isDownload]);

  useEffect(() => {
    setAddErrMessage("");
  }, [formik.values]);

  useEffect(() => {
    if (refetchList) setRefetchList(false);
  }, [data]);

  useEffect(() => {
    formik.setFieldValue(
      "json.po_number",
      selectedPo ? selectedPo.po_number : ""
    );
  }, [selectedPo]);

  useEffect(() => {
    if (openAdd) {
      formik.resetForm();
      setDueDate(null);
    }
  }, [openAdd]);

  useEffect(() => {
    if (isAdd) {
      mutateNewInvoice(formik.values);
      setIsAdd(false);
    }
  }, [isAdd]);

  useEffect(() => {
    if (isPay) {
      const body = selectedRows.map((id) => ({
        id: Number(id),
        status: 1,
      }));

      mutateUpdatePaymentInvoice(body);
    }
  }, [isPay, selectedRows, mutateUpdatePaymentInvoice]);

  useEffect(() => {
    dueDate
      ? formik.setFieldValue(
          "json.due_date",
          format(new Date(dueDate), "yyyy-MM-dd")
        )
      : formik.setFieldValue("json.due_date", "");
  }, [dueDate]);

  useEffect(() => {
    setPage(1);
  }, [fetchLimit, search, filter]);

  const sortedData = useMemo(() => {
    if (!data || !data.invoice) return [];

    const sorted = [...data.invoice];
    if (sortConfig.key !== null) {
      sorted.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sorted;
  }, [data, sortConfig]);

  const handleSelectRow = (id) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };

  const handleSelectAll = () => {
    if (isSelectAll) {
      setSelectedRows([]);
    } else {
      const allIds = sortedData.map((item) => item.id);
      setSelectedRows(allIds);
    }
    setIsSelectAll(!isSelectAll);
  };

  useEffect(() => {
    const allIds = sortedData.map((item) => item.id);
    setIsSelectAll(selectedRows.length === allIds.length && allIds.length > 0);
  }, [selectedRows, sortedData]);

  return (
    <div>
      <div className="flex mb-4 gap-2">
        <TextField
          fullWidth
          placeholder="Nama Customer, No. Invoice, No. DO, No. PO, atau Label"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <FormControl fullWidth>
          <Select value={filter} onChange={(e) => setFilter(e.target.value)}>
            <MenuItem value={0}>Semua Status</MenuItem>
            <MenuItem value={1}>Sudah Dibayar</MenuItem>
            <MenuItem value={2}>Belum Dibayar</MenuItem>
            <MenuItem value={3}>Terlambat Dibayar</MenuItem>
          </Select>
        </FormControl>
        {selectedRows.length > 0 && (
          <button
            onClick={() => setOpenPay(true)}
            className="w-1/4 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
          >
            <p>Bayar</p>
          </button>
        )}
        <button
          onClick={() => navigate("/customer-invoice/create-invoice")}
          className="w-1/4 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
        >
          <p>Buat Invoice</p>
          <IoMdAddCircleOutline className="text-[18px]" />
        </button>
      </div>

      <>
        <div className="rounded-md shadow-md">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                {filter !== 1 && (
                  <th className="p-3 text-left">
                    <Checkbox
                      checked={isSelectAll}
                      onChange={handleSelectAll}
                      inputProps={{ "aria-label": "Select all invoices" }}
                    />
                  </th>
                )}
                <th className="p-3 text-left">No</th>
                <th className="p-3 text-left">Tanggal</th>
                <th className="p-3 text-left">No. Invoice</th>
                <th className="p-3 text-left">No. DO</th>
                <th className="p-3 text-left">Customer</th>
                <th className="p-3 text-left">Label</th>
                <th className="p-3 text-left">Qty</th>
                <th className="p-3 text-left">Harga</th>
                <th className="p-3 text-left">Diskon</th>
                <th className="p-3 text-left">PPN</th>
                <th className="p-3 text-left">Freight Charge</th>
                <th className="p-3 text-left">Grand Total</th>
                <th className="p-3 text-left">Status</th>
                <th className="p-3 w-[15%] text-left">Action</th>
              </thead>
              <tbody>
                {data && data.invoice?.length > 0 && !isFetching ? (
                  data.invoice.map((item, i) => {
                    return (
                      <tr className="border-t border-l border-2" key={i}>
                        {filter !== 1 && (
                          <td className="p-3">
                            {item.status !== 1 ? (
                              <Checkbox
                                className="cursor-pointer"
                                type="checkbox"
                                checked={selectedRows.includes(item.id)}
                                onChange={() => handleSelectRow(item.id)}
                              />
                            ) : (
                              <Checkbox
                                disabled
                                className="cursor-pointer"
                                type="checkbox"
                                checked
                              />
                            )}
                          </td>
                        )}
                        <td className="p-3">
                          {(page - 1) * fetchLimit + (i + 1)}
                        </td>
                        <td className="p-3">
                          {format(new Date(item.invoice_date), "dd-MM-yyyy")}
                        </td>
                        <td
                          className="p-3 text-blue-500 cursor-pointer hover:text-slate-500"
                          onClick={() =>
                            navigate(`/customer-invoice/detail/${item.id}`)
                          }
                        >
                          {item.invoice_number}
                        </td>
                        <td className="p-3">{item.po_number}</td>
                        <td className="p-3">{item.customer_name}</td>
                        <td className="p-3">
                          {item.data_label
                            ? item.data_label
                                .map((label) => label.name_label)
                                .join(", ")
                            : ""}
                        </td>
                        <td className="p-3">
                          {item.data_label
                            ? item.data_label.reduce(
                                (acc, num) => acc + num.quantity,
                                0
                              )
                            : 0}
                        </td>
                        <td className="p-3">
                          Rp
                          {item.total_price?.toLocaleString()}
                        </td>
                        <td className="p-3">
                          Rp
                          {item.discount?.toLocaleString()}
                        </td>
                        <td className="p-3">
                          Rp
                          {item.grand_total
                            ? Number(
                                parseFloat(
                                  item.total_price * (item.ppn ? 0.11 : 0)
                                ).toFixed(4)
                              ).toLocaleString()
                            : 0}
                        </td>
                        <td className="p-3">
                          Rp
                          {item.freight_charge?.toLocaleString()}
                        </td>
                        <td className="p-3">
                          Rp
                          {(item.grand_total
                            ? item.grand_total
                            : 0
                          ).toLocaleString()}
                        </td>
                        <td className="p-3">
                          {item.status === 1
                            ? "Sudah Dibayar"
                            : item.status === 2
                            ? "Belum Dibayar"
                            : "Terlambat Dibayar"}
                        </td>
                        <td className="p-3 w-[15%]">
                          <div className="flex gap-2 items-center">
                            <IoMdDownload
                              onClick={() => {
                                setIsDownload(true);
                                setSelectedInvoice(item);
                              }}
                              className="text-blue-500 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : !isFetching ? (
                  <tr className="border-t border-l border-2">
                    <td colSpan={13} className="py-3 text-center">
                      Data invoice customer tidak ditemukan
                    </td>
                  </tr>
                ) : (
                  <tr className="border-t border-l border-2">
                    <td colSpan={13} className="py-3 text-center">
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {data && data.total > 0 ? (
            <div className="w-full flex justify-between pb-8 px-8">
              <button
                onClick={() => {
                  if (page > 1) setPage(page - 1);
                }}
                disabled={page === 1}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowLeftLong /> Previous
              </button>
              <Pagination
                hideNextButton
                hidePrevButton
                shape="rounded"
                page={page}
                count={Math.ceil(data.total / fetchLimit)}
                onChange={(e, page) => setPage(page)}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
              <button
                onClick={() => {
                  if (page < Math.ceil(data.total / fetchLimit))
                    setPage(page + 1);
                }}
                disabled={page === Math.ceil(data.total / fetchLimit)}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowRightLong /> Next
              </button>
            </div>
          ) : null}
        </div>
        {data && data.total > 0 ? (
          <div className="mt-4 text-xs flex gap-3 items-center">
            <p>Show Table</p>
            <p
              onClick={() => setFetchLimit(10)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 10 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              10
            </p>
            <p
              onClick={() => setFetchLimit(50)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 50 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              50
            </p>
            <p
              onClick={() => setFetchLimit(100)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 100 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              100
            </p>
          </div>
        ) : null}
      </>

      <ModalInput
        title="Create Customer Invoice"
        subtitle=""
        content={
          <>
            <TextField
              fullWidth
              sx={{ marginBottom: 2 }}
              label="No. Invoice"
              value={formik.values.json.invoice_number}
              onChange={(e) =>
                formik.setFieldValue("json.invoice_number", e.target.value)
              }
            />
            <Autocomplete
              sx={{ width: "100%", marginBottom: 2 }}
              disablePortal
              value={selectedPo}
              onChange={(_, option) => {
                setSelectedPo(option);
              }}
              options={dataPo && dataPo.data ? dataPo.data : []}
              getOptionLabel={(option) => option.po_number}
              renderInput={(params) => <TextField {...params} label="No. PO" />}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === "reset") {
                  setSelectedPo(null);
                  return;
                }
              }}
            />
            <p>Tanggal Jatuh Tempo</p>
            <div className="mb-4 border-2 rounded-md w-full flex items-center hover:bg-slate-100">
              <div
                className="flex justify-between items-center h-full w-full p-2 cursor-pointer text-black"
                onClick={(e) => {
                  setAnchorElCalendar(e.currentTarget);
                }}
              >
                {dueDate === null ? (
                  <p>----/--/--</p>
                ) : (
                  <p>{format(new Date(dueDate), "yyyy-MM-dd")}</p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
            <div className="w-full border-2 p-4 mb-4 rounded-lg text-center">
              <>
                {isPending ? (
                  <CircularProgress />
                ) : formik.values.file === null ? (
                  <>
                    <>
                      <label
                        htmlFor="dropzone-file1"
                        className="cursor-pointer flex flex-wrap justify-center my-8 hover:text-slate-400"
                      >
                        <div className="flex items-center justify-center">
                          <AiOutlineCloudUpload className="text-3xl" />
                          <p className="ml-4 font-bold text-lg">Upload File</p>
                        </div>
                        <p className="text-sm rounded-md cursor-pointer w-full mt-2 text-gray-400">
                          Klik untuk mencari file di perangkat anda
                        </p>
                        <input
                          id="dropzone-file1"
                          type="file"
                          accept="image/jpeg, image/jpg, image/png"
                          onChange={(e) => {
                            uploadMedia(e);
                          }}
                          hidden
                        />
                      </label>
                    </>
                  </>
                ) : (
                  <div className="flex items-center justify-center w-full relative">
                    <img
                      id="uploaded-img"
                      className="max-h-40 object-contain"
                      alt="Uploaded Invoice"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        formik.setFieldValue("file", null);
                      }}
                    >
                      <MdCancel className="text-2xl text-red-500 hover:text-red-700 absolute top-0 right-0" />
                    </button>
                  </div>
                )}
              </>
            </div>
            <p className="text-red-500">{addErrMessage}</p>
          </>
        }
        open={openAdd}
        setOpen={setOpenAdd}
        hasButton={true}
        buttonText="Submit"
        setTriggerFunc={setIsAdd}
        isDisable={!formik.isValid || isPending}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendar}
        setAnchorElCalendarStart={setAnchorElCalendar}
        startDate={dueDate}
        setStartDate={setDueDate}
      />

      <ModalInput
        title="Bayar"
        subtitle={`Apakah anda yakin invoice sudah dibayar?`}
        content={<>{/* <p className="text-red-500">{errorMessage}</p> */}</>}
        open={openPay}
        setOpen={setOpenPay}
        hasButton={true}
        buttonText="Yes"
        setTriggerFunc={setIsPay}
        isDisable={false}
      />

      {/* {selectedInvoice ? ( */}
      <PrintInvoice
        isDownload={isDownload}
        setIsDownload={setIsDownload}
        data={selectedInvoice}
        type="customer"
      />
      {/* ) : null} */}
    </div>
  );
};

export default CustomerInvoiceList;
