import { useEffect, useRef } from "react";
import Logo from "../../../assets/logo.png";
import jsPDF from "jspdf";
import moment from "moment";

const PrintReturSupplier = ({ isDownload, setIsDownload, data }) => {
  const poRef = useRef();
  const date = new Date();

  const styles = {
    page: {
      marginTop: "6mm",
      fontSize: 8,
      size: "a4",
    },
    detail: {
      fontSize: 6,
    },
    textXs: { fontSize: 6 },
    textMd: { fontSize: 8 },
    textLg: { fontSize: 12 },
    terbilangContainer: {
      width: "140mm",
    },
  };

  useEffect(() => {
    if (isDownload && data) {
      const doc = new jsPDF({
        lineHeight: 1.2,
        fontSize: 11,
        format: "a4",
        unit: "px",
      });
      doc.html(poRef.current, {
        callback: function (doc) {
          doc.save(
            "RTR/INDOLABEL/SPL/" + moment(date).format("YYYYMMDD") + ".pdf"
          );
        },
        x: 0,
        y: 0,
        autoPaging: "text",
        width: 2480,
      });

      setIsDownload(false);
    }
  }, [isDownload, data, setIsDownload]);

  if (!data) return null;

  return (
    <div>
      {data ? (
        <div ref={poRef} style={styles.page}>
          <div className="w-96">
            <table className="border-collapse w-full mx-6 mb-1">
              <tbody className="ml-auto">
                <tr className="h-10">
                  <td className="border border-black border-r-0 font-bold pb-2 pl-1">
                    <img src={Logo} className="w-[25mm] h-full" alt="Logo" />
                    <p className="w-full font-bold text-[6px] mt-[-6px]">
                      PT. INDOLABEL SURYA PRATAMA
                    </p>
                  </td>
                  <td className="border border-black border-r-0 w-36 font-bold pb-2 text-center">
                    SUPPLIER COMPLAIN REPORT
                  </td>
                  <td
                    className="border border-black pb-2 pl-1 w-28"
                    style={styles.detail}
                  >
                    <tr>No. Doc : </tr>
                    <tr>Revisi : </tr>
                    <tr>Tgl berlaku : </tr>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="border-collapse w-full mx-6 mb-1">
              <tbody className="ml-auto">
                <tr>
                  <td className="border border-black">
                    <div className="flex">
                      <div className="w-1/2 pl-1 pb-2" style={styles.detail}>
                        <div className="flex">
                          <p>No. SCR</p>
                          <p className="ml-2">: {data.no_ncr}</p>
                        </div>
                        <div className="flex">
                          <p>Tgl. Kejadian</p>
                          <p className="ml-2">: {data.accident_date}</p>
                        </div>
                        <div className="flex">
                          <p>Lokasi Kejadian</p>
                          <p className="ml-2">: {data.location}</p>
                        </div>
                        <div className="flex">
                          <p>Supplier Name</p>
                          <p className="ml-2">: {data.supplier_name}</p>
                        </div>
                        <div className="flex">
                          <p>Manufacture Name</p>
                          <p className="ml-2">: {data.manufacture}</p>
                        </div>
                        <div className="flex">
                          <p>Material Name :</p>
                          {data.material &&
                            data.material.map((item, i) => (
                              <p className="ml-2">{item},</p>
                            ))}
                        </div>
                      </div>
                      <div className="w-1/2 pl-1 pb-2" style={styles.detail}>
                        <div className="flex">
                          <p>PO No</p>
                          <p className="ml-2">: {data.po_number}</p>
                        </div>
                        <div className="flex">
                          <p>Lot No</p>
                          <p className="ml-2">: {data.lot_no}</p>
                        </div>
                        <div className="flex">
                          <p>Item Code :</p>
                          {data.internal_code &&
                            data.internal_code.map((item, i) => (
                              <p key={i} className="ml-2">
                                {item},
                              </p>
                            ))}
                        </div>
                        <div className="flex">
                          <p>Job No (SPK)</p>
                          <p className="ml-2">: {data.spk_number}</p>
                        </div>
                        <div className="flex">
                          <p>Qty Complaint :</p>
                          {data.quantity &&
                            data.quantity.map((item, i) => (
                              <p className="ml-2">{item}, </p>
                            ))}
                        </div>
                        <div className="flex">
                          <p>Status Complain</p>
                          <p className="ml-2">: {data.status}</p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="border-collapse w-full mx-6 mb-1">
              <tbody className="ml-auto" style={styles.detail}>
                <tr className="mt-[-4px]">
                  <td className="border border-black align-top w-3/4">
                    <p className="font-bold border-b border-black pl-1 pb-1">
                      Rincian Ketidaksesuaian :
                    </p>
                    <p className="pl-1">{data.problem}</p>
                  </td>
                  <td className="border border-black border-l-0 align-top w-1/4">
                    <div className="border-b border-black">
                      <p className="pl-1">Dibuat oleh :</p>
                      <div className="h-8 flex items-center justify-center"></div>
                      <p className="text-center pb-1"></p>
                    </div>
                    <div className="">
                      <p className="pl-1">Disetujui oleh :</p>
                      <div className="h-8 flex items-center justify-center"></div>
                      <p className="text-center pb-1"></p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <table
              className="border-collapse w-full mx-6 mb-1"
              style={styles.detail}
            >
              <thead>
                <tr>
                  <th
                    className="border border-black border-b-0 text-left pl-1 pb-1"
                    colSpan={7}
                  >
                    Analisa Penyebab Masalah:
                  </th>
                </tr>
                <tr>
                  <th className="border border-black border-b-0 text-center pb-1">
                    Analisa 5M
                  </th>
                  <th className="border border-black border-b-0 border-l-0 text-center pb-1 px-4">
                    Why 1
                  </th>
                  <th className="border border-black border-b-0 border-l-0 text-center pb-1 px-4">
                    Why 2
                  </th>
                  <th className="border border-black border-b-0 border-l-0 text-center pb-1 px-4">
                    Why 3
                  </th>
                  <th className="border border-black border-b-0 border-l-0 text-center pb-1 px-4">
                    Why 4
                  </th>
                  <th className="border border-black border-b-0 border-l-0 text-center pb-1 px-4">
                    Why 5
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-black border-b-0 p-2">Man</td>
                  <td className="border border-black border-b-0 border-l-0 p-2"></td>
                  <td className="border border-black border-b-0 border-l-0 p-2"></td>
                  <td className="border border-black border-b-0 border-l-0 p-2"></td>
                  <td className="border border-black border-b-0 border-l-0 p-2"></td>
                  <td className="border border-black border-b-0 border-l-0 p-2"></td>
                </tr>
                <tr>
                  <td className="border border-black border-b-0 p-2">
                    Machine
                  </td>
                  <td className="border border-black border-b-0 border-l-0 p-2"></td>
                  <td className="border border-black border-b-0 border-l-0 p-2"></td>
                  <td className="border border-black border-b-0 border-l-0 p-2"></td>
                  <td className="border border-black border-b-0 border-l-0 p-2"></td>
                  <td className="border border-black border-b-0 border-l-0 p-2"></td>
                </tr>
                <tr>
                  <td className="border border-black border-b-0 p-2">Method</td>
                  <td className="border border-black border-b-0 border-l-0 p-2"></td>
                  <td className="border border-black border-b-0 border-l-0 p-2"></td>
                  <td className="border border-black border-b-0 border-l-0 p-2"></td>
                  <td className="border border-black border-b-0 border-l-0 p-2"></td>
                  <td className="border border-black border-b-0 border-l-0 p-2"></td>
                </tr>
                <tr>
                  <td className="border border-black border-b-0 p-2">
                    Material
                  </td>
                  <td className="border border-black border-b-0 border-l-0 p-2"></td>
                  <td className="border border-black border-b-0 border-l-0 p-2"></td>
                  <td className="border border-black border-b-0 border-l-0 p-2"></td>
                  <td className="border border-black border-b-0 border-l-0 p-2"></td>
                  <td className="border border-black border-b-0 border-l-0 p-2"></td>
                </tr>
                <tr>
                  <td className="border border-black p-2">
                    Mileu / Environment
                  </td>
                  <td className="border border-black border-l-0 p-2"></td>
                  <td className="border border-black border-l-0 p-2"></td>
                  <td className="border border-black border-l-0 p-2"></td>
                  <td className="border border-black border-l-0 p-2"></td>
                  <td className="border border-black border-l-0 p-2"></td>
                </tr>
              </tbody>
            </table>

            <table className="border-collapse w-full mx-6 mb-1">
              <tbody style={styles.detail}>
                <tr>
                  <td className="border border-black align-top w-3/4">
                    <p className="font-bold border-b border-black pl-1 pb-1">
                      Corrective Action (Diisi Supplier):
                    </p>
                    <table className="w-full">
                      <thead>
                        <tr>
                          <th className="border border-black border-b-0 border-t-0 border-l-0 text-center pb-1">
                            Uraian
                          </th>
                          <th className="border border-black border-b-0 border-t-0 border-l-0 text-center pb-1">
                            Due Date
                          </th>
                          <th className="text-center pb-1">PIC</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border border-black border-b-0 border-l-0 p-8"></td>
                          <td className="border border-black border-b-0 border-l-0 p-8"></td>
                          <td className="border border-black border-b-0 border-l-0 border-r-0 p-8"></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td className="border border-black border-l-0 align-top w-1/4">
                    <div className="border-b border-black pb-1">
                      <p className="pl-1">Dibuat oleh:</p>
                      <div className="h-8 flex items-center justify-center"></div>
                    </div>
                    <div className="pb-1">
                      <p className="pl-1">Disetujui oleh:</p>
                      <div className="h-8 flex items-center justify-center"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="border-collapse w-full mx-6 mb-1">
              <tbody className="ml-auto" style={styles.detail}>
                <tr className="mt-[-4px]">
                  <td className="border border-black align-top w-3/4">
                    <p className="pl-1 font-bold">
                      Verifikasi CAPA (Diisi Indolabel) :
                    </p>
                    <p className="pl-1">{data.capa ? data.capa : "-"}</p>
                  </td>
                  <td className="border border-black border-l-0 align-top w-1/4">
                    <div className="">
                      <p className="pl-1">Diperiksa oleh,</p>
                      <div className="h-8 flex items-center justify-center"></div>
                    </div>
                    <div className="">
                      <p className="pl-1">Disetujui oleh,</p>
                      <div className="h-8 flex items-center justify-center"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PrintReturSupplier;
