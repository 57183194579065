import {
  Autocomplete,
  Breadcrumbs,
  Chip,
  FormControl,
  Link,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SingleCalendar from "../../common/SingleCalendar";
import { useEffect, useState } from "react";
import { MdCalendarMonth } from "react-icons/md";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getDataProject,
  getShipmentMethod,
  setNewDo,
  setNewShipmentMethod,
} from "../../../services/deliveryOrder";
import ModalInput from "../../common/ModalInput";
import { getSpkList } from "../../../services/SpkApi";
import { getSoList } from "../../../services/salesOrderApi";

const AddDeliveryOrder = () => {
  const navigate = useNavigate();
  const curDate = Date.now();

  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [openAddSm, setOpenAddSm] = useState(false);
  const [newSm, setNewSm] = useState("");
  const [isAddSm, setIsAddSm] = useState(false);
  const [anchorElCalendarDelivery, setAnchorElCalendarDelivery] =
    useState(null);
  const [doDate, setDoDate] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [plate, setPlate] = useState("");
  const [so, setSo] = useState(null);
  const [spk, setSpk] = useState(null);
  const [spkArr, setSpkArr] = useState([]);
  const [shipping, setShipping] = useState(0);
  const [officer, setOfficer] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageCreateSm, setErrorMessageCreateSm] = useState("");
  const [refreshSm, setRefreshSm] = useState(true);
  const [noDo, setNoDo] = useState("");
  const [deliveryDateSo, setDeliveryDateSo] = useState(null);
  const [dueDateSo, setDueDateSo] = useState(null);
  const [anchorElCalendarDeliverySo, setAnchorElCalendarDeliverySo] =
    useState(null);
  const [anchorElCalendarDueSo, setAnchorElCalendarDueSo] = useState(null);
  const [label, setLabel] = useState([]);

  const { mutate: mutateNewDo } = useMutation({
    mutationFn: setNewDo,
    onSuccess: () => navigate("/do"),
    onError: (err) => setErrorMessage(err.message.id),
  });

  const { mutate: mutateSoList, data: dataSoList } = useMutation({
    mutationFn: getSoList,
  });

  const { mutate: mutateNewSm, isPending: isPendingCreateSm } = useMutation({
    mutationFn: setNewShipmentMethod,
    onSuccess: () => {
      setOpenAddSm(false);
      setRefreshSm(true);
      setIsAddSm(false);
    },
    onError: (err) => setErrorMessageCreateSm(err.message.id),
  });

  const { data: dataShipmentMethod } = useQuery({
    queryKey: ["sm-list", refreshSm],
    queryFn: () => getShipmentMethod(),
  });

  const {
    mutate: mutateDataProject,
    data: dataProject,
    isPending,
  } = useMutation({
    mutationFn: getDataProject,
    onSuccess: (data) => {
      var temp = [];
      data.map((item) =>
        temp.push({
          spk_id: item.spk_id,
          label_name: item.label_name,
          qty_delivery: 0,
        })
      );
      setLabel(temp);
    },
  });

  const isFormValid = () => {
    return (
      doDate !== null &&
      deliveryDate !== null &&
      so !== null &&
      spkArr.length > 0 &&
      shipping > 0 &&
      officer !== "" &&
      label.every((item) => item.qty_delivery > 0)
    );
  };

  const handleSubmit = () => {
    const body = {
      do_number: noDo,
      do_date: format(doDate, "yyyy-MM-dd"),
      so_id: so.id,
      spk_no: spkArr.map((item) => item.id),
      delivery_date: format(deliveryDate, "yyyy-MM-dd"),
      shipment_method: shipping,
      carrier_name: officer,
      plate_number: plate,
      label: label,
    };
    mutateNewDo(body);
  };

  const { mutate: mutateSpkList, data: dataSpkList } = useMutation({
    mutationFn: getSpkList,
  });

  useEffect(() => {
    if (spkArr && spkArr.length > 0) {
      mutateDataProject({ id: spkArr.map((item) => item.id) });
    }
  }, [spkArr]);

  useEffect(() => {
    if (shipping < 0) {
      setOpenAddSm(true);
      setShipping(0);
    }
  }, [shipping]);

  useEffect(() => {
    if (isAddSm) {
      mutateNewSm({ shipment_name: newSm });
    }
  }, [isAddSm]);

  useEffect(() => {
    setRefreshSm(false);
  }, [dataShipmentMethod]);

  useEffect(() => {
    setErrorMessage("");
  }, [doDate, deliveryDate, spk, shipping, officer]);

  useEffect(() => {
    if (so) {
      setDeliveryDateSo(
        so.delivery_date && so.delivery_date !== ""
          ? format(so.delivery_date, "yyyy-MM-dd")
          : null
      );
      setDueDateSo(
        so.due_date && so.due_date !== ""
          ? format(so.due_date, "yyyy-MM-dd")
          : null
      );
      mutateSpkList({
        offset: 1,
        limit: 100,
        search: so.so_number,
        po_start: "",
        po_end: "",
        delivery_start: "",
        delivery_end: "",
        job_type: [],
        category: [],
        dies: [],
      });
    }
  }, [so]);

  useEffect(() => {
    mutateSoList({
      search: "",
      status: 0,
      so_start: "",
      so_end: "",
      customer: 0,
      shipping: 0,
      po_start: "",
      po_end: "",
      delivery_start: "",
      delivery_end: "",
      due_start: "",
      due_end: "",
      offset: 1,
      limit: 100000,
      is_dropdown: true,
    });
  }, []);

  useEffect(() => {
    setNoDo(
      "DO/" +
        new Date().getFullYear() +
        "/" +
        curDate.toString(36).toUpperCase()
    );
  }, []);

  return (
    <>
      <div className="flex justify-between items-center mb-8">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/do">
            Delivery Order
          </Link>
          <Link underline="hover" color="inherit">
            Create Delivery Order
          </Link>
        </Breadcrumbs>
      </div>
      <p className="text-xl font-bold mb-8">Create Delivery Order</p>

      <p>Nomor Do</p>
      <TextField fullWidth value={noDo} disabled sx={{ marginBottom: 2 }} />

      <p>Delivery Order Date</p>
      <div className="mb-4 border-2 rounded-md w-full flex items-center hover:bg-slate-100">
        <div
          className="flex justify-between items-center h-full w-full cursor-pointer p-2"
          onClick={(e) => setAnchorElCalendar(e.currentTarget)}
        >
          {doDate === null ? (
            <p className="text-gray-300">----/--/--</p>
          ) : (
            <p className="text-gray-300">
              {format(new Date(doDate), "yyyy-MM-dd")}
            </p>
          )}
          <MdCalendarMonth className="w-6 h-6" />
        </div>
      </div>
      <Autocomplete
        sx={{ width: "100%", marginBottom: 2 }}
        disablePortal
        value={so}
        onChange={(_, option) => {
          setSo(option);
        }}
        options={dataSoList ? dataSoList.data : []}
        getOptionLabel={(option) =>
          option.so_number + " - " + option.customer_name
        }
        renderInput={(params) => <TextField {...params} label="No. SO" />}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "reset") {
            setSo(null);
            return;
          }
        }}
      />

      {so ? (
        <>
          {so.shipping_address
            ? so.shipping_address.map((adr) => (
                <TextField
                  fullWidth
                  value={adr}
                  disabled
                  label="Alamat Pengiriman"
                  sx={{ marginBottom: 2 }}
                />
              ))
            : null}
          <TextField
            fullWidth
            value={so.bill_to ? so.bill_to : ""}
            disabled
            label="Alamat Penagihan"
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            value={so.franco ? so.franco : ""}
            disabled
            label="Alamat pengiriman"
            sx={{ marginBottom: 2 }}
          />
        </>
      ) : null}
      <Autocomplete
        sx={{ width: "100%", marginBottom: 2 }}
        disablePortal
        disabled={!so}
        multiple
        value={spkArr}
        onChange={(_, option) => {
          setSpkArr(option);
        }}
        options={dataSpkList && dataSpkList.data ? dataSpkList.data : []}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option.spk_no}
              {...getTagProps({ index })}
            />
          ))
        }
        getOptionLabel={(option) => option.spk_no}
        renderInput={(params) => <TextField {...params} label="No. SPK" />}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "reset") {
            setSpkArr([]);
            return;
          }
        }}
      />

      <p>No. PO</p>
      <TextField
        fullWidth
        value={spkArr.length > 0 ? spkArr[0].po_number : ""}
        disabled
        sx={{ marginBottom: 2 }}
      />

      <p>Nama Customer</p>
      <TextField
        fullWidth
        value={spkArr.length > 0 ? spkArr[0].customer_name : ""}
        disabled
        sx={{ marginBottom: 2 }}
      />

      <p className="text-xl font-bold">Label</p>
      <div className="rounded-md shadow-md mb-4">
        <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
          <table className="w-full">
            <thead className="bg-[#F4F5FF] border-t border-b border-2">
              <th className="p-3 text-left">No</th>
              <th className="p-3 text-left">Label Name</th>
              <th className="p-3 text-left">Qty Plan</th>
              <th className="p-3 text-left">Qty Actual</th>
              <th className="p-3 text-left">Qty Deliver</th>
              <th className="p-3 text-left">Stok In</th>
            </thead>
            <tbody>
              {dataProject && dataProject?.length > 0 && !isPending ? (
                dataProject
                  .filter((item) => item.qty_remainder > 0)
                  .map((item, i) => (
                    <tr key={i}>
                      <td className="p-3 text-left border-b-2">{i + 1}</td>
                      <td className="p-3 text-left border-b-2">
                        {item.label_name}
                      </td>
                      <td className="p-3 text-left border-b-2">
                        {item.qty_order}
                      </td>
                      <td className="p-3 text-left border-b-2">
                        {item.qty_production}
                      </td>
                      <td className="p-3 text-left border-b-2">
                        <TextField
                          fullWidth
                          value={label[i].qty_delivery}
                          onChange={(e) => {
                            if (!isNaN(Number(e.target.value))) {
                              var temp = [...label];
                              temp[i].qty_delivery =
                                Number(e.target.value) > item.qty_remainder
                                  ? item.qty_remainder
                                  : Number(e.target.value);
                              setLabel(temp);
                            }
                          }}
                        />
                      </td>
                      <td className="p-3 text-left border-b-2">
                        {item.qty_remainder === item.qty_order
                          ? item.qty_order - label[i].qty_delivery
                          : 0}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td className="p-3 text-center" colSpan={6}>
                    Tidak ada SPK yang terpilih
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <p>Tanggal Delivery</p>
      <div className="mb-4  w-full border-2 rounded-md w-full flex items-center hover:bg-slate-100">
        <div
          className="flex justify-between items-center h-full cursor-pointer w-full cursor-pointer p-2"
          onClick={(e) => setAnchorElCalendarDelivery(e.currentTarget)}
        >
          {deliveryDate === null ? (
            <p className="text-gray-300">----/--/--</p>
          ) : (
            <p className="text-gray-300">
              {format(new Date(deliveryDate), "yyyy-MM-dd")}
            </p>
          )}
          <MdCalendarMonth className="w-6 h-6" />
        </div>
      </div>

      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        {/* <Select value={shipping} onChange={(e) => setShipping(e.target.value)} > */}
        <Select
          value={shipping}
          onChange={(e) => {
            setShipping(e.target.value);
            if (
              dataShipmentMethod &&
              e.target.value > 0 &&
              dataShipmentMethod.find((item) => item.id === e.target.value)
                .shipment_name !== "Indolabel"
            )
              setPlate("");
          }}
        >
          <MenuItem value={0} disabled>
            Pilih Metode Pengiriman
          </MenuItem>
          {dataShipmentMethod
            ? dataShipmentMethod.map((item) => {
                return (
                  <MenuItem value={item.id}>{item.shipment_name}</MenuItem>
                );
              })
            : null}
          {/* <MenuItem value={-1}>Lainnya</MenuItem> */}
        </Select>
      </FormControl>
      {dataShipmentMethod &&
      dataShipmentMethod.find((item) => item.id === shipping)?.shipment_name ===
        "Indolabel" ? (
        <TextField
          fullWidth
          label="Plat"
          sx={{ marginBottom: 2 }}
          value={plate}
          onChange={(e) => setPlate(e.target.value)}
        />
      ) : null}
      <TextField
        fullWidth
        label="Petugas Pengiriman"
        value={officer}
        onChange={(e) => setOfficer(e.target.value)}
      />

      <p className="mt-8 mb-2 text-red-500 text-right">{errorMessage}</p>
      <div className="flex justify-end w-1/3 gap-2 float-right">
        <button
          type="button"
          className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
          onClick={() => navigate("/do")}
        >
          Cancel
        </button>
        <button
          className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
          disabled={!isFormValid()}
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>

      <ModalInput
        title="Tambah Metode Pengiriman"
        subtitle={errorMessageCreateSm}
        content={
          <>
            <TextField
              fullWidth
              label="Nama Metode Pengiriman Baru"
              value={newSm}
              onChange={(e) => setNewSm(e.target.value)}
            />
          </>
        }
        open={openAddSm}
        setOpen={setOpenAddSm}
        hasButton={true}
        buttonText="Save"
        setTriggerFunc={setIsAddSm}
        isDisable={isPendingCreateSm || newSm === ""}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendar}
        setAnchorElCalendarStart={setAnchorElCalendar}
        startDate={doDate}
        setStartDate={setDoDate}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendarDelivery}
        setAnchorElCalendarStart={setAnchorElCalendarDelivery}
        startDate={deliveryDate}
        setStartDate={setDeliveryDate}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendarDeliverySo}
        setAnchorElCalendarStart={setAnchorElCalendarDeliverySo}
        startDate={deliveryDateSo}
        setStartDate={setDeliveryDateSo}
      />
      <SingleCalendar
        anchorElCalendarStart={anchorElCalendarDueSo}
        setAnchorElCalendarStart={setAnchorElCalendarDueSo}
        startDate={dueDateSo}
        setStartDate={setDueDateSo}
      />
    </>
  );
};

export default AddDeliveryOrder;
