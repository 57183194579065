import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import {
  getMaterialDetailDropdown,
  getMaterialList,
} from "../../../services/materialApi";
import { Autocomplete, FormGroup, FormLabel, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { setStock, getStockList } from "../../../services/stockAdjustment";

const validationSchema = yup.object({
  material_id: yup.number().min(1),
  po_id: yup.number().min(1),
  adjust_reason: yup.string().min(1),
  stock_on_hands: yup.number().min(1),
  current_stock: yup.number().min(0),
});

const AddStockAdjustment = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [filter, setFilter] = useState("");
  const [status, setStatus] = useState("");

  const formik = useFormik({
    initialValues: {
      material_id: 0,
      po_id: 0,
      adjust_reason: "",
      stock_on_hands: 0,
      current_stock: 0,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      mutateStock(values);
    },
  });

  const { data: dataMaterial } = useQuery({
    queryKey: ["material-list"],
    queryFn: () =>
      getMaterialList({
        search: "",
        filter: "",
        offset: 1,
        limit: 10000,
      }),
  });

  const { data: dataDetailMaterial } = useQuery({
    queryKey: ["detail-material", formik.values.material_id],
    queryFn: () => getMaterialDetailDropdown(formik.values.material_id),
    enabled: formik.values.material_id !== null,
  });

  const {
    mutate: mutateStockList,
    data: dataStock,
    isLoading: isLoadingStockList,
  } = useMutation({
    mutationFn: getStockList,
  });

  useEffect(() => {
    const body = {
      status: status,
    };
    mutateStockList(body);
  }, [status]);

  useEffect(() => {
    if (
      formik.values.po_id &&
      dataDetailMaterial &&
      formik.values.material_id
    ) {
      const selectedPO = dataDetailMaterial.history.find(
        (item) => item.id === formik.values.po_id
      );
      if (selectedPO) {
        formik.setFieldValue("current_stock", selectedPO.final_stock);
      }
    }
  }, [formik.values.po_id, dataDetailMaterial, formik.values.material_id]);

  const { mutate: mutateStock, isPending } = useMutation({
    mutationFn: setStock,
    onSuccess: () => navigate("/stock-adjustment"),
    onError: (err) => setErrorMessage(err.message),
  });

  const statusOptions = dataStock
    ? [...new Set(dataStock.data.map((item) => item.status))]
    : [];

  const filteredStatusOptions = statusOptions
    .filter((option) => option.toLowerCase().includes(filter.toLowerCase()))
    .slice(0, 5);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FormGroup sx={{ width: "100%" }}>
          <FormLabel>Material</FormLabel>
          <Autocomplete
            sx={{ width: "100%", marginBottom: 2 }}
            disablePortal
            value={
              formik.values.material_id && dataMaterial
                ? dataMaterial.materials.find(
                    (item) => item.id === formik.values.material_id
                  )
                : null
            }
            onChange={(_, option) => {
              formik.setFieldValue("material_id", option ? option.id : null);
              formik.setFieldValue("po_id", null);
            }}
            options={dataMaterial ? dataMaterial.materials : []}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} placeholder="Pilih Material" />
            )}
          />
        </FormGroup>
        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>PO Number</FormLabel>
          <Autocomplete
            sx={{ width: "100%" }}
            disablePortal
            disabled={!formik.values.material_id}
            value={
              formik.values.po_id &&
              dataDetailMaterial &&
              formik.values.material_id
                ? dataDetailMaterial.history.find(
                    (item) => item.id === formik.values.po_id
                  )
                : null
            }
            onChange={(_, option) => {
              formik.setFieldValue("po_id", option ? option.id : null);
              formik.setFieldValue("stock_on_hands", 0);
              if (option) {
                formik.setFieldValue("current_stock", option.final_stock);
              } else {
                formik.setFieldValue("current_stock", 0);
              }
            }}
            options={dataDetailMaterial ? dataDetailMaterial.history : []}
            getOptionLabel={(option) => option.po_number}
            renderInput={(params) => (
              <TextField {...params} placeholder="Pilih PO" />
            )}
          />
        </FormGroup>
        <FormGroup sx={{ width: "100%", marginBottom: 4 }}>
          <FormLabel>Alasan Penyesuaian</FormLabel>
          <Autocomplete
            freeSolo
            options={filteredStatusOptions}
            inputValue={filter}
            onInputChange={(event, newInputValue) => {
              setFilter(newInputValue);
              formik.setFieldValue("adjust_reason", newInputValue);
            }}
            onChange={(event, newValue) => {
              setStatus(newValue || "");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Pilih atau ketik alasan penyesuaian"
                disabled={isLoadingStockList}
              />
            )}
          />
        </FormGroup>
        <div className="mb-4 flex gap-4">
          <TextField
            disabled
            fullWidth
            value={
              dataDetailMaterial && dataDetailMaterial.detail.expired_date
                ? format(dataDetailMaterial.detail.expired_date, "dd MMMM yyyy")
                : ""
            }
            label="Tanggal Kadaluwarsa"
          />
          <TextField
            disabled
            fullWidth
            value={
              formik.values.po_id &&
              dataDetailMaterial &&
              formik.values.material_id
                ? dataDetailMaterial.history.find(
                    (item) => item.id === formik.values.po_id
                  ).final_stock
                : 0
            }
            label="Stok Saat Ini"
          />
        </div>
        <FormGroup sx={{ width: "100%", marginBottom: 4 }}>
          <FormLabel>Stock On Hands</FormLabel>
          <TextField
            fullWidth
            value={formik.values.stock_on_hands}
            disabled={!formik.values.po_id}
            onChange={(e) => {
              if (!isNaN(Number(e.target.value)))
                formik.setFieldValue(
                  "stock_on_hands",
                  dataDetailMaterial.history.find(
                    (item) => item.id === formik.values.po_id
                  ).final_stock >= Number(e.target.value)
                    ? Number(e.target.value)
                    : dataDetailMaterial.history.find(
                        (item) => item.id === formik.values.po_id
                      ).final_stock
                );
            }}
          />
        </FormGroup>

        <p className="text-red-500 mb-4 text-right m-4">{errorMessage}</p>
        <div className="w-full">
          <div className="flex justify-end w-1/3 gap-2 float-right">
            <button
              type="button"
              className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
              onClick={() => navigate("/stock-adjustment")}
            >
              Cancel
            </button>
            <button
              className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
              disabled={!formik.isValid || isPending}
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddStockAdjustment;
