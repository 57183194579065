import { InputAdornment, TextField } from "@mui/material";

const SpkCardLabelInfo = ({ curProj, setCurProject, formik, i, isDetail }) => {
  const changeValue = (keyName, val) => {
    var tempProject = JSON.parse(JSON.stringify(curProj));
    tempProject.label[0][keyName] = val;
    formik.setFieldValue(`project[${i}].label[0][${keyName}]`, val);
    setCurProject(tempProject);
  };
  return (
    <>
      <hr className="my-2" />
      <div className="rounded-md shadow-md">
        <div className="block overflow-x-auto w-full rounded-md">
          <table className="w-full">
            <thead className="bg-[#18479D] text-white border-t border-b border-2">
              <th className="p-3 text-left">1 Lembar</th>
              <th className="p-3 text-left">1 Ikat</th>
              <th className="p-3 text-left">1 Pack</th>
              <th className="p-3 text-left">1 Roll</th>
              <th className="p-3 text-left">Core Dia</th>
            </thead>
            <tbody>
              <tr className="border-t border-l border-2">
                <td className="p-3">
                  {isDetail ? (
                    curProj.label[0].lembar
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">PCS</InputAdornment>
                        ),
                      }}
                      value={curProj.label[0].lembar}
                      onChange={(e) => {
                        if (!isNaN(Number(e.target.value))) {
                          changeValue("lembar", Number(e.target.value));
                        }
                      }}
                    />
                  )}
                </td>
                <td className="p-3">
                  {isDetail ? (
                    curProj.label[0].ikat
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Lembar</InputAdornment>
                        ),
                      }}
                      value={curProj.label[0].ikat}
                      onChange={(e) => {
                        if (!isNaN(Number(e.target.value))) {
                          changeValue("ikat", Number(e.target.value));
                        }
                      }}
                    />
                  )}
                </td>
                <td className="p-3">
                  {isDetail ? (
                    curProj.label[0].pack
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">PCS</InputAdornment>
                        ),
                      }}
                      value={curProj.label[0].pack}
                      onChange={(e) => {
                        if (!isNaN(Number(e.target.value))) {
                          changeValue("pack", Number(e.target.value));
                        }
                      }}
                    />
                  )}
                </td>
                <td className="p-3">
                  {isDetail ? (
                    curProj.label[0].roll
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">PCS</InputAdornment>
                        ),
                      }}
                      value={curProj.label[0].roll}
                      onChange={(e) => {
                        if (!isNaN(Number(e.target.value))) {
                          changeValue("roll", Number(e.target.value));
                        }
                      }}
                    />
                  )}
                </td>
                <td className="p-3">
                  {isDetail ? (
                    curProj.label[0].core_dia
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">mm</InputAdornment>
                        ),
                      }}
                      value={curProj.label[0].core_dia}
                      onChange={(e) => {
                        if (!isNaN(Number(e.target.value))) {
                          changeValue("core_dia", Number(e.target.value));
                        }
                      }}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SpkCardLabelInfo;
