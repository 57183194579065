import {
  CircularProgress,
  FormControl,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  TextField,
} from "@mui/material";
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useRef, useState } from "react";
import { IoMdAddCircleOutline, IoMdDownload } from "react-icons/io";
import { Search } from "@mui/icons-material";
import { useMutation, useQuery } from "@tanstack/react-query";
import ModalInput from "../../common/ModalInput";
import { MdCalendarMonth } from "react-icons/md";
import RangeCalendar from "../../common/RangeCalendar";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  getSupplierPoList,
  updatePoStatus,
} from "../../../services/supplierPoApi";
import PrintPo from "./PrintPo";
import PrintReleasePo from "./PrintReleasePo";
import { FaPencilAlt } from "react-icons/fa";

const SupplierPoList = () => {
  const date = new Date();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [selectedDates, setSelectedDates] = useState(["", ""]);
  const [searchStartDate, setSearchStartDate] = useState(date);
  const [searchEndDate, setSearchEndDate] = useState(null);
  const [selectedPo, setSelectedPO] = useState(null);
  const [downloadPo, setDownloadPo] = useState(false);
  const [downloadReleasePo, setDownloadReleasePo] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [openChange, setOpenChange] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [newStatus, setNewStatus] = useState(0);
  const [filter, setFilter] = useState(0);

  const { data, isFetching: isFetchingList } = useQuery({
    queryKey: [
      "po-list",
      search,
      filter,
      page,
      fetchLimit,
      selectedDates[1],
      refetch,
    ],
    queryFn: () =>
      getSupplierPoList({
        inv: filter,
        search: search,
        start: selectedDates[0],
        end: selectedDates[1],
        offset: page,
        limit: fetchLimit,
      }),
  });

  const { mutate: mutateUpdate } = useMutation({
    mutationFn: updatePoStatus,
    onSuccess: () => {
      setRefetch(true);
      setIsChange(false);
      setOpenChange(false);
    },
  });

  const handleChangeStatus = () => {
    const body = {
      id: selectedPo.id,
      status: newStatus,
    };
    mutateUpdate(body);
  };

  useEffect(() => {
    if (isChange) handleChangeStatus();
  }, [isChange]);

  useEffect(() => {
    setPage(1);
  }, [fetchLimit, search, selectedDates, filter]);

  useEffect(() => {
    if (!downloadPo) setSelectedPO(null);
  }, [downloadPo]);

  useEffect(() => {
    if (!downloadReleasePo) setSelectedPO(null);
  }, [downloadReleasePo]);

  useEffect(() => {
    if (refetch) setRefetch(false);
  }, [data]);

  return (
    <div>
      <div className="flex mb-4 gap-2">
        <TextField
          fullWidth
          placeholder="No. invoice customer"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <div className="w-full border-2 rounded-md flex items-center hover:bg-slate-100">
          <div
            className="flex justify-between items-center h-full w-full cursor-pointer px-2"
            onClick={(e) => setAnchorElCalendar(e.currentTarget)}
          >
            {selectedDates[0] === "" ? (
              <p className="text-gray-300">----/--/--</p>
            ) : (
              <p className="text-gray-300">{selectedDates[0]}</p>
            )}
            <p className="text-gray-300"> s/d </p>
            {selectedDates[1] === "" ? (
              <p className="text-gray-300">----/--/--</p>
            ) : (
              <p className="text-gray-300">{selectedDates[1]}</p>
            )}
            <MdCalendarMonth className="w-6 h-6" />
          </div>
        </div>
        <FormControl fullWidth>
          <Select value={filter} onChange={(e) => setFilter(e.target.value)}>
            <MenuItem value={0}>Semua Status</MenuItem>
            <MenuItem value={1}>Sudah Diinvoice</MenuItem>
            <MenuItem value={2}>Belum Diinvoice</MenuItem>
            <MenuItem value={3}>Parsial</MenuItem>
          </Select>
        </FormControl>

        <button
          onClick={() => navigate("/sv-po/create-po")}
          className="w-1/4 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
        >
          <p>Create PO</p>
          <IoMdAddCircleOutline className="text-[18px]" />
        </button>
      </div>
      <div className="mb-4 flex gap-2">
        <p className="">Summary of Grand Total:</p>
        {data && !isFetchingList ? (
          <p className="font-bold">Rp{data?.final_total.toLocaleString()}</p>
        ) : (
          <p className="font-bold">Rpxxx</p>
        )}
      </div>

      <>
        <div className="rounded-md shadow-md">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <th className="p-3 text-left">No</th>
                <th className="p-3 text-left">Tanggal PO</th>
                <th className="p-3 text-left">No. PO</th>
                <th className="p-3 text-left">Supplier/Vendor</th>
                <th className="p-3 text-left">Catatan</th>
                <th className="p-3 text-left">Qty</th>
                <th className="p-3 text-left">Qty Receive</th>
                <th className="p-3 text-left">Grand Total</th>
                <th className="p-3 text-left">Tanggal Kedatangan</th>
                <th className="p-3 text-left">Status</th>
                <th className="p-3 text-left w-[15%]">Action</th>
              </thead>
              <tbody>
                {data &&
                data.data &&
                data.data.length > 0 &&
                !isFetchingList ? (
                  data.data.map((item, i) => {
                    return (
                      <tr className="border-t border-l border-2" key={i}>
                        <td className="p-3">
                          {(page - 1) * fetchLimit + (i + 1)}
                        </td>
                        <td className="p-3">
                          {format(item.po_date, "yyyy-MM-dd")}
                        </td>
                        <td
                          className="p-3 text-blue-500 hover:text-blue-300 cursor-pointer"
                          onClick={() => navigate(`/sv-po/detail/${item.id}`)}
                        >
                          {item.po_number}
                        </td>
                        <td className="p-3">{item.supplier_name}</td>
                        <td className="p-3">{item.notes}</td>
                        <td className="p-3">{item.quantity}</td>
                        <td className="p-3">{item.quantity_receive}</td>
                        <td className="p-3">
                          Rp
                          {Number(
                            parseFloat(item.grand_total).toFixed(4)
                          ).toLocaleString()}
                        </td>
                        <td className="p-3">
                          {format(item.arrive_date, "yyyy-MM-dd")}
                        </td>
                        <td className="p-3">
                          <FormControl fullWidth>
                            <Select
                              value={item.status}
                              onChange={(e) => {
                                setOpenChange(true);
                                setSelectedPO(item);
                                setNewStatus(e.target.value);
                              }}
                            >
                              <MenuItem value={1}>Sudah Diinvoice</MenuItem>
                              <MenuItem value={2}>Belum Diinvoice</MenuItem>
                              <MenuItem value={3}>Parsial</MenuItem>
                            </Select>
                          </FormControl>
                        </td>
                        <td className="p-3 w-[15%]">
                          <div className="flex gap-2 items-center">
                            <IoMdDownload
                              onClick={() => {
                                setOpenDownload(true);
                                setSelectedPO(item);
                              }}
                              className="text-blue-500 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                            />
                            <FaPencilAlt
                              onClick={() => {
                                if (item.status === 2)
                                  navigate(`/sv-po/edit/${item.id}`);
                              }}
                              className={`border w-[24px] h-full rounded-md p-1 ${
                                item.status === 2
                                  ? "text-blue-500 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                                  : "text-gray-300"
                              }`}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : !isFetchingList ? (
                  <tr className="border-t border-l border-2">
                    <td colSpan={11} className="py-3 text-center">
                      Data po tidak ditemukan
                    </td>
                  </tr>
                ) : (
                  <tr className="border-t border-l border-2">
                    <td colSpan={11} className="py-3 text-center">
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {data && data.total > 0 ? (
            <div className="w-full flex justify-between pb-8 px-8">
              <button
                onClick={() => {
                  if (page > 1) setPage(page - 1);
                }}
                disabled={page === 1}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowLeftLong /> Previous
              </button>
              <Pagination
                hideNextButton
                hidePrevButton
                shape="rounded"
                page={page}
                count={Math.ceil(data.total / fetchLimit)}
                onChange={(e, page) => setPage(page)}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
              <button
                onClick={() => {
                  if (page < Math.ceil(data.total / fetchLimit))
                    setPage(page + 1);
                }}
                disabled={page === Math.ceil(data.total / fetchLimit)}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowRightLong /> Next
              </button>
            </div>
          ) : null}
        </div>
        {data && data.total > 0 ? (
          <div className="mt-4 text-xs flex gap-3 items-center">
            <p>Show Table</p>
            <p
              onClick={() => setFetchLimit(10)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 10 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              10
            </p>
            <p
              onClick={() => setFetchLimit(50)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 50 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              50
            </p>
            <p
              onClick={() => setFetchLimit(100)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 100 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              100
            </p>
          </div>
        ) : null}
      </>

      {selectedPo && openDownload ? (
        <>
          <PrintPo
            isDownload={downloadPo}
            setIsDownload={setDownloadPo}
            id={selectedPo.id}
          />
          <PrintReleasePo
            isDownload={downloadReleasePo}
            setIsDownload={setDownloadReleasePo}
            id={selectedPo.id}
          />
        </>
      ) : null}

      <ModalInput
        title="Download PO"
        subtitle="Pilih dokumen yang ingin anda download"
        content={
          <div className="flex gap-2">
            <div
              className="w-full border-blue-500 text-blue-500 cursor-pointer hover:bg-slate-100 border-2 rounded-md text-center p-2"
              onClick={() => setDownloadPo(true)}
            >
              PO
            </div>
            <div
              className="w-full border-blue-500 text-blue-500 cursor-pointer hover:bg-slate-100 border-2 rounded-md text-center p-2"
              onClick={() => setDownloadReleasePo(true)}
            >
              Release PO
            </div>
          </div>
        }
        open={openDownload}
        setOpen={setOpenDownload}
        hasButton={false}
        setTriggerFunc={() => {}}
        isDisable={false}
      />

      <ModalInput
        title="Update Status PO?"
        subtitle={`Apakah anda yakin ingin mengubah status PO menjadi ${
          newStatus === 1
            ? "sudah diinvoice"
            : newStatus === 2
            ? "belum diinvoice"
            : "parsial"
        }?`}
        content={<></>}
        open={openChange}
        setOpen={setOpenChange}
        buttonText="Yes"
        hasButton={true}
        setTriggerFunc={setIsChange}
        isDisable={false}
      />

      <RangeCalendar
        anchorElCalendar={anchorElCalendar}
        setAnchorElCalendar={setAnchorElCalendar}
        setSelectedDates={setSelectedDates}
        searchStartDate={searchStartDate}
        searchEndDate={searchEndDate}
        setSearchStartDate={setSearchStartDate}
        setSearchEndDate={setSearchEndDate}
      />
    </div>
  );
};

export default SupplierPoList;
