import { createSlice } from "@reduxjs/toolkit";
import cookie from "js-cookie";
import axios from "axios";

function createCookie(name, value, minutes) {
  if (minutes) {
    var date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    var expires = "; expires=" + date.toGMTString();
  } else {
    expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

const initialState = {
  accessToken: "",
  accessExpired: "",
  renewToken: "",
  renewExpired: "",
  img_profile: "",
  fullname: "",
  email: "",
  role: 0,
  inventory: [],
  working_order: [],
  invoice: [],
  gear: [],
  die_cut: [],
  equipment: [],
  retur_customer: [],
  supplier: [],
  quotation: [],
  customer: [],
  user_management: [],
  retur_supplier: [],
  mps_flexo: [],
  mps: [],
  plate_usage: [],
  finishgood: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.accessToken = payload.accessToken;
      state.accessExpired = payload.accessExpired;
      state.renewToken = payload.renewToken;
      state.renewExpired = payload.renewExpired;
      state.img_profile = payload.img_profile;
      state.fullname = payload.fullname;
      state.role = payload.role;
      state.inventory = payload.inventory;
      state.working_order = payload.working_order;
      state.invoice = payload.invoice;
      state.gear = payload.gear;
      state.die_cut = payload.die_cut;
      state.equipment = payload.equipment;
      state.retur_customer = payload.retur_customer;
      state.supplier = payload.supplier;
      state.quotation = payload.quotation;
      state.customer = payload.customer;
      state.retur_supplier = payload.retur_supplier;
      state.mps_flexo = payload.mps_flexo;
      state.mps = payload.mps;
      state.plate_usage = payload.plate_usage;
      state.user_management = payload.user_management;
      state.finishgood = payload.finishgood;
      createCookie("accessToken", payload.accessToken, 60);
      createCookie("accessExpired", payload.accessExpired);
      createCookie("renewToken", payload.renewToken);
      createCookie("renewExpired", payload.renewExpired);
      localStorage.setItem("img_profile", payload.img_profile);
      localStorage.setItem("fullname", payload.fullname);
      localStorage.setItem("email", payload.email);
      localStorage.setItem("role", payload.role);
      localStorage.setItem("inventory", payload.inventory);
      localStorage.setItem("working_order", payload.working_order);
      localStorage.setItem("invoice", payload.invoice);
      localStorage.setItem("gear", payload.gear);
      localStorage.setItem("die_cut", payload.die_cut);
      localStorage.setItem("equipment", payload.equipment);
      localStorage.setItem("retur_customer", payload.retur_customer);
      localStorage.setItem("supplier", payload.supplier);
      localStorage.setItem("quotation", payload.quotation);
      localStorage.setItem("customer", payload.customer);
      localStorage.setItem("user_management", payload.user_management);
      localStorage.setItem("retur_supplier", payload.retur_supplier);
      localStorage.setItem("mps_flexo", payload.mps_flexo);
      localStorage.setItem("mps", payload.mps);
      localStorage.setItem("plate_usage", payload.plate_usage);
      localStorage.setItem("finishgood", payload.finishgood);
    },

    renewTokenUser: () => {
      const refreshToken = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_ERP}erp/renew-token`,
            {
              headers: {
                Authorization: "Bearer " + cookie.get("renewToken"),
              },
            }
          );
          // state.accessToken = response.data.detail.token;
          // state.accessExpired = response.data.detail.token_expired;
          createCookie("accessToken", response.data.detail.token, 60);
          createCookie("accessExpired", response.data.detail.token_expired);
        } catch (error) {
          //
        }
      };
      refreshToken();
    },

    logout: (state) => {
      state.accessToken = null;
      state.accessExpired = null;
      state.renewToken = null;
      state.renewExpired = null;
      cookie.remove("accessToken");
      cookie.remove("accessExpired");
      cookie.remove("renewToken");
      cookie.remove("renewExpired");
      localStorage.removeItem("img_profile");
      localStorage.removeItem("fullname");
      localStorage.removeItem("email");
      localStorage.removeItem("role");
      localStorage.removeItem("inventory");
      localStorage.removeItem("working_order");
      localStorage.removeItem("invoice");
      localStorage.removeItem("gear");
      localStorage.removeItem("die_cut");
      localStorage.removeItem("equipment");
      localStorage.removeItem("retur_customer");
      localStorage.removeItem("supplier");
      localStorage.removeItem("quotation");
      localStorage.removeItem("customer");
      localStorage.removeItem("retur_supplier");
      localStorage.removeItem("mps_flexo");
      localStorage.removeItem("mps");
      localStorage.removeItem("plate_usage");
      localStorage.removeItem("finishgood");
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, switchUser, renewTokenUser, logout } =
  authSlice.actions;

export default authSlice.reducer;
