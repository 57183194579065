import React, { useEffect, useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { Avatar, Tooltip } from "@mui/material";

import { FiLogOut } from "react-icons/fi";
import { FaBars } from "react-icons/fa";
import Logo from "../../assets/logo.png";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/features/auth/authSlice";

const activeClass = "bg-[#F4F5FF] w-full rounded-md block hover:bg-[#dadbe3]";

const SidebarComponent = () => {
  const { toggleSidebar, broken, collapsed, toggled } = useProSidebar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const img = localStorage.getItem("profile_img");
  const fullName = localStorage.getItem("fullname");
  const email = localStorage.getItem("email");
  const inventory = localStorage.getItem("inventory");
  const gear = localStorage.getItem("gear");
  const diecut = localStorage.getItem("die_cut");
  const equipment = localStorage.getItem("equipment");
  const returCustomer = localStorage.getItem("retur_customer");
  const returSupplier = localStorage.getItem("retur_supplier");
  const supplier = localStorage.getItem("supplier");
  const workingOrder = localStorage.getItem("working_order");
  const customer = localStorage.getItem("customer");
  const userManagement = localStorage.getItem("user_management");
  const invoice = localStorage.getItem("invoice");
  const quotation = localStorage.getItem("quotation");
  const mpsFlexo = localStorage.getItem("mps_flexo");
  const mps = localStorage.getItem("mps");
  const plateUsage = localStorage.getItem("plate_usage");
  const finishgood = localStorage.getItem("finishgood");

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  };

  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children:
        name.split(" ").length === 1
          ? `${name.split(" ")[0][0]}`
          : `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className="flex min-h-screen h-[100%] text-[#526581]">
      <Sidebar
        breakPoint="lg"
        backgroundColor="white"
        className="shadow-r-md mb-24"
        width="310px"
      >
        <div className="flex flex-column flex-wrap">
          <img
            src={Logo}
            alt="logo"
            className="my-6 ml-[20px] h-[10%] w-[25%]"
          />
          <Menu>
            {inventory?.length > 0 ? (
              <SubMenu label="Inventory Management" defaultOpen={false}>
                {inventory.includes(1) ? (
                  <NavLink
                    to="/material"
                    className={({ isActive }) => (isActive ? activeClass : "")}
                  >
                    <MenuItem className="pl-8">Material</MenuItem>
                  </NavLink>
                ) : null}
                {inventory.includes(2) ? (
                  <NavLink
                    to="/do"
                    className={({ isActive }) => (isActive ? activeClass : "")}
                  >
                    <MenuItem className="pl-8">Delivery Order</MenuItem>
                  </NavLink>
                ) : null}
                {inventory.includes(3) ? (
                  <NavLink
                    to="/stock-adjustment"
                    className={({ isActive }) => (isActive ? activeClass : "")}
                  >
                    <MenuItem className="pl-8">Stock Adjustment</MenuItem>
                  </NavLink>
                ) : null}
              </SubMenu>
            ) : null}
            {gear?.length > 0 ? (
              <NavLink
                to="/gear-management"
                className={({ isActive }) => (isActive ? activeClass : "")}
              >
                <MenuItem>Gear Management</MenuItem>
              </NavLink>
            ) : null}
            {diecut?.length > 0 ? (
              <NavLink
                to="/diecut-management"
                className={({ isActive }) => (isActive ? activeClass : "")}
              >
                <MenuItem>Die Cut Management</MenuItem>
              </NavLink>
            ) : null}
            {equipment?.length > 0 ? (
              <NavLink
                to="/equipment-management"
                className={({ isActive }) => (isActive ? activeClass : "")}
              >
                <MenuItem>Equipment Management</MenuItem>
              </NavLink>
            ) : null}
            {finishgood && finishgood?.length > 0 ? (
              <NavLink
                to="/finishgood"
                className={({ isActive }) => (isActive ? activeClass : "")}
              >
                <MenuItem>Finishgood Inventory</MenuItem>
              </NavLink>
            ) : null}
            {returCustomer?.length > 0 ? (
              <NavLink
                to="/retur-customer"
                className={({ isActive }) => (isActive ? activeClass : "")}
              >
                <MenuItem>Retur Customer</MenuItem>
              </NavLink>
            ) : null}
            {returSupplier && returSupplier?.length > 0 ? (
              <NavLink
                to="/retur-supplier"
                className={({ isActive }) => (isActive ? activeClass : "")}
              >
                <MenuItem>Retur Supplier</MenuItem>
              </NavLink>
            ) : null}
            {supplier?.length > 0 ? (
              <NavLink
                to="/supplier"
                className={({ isActive }) => (isActive ? activeClass : "")}
              >
                <MenuItem>Supplier/Vendor</MenuItem>
              </NavLink>
            ) : null}
            <SubMenu label="Quotation Management" defaultOpen={false}>
              {quotation.includes(1) ? (
                <NavLink
                  to="/quotation"
                  className={({ isActive }) => (isActive ? activeClass : "")}
                >
                  <MenuItem className="pl-8">Quotation</MenuItem>
                </NavLink>
              ) : null}
              {quotation.includes(2) ? (
                <NavLink
                  to="/costing"
                  className={({ isActive }) => (isActive ? activeClass : "")}
                >
                  <MenuItem className="pl-8">Service Costing</MenuItem>
                </NavLink>
              ) : null}
            </SubMenu>
            {workingOrder?.length > 0 ? (
              <SubMenu label="Working Order Management" defaultOpen={false}>
                {workingOrder.includes(1) ? (
                  <NavLink
                    to="/sales-order"
                    className={({ isActive }) => (isActive ? activeClass : "")}
                  >
                    <MenuItem className="pl-8">Sales Order</MenuItem>
                  </NavLink>
                ) : null}
                {workingOrder.includes(2) ? (
                  <NavLink
                    to="/spk"
                    className={({ isActive }) => (isActive ? activeClass : "")}
                  >
                    <MenuItem className="pl-8">
                      Surat Perintah Kerja (SPK)
                    </MenuItem>
                  </NavLink>
                ) : null}
                {workingOrder.includes(3) ? (
                  <NavLink
                    to="/schedule"
                    className={({ isActive }) => (isActive ? activeClass : "")}
                  >
                    <MenuItem className="pl-8">Schedule</MenuItem>
                  </NavLink>
                ) : null}
              </SubMenu>
            ) : null}
            {mpsFlexo && mpsFlexo.length > 0 ? (
              <NavLink
                to="/mps-flexo"
                className={({ isActive }) => (isActive ? activeClass : "")}
              >
                <MenuItem>MPS Flexo</MenuItem>
              </NavLink>
            ) : null}
            {mps && mps.length > 0 ? (
              <>
                <NavLink
                  to="/mps-flexible"
                  className={({ isActive }) => (isActive ? activeClass : "")}
                >
                  <MenuItem>MPS Flexible</MenuItem>
                </NavLink>
                <NavLink
                  to="/mps-platbet"
                  className={({ isActive }) => (isActive ? activeClass : "")}
                >
                  <MenuItem>MPS Platbet</MenuItem>
                </NavLink>
              </>
            ) : null}
            {plateUsage && plateUsage.length > 0 ? (
              <NavLink
                to="/plat-usage"
                className={({ isActive }) => (isActive ? activeClass : "")}
              >
                <MenuItem>Plat Usage</MenuItem>
              </NavLink>
            ) : null}
            {customer.length > 0 ? (
              <NavLink
                to="/customer-management"
                className={({ isActive }) => (isActive ? activeClass : "")}
              >
                <MenuItem>Customer Management</MenuItem>
              </NavLink>
            ) : null}
            {userManagement.length > 0 ? (
              <NavLink
                to="/user-management"
                className={({ isActive }) => (isActive ? activeClass : "")}
              >
                <MenuItem>User Management</MenuItem>
              </NavLink>
            ) : null}
            {invoice.length > 0 ? (
              <SubMenu label="Invoice Bill" defaultOpen={false}>
                {invoice.includes(1) ? (
                  <NavLink
                    to="/sv-po"
                    className={({ isActive }) => (isActive ? activeClass : "")}
                  >
                    <MenuItem className="pl-8">Supplier PO</MenuItem>
                  </NavLink>
                ) : null}
                {invoice.includes(2) ? (
                  <NavLink
                    to="/sv-invoice"
                    className={({ isActive }) => (isActive ? activeClass : "")}
                  >
                    <MenuItem className="pl-8">Supplier Invoice</MenuItem>
                  </NavLink>
                ) : null}
                {invoice.includes(3) ? (
                  <NavLink
                    to="/customer-invoice"
                    className={({ isActive }) => (isActive ? activeClass : "")}
                  >
                    <MenuItem className="pl-8">Customer Invoice</MenuItem>
                  </NavLink>
                ) : null}
              </SubMenu>
            ) : null}
          </Menu>
          <div
            className={`bg-gray-200 grid grid-cols-5 p-6 w-[309.5px] bottom-0 left-0 ${
              !broken || (broken && toggled) ? "fixed" : "hidden"
            }`}
          >
            <div className="w-fit flex items-center">
              {img && img !== "" ? (
                <img
                  src={Logo}
                  alt="profile"
                  className="w-10 h-10 object-cover rounded-full"
                />
              ) : (
                <Avatar {...stringAvatar(fullName !== null ? fullName : "")} />
              )}
            </div>
            <div className="break-all col-span-3">
              <div className="flex flex-col text-[12px] cursor-default">
                <p className="font-bold text-black">{fullName}</p>
                <p className="text-gray-500">{email}</p>
              </div>
            </div>
            <div className="flex items-center">
              <Tooltip title="Logout">
                <div
                  className="ml-[10px] hover:cursor-pointer hover:text-[#0d6efd] text-gray-500"
                  onClick={handleLogout}
                >
                  <FiLogOut className="text-[30px]" />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </Sidebar>
      <main>
        <div style={{ display: "flex", padding: 10 }}>
          {broken ? (
            <button
              className="sb-button fixed z-30 top-[15px] right-5"
              onClick={() => toggleSidebar()}
            >
              <FaBars />
            </button>
          ) : null}
        </div>
      </main>
    </div>
  );
};

export default SidebarComponent;
