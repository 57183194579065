const SpkCardMaterial = ({ proj }) => {
  return (
    <>
      <hr className="my-2" />
      <div className="rounded-md shadow-md">
        <div className="block overflow-x-auto w-full rounded-md">
          <table className="w-full">
            <thead className="bg-[#18479D] text-white border-t border-b border-2">
              <th className="p-3 text-left">No</th>
              <th className="p-3 text-left">Label Name</th>
              <th className="p-3 text-left">Material Name</th>
              <th className="p-3 text-left">Size</th>
              <th className="p-3 text-left">Consumption</th>
            </thead>
            <tbody>
              {proj.material.map((mat, i) => (
                <tr className="border-t border-l border-2" key={i}>
                  <td className="p-3">{i + 1}</td>
                  <td className="p-3">{mat.label_name}</td>
                  <td className="p-3">{mat.material_name}</td>
                  <td className="p-3">{mat.size}</td>
                  <td className="p-3">
                    {Number(parseFloat(mat.consumption).toFixed(4))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SpkCardMaterial;
