import {
  CircularProgress,
  FormControl,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { FaArrowRightLong, FaArrowLeftLong, FaTrash } from "react-icons/fa6";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import { IoPencilOutline } from "react-icons/io5";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Search } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import ModalInput from "../../common/ModalInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { MdCalendarMonth } from "react-icons/md";
import RangeCalendar from "../../common/RangeCalendar";
import {
  createSupplier,
  deleteSupplier,
  getSupplierList,
} from "../../../services/supplierApi";
import { format } from "date-fns";
import AddSupplierModalContent from "./AddSupplierModalContent";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object({
  category: yup.string(),
  supplier_name: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  address_hq: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  address_manufacturer: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  phone: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  is_pkp: yup.boolean(),
  npwp: yup
    .string()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  top: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  lead_time: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  pic_name: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  pic_phone: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  pic_email: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  join_date: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
});

const SupplierList = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(0);
  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [refetchList, setRefetchList] = useState(true);
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [selectedDates, setSelectedDates] = useState(["", ""]);
  const [searchStartDate, setSearchStartDate] = useState(null);
  const [searchEndDate, setSearchEndDate] = useState(null);

  const formik = useFormik({
    initialValues: {
      category: "",
      supplier_name: "",
      address_hq: "",
      address_manufacturer: "",
      phone: "",
      is_pkp: false,
      npwp: "",
      top: "",
      lead_time: "",
      pic_name: "",
      pic_phone: "",
      pic_email: "",
      join_date: "",
    },
    validationSchema,
    validateOnMount: true,
  });

  const {
    mutate: mutateSupplierList,
    data,
    isPending: isPendingList,
  } = useMutation({
    mutationFn: getSupplierList,
    onSuccess: () => {},
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  const { mutate: mutateNewSupplier, isPending: isPendingAdd } = useMutation({
    mutationFn: createSupplier,
    onSuccess: () => {
      setIsAdd(false);
      setOpenAdd(false);
      setRefetchList(true);
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  const { mutate: mutateDeleteSupplier } = useMutation({
    mutationFn: deleteSupplier,
    onSuccess: () => {
      setErrorMessage("");
      setOpenDelete(false);
      setRefetchList(true);
      setIsDelete(false);
    },
  });

  useEffect(() => {
    if (data) {
      setRefetchList(false);
    }
  }, [data]);

  useEffect(() => {
    const body = {
      name: search,
      start_date: selectedDates[0],
      end_date: selectedDates[1],
      pkp: filter,
      offset: page,
      limit: fetchLimit,
    };
    mutateSupplierList(body);
  }, [search, selectedDates, filter, page, fetchLimit, refetchList]);

  useEffect(() => {
    if (openAdd)
      formik.setValues({
        category: "",
        supplier_name: "",
        address_hq: "",
        address_manufacturer: "",
        phone: "",
        is_pkp: false,
        npwp: "",
        top: "",
        lead_time: "",
        pic_name: "",
        pic_phone: "",
        pic_email: "",
        join_date: "",
      });
  }, [openAdd]);

  useEffect(() => {
    if (isAdd) {
      mutateNewSupplier(formik.values);
    }
  }, [isAdd]);

  useEffect(() => {
    if (isDelete) {
      mutateDeleteSupplier(selectedSupplier);
    }
  }, [isDelete]);

  useEffect(() => {
    setPage(1);
  }, [fetchLimit, search, filter]);

  return (
    <div>
      <div className="flex mb-4 gap-2">
        <TextField
          fullWidth
          placeholder="Nama Supplier/Vendor"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <div className="w-full border-2 rounded-md flex items-center hover:bg-slate-100">
          <div
            className="flex justify-between items-center h-full w-full cursor-pointer px-2"
            onClick={(e) => setAnchorElCalendar(e.currentTarget)}
          >
            {selectedDates[0] === "" ? (
              <p className="text-gray-300">----/--/--</p>
            ) : (
              <p className="text-gray-300">{selectedDates[0]}</p>
            )}
            <p className="text-gray-300"> s/d </p>
            {selectedDates[1] === "" ? (
              <p className="text-gray-300">----/--/--</p>
            ) : (
              <p className="text-gray-300">{selectedDates[1]}</p>
            )}
            <MdCalendarMonth className="w-6 h-6" />
          </div>
        </div>
        <FormControl
          sx={{
            width: "100%",
          }}
        >
          <Select value={filter} onChange={(e) => setFilter(e.target.value)}>
            <MenuItem value={0}>Semua Status</MenuItem>
            <MenuItem value={1}>PKP</MenuItem>
            <MenuItem value={2}>Non PKP</MenuItem>
          </Select>
        </FormControl>
        <button
          onClick={() => setOpenAdd(true)}
          className="w-1/4 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
        >
          <p>Add Supplier/Vendor</p>
          <IoMdAddCircleOutline className="text-[18px]" />
        </button>
      </div>
      <>
        {" "}
        <div className="rounded-md shadow-md">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <th className="p-3 text-left">No</th>
                <th className="p-3 text-left">Nama Supplier/Vendor</th>
                <th className="p-3 text-left">Alamat Head Office</th>
                <th className="p-3 text-left">Alamat Manufaktur</th>
                <th className="p-3 text-left">No. Telpon</th>
                <th className="p-3 text-left">Status</th>
                <th className="p-3 text-left">NPWP</th>
                <th className="p-3 text-left">TOP</th>
                <th className="p-3 text-left">Lead Time</th>
                <th className="p-3 text-left">Nama PIC</th>
                <th className="p-3 text-left">No. Telpon PIC</th>
                <th className="p-3 text-left">Email PIC</th>
                <th className="p-3 text-left">Tanggal Bergabung</th>
                <th className="p-3 text-left w-[15%]">Action</th>
              </thead>
              <tbody>
                {data && data.data && data.data.length > 0 && !isPendingList ? (
                  data.data.map((item, i) => {
                    return (
                      <tr className="border-t border-l border-2" key={i}>
                        <td className="p-3">
                          {(page - 1) * fetchLimit + (i + 1)}
                        </td>
                        <td className="p-3">{item.supplier_name}</td>
                        <td className="p-3">{item.address_hq}</td>
                        <td className="p-3">{item.address_manufacturer}</td>
                        <td className="p-3">{item.phone}</td>
                        <td className="p-3">
                          <Switch checked={item.is_pkp} disabled />
                        </td>
                        <td className="p-3">{item.npwp}</td>
                        <td className="p-3">{item.top}</td>
                        <td className="p-3">{item.lead_time}</td>
                        <td className="p-3">{item.pic_name}</td>
                        <td className="p-3">{item.pic_phone}</td>
                        <td className="p-3">{item.pic_email}</td>
                        <td className="p-3">
                          {format(item.join_date, "yyyy-MM-dd")}
                        </td>
                        <td className="p-3 w-[15%]">
                          <div className="flex gap-2 items-center">
                            <FaTrash
                              onClick={() => {
                                setOpenDelete(true);
                                setSelectedSupplier(item.id);
                              }}
                              className="text-red-500 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                            />
                            <IoPencilOutline
                              onClick={() => {
                                navigate(
                                  `/supplier/supplier-detail/${item.id}`
                                );
                              }}
                              className="text-blue-500 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : !isPendingList ? (
                  <tr className="border-t border-l border-2">
                    <td colSpan={14} className="py-3 text-center">
                      Data supplier tidak ditemukan
                    </td>
                  </tr>
                ) : (
                  <tr className="border-t border-l border-2">
                    <td colSpan={14} className="py-3 text-center">
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {data && data.total > 0 ? (
            <div className="w-full flex justify-between pb-8 px-8">
              <button
                onClick={() => {
                  if (page > 1) setPage(page - 1);
                }}
                disabled={page === 1}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowLeftLong /> Previous
              </button>
              <Pagination
                hideNextButton
                hidePrevButton
                shape="rounded"
                page={page}
                count={Math.ceil(data.total / fetchLimit)}
                onChange={(e, page) => setPage(page)}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
              <button
                onClick={() => {
                  if (page < Math.ceil(data.total / fetchLimit))
                    setPage(page + 1);
                }}
                disabled={page === Math.ceil(data.total / fetchLimit)}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowRightLong /> Next
              </button>
            </div>
          ) : null}
        </div>
        {data && data.total > 0 ? (
          <div className="mt-4 text-xs flex gap-3 items-center">
            <p>Show Table</p>
            <p
              onClick={() => setFetchLimit(10)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 10 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              10
            </p>
            <p
              onClick={() => setFetchLimit(50)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 50 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              50
            </p>
            <p
              onClick={() => setFetchLimit(100)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 100 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              100
            </p>
          </div>
        ) : null}
      </>

      <ModalInput
        title="Tambah Supplier/Vendor"
        subtitle=""
        content={<AddSupplierModalContent formik={formik} />}
        open={openAdd}
        setOpen={setOpenAdd}
        hasButton={true}
        buttonText="Save"
        setTriggerFunc={setIsAdd}
        isDisable={
          !formik.isValid ||
          formik.values.category === "" ||
          isPendingAdd ||
          (formik.values.is_pkp && formik.values.npwp === "")
        }
      />

      <ModalInput
        title="Hapus Supplier/Vendor"
        subtitle="Apakah anda yakin ingin menghapus supplier/vendor ini?"
        content={<></>}
        open={openDelete}
        setOpen={setOpenDelete}
        hasButton={true}
        buttonText="Yes"
        setTriggerFunc={setIsDelete}
        isDisable={false}
      />

      <RangeCalendar
        anchorElCalendar={anchorElCalendar}
        setAnchorElCalendar={setAnchorElCalendar}
        setSelectedDates={setSelectedDates}
        searchStartDate={searchStartDate}
        searchEndDate={searchEndDate}
        setSearchStartDate={setSearchStartDate}
        setSearchEndDate={setSearchEndDate}
      />
    </div>
  );
};

export default SupplierList;
