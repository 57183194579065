import {
  Autocomplete,
  Chip,
  FormGroup,
  FormLabel,
  InputAdornment,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { getSpkDetail, getSpkList } from "../../../services/SpkApi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  setReturSupplier,
  getMasterReturSupplier,
  setMasterReturSupplier,
  getInternalCode,
  getMaterial,
} from "../../../services/returSupplierApi";
import * as yup from "yup";
import { getSupplierList } from "../../../services/supplierApi";
import { MdCalendarMonth } from "react-icons/md";
import { format } from "date-fns";
import SingleCalendar from "../../common/SingleCalendar";
import ModalInput from "../../common/ModalInput";
import DeleteModalReturnSupplier from "./DeleteModalReturnSupplier";

const validationSchema = yup.object({
  ncr_date: yup.string().required(),
  ncr_number: yup.string().required(),
  accident_date: yup.string().required(),
  spk_id: yup.number().required(),
  po_number: yup.string(),
  location: yup.string().required(),
  supplier_name: yup.string().required(),
  manufacture: yup.string().required(),
  non_conformity_id: yup.number().min(1),
  internal_code: yup.array().of(yup.number()).min(1),
  material: yup.array().min(1),
  lot_no: yup.string(),
  problem: yup.string(),
  affected_spk: yup.string(),
  root_cause: yup.string(),
  capa: yup.string(),
  pic: yup.string(),
  due_date: yup.string().required(),
  status: yup.number().min(1),
});

const AddReturnSupplier = () => {
  const navigate = useNavigate();
  const defaultFilterOptions = createFilterOptions();

  const [selectedSpk, setSelectedSpk] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [ncrDate, setNcrDate] = useState(null);
  const [anchorElCalendarNcr, setAnchorElCalendarNcr] = useState(null);
  const [accidentDate, setAccidentDate] = useState(null);
  const [anchorElCalendarAccident, setAnchorElCalendarAccident] =
    useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [anchorElCalendarDue, setAnchorElCalendarDue] = useState(null);
  const [codeArr, setCodeArr] = useState([]);
  const [materialArr, setMaterialArr] = useState([]);
  const [internalCode, setInternalCode] = useState([]);
  const [material, setMaterial] = useState([]);
  const [openDeleteNonConformity, setOpenDeleteNonConformity] = useState(false);
  const [openDeleteStatus, setOpenDeleteStatus] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isAddStatus, setIsAddStatus] = useState(false);
  const [openAddNonConformity, setOpenAddNonConformity] = useState(false);
  const [openAddStatus, setOpenAddStatus] = useState(false);
  const [refetchList, setRefetchList] = useState(false);
  const [newNonConformity, setNewNonConformity] = useState({
    name: "",
    type: 1,
  });
  const [newStatus, setNewStatus] = useState({
    name: "",
    type: 2,
  });

  useEffect(() => {
    if (codeArr) {
      const code = codeArr.map((item) => item.costing_id);
      setInternalCode(code);
    }
  }, [codeArr, selectedSpk]);

  useEffect(() => {
    if (materialArr) {
      const code = materialArr.map((item) => item.material_id);
      setMaterial(code);
    }
  }, [materialArr]);

  const { mutate: mutateSpkList, data: dataSpkList } = useMutation({
    mutationFn: getSpkList,
  });

  const { data: dataNonConformity } = useQuery({
    queryKey: ["non_conformity", isAdd, refetchList],
    queryFn: () => getMasterReturSupplier({ type: 1 }),
  });

  const { data: dataStatus } = useQuery({
    queryKey: ["status", isAddStatus, refetchList],
    queryFn: () => getMasterReturSupplier({ type: 2 }),
  });

  const {
    mutate: mutateNewNonConformity,
    isPending: isPendingCreateNonConformity,
  } = useMutation({
    mutationFn: setMasterReturSupplier,
    onSuccess: () => {
      setOpenAddNonConformity(false);
      setIsAdd(false);
      setNewNonConformity({
        name: "",
        type: 1,
      });
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
      setIsAdd(false);
    },
  });

  const { mutate: mutateNewStatus, isPending: isPendingCreateStatus } =
    useMutation({
      mutationFn: setMasterReturSupplier,
      onSuccess: () => {
        setOpenAddStatus(false);
        setIsAddStatus(false);
        setNewStatus({
          name: "",
          type: 2,
        });
      },
      onError: (err) => {
        setErrorMessage(err.message.id);
        setIsAddStatus(false);
      },
    });

  const { data: dataDetailSpk, isFetching } = useQuery({
    queryKey: ["spk-detail", selectedSpk],
    queryFn: () => getSpkDetail(selectedSpk?.id),
    enabled: selectedSpk !== null,
  });

  const { mutate: mutateInternalCode, data: dataInternalCode } = useMutation({
    mutationFn: getInternalCode,
  });

  useEffect(() => {
    if (selectedSpk) {
      mutateInternalCode(selectedSpk?.id);
    }
  }, [selectedSpk]);

  const { mutate: mutateMaterial, data: dataMaterial } = useMutation({
    mutationFn: getMaterial,
  });

  useEffect(() => {
    if (internalCode) {
      mutateMaterial(internalCode);
    }
  }, [internalCode]);

  const { mutate: mutateSupplierList, data: dataSupplier } = useMutation({
    mutationFn: getSupplierList,
  });

  useEffect(() => {
    if (isAdd) {
      mutateNewNonConformity(newNonConformity);
    }
    if (!openAddNonConformity) {
      setNewNonConformity({
        name: "",
        type: 1,
      });
    }
  }, [isAdd, openAddNonConformity]);

  useEffect(() => {
    if (isAddStatus) {
      mutateNewStatus(newStatus);
    }
    if (!openAddStatus) {
      setNewStatus({
        name: "",
        type: 2,
      });
    }
  }, [isAddStatus, openAddStatus]);

  const { mutate: mutateReturSupplier, isPending } = useMutation({
    mutationFn: setReturSupplier,
    onSuccess: () => navigate("/retur-supplier"),
    onError: (err) => setErrorMessage(err.message.id),
  });

  useEffect(() => {
    setRefetchList(false);
  }, [dataNonConformity, dataStatus]);

  const formik = useFormik({
    initialValues: {
      ncr_date: "",
      ncr_number: "",
      accident_date: "",
      spk_id: 0,
      po_number: "",
      location: "",
      supplier_name: "",
      manufacture: "",
      non_conformity_id: 0,
      internal_code: [],
      material: [],
      lot_no: "",
      problem: "",
      affected_spk: "",
      root_cause: "",
      capa: "",
      pic: "",
      due_date: "",
      status: 1,
    },
    validationSchema,
    onSubmit: (values) => {
      mutateReturSupplier(values);
    },
  });

  useEffect(() => {
    formik.setFieldValue("internal_code", internalCode);
  }, [internalCode]);

  useEffect(() => {
    formik.setFieldValue("material.material_id", material);
  }, [material]);

  useEffect(() => {
    formik.setFieldValue(
      "ncr_date",
      ncrDate ? format(new Date(ncrDate), "yyyy-MM-dd") : ""
    );
  }, [ncrDate]);

  useEffect(() => {
    formik.setFieldValue(
      "accident_date",
      accidentDate ? format(new Date(accidentDate), "yyyy-MM-dd") : ""
    );
  }, [accidentDate]);

  useEffect(() => {
    formik.setFieldValue(
      "due_date",
      dueDate ? format(new Date(dueDate), "yyyy-MM-dd") : ""
    );
  }, [dueDate]);

  useEffect(() => {
    formik.setFieldValue("po_number", selectedSpk ? selectedSpk.po_number : "");
  }, [selectedSpk]);

  useEffect(() => {
    mutateSpkList({
      offset: 1,
      limit: 10000,
      schedule: 0,
      search: "",
      po_start: "",
      po_end: "",
      delivery_start: "",
      delivery_end: "",
      job_type: [],
      category: [],
      dies: [],
    });

    mutateSupplierList({
      name: "",
      start_date: "",
      end_date: "",
      pkp: 0,
      offset: 1,
      limit: 10000,
    });
  }, []);

  useEffect(() => {
    if (formik.values?.non_conformity_id === -1) {
      formik.setFieldValue("non_conformity_id", 0);
      setOpenAddNonConformity(true);
    }
  }, [formik.values?.non_conformity_id]);

  useEffect(() => {
    if (formik.values?.status === -1) {
      formik.setFieldValue("status", 0);
      setOpenAddStatus(true);
    }
  }, [formik.values?.status]);

  useEffect(() => {
    setCodeArr([]);
  }, [selectedSpk]);

  useEffect(() => {
    setMaterialArr([]);
  }, [codeArr]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex gap-4">
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Date NCR</FormLabel>
            <div className="w-full border-2 rounded-md flex items-center hover:bg-slate-100">
              <div
                className="flex justify-between items-center h-full w-full cursor-pointer p-3.5"
                onClick={(e) => setAnchorElCalendarNcr(e.currentTarget)}
              >
                {ncrDate === null ? (
                  <p>----/--/--</p>
                ) : (
                  <p>{format(new Date(ncrDate), "yyyy-MM-dd")}</p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
          </FormGroup>
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>No NCR</FormLabel>
            <TextField
              fullWidth
              value={formik.values.ncr_number}
              onChange={(e) =>
                formik.setFieldValue("ncr_number", e.target.value)
              }
              placeholder="Nomor NCR"
            />
          </FormGroup>
        </div>

        <div className="flex gap-4">
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Tanggal Accident</FormLabel>
            <div className="w-full border-2 rounded-md flex items-center hover:bg-slate-100">
              <div
                className="flex justify-between items-center h-full w-full cursor-pointer p-3.5"
                onClick={(e) => setAnchorElCalendarAccident(e.currentTarget)}
              >
                {accidentDate === null ? (
                  <p>----/--/--</p>
                ) : (
                  <p>{format(new Date(accidentDate), "yyyy-MM-dd")}</p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
          </FormGroup>
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>SPK</FormLabel>
            <Autocomplete
              sx={{ width: "100%" }}
              disablePortal
              value={selectedSpk}
              onChange={(_, option) => {
                formik.setFieldValue("spk_id", option ? option.id : null);
                setSelectedSpk(option);
              }}
              options={dataSpkList && dataSpkList.data ? dataSpkList.data : []}
              getOptionLabel={(option) => option.spk_no}
              renderInput={(params) => (
                <TextField {...params} placeholder="Pilih SPK" />
              )}
            />
          </FormGroup>
        </div>

        <div className="flex gap-4">
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>PO No</FormLabel>
            <TextField
              fullWidth
              value={dataDetailSpk ? dataDetailSpk.po_number : ""}
              disabled
              placeholder="Nomor PO"
            />
          </FormGroup>
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Location Kejadian</FormLabel>
            <TextField
              fullWidth
              value={formik.values.location}
              onChange={(e) => formik.setFieldValue("location", e.target.value)}
              placeholder="Lokasi Kejadian"
            />
          </FormGroup>
        </div>

        <div className="flex gap-4">
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Supplier Name</FormLabel>
            <TextField
              fullWidth
              value={formik.values.supplier_name}
              onChange={(e) =>
                formik.setFieldValue("supplier_name", e.target.value)
              }
              placeholder="Nama Supplier"
            />
          </FormGroup>
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Manufacture</FormLabel>
            <TextField
              fullWidth
              value={formik.values.manufacture}
              onChange={(e) =>
                formik.setFieldValue("manufacture", e.target.value)
              }
              placeholder="Nama Manufaktur"
            />
          </FormGroup>
        </div>

        <div className="flex gap-4">
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Type non conformity</FormLabel>
            <Autocomplete
              sx={{ width: "100%" }}
              disablePortal
              value={
                dataNonConformity?.data &&
                dataNonConformity?.data.find(
                  (item) => item.id === formik.values.non_conformity_id
                )
                  ? dataNonConformity?.data?.name
                  : dataNonConformity?.data?.name
              }
              onChange={(_, option) => {
                if (option)
                  formik.setFieldValue("non_conformity_id", option.id);
              }}
              options={
                dataNonConformity
                  ? [
                      { id: -1, name: "Tambah non conformity disini..." },
                      ...dataNonConformity,
                    ]
                  : []
              }
              getOptionLabel={(option) =>
                option.name && typeof option.name === "string"
                  ? option.name
                  : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Pilih Type Non Conformity"
                  onChange={(e) =>
                    setNewNonConformity({
                      ...newNonConformity,
                      name: e.target.value,
                    })
                  }
                />
              )}
              filterOptions={(options, state) => {
                const results = defaultFilterOptions(options, state);
                if (results.length === 0) {
                  return [{ id: -1, name: "Tambah Type Non Conformity" }];
                }

                return results;
              }}
            />
            <p
              className="text-right w-full text-xs underline text-blue-500 cursor-pointer"
              onClick={() => setOpenDeleteNonConformity(true)}
            >
              Pengaturan Type Non Conformity
            </p>
          </FormGroup>
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Internal Code</FormLabel>
            <Autocomplete
              sx={{ width: "100%", marginBottom: 2 }}
              disablePortal
              disabled={!selectedSpk}
              multiple
              value={codeArr}
              onChange={(_, option) => {
                setCodeArr(option);
              }}
              options={
                dataInternalCode && dataInternalCode?.items
                  ? dataInternalCode?.items
                  : []
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option.code}
                    {...getTagProps({ index })}
                  />
                ))
              }
              getOptionLabel={(option) => option.code}
              renderInput={(params) => (
                <TextField {...params} placeholder="Pilih Internal Code" />
              )}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === "reset") {
                  setCodeArr([]);
                  return;
                }
              }}
            />
          </FormGroup>
        </div>

        <div className="flex gap-4">
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Material</FormLabel>
            <Autocomplete
              sx={{ width: "100%", marginBottom: 2 }}
              disablePortal
              disabled={codeArr && codeArr.length === 0}
              multiple
              value={materialArr}
              onChange={(_, option) => {
                setMaterialArr(option);
              }}
              options={
                dataMaterial?.data?.flatMap((item) => item.material) || []
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option.material_name}
                    {...getTagProps({ index })}
                  />
                ))
              }
              getOptionLabel={(option) => option.material_name}
              renderInput={(params) => (
                <TextField {...params} placeholder="Pilih Material" />
              )}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === "reset") {
                  setMaterialArr([]);
                  return;
                }
              }}
            />
          </FormGroup>
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Lot No</FormLabel>
            <TextField
              fullWidth
              value={formik.values.lot_no}
              onChange={(e) => formik.setFieldValue("lot_no", e.target.value)}
              placeholder="Nomor Lot"
            />
          </FormGroup>
        </div>

        <div className="rounded-md shadow-md">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-4 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <th className="p-3 text-left">No</th>
                <th className="p-3 text-left">Material Name</th>
                <th className="p-3 text-left">Qty Material</th>
              </thead>
              <tbody>
                {materialArr && materialArr?.length > 0 ? (
                  materialArr?.map((item, i) => (
                    <tr key={i}>
                      <td className="p-3 text-left border-b-2">{i + 1}</td>
                      <td className="p-3 text-left border-b-2">
                        {item.material_name}
                      </td>
                      <td className="p-3 text-left border-b-2">
                        <TextField
                          fullWidth
                          value={
                            formik.values.material?.length > 0
                              ? formik.values.material[i]?.quantity
                              : 0
                          }
                          onChange={(e) => {
                            if (!isNaN(Number(e.target.value))) {
                              formik.setFieldValue(
                                "material[" + i + "].material_id",
                                item.id
                              );
                              formik.setFieldValue(
                                "material[" + i + "].quantity",
                                Number(e.target.value)
                              );
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="p-3 text-center" colSpan={6}>
                      Tidak ada material yang terpilih
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Problem</FormLabel>
          <TextField
            fullWidth
            value={formik.values.problem}
            onChange={(e) => {
              if (e.target.value.length <= 300)
                formik.setFieldValue("problem", e.target.value);
            }}
            placeholder="Tuliskan problem disini..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {formik.values.problem.length}/300
                </InputAdornment>
              ),
            }}
          />
        </FormGroup>

        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Affected SPK</FormLabel>
          <TextField
            fullWidth
            value={formik.values.affected_spk}
            onChange={(e) => {
              if (e.target.value.length <= 200)
                formik.setFieldValue("affected_spk", e.target.value);
            }}
            placeholder="Tuliskan affected SPK disini..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {formik.values.affected_spk.length}/200
                </InputAdornment>
              ),
            }}
          />
        </FormGroup>

        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Root Cause</FormLabel>
          <TextField
            fullWidth
            value={formik.values.root_cause}
            onChange={(e) => {
              if (e.target.value.length <= 200)
                formik.setFieldValue("root_cause", e.target.value);
            }}
            placeholder="Tuliskan root cause disini..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {formik.values.root_cause.length}/200
                </InputAdornment>
              ),
            }}
          />
        </FormGroup>

        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>CAPA</FormLabel>
          <TextField
            fullWidth
            value={formik.values.capa}
            onChange={(e) => {
              if (e.target.value.length <= 200)
                formik.setFieldValue("capa", e.target.value);
            }}
            placeholder="Tuliskan CAPA disini..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {formik.values.capa.length}/200
                </InputAdornment>
              ),
            }}
          />
        </FormGroup>

        <div className="flex gap-4">
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>PIC</FormLabel>
            <TextField
              fullWidth
              value={formik.values.pic}
              onChange={(e) => formik.setFieldValue("pic", e.target.value)}
              placeholder="Nama PIC"
            />
          </FormGroup>
          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Due Date</FormLabel>
            <div className="w-full border-2 rounded-md flex items-center hover:bg-slate-100">
              <div
                className="flex justify-between items-center h-full w-full cursor-pointer p-3.5"
                onClick={(e) => setAnchorElCalendarDue(e.currentTarget)}
              >
                {dueDate === null ? (
                  <p>----/--/--</p>
                ) : (
                  <p>{format(new Date(dueDate), "yyyy-MM-dd")}</p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
          </FormGroup>
        </div>

        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Status</FormLabel>
          <Autocomplete
            sx={{ width: "100%" }}
            disablePortal
            value={
              dataStatus?.data &&
              dataStatus?.data.find((item) => item.id === formik.values.status)
                ? dataStatus?.data?.name
                : dataStatus?.data?.name
            }
            onChange={(_, option) => {
              if (option) formik.setFieldValue("status", option.id);
            }}
            options={
              dataStatus
                ? [
                    ...dataStatus,
                    { id: -1, name: "Tambah status baru disini..." },
                  ]
                : []
            }
            getOptionLabel={(option) =>
              option.name && typeof option.name === "string" ? option.name : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Pilih Status"
                onChange={(e) =>
                  setNewStatus({
                    ...newStatus,
                    name: e.target.value,
                  })
                }
              />
            )}
            filterOptions={(options, state) => {
              const results = defaultFilterOptions(options, state);
              if (results.length === 0) {
                return [{ id: -1, name: "Tambah Status" }];
              }

              return results;
            }}
          />
          <p
            className="text-right w-full text-xs underline text-blue-500 cursor-pointer"
            onClick={() => setOpenDeleteStatus(true)}
          >
            Pengaturan Status
          </p>
        </FormGroup>

        <p className="text-red-500 mb-4 text-right m-4">{errorMessage}</p>
        <div className="w-full">
          <div className="flex justify-end w-1/3 gap-2 float-right mb-8">
            <button
              type="button"
              className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
              onClick={() => navigate("/retur-supplier")}
            >
              Cancel
            </button>
            <button
              className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
              disabled={!formik.isValid}
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </form>

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendarNcr}
        setAnchorElCalendarStart={setAnchorElCalendarNcr}
        startDate={ncrDate}
        setStartDate={setNcrDate}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendarAccident}
        setAnchorElCalendarStart={setAnchorElCalendarAccident}
        startDate={accidentDate}
        setStartDate={setAccidentDate}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendarDue}
        setAnchorElCalendarStart={setAnchorElCalendarDue}
        startDate={dueDate}
        setStartDate={setDueDate}
      />

      <ModalInput
        title="Tambah Type Non Conformity"
        content={
          <>
            <TextField
              fullWidth
              label="Nama non conformity baru disini..."
              value={newNonConformity.name}
              onChange={(e) =>
                setNewNonConformity({
                  ...newNonConformity,
                  name: e.target.value,
                })
              }
            />
          </>
        }
        open={openAddNonConformity}
        setOpen={setOpenAddNonConformity}
        hasButton={true}
        buttonText="Save"
        setTriggerFunc={setIsAdd}
        isDisable={isPendingCreateNonConformity || newNonConformity === ""}
      />

      <ModalInput
        title="Tambah Status"
        content={
          <>
            <TextField
              fullWidth
              label="Nama status baru disini..."
              value={newStatus.name}
              onChange={(e) =>
                setNewStatus({
                  ...newStatus,
                  name: e.target.value,
                })
              }
            />
          </>
        }
        open={openAddStatus}
        setOpen={setOpenAddStatus}
        hasButton={true}
        buttonText="Save"
        setTriggerFunc={setIsAddStatus}
        isDisable={isPendingCreateStatus || newStatus === ""}
      />

      {openDeleteNonConformity ? (
        <DeleteModalReturnSupplier
          dataReturSupplier={dataNonConformity}
          setOpenDeleteModal={setOpenDeleteNonConformity}
          openDeleteModal={openDeleteNonConformity}
          setRefetchList={setRefetchList}
          type="non-conformity"
        />
      ) : null}

      {openDeleteStatus ? (
        <DeleteModalReturnSupplier
          dataReturSupplier={dataStatus}
          setOpenDeleteModal={setOpenDeleteStatus}
          openDeleteModal={openDeleteStatus}
          setRefetchList={setRefetchList}
          type="status"
        />
      ) : null}
    </>
  );
};

export default AddReturnSupplier;
