import {
  CircularProgress,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import ModalInput from "../../common/ModalInput";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  createDieCut,
  deleteDieCut,
  getDieCutList,
  updateDieCut,
} from "../../../services/dieCutApi";
import AddDieCutModalContent from "./AddDieCutModalContent";
import EditDieCutModalContent from "./EditDieCutModalContent";
import BarcodeJobBag from "./BarcodeJobBag";
import { format } from "date-fns";

const validationSchema = yup.object({
  code: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  type: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  length: yup.number().min(1),
  width: yup.number().min(1),
  repeat: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  job_bag: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  description: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  date: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  gap_along: yup.number().min(1),
  gap_across: yup.number().min(1),
  up_along: yup.number().min(1),
  up_across: yup.number().min(1),
  running_meter: yup.number().min(1),
  vendor: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  material_type: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
  internal_code: yup
    .string()
    .required()
    .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
});

const DieCutList = () => {
  const [page, setPage] = useState(1);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [openAdd, setOpenAdd] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedDie, setSelectedDie] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [refetchList, setRefetchList] = useState(true);
  const [isDownload, setIsDownload] = useState(false);

  const formik = useFormik({
    initialValues: {
      code: "",
      type: "",
      length: 0,
      width: 0,
      repeat: "",
      job_bag: "",
      description: "",
      date: "",
      gap_along: 0,
      gap_across: 0,
      up_along: 0,
      up_across: 0,
      running_meter: 0,
      vendor: "",
      material_type: "",
      internal_code: "",
    },
    validationSchema,
    validateOnMount: true,
  });

  const { data, isFetching } = useQuery({
    queryKey: ["diecut-list", page, fetchLimit, refetchList],
    queryFn: () =>
      getDieCutList({
        offset: page,
        limit: fetchLimit,
      }),
  });

  const { mutate: mutateNewDie, isPending: isPendingAdd } = useMutation({
    mutationFn: createDieCut,
    onSuccess: () => {
      setIsAdd(false);
      setOpenAdd(false);
      setRefetchList(true);
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
    onMutate: () => {
      setErrorMessage("");
    },
  });

  const { mutate: mutateDelete, isPending: isPendingDelete } = useMutation({
    mutationFn: deleteDieCut,
    onSuccess: () => {
      setOpenDetail(false);
      setOpenDelete(false);
      setIsDelete(false);
      setRefetchList(true);
    },
  });

  const { mutate: mutateUpdateDie, isPending: isPendingEdit } = useMutation({
    mutationFn: updateDieCut,
    onSuccess: () => {
      setIsEdit(false);
      setOpenDetail(false);
      setRefetchList(true);
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
    onMutate: () => {
      setErrorMessage("");
    },
  });

  useEffect(() => {
    if (data) {
      setRefetchList(false);
    }
  }, [data]);

  useEffect(() => {
    if (!isDownload) setSelectedDie(null);
  }, [isDownload]);

  useEffect(() => {
    if (openAdd || !openDetail)
      formik.setValues({
        code: "",
        type: "",
        length: 0,
        width: 0,
        repeat: "",
        job_bag: "",
        description: "",
        date: "",
        gap_along: 0,
        gap_across: 0,
        up_along: 0,
        up_across: 0,
        running_meter: 0,
        vendor: "",
        material_type: "",
      });
    setErrorMessage("");
  }, [openAdd, openDetail]);

  useEffect(() => {
    if (isAdd) {
      mutateNewDie(formik.values);
    }
  }, [isAdd]);

  useEffect(() => {
    if (isEdit) {
      mutateUpdateDie({
        id: selectedDie.id,
        code: formik.values.code,
        type: formik.values.type,
        length: formik.values.length,
        width: formik.values.width,
        repeat: formik.values.repeat,
        job_bag: formik.values.job_bag,
        description: formik.values.description,
        date: formik.values.date,
        gap_along: formik.values.gap_along,
        gap_across: formik.values.gap_across,
        up_along: formik.values.up_along,
        up_across: formik.values.up_across,
        running_meter: formik.values.running_meter,
        vendor: formik.values.vendor,
        material_type: formik.values.material_type,
        internal_code: formik.values.internal_code,
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (isDelete) {
      mutateDelete(selectedDie.id);
    }
  }, [isDelete]);

  return (
    <div>
      <>
        {" "}
        <div className="rounded-md shadow-md">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <th className="p-3 text-left">No</th>
                <th className="p-3 text-left">Nama MPS</th>
                <th className="p-3 text-left">Tanggal Digunakan</th>
                <th className="p-3 text-left">Nomor SPK</th>
                <th className="p-3 text-left">Gear Size</th>
                <th className="p-3 text-left">MPS</th>
              </thead>
              <tbody>
                {data && data.diecuts.length > 0 && !isFetching ? (
                  data.diecuts.map((item, i) => {
                    return (
                      <tr className="border-t border-l border-2" key={i}>
                        <td className="p-3">
                          {(page - 1) * fetchLimit + (i + 1)}
                        </td>
                        <td className="p-3">{item.mps_name}</td>
                        <td className="p-3 text-nowrap">
                          {item.date === ""
                            ? "-"
                            : format(item.date, "dd-MM-yyyy")}
                        </td>
                        <td className="p-3">{item.spk_number}</td>
                        <td className="p-3">{item.gear_size}</td>
                        <td className="p-3">{item.mps}</td>
                      </tr>
                    );
                  })
                ) : !isFetching ? (
                  <tr className="border-t border-l border-2">
                    <td colSpan={16} className="py-3 text-center">
                      Data die cut tidak ditemukan
                    </td>
                  </tr>
                ) : (
                  <tr className="border-t border-l border-2">
                    <td colSpan={16} className="py-3 text-center">
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {data && data.count > 0 ? (
            <div className="w-full flex justify-between pb-8 px-8">
              <button
                onClick={() => {
                  if (page > 1) setPage(page - 1);
                }}
                disabled={page === 1}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowLeftLong /> Previous
              </button>
              <Pagination
                hideNextButton
                hidePrevButton
                shape="rounded"
                page={page}
                count={Math.ceil(data.count / fetchLimit)}
                onChange={(e, page) => setPage(page)}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
              <button
                onClick={() => {
                  if (page < Math.ceil(data.count / fetchLimit))
                    setPage(page + 1);
                }}
                disabled={page === Math.ceil(data.count / fetchLimit)}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowRightLong /> Next
              </button>
            </div>
          ) : null}
        </div>
        {data && data.count > 0 ? (
          <div className="mt-4 text-xs flex gap-3 items-center">
            <p>Show Table</p>
            <p
              onClick={() => setFetchLimit(10)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 10 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              10
            </p>
            <p
              onClick={() => setFetchLimit(50)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 50 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              50
            </p>
            <p
              onClick={() => setFetchLimit(100)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 100 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              100
            </p>
          </div>
        ) : null}
      </>

      <ModalInput
        title="Tambah Die Cut"
        subtitle=""
        content={
          <AddDieCutModalContent formik={formik} errorMessage={errorMessage} />
        }
        open={openAdd}
        setOpen={setOpenAdd}
        hasButton={true}
        buttonText="Save"
        setTriggerFunc={setIsAdd}
        isDisable={!formik.isValid || isPendingAdd}
      />

      <ModalInput
        title="Detail Die Cut"
        subtitle=""
        content={
          <EditDieCutModalContent
            formik={formik}
            setOpenDelete={setOpenDelete}
          />
        }
        open={openDetail}
        setOpen={setOpenDetail}
        hasButton={true}
        buttonText="Save"
        setTriggerFunc={setIsEdit}
        isDisable={!formik.isValid || isPendingEdit}
      />

      <ModalInput
        title="Hapus Die Cut"
        subtitle="Apakah anda ingin menghapus die cut ini?"
        content={<></>}
        open={openDelete}
        setOpen={setOpenDelete}
        hasButton={true}
        buttonText="Hapus"
        setTriggerFunc={setIsDelete}
        isDisable={isPendingDelete}
      />

      {selectedDie !== null ? (
        <BarcodeJobBag
          dieData={selectedDie}
          isDownload={isDownload}
          setIsDownload={setIsDownload}
        />
      ) : null}
    </div>
  );
};

export default DieCutList;
