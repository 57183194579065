import axios from "axios";
import Cookies from "js-cookie";
import { GeneratePublicToken } from "../utils/GeneratePublicToken";

const token = () => Cookies.get("accessToken");

export const getDieCutList = async ({ limit, offset }) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/diecut-list?&limit=${limit}&offset=${offset}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data
  }
};

export const createDieCut = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/diecut-create`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data
  }
};

export const updateDieCut = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/diecut-create`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data
  }
};

export const deleteDieCut = async (id) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/diecut-delete?id=${id}`,
      method: "DELETE",
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data
  }
};
