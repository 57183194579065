import { useEffect, useRef } from "react";
import Logo from "../../../assets/logo.png";
import jsPDF from "jspdf";
import { format } from "date-fns";
import angkaTerbilangJs from "@develoka/angka-terbilang-js";
import { getDoDetail } from "../../../services/deliveryOrder";
import { useQuery } from "@tanstack/react-query";

const PrintDo = ({ isDownload, setIsDownload, data }) => {
  const invoiceRef = useRef();

  const {
    data: dataDo,
    status,
    isFetching,
  } = useQuery({
    queryKey: ["do-detail"],
    enabled: data !== null && isDownload,
    queryFn: () => getDoDetail(data.id),
  });

  const styles = {
    page: {
      // paddingLeft: "12mm",
      // paddingRight: "12mm",
      // marginTop: "8mm",
      fontSize: 8,
      // width: "210mm",
    },

    terbilangContainer: {
      width: "140mm",
    },
  };

  useEffect(() => {
    if (status === "error") {
      setIsDownload(false);
    }
  }, [status]);

  useEffect(() => {
    if (isDownload && data && dataDo && !isFetching) {
      const doc = new jsPDF({
        lineHeight: 1.2,
        fontSize: 11,
        format: "a4",
        unit: "px",
      });
      doc.html(invoiceRef.current, {
        callback: function (doc) {
          doc.save("Delivery Order_" + data.spk_no + ".pdf");
        },
        x: 0,
        y: 0,
        // autoPaging: "text",
        // width: 2470,
      });
      setIsDownload(false);
      setIsDownload(false);
    }
  }, [isFetching]);

  return (
    <>
      {data && dataDo ? (
        <div ref={invoiceRef} style={styles.page} className="">
          <div>
            <img
              src={Logo}
              className="w-[25mm] h-fit mb-2 mt-6 px-4 inline-block"
            />
            <p className="font-bold text-lg text-right w-fit inline-block w-[calc(384px-20mm)]">
              Surat Jalan
            </p>
          </div>

          <div className="px-4 mb-8">
            <div className="inline-block align-top w-64 mr-2">
              {dataDo.address}
            </div>
            <div className="inline-block align-top w-32">
              No. DO: {dataDo.do_number} <br />
              No. PO: {dataDo.po_number}
            </div>
          </div>

          <div className="px-4 mb-4">
            <div className="inline-block w-48">
              <p>Yang Terhormat {dataDo.customer_name},</p>
              <p>Top: {dataDo.top}</p>
            </div>
            <p className="inline-block w-48 text-right">
              Franco: {dataDo.franco}
            </p>
          </div>

          <table className="mb-1 border-collapse w-96 mx-4">
            <thead>
              <tr className="bg-blue-100">
                <th className="p-1 border border-b-0 border-black border-r-0">
                  No
                </th>
                <th className="p-1 border border-b-0 border-black border-r-0">
                  Nama Label
                </th>
                <th className="p-1 border border-b-0 border-black">
                  Qty Delivery
                </th>
              </tr>
            </thead>
            <tbody>
              {dataDo?.label?.length > 0 ? (
                dataDo?.label?.map((item, i) => (
                  <tr key={i}>
                    <td
                      className={`p-1 text-center border border-black border-r-0 ${
                        i === dataDo?.label?.length - 1 ? "" : "border-b-0"
                      }`}
                    >
                      {i + 1}
                    </td>
                    <td
                      className={`p-1 text-center border border-black border-r-0 ${
                        i === dataDo?.label?.length - 1 ? "" : "border-b-0"
                      }`}
                    >
                      {item.label_name}
                    </td>
                    <td
                      className={`p-1 text-center border border-black ${
                        i === dataDo?.label?.length - 1 ? "" : "border-b-0"
                      }`}
                    >
                      {item.qty_delivery}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={2}>Tidak ada label</td>
                </tr>
              )}
            </tbody>
          </table>

          <div className=" px-4 mb-8 mb-12">
            <p className="w-32 inline-block text-center">Disetujui Oleh</p>
            <p className="w-32 inline-block text-center">Dikirm Oleh</p>
            <p className="w-32 inline-block text-center">Diterima Oleh</p>
          </div>
          <div className=" px-4 mb-8 mb-12">
            <p className="w-32 inline-block text-center">
              {"("}
              &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
              {")"}
            </p>
            <p className="w-32 inline-block text-center">
              {dataDo.carrier_name}
            </p>
            <p className="w-32 inline-block text-center">
              {"("}
              &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
              {")"}
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PrintDo;
