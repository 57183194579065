import { Autocomplete, TextField } from "@mui/material";
import ModalInput from "../../common/ModalInput";
import { useMutation } from "@tanstack/react-query";
import { updateStockMaterial } from "../../../services/materialApi";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import SingleCalendar from "../../common/SingleCalendar";
import { MdCalendarMonth } from "react-icons/md";
import { format } from "date-fns";
import * as yup from "yup";
import { getSupplierList } from "../../../services/supplierApi";

const validationSchema = yup.object({
  material_id: yup.number(),
  po_number: yup.string().required(),
  quantity_order: yup.number().min(1).required(),
  incoming_order: yup.number().min(1).required(),
  incoming_date: yup.string().required(),
  supplier_name: yup.string().required(),
});

const UpdateStockMaterial = ({
  id,
  openUpdate,
  setOpenUpdate,
  setRefetchList,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [incomingDate, setIncomingDate] = useState(null);

  const formik = useFormik({
    initialValues: {
      material_id: Number(id),
      po_number: "",
      quantity_order: 0,
      incoming_order: 0,
      incoming_date: "",
      supplier_name: "",
    },
    validationSchema,
    validateOnMount: true,
  });

  const {
    mutate: mutateSupplierList,
    data,
    isPending: isPendingList,
  } = useMutation({
    mutationFn: getSupplierList,
    onSuccess: () => {},
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  const { mutate: mutateUpdate, isPending } = useMutation({
    mutationFn: updateStockMaterial,
    onSuccess: () => {
      setIsUpdate(false);
      setOpenUpdate(false);
      setRefetchList(true);
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
      setIsUpdate(false);
    },
  });

  useEffect(() => {
    incomingDate
      ? formik.setFieldValue(
          "incoming_date",
          format(new Date(incomingDate), "yyyy-MM-dd")
        )
      : formik.setFieldValue("incoming_date", "");
  }, [incomingDate]);

  useEffect(() => {
    if (anchorElCalendar && !incomingDate) setIncomingDate(new Date());
  }, [anchorElCalendar]);

  useEffect(() => {
    if (isUpdate) mutateUpdate(formik.values);
  }, [isUpdate]);

  useEffect(() => {
    setErrorMessage("");
  }, [formik.values]);

  useEffect(() => {
    if (openUpdate) {
      formik.resetForm();
      setIncomingDate(null);
    }
  }, [openUpdate]);

  useEffect(() => {
    const body = {
      name: "",
      start_date: "",
      end_date: "",
      pkp: 0,
      offset: 1,
      limit: 10000,
    };
    mutateSupplierList(body);
  }, []);

  return (
    <div>
      <ModalInput
        title="Update Stok"
        subtitle=""
        content={
          <>
            <p>Tanggal Masuk</p>
            <div className="mb-4 border-2 rounded-md w-full flex items-center hover:bg-slate-100">
              <div
                className="flex justify-between items-center h-full w-full cursor-pointer p-2"
                onClick={(e) => setAnchorElCalendar(e.currentTarget)}
              >
                {incomingDate === null ? (
                  <p className="text-gray-300">----/--/--</p>
                ) : (
                  <p className="text-gray-300">
                    {format(new Date(incomingDate), "yyyy-MM-dd")}
                  </p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
            <TextField
              fullWidth
              label="Jumlah Order"
              sx={{ marginBottom: 2 }}
              value={formik.values.quantity_order}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue(
                    "quantity_order",
                    Number(e.target.value)
                  );
              }}
            />
            <TextField
              fullWidth
              label="Jumlah Masuk"
              sx={{ marginBottom: 2 }}
              value={formik.values.incoming_order}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue(
                    "incoming_order",
                    Number(e.target.value)
                  );
              }}
            />
            <TextField
              fullWidth
              label="No. PO"
              sx={{ marginBottom: 2 }}
              value={formik.values.po_number}
              onChange={(e) => {
                formik.setFieldValue("po_number", e.target.value);
              }}
            />
            <Autocomplete
              sx={{ width: "100%", marginBottom: 2 }}
              disablePortal
              value={formik.values.supplier_name}
              onChange={(_, option) => {
                formik.setFieldValue("supplier_name", option ? option : "");
              }}
              options={
                data && data.data
                  ? [...data.data.map((elem) => elem.supplier_name)]
                  : []
              }
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField {...params} label="Supplier/Vendor" />
              )}
            />

            <p className="text-red-500 my-2">{errorMessage}</p>
          </>
        }
        open={openUpdate}
        setOpen={setOpenUpdate}
        hasButton={true}
        buttonText="Save"
        setTriggerFunc={setIsUpdate}
        isDisable={isPending || !formik.isValid}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendar}
        setAnchorElCalendarStart={setAnchorElCalendar}
        startDate={incomingDate}
        setStartDate={setIncomingDate}
      />
    </div>
  );
};

export default UpdateStockMaterial;
