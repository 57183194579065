import React from "react";
import Header from "../../components/common/Header";
import SidebarComponent from "../../components/common/SidebarComponent";
import HOC from "../../components/HOC";
import JobOperator from "../../components/screen/schedule/JobOperator";
import JobQC from "../../components/screen/schedule/JobQC";
import JobPlate from "../../components/screen/schedule/JobPlate";
import JobGudang from "../../components/screen/schedule/JobGudang";
import JobTinta from "../../components/screen/schedule/JobTinta";

const ScheduleTakeJobPage = () => {
  const role = Number(localStorage.getItem("role"));

  return (
    <div className="">
      <div className="flex">
        <div className="w-0 lg:w-[310px] h-screen sticky top-0 z-30">
          <SidebarComponent />
        </div>
        <div className="w-[100%] lg:w-[calc(100%-310px)] p-3 mt-4 lg:mt-0">
          <Header />
          <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
            Take Job{" "}
            {role === 1
              ? "(Plate - Plate & Die Cut)"
              : role === 2
              ? "(Gudang - Paper, BOPP, Foil)"
              : role === 3
              ? "(Tinta)"
              : role === 4
              ? "(Operasional)"
              : role === 5
              ? "QC"
              : ""}
          </h1>
          {role === 1 ? (
            <JobPlate />
          ) : role === 2 ? (
            <JobGudang />
          ) : role === 3 ? (
            <JobTinta />
          ) : role === 4 ? (
            <JobOperator />
          ) : role === 5 ? (
            <JobQC />
          ) : (
            <p className="text-gray-300 text-center">
              Silahkan mengontak admin untuk mengambil job
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default HOC(ScheduleTakeJobPage);
