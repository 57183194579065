import {
  CircularProgress,
  InputAdornment,
  Pagination,
  PaginationItem,
  TextField,
  FormGroup,
  FormLabel,
} from "@mui/material";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { IoMdAddCircleOutline, IoMdDownload } from "react-icons/io";
import { Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getSupplierList } from "../../../services/supplierApi";
import { useMutation } from "@tanstack/react-query";
import RangeCalendar from "../../common/RangeCalendar";
import { MdCalendarMonth } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import { IoPencilOutline } from "react-icons/io5";
import ModalInput from "../../common/ModalInput";
import PrintReturnSupplier from "./PrintReturnSupplier";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { pdf } from "@react-pdf/renderer";
import {
  deleteReturSupplier,
  getReturSupplierList,
} from "../../../services/returSupplierApi";
import { getDetailReturSupplier } from "../../../services/returSupplierApi";
import PrintReturSupplier from "./PrintReturnSupplier";

const ReturnSupplierList = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [anchorElCalendarIssue, setAnchorElCalendarIssue] = useState(null);
  const [anchorElCalendarRetur, setAnchorElCalendarRetur] = useState(null);
  const [selectedDatesNcr, setSelectedDatesNcr] = useState(["", ""]);
  const [selectedDatesAccident, setSelectedDatesAccident] = useState(["", ""]);
  const [searchStartDateRetur, setSearchStartDateRetur] = useState(null);
  const [searchEndDateRetur, setSearchEndDateRetur] = useState(null);
  const [searchStartDateIssue, setSearchStartDateIssue] = useState(null);
  const [searchEndDateIssue, setSearchEndDateIssue] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRetur, setSelectedRetur] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [selectedReturSupplier, setSelectedReturSupplier] = useState(null);
  const [isDownload, setIsDownload] = useState(false);

  const generatePdf = async (pdfData) => {
    const blob = await pdf(
      <PrintReturnSupplier dataDetail={pdfData} />
    ).toBlob();
    window.open(URL.createObjectURL(blob), "_blank");
  };

  const {
    mutate: mutateSupplierList,
    data: dataSupplier,
    isPending: isPendingList,
  } = useMutation({
    mutationFn: getSupplierList,
  });

  const { mutate: mutateDelete, isPending: isPendingDelete } = useMutation({
    mutationFn: deleteReturSupplier,
    onSuccess: () => {
      setRefetch(true);
      setOpenDelete(false);
    },
  });

  const {
    mutate: mutateList,
    data,
    isPending,
  } = useMutation({
    mutationFn: getReturSupplierList,
  });

  const { mutate: mutateDetail } = useMutation({
    mutationKey: ["detail-supplier"],
    mutationFn: getDetailReturSupplier,
    onSuccess: (data) => generatePdf(data),
  });

  useEffect(() => {
    const body = {
      ncr_date_start: selectedDatesNcr[0],
      ncr_date_end: selectedDatesNcr[1],
      accident_date_start: selectedDatesAccident[0],
      accident_date_end: selectedDatesAccident[1],
      search: search,
      limit: fetchLimit,
      offset: page,
    };
    mutateList(body);
    setRefetch(false);
  }, [
    search,
    selectedDatesNcr,
    selectedDatesAccident,
    page,
    fetchLimit,
  ]);

  useEffect(() => {
    if (!isDownload) setSelectedReturSupplier(null);
  }, [isDownload]);

  useEffect(() => {
    const body = {
      name: "",
      start_date: "",
      end_date: "",
      pkp: 0,
      offset: 1,
      limit: 10000,
    };
    mutateSupplierList(body);
  }, []);

  return (
    <>
      <div className="flex mb-4 gap-2 items-stretch">
        <FormGroup sx={{ width: "100%" }}>
          <FormLabel>NCR</FormLabel>
          <div className="w-full border-2 rounded-md flex items-center hover:bg-slate-100">
            <div
              className="flex justify-between items-center h-full w-full cursor-pointer px-2 py-3.5"
              onClick={(e) => {
                setAnchorElCalendarIssue(e.currentTarget);
              }}
            >
              {selectedDatesNcr[0] === "" ? (
                <p className="text-gray-300">----/--/--</p>
              ) : (
                <p className="text-gray-300">{selectedDatesNcr[0]}</p>
              )}
              <p className="text-gray-300"> s/d </p>
              {selectedDatesNcr[1] === "" ? (
                <p className="text-gray-300">----/--/--</p>
              ) : (
                <p className="text-gray-300">{selectedDatesNcr[1]}</p>
              )}
              <MdCalendarMonth className="w-6 h-6" />
            </div>
          </div>
        </FormGroup>

        <FormGroup sx={{ width: "100%" }}>
          <FormLabel>Tanggal Accident</FormLabel>

          <div className="border-2 rounded-md w-full flex items-center hover:bg-slate-100">
            <div
              className="flex justify-between items-center h-full w-full cursor-pointer px-2 py-3.5"
              onClick={(e) => {
                setAnchorElCalendarRetur(e.currentTarget);
              }}
            >
              {selectedDatesAccident[0] === "" ? (
                <p className="text-gray-300">----/--/--</p>
              ) : (
                <p className="text-gray-300">{selectedDatesAccident[0]}</p>
              )}
              <p className="text-gray-300"> s/d </p>
              {selectedDatesAccident[1] === "" ? (
                <p className="text-gray-300">----/--/--</p>
              ) : (
                <p className="text-gray-300">{selectedDatesAccident[1]}</p>
              )}
              <MdCalendarMonth className="w-6 h-6" />
            </div>
          </div>
        </FormGroup>
        <FormGroup sx={{ width: "100%" }}>
          <FormLabel>Search</FormLabel>
          <TextField
            fullWidth
            placeholder="Search by No. NCR, No. SPK, No. PO, Location Kejadian, Supplier Name atau Manufaktur"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </FormGroup>
        <div className="w-1/2 self-stretch flex items-stretch">
          <button
            onClick={() => navigate("/retur-supplier/add-supplier")}
            className="w-full flex self-stretch items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
          >
            <p>Add Retur</p>
            <IoMdAddCircleOutline className="text-[18px]" />
          </button>
        </div>
      </div>
      <div className="rounded-md shadow-md">
        <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
          <table className="w-full">
            <thead className="bg-[#F4F5FF] border-t border-b border-2">
              <th className="p-3 text-left">No</th>
              <th className="p-3 text-left">Tanggal NCR</th>
              <th className="p-3 text-left">No NCR</th>
              <th className="p-3 text-left">Tanggal Accident</th>
              <th className="p-3 text-left">No SPK</th>
              <th className="p-3 text-left">No PO</th>
              <th className="p-3 text-left">Location</th>
              <th className="p-3 text-left">Supplier Name</th>
              <th className="p-3 text-left">Manufacture</th>
              <th className="p-3 text-left">Type non conformity</th>
              <th className="p-3 text-left">Internal Code</th>
              <th className="p-3 text-left">Material Name</th>
              <th className="p-3 text-left">Qty Material</th>
              <th className="p-3 text-left">No Lot</th>
              <th className="p-3 text-left">Problem</th>
              <th className="p-3 text-left">Affected SPK</th>
              <th className="p-3 text-left">Root Cause</th>
              <th className="p-3 text-left">CAPA</th>
              <th className="p-3 text-left">Pic</th>
              <th className="p-3 text-left">Due Date</th>
              <th className="p-3 text-left">Status</th>
              <th className="p-3 text-center">Action</th>
            </thead>
            <tbody>
              {data && !isPending && data.count > 0 ? (
                data.data.map((item, i) => (
                  <tr key={i}>
                    <td className="p-3">{(page - 1) * fetchLimit + (i + 1)}</td>
                    <td className="p-3">{item.ncr_date}</td>
                    <td className="p-3">{item.ncr_number}</td>
                    <td className="p-3">{item.accident_date}</td>
                    <td className="p-3">{item.spk_number}</td>
                    <td className="p-3">{item.po_number}</td>
                    <td className="p-3">{item.location}</td>
                    <td className="p-3">{item.supplier_name}</td>
                    <td className="p-3">{item.manufacture}</td>
                    <td className="p-3">{item.non_conformity}</td>
                    <td className="p-3">
                      {item.internal_code.map((code, i) => (
                        <p className="truncate" key={i}>
                          {code}
                        </p>
                      ))}
                    </td>
                    <td className="p-3">
                      {item.material.map((mat, i) => (
                        <p className="truncate" key={i}>
                          {mat}
                        </p>
                      ))}
                    </td>
                    <td className="p-3">
                      {item.quantity.map((qty, i) => (
                        <p key={i}>{qty}</p>
                      ))}
                    </td>
                    <td className="p-3">{item.lot_no}</td>
                    <td className="p-3">{item.problem}</td>
                    <td className="p-3">{item.affected_spk}</td>
                    <td className="p-3">{item.root_cause}</td>
                    <td className="p-3">{item.capa}</td>
                    <td className="p-3">{item.pic}</td>
                    <td className="p-3">{item.due_date}</td>
                    <td className="p-3">{item.status}</td>
                    <td className="p-3 text-center">
                      <div className="flex gap-2 items-center justify-center">
                        <IoPencilOutline
                          onClick={() => {
                            navigate(`/retur-supplier/edit/${item.id}`);
                          }}
                          className="text-blue-500 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                        />
                        <FaTrash
                          onClick={() => {
                            setOpenDelete(true);
                            setSelectedRetur(item.id);
                          }}
                          className="border w-[24px] h-full rounded-md p-1 text-red-500 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                        />
                        <IoMdDownload
                          onClick={() => {
                            setIsDownload(true);
                            setSelectedReturSupplier(item);
                          }}
                          className="text-blue-500 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                        />
                      </div>
                    </td>
                  </tr>
                ))
              ) : isPending ? (
                <tr>
                  <td className="p-3 text-center" colSpan={12}>
                    <CircularProgress size={20} />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td className="p-3 text-center text-gray-300" colSpan={12}>
                    Tidak ada data retur supplier
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {data && data.count > 0 ? (
          <div className="w-full flex justify-between pb-8 px-8">
            <button
              onClick={() => {
                if (page > 1) setPage(page - 1);
              }}
              disabled={page === 1}
              className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
            >
              <FaArrowLeftLong /> Previous
            </button>
            <Pagination
              hideNextButton
              hidePrevButton
              shape="rounded"
              page={page}
              count={Math.ceil(data.count / fetchLimit)}
              onChange={(e, page) => setPage(page)}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                />
              )}
            />
            <button
              onClick={() => {
                if (page < Math.ceil(data.count / fetchLimit))
                  setPage(page + 1);
              }}
              disabled={page === Math.ceil(data.count / fetchLimit)}
              className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
            >
              <FaArrowRightLong /> Next
            </button>
          </div>
        ) : null}
      </div>
      {data && data.count > 0 ? (
        <div className="mt-4 text-xs flex gap-3 items-center">
          <p>Show Table</p>
          <p
            onClick={() => setFetchLimit(10)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 10 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            10
          </p>
          <p
            onClick={() => setFetchLimit(50)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 50 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            50
          </p>
          <p
            onClick={() => setFetchLimit(100)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 100 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            100
          </p>
        </div>
      ) : null}

      <ModalInput
        title="Hapus Retur Supplier"
        subtitle="Apakah anda ingin menghapus retur supplier ini?"
        content={<></>}
        open={openDelete}
        setOpen={setOpenDelete}
        hasButton={true}
        buttonText={isPendingDelete ? <CircularProgress size={20} /> : "Hapus"}
        setTriggerFunc={() => mutateDelete(selectedRetur)}
        isDisable={isPendingDelete}
      />

      <RangeCalendar
        anchorElCalendar={anchorElCalendarIssue}
        setAnchorElCalendar={setAnchorElCalendarIssue}
        setSelectedDates={setSelectedDatesNcr}
        searchStartDate={searchStartDateIssue}
        searchEndDate={searchEndDateIssue}
        setSearchStartDate={setSearchStartDateIssue}
        setSearchEndDate={setSearchEndDateIssue}
      />

      <RangeCalendar
        anchorElCalendar={anchorElCalendarRetur}
        setAnchorElCalendar={setAnchorElCalendarRetur}
        setSelectedDates={setSelectedDatesAccident}
        searchStartDate={searchStartDateRetur}
        searchEndDate={searchEndDateRetur}
        setSearchStartDate={setSearchStartDateRetur}
        setSearchEndDate={setSearchEndDateRetur}
      />

      <PrintReturSupplier
        isDownload={isDownload}
        setIsDownload={setIsDownload}
        data={selectedReturSupplier}
      />
    </>
  );
};

export default ReturnSupplierList;
