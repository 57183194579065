import axios from "axios";
import Cookies from "js-cookie";

const token = () => Cookies.get("accessToken");

export const getCustomerList = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/list-customers`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const createCustomer = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
        "Content-Type": "multipart/form-data",
      },
      url: `${process.env.REACT_APP_API_ERP}erp/customer`,
      method: "POST",
      data: {
        file: body.file,
        json: JSON.stringify(body.json),
      },
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateCustomer = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
        "Content-Type": "multipart/form-data",
      },
      url: `${process.env.REACT_APP_API_ERP}erp/customer`,
      method: "POST",
      data: {
        file: body.file,
        json: JSON.stringify(body.json),
      },
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getDetailCustomer = async (id) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/detail-customer?id=${id}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const deleteCustomer = async (id) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/delete-customer?id=${id}`,
      method: "DELETE",
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const deleteImage = async (file, id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_ERP}erp/remove-image?filename=${file}&id=${id}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};
