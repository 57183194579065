import { Search } from "@mui/icons-material";
import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  TextField,
} from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { FaArrowLeftLong, FaArrowRightLong, FaTrash } from "react-icons/fa6";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IoPencilOutline } from "react-icons/io5";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import ModalInput from "../../common/ModalInput";
import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteMps, getMpsExport, getMpsList } from "../../../services/mpsApi";
import * as XLSX from "xlsx";
import moment from "moment";
import { MdCalendarMonth } from "react-icons/md";
import RangeCalendar from "../../common/RangeCalendar";

const TYPE = 2;

const MpsPlatbetList = () => {
  const navigate = useNavigate();
  const date = new Date();

  const [page, setPage] = useState(1);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [selectedMps, setSelectedMps] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(0);
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [selectedDates, setSelectedDates] = useState(["", ""]);
  const [searchStartDate, setSearchStartDate] = useState(date);
  const [searchEndDate, setSearchEndDate] = useState(null);

  const handleExport = () => {
    mutateExportList();
  };

  const exportXlsx = (dataExport) => {
    const rows = dataExport.data.map((row, i) => ({
      no: i + 1,
      mps_no: row.mps_no,
      mps: row.mps_name,
      status:
        row.status === 1
          ? "Terpakai"
          : row.status === 2
          ? "Tidak terpakai"
          : row.status === 3
          ? "Belum terpakai"
          : row.status === 4
          ? "Pernah terpakai"
          : "Tumpul",
      size_along: row.size_along,
      size_across: row.size_across,
      eye_along: row.eye_along,
      eye_across: row.eye_across,
      gap_along: row.gap_along,
      gap_across: row.gap_across,
      shape: row.die_cut_shape,
      used_date: row.used_date ? format(row.used_date, "yyyy-MM-dd") : "-",
      specification: row.specification === 1 ? "Ada" : "Tidak Ada",
      date: row.mps_date ? format(row.mps_date, "yyyy-MM-dd") : "-",
    }));

    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Daftar MPS Platbet");

    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          "No",
          "MPS No",
          "Nama MPS",
          "Status",
          "Ukuran (Along)",
          "Ukuran (Across)",
          "Total Mata (Along)",
          "Total Mata (Across)",
          "Gap (Along)",
          "Gap (Across)",
          "Bentuk Die Cut",
          "Tanggal Terakhir Digunakan",
          "Specification",
          "Tanggal",
        ],
      ],
      {
        origin: "A1",
      }
    );

    const max_width = rows.reduce((w, r) => Math.max(w, r.no.length), 10);
    worksheet["!cols"] = [{ wch: max_width }];

    XLSX.writeFile(
      workbook,
      "Daftar MPS Platbet" + moment().format("DD_MM_YYYY") + ".xlsx",
      { compression: true }
    );
  };

  const { data, isFetching, refetch } = useQuery({
    queryKey: [
      "mps-platbet-list",
      page,
      fetchLimit,
      search,
      status,
      selectedDates[1],
      TYPE,
    ],
    queryFn: () =>
      getMpsList({
        limit: fetchLimit,
        offset: page,
        search,
        status,
        start: selectedDates[0],
        end: selectedDates[1],
        type: TYPE,
      }),
  });

  const {
    mutate: mutateExportList,
    isPending: isPendingExport,
    // data: dataExport,
  } = useMutation({
    mutationFn: () => getMpsExport(search, status, TYPE),
    onSuccess: (data) => exportXlsx(data),
  });

  const { mutate: mutateDelete, isPending } = useMutation({
    mutationFn: deleteMps,
    onSuccess: () => {
      refetch();
      setOpenDelete(false);
    },
    onError: (err) => setErrorMessage(err.message.id),
  });

  useEffect(() => {
    if (!openDelete) setErrorMessage("");
  }, [openDelete]);

  return (
    <>
      <div className="flex mb-4 gap-2">
        <TextField
          fullWidth
          placeholder="Search by name"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <div className="w-full border-2 rounded-md flex items-center hover:bg-slate-100">
          <div
            className="flex justify-between items-center h-full w-full cursor-pointer px-2"
            onClick={(e) => setAnchorElCalendar(e.currentTarget)}
          >
            {selectedDates[0] === "" ? (
              <p className="text-gray-300">----/--/--</p>
            ) : (
              <p className="text-gray-300">{selectedDates[0]}</p>
            )}
            <p className="text-gray-300"> s/d </p>
            {selectedDates[1] === "" ? (
              <p className="text-gray-300">----/--/--</p>
            ) : (
              <p className="text-gray-300">{selectedDates[1]}</p>
            )}
            <MdCalendarMonth className="w-6 h-6" />
          </div>
        </div>
        <Select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          sx={{ width: "100%" }}
        >
          <MenuItem value={0}>Semua status</MenuItem>
          <MenuItem value={1}>Terpakai</MenuItem>
          <MenuItem value={2}>Tidak terpakai</MenuItem>
          <MenuItem value={3}>Belum terpakai</MenuItem>
          <MenuItem value={4}>Pernah terpakai</MenuItem>
          <MenuItem value={5}>Tumpul</MenuItem>
        </Select>
        <button
          onClick={() => navigate("/mps-platbet/create-mps")}
          className="w-1/4 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
        >
          <p>Add MPS</p>
          <IoMdAddCircleOutline className="text-[18px]" />
        </button>
      </div>

      <div className="w-full mb-4">
        <button
          className="rounded-md p-2 px-8 border-2 float-right mb-4 cursor-pointer hover:bg-slate-100 disabled:bg-gray-300 disabled:text-white disabled:cursor-default"
          disabled={isPendingExport}
          onClick={handleExport}
        >
          {isPendingExport ? <CircularProgress size={20} /> : "Export MPS"}
        </button>
      </div>

      <div className="rounded-md shadow-md">
        <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
          <table className="w-full">
            <thead className="bg-[#F4F5FF] border-t border-b border-2">
              <th className="p-3 text-left">No</th>
              <th className="p-3 text-left">MPS No</th>
              <th className="p-3 text-left">Nama MPS</th>
              <th className="p-3 text-left">Status</th>
              <th className="p-3 text-left">Ukuran (Along)</th>
              <th className="p-3 text-left">Ukuran (Across)</th>
              <th className="p-3 text-left">Total Mata (Along)</th>
              <th className="p-3 text-left">Total Mata (Across)</th>
              <th className="p-3 text-left">Gap (Along)</th>
              <th className="p-3 text-left">Gap (Across)</th>
              <th className="p-3 text-left">Bentuk Die Cut</th>
              <th className="p-3 text-left">Tanggal Terakhir Digunakan</th>
              <th className="p-3 text-left">Specification</th>
              <th className="p-3 text-left">Tanggal</th>
              <th className="p-3 text-left">Action</th>
            </thead>
            <tbody>
              {data && data.count > 0 && !isFetching ? (
                data.data.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td className="p-3 text-left">
                        {(page - 1) * fetchLimit + (i + 1)}
                      </td>
                      <td className="p-3 text-left">{item.mps_no}</td>
                      <td className="p-3 text-left">{item.mps_name}</td>
                      <td className="p-3 text-left">
                        {item.status === 1
                          ? "Terpakai"
                          : item.status === 2
                          ? "Tidak terpakai"
                          : item.status === 3
                          ? "Belum terpakai"
                          : item.status === 4
                          ? "Pernah terpakai"
                          : "Tumpul"}
                      </td>
                      <td className="p-3 text-left">{item.size_along}</td>
                      <td className="p-3 text-left">{item.size_across}</td>
                      <td className="p-3 text-left">{item.eye_along}</td>
                      <td className="p-3 text-left">{item.eye_across}</td>
                      <td className="p-3 text-left">{item.gap_along}</td>
                      <td className="p-3 text-left">{item.gap_across}</td>
                      <td className="p-3 text-left">{item.die_cut_shape}</td>
                      <td className="p-3 text-left">
                        {item.used_date
                          ? format(item.used_date, "yyyy-MM-dd")
                          : "-"}
                      </td>
                      <td className="p-3 text-left">
                        {item.specification === 1 ? "Ada" : "Tidak Ada"}
                      </td>
                      <td className="p-3 text-left">
                        {item.mps_date
                          ? format(item.mps_date, "yyyy-MM-dd")
                          : "-"}
                      </td>
                      <td className="p-3 text-center">
                        <div className="flex gap-2 items-center">
                          <FaTrash
                            onClick={() => {
                              setSelectedMps(item.id);
                              setOpenDelete(true);
                            }}
                            className={`border w-[24px] h-full rounded-md p-1 text-red-500 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out`}
                          />
                          <IoPencilOutline
                            onClick={() =>
                              navigate(`/mps-platbet/edit/${item.id}`)
                            }
                            className="text-blue-500 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : isFetching ? (
                <td className="p-3 text-center text-gray-300" colSpan={18}>
                  <CircularProgress size={20} />
                </td>
              ) : (
                <td className="p-3 text-center text-gray-300" colSpan={18}>
                  Tidak ada data MPS
                </td>
              )}
            </tbody>
          </table>
        </div>

        {data && data.count > 0 ? (
          <div className="w-full flex justify-between pb-8 px-8">
            <button
              onClick={() => {
                if (page > 1) setPage(page - 1);
              }}
              disabled={page === 1}
              className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
            >
              <FaArrowLeftLong /> Previous
            </button>
            <Pagination
              hideNextButton
              hidePrevButton
              shape="rounded"
              page={page}
              count={Math.ceil(data.count / fetchLimit)}
              onChange={(e, page) => setPage(page)}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                />
              )}
            />
            <button
              onClick={() => {
                if (page < Math.ceil(data.count / fetchLimit))
                  setPage(page + 1);
              }}
              disabled={page === Math.ceil(data.count / fetchLimit)}
              className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
            >
              <FaArrowRightLong /> Next
            </button>
          </div>
        ) : null}
      </div>
      {data && data.count > 0 ? (
        <div className="mt-4 text-xs flex gap-3 items-center">
          <p>Show Table</p>
          <p
            onClick={() => setFetchLimit(10)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 10 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            10
          </p>
          <p
            onClick={() => setFetchLimit(50)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 50 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            50
          </p>
          <p
            onClick={() => setFetchLimit(100)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 100 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            100
          </p>
        </div>
      ) : null}

      <ModalInput
        title="Hapus MPS Flexible & Platbet"
        subtitle="Apakah anda yakin ingin menghapus MPS ini?"
        content={
          <>
            <p className="text-red-500">{errorMessage}</p>
          </>
        }
        open={openDelete}
        setOpen={setOpenDelete}
        hasButton={true}
        buttonText="Yes"
        setTriggerFunc={() => {
          mutateDelete(selectedMps);
        }}
        isDisable={isPending}
      />

      <RangeCalendar
        anchorElCalendar={anchorElCalendar}
        setAnchorElCalendar={setAnchorElCalendar}
        setSelectedDates={setSelectedDates}
        searchStartDate={searchStartDate}
        searchEndDate={searchEndDate}
        setSearchStartDate={setSearchStartDate}
        setSearchEndDate={setSearchEndDate}
      />
    </>
  );
};

export default MpsPlatbetList;
