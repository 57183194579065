import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import {
  Snackbar,
  IconButton,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import Cookies from "js-cookie";
import Logo from "../../../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import HOC from "../../HOC/index";
import { useDispatch } from "react-redux";
import { AiFillEyeInvisible } from "react-icons/ai";
import { MdVisibility } from "react-icons/md";
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import { setUser } from "../../../redux/features/auth/authSlice";
import { login } from "../../../services/authApi";
import * as yup from "yup";

const validationSchema = yup.object({
  email: yup.string().email().required("Email wajib diisi"),
  password: yup.string().required("Password wajib diisi"),
});

const EmailLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [statusCode, setStatusCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClose = (_, reason) => {
    if (reason === "clickaway") return;
    setStatusCode(false);
    setErrorMessage("");
  };

  const changePage = () => {
    const url = `${process.env.REACT_APP_LINK_URL}`;
    window.location.href = url;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (value) => {
      mutateLogin(value);
    },
    validateOnMount: true,
  });

  const {
    mutate: mutateLogin,
    data,
    isPending,
  } = useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      if (data === undefined) return;
      dispatch(
        setUser({
          accessToken: data.token.access,
          accessExpired: data.token.access_expired,
          renewToken: data.token.renew,
          renewExpired: data.token.renew_expired,
          img_profile: data.img_profile,
          fullname: data.fullname,
          email: data.email,
          role: data.role,
          inventory: data.inventory ? data.inventory : [],
          working_order: data.working_order ? data.working_order : [],
          invoice: data.invoice ? data.invoice : [],
          gear: data.gear ? data.gear : [],
          die_cut: data.die_cut ? data.die_cut : [],
          equipment: data.equipment ? data.equipment : [],
          retur_customer: data.retur_customer ? data.retur_customer : [],
          supplier: data.supplier ? data.supplier : [],
          quotation: data.quotation ? data.quotation : [],
          customer: data.customer ? data.customer : [],
          user_management: data.user_management ? data.user_management : [],
          retur_supplier: data.retur_supplier ? data.retur_supplier : [],
          mps_flexo: data.mps_flexo ? data.mps_flexo : [],
          mps: data.mps ? data.mps : [],
          plate_usage: data.plate_usage ? data.plate_usage : [],
          finishgood: data.finishgood ? data.finishgood : [],
        })
      );
      if (Cookies.get("accessToken") !== undefined || null || "") {
        navigate("/gear-management"); //TODO: CHANGE TO DASHBOARD
      } else {
        navigate("/login");
      }
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  return (
    <div className="bg-white">
      <div className="flex flex-row justify-center h-screen">
        <div className="hidden md:order-first bg-cover lg:block lg:w-1/2">
          <div className="flex items-center h-full px-20 bg-[#17469E]">
            <div className="w-full my-10 flex justify-center items-center bg-white bg-opacity-40 rounded-md p-4">
              <img src={Logo} alt="" className="mt-16 lg:my-0" />
            </div>
          </div>
        </div>

        <div className="w-full lg:w-1/2 flex justify-center items-center px-12">
          <div className="border-2 border-gray-300 rounded-md p-8 w-fit h-fit">
            <img
              src={Logo}
              alt=""
              className="mt-16 lg:my-0 w-[100px] lg:w-[200px]"
            />
            <h2 className="text-3xl font-bold text-black my-10 lg:my-4">
              Sign In
            </h2>
            <p className="mb-8">
              Segera akses akun Anda untuk memulai pencetakan label berkualitas
              tinggi dan mengoptimalkan efisiensi bisnis Anda.
            </p>

            <form onSubmit={formik.handleSubmit}>
              {" "}
              <div>
                <TextField
                  value={formik.values.email}
                  onChange={(e) =>
                    formik.setFieldValue("email", e.target.value)
                  }
                  variant="standard"
                  label="Email"
                  helperText={formik.touched.email && formik.errors.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  className="w-full"
                  fullWidth
                />
              </div>
              <div className="mt-4 flex items-center">
                <TextField
                  variant="standard"
                  value={formik.values.password}
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  onChange={(e) =>
                    formik.setFieldValue("password", e.target.value)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  className="w-full"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPassword ? (
                          <AiFillEyeInvisible
                            onClick={() => setShowPassword(!showPassword)}
                            className="cursor-pointer"
                          />
                        ) : (
                          <MdVisibility
                            onClick={() => setShowPassword(!showPassword)}
                            className="cursor-pointer"
                          />
                        )}
                      </InputAdornment>
                    ),
                    style: { textTransform: "uppercase" },
                  }}
                  fullWidth
                />
              </div>
              <p className="text-red-500 text-sm">{errorMessage}</p>
              <div className="mt-4">
                <div className="text-right text-xs font-display text-indigo-600 hover:text-indigo-800 cursor-pointer">
                  <Link to="/reset-password">
                    <p className="underline decoration-solid">
                      Forgot Password?
                    </p>
                  </Link>
                </div>
              </div>
              <div className="mt-10 flex items-center">
                {!isPending ? (
                  <button
                    className="bg-[#17469E] text-white w-full tracking-wide font-semibold p-4 rounded-lg 
                    focus:outline-none focus:shadow-outline hover:bg-indigo-600 shadow-lg transform duration-200 hover:scale-100
                    disabled:bg-slate-400"
                    type="submit"
                    disabled={isPending}
                  >
                    <div className="absolute right-0 inset-y-0 mr-4">
                      <BsFillArrowRightSquareFill className="h-full w-6" />
                    </div>
                    <div className="inline-block">
                      {isPending ? <CircularProgress /> : <p>Sign In</p>}
                    </div>
                  </button>
                ) : (
                  <div className="w-full text-center">
                    <CircularProgress size={20} color="primary" />
                  </div>
                )}
              </div>
            </form>
            <div className="mt-4 flex items-center">
              <button
                onClick={changePage}
                className="bg-white border-2 w-full tracking-wide font-semibold p-4 rounded-lg 
                    focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-white shadow-lg transform duration-200 hover:scale-100
                    disabled:bg-slate-400"
              >
                Go To Backstage
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={statusCode}
        autoHideDuration={5000}
        onClose={() => {
          setStatusCode(false);
          setErrorMessage("");
        }}
        message={errorMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
};

export default HOC(EmailLogin, "Login-required");
