import { Search } from "@mui/icons-material";
import {
  InputAdornment,
  PaginationItem,
  TextField,
  Pagination,
  FormGroup,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RangeCalendar from "../../common/RangeCalendar";
import { useMutation } from "@tanstack/react-query";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { MdCalendarMonth } from "react-icons/md";
import { getFinihsGoodList } from "../../../services/finishgoodApi";

const FinishgoodList = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [anchorElInCalendar, setAnchorElInCalendar] = useState(null);
  const [anchorElOutCalendar, setAnchorElOutCalendar] = useState(null);
  const [searchStartDate, setSearchStartDate] = useState(null);
  const [searchEndDate, setSearchEndDate] = useState(null);
  const [selectedInDates, setSelectedInDates] = useState(["", ""]);
  const [selectedOutDates, setSelectedOutDates] = useState(["", ""]);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [page, setPage] = useState(1);

  const {
    mutate: mutateListFinishgood,
    data,
    isPending: finishgoodPending,
  } = useMutation({
    mutationFn: getFinihsGoodList,
  });

  useEffect(() => {
    const body = {
      limit: fetchLimit,
      offset: page,
      search: search,
      inStart: selectedInDates[0],
      inEnd: selectedInDates[1],
      outStart: selectedOutDates[0],
      outEnd: selectedOutDates[1],
    };
    mutateListFinishgood(body);
  }, [fetchLimit, page, search, selectedInDates, selectedOutDates]);

  useEffect(() => {
    setPage(1);
  }, [fetchLimit, search]);

  return (
    <>
      <div className="flex mb-4 gap-2 items-stretch">
        <FormGroup sx={{ width: "100%" }}>
          <FormLabel>Stock In</FormLabel>
          <div className="border-2 rounded-md w-full flex items-center hover:bg-slate-100">
            <div
              className="flex justify-between items-center h-full w-full cursor-pointer px-2 py-3.5"
              onClick={(e) => setAnchorElInCalendar(e.currentTarget)}
            >
              {selectedInDates[0] === "" ? (
                <p className="text-gray-300">----/--/--</p>
              ) : (
                <p className="text-gray-300">{selectedInDates[0]}</p>
              )}
              <p className="text-gray-300"> s/d </p>
              {selectedInDates[1] === "" ? (
                <p className="text-gray-300">----/--/--</p>
              ) : (
                <p className="text-gray-300">{selectedInDates[1]}</p>
              )}
              <MdCalendarMonth className="w-6 h-6" />
            </div>
          </div>
        </FormGroup>
        <FormGroup sx={{ width: "100%" }}>
          <FormLabel>Stock Out</FormLabel>
          <div className="border-2 rounded-md w-full flex items-center hover:bg-slate-100">
            <div
              className="flex justify-between items-center h-full w-full cursor-pointer px-2 py-3.5"
              onClick={(e) => setAnchorElOutCalendar(e.currentTarget)}
            >
              {selectedOutDates[0] === "" ? (
                <p className="text-gray-300">----/--/--</p>
              ) : (
                <p className="text-gray-300">{selectedOutDates[0]}</p>
              )}
              <p className="text-gray-300"> s/d </p>
              {selectedOutDates[1] === "" ? (
                <p className="text-gray-300">----/--/--</p>
              ) : (
                <p className="text-gray-300">{selectedOutDates[1]}</p>
              )}
              <MdCalendarMonth className="w-6 h-6" />
            </div>
          </div>
        </FormGroup>
        <FormGroup sx={{ width: "100%" }}>
          <FormLabel>Search</FormLabel>
          <TextField
            fullWidth
            placeholder="Nama Label/Internal Code"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </FormGroup>
      </div>

      <>
        <div className="rounded-md shadow-md">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <th className="p-3 text-left">No</th>
                <th className="p-3 text-left">Internal Code</th>
                <th className="p-3 text-left">Label Name</th>
                <th className="p-3 text-left">Stock on Hand</th>
              </thead>
              <tbody>
                {data && data.data?.length > 0 && !finishgoodPending ? (
                  data.data?.map((item, i) => (
                    <tr className="border-t border-l border-2">
                      <td className="p-3">
                        {(page - 1) * fetchLimit + (i + 1)}
                      </td>
                      <td
                        className="p-3 text-blue-500 cursor-pointer hover:text-slate-500"
                        onClick={() =>
                          navigate(`/finishgood/detail/${item.costing_id}`)
                        }
                      >
                        {item.internal_code}
                      </td>
                      <td>{item.name_label}</td>
                      <td className="p-3">{item.stock_on_hand}</td>
                    </tr>
                  ))
                ) : finishgoodPending ? (
                  <tr>
                    <td colSpan={7} className="text-center p-3">
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                ) : (
                  <tr className="border-t border-l border-2">
                    <td className="p-3 text-center" colSpan={16}>
                      Tidak ada data
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {data && data?.count > 0 ? (
            <div className="w-full flex justify-between pb-8 px-8">
              <button
                onClick={() => {
                  if (page > 1) setPage(page - 1);
                }}
                disabled={page === 1}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowLeftLong /> Previous
              </button>
              <Pagination
                hideNextButton
                hidePrevButton
                shape="rounded"
                page={page}
                count={Math.ceil(data?.count / fetchLimit)}
                onChange={(e, page) => setPage(page)}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
              <button
                onClick={() => {
                  if (page < Math.ceil(data?.count / fetchLimit))
                    setPage(page + 1);
                }}
                disabled={page === Math.ceil(data?.count / fetchLimit)}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowRightLong /> Next
              </button>
            </div>
          ) : null}
        </div>
        {data && data?.count > 0 ? (
          <div className="mt-4 text-xs flex gap-3 items-center">
            <p>Show Table</p>
            <p
              onClick={() => setFetchLimit(10)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 10 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              10
            </p>
            <p
              onClick={() => setFetchLimit(50)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 50 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              50
            </p>
            <p
              onClick={() => setFetchLimit(100)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 100 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              100
            </p>
          </div>
        ) : null}
      </>

      <RangeCalendar
        anchorElCalendar={anchorElInCalendar}
        setAnchorElCalendar={setAnchorElInCalendar}
        setSelectedDates={setSelectedInDates}
        searchStartDate={searchStartDate}
        searchEndDate={searchEndDate}
        setSearchStartDate={setSearchStartDate}
        setSearchEndDate={setSearchEndDate}
      />

      <RangeCalendar
        anchorElCalendar={anchorElOutCalendar}
        setAnchorElCalendar={setAnchorElOutCalendar}
        setSelectedDates={setSelectedOutDates}
        searchStartDate={searchStartDate}
        searchEndDate={searchEndDate}
        setSearchStartDate={setSearchStartDate}
        setSearchEndDate={setSearchEndDate}
      />
    </>
  );
};

export default FinishgoodList;
