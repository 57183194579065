import { secondsToHours, secondsToMinutes } from "date-fns";
import { useEffect, useState } from "react";
import { FaPlayCircle, FaStopCircle } from "react-icons/fa";

const UpdateJobTimer = ({
  timerInfo,
  formik,
  activeButton,
  setActiveButton,
  i,
}) => {
  const [isStart, setIsStart] = useState(false);
  const [count, setCount] = useState(formik?.values[timerInfo.keyName] || 0);
  const [prevCount, setPrevCount] = useState(
    formik?.values[timerInfo.keyName] || 0
  );

  useEffect(() => {
    setActiveButton(isStart ? i : null);

    let intervalId;
    if (isStart) {
      intervalId = setInterval(() => {
        setCount((prevCount) => prevCount + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isStart]);

  useEffect(() => {
    if (!isStart) {
      if (count !== prevCount) {
        const total_time = formik?.values?.total_time || 0;
        formik?.setFieldValue(timerInfo.keyName, count, false);
        formik?.setFieldValue(
          "total_time",
          total_time + count - prevCount,
          false
        );
        setPrevCount(count);
      }
    }
  }, [isStart, count]);

  useEffect(() => {
    if (!isStart && formik?.values[timerInfo.keyName] !== count) {
      setCount(formik?.values[timerInfo.keyName] || 0);
      setPrevCount(formik?.values[timerInfo.keyName] || 0);
    }
  }, [formik.values[timerInfo.keyName]]);

  return (
    <div className="w-full">
      <div className="mx-auto">
        <button
          type="button"
          className={`w-full border-2 p-2 rounded-md flex items-center gap-2 ${
            activeButton === null
              ? "hover:bg-slate-100"
              : activeButton !== i
              ? "bg-gray-100 cursor-default"
              : ""
          }`}
          onClick={() => {
            if (activeButton === null || activeButton === i) {
              setIsStart(!isStart);
            }
          }}
        >
          {!isStart ? (
            <FaPlayCircle className="text-green-500" />
          ) : (
            <FaStopCircle className="text-red-500" />
          )}
          {timerInfo?.name}
        </button>
        <p className="text-left text-gray-500">
          {secondsToHours(count).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
          h -{" "}
          {secondsToMinutes(count).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
          m -{" "}
          {(count % 60).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
          s
        </p>
      </div>
    </div>
  );
};

export default UpdateJobTimer;
