import { Button, Popover } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SingleCalendar = ({
  anchorElCalendarStart,
  setAnchorElCalendarStart,
  startDate,
  setStartDate,
  showAll,
  disableFuture,
}) => {
  const openCalendarStart = Boolean(anchorElCalendarStart);

  const handleCloseCalendarStart = () => {
    setAnchorElCalendarStart(null);
  };

  return (
    <Popover
      open={openCalendarStart}
      anchorEl={anchorElCalendarStart}
      onClose={handleCloseCalendarStart}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{ width: "100%" }}
    >
      <div className="flex flex-wrap justify-center p-2">
        <DatePicker
          selected={startDate ? new Date(startDate) : new Date()}
          onChange={(date) => {
            setStartDate(date);
          }}
          startDate={startDate ? startDate : new Date()}
          maxDate={disableFuture ? new Date() : null}
          inline
          className="w-full"
        />
        {showAll === undefined ? (
          <div className="flex w-full mt-2 gap-1">
            <Button
              fullWidth
              variant="outlined"
              onClick={() => setStartDate(null)}
            >
              Hapus
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={() => setAnchorElCalendarStart(null)}
            >
              Select
            </Button>
          </div>
        ) : null}
      </div>
    </Popover>
  );
};

export default SingleCalendar;
