import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { renewTokenUser } from "./redux/features/auth/authSlice";

// =========================== Pages =========================== //

import EmailLogin from "./components/screen/auth/EmailLogin";
import ForgetPassword from "./components/screen/auth/ForgetPassword";
import NewPassword from "./components/screen/auth/NewPassword";

import MaterialPage from "./pages/material/MaterialPage";
import AddMaterialPage from "./pages/material/AddMaterialPage";
import DetailMaterialPage from "./pages/material/DetailMaterialPage";

import CostingPage from "./pages/costing/CostingPage";
import AddCostingPage from "./pages/costing/AddCostingPage";
import DetailCostingPage from "./pages/costing/DetailCostingPage";
import EditCostingPage from "./pages/costing/EditCostingPage";

import StockAdjustmentPage from "./pages/stockAdjustment/StockAdjustmentPage";
import AddStockAdjustmentPage from "./pages/stockAdjustment/AddStockAdjustmentPage";

import GearManagementPage from "./pages/GearManagementPage";

import DieCutPage from "./pages/DieCutPage";

import SupplierPage from "./pages/supplier/SupplierPage";
import SupplierDetailPage from "./pages/supplier/SupplierDetailPage";

import QuotationPage from "./pages/quotation/QuotationPage";
import AddQuotationPage from "./pages/quotation/AddQuotationPage";
import DetailQuotationPage from "./pages/quotation/DetailQuotationPage";
import EditQuotationPage from "./pages/quotation/EditQuotationPage";

import CustomerManagementPage from "./pages/customerManagement/CustomerManagementPage";
import DetailCustomerManagementPage from "./pages/customerManagement/DetailCustomerManagementPage";

import UserManagementPage from "./pages/userManagement/UserManagementPage";
import AddUserPage from "./pages/userManagement/AddUserPage";
import EditUserPage from "./pages/userManagement/EditUserPage";
import EquipmentManagementPage from "./pages/equipmentManagement/EquipmentManagementPage";
import EquipmentManagementDetailPage from "./pages/equipmentManagement/EquipmentManagementDetailPage";

import SupplierPoPage from "./pages/supplierPo/SupplierPoPage";
import AddSupplierPoPage from "./pages/supplierPo/AddSupplierPoPage";
import DetailSupplierPoPage from "./pages/supplierPo/DetailSupplierPoPage";
import EditSupplierPoPage from "./pages/supplierPo/EditSupplierPoPage";

import InvoiceListPage from "./pages/supplierInvoice/InvoiceListPage";
import InvoiceDetailPage from "./pages/supplierInvoice/InvoiceDetailPage";

import CustomerListPage from "./pages/customerInvoice/CustomerListPage";
import AddCustomerPage from "./pages/customerInvoice/AddCustomerPage";
import EditCustomerPage from "./pages/customerInvoice/EditCustomerPage";

import DeliveryOrderPage from "./pages/deliveryOrder/DeliveryOrderPage";
import AddDeliveryOrderPage from "./pages/deliveryOrder/AddDeliveryOrderPage";
import DetailDeliveryOrderPage from "./pages/deliveryOrder/DetailDeliveryOrderPage";

import ReturnCustomerPage from "./pages/returnCustomer/ReturnCustomerPage";
import AddReturnCustomerPage from "./pages/returnCustomer/AddReturnCustomerPage";
import DetailReturnCustomerPage from "./pages/returnCustomer/DetailReturnCustomerPage";

import SalesOrderPage from "./pages/salesOrder/SalesOrderPage";
import AddSalesOrderPage from "./pages/salesOrder/AddSalesOrderPage";
import EditSalesOrderPage from "./pages/salesOrder/EditSalesOrderPage ";

import SpkPage from "./pages/spk/SpkPage";
import AddSpkPage from "./pages/spk/AddSpkPage";
import DetailSpkPage from "./pages/spk/DetailSpkPage";

import SchedulePage from "./pages/schedule/SchedulePage";
import ScheduleTakeJobPage from "./pages/schedule/ScheduleTakeJobPage";
import ScheduleDetailPage from "./pages/schedule/ScheduleDetailPage";

import ReturnSupplierPage from "./pages/returnSupplier/ReturnSupplierPage";
import AddReturnSupplierPage from "./pages/returnSupplier/AddReturnSupplierPage";
import EditReturnSupplierPage from "./pages/returnSupplier/EditReturnSupplierPage";

import MpsFlexoPage from "./pages/mps/MpsFlexoPage";
import AddMpsFlexoPage from "./pages/mps/AddMpsFlexoPage";

import MpsFlexiblePlatbetPage from "./pages/mps/MpsFlexiblePlatbetPage";
import AddMpsFlexiblePlatbetPage from "./pages/mps/AddMpsFlexiblePlatbetPage";
import EditMpsFlexiblePlatbetPage from "./pages/mps/EditMpsFlexiblePlatbetPage";

import PlatUsagePage from "./pages/platUsage/PlatUsagePage";
import AddPlatUsagePage from "./pages/platUsage/AddPlatUsagePage";

import FinishgoodListPage from "./pages/finishgood/FinishgoodListPage";
import DetailFinishgoodPage from "./pages/finishgood/DetailFinishgoodPage";
import EditScheduleTakeJobPage from "./pages/schedule/EditScheduleTakeJobPage";

import MpsPlatbetPage from "./pages/mpsPlatbet/MpsPlatbetPage";
import AddMpsPlatbetPage from "./pages/mpsPlatbet/AddMpsPlatbetPage";
import EditMpsPlatbetPage from "./pages/mpsPlatbet/EditMpsPlatbetPage";

function App() {
  const dispatch = useDispatch();

  setInterval(() => {
    dispatch(renewTokenUser());
  }, 600000);

  return (
    <div className="App">
      <Routes>
        {/* auth pages */}
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<EmailLogin />} />
        <Route path="/reset-password" element={<ForgetPassword />} />
        <Route path="/new-password/:param" element={<NewPassword />} />
        {/* material */}
        <Route path="/material" element={<MaterialPage />} />
        <Route path="/material/add-material" element={<AddMaterialPage />} />
        <Route path="/material/detail/:id" element={<DetailMaterialPage />} />
        {/* costing */}
        <Route path="/costing" element={<CostingPage />} />
        <Route path="/costing/add-costing" element={<AddCostingPage />} />
        <Route path="/costing/detail/:id" element={<DetailCostingPage />} />
        <Route path="/costing/edit/:id" element={<EditCostingPage />} />
        {/* Delivery Order */}
        <Route path="/do" element={<DeliveryOrderPage />} />
        <Route path="/do/create-do" element={<AddDeliveryOrderPage />} />
        <Route path="/do/detail/:id" element={<DetailDeliveryOrderPage />} />
        {/* stock adjustment */}
        <Route path="/stock-adjustment" element={<StockAdjustmentPage />} />
        <Route
          path="/stock-adjustment/create-stock-adjustment"
          element={<AddStockAdjustmentPage />}
        />
        {/* die cut */}
        <Route path="/diecut-management" element={<DieCutPage />} />
        {/* retur customer */}
        <Route path="/retur-customer" element={<ReturnCustomerPage />} />
        <Route
          path="/retur-customer/add-customer"
          element={<AddReturnCustomerPage />}
        />
        <Route
          path="/retur-customer/edit/:id"
          element={<DetailReturnCustomerPage />}
        />
        {/* retur supplier */}
        <Route path="/retur-supplier" element={<ReturnSupplierPage />} />
        <Route
          path="/retur-supplier/add-supplier"
          element={<AddReturnSupplierPage />}
        />
        <Route
          path="/retur-supplier/edit/:id"
          element={<EditReturnSupplierPage />}
        />
        {/* equipment */}
        <Route
          path="/equipment-management"
          element={<EquipmentManagementPage />}
        />
        <Route
          path="/equipment-management/detail/:id"
          element={<EquipmentManagementDetailPage />}
        />
        {/* gear management */}
        <Route path="/gear-management" element={<GearManagementPage />} />
        {/* supplier */}
        <Route path="/supplier" element={<SupplierPage />} />
        <Route
          path="/supplier/supplier-detail/:id"
          element={<SupplierDetailPage />}
        />
        {/* quotation */}
        <Route path="/quotation" element={<QuotationPage />} />
        <Route path="/quotation/add-quotation" element={<AddQuotationPage />} />
        <Route path="/quotation/detail/:id" element={<DetailQuotationPage />} />
        <Route path="/quotation/edit/:id" element={<EditQuotationPage />} />
        {/* sales order */}
        <Route path="sales-order" element={<SalesOrderPage />} />
        <Route path="sales-order/create-so" element={<AddSalesOrderPage />} />
        <Route path="sales-order/detail/:id" element={<EditSalesOrderPage />} />
        {/* SPK */}
        <Route path="spk" element={<SpkPage />} />
        <Route path="spk/create-spk" element={<AddSpkPage />} />
        <Route path="spk/detail/:id" element={<DetailSpkPage />} />
        {/* Schedule */}
        <Route path="schedule" element={<SchedulePage />} />
        <Route path="schedule/detail/:id" element={<ScheduleDetailPage />} />
        <Route path="schedule/take-job" element={<ScheduleTakeJobPage />} />
        <Route
          path="schedule/take-job/:spkId"
          element={<EditScheduleTakeJobPage />}
        />
        {/* mps flexo */}
        <Route path="mps-flexo" element={<MpsFlexoPage />} />
        <Route path="mps-flexo/create-mps" element={<AddMpsFlexoPage />} />
        {/* mps flexible */}
        <Route path="mps-flexible" element={<MpsFlexiblePlatbetPage />} />
        <Route path="mps-flexible/create-mps" element={<AddMpsFlexiblePlatbetPage />} />
        <Route path="mps-flexible/edit/:id" element={<EditMpsFlexiblePlatbetPage />} />
        {/* mps platbet */}
        <Route path="mps-platbet" element={<MpsPlatbetPage />} />
        <Route path="mps-platbet/create-mps" element={<AddMpsPlatbetPage />} />
        <Route path="mps-platbet/edit/:id" element={<EditMpsPlatbetPage />} />
        {/* plat usage report */}
        <Route path="plat-usage" element={<PlatUsagePage />} />
        <Route
          path="plat-usage/create-plat-usage"
          element={<AddPlatUsagePage />}
        />
        {/* customer management */}
        <Route
          path="/customer-management"
          element={<CustomerManagementPage />}
        />
        <Route
          path="/customer-management/detail/:id"
          element={<DetailCustomerManagementPage />}
        />
        {/* user management */}
        <Route path="/user-management" element={<UserManagementPage />} />
        <Route path="/user-management/add-user" element={<AddUserPage />} />
        <Route
          path="/user-management/edit-user/:id"
          element={<EditUserPage />}
        />
        {/* Finishgood Inventory */}
        <Route path="/finishgood" element={<FinishgoodListPage />} />
        <Route
          path="/finishgood/detail/:id"
          element={<DetailFinishgoodPage />}
        />
        {/* Supplier PO */}
        <Route path="/sv-po" element={<SupplierPoPage />} />
        <Route path="/sv-po/create-po" element={<AddSupplierPoPage />} />
        <Route path="/sv-po/detail/:id" element={<DetailSupplierPoPage />} />
        <Route path="/sv-po/edit/:id" element={<EditSupplierPoPage />} />
        {/* Supplier Invoice */}
        <Route path="/sv-invoice" element={<InvoiceListPage />} />
        <Route path="/sv-invoice/detail/:id" element={<InvoiceDetailPage />} />
        {/* Customer Invoice */}
        <Route path="/customer-invoice" element={<CustomerListPage />} />
        <Route
          path="/customer-invoice/create-invoice"
          element={<AddCustomerPage />}
        />
        <Route
          path="/customer-invoice/detail/:id"
          element={<EditCustomerPage />}
        />
      </Routes>
    </div>
  );
}

export default App;
