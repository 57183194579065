import {
  CircularProgress,
  FormControl,
  FormLabel,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Popover,
  Select,
  TextField,
} from "@mui/material";
import * as XLSX from "xlsx";
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import { IoMdAddCircleOutline, IoMdDownload } from "react-icons/io";
import { Search } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import RangeCalendar from "../../common/RangeCalendar";
import { useNavigate } from "react-router-dom";
import { IoChevronBack, IoFilter } from "react-icons/io5";
import { getSpkDetail, getSpkList } from "../../../services/SpkApi";
import moment from "moment";
import PrintSpk from "./PrintSpk";

const jobType = [
  { id: 1, name: "New" },
  { id: 2, name: "Repeat" },
  { id: 3, name: "Sample Order" },
  { id: 4, name: "RWC" },
  { id: 5, name: "New + CRB" },
  { id: 6, name: "RWC + CRB" },
  { id: 7, name: "Reprint" },
];

const SpkList = () => {
  const navigate = useNavigate();

  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [selectedDates, setSelectedDates] = useState(["", ""]);
  const [searchStartDate, setSearchStartDate] = useState(null);
  const [searchEndDate, setSearchEndDate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [typeDate, setTypeDate] = useState("");
  const [isDownload, setIsDownload] = useState(false);
  const [selectedSpk, setSelectedSpk] = useState(null);
  const [dataDetailSpk, setDataDetailSpk] = useState(null);
  const [fetchingDetailSpk, setFetchingDetailSpk] = useState(false);

  const formik = useFormik({
    initialValues: {
      offset: 1,
      limit: 10,
      schedule: 0,
      search: "",
      po_start: "",
      po_end: "",
      delivery_start: "",
      delivery_end: "",
      job_type: [],
      category: [],
      dies: [],
    },
  });

  const {
    mutate: mutateSpkList,
    data,
    isPending,
  } = useMutation({
    mutationFn: getSpkList,
  });

  const {
    mutate: mutateExportList,
    data: dataExport,
    isPending: isPendingExport,
  } = useMutation({
    mutationFn: getSpkList,
    onSuccess: (data) => exportXlsx(data),
  });

  const open = Boolean(anchorEl);

  const exportXlsx = (dataExport) => {
    const rows = dataExport.data.map((row, i) => ({
      no: i + 1,
      spk_no: row.spk_no,
      so_no: row.so_no,
      po_number: row.po_number,
      po_date: row.po_date,
      customer_name: row.customer_name,
      sales: row.sales,
      job: row.job_type,
      delivery_date: row.delivery_date,
      mc: row.mc_printing,
      category: row.category === 1 ? "Prime" : "Difficult",
      die: row.die === 1 ? "PB" : "FL",
      created: row.created_by,
    }));

    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "Daftar SPK " + moment().format("DD-MM-YYYY")
    );

    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          "No",
          "No. SPK",
          "No. SO",
          "No. PO",
          "Tanggal PO",
          "Customer",
          "Sales",
          "Job Type",
          "Tanggal Delivery",
          "M/C Printing",
          "Kategori",
          "Dies",
          "Created By",
        ],
      ],
      {
        origin: "A1",
      }
    );

    const max_width = rows.reduce((w, r) => Math.max(w, r.no.length), 10);
    worksheet["!cols"] = [{ wch: max_width }];

    XLSX.writeFile(
      workbook,
      "Daftar SPK " + moment().format("DD_MM_YYYY") + ".xlsx",
      { compression: true }
    );
  };

  useEffect(() => {
    const fetchDataDetailSpk = async () => {
      if (selectedSpk) {
        setFetchingDetailSpk(true);
        try {
          const data = await getSpkDetail(selectedSpk?.id);
          setDataDetailSpk(data);
        } catch (error) {
          console.error("Error fetching spk detail:", error);
        } finally {
          setFetchingDetailSpk(false);
        }
      }
    };

    fetchDataDetailSpk();
  }, [selectedSpk]);

  const handleSelectDate = (e, type) => {
    setSearchStartDate(null);
    setSearchEndDate(null);
    setTypeDate(type);
    setAnchorElCalendar(e.currentTarget);
  };

  const handleExport = () => {
    mutateExportList((({ offset, limit, ...o }) => o)(formik.values));
  };

  useEffect(() => {
    if (!isDownload) setSelectedSpk(null);
  }, [isDownload]);

  useEffect(() => {
    if (typeDate !== "") {
      if (typeDate === "delivery") {
        formik.setFieldValue("delivery_start", selectedDates[0]);
        formik.setFieldValue("delivery_end", selectedDates[1]);
      }
      if (typeDate === "po") {
        formik.setFieldValue("po_start", selectedDates[0]);
        formik.setFieldValue("po_end", selectedDates[1]);
      }
    }
  }, [selectedDates]);

  useEffect(() => {
    mutateSpkList(formik.values);
  }, [formik.values]);

  useEffect(() => {
    if (!anchorElCalendar) setTypeDate("");
  }, [anchorElCalendar]);

  useEffect(() => {
    formik.setFieldValue("offset", 1);
  }, [formik.values.limit, formik.values.search, formik.values.status]);

  const getJobTypeName = (id) => {
    const job = jobType.find((jt) => jt.id === id);
    return job ? job.name : "Unknown";
  };

  return (
    <div>
      <div className="flex mb-4 items-stretch gap-2">
        <FormControl fullWidth>
          <FormLabel>Search</FormLabel>
          <TextField
            fullWidth
            placeholder="Search by No. SPK, No. SO, No. PO, Customer, Sales, M/C Printing"
            value={formik.values.search}
            onChange={(e) => formik.setFieldValue("search", e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <FormLabel>Job Type</FormLabel>
          <Select
            multiple
            value={formik.values.job_type}
            onChange={(e) => formik.setFieldValue("job_type", e.target.value)}
            displayEmpty
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <p className="text-gray-400">Select job type</p>;
              }
              return selected
                .map((value) => {
                  switch (value) {
                    case 1:
                      return "New";
                    case 2:
                      return "Repeat";
                    case 3:
                      return "Sample Order";
                    case 4:
                      return "RWC";
                    case 5:
                      return "New + CRB";
                    case 6:
                      return "RWC + CRB";
                    case 7:
                      return "Reprint";
                    default:
                      return "";
                  }
                })
                .join(", ");
            }}
          >
            <MenuItem disabled value="">
              <p>Select job type</p>
            </MenuItem>
            <MenuItem value={1}>New</MenuItem>
            <MenuItem value={2}>Repeat</MenuItem>
            <MenuItem value={3}>Sample Order</MenuItem>
            <MenuItem value={4}>RWC</MenuItem>
            <MenuItem value={5}>New + CRB</MenuItem>
            <MenuItem value={6}>RWC + CRB</MenuItem>
            <MenuItem value={7}>Reprint</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <FormLabel>Kategori</FormLabel>
          <Select
            multiple
            value={formik.values.category}
            onChange={(e) => formik.setFieldValue("category", e.target.value)}
            displayEmpty
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <p className="text-gray-400">Select category</p>;
              }
              return selected
                .map((value) => {
                  switch (value) {
                    case 1:
                      return "Prime";
                    case 2:
                      return "Difficult";
                    default:
                      return "";
                  }
                })
                .join(", ");
            }}
          >
            <MenuItem disabled value="">
              <p>Select category</p>
            </MenuItem>
            <MenuItem value={1}>Prime</MenuItem>
            <MenuItem value={2}>Difficult</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <FormLabel>Dies</FormLabel>
          <Select
            multiple
            value={formik.values.dies}
            onChange={(e) => formik.setFieldValue("dies", e.target.value)}
            displayEmpty
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <p className="text-gray-400">Select dies</p>;
              }
              return selected
                .map((value) => {
                  switch (value) {
                    case 1:
                      return "PB";
                    case 2:
                      return "FL";
                    default:
                      return "";
                  }
                })
                .join(", ");
            }}
          >
            <MenuItem disabled value="">
              <p>Select dies</p>
            </MenuItem>
            <MenuItem value={1}>PB</MenuItem>
            <MenuItem value={2}>FL</MenuItem>
          </Select>
        </FormControl>
        <div
          className="p-2 w-48 border-2 rounded-md flex items-center justify-center cursor-pointer hover:bg-slate-100"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <IoFilter />
        </div>
        <button
          onClick={() => navigate("/spk/create-spk")}
          className="w-1/4 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
        >
          {/* <p>Add Sales Order</p> */}
          <IoMdAddCircleOutline className="text-[18px]" />
        </button>
      </div>

      <div className="w-full mb-4">
        <button
          className="rounded-md p-2 px-8 border-2 float-right mb-4 cursor-pointer hover:bg-slate-100 disabled:bg-gray-300 disabled:text-white disabled:cursor-default"
          disabled={isPendingExport}
          onClick={handleExport}
        >
          {isPendingExport ? <CircularProgress size={20} /> : "Export SPK"}
        </button>
      </div>

      <>
        {" "}
        <div className="rounded-md shadow-md">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <th className="p-3 text-left">No</th>
                <th className="p-3 text-left">No. SPK</th>
                <th className="p-3 text-left">No. SO</th>
                <th className="p-3 text-left">No. PO</th>
                <th className="p-3 text-left">Tanggal PO</th>
                <th className="p-3 text-left">Customer</th>
                <th className="p-3 text-left">Sales</th>
                <th className="p-3 text-left">Job Type</th>
                <th className="p-3 text-left">Tanggal Delivery</th>
                <th className="p-3 text-left">M/C Printing</th>
                <th className="p-3 text-left">Kategori</th>
                <th className="p-3 text-left">Dies</th>
                <th className="p-3 text-left">Created By</th>
                <th className="p-3 text-left">Action</th>
              </thead>
              <tbody>
                {data && data.data && data.data.length > 0 && !isPending ? (
                  data.data.map((item, i) => {
                    return (
                      <tr className="border-t border-l border-2" key={i}>
                        <td className="p-3">
                          {(formik.values.offset - 1) * formik.values.limit +
                            (i + 1)}
                        </td>
                        <td
                          className="p-3 text-blue-500 cursor-pointer hover:text-blue-700"
                          onClick={() => navigate(`/spk/detail/${item.id}`)}
                        >
                          {item.spk_no}
                        </td>
                        <td className="p-3">{item.so_no}</td>
                        <td className="p-3">{item.po_number}</td>
                        <td className="p-3">{item.po_date}</td>
                        <td className="p-3">{item.customer_name}</td>
                        <td className="p-3">{item.sales}</td>
                        <td className="p-3">{getJobTypeName(item.job_type)}</td>
                        <td className="p-3">{item.delivery_date}</td>
                        <td className="p-3">{item.mc_printing}</td>
                        <td className="p-3">
                          {item.category === 1 ? "Prime" : "Difficult"}
                        </td>
                        <td className="p-3">{item.die === 1 ? "PB" : "FL"}</td>
                        <td className="p-3">{item.created_by}</td>
                        <td className="p-3 text-center">
                          <div className="flex gap-2 items-center justify-center">
                            <IoMdDownload
                              onClick={() => {
                                setIsDownload(true);
                                setSelectedSpk(item);
                              }}
                              className="text-blue-500 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : !isPending ? (
                  <tr className="border-t border-l border-2">
                    <td colSpan={13} className="py-3 text-center">
                      Data SPK tidak ditemukan
                    </td>
                  </tr>
                ) : (
                  <tr className="border-t border-l border-2">
                    <td colSpan={13} className="py-3 text-center">
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {data && data.count > 0 ? (
            <div className="w-full flex justify-between pb-8 px-8">
              <button
                onClick={() => {
                  if (formik.values.offset > 1)
                    formik.setFieldValue("offset", formik.values.offset - 1);
                }}
                disabled={formik.values.offset === 1}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowLeftLong /> Previous
              </button>
              <Pagination
                hideNextButton
                hidePrevButton
                shape="rounded"
                page={formik.values.offset}
                count={Math.ceil(data.count / formik.values.limit)}
                onChange={(e, page) => formik.setFieldValue("offset", page)}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
              <button
                onClick={() => {
                  if (
                    formik.values.offset <
                    Math.ceil(data.count / formik.values.limit)
                  )
                    formik.setFieldValue("offset", formik.values.offset + 1);
                }}
                disabled={
                  formik.values.offset ===
                  Math.ceil(data.count / formik.values.limit)
                }
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowRightLong /> Next
              </button>
            </div>
          ) : null}
        </div>
        {data && data.count > 0 ? (
          <div className="mt-4 text-xs flex gap-3 items-center">
            <p>Show Table</p>
            <p
              onClick={() => formik.setFieldValue("limit", 10)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                formik.values.limit === 10
                  ? "border rounded-md bg-slate-100"
                  : ""
              }`}
            >
              10
            </p>
            <p
              onClick={() => formik.setFieldValue("limit", 50)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                formik.values.limit === 50
                  ? "border rounded-md bg-slate-100"
                  : ""
              }`}
            >
              50
            </p>
            <p
              onClick={() => formik.setFieldValue("limit", 100)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                formik.values.limit === 100
                  ? "border rounded-md bg-slate-100"
                  : ""
              }`}
            >
              100
            </p>
          </div>
        ) : null}
      </>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="">
          <div
            className={`p-3 border-b-2 hover:bg-slate-100 cursor-pointer flex justify-between items-center ${
              formik.values.delivery_start !== "" &&
              formik.values.delivery_end !== ""
                ? "bg-slate-100"
                : ""
            }`}
            onClick={(e) => handleSelectDate(e, "delivery")}
          >
            <IoChevronBack />
            Tanggal Delivery
          </div>
          <div
            className={`p-3 border-b-2 hover:bg-slate-100 cursor-pointer flex justify-between items-center ${
              formik.values.po_start !== "" && formik.values.po_end !== ""
                ? "bg-slate-100"
                : ""
            }`}
            onClick={(e) => handleSelectDate(e, "po")}
          >
            <IoChevronBack />
            Tanggal PO
          </div>
        </div>
      </Popover>

      <RangeCalendar
        anchorElCalendar={anchorElCalendar}
        setAnchorElCalendar={setAnchorElCalendar}
        setSelectedDates={setSelectedDates}
        searchStartDate={searchStartDate}
        searchEndDate={searchEndDate}
        setSearchStartDate={setSearchStartDate}
        setSearchEndDate={setSearchEndDate}
        toSide={true}
      />

      <PrintSpk
        isDownload={isDownload}
        setIsDownload={setIsDownload}
        data={dataDetailSpk}
      />
    </div>
  );
};

export default SpkList;
