import axios from "axios";
import Cookies from "js-cookie";
import { GeneratePublicToken } from "../utils/GeneratePublicToken";

const token = () => Cookies.get("accessToken");

export const getEquipmentList = async ({
  search,
  start,
  end,
  limit,
  offset,
}) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/equipment-list?search=${search}&start=${start}&end=${end}&limit=${limit}&offset=${offset}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const deleteEquipment = async (id) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/equipment-delete?id=${id}`,
      method: "DELETE",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const setNewEquipment = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/equipment-create`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getEquipmentDetail = async (id) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/equipment-detail?id=${id}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};