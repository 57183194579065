import { useEffect } from "react";
import JsBarcode from "jsbarcode";
import jsPDF from "jspdf";

const PrintInternalCode = ({ data, isDownload, setIsDownload }) => {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, data.internal_code);
  const barcode = canvas.toDataURL("image/png");

  useEffect(() => {
    if (isDownload && data) {
      const doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [40, 100],
      });

      doc.setFontSize(12);

      doc.text(`${data.label_name}`, 50, 8, "center");
      doc.addImage(barcode, "png", 25, 10, 50, 18);

      doc.setFontSize(8);
      doc.text(`${data.internal_code}`, 50, 33, "center");
      doc.text(`${data.customer}`, 50, 37, "center");

      doc.save(data.internal_code + ".pdf");

      setIsDownload(false);
    }
  }, [isDownload]);
  return <></>;
};

export default PrintInternalCode;
