import axios from "axios";
import Cookies from "js-cookie";

const token = () => Cookies.get("accessToken");

export const getScheduleList = async ({
  offset,
  limit,
  search,
  due_start,
  due_end,
  spk_date_start,
  spk_date_end,
  type,
  machineName
}) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/list-schedule?type=${type}&search=${search}&offset=${offset}&limit=${limit}&due_start=${due_start}&due_end=${due_end}&spk_date_start=${spk_date_start}&spk_date_end=${spk_date_end}&machine_name=${machineName}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getScheduleExport = async ({
  search,
  due_start,
  due_end,
  spk_date_start,
  spk_date_end,
  type,
}) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/list-schedule?type=${type}&search=${search}&due_start=${due_start}&due_end=${due_end}&spk_date_start=${spk_date_start}&spk_date_end=${spk_date_end}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const setNewJob = async (body) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/take-job`,
      method: "POST",
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getDetailSchedule = async (id) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/detail-schedule?id=${id}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getDetailScheduleBySpk = async (spkId, internalCode) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/detail-schedule?spk_id=${spkId}&internal_code=${internalCode}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getMpsListSchedule = async () => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/list-mps`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};
