import {
  FormGroup,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { MdCalendarMonth } from "react-icons/md";
import SingleCalendar from "../../common/SingleCalendar";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { getMpsDetail, setMps } from "../../../services/mpsApi";

const validationSchema = yup.object({
  id: yup.number(),
  mps_name: yup.string().required(),
  status: yup.string().required(),
  size_along: yup.string().required(),
  size_across: yup.string().required(),
  eye_along: yup.string().required(),
  eye_across: yup.string().required(),
  gap_along: yup.string().required(),
  gap_across: yup.string().required(),
  die_cut_shape: yup.string().required(),
  specification: yup.number().min(1),
  mps_date: yup.string().required(),
});

const EditMpsFlexiblePlatbet = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [date, setDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const { data: dataDetail } = useQuery({
    queryKey: ["detail-mps", id],
    queryFn: () => getMpsDetail(id),
    enabled: id !== undefined,
  });

  const formik = useFormik({
    initialValues: {
      id: Number(id),
      mps_name: dataDetail && dataDetail.mps_name ? dataDetail.mps_name : "",
      status: dataDetail && dataDetail.status ? dataDetail.status : 0,
      size_along:
        dataDetail && dataDetail.size_along ? dataDetail.size_along : "",
      size_across:
        dataDetail && dataDetail.size_across ? dataDetail.size_across : "",
      eye_along: dataDetail && dataDetail.eye_along ? dataDetail.eye_along : "",
      eye_across:
        dataDetail && dataDetail.eye_across ? dataDetail.eye_across : "",
      gap_along: dataDetail && dataDetail.gap_along ? dataDetail.gap_along : "",
      gap_across:
        dataDetail && dataDetail.gap_across ? dataDetail.gap_across : "",
      die_cut_shape:
        dataDetail && dataDetail.die_cut_shape ? dataDetail.die_cut_shape : "",
      specification:
        dataDetail && dataDetail.specification ? dataDetail.specification : "",
      mps_date: dataDetail && dataDetail.mps_date ? dataDetail.mps_date : "",
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      mutateNewMps(values);
    },
  });

  const { mutate: mutateNewMps, isPending } = useMutation({
    mutationFn: setMps,
    onSuccess: () => navigate(`/mps-flexible`),
    onError: (err) => setErrorMessage(err.message.id),
  });

  useEffect(() => {
    formik.setFieldValue(
      "mps_date",
      date ? format(new Date(date), "yyyy-MM-dd") : ""
    );
  }, [date]);

  useEffect(() => {
    if (dataDetail) setDate(dataDetail.mps_date);
  }, [dataDetail]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>MPS No</FormLabel>
          <TextField
            fullWidth
            disabled
            value={dataDetail ? dataDetail.mps_no : ""}
          />
        </FormGroup>
        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Nama MPS</FormLabel>
          <TextField
            fullWidth
            value={formik.values.mps_name}
            onChange={(e) => {
              if (e.target.value.length <= 200)
                formik.setFieldValue("mps_name", e.target.value);
            }}
          />
        </FormGroup>
        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Status</FormLabel>
          <Select
            value={formik.values.status}
            onChange={(e) => formik.setFieldValue("status", e.target.value)}
          >
            <MenuItem value={0} disabled>
              Pilih Status
            </MenuItem>
            <MenuItem value={1}>Terpakai</MenuItem>
            <MenuItem value={2}>Tidak terpakai</MenuItem>
            <MenuItem value={3}>Belum terpakai</MenuItem>
            <MenuItem value={4}>Pernah terpakai</MenuItem>
            <MenuItem value={5}>Tumpul</MenuItem>
          </Select>
        </FormGroup>
        <div className="mb-4 flex gap-4">
          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>Size Along</FormLabel>
            <TextField
              fullWidth
              value={formik.values.size_along}
              onChange={(e) => {
                formik.setFieldValue("size_along", e.target.value);
              }}
            />
          </FormGroup>
          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>Size Across</FormLabel>
            <TextField
              fullWidth
              value={formik.values.size_across}
              onChange={(e) => {
                formik.setFieldValue("size_across", e.target.value);
              }}
            />
          </FormGroup>
        </div>
        <div className="mb-4 flex gap-4">
          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>Total Mata Along</FormLabel>
            <TextField
              fullWidth
              value={formik.values.eye_along}
              onChange={(e) => {
                formik.setFieldValue("eye_along", e.target.value);
              }}
            />
          </FormGroup>
          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>Total Mata Across</FormLabel>
            <TextField
              fullWidth
              value={formik.values.eye_across}
              onChange={(e) => {
                formik.setFieldValue("eye_across", e.target.value);
              }}
            />
          </FormGroup>
        </div>
        <div className="mb-4 flex gap-4">
          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>Gap Along</FormLabel>
            <TextField
              fullWidth
              value={formik.values.gap_along}
              onChange={(e) => {
                formik.setFieldValue("gap_along", e.target.value);
              }}
            />
          </FormGroup>
          <FormGroup sx={{ width: "100%" }}>
            <FormLabel>Gap Across</FormLabel>
            <TextField
              fullWidth
              value={formik.values.gap_across}
              onChange={(e) => {
                formik.setFieldValue("gap_across", e.target.value);
              }}
            />
          </FormGroup>
        </div>

        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Bentuk Die Cut</FormLabel>
          <TextField
            fullWidth
            value={formik.values.die_cut_shape}
            onChange={(e) => {
              formik.setFieldValue("die_cut_shape", e.target.value);
            }}
          />
        </FormGroup>

        <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
          <FormLabel>Spesifikasi</FormLabel>
          <Select
            value={formik.values.specification}
            onChange={(e) =>
              formik.setFieldValue("specification", e.target.value)
            }
          >
            <MenuItem value={0} disabled>
              Pilih Spesifikasi
            </MenuItem>
            <MenuItem value={1}>Ada</MenuItem>
            <MenuItem value={2}>Tidak Ada</MenuItem>
          </Select>
        </FormGroup>

        <p>Tanggal</p>
        <div className="mb-8 border-2 rounded-md w-full flex items-center hover:bg-slate-100">
          <div
            className="flex justify-between items-center h-full w-full cursor-pointer p-3.5"
            onClick={(e) => setAnchorElCalendar(e.currentTarget)}
          >
            {date === null ? (
              <p>----/--/--</p>
            ) : (
              <p>{format(new Date(date), "yyyy-MM-dd")}</p>
            )}
            <MdCalendarMonth className="w-6 h-6" />
          </div>
        </div>

        <div className="w-full">
          <p className="text-red-500 mb-4 text-right m-4">{errorMessage}</p>
          <div className="flex justify-end w-1/3 gap-2 float-right">
            <button
              type="button"
              className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
              onClick={() => navigate("/mps")}
            >
              Cancel
            </button>
            <button
              className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
              disabled={!formik.isValid | isPending}
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </form>
      <SingleCalendar
        anchorElCalendarStart={anchorElCalendar}
        setAnchorElCalendarStart={setAnchorElCalendar}
        startDate={date}
        setStartDate={setDate}
      />
    </>
  );
};

export default EditMpsFlexiblePlatbet;
