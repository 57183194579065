import { Collapse } from "@mui/material";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import SpkCardInfo from "./SpkCardInfo";
import SpkCardMaterial from "./SpkCardMaterial";
import SpkCardEstimateSystem from "./SpkCardEstimateSystem";
import SpkCardEstimateHr from "./SpkCardEstimateHr";
import SpkCardLabelInfo from "./SpkCardLabelInfo";
import SpkCardQC from "./SpkCardQC";
import SpkCardDetailAdvanceDetail from "./SpkCardDetailAdvancedDetail";
import SpkCardInkCoverage from "./SpkCardInkCoverage";

const SpkDetailCard = ({
  formik,
  expandArr,
  setExpandArr,
  data,
  i,
  isEdit,
}) => {
  const handleChangeExpand = (index, type, toggle) => {
    var temp = [...expandArr];
    temp[index][type] = toggle;
    setExpandArr(temp);
  };

  return (
    <>
      {data && expandArr[i] ? (
        <div className="border-2 border-gray-300 rounded-md p-3.5">
          <div className="w-full justify-between items-center flex p-2 mb-4">
            <p className="font-bold">{data.internal_code}</p>
            {expandArr[i].project ? (
              <FaChevronUp
                className="cursor-pointer hover:text-gray-300"
                onClick={() => handleChangeExpand(i, "project", false)}
              />
            ) : (
              <FaChevronDown
                className="cursor-pointer hover:text-gray-300"
                onClick={() => handleChangeExpand(i, "project", true)}
              />
            )}
          </div>
          <Collapse in={expandArr[i].project}>
            <div className="border-2 border-gray-300 rounded-md p-3.5 mb-4">
              <SpkCardInfo
                i={i}
                isDetail={true}
                proj={data}
                isEdit={isEdit}
                formik={formik}
              />
            </div>

            <div className="border-2 border-gray-300 rounded-md p-3.5 mb-4">
              <div className="w-full justify-between flex p-2">
                <p className="font-bold">Advanced Details</p>
                {expandArr[i].detail ? (
                  <FaChevronUp
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "detail", false)}
                  />
                ) : (
                  <FaChevronDown
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "detail", true)}
                  />
                )}
              </div>
              <Collapse in={expandArr[i].detail}>
                <SpkCardDetailAdvanceDetail curProj={data} />
              </Collapse>
            </div>

            <div className="border-2 border-gray-300 rounded-md p-3.5 mb-4">
              <div className="w-full justify-between flex p-2">
                <p className="font-bold">Material Details</p>
                {expandArr[i].material ? (
                  <FaChevronUp
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "material", false)}
                  />
                ) : (
                  <FaChevronDown
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "material", true)}
                  />
                )}
              </div>
              <Collapse in={expandArr[i].material}>
                <SpkCardMaterial proj={data} />
              </Collapse>
            </div>

            <div className="border-2 border-gray-300 rounded-md p-3.5 mb-4">
              <div className="w-full justify-between flex p-2">
                <p className="font-bold">Material</p>
                {expandArr[i].estimate_system ? (
                  <FaChevronUp
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() =>
                      handleChangeExpand(i, "estimate_system", false)
                    }
                  />
                ) : (
                  <FaChevronDown
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() =>
                      handleChangeExpand(i, "estimate_system", true)
                    }
                  />
                )}
              </div>
              <Collapse in={expandArr[i].estimate_system}>
                <SpkCardEstimateSystem curProject={data} isDetail={true} />
              </Collapse>
            </div>

            <div className="border-2 border-gray-300 rounded-md p-3.5 mb-4">
              <div className="w-full justify-between flex p-2">
                <p className="font-bold">Time</p>
                {expandArr[i].estimate_hr ? (
                  <FaChevronUp
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "estimate_hr", false)}
                  />
                ) : (
                  <FaChevronDown
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "estimate_hr", true)}
                  />
                )}
              </div>
              <Collapse in={expandArr[i].estimate_hr}>
                <SpkCardEstimateHr curProj={data} isDetail={true} />
              </Collapse>
            </div>

            <div className="border-2 border-gray-300 rounded-md p-3.5 mb-4">
              <div className="w-full justify-between flex p-2">
                <p className="font-bold">Label Information</p>
                {expandArr[i].label ? (
                  <FaChevronUp
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "label", false)}
                  />
                ) : (
                  <FaChevronDown
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "label", true)}
                  />
                )}
              </div>
              <Collapse in={expandArr[i].label}>
                <SpkCardLabelInfo curProj={data} isDetail={true} />
              </Collapse>
            </div>

            <div className="border-2 border-gray-300 rounded-md p-3.5 mb-4">
              <div className="w-full justify-between flex p-2">
                <p className="font-bold">Quality Control</p>
                {expandArr[i].qc ? (
                  <FaChevronUp
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "qc", false)}
                  />
                ) : (
                  <FaChevronDown
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "qc", true)}
                  />
                )}
              </div>
              <Collapse in={expandArr[i].qc}>
                <SpkCardQC curProj={data} isDetail={true} />
              </Collapse>
            </div>

            <div className="border-2 border-gray-300 rounded-md p-3.5 mb-4">
              <div className="w-full justify-between flex p-2">
                <p className="font-bold">Ink Coverage</p>
                {expandArr[i].ink_coverage ? (
                  <FaChevronUp
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "ink_coverage", false)}
                  />
                ) : (
                  <FaChevronDown
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "ink_coverage", true)}
                  />
                )}
              </div>
              <Collapse in={expandArr[i]?.ink_coverage}>
                <SpkCardInkCoverage curProject={data} isDetail={true} />
              </Collapse>
            </div>
          </Collapse>
        </div>
      ) : (
        <p className="text-gray-400 text-center">Belum ada project</p>
      )}
    </>
  );
};

export default SpkDetailCard;
