import { useEffect, useState } from "react";
import ModalInput from "../../common/ModalInput";
import {
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { MdCalendarMonth } from "react-icons/md";
import SingleCalendar from "../../common/SingleCalendar";
import { format } from "date-fns";
import { useFormik } from "formik";
import RangeCalendar from "../../common/RangeCalendar";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { setNewEquipment } from "../../../services/equipmentApi";

const validationSchema = yup.object({
  code: yup.string().required(),
  name: yup.string().required(),
  scheduled_start_date: yup.string().required(),
  scheduled_end_date: yup.string().required(),
  planned_start_date: yup.string().required(),
  planned_end_date: yup.string().required(),
  status: yup.string(),
});

const AddEquipmentModal = ({ openAdd, setOpenAdd, setRefetchList }) => {
  const date = new Date();
  const [isAdd, setIsAdd] = useState(false);
  const [maintenanceDate, setMaintenanceDate] = useState(["", ""]);
  const [maintenanceStart, setMaintenanceStart] = useState(date);
  const [maintenanceEnd, setMaintenanceEnd] = useState(date);
  const [nextMaintenanceDate, setNextMaintenanceDate] = useState(["", ""]);
  const [maintenanceStartNext, setMaintenanceStartNext] = useState(date);
  const [maintenanceEndNext, setMaintenanceEndNext] = useState(date);
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [anchorElCalendarNext, setAnchorElCalendarNext] = useState(null);

  const { mutate: mutateNewEquipment, isPending: isPendingAdd } = useMutation({
    mutationFn: setNewEquipment,
    onSuccess: () => {
      setRefetchList(true);
      setOpenAdd(false);
    },
  });

  const formik = useFormik({
    initialValues: {
      code: "",
      name: "",
      scheduled_start_date: "",
      scheduled_end_date: "",
      planned_start_date: "",
      planned_end_date: "",
      status: "",
    },
    validationSchema,
    validateOnMount: true,
  });

  useEffect(() => {
    if (isAdd) {
      mutateNewEquipment(formik.values);
      setIsAdd(false);
    }
  }, [isAdd]);

  useEffect(() => {
    if (openAdd) {
      formik.resetForm();
      setMaintenanceDate(["", ""]);
      setNextMaintenanceDate(["", ""]);
      setMaintenanceStart(null);
      setMaintenanceStartNext(null);
      setMaintenanceEnd(null);
      setMaintenanceEndNext(null);
    }
  }, [openAdd]);

  useEffect(() => {
    if (maintenanceDate[0] !== "" && maintenanceDate[1] !== "") {
      formik.setFieldValue("scheduled_start_date", maintenanceDate[0]);
      formik.setFieldValue("scheduled_end_date", maintenanceDate[1]);
    }
  }, [maintenanceDate]);

  useEffect(() => {
    if (nextMaintenanceDate[0] !== "" && nextMaintenanceDate[1] !== "") {
      formik.setFieldValue("planned_start_date", nextMaintenanceDate[0]);
      formik.setFieldValue("planned_end_date", nextMaintenanceDate[1]);
    }
  }, [nextMaintenanceDate]);

  return (
    <div>
      <ModalInput
        title="Tambah Equipment & Jadwal"
        subtitle=""
        content={
          <>
            <TextField
              label="Kode Equipment"
              fullWidth
              sx={{ marginBottom: 2 }}
              value={formik.values.code}
              onChange={(e) => {
                if (e.target.value.length <= 100)
                  formik.setFieldValue("code", e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {formik.values.code.length}/100
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Nama Equipment"
              fullWidth
              sx={{ marginBottom: 2 }}
              value={formik.values.name}
              onChange={(e) => {
                if (e.target.value.length <= 100)
                  formik.setFieldValue("name", e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {formik.values.name.length}/100
                  </InputAdornment>
                ),
              }}
            />
            <p>Jadwal Maintenance</p>
            <div className="mb-4 w-full border-2 rounded-md w-full flex items-center hover:bg-slate-100">
              <div
                className="flex justify-between items-center h-full cursor-pointer w-full cursor-pointer p-4"
                onClick={(e) => setAnchorElCalendar(e.currentTarget)}
              >
                {maintenanceDate[0] === "" ? (
                  <p className="text-gray-300">----/--/--</p>
                ) : (
                  <p className="text-gray-300">{maintenanceDate[0]}</p>
                )}
                <p className="text-gray-300"> s/d </p>
                {maintenanceDate[1] === "" ? (
                  <p className="text-gray-300">----/--/--</p>
                ) : (
                  <p className="text-gray-300">{maintenanceDate[1]}</p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
            <p>Maintenance Selanjutnya</p>
            <div className="mb-4 w-full border-2 rounded-md w-full flex items-center hover:bg-slate-100">
              <div
                className="flex justify-between items-center h-full cursor-pointer w-full cursor-pointer p-4"
                onClick={(e) => setAnchorElCalendarNext(e.currentTarget)}
              >
                {nextMaintenanceDate[0] === "" ? (
                  <p className="text-gray-300">----/--/--</p>
                ) : (
                  <p className="text-gray-300">{nextMaintenanceDate[0]}</p>
                )}
                <p className="text-gray-300"> s/d </p>
                {nextMaintenanceDate[1] === "" ? (
                  <p className="text-gray-300">----/--/--</p>
                ) : (
                  <p className="text-gray-300">{nextMaintenanceDate[1]}</p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>

            <FormControl fullWidth>
              <Select
                label="Status"
                value={formik.values.status}
                onChange={(e) => formik.setFieldValue("status", e.target.value)}
              >
                <MenuItem value={"Normal"}>Normal</MenuItem>
                <MenuItem value={"Broken"}>Broken</MenuItem>
              </Select>
            </FormControl>
          </>
        }
        open={openAdd}
        setOpen={setOpenAdd}
        hasButton={true}
        buttonText="Save"
        setTriggerFunc={setIsAdd}
        isDisable={
          !formik.isValid
          //   || isPendingAdd
        }
      />

      <RangeCalendar
        anchorElCalendar={anchorElCalendar}
        setAnchorElCalendar={setAnchorElCalendar}
        setSelectedDates={setMaintenanceDate}
        searchStartDate={maintenanceStart}
        searchEndDate={maintenanceEnd}
        setSearchStartDate={setMaintenanceStart}
        setSearchEndDate={setMaintenanceEnd}
      />

      <RangeCalendar
        anchorElCalendar={anchorElCalendarNext}
        setAnchorElCalendar={setAnchorElCalendarNext}
        setSelectedDates={setNextMaintenanceDate}
        searchStartDate={maintenanceStartNext}
        searchEndDate={maintenanceEndNext}
        setSearchStartDate={setMaintenanceStartNext}
        setSearchEndDate={setMaintenanceEndNext}
      />
    </div>
  );
};

export default AddEquipmentModal;
