import axios from "axios";
import Cookies from "js-cookie";

const token = () => Cookies.get("accessToken");

export const getSupplierInvoiceList = async ({
  offset,
  limit,
  search,
  status,
}) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/list-invoice?offset=${offset}&limit=${limit}&search=${search}&status=${status}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getSupplierInvoiceDetail = async (id) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/detail-invoice?id=${id}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const updateSupplierInvoice = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
        "Content-Type": "multipart/form-data",
      },
      url: `${process.env.REACT_APP_API_ERP}erp/update-invoice`,
      method: "PUT",
      data: {
        file: body.file,
        due_date: body.due_date,
        id: body.id,
        dpp: body.dpp,
        faktur: body.faktur,
        invoice_date: body.invoice_date,
        invoice_receive_date: body.invoice_receive_date,
      },
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const setNewSupplierInvoice = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
        "Content-Type": "multipart/form-data",
      },
      url: `${process.env.REACT_APP_API_ERP}erp/invoice`,
      method: "POST",
      data: {
        file: body.file,
        json: JSON.stringify(body.json),
      },
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const deleteImageSupplier = async (file, id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_ERP}erp/remove-image?filename=${file}&id=${id}`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const updateInvoicePaid = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_ERP}erp/pay-invoices`,
      method: "PUT",
      data: {
        id: id,
      },
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const getInvoiceCategoryList = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      url: `${process.env.REACT_APP_API_ERP}erp/list-invoice-category`,
      method: "GET",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const setInvoiceCategoryList = async (name) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}erp/invoice-category`,
      method: "POST",
      data: {
        category_name: name,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const deleteInvoiceCategory = async (id) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token()}` },
      url: `${process.env.REACT_APP_API_ERP}/erp/invoice-category-delete?id=${id}`,
      method: "DELETE",
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};
