import React, { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { TextField } from "@mui/material";
import Logo from "../../../assets/logo.png";
import { Link } from "react-router-dom";
import HOC from "../../HOC/index";
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { sendForgetPasswordLink } from "../../../services/authApi";

const SEND_VERIFICATION_RETRY_DELAY_SECONDS = 30;

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [linkRequested, setLinkRequested] = useState(false);
  const [isResetTimer, setIsResetTimer] = useState(false);
  const [isStart, setIsStart] = useState(false);
  const [countdownSeconds, setCountdownSeconds] = useState(
    SEND_VERIFICATION_RETRY_DELAY_SECONDS
  );

  const { mutate: mutateLink, isPending } = useMutation({
    mutationFn: sendForgetPasswordLink,
    onSuccess: () => {
      setCountdownSeconds(SEND_VERIFICATION_RETRY_DELAY_SECONDS);
      setLinkRequested(true);
      setIsResetTimer(true);
      setIsStart(true);
      setErrorMessage("");
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  const linkTimer = () => {
    const interval = setInterval(() => {
      setCountdownSeconds((prevCount) => {
        if (prevCount === 0) {
          clearInterval(interval);
          setIsStart(false);
          setIsResetTimer(false);
          setCountdownSeconds(SEND_VERIFICATION_RETRY_DELAY_SECONDS);
          return prevCount;
        }
        return prevCount - 1;
      });

      return () => clearInterval(interval);
    }, 1000);
  };

  useEffect(() => {
    if (!linkRequested) return;
    if (isResetTimer) linkTimer();
  }, [isResetTimer]);

  return (
    <div className="bg-white ">
      <div className="flex flex-row justify-center h-screen">
        <div className="hidden md:order-first bg-cover lg:block lg:w-1/2">
          <div className="flex items-center h-full px-20 bg-[#17469E]">
            <div className="w-full my-10 flex justify-center items-center bg-white bg-opacity-40 rounded-md p-4">
              <img src={Logo} alt="" className="mt-16 lg:my-0" />
            </div>
          </div>
        </div>

        <div className="w-full lg:w-1/2 flex justify-center items-center px-12">
          <div className="border-2 border-gray-300 rounded-md p-8 w-fit h-fit">
            <img
              src={Logo}
              alt=""
              className="mt-16 lg:my-0 w-[100px] lg:w-[200px]"
            />
            <h2 className="text-3xl font-bold text-black my-10 lg:my-4">
              Forgot Password
            </h2>
            <p className="mb-8">Ubah password anda dengan yang baru</p>
            <div>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="standard"
                label="Email"
                className="w-full"
                fullWidth
              />
            </div>
            <p className="text-red-500 text-sm">{errorMessage}</p>
            <div className="mt-8 flex items-center">
              <button
                className="bg-[#17469E] text-white w-full tracking-wide font-semibold p-4 rounded-lg 
                    focus:outline-none focus:shadow-outline hover:bg-indigo-600 shadow-lg transform duration-200 hover:scale-100
                    disabled:bg-slate-400"
                disabled={isPending || isStart}
                onClick={() => mutateLink(email)}
              >
                <div className="absolute right-0 inset-y-0 mr-4">
                  <BsFillArrowRightSquareFill className="h-full w-6" />
                </div>
                <div className="inline-block">
                  {isStart ? (
                    <p>
                      Resend Link{" "}
                      <span className="text-red-500">
                        {"("}
                        {new Date(countdownSeconds * 1000).getSeconds()}
                        {")"}
                      </span>
                    </p>
                  ) : (
                    <p>Send Verification Link</p>
                  )}
                </div>
              </button>
            </div>
            {isStart ? (
              <div className="flex my-4 bg-blue-100 items-center space-between p-4 rounded-md text-xs md:text-sm gap-2 text-slate-500">
                <p>
                  Link berhasil dikirim ke email, periksa email anda untuk
                  mengatur ulang password
                </p>
                <IoIosCheckmarkCircle className="text-green-500 lg:w-[8%] md:w-[15%] w-[48px] h-full" />
              </div>
            ) : null}
            <div className="text-right font-display text-[#17469E] hover:text-indigo-800 cursor-pointer mt-4 text-xs">
              <Link to="/login">
                <p className="underline decoration-solid">Back to Login</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(ForgetPassword, "Login-required");
