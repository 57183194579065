import {
  Autocomplete,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import SingleCalendar from "../../common/SingleCalendar";
import { MdCalendarMonth, MdCancel } from "react-icons/md";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getUserListActive } from "../../../services/userManagementApi";
import { getShipmentMethod } from "../../../services/deliveryOrder";
import { AiOutlineCloudUpload } from "react-icons/ai";
import * as yup from "yup";
import { setNewSo } from "../../../services/salesOrderApi";
import ModalInput from "../../common/ModalInput";
import { FaTrash } from "react-icons/fa6";
import {
  getAllQuotation,
  getDetailQuotation,
} from "../../../services/quotationApi";
import { getCustomerList } from "../../../services/customerManagementApi";

const validationSchema = yup.object({
  file: yup.mixed().nullable(),
  json: yup.object({
    status_order: yup.number().min(1).required(),
    user_id: yup.number().min(1).required(),
    quotation_id: yup.number().min(1).required(),
    so_number: yup.string().required(),
    shipping: yup.number().min(1).required(),
    po_number: yup.string().required(),
    po_date: yup.string().required(),
    due_date: yup.string().required(),
    delivery_date: yup.string().required(),
    bill_to: yup.string().required(),
    shipping_address: yup.array(),
    customer_id: yup.number().min(1),
    costing: yup.array().of(
      yup.object().shape({
        costing_id: yup.number(),
        internal_code: yup.string(),
      })
    ),
  }),
});

const AddSalesOrder = () => {
  const navigate = useNavigate();
  const curDate = Date.now();

  const [errorMessage, setErrorMessage] = useState("");
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [typeDate, setTypeDate] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);
  const [salesName, setSalesName] = useState("");

  const handleOpenCalendar = (e, type) => {
    setAnchorElCalendar(e.currentTarget);
    setTypeDate(type);
  };

  const uploadMedia = (e) => {
    const files = e.target.files[0];

    if (!files) return;

    formik.setFieldValue("file", files);
  };

  const changeShippingAddress = (val, i) => {
    formik.setFieldValue("json.shipping_address[" + i + "]", val);
  };

  const changeCode = (index, value) => {
    const costing = [...formik.values.json.costing];
    costing[index].internal_code = value;
    formik.setFieldValue("json.costing", costing);
  };

  const formik = useFormik({
    initialValues: {
      file: null,
      json: {
        status_order: 0,
        user_id: 0,
        quotation_id: 0,
        customer_id: 0,
        so_number: "",
        shipping: 0,
        po_number: "",
        po_date: "",
        due_date: "",
        delivery_date: "",
        shipping_address: [""],
        bill_to: "",
        costing: [
          {
            costing_id: 0,
            internal_code: "",
          },
        ],
      },
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: () => {
      setOpenAdd(true);
    },
  });

  const { data: dataQuotation } = useQuery({
    queryKey: ["quotation-list", salesName],
    queryFn: () => getAllQuotation(salesName),
  });

  const { mutate: mutateNewSo } = useMutation({
    mutationFn: setNewSo,
    onSuccess: () => navigate("/sales-order"),
    onError: (err) => setErrorMessage(err.message.id),
  });

  const { mutate: mutateCustomerList, data: dataCust } = useMutation({
    mutationFn: getCustomerList,
  });

  const { data: dataUser } = useQuery({
    queryKey: ["user-list"],
    queryFn: () =>
      getUserListActive({
        name: "",
        role: 6,
        offset: 1,
        limit: 10000,
        status: true,
      }),
  });

  const { data: dataShipmentMethod } = useQuery({
    queryKey: ["shipment-method-list"],
    queryFn: () => getShipmentMethod(),
  });

  const { data: dataDetailQuotation } = useQuery({
    queryKey: ["detail-quotation", selectedQuotation],
    queryFn: () => getDetailQuotation(selectedQuotation),
    enabled: selectedQuotation !== null,
  });

  useEffect(() => {
    if (typeDate === "po")
      formik.setFieldValue(
        "json.po_date",
        startDate ? format(startDate, "yyyy-MM-dd") : ""
      );
    if (typeDate === "due")
      formik.setFieldValue(
        "json.due_date",
        startDate ? format(startDate, "yyyy-MM-dd") : ""
      );
    if (typeDate === "delivery")
      formik.setFieldValue(
        "json.delivery_date",
        startDate ? format(startDate, "yyyy-MM-dd") : ""
      );
    setTypeDate("");
  }, [startDate]);

  useEffect(() => {
    if (dataDetailQuotation) {
      formik.setFieldValue(
        "json.shipping",
        dataDetailQuotation.shipping_method && dataShipmentMethod
          ? dataShipmentMethod.find(
              (item) => item.id === dataDetailQuotation.shipping_method
            ).id
          : 0
      );
      formik.setFieldValue(
        "json.bill_to",
        dataDetailQuotation.bill_to ? dataDetailQuotation.bill_to : ""
      );
      formik.setFieldValue(
        "json.shipping_address[0]",
        dataDetailQuotation.ship_to ? dataDetailQuotation.ship_to : ""
      );
      formik.setFieldValue(
        "json.customer_id",
        dataDetailQuotation.customer_id ? dataDetailQuotation.customer_id : ""
      );

      const costing = dataDetailQuotation?.labels?.map((item) => ({
        costing_id: item.label_id,
        internal_code: item.code || "",
      }));

      formik.setFieldValue("json.costing", costing);
    }
  }, [dataDetailQuotation]);

  useEffect(() => {
    if (anchorElCalendar === null && typeDate === "") setStartDate(null);
  }, [anchorElCalendar]);

  useEffect(() => {
    formik.setFieldValue(
      "json.so_number",
      "SO/" +
        new Date().getFullYear() +
        "/" +
        curDate.toString(36).toUpperCase()
    );

    const body = {
      name: "",
      start_date: "",
      end_date: "",
      pkp: 0,
      offset: 1,
      limit: 10000,
    };
    mutateCustomerList(body);
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="my-2 flex w-full gap-2 items-end">
          <FormControl fullWidth>
            <FormLabel>Status Order</FormLabel>
            <Select
              value={formik.values.json.status_order}
              onChange={(e) =>
                formik.setFieldValue(
                  "json.status_order",
                  Number(e.target.value)
                )
              }
            >
              <MenuItem value={0} disabled>
                Pilih Status Order
              </MenuItem>
              <MenuItem value={1}>New</MenuItem>
              <MenuItem value={2}>Repeat</MenuItem>
              <MenuItem value={3}>Sample Order</MenuItem>
              <MenuItem value={4}>RWC</MenuItem>
              <MenuItem value={5}>New + CRB</MenuItem>
              <MenuItem value={6}>RWC + CRB</MenuItem>
              <MenuItem value={7}>Reprint</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <FormLabel>Metode Pengiriman</FormLabel>
            <Select
              value={formik.values.json.shipping}
              onChange={(e) =>
                formik.setFieldValue("json.shipping", e.target.value)
              }
            >
              <MenuItem value={0} disabled>
                Pilih Metode Pengiriman
              </MenuItem>
              {dataShipmentMethod && dataShipmentMethod.length > 0
                ? dataShipmentMethod.map((item) => {
                    return (
                      <MenuItem value={item.id} key={item.id}>
                        {item.shipment_name}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
          </FormControl>
        </div>
        <div className="my-2 flex w-full gap-2 items-end">
          <FormControl fullWidth>
            <FormLabel>Sales</FormLabel>
            <Select
              value={formik.values.json.user_id}
              onChange={(e) => {
                formik.setFieldValue("json.user_id", Number(e.target.value));
                setSalesName(
                  dataUser.data.find((item) => item.id === e.target.value)
                    .fullname
                );
                setSelectedQuotation(null);
              }}
            >
              <MenuItem value={0} disabled>
                Pilih Sales
              </MenuItem>
              {dataUser && dataUser.data && dataUser.data.length > 0
                ? dataUser.data.map((item) => {
                    return <MenuItem value={item.id}>{item.fullname}</MenuItem>;
                  })
                : null}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <FormLabel>Nomor PO</FormLabel>
            <TextField
              value={formik.values.json.po_number}
              onChange={(e) => {
                if (e.target.value.length <= 200)
                  formik.setFieldValue("json.po_number", e.target.value);
              }}
            />
          </FormControl>
        </div>
        <div className="my-2 flex w-full gap-2 items-end">
          <FormControl fullWidth>
            <FormLabel>No. Quotation</FormLabel>
            <Select
              disabled={formik.values.json.user_id === 0}
              value={selectedQuotation}
              onChange={(e) => {
                formik.setFieldValue("json.quotation_id", e.target.value);
                setSelectedQuotation(e.target.value);
              }}
            >
              <MenuItem value={0} disabled>
                Pilih Quotation
              </MenuItem>
              {dataQuotation && dataQuotation.data
                ? dataQuotation.data.map((item) => {
                    return (
                      <MenuItem value={item.id}>
                        {item.quotation_number}
                        {" - "}
                        {item.customer}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
          </FormControl>
          <div className="w-full">
            <p>Tanggal PO</p>
            <div className="w-full mt-1 border-2 rounded-md flex items-center hover:bg-slate-100">
              <div
                className="flex justify-between items-center h-full cursor-pointer w-full p-3.5"
                onClick={(e) => handleOpenCalendar(e, "po")}
              >
                {formik.values.json.po_date === "" ||
                formik.values.json.po_date === null ? (
                  <p>----/--/--</p>
                ) : (
                  <p>
                    {format(new Date(formik.values.json.po_date), "yyyy/MM/dd")}
                  </p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
          </div>
        </div>
        <div className="my-2 flex w-full gap-2 items-end">
          <FormControl fullWidth>
            <FormLabel>Nomor SO</FormLabel>
            <TextField disabled value={formik.values.json.so_number} />
          </FormControl>
          <div className="w-full">
            <p>Upload File</p>
            <div className="w-full mt-1 border-2 rounded-md flex items-center p-1.5">
              {formik.values.file === null ? (
                <label htmlFor="dropzone-file1" className="">
                  <div className="border-2 rounded-md p-2 flex justify-between items-center cursor-pointer hover:bg-slate-100 gap-2 border-blue-500 text-blue-500">
                    <>
                      <AiOutlineCloudUpload /> <p>Choose File</p>
                    </>
                  </div>
                  <input
                    id="dropzone-file1"
                    type="file"
                    accept="image/jpeg, image/jpg, image/png, .pdf, .csv, .wordx"
                    onChange={(e) => {
                      uploadMedia(e);
                    }}
                    hidden
                  />
                </label>
              ) : (
                <div
                  onClick={() => formik.setFieldValue("file", null)}
                  className="border-2 rounded-md p-2 flex justify-between items-center cursor-pointer hover:bg-slate-100 gap-2 border-red-500 text-red-500"
                >
                  <>
                    <MdCancel /> <p>Remove File</p>
                  </>
                </div>
              )}

              <p className="ml-4 text-gray-300">
                {formik.values.file
                  ? formik.values.file.name
                  : "Pilih file dari perangkat anda"}
              </p>
            </div>
          </div>
        </div>
        <div className="my-2 flex w-full gap-2 items-end">
          <div className="w-full">
            <p>Tanggal SO</p>
            <div className="w-full mt-1 border-2 rounded-md flex items-center bg-slate-100">
              <div className="flex justify-between items-center h-full w-full p-3.5 text-gray-500">
                <p>
                  {formik.values.json.quotation_id !== 0
                    ? format(new Date(), "yyyy/MM/dd")
                    : "----/--/--"}
                </p>
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
          </div>
          <div className="w-full">
            <p>Tanggal Jatuh Tempo</p>
            <div className="w-full mt-1 border-2 rounded-md flex items-center hover:bg-slate-100">
              <div
                className="flex justify-between items-center h-full cursor-pointer w-full p-3.5"
                onClick={(e) => handleOpenCalendar(e, "due")}
              >
                {formik.values.json.due_date === "" ||
                formik.values.json.due_date === null ? (
                  <p>----/--/--</p>
                ) : (
                  <p>
                    {format(
                      new Date(formik.values.json.due_date),
                      "yyyy/MM/dd"
                    )}
                  </p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
          </div>
        </div>
        <div className="my-2 flex w-full gap-2 items-end">
          <FormControl fullWidth>
            <FormLabel>Customer</FormLabel>
            <Autocomplete
              sx={{ width: "100%" }}
              disablePortal
              value={
                formik.values.json.customer_id && dataCust
                  ? dataCust.data.find(
                      (item) => item.id === formik.values.json.customer_id
                    )
                  : null
              }
              onChange={(_, option) => {
                formik.setFieldValue(
                  "json.customer_id",
                  option ? option.id : null
                );
                formik.setFieldValue(
                  "json.bill_to",
                  option ? option.franco : ""
                );
                formik.setFieldValue(
                  "json.shipping_address",
                  option ? [option.franco] : [""]
                );
              }}
              options={dataCust ? dataCust.data : []}
              getOptionLabel={(option) => option.customer_name}
              renderInput={(params) => (
                <TextField {...params} placeholder="Pilih Customer" />
              )}
            />
          </FormControl>
          <div className="w-full">
            <p>ISP Confirmation</p>
            <div className="w-full mt-1 border-2 rounded-md flex items-center hover:bg-slate-100">
              <div
                className="flex justify-between items-center h-full cursor-pointer w-full p-3.5"
                onClick={(e) => handleOpenCalendar(e, "delivery")}
              >
                {formik.values.json.delivery_date === "" ||
                formik.values.json.delivery_date === null ? (
                  <p>----/--/--</p>
                ) : (
                  <p>
                    {format(
                      new Date(formik.values.json.delivery_date),
                      "yyyy/MM/dd"
                    )}
                  </p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
          </div>
        </div>
        <FormControl fullWidth sx={{ marginY: 1 }}>
          <FormLabel>Alamat Penagihan</FormLabel>
          <TextField
            multiline
            rows={4}
            onChange={(e) => {
              if (e.target.value.length <= 100)
                formik.setFieldValue("json.bill_to", e.target.value);
            }}
            value={formik.values.json.bill_to}
          />
        </FormControl>

        {formik.values.json.shipping_address.map((item, i) => (
          <FormControl fullWidth sx={{ marginY: 1 }} key={i}>
            <FormLabel>Alamat Pengiriman</FormLabel>
            <div className="flex gap-2 items-center">
              <TextField
                fullWidth
                multiline
                rows={2}
                onChange={(e) => changeShippingAddress(e.target.value, i)}
                value={formik.values.json.shipping_address[i]}
              />
              <FaTrash
                className={`${
                  i === 0
                    ? "cursor-default text-gray-300"
                    : "text-red-500 hover:text-gray-300 cursor-pointer"
                }`}
                onClick={() => {
                  if (i > 0)
                    formik.setFieldValue("json.shipping_address", [
                      ...formik.values.json.shipping_address.slice(0, i),
                      ...formik.values.json.shipping_address.slice(i + 1),
                    ]);
                }}
              />
            </div>
          </FormControl>
        ))}

        <p
          className="text-blue-500 font-bold text-right cursor-pointer hover:text-gray-300"
          onClick={() =>
            formik.setFieldValue("json.shipping_address", [
              ...formik.values.json.shipping_address,
              "",
            ])
          }
        >
          Tambah Alamat Pengiriman
        </p>

        <div className="rounded-md shadow-md mt-4">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <tr>
                  <th className="p-3 text-left">No</th>
                  <th className="p-3 text-left">Nama Label</th>
                  <th className="p-3 text-left">Internal Code</th>
                  <th className="p-3 text-left">Label Size</th>
                  <th className="p-3 text-left">Material</th>
                  <th className="p-3 text-left">Price</th>
                  <th className="p-3 text-left">Qty</th>
                  <th className="p-3 text-left">Total Price</th>
                </tr>
              </thead>
              <tbody>
                {dataDetailQuotation
                  ? dataDetailQuotation.labels.map((item, i) => (
                      <tr key={i}>
                        <td className="p-3 text-left">{i + 1}</td>
                        <td className="p-3 text-left">{item.label_name}</td>
                        <td className="p-3 text-center">
                          <TextField
                            fullWidth
                            multiline
                            onChange={(e) => changeCode(i, e.target.value)}
                            value={
                              formik.values.json.costing[i]?.internal_code ||
                              item.code
                            }
                          />
                        </td>
                        <td className="p-3 text-left">{item.label_size}</td>
                        <td className="p-3 text-left">
                          <ul>
                            {item.material.map((mat, index) => (
                              <li key={index}>{mat}</li>
                            ))}
                          </ul>
                        </td>
                        <td className="p-3 text-left">
                          {item.price.toLocaleString()}
                        </td>
                        <td className="p-3 text-left">{item.qty}</td>
                        <td className="p-3 text-left">
                          {dataDetailQuotation.total_price.toLocaleString()}
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>

        <p className="text-red-500 my-4 text-right">{errorMessage}</p>

        <div className="w-full">
          <div className="flex justify-end w-1/3 gap-2 float-right">
            <button
              type="button"
              className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
              onClick={() => navigate("/sales-order")}
            >
              Cancel
            </button>
            <button
              className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
              disabled={!formik.isValid}
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </form>

      <ModalInput
        title="Tambah SO"
        subtitle={`Apakah anda ingin menyimpan data SO ini?`}
        content={
          <>
            <p className="text-red-500">{errorMessage}</p>{" "}
          </>
        }
        open={openAdd}
        setOpen={setOpenAdd}
        hasButton={true}
        buttonText="Simpan"
        setTriggerFunc={() => {
          mutateNewSo(formik.values);
        }}
        isDisable={false}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendar}
        setAnchorElCalendarStart={setAnchorElCalendar}
        startDate={startDate}
        setStartDate={setStartDate}
      />
    </>
  );
};

export default AddSalesOrder;
