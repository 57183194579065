import { FormGroup, Select, TextField, MenuItem } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { getSupplierList } from "../../../services/supplierApi";
import { useEffect, useState } from "react";
import SingleCalendar from "../../common/SingleCalendar";
import { format, isSameDay } from "date-fns";
import { MdCalendarMonth } from "react-icons/md";

const GearModalContent = ({ formik }) => {
  const [incomingDate, setIncomingDate] = useState(
    formik.values.incoming_date ? new Date(formik.values.incoming_date) : null
  );
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);

  const { mutate: mutateSupplierList, data } = useMutation({
    mutationFn: getSupplierList,
    onSuccess: () => {},
  });

  useEffect(() => {
    if (
      !isSameDay(
        Date(incomingDate),
        formik.values.incoming_date ||
          formik.values.incoming_date === null ||
          incomingDate === null
      )
    )
      formik.setFieldValue(
        "incoming_date",
        incomingDate ? format(new Date(incomingDate), "yyyy-MM-dd") : null
      );
  }, [incomingDate]);

  useEffect(() => {
    const body = {
      name: "",
      start_date: "",
      end_date: "",
      pkp: 0,
      offset: 1,
      limit: 10000,
    };
    mutateSupplierList(body);
  }, []);

  return (
    <>
      <TextField
        label="Gear Size"
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.lvc}
        onChange={(e) => {
          if (e.target.value?.length <= 100)
            isNaN(Number(e.target.value))
              ? formik.setFieldValue("lvc", 0)
              : formik.setFieldValue("lvc", Number(e.target.value));
        }}
        helperText={formik.touched.lvc && formik.errors.lvc}
        error={formik.touched.lvc && Boolean(formik.errors.lvc)}
      />
      <TextField
        label="Distorsi"
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.distortion}
        onChange={(e) => {
          isNaN(Number(e.target.value))
            ? formik.setFieldValue("distortion", 0)
            : formik.setFieldValue("distortion", Number(e.target.value));
        }}
        helperText={formik.touched.distortion && formik.errors.distortion}
        error={formik.touched.distortion && Boolean(formik.errors.distortion)}
      />
      <p>Tanggal Sampai</p>
      <div className="mb-4  w-full border-2 rounded-md w-full flex items-center hover:bg-slate-100">
        <div
          className="flex justify-between items-center h-full cursor-pointer w-full cursor-pointer p-2"
          onClick={(e) => setAnchorElCalendar(e.currentTarget)}
        >
          {formik.values.incoming_date === null ||
          formik.values.incoming_date === "" ? (
            <p>----/--/--</p>
          ) : (
            <p>{format(new Date(formik.values.incoming_date), "yyyy/MM/dd")}</p>
          )}
          <MdCalendarMonth className="w-6 h-6" />
        </div>
      </div>
      <TextField
        label="Repeat Length"
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.repeat_length}
        onChange={(e) => {
          isNaN(Number(e.target.value))
            ? formik.setFieldValue("repeat_length", 0)
            : formik.setFieldValue("repeat_length", Number(e.target.value));
        }}
        helperText={formik.touched.repeat_length && formik.errors.repeat_length}
        error={
          formik.touched.repeat_length && Boolean(formik.errors.repeat_length)
        }
      />
      <TextField
        label="Quantity"
        fullWidth
        sx={{ marginBottom: 2 }}
        value={formik.values.quantity}
        onChange={(e) => {
          isNaN(Number(e.target.value))
            ? formik.setFieldValue("quantity", 0)
            : formik.setFieldValue("quantity", Number(e.target.value));
        }}
        helperText={formik.touched.quantity && formik.errors.quantity}
        error={formik.touched.quantity && Boolean(formik.errors.quantity)}
      />
      <FormGroup fullWidth>
        <Select
          value={formik.values.vendor}
          onChange={(e) => formik.setFieldValue("vendor", e.target.value)}
        >
          <MenuItem value={""} disabled>
            Pilih Vendor
          </MenuItem>
          {data && data.data
            ? data.data.map((item) => {
                return (
                  <MenuItem value={item.supplier_name}>
                    {item.supplier_name}
                  </MenuItem>
                );
              })
            : null}
        </Select>
      </FormGroup>
      <SingleCalendar
        anchorElCalendarStart={anchorElCalendar}
        setAnchorElCalendarStart={setAnchorElCalendar}
        startDate={incomingDate}
        setStartDate={setIncomingDate}
      />
    </>
  );
};

export default GearModalContent;
