import {
  TextField,
  InputAdornment,
  CircularProgress,
  FormGroup,
  FormLabel,
  Autocomplete,
  Chip,
  createFilterOptions,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { getSupplierList } from "../../../services/supplierApi";
import { useNavigate } from "react-router-dom";
import { MdCalendarMonth } from "react-icons/md";
import { format } from "date-fns";
import {
  getDeliveryOrder,
  getMasterDataReturCustomer,
  setMasterDataReturCustomer,
  setNewReturCustomer,
} from "../../../services/returCustomerApi";
import * as yup from "yup";
import SingleCalendar from "../../common/SingleCalendar";
import { getSpkDetail, getSpkList } from "../../../services/SpkApi";
import { getInternalCode } from "../../../services/returSupplierApi";
import ModalInput from "../../common/ModalInput";

const validationSchema = yup.object({
  date_retur: yup.string(),
  prod_date: yup.string(),
  spk_id: yup.number(),
  do_id: yup.array(),
  customer_id: yup.number(),
  problem: yup.string(),
  items: yup
    .array()
    .of(
      yup.object().shape({
        internal_code_id: yup.number(),
        internal_code: yup.string().required(),
        uom_name: yup.string().required(),
        label_name: yup.string().required(),
        uom_id: yup.number(),
        quantity: yup.number().required(),
        price_unit: yup.number().required(),
        total_cost: yup.number().required(),
      })
    )
    .min(1),
  remark: yup.string(),
});

const AddReturnCustomer = () => {
  const navigate = useNavigate();
  const defaultFilterOptions = createFilterOptions();

  const [searchMaterial, setSearchMaterial] = useState("");
  const [fetchLimit, setFetchLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [anchorElCalendarReturn, setAnchorElCalendarReturn] = useState(null);
  const [anchorElCalendarProd, setAnchorElCalendarProd] = useState(null);
  const [returnDate, setReturnDate] = useState(null);
  const [prodDate, setProdDate] = useState(null);
  const [selectedSpk, setSelectedSpk] = useState(null);
  const [codeArr, setCodeArr] = useState([]);
  const [doArr, setDoArr] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [refetchList, setRefetchList] = useState(false);
  const [openAddUom, setOpenAddUom] = useState(false);
  const [newUom, setNewUom] = useState({
    name: "",
    type: 3,
  });
  const [internalCode, setInternalCode] = useState([]);
  const [deliveryOrderId, setDeliveryOrderId] = useState([]);

  useEffect(() => {
    if (codeArr) {
      const code = codeArr.map((item) => item.costing_id);
      setInternalCode(code);
    }
  }, [codeArr, selectedSpk]);

  useEffect(() => {
    if (doArr) {
      const doId = doArr.map((item) => item.id);
      setDeliveryOrderId(doId);
    }
  }, [doArr, selectedSpk]);

  const { mutate: mutateSpkList, data: dataSpkList } = useMutation({
    mutationFn: getSpkList,
  });

  const { data: dataDetailSpk, isFetching: isFetchingSpk } = useQuery({
    queryKey: ["spk-detail", selectedSpk],
    queryFn: () => getSpkDetail(selectedSpk?.id),
    enabled: selectedSpk !== null,
  });

  const { mutate: mutateInternalCode, data: dataInternalCode } = useMutation({
    mutationFn: getInternalCode,
  });

  useEffect(() => {
    if (selectedSpk) {
      mutateInternalCode(selectedSpk?.id);
    }
  }, [selectedSpk]);

  useEffect(() => {
    setCodeArr([]);
    setDoArr([]);
  }, [selectedSpk]);

  const { mutate: mutateDeliveryOrder, data: dataDeliveryOrder } = useMutation({
    mutationFn: getDeliveryOrder,
  });

  useEffect(() => {
    if (selectedSpk) {
      mutateDeliveryOrder(selectedSpk?.id);
    }
  }, [selectedSpk]);

  const { data: dataUom } = useQuery({
    queryKey: ["uom", isAdd, refetchList],
    queryFn: () => getMasterDataReturCustomer({ type: 3 }),
  });

  const { mutate: mutateNewUom, isPending: isPendingCreateUom } = useMutation({
    mutationFn: setMasterDataReturCustomer,
    onSuccess: () => {
      setOpenAddUom(false);
      setIsAdd(false);
      setNewUom({
        name: "",
        type: 3,
      });
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
      setIsAdd(false);
    },
  });

  useEffect(() => {
    setRefetchList(false);
  }, [dataUom]);

  useEffect(() => {
    if (isAdd) {
      mutateNewUom(newUom);
    }
    if (!openAddUom) {
      setNewUom({
        name: "",
        type: 3,
      });
    }
  }, [isAdd, openAddUom]);

  const {
    mutate: mutateSupplierList,
    data: dataSupplier,
    isPending: isPendingList,
  } = useMutation({
    mutationFn: getSupplierList,
    onSuccess: () => {},
  });

  const { mutate: mutateNewReturn, isPending: isPendingCreate } = useMutation({
    mutationFn: setNewReturCustomer,
    onSuccess: () => navigate("/retur-customer"),
    onError: (err) => setErrorMessage(err.message.id),
  });

  const formik = useFormik({
    initialValues: {
      date_retur: "",
      prod_date: "",
      spk_id: 0,
      do_id: [],
      customer_id: 0,
      problem: "",
      items: [
        {
          internal_code_id: 0,
          internal_code: "",
          uom_name: "",
          label_name: "",
          uom_id: 0,
          quantity: 0,
          price_unit: 0,
          total_cost: 0,
        },
      ],
      remark: "",
    },
    validationSchema,
    onSubmit: (values) => {
      mutateNewReturn(values);
    },
  });

  useEffect(() => {
    mutateSpkList({
      offset: 1,
      limit: 10000,
      schedule: 0,
      search: "",
      po_start: "",
      po_end: "",
      delivery_start: "",
      delivery_end: "",
      job_type: [],
      category: [],
      dies: [],
    });

    mutateSupplierList({
      name: "",
      start_date: "",
      end_date: "",
      pkp: 0,
      offset: 1,
      limit: 10000,
    });
  }, []);

  useEffect(() => {
    formik.setFieldValue("customer_id", dataDetailSpk?.customer_id);
  }, [dataDetailSpk]);

  useEffect(() => {
    if (dataDeliveryOrder?.data) {
      const ids = dataDeliveryOrder?.data.map((order) => order.id);
      formik.setFieldValue("do_id", ids);
    }
  }, [dataDeliveryOrder?.data]);

  useEffect(() => {
    formik.setFieldValue(
      "date_retur",
      returnDate ? format(new Date(returnDate), "yyyy-MM-dd") : ""
    );
  }, [returnDate]);

  useEffect(() => {
    formik.setFieldValue(
      "prod_date",
      prodDate ? format(new Date(prodDate), "yyyy-MM-dd") : ""
    );
  }, [prodDate]);

  useEffect(() => {
    internalCode.forEach((code, index) => {
      formik.setFieldValue(`items[${index}].internal_code_id`, code);
    });
  }, [internalCode]);

  useEffect(() => {
    dataInternalCode?.items?.forEach((item, index) => {
      formik.setFieldValue(`items[${index}].internal_code`, item.code);
      formik.setFieldValue(`items[${index}].label_name`, item.label_name);
    });
  }, [dataInternalCode]);

  useEffect(() => {
    if (formik.values?.items[0].uom_id === -1) {
      formik.setFieldValue("items[0].uom_id", 0);
      setOpenAddUom(true);
    }
  }, [formik.values?.items[0].uom_id]);

  useEffect(() => {
    setPage(1);
  }, [fetchLimit, searchMaterial]);

  useEffect(() => {
    const body = {
      name: "",
      start_date: "",
      end_date: "",
      pkp: 0,
      offset: 1,
      limit: 10000,
    };
    mutateSupplierList(body);
  }, []);

  return (
    <div className="mt-12 lg:mt-2">
      <>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex gap-4">
            <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
              <FormLabel>Date Return</FormLabel>
              <div className="w-full border-2 rounded-md flex items-center hover:bg-slate-100">
                <div
                  className="flex justify-between items-center h-full w-full cursor-pointer p-3.5"
                  onClick={(e) => setAnchorElCalendarReturn(e.currentTarget)}
                >
                  {returnDate === null ? (
                    <p>----/--/--</p>
                  ) : (
                    <p>{format(new Date(returnDate), "yyyy-MM-dd")}</p>
                  )}
                  <MdCalendarMonth className="w-6 h-6" />
                </div>
              </div>
            </FormGroup>
            <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
              <FormLabel>Prod Date</FormLabel>
              <div className="w-full border-2 rounded-md flex items-center hover:bg-slate-100">
                <div
                  className="flex justify-between items-center h-full w-full cursor-pointer p-3.5"
                  onClick={(e) => setAnchorElCalendarProd(e.currentTarget)}
                >
                  {prodDate === null ? (
                    <p>----/--/--</p>
                  ) : (
                    <p>{format(new Date(prodDate), "yyyy-MM-dd")}</p>
                  )}
                  <MdCalendarMonth className="w-6 h-6" />
                </div>
              </div>
            </FormGroup>
          </div>

          <div className="flex gap-4">
            <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
              <FormLabel>SPK</FormLabel>
              <Autocomplete
                sx={{ width: "100%" }}
                disablePortal
                value={selectedSpk}
                onChange={(_, option) => {
                  formik.setFieldValue("spk_id", option ? option.id : null);
                  setSelectedSpk(option);
                }}
                options={
                  dataSpkList && dataSpkList.data ? dataSpkList.data : []
                }
                getOptionLabel={(option) => option.spk_no}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Pilih SPK" />
                )}
              />
            </FormGroup>
            <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
              <FormLabel>No. DO</FormLabel>
              <Autocomplete
                sx={{ width: "100%" }}
                disablePortal
                disabled={!selectedSpk}
                multiple
                value={doArr}
                onChange={(_, selectedOptions) => {
                  setDoArr(selectedOptions);
                  const selectedIds = selectedOptions.map(
                    (option) => option.id
                  );
                  formik.setFieldValue("do_id", selectedIds);
                }}
                options={dataDeliveryOrder?.data ? dataDeliveryOrder.data : []}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.do_number}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                getOptionLabel={(option) => option.do_number}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Pilih Internal Code" />
                )}
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "reset") {
                    setDoArr([]);
                    formik.setFieldValue("do_id", []);
                    return;
                  }
                }}
              />
            </FormGroup>
          </div>

          <div className="flex gap-4">
            <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
              <FormLabel>PO No</FormLabel>
              <TextField
                fullWidth
                value={dataDetailSpk ? dataDetailSpk.po_number : ""}
                disabled
                placeholder="Nomor PO"
              />
            </FormGroup>
            <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
              <FormLabel>Customer</FormLabel>
              <TextField
                fullWidth
                disabled
                value={dataDetailSpk?.customer_name}
                placeholder="Customer"
              />
            </FormGroup>
          </div>

          <div className="flex gap-4">
            <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
              <FormLabel>Internal Code</FormLabel>
              <Autocomplete
                sx={{ width: "100%" }}
                disablePortal
                disabled={!selectedSpk}
                multiple
                value={codeArr}
                onChange={(_, option) => {
                  setCodeArr(option);
                }}
                options={
                  dataInternalCode && dataInternalCode?.items
                    ? dataInternalCode?.items
                    : []
                }
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.code}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                getOptionLabel={(option) => option.code}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Pilih Internal Code" />
                )}
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "reset") {
                    setCodeArr([]);
                    return;
                  }
                }}
              />
            </FormGroup>
          </div>

          <div className="rounded-md shadow-md">
            <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-4 rounded-md">
              <table className="w-full">
                <thead className="bg-[#F4F5FF] border-t border-b border-2">
                  <th className="p-3 text-left">No</th>
                  <th className="p-3 text-left">Nama Product</th>
                  <th className="p-3 text-left">Qty</th>
                  <th className="p-3 text-left">UOM</th>
                  <th className="p-3 text-left">Price per Unit</th>
                  <th className="p-3 text-left">Total Cost</th>
                </thead>
                <tbody>
                  {codeArr?.length > 0 ? (
                    codeArr.map((item, i) => (
                      <tr className="border-t border-l border-2" key={i}>
                        <td className="p-3">{i + 1}</td>
                        <td className="p-3 text-left">{item.label_name}</td>
                        <td className="p-3 text-left w-28">
                          <TextField
                            fullWidth
                            value={
                              formik.values.items &&
                              formik.values.items[i]?.quantity !== undefined
                                ? formik.values.items[i]?.quantity
                                : 0
                            }
                            onChange={(e) => {
                              const newValue = e.target.value;

                              if (!isNaN(Number(newValue)) && newValue !== "") {
                                formik.setFieldValue(
                                  `items[${i}].quantity`,
                                  Number(newValue)
                                );
                              } else if (newValue === "") {
                                formik.setFieldValue(`items[${i}].quantity`, 0);
                              }
                            }}
                          />
                        </td>
                        <td className="p-3 text-left">
                          <Autocomplete
                            sx={{ width: "100%" }}
                            disablePortal
                            value={
                              dataUom?.find(
                                (uom) =>
                                  uom.id === formik.values.items[i]?.uom_id
                              ) || null
                            }
                            onChange={(_, option) => {
                              if (option) {
                                formik.setFieldValue(
                                  `items[${i}].uom_id`,
                                  option.id
                                );
                                formik.setFieldValue(
                                  `items[${i}].uom_name`,
                                  option.name
                                );
                              }
                            }}
                            options={
                              dataUom
                                ? [
                                    { id: -1, name: "Tambah UOM disini..." },
                                    ...dataUom,
                                  ]
                                : []
                            }
                            getOptionLabel={(option) =>
                              option.name && typeof option.name === "string"
                                ? option.name
                                : ""
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Pilih Type Non Conformity"
                              />
                            )}
                            filterOptions={(options, state) => {
                              const results = defaultFilterOptions(
                                options,
                                state
                              );
                              if (results?.length === 0) {
                                return [{ id: -1, name: "Tambah UOM" }];
                              }
                              return results;
                            }}
                          />
                        </td>
                        <td className="p-3 text-left w-44">
                          <TextField
                            fullWidth
                            value={
                              formik.values.items &&
                              formik.values.items[i]?.price_unit !== undefined
                                ? formik.values.items[i]?.price_unit
                                : 0
                            }
                            onChange={(e) => {
                              const newValue = e.target.value;

                              if (!isNaN(Number(newValue)) && newValue !== "") {
                                formik.setFieldValue(
                                  `items[${i}].price_unit`,
                                  Number(newValue)
                                );
                              } else if (newValue === "") {
                                formik.setFieldValue(
                                  `items[${i}].price_unit`,
                                  0
                                );
                              }
                            }}
                          />
                        </td>
                        <td className="p-3 text-left w-44">
                          <TextField
                            fullWidth
                            value={
                              formik.values.items &&
                              formik.values.items[i]?.total_cost !== undefined
                                ? formik.values.items[i]?.total_cost
                                : 0
                            }
                            onChange={(e) => {
                              const newValue = e.target.value;

                              if (!isNaN(Number(newValue)) && newValue !== "") {
                                formik.setFieldValue(
                                  `items[${i}].total_cost`,
                                  Number(newValue)
                                );
                              } else if (newValue === "") {
                                formik.setFieldValue(
                                  `items[${i}].total_cost`,
                                  0
                                );
                              }
                            }}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="text-center p-3" colSpan={7}>
                        Belum ada material terpilih
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Problem</FormLabel>
            <TextField
              fullWidth
              value={formik.values.problem}
              onChange={(e) => {
                if (e.target.value.length <= 300)
                  formik.setFieldValue("problem", e.target.value);
              }}
              placeholder="Tuliskan problem disini..."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {formik.values.problem.length}/300
                  </InputAdornment>
                ),
              }}
            />
          </FormGroup>

          <FormGroup sx={{ width: "100%", marginBottom: 2 }}>
            <FormLabel>Remark</FormLabel>
            <TextField
              fullWidth
              value={formik.values.remark}
              onChange={(e) => {
                if (e.target.value.length <= 300)
                  formik.setFieldValue("remark", e.target.value);
              }}
              placeholder="Tuliskan remark disini..."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {formik.values.remark.length}/300
                  </InputAdornment>
                ),
              }}
            />
          </FormGroup>

          <p className="text-red-500 mb-4 text-right m-4">{errorMessage}</p>
          <div className="flex justify-end w-1/3 gap-2 float-right mb-4">
            <button
              type="button"
              className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
              onClick={() => navigate("/retur-customer")}
            >
              Cancel
            </button>
            <button
              className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
              type="submit"
              disabled={!formik.isValid || isPendingCreate}
            >
              {isPendingCreate ? <CircularProgress size={20} /> : "Save"}
            </button>
          </div>
        </form>

        <SingleCalendar
          anchorElCalendarStart={anchorElCalendarReturn}
          setAnchorElCalendarStart={setAnchorElCalendarReturn}
          startDate={returnDate}
          setStartDate={setReturnDate}
        />

        <SingleCalendar
          anchorElCalendarStart={anchorElCalendarProd}
          setAnchorElCalendarStart={setAnchorElCalendarProd}
          startDate={prodDate}
          setStartDate={setProdDate}
        />

        <ModalInput
          title="Tambah UOM"
          content={
            <>
              <TextField
                fullWidth
                label="Nama UOM baru disini..."
                value={newUom.name}
                onChange={(e) =>
                  setNewUom({
                    ...newUom,
                    name: e.target.value,
                  })
                }
              />
            </>
          }
          open={openAddUom}
          setOpen={setOpenAddUom}
          hasButton={true}
          buttonText="Save"
          setTriggerFunc={setIsAdd}
          isDisable={isPendingCreateUom || newUom === ""}
        />
      </>
    </div>
  );
};

export default AddReturnCustomer;
