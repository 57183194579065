import { Collapse } from "@mui/material";
import { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import SpkCardInfo from "./SpkCardInfo";
import SpkCardAdvancedDetail from "./SpkCardAdvancedDetail";
import SpkCardMaterial from "./SpkCardMaterial";
import SpkCardEstimateSystem from "./SpkCardEstimateSystem";
import SpkCardEstimateHr from "./SpkCardEstimateHr";
import SpkCardLabelInfo from "./SpkCardLabelInfo";
import SpkCardQC from "./SpkCardQC";
import { useNavigate } from "react-router-dom";
import SpkCardInkCoverage from "./SpkCardInkCoverage";

const SpkProjectCard = ({
  expandArr,
  setExpandArr,
  formik,
  i,
  project,
  setIsSubmit,
  isSave,
}) => {
  const navigate = useNavigate();
  const [curProject, setCurProject] = useState(project);

  const handleChangeExpand = (index, type, toggle) => {
    var temp = [...expandArr];
    temp[index][type] = toggle;
    setExpandArr(temp);
  };

  const findDistortion = (size) => {
    return size === 78
      ? 96003
      : size === 80
      ? 96103
      : size === 88
      ? 96457
      : size === 97
      ? 96786
      : size === 98
      ? 96819
      : size === 104
      ? 97002
      : size === 106
      ? 97058
      : size === 112
      ? 97216
      : size === 120
      ? 97402
      : size === 124
      ? 97486
      : size === 128
      ? 97564
      : size === 136
      ? 97708
      : size === 144
      ? 97835
      : 0;
  };

  useEffect(() => {
    if (!isSave) {
      const formikProject = formik.values.project[i];
      var tempProject = JSON.parse(JSON.stringify(curProject));

      // Detail
      tempProject.detail.qty_production = formikProject.detail.qty_production;
      tempProject.detail.finishing = formikProject.detail.finishing;

      tempProject.detail.gear_amount =
        formikProject.detail.gear_size === null &&
        formikProject.detail.up_along === 0
          ? 0
          : (formikProject.detail.gear_size * 3.175) /
            formikProject.detail.up_along;

      tempProject.detail.gap_along =
        formikProject.detail.gear_size === null &&
        formikProject.detail.up_along === 0
          ? 0
          : tempProject.detail.gear_amount -
            Number(formikProject.detail.label_size.split("x")[1]);

      tempProject.detail.max_up =
        formikProject.detail.gear_size === null &&
        formikProject.detail.up_along === 0
          ? 0
          : 250 / Number(formikProject.detail.label_size.split("x")[0]);

      tempProject.detail.total_gap_across =
        formikProject.detail.adjustment_up - 1 >= 0
          ? formikProject.detail.adjustment_up - 1
          : 0;

      tempProject.detail.total_gap_range =
        formikProject.detail.gap_across * tempProject.detail.total_gap_across;

      tempProject.detail.clean_counter =
        formikProject.detail.adjustment_up > 0
          ? tempProject.detail.qty_production /
            formikProject.detail.adjustment_up
          : 0;

      tempProject.detail.along_run = formikProject.detail.gear_size;

      tempProject.detail.speed =
        formikProject.detail.total_color >= 1 &&
        formikProject.detail.total_color <= 4
          ? 4200
          : formikProject.detail.total_color >= 5 &&
            formikProject.detail.total_color <= 6
          ? 3600
          : formikProject.detail.total_color >= 7 &&
            formikProject.detail.total_color <= 8
          ? 3000
          : formikProject.detail.total_color >= 9 &&
            formikProject.detail.total_color <= 10
          ? 2400
          : 0;

      tempProject.detail.eye_total =
        formikProject.detail.up_along * formikProject.detail.adjustment_up;

      tempProject.detail.printing_area =
        formikProject.detail.adjustment_up *
        Number(formikProject.detail.label_size.split("x")[0]);

      tempProject.detail.size_material = parseFloat(
        tempProject.detail.total_gap_range +
          formikProject.detail.j_left +
          formikProject.detail.j_right +
          tempProject.detail.printing_area
      );

      tempProject.detail.adjustment_mm = parseFloat(
        tempProject.detail.size_material
      );

      tempProject.detail.tolerance_percent = formikProject.detail.total_color;

      tempProject.detail.difficult_waste =
        formik.values.category === 2 ? 250 : 0;

      tempProject.detail.tolerance_m =
        25 +
        10 * formikProject.detail.total_color +
        (tempProject.detail.tolerance_percent / 100) *
          ((tempProject.detail.clean_counter * tempProject.detail.along_run) /
            1000) +
        tempProject.detail.difficult_waste;

      tempProject.detail.crb =
        formik.values.job_type === 5 || formik.values.job_type === 6
          ? 250
          : parseFloat(formikProject.detail.crb);

      tempProject.detail.max_counter =
        formikProject.detail.adjustment_up > 0
          ? tempProject.detail.clean_counter + tempProject.detail.crb
          : 0;

      tempProject.detail.max_run =
        (tempProject.detail.clean_counter * tempProject.detail.along_run) /
          1000 +
        tempProject.detail.tolerance_percent / 100 +
        tempProject.detail.crb +
        tempProject.detail.difficult_waste;

      tempProject.detail.distortion = findDistortion(
        formikProject.detail.gear_size
      );

      // Material
      var tempMaterial = [...tempProject.material];
      tempProject.material.map((_, index) => {
        tempMaterial[index].size = tempProject.detail.adjustment_mm;
        tempMaterial[index].consumption = tempProject.detail.max_run;
      });
      tempProject.material = tempMaterial;

      // System Estimate
      var tempSys = [...tempProject.system_estimate];
      tempProject.system_estimate.map((_, index) => {
        tempSys[index] = {
          run_system: isNaN(
            (tempProject.detail.clean_counter * tempProject.detail.along_run) /
              1000
          )
            ? 0
            : (tempProject.detail.clean_counter *
                tempProject.detail.along_run) /
              1000,
          run_waste_system:
            tempProject.detail.tolerance_m / 100 + tempProject.detail.crb,
          total_system: isNaN(
            (tempProject.detail.clean_counter * tempProject.detail.along_run) /
              1000
          )
            ? 0
            : (tempProject.detail.clean_counter *
                tempProject.detail.along_run) /
                1000 +
              (tempProject.detail.tolerance_m / 100 + tempProject.detail.crb),
        };
      });
      tempProject.system_estimate = tempSys;

      // HR Estimate
      var tempHr = [...tempProject.hr_estimate];
      tempProject.hr_estimate.map((_, index) => {
        tempHr[index] = {
          run_hr:
            tempProject.system_estimate[0].run_system /
              tempProject.detail.speed +
            0.1 * tempProject.detail.total_color,
          run_waste_hr: tempProject.detail.total_color * 0.1 + 0.1 + 0.33,
          total_hr:
            tempProject.system_estimate[0].run_system /
              tempProject.detail.speed +
            0.1 * tempProject.detail.total_color +
            (tempProject.detail.total_color * 0.1 + 0.1 + 0.33),
        };
      });
      tempProject.hr_estimate = tempHr;

      // QC
      tempProject.qc = [
        {
          sliting: tempProject.detail.max_run / 2000,
          sheeting:
            formikProject.detail.qty_order /
            (tempProject.label[0].lembar > 0
              ? tempProject.label[0].lembar
              : 1) /
            7000,
          cutting: formik.values.die === 1 ? 3.9 : 0,
          packing_roll:
            formikProject.detail.qty_order /
            (tempProject.label[0].roll > 0 ? tempProject.label[0].roll : 1) /
            30,
          packing_sheet:
            formikProject.detail.qty_order /
            (tempProject.label[0].roll > 0 || tempProject.label[0].ikat > 0
              ? tempProject.label[0].roll * tempProject.label[0].ikat
              : 1) /
            30,
        },
      ];

      // Ink_Coverage
      tempProject.ink_coverage = {
        black: parseFloat(formikProject?.ink_coverage?.black),
        cyan: parseFloat(formikProject?.ink_coverage?.cyan),
        green: parseFloat(formikProject?.ink_coverage?.green),
        magenta: parseFloat(
          formikProject?.ink_coverage?.magenta
        ),
        orange: parseFloat(formikProject?.ink_coverage?.orange),
        p_032: parseFloat(formikProject?.ink_coverage?.p_032),
        ref_blue: parseFloat(
          formikProject?.ink_coverage?.ref_blue
        ),
        varnish: parseFloat(
          formikProject?.ink_coverage?.varnish
        ),
        violet: parseFloat(formikProject?.ink_coverage?.violet),
        yellow: parseFloat(formikProject?.ink_coverage?.yellow),
      };

      setCurProject(tempProject);
    }
  }, [
    formik.values.project[i].detail,
    formik.values.job_type,
    formik.values.category,
    formik.values.die,
  ]);

  useEffect(() => {
    if (isSave) {
      formik.setFieldValue(`project[${i}]`, curProject);
      setIsSubmit(true);
    }
  }, [isSave]);
  
  return (
    <>
      {formik.values.project.length > 0 ? (
        <div className="border-2 border-gray-300 rounded-md p-3.5">
          <div className="w-full justify-between items-center flex p-2">
            <p className="font-bold">
              {formik.values.project[i].detail.internal_code}
            </p>

            {expandArr[i]?.project ? (
              <FaChevronUp
                className="cursor-pointer hover:text-gray-300"
                onClick={() => handleChangeExpand(i, "project", false)}
              />
            ) : (
              <FaChevronDown
                className="cursor-pointer hover:text-gray-300"
                onClick={() => handleChangeExpand(i, "project", true)}
              />
            )}
          </div>
          <Collapse in={expandArr[i]?.project}>
            <div className="border-2 border-gray-300 rounded-md p-3.5 mb-4">
              <SpkCardInfo proj={project} formik={formik} i={i} />
            </div>

            <div className="border-2 border-gray-300 rounded-md p-3.5 mb-4">
              <div className="w-full justify-between flex p-2">
                <p className="font-bold">Advanced Details</p>
                {expandArr[i]?.detail ? (
                  <FaChevronUp
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "detail", false)}
                  />
                ) : (
                  <FaChevronDown
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "detail", true)}
                  />
                )}
              </div>
              <Collapse in={expandArr[i]?.detail}>
                <SpkCardAdvancedDetail
                  proj={project}
                  formik={formik}
                  i={i}
                  curProject={curProject}
                  setCurProject={setCurProject}
                />
              </Collapse>
            </div>
            <div className="border-2 border-gray-300 rounded-md p-3.5 mb-4">
              <div className="w-full justify-between flex p-2">
                <p className="font-bold">Material Details</p>
                {expandArr[i]?.material ? (
                  <FaChevronUp
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "material", false)}
                  />
                ) : (
                  <FaChevronDown
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "material", true)}
                  />
                )}
              </div>
              <Collapse in={expandArr[i]?.material}>
                <SpkCardMaterial proj={curProject} />
              </Collapse>
            </div>

            <div className="border-2 border-gray-300 rounded-md p-3.5 mb-4">
              <div className="w-full justify-between flex p-2">
                <p className="font-bold">Material</p>
                {expandArr[i]?.estimate_system ? (
                  <FaChevronUp
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() =>
                      handleChangeExpand(i, "estimate_system", false)
                    }
                  />
                ) : (
                  <FaChevronDown
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() =>
                      handleChangeExpand(i, "estimate_system", true)
                    }
                  />
                )}
              </div>
              <Collapse in={expandArr[i]?.estimate_system}>
                <SpkCardEstimateSystem
                  curProject={curProject}
                  i={i}
                  isDetail={false}
                />
              </Collapse>
            </div>

            <div className="border-2 border-gray-300 rounded-md p-3.5 mb-4">
              <div className="w-full justify-between flex p-2">
                <p className="font-bold">Time</p>
                {expandArr[i]?.estimate_hr ? (
                  <FaChevronUp
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "estimate_hr", false)}
                  />
                ) : (
                  <FaChevronDown
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "estimate_hr", true)}
                  />
                )}
              </div>
              <Collapse in={expandArr[i]?.estimate_hr}>
                <SpkCardEstimateHr curProj={curProject} isDetail={false} />
              </Collapse>
            </div>

            <div className="border-2 border-gray-300 rounded-md p-3.5 mb-4">
              <div className="w-full justify-between flex p-2">
                <p className="font-bold">Label Information</p>
                {expandArr[i]?.label ? (
                  <FaChevronUp
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "label", false)}
                  />
                ) : (
                  <FaChevronDown
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "label", true)}
                  />
                )}
              </div>
              <Collapse in={expandArr[i]?.label}>
                <SpkCardLabelInfo
                  curProj={curProject}
                  setCurProject={setCurProject}
                  formik={formik}
                  i={i}
                />
              </Collapse>
            </div>

            <div className="border-2 border-gray-300 rounded-md p-3.5 mb-4">
              <div className="w-full justify-between flex p-2">
                <p className="font-bold">Quality Control</p>
                {expandArr[i]?.qc ? (
                  <FaChevronUp
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "qc", false)}
                  />
                ) : (
                  <FaChevronDown
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "qc", true)}
                  />
                )}
              </div>
              <Collapse in={expandArr[i]?.qc}>
                <SpkCardQC curProj={curProject} isDetail={false} />
              </Collapse>
            </div>

            <div className="border-2 border-gray-300 rounded-md p-3.5 mb-4">
              <div className="w-full justify-between flex p-2">
                <p className="font-bold">Ink Coverage</p>
                {expandArr[i]?.ink_coverage ? (
                  <FaChevronUp
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "ink_coverage", false)}
                  />
                ) : (
                  <FaChevronDown
                    className="cursor-pointer hover:text-gray-300"
                    onClick={() => handleChangeExpand(i, "ink_coverage", true)}
                  />
                )}
              </div>
              <Collapse in={expandArr[i]?.ink_coverage}>
                <SpkCardInkCoverage
                  proj={project}
                  formik={formik}
                  i={i}
                  curProject={curProject}
                  setCurProject={setCurProject}
                  isDetail={false}
                />
              </Collapse>
            </div>
          </Collapse>
        </div>
      ) : (
        <p className="text-gray-400 text-center">
          Belum ada project, mohon memilih nomor SO
        </p>
      )}
    </>
  );
};

export default SpkProjectCard;
