import {
  Autocomplete,
  MenuItem,
  Select,
  TextField,
  FormControl,
  FormGroup,
  FormLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getSpkDetail, getSpkList } from "../../../services/SpkApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { secondsToHours, secondsToMinutes } from "date-fns";
import {
  getDetailScheduleBySpk,
  setNewJob,
} from "../../../services/scheduleApi";
import UpdateJobTimer from "./UpdateJobTimer";

const validationSchema = yup.object({
  schedule_access: yup.number(),
  spk_id: yup.number().min(1),
  internal_code: yup.string().required(),
});

const JobOperator = () => {
  const navigate = useNavigate();
  const { spkId } = useParams();
  const timerName = [
    { name: "Setup", keyName: "setup" },
    { name: "Acc QC/Leader", keyName: "acc_qc" },
    { name: "Acc Customer/Sales", keyName: "acc_customer" },
    { name: "Printing Defect", keyName: "printing_defect" },
    { name: "Change Over", keyName: "change_over" },
    { name: "Break Down Repair", keyName: "breakdown_repair" },
    { name: "Break Down UV Lamp", keyName: "breakdown_uv" },
    { name: "Run", keyName: "run" },
    {
      name: "Cleaning & Maintenance",
      keyName: "cleaning_maintenance",
    },
    { name: "Tunggu Material", keyName: "wait_material" },
    { name: "Break", keyName: "break" },
    { name: "No Job", keyName: "no_job" },
    { name: "Paper Problem", keyName: "paper_problem" },
    { name: "Color Adjust", keyName: "color_adjust" },
    { name: "Plate Problem", keyName: "plate_problem" },
    { name: "Screen Problem", keyName: "screen_problem" },
    { name: "Die Cut Problem", keyName: "diecut_problem" },
    { name: "Register Problem", keyName: "register_problem" },
    { name: "Press Complete", keyName: "press_complete" },
    { name: "Rework/Reprint", keyName: "rework" },
  ];
  const [spk, setSpk] = useState(null);
  const [internalCode, setInternalCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeButton, setActiveButton] = useState(null);
  const [dataDetailSchedule, setDataDetailSchedule] = useState(null);
  const [isFetchingDetailSchedule, setIsFetchingDetailSchedule] =
    useState(false);

  useEffect(() => {
    const fetchDetailSchedule = async () => {
      if (spk?.id && internalCode) {
        setIsFetchingDetailSchedule(true);
        try {
          const data = await getDetailScheduleBySpk(spk?.id, internalCode);
          setDataDetailSchedule(data);
        } catch (error) {
          console.error("Error fetching schedule detail:", error);
        } finally {
          setIsFetchingDetailSchedule(false);
        }
      }
    };

    fetchDetailSchedule();
  }, [spk?.id]);

    const { data: dataDetailSpk, isFetching } = useQuery({
      queryKey: [
        "spk-detail",
        spk?.id,
        dataDetailSchedule?.tooling_control?.spk_id,
      ],
      queryFn: () =>
        getSpkDetail(dataDetailSchedule?.tooling_control?.spk_id || spk?.id),
      enabled: !!dataDetailSchedule || !!spk?.id,
    });

  const formik = useFormik({
    initialValues: {
      schedule_access: 4,
      spk_id: dataDetailSchedule
        ? dataDetailSchedule.tooling_control.spk_id
        : 0,
      internal_code: dataDetailSchedule
        ? dataDetailSchedule.tooling_control.internal_code
        : "",
      total_time: 0,
      setup: 0,
      acc_qc: 0,
      acc_customer: 0,
      printing_defect: 0,
      change_over: 0,
      breakdown_repair: 0,
      breakdown_uv: 0,
      run: 0,
      cleaning_maintenance: 0,
      wait_material: 0,
      break: 0,
      no_job: 0,
      paper_problem: 0,
      color_adjust: 0,
      plate_problem: 0,
      screen_problem: 0,
      diecut_problem: 0,
      register_problem: 0,
      press_complete: 0,
      rework: 0,
      size_estimate: 0,
      size_act: "",
      run_estimate: 0,
      run_act: "",
      time_estimate: 0,
      time_act: "",
      note: "",
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values) => {
      mutateTakeJob(values);
    },
  });

  const { mutate: mutateTakeJob, isPending } = useMutation({
    mutationFn: setNewJob,
    onSuccess: () => navigate("/schedule"),
    onError: (err) => setErrorMessage(err.message.id),
  });

  useEffect(() => {
    const fieldsToSet = {
      schedule_access: 4,
      spk_id: spk?.id,
      internal_code: internalCode,
      total_time: calculateTotalTime(dataDetailSchedule),
      setup: Number(dataDetailSchedule?.production_information?.setup),
      acc_qc: Number(dataDetailSchedule?.production_information?.acc_qc),
      acc_customer: Number(
        dataDetailSchedule?.production_information?.acc_customer
      ),
      printing_defect: Number(
        dataDetailSchedule?.production_information?.printing_defect
      ),
      change_over: Number(
        dataDetailSchedule?.production_information?.change_over
      ),
      breakdown_repair: Number(
        dataDetailSchedule?.production_information?.breakdown_repair
      ),
      breakdown_uv: Number(
        dataDetailSchedule?.production_information?.breakdown_uv
      ),
      run: Number(dataDetailSchedule?.production_information?.run),
      cleaning_maintenance: Number(
        dataDetailSchedule?.production_information?.cleaning_maintenance
      ),
      wait_material: Number(
        dataDetailSchedule?.production_information?.wait_material
      ),
      break: Number(dataDetailSchedule?.production_information?.break),
      no_job: Number(dataDetailSchedule?.production_information?.no_job),
      paper_problem: Number(
        dataDetailSchedule?.production_information?.paper_problem
      ),
      color_adjust: Number(
        dataDetailSchedule?.production_information?.color_adjust
      ),
      plate_problem: Number(
        dataDetailSchedule?.production_information?.plate_problem
      ),
      screen_problem: Number(
        dataDetailSchedule?.production_information?.screen_problem
      ),
      diecut_problem: Number(
        dataDetailSchedule?.production_information?.diecut_problem
      ),
      register_problem: Number(
        dataDetailSchedule?.production_information?.register_problem
      ),
      press_complete: Number(
        dataDetailSchedule?.production_information?.press_complete
      ),
      rework: Number(dataDetailSchedule?.production_information?.rework),
      size_estimate: dataDetailSpk?.project[0]?.material[0]?.size,
      size_act: dataDetailSchedule?.production_information?.size_act,
      run_estimate: dataDetailSpk?.project[0]?.system_estimate[0]?.total_system,
      run_act: dataDetailSchedule?.production_information?.run_act,
      time_estimate: dataDetailSpk?.project[0]?.hr_estimate[0]?.total_hr,
      time_act: dataDetailSchedule?.production_information?.time_act,
      note: dataDetailSchedule?.production_information?.production_note,
    };

    Object.entries(fieldsToSet).forEach(([field, value]) => {
      formik.setFieldValue(field, value);
    });
  }, [dataDetailSchedule, internalCode]);

  const calculateTotalTime = (dataDetailSchedule) => {
    return (
      (Number(dataDetailSchedule?.production_information?.setup) || 0) +
      (Number(dataDetailSchedule?.production_information?.acc_qc) || 0) +
      (Number(dataDetailSchedule?.production_information?.acc_customer) || 0) +
      (Number(dataDetailSchedule?.production_information?.printing_defect) ||
        0) +
      (Number(dataDetailSchedule?.production_information?.change_over) || 0) +
      (Number(dataDetailSchedule?.production_information?.breakdown_repair) ||
        0) +
      (Number(dataDetailSchedule?.production_information?.breakdown_uv) || 0) +
      (Number(dataDetailSchedule?.production_information?.run) || 0) +
      (Number(
        dataDetailSchedule?.production_information?.cleaning_maintenance
      ) || 0) +
      (Number(dataDetailSchedule?.production_information?.wait_material) || 0) +
      (Number(dataDetailSchedule?.production_information?.break) || 0) +
      (Number(dataDetailSchedule?.production_information?.no_job) || 0) +
      (Number(dataDetailSchedule?.production_information?.paper_problem) || 0) +
      (Number(dataDetailSchedule?.production_information?.color_adjust) || 0) +
      (Number(dataDetailSchedule?.production_information?.plate_problem) || 0) +
      (Number(dataDetailSchedule?.production_information?.screen_problem) ||
        0) +
      (Number(dataDetailSchedule?.production_information?.diecut_problem) ||
        0) +
      (Number(dataDetailSchedule?.production_information?.register_problem) ||
        0) +
      (Number(dataDetailSchedule?.production_information?.press_complete) ||
        0) +
      (Number(dataDetailSchedule?.production_information?.rework) || 0)
    );
  };

  const { mutate: mutateSpkList, data: dataSpkList } = useMutation({
    mutationFn: getSpkList,
  });

  useEffect(() => {
    mutateSpkList({
      offset: 1,
      limit: 10,
      schedule: 4,
      search: "",
      po_start: "",
      po_end: "",
      delivery_start: "",
      delivery_end: "",
      job_type: [],
      category: [],
      dies: [],
    });
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Autocomplete
          sx={{ width: "100%", marginBottom: 2 }}
          disablePortal
          disabled={spkId}
          value={
            spkId
              ? dataSpkList
                ? dataSpkList?.data?.find(
                    (item) =>
                      item.id ===
                      Number(dataDetailSchedule.tooling_control.spk_id)
                  )
                : null
              : spk
          }
          onChange={(_, option) => {
            if (!spkId) {
              setSpk(option);
              formik.setFieldValue("spk_id", option ? option.id : null);
            }
          }}
          options={dataSpkList ? dataSpkList.data : []}
          getOptionLabel={(option) =>
            option.spk_no + " - " + option.customer_name
          }
          renderInput={(params) => <TextField {...params} label="No. SPK" />}
          onInputChange={(event, newInputValue, reason) => {
            if (reason === "reset" && !spkId) {
              setSpk(null);
              formik.setFieldValue("spk_id", null);
              formik.setFieldValue("internal_code", "");
              return;
            }
          }}
        />
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <FormLabel>Internal Code</FormLabel>
          <Select
            disabled={!dataDetailSpk || (!spkId && spk === null) || spkId}
            value={formik.values.internal_code}
            onChange={(e) => {
              formik.setFieldValue("internal_code", e.target.value);
              setInternalCode(e.target.value);
            }}
          >
            <MenuItem value={""} disabled>
              Pilih Internal Code
            </MenuItem>
            {dataDetailSpk && dataDetailSpk.project ? (
              dataDetailSpk.project.map((item, i) => (
                <MenuItem value={item.internal_code} key={i}>
                  {item.internal_code}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No Internal Codes Available</MenuItem>
            )}
          </Select>
        </FormControl>
        <FormGroup>
          <FormLabel>Label Name</FormLabel>
          <TextField
            fullWidth
            sx={{ marginBottom: 2 }}
            disabled
            value={
              dataDetailSpk && formik.values.internal_code !== ""
                ? dataDetailSpk?.project?.find(
                    (proj) =>
                      proj.internal_code === formik?.values?.internal_code
                  )?.label_name
                : ""
            }
          />
        </FormGroup>

        <div className="my-4 grid grid-cols-4 grid-flow-row gap-2">
          {timerName.map((timer, i) => (
            <UpdateJobTimer
              timerInfo={timer}
              formik={formik}
              activeButton={activeButton}
              setActiveButton={setActiveButton}
              i={i}
            />
          ))}
        </div>

        <div className="flex items-center gap-2">
          <p className="">Total Time</p>
          <div className="py-2 px-8 bg-slate-100 rounded-md flex items-end">
            {secondsToHours(formik.values.total_time) > 0 ? (
              <>
                <p className="text-xl">
                  {secondsToHours(formik.values.total_time)}
                </p>
                <p className="mr-3">h</p>
              </>
            ) : null}
            {secondsToMinutes(formik.values.total_time) > 0 ? (
              <>
                <p className="text-xl">
                  {secondsToMinutes(formik.values.total_time)}
                </p>
                <p className="mr-3">m</p>{" "}
              </>
            ) : null}
            {formik.values.total_time > 0 ? (
              <>
                <p>{formik.values.total_time % 60}</p>
                <p>s</p>{" "}
              </>
            ) : (
              0
            )}
          </div>
        </div>

        <div className="my-4">
          <p className="font-bold">Size</p>
          <hr className="my-2" />
          <div className="mb-4 flex gap-2">
            <TextField
              fullWidth
              label="Estimation"
              disabled
              value={formik.values.size_estimate}
            />
            <TextField
              fullWidth
              label="Actual"
              value={formik.values.size_act}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue("size_act", e.target.value);
              }}
            />
          </div>
        </div>

        <div className="my-4">
          <p className="font-bold">Run (M)</p>
          <hr className="my-2" />
          <div className="mb-4 flex gap-2">
            <TextField
              fullWidth
              label="Estimation"
              disabled
              value={formik.values.run_estimate}
            />
            <TextField
              fullWidth
              label="Actual"
              value={formik.values.run_act}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue("run_act", e.target.value);
              }}
            />
          </div>
        </div>

        <div className="my-4">
          <p className="font-bold">Time (HR)</p>
          <hr className="my-2" />
          <div className="mb-4 flex gap-2">
            <TextField
              fullWidth
              label="Estimation"
              disabled
              value={formik.values.time_estimate}
            />
            <TextField
              fullWidth
              label="Actual"
              value={formik.values.time_act}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue("time_act", e.target.value);
              }}
            />
          </div>
        </div>

        <TextField
          fullWidth
          label="Note"
          value={formik.values.note}
          onChange={(e) => formik.setFieldValue("note", e.target.value)}
          sx={{ marginBottom: 2 }}
        />

        <p className="text-right text-red-500 my-4">{errorMessage}</p>

        <div className="flex justify-end w-1/3 gap-2 float-right">
          <button
            className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
            onClick={() => navigate("/schedule")}
            type="button"
          >
            Cancel
          </button>
          <button
            className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
            disabled={!formik.isValid || isPending}
            type="submit"
          >
            Done
          </button>
        </div>
      </form>
    </>
  );
};

export default JobOperator;
