import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  CircularProgress,
  InputAdornment,
  Pagination,
  PaginationItem,
  TextField,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getMaterialList } from "../../../services/materialApi";
import { format } from "date-fns";

const MaterialList = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [page, setPage] = useState(1);

  const { data, isFetching } = useQuery({
    queryKey: ["material-list", search, filter, page, fetchLimit],
    queryFn: () =>
      getMaterialList({
        search: search,
        filter: filter.join("|"),
        offset: page,
        limit: fetchLimit,
      }),
  });

  useEffect(() => {
    setPage(1);
  }, [fetchLimit, search, filter]);

  return (
    <div>
      <div className="flex mb-4 gap-2">
        <TextField
          fullWidth
          placeholder="Search by nama material, ID, No. PO, UOM, atau kategori"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Autocomplete
          multiple
          options={data && data.supplier ? data.supplier : []}
          disableCloseOnSelect
          limitTags={1}
          onChange={(_, option) => {
            setFilter(option);
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          style={{ width: 500 }}
          renderInput={(params) => (
            <TextField {...params} label="Filter" placeholder="" />
          )}
        />
        <button
          onClick={() => navigate("/material/add-material")}
          className="w-1/4 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
        >
          <p>Add material</p>
          <IoMdAddCircleOutline className="text-[18px]" />
        </button>
      </div>
      <>
        {" "}
        <div className="rounded-md shadow-md">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <th className="p-3 text-left">No</th>
                <th className="p-3 text-left">Kode Material</th>
                <th className="p-3 text-left">Nama Material</th>
                <th className="p-3 text-left">Tanggal Masuk</th>
                <th className="p-3 text-left">Tanggal Kadaluarsa</th>
                <th className="p-3 text-left">Length</th>
                <th className="p-3 text-left">Width</th>
                <th className="p-3 text-left">Lot Number</th>
                <th className="p-3 text-left">Jumlah Order</th>
                <th className="p-3 text-left">Order Masuk</th>
                <th className="p-3 text-left">Stok</th>
                <th className="p-3 text-left">UOM</th>
                <th className="p-3 text-left">Harga Jual</th>
              </thead>
              <tbody>
                {data && data.materials.length > 0 && !isFetching ? (
                  data.materials.map((item, i) => {
                    return (
                      <tr className="border-t border-l border-2" key={i}>
                        <td className="p-3">
                          {(page - 1) * fetchLimit + (i + 1)}
                        </td>
                        <td
                          className="p-3 text-blue-500 cursor-pointer hover:text-slate-500"
                          onClick={() =>
                            navigate(`/material/detail/${item.id}`)
                          }
                        >
                          {item.material_id}
                        </td>
                        <td className="p-3">{item.name}</td>
                        <td className="p-3">
                          {item.incoming_date
                            ? format(item.incoming_date, "dd-MM-yyyy")
                            : ""}
                        </td>
                        <td className="p-3">
                          {item.expired_date
                            ? format(item.expired_date, "dd-MM-yyyy")
                            : ""}
                        </td>
                        <td className="p-3">{item.length}</td>
                        <td className="p-3">{item.width}</td>
                        <td className="p-3">
                          {item.lot_number}
                        </td>
                        <td className="p-3">{item.quantity_order}</td>
                        <td className="p-3">{item.incoming_order}</td>
                        <td className="p-3">{item.stock}</td>
                        <td className="p-3">{item.uom}</td>
                        <td className="p-3">
                          Rp{item.sell_price.toLocaleString().replace(",", ".")}
                        </td>
                      </tr>
                    );
                  })
                ) : !isFetching ? (
                  <tr className="border-t border-l border-2">
                    <td colSpan={13} className="py-3 text-center">
                      Data material tidak ditemukan
                    </td>
                  </tr>
                ) : (
                  <tr className="border-t border-l border-2">
                    <td colSpan={13} className="py-3 text-center">
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {data && data.count > 0 ? (
            <div className="w-full flex justify-between pb-8 px-8">
              <button
                onClick={() => {
                  if (page > 1) setPage(page - 1);
                }}
                disabled={page === 1}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowLeftLong /> Previous
              </button>
              <Pagination
                hideNextButton
                hidePrevButton
                shape="rounded"
                page={page}
                count={Math.ceil(data.count / fetchLimit)}
                onChange={(e, page) => setPage(page)}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
              <button
                onClick={() => {
                  if (page < Math.ceil(data.count / fetchLimit))
                    setPage(page + 1);
                }}
                disabled={page === Math.ceil(data.count / fetchLimit)}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowRightLong /> Next
              </button>
            </div>
          ) : null}
        </div>
        {data && data.count > 0 ? (
          <div className="mt-4 text-xs flex gap-3 items-center">
            <p>Show Table</p>
            <p
              onClick={() => setFetchLimit(10)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 10 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              10
            </p>
            <p
              onClick={() => setFetchLimit(50)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 50 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              50
            </p>
            <p
              onClick={() => setFetchLimit(100)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 100 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              100
            </p>
          </div>
        ) : null}
      </>
    </div>
  );
};

export default MaterialList;
