import { CircularProgress, Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import SingleCalendar from "../../common/SingleCalendar";
import { format } from "date-fns";
import { MdCalendarMonth, MdCancel } from "react-icons/md";
import { useQuery } from "@tanstack/react-query";
import {
  deleteImage,
  getDetailCustomer,
} from "../../../services/customerManagementApi";
import { AiOutlineCloudUpload } from "react-icons/ai";

const isPending = false;

const EditCustomerModalContent = ({ formik, id, setSelectedFileName }) => {
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [paymentDate, setPaymentDate] = useState(null);
  const [errorImg, setErrorImg] = useState("");
  const [isDeleteImg, setIsDeleteImg] = useState(false);
  const [isInitialImage, setIsInitialImage] = useState(true);
  const [changeImage, setChangeImage] = useState(false);

  const uploadMedia = (e) => {
    const files = e.target.files[0];
    if (!files) return;
    formik.setFieldValue("file", files);
    setChangeImage(true);
    setIsInitialImage(false);
  };

  const { data, isFetching } = useQuery({
    queryKey: ["detail-customer", id],
    queryFn: () => getDetailCustomer(id),
  });

  const {
    data: dataDelete,
    isFetching: isPendingDelete,
    failureReason,
  } = useQuery({
    queryKey: ["delete-img", isDeleteImg],
    queryFn: () => deleteImage(data.filename, id),
    enabled: isDeleteImg,
  });

  useEffect(() => {
    if (failureReason) setErrorImg("Gagal menghapus image");
  }, [failureReason]);

  useEffect(() => {
    if (!formik.values.is_pkp) formik.setFieldValue("npwp", "");
  }, [formik.values.is_pkp]);

  useEffect(() => {
    if (isPending || isPendingDelete) setErrorImg("");
  }, [isPending, isPendingDelete]);

  useEffect(() => {
    paymentDate
      ? formik.setFieldValue(
          "date_join",
          format(new Date(paymentDate), "yyyy-MM-dd")
        )
      : formik.setFieldValue("date_join", "");
  }, [paymentDate]);

  useEffect(() => {
    if (changeImage && !isFetching && data) {
      var reader = new FileReader();
      var imgtag = document.getElementById("uploaded-img");

      if (!isInitialImage) {
        reader.onload = function (event) {
          imgtag.src = event.target.result;
        };

        reader.readAsDataURL(formik.values.file);
      } else {
        imgtag.src = `data:image/png;base64,${data.customer_img}`;
      }
      setChangeImage(false);
    }
  }, [changeImage, isFetching]);

  useEffect(() => {
    if (dataDelete) {
      formik.setFieldValue("file", null);
      setIsDeleteImg(false);
    }
  }, [dataDelete]);

  useEffect(() => {
    if (data) {
      formik.setValues({
        file: data.customer_img === "" ? null : data.customer_img,
        json: {
          customer_name: data.customer_name,
          date_join: data.date_join,
          phone: data.phone,
          is_pkp: data.is_pkp,
          npwp: data.npwp,
          top: data.top,
          franco: data.franco,
          bill_to: data.bill_to,
          pic_name: data.pic_name,
          pic_phone: data.phone,
          pic_email: data.pic_email,
          notes: data.notes,
        },
      });
      setPaymentDate(new Date(data.date_join));
      setSelectedFileName(data.filename);
      setChangeImage(data.customer_img !== "");
    }
  }, [data]);

  return (
    <>
      {!isFetching && data ? (
        <>
          <div className="w-full border-2 p-4 rounded-lg text-center">
            <>
              {isPending ? (
                <CircularProgress />
              ) : formik.values.file === "" || formik.values.file === null ? (
                <>
                  <>
                    <label
                      htmlFor="dropzone-file1"
                      className="cursor-pointer flex flex-wrap justify-center my-8 hover:text-slate-400"
                    >
                      <div className="flex items-center justify-center">
                        <AiOutlineCloudUpload className="text-3xl" />
                        <p className="ml-4 font-bold text-lg">Upload File</p>
                      </div>
                      <p className="text-sm rounded-md cursor-pointer w-full mt-2 text-gray-400">
                        Klik untuk mencari file di perangkat anda
                      </p>
                      <input
                        id="dropzone-file1"
                        type="file"
                        accept="image/jpeg, image/jpg, image/png"
                        onChange={(e) => {
                          uploadMedia(e);
                        }}
                        hidden
                      />
                    </label>
                  </>
                </>
              ) : (
                <div className="flex items-center justify-center w-full relative">
                  <img
                    id="uploaded-img"
                    className="max-h-40 object-contain"
                    alt="Img"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      isInitialImage
                        ? setIsDeleteImg(true)
                        : formik.setFieldValue("file", null);
                    }}
                  >
                    <MdCancel className="text-2xl text-red-500 hover:text-red-700 absolute top-0 right-0" />
                  </button>
                </div>
              )}
            </>
          </div>
          <p className="text-red-500 text-xs text-center mb-4">{errorImg}</p>
          <TextField
            label="Nama Customer"
            fullWidth
            sx={{ marginBottom: 2 }}
            value={formik.values.json.customer_name}
            onChange={(e) => {
              if (e.target.value.length <= 100)
                formik.setFieldValue("json.customer_name", e.target.value);
            }}
            // helperText={
            //   formik.touched.json.customer_name && formik.errors.json.customer_name
            // }
            // error={
            //   formik.touched.json.customer_name &&
            //   Boolean(formik.errors.json.customer_name)
            // }
          />
          <p>Tanggal Bergabung</p>
          <div className="mb-4  border-2 rounded-md w-full flex items-center hover:bg-slate-100">
            <div
              className="flex justify-between items-center h-full w-full cursor-pointer p-2"
              onClick={(e) => setAnchorElCalendar(e.currentTarget)}
            >
              {paymentDate === null ? (
                <p className="text-gray-300">----/--/--</p>
              ) : (
                <p className="text-gray-300">
                  {format(new Date(paymentDate), "yyyy-MM-dd")}
                </p>
              )}
              <MdCalendarMonth className="w-6 h-6" />
            </div>
          </div>
          <TextField
            label="No. Telp"
            fullWidth
            sx={{ marginBottom: 2 }}
            value={formik.values.json.phone}
            onChange={(e) => {
              if (e.target.value.length <= 20)
                formik.setFieldValue("json.phone", e.target.value);
            }}
            // helperText={formik.touched.json.phone && formik.errors.json.phone}
            // error={formik.touched.json.phone && Boolean(formik.errors.json.phone)}
          />{" "}
          <div className="w-full mb-4 flex items-center justify-between">
            <p className="basis">Status PKP</p>
            <div className="flex items-center">
              <Switch
                onChange={(e, checked) =>
                  formik.setFieldValue("json.is_pkp", checked)
                }
                checked={formik.values.json.is_pkp}
              />
              <p>{formik.values.json.is_pkp ? "Aktif" : "Tidak Aktif"}</p>
            </div>
          </div>
          {formik.values.json.is_pkp ? (
            <TextField
              label="NPWP"
              fullWidth
              sx={{ marginBottom: 2 }}
              value={formik.values.json.npwp}
              onChange={(e) => {
                if (e.target.value.length <= 30) {
                  formik.setFieldValue("json.npwp", e.target.value);
                }
              }}
              // helperText={formik.touched.npwp && formik.errors.npwp}
              // error={formik.touched.npwp && Boolean(formik.errors.npwp)}
            />
          ) : null}
          <TextField
            label="Terms of Payment"
            fullWidth
            sx={{ marginBottom: 2 }}
            onChange={(e) => {
              if (e.target.value.length <= 100) {
                formik.setFieldValue("json.top", e.target.value);
              }
            }}
            value={formik.values.json.top}
            // helperText={formik.touched.top && formik.errors.top}
            // error={formik.touched.top && Boolean(formik.errors.top)}
          />{" "}
          <TextField
            label="Franco"
            fullWidth
            sx={{ marginBottom: 2 }}
            onChange={(e) => {
              if (e.target.value.length <= 100) {
                formik.setFieldValue("json.franco", e.target.value);
              }
            }}
            value={formik.values.json.franco}
            // helperText={formik.touched.franco && formik.errors.franco}
            // error={formik.touched.franco && Boolean(formik.errors.franco)}
          />{" "}
          <TextField
            label="Bill To"
            fullWidth
            sx={{ marginBottom: 2 }}
            onChange={(e) => {
              if (e.target.value.length <= 100) {
                formik.setFieldValue("json.bill_to", e.target.value);
              }
            }}
            value={formik.values.json.bill_to}
            // helperText={formik.touched.franco && formik.errors.franco}
            // error={formik.touched.franco && Boolean(formik.errors.franco)}
          />{" "}
          <TextField
            label="Nama PIC"
            fullWidth
            sx={{ marginBottom: 2 }}
            value={formik.values.json.pic_name}
            onChange={(e) => {
              if (e.target.value.length <= 100) {
                formik.setFieldValue("json.pic_name", e.target.value);
              }
            }}
            // helperText={formik.touched.pic_name && formik.errors.pic_name}
            // error={formik.touched.pic_name && Boolean(formik.errors.pic_name)}
          />
          <TextField
            label="No. Telpon PIC"
            fullWidth
            sx={{ marginBottom: 2 }}
            value={formik.values.json.pic_phone}
            onChange={(e) => {
              if (e.target.value.length <= 30) {
                formik.setFieldValue("json.pic_phone", e.target.value);
              }
            }}
            // helperText={formik.touched.pic_phone && formik.errors.pic_phone}
            // error={formik.touched.pic_phone && Boolean(formik.errors.pic_phone)}
          />
          <TextField
            label="Email PIC"
            fullWidth
            sx={{ marginBottom: 2 }}
            value={formik.values.json.pic_email}
            onChange={(e) => {
              if (e.target.value.length <= 100) {
                formik.setFieldValue("json.pic_email", e.target.value);
              }
            }}
            // helperText={formik.touched.pic_email && formik.errors.pic_email}
            // error={formik.touched.pic_email && Boolean(formik.errors.pic_email)}
          />
          <TextField
            label="Notes"
            fullWidth
            sx={{ marginBottom: 2 }}
            value={formik.values.json.notes}
            onChange={(e) => {
              if (e.target.value.length <= 300) {
                formik.setFieldValue("json.notes", e.target.value);
              }
            }}
            // helperText={formik.touched.notes && formik.errors.notes}
            // error={formik.touched.notes && Boolean(formik.errors.notes)}
          />
          <SingleCalendar
            anchorElCalendarStart={anchorElCalendar}
            setAnchorElCalendarStart={setAnchorElCalendar}
            startDate={paymentDate}
            setStartDate={setPaymentDate}
          />
        </>
      ) : (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default EditCustomerModalContent;
