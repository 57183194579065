import {
  Breadcrumbs,
  InputAdornment,
  Link,
  TextField,
  CircularProgress,
  Switch,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { useState, useEffect } from "react";
import { MdCalendarMonth } from "react-icons/md";
import SingleCalendar from "../../common/SingleCalendar";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { IoPencilSharp } from "react-icons/io5";
import ModalInput from "../../common/ModalInput";
import {
  createCustomerInvoice,
  getCustomerInvoiceDetail,
  updatePaymentCustomerInvoice,
} from "../../../services/customerInvoiceApi";
import { addDays } from "date-fns";
import { getCustomerList } from "../../../services/customerManagementApi";

const EditCustomerInvoice = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [invoiceDate, setInvoiceDate] = useState(null);
  const [anchorElCalendarDue, setAnchorElCalendarDue] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [labelArr, setLabelArr] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [openPay, setOpenPay] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPay, setIsPay] = useState(false);
  const [faktur, setFaktur] = useState("");
  const [dpp, setDpp] = useState(0);
  const [exchangeDate, setExchangeDate] = useState(null);
  const [ppn, setPpn] = useState(false);
  const [top, setTop] = useState(0);
  const [freight, setFreight] = useState(0);
  const [discount, setDiscount] = useState(0);

  const { data, isFetching } = useQuery({
    queryKey: ["po-list", id],
    queryFn: () => getCustomerInvoiceDetail(id),
  });

  const { mutate: mutateUpdatePaymentInvoice } = useMutation({
    mutationFn: updatePaymentCustomerInvoice,
    onSuccess: () => {
      navigate("/customer-invoice");
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
      setIsPay(false);
    },
  });

  const { mutate: mutateUpdateInvoice } = useMutation({
    mutationFn: createCustomerInvoice,
    onSuccess: () => {
      navigate("/customer-invoice");
    },
    onError: (err) => setErrorMessage(err.message.id),
  });

  const { mutate: mutateCustomerList, data: dataCustomer } = useMutation({
    mutationFn: getCustomerList,
  });

  const handleSubmit = () => {
    const body = {
      id: Number(id),
      invoice_date: format(new Date(invoiceDate), "yyyy-MM-dd"),
      invoice_number: data.invoice_number,
      total_price: data.total_price,
      grand_total:
        (data.total_price - discount) * (data.ppn ? 1.11 : 1) + freight,
      freight_charge: freight,
      top: top,
      faktur: faktur,
      isPPN: data.ppn,
      discount: discount,
    };
    mutateUpdateInvoice(body);
  };

  useEffect(() => {
    if (isPay) {
      const body = {
        id: Number(id),
        status: 1,
      };
      mutateUpdatePaymentInvoice(body);
    }
  }, [isPay]);

  useEffect(() => {
    if (data) {
      setInvoiceDate(parseISO(data.invoice_date));
      setFaktur(data.faktur);
      setDpp(data.dpp);
      setTop(data.top);
      setFreight(data.freight_charge);
      setDiscount(data.discount);
      setPpn(data.isPPN);
      setExchangeDate(
        data.exchange_date !== ""
          ? format(new Date(data.exchange_date), "yyyy-MM-dd")
          : null
      );

      mutateCustomerList({
        name: data.customer_name,
        start_date: "",
        end_date: "",
        pkp: 0,
        offset: 1,
        limit: 1,
      });
    }
  }, [data]);

  return (
    <div className="mt-12 lg:mt-2">
      <div className="flex justify-between items-center mb-8">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/sv-invoice">
            Customer Invoice
          </Link>
          <Link underline="hover" color="inherit">
            Detail Customer Invoice
          </Link>
        </Breadcrumbs>
        <div className="w-1/3 float-right justify-end flex items-center gap-2">
          {isEdit ? (
            <button
              onClick={() => setIsEdit(false)}
              className="w-fit text-red-500 px-12 flex items-center justify-center gap-2 border-red-500 border-2 p-2 rounded-md hover:bg-red-100 cursor-pointer"
            >
              <IoPencilSharp />
              <p>Cancel Edit</p>
            </button>
          ) : (
            <>
              <button
                onClick={() => setIsEdit(true)}
                className="w-fit px-12 flex items-center justify-center gap-2 border-[#18479D] border-2 p-2 rounded-md text-[#18479D] hover:bg-slate-100 cursor-pointer"
              >
                <IoPencilSharp />
                <p>Edit</p>
              </button>

              <button
                onClick={() => setOpenPay(true)}
                disabled={data ? data.status === 1 : true}
                className="w-fit disabled:bg-gray-400 disabled:cursor-default px-12 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white hover:bg-[#163e87] cursor-pointer"
              >
                <p>Update</p>
              </button>
            </>
          )}
        </div>
      </div>

      {data && !isFetching ? (
        <>
          <p>Tanggal Invoice</p>
          <div className="mb-4 border-2 rounded-md w-full flex items-center bg-slate-100">
            <div className="flex justify-between items-center h-full w-full  p-2">
              {invoiceDate === null ? (
                <p>----/--/--</p>
              ) : (
                <p>{format(new Date(invoiceDate), "yyyy-MM-dd")}</p>
              )}
              <MdCalendarMonth className="w-6 h-6" />
            </div>
          </div>
          <TextField
            fullWidth
            disabled
            value={data.invoice_number}
            sx={{ marginBottom: 2 }}
            label="No. Invoice Customer"
          />
          <TextField
            fullWidth
            disabled
            value={data.po_number}
            sx={{ marginBottom: 2 }}
            label="No.DO"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: 2 }}
            value={data.customer_name}
            label="Nama Customer"
            disabled
          />
          <TextField
            fullWidth
            sx={{ marginBottom: 2 }}
            value={data.po_number ? data.po_number : ""}
            label="No. PO Customer"
            disabled
          />
          <TextField
            fullWidth
            sx={{ marginBottom: 2 }}
            value={faktur}
            disabled={!isEdit}
            onChange={(e) => {
              setFaktur(e.target.value);
            }}
            label="No. Faktur Pajak"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: 2 }}
            value={top}
            disabled={!isEdit}
            onChange={(e) => {
              if (!isNaN(Number(e.target.value))) {
                setTop(Number(e.target.value));
              }
            }}
            label="TOP"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">DAYS</InputAdornment>
              ),
            }}
          />
          <div className="mb-4 flex justify-between">
            <p>Customer PPN</p>
            <Switch
              disabled
              checked={dataCustomer ? dataCustomer.data[0].is_pkp : false}
            />
          </div>

          <div className="mt-2">
            <p className="text-xl font-bold">Added Label Name</p>
            <hr className="my-2" />
            <div className="rounded-md shadow-md">
              <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
                <table className="w-full">
                  <thead className="bg-[#F4F5FF] border-t border-b border-2">
                    <th className="p-3 text-left">No</th>
                    <th className="p-3 text-left">Label Name</th>
                    <th className="p-3 text-left">Qty</th>
                    <th className="p-3 text-left">Price</th>
                    <th className="p-3 text-left">Subtotal</th>
                  </thead>
                  <tbody>
                    {data && data.data_label?.length > 0 ? (
                      data.data_label.map((item, i) => (
                        <tr className="border-t-2 border-b-2" key={i}>
                          <td className="p-3 text-left">{i + 1}</td>
                          <td className="p-3 text-left">{item.name_label}</td>
                          <td className="p-3 text-left">
                            {item.quantity_delivery}
                          </td>
                          <td className="p-3 text-left">
                            Rp
                            {item.price ? item.price.toLocaleString() : 0}
                          </td>
                          <td className="p-3 text-left">
                            Rp
                            {item.price
                              ? (
                                  item.price * item.quantity_delivery
                                ).toLocaleString()
                              : 0}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="border-t-2 border-b-2">
                        <td
                          className="p-3 text-center text-gray-500"
                          colSpan={4}
                        >
                          Belum ada label
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-wrap basis-1/3 justify-end">
            {data && data.data_label?.length > 0 ? (
              <>
                <div className="font-bold float-right">
                  <div className="flex mb-4">
                    <p className="w-1/3">Total</p>
                    <p className="w-fit mr-2">:</p>
                    <p className="w-64">
                      Rp
                      {data.total_price.toLocaleString()}
                    </p>
                  </div>
                  <div className="flex mb-4 items-center">
                    <p className="w-1/3">Discount</p>
                    <p className="w-fit mr-2">:</p>
                    <TextField
                      className="w-64"
                      value={discount}
                      disabled={!isEdit}
                      onChange={(e) => {
                        if (!isNaN(Number(e.target.value.replace(/,/g, "")))) {
                          const numberValue = Number(e.target.value);
                          setDiscount(numberValue);
                        }
                      }}
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              fontWeight: "bold",
                              marginRight: 0,
                              fontFamily: "Montserrat",
                            }}
                          >
                            <p className="font-bold text-black">Rp</p>
                          </InputAdornment>
                        ),
                        disableUnderline: true,
                        style: {
                          fontWeight: "bold",
                          textDecoration: "underline",
                          fontFamily: "Montserrat",
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          border: "none",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                      }}
                    />
                  </div>
                  <div className="flex mb-4">
                    <p className="w-1/3">DPP</p>
                    <p className="w-fit mr-2">:</p>
                    <p className="w-64">
                      Rp{(data.total_price - discount).toLocaleString()}
                    </p>
                  </div>
                  {data.ppn ? (
                    <div className="flex mb-4">
                      <p className="w-1/3">PPN</p>
                      <p className="w-fit mr-2">:</p>
                      <p className="w-64">
                        Rp
                        {(data.total_price > 0
                          ? (data.total_price - discount) * 0.11
                          : 0
                        ).toLocaleString()}
                      </p>
                    </div>
                  ) : null}
                  <div className="flex mb-4 items-center">
                    <p className="w-1/3">Freight Charge</p>
                    <p className="w-fit mr-2">:</p>
                    <TextField
                      className="w-64"
                      value={freight}
                      disabled={!isEdit}
                      onChange={(e) => {
                        if (!isNaN(Number(e.target.value.replace(/,/g, "")))) {
                          const numberValue = Number(e.target.value);
                          setFreight(numberValue);
                        }
                      }}
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              fontWeight: "bold",
                              marginRight: 0,
                              fontFamily: "Montserrat",
                            }}
                          >
                            <p className="font-bold text-black">Rp</p>
                          </InputAdornment>
                        ),
                        disableUnderline: true,
                        style: {
                          fontWeight: "bold",
                          textDecoration: "underline",
                          fontFamily: "Montserrat",
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          border: "none",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none",
                        },
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                      }}
                    />
                  </div>
                  <div className="flex mb-4">
                    <p className="w-1/3">Grand Total</p>
                    <p className="w-fit mr-2">:</p>
                    <p className="w-64">
                      Rp
                      {(
                        (data.total_price - discount) * (data.ppn ? 1.11 : 1) +
                        freight
                      ).toLocaleString()}
                    </p>
                  </div>
                </div>
              </>
            ) : null}
          </div>

          {labelArr.length > 0 ? (
            <>
              <div className="w-1/3 font-bold float-right">
                <div className="flex mb-4">
                  <p className="w-1/3">Total Harga</p>
                  <p className="w-fit mr-2">:</p>
                  <p>Rpxx.xxx</p>
                </div>
                <div className="flex mb-4 items-center">
                  <p className="w-1/3">Freight Charge</p>
                  <p className="w-fit mr-2">:</p>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Rp</InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="flex mb-4">
                  <p className="w-1/3">Grand Total</p>
                  <p className="w-fit mr-2">:</p>
                  <p>Rpxx.xxx</p>
                </div>
              </div>
            </>
          ) : null}
        </>
      ) : isFetching ? (
        <CircularProgress size={20} />
      ) : null}

      {isEdit ? (
        <>
          {" "}
          <p className="text-red-500 mb-4 text-right m-4">{errorMessage}</p>
          <div className="flex justify-end w-1/3 gap-2 float-right">
            <button
              type="button"
              className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
              onClick={() => navigate("/customer-invoice")}
            >
              Cancel
            </button>
            <button
              className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
              //   disabled={!formik.isValid || isPending || countGrandTotal() < 0}
              onClick={() => setOpenSave(true)}
            >
              Save
            </button>
          </div>
        </>
      ) : null}

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendarDue}
        setAnchorElCalendarStart={setAnchorElCalendarDue}
        startDate={dueDate}
        setStartDate={setDueDate}
      />

      <ModalInput
        title="Bayar"
        subtitle={`Apakah anda yakin invoice sudah dibayar?`}
        content={<>{/* <p className="text-red-500">{errorMessage}</p> */}</>}
        open={openPay}
        setOpen={setOpenPay}
        hasButton={true}
        buttonText="Yes"
        setTriggerFunc={setIsPay}
        isDisable={false}
      />

      <ModalInput
        title="Simpan Perubahan"
        subtitle={`Apakah anda yakin mengubah invoice ini?`}
        content={<>{/* <p className="text-red-500">{errorMessage}</p> */}</>}
        open={openSave}
        setOpen={setOpenSave}
        hasButton={true}
        buttonText="Yes"
        setTriggerFunc={() => {
          handleSubmit();
        }}
        isDisable={false}
      />
    </div>
  );
};

export default EditCustomerInvoice;
