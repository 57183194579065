import { Breadcrumbs, Link, TextField } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { IoMdAddCircleOutline } from "react-icons/io";
import { FiMinusCircle } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { getMaterialDetail } from "../../../services/materialApi";
import UpdateStockMaterial from "./UpdateStockMaterial";
import { format } from "date-fns";
import ReductionStockMaterial from "./ReductionStockMaterial";

const DetailMaterial = () => {
  const { id } = useParams();

  const [errorMessage, setErrorMessage] = useState("");
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openReduction, setOpenReduction] = useState(false);
  const [refetchList, setRefetchList] = useState(false);

  const { data, failureReason } = useQuery({
    queryKey: ["detail-material", refetchList],
    queryFn: () => getMaterialDetail(id),
  });

  useEffect(() => {
    if (failureReason) setErrorMessage("Gagal mengambil detail material");
  }, [failureReason]);

  useEffect(() => {
    setRefetchList(false);
  }, [data]);

  return (
    <div className="mt-12 lg:mt-2">
      <div className="flex justify-between items-center mb-8">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/material">
            Material
          </Link>
          <Link underline="hover" color="inherit">
            Detail Material
          </Link>
        </Breadcrumbs>
        <div className="flex gap-2">
          <button
            onClick={() => setOpenReduction(true)}
            className="w-fit px-8 flex items-center justify-center gap-2 bg-[#992738] p-2 rounded-md text-white text-xs hover:bg-[#a03c4b] cursor-pointer"
          >
            <FiMinusCircle className="text-[18px]" />
            <p>Reduce Material</p>
          </button>
          <button
            onClick={() => setOpenUpdate(true)}
            className="w-fit px-8 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
          >
            <IoMdAddCircleOutline className="text-[18px]" />
            <p>Update Stok</p>
          </button>
        </div>
      </div>

      {data && data.detail ? (
        <>
          <div className="flex gap-4 items-center mb-4">
            <TextField
              disabled
              variant="standard"
              fullWidth
              label="Kode Material"
              value={data.detail.material_id}
            />
            <TextField
              disabled
              variant="standard"
              fullWidth
              label="Nama Material"
              value={data.detail.name}
            />
          </div>
          <div className="flex gap-4 items-center mb-4">
            <TextField
              disabled
              variant="standard"
              fullWidth
              label="Tanggal Masuk"
              value={format(data.detail.incoming_date, "yyyy-MM-dd")}
            />
            <TextField
              disabled
              variant="standard"
              fullWidth
              label="Length"
              value={data.detail.length}
            />
          </div>
          <div className="flex gap-4 items-center mb-4">
            <TextField
              disabled
              variant="standard"
              fullWidth
              label="Jumlah Order"
              value={data.detail.quantity_order}
            />
            <TextField
              disabled
              variant="standard"
              fullWidth
              label="Width"
              value={data.detail.width}
            />
          </div>
          <div className="flex gap-4 items-center mb-4">
            <TextField
              disabled
              variant="standard"
              fullWidth
              label="Nama Supplier/Vendor"
              value={data.detail.supplier_name}
            />
            <TextField
              disabled
              variant="standard"
              fullWidth
              label="Lot Number"
              value={data.detail.lot_number}
            />
          </div>
          <div className="flex gap-4 items-center mb-4">
            <TextField
              disabled
              variant="standard"
              fullWidth
              label="Kategori"
              value={data.detail.category_name}
            />
            <TextField
              disabled
              variant="standard"
              fullWidth
              label="UOM"
              value={data.detail.uom}
            />
          </div>
          <div className="flex gap-4 items-center mb-4">
            <TextField
              disabled
              variant="standard"
              fullWidth
              label="Stok"
              value={data.detail.stock}
            />
            <TextField
              disabled
              variant="standard"
              fullWidth
              label="Harga Jual"
              value={data.detail.sell_price.toLocaleString().replace(",", ".")}
            />
          </div>
          <div className="flex gap-4 items-center mb-4">
            <TextField
              disabled
              variant="standard"
              fullWidth
              label="Stok Masuk"
              value={data.detail.incoming_order}
            />
            <TextField
              disabled
              variant="standard"
              fullWidth
              label="Tanggal Kadaluarsa"
              value={
                data.detail.expired_date
                  ? format(new Date(data.detail.expired_date), "dd-MM-yyyy")
                  : ""
              }
            />
          </div>
        </>
      ) : (
        <p className="text-red-500">{errorMessage}</p>
      )}

      <p className="mt-12 font-bold text-xl">Stock Movement</p>
      <hr className="my-2" />

      <div className="rounded-md">
        <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8">
          <table className="w-full">
            <thead className="bg-[#F4F5FF] border-t border-b border-l-0 border-r-0 border-2">
              <th className="p-3 text-left">No</th>
              <th className="p-3 text-left">Tanggal Masuk</th>
              <th className="p-3 text-left">Stok Awal</th>
              <th className="p-3 text-left">Masuk</th>
              <th className="p-3 text-left">No PO</th>
              <th className="p-3 text-left">Supplier/Vendor</th>
              <th className="p-3 text-left">Keluar</th>
              <th className="p-3 text-left">No SPK</th>
              <th className="p-3 text-left">No DO</th>
              <th className="p-3 text-left">Stok Akhir</th>
            </thead>
            <tbody>
              {data && data.history ? (
                data.history.map((item, i) => (
                  <tr
                    className="border-t border-l border-r-0 border-2"
                    key={i}
                  >
                    <td className="p-3">{i + 1}</td>
                    <td className="p-3 text-nowrap">
                      {item.history_date !== ""
                        ? format(item.history_date, "yyyy-MM-dd") // to change upon confirmation
                        : "-"}
                    </td>
                    <td className="p-3">{item.current_stock}</td>
                    <td className="p-3">{item.incoming_stock}</td>
                    <td className="p-3">
                      {item.po_number === "" ? "-" : item.po_number}
                    </td>
                    <td className="p-3">
                      {item.supplier_name === "" ? "-" : item.supplier_name}
                    </td>
                    <td className="p-3">{item.outgoing_stock}</td>
                    <td className="p-3">
                      {item.spk_number === "" ? "-" : item.spk_number}
                    </td>
                    <td className="p-3">
                      {item.do_number === "" ? "-" : item.do_number}
                    </td>
                    <td className="p-3">{item.final_stock}</td>
                  </tr>
                ))
              ) : (
                <tr className="border-t border-l border-r-0 border-2">
                  <td className="p-3 text-center" colSpan={8}>
                    Movement stock tidak ditemukan
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <UpdateStockMaterial
        id={id}
        openUpdate={openUpdate}
        setOpenUpdate={setOpenUpdate}
        setRefetchList={setRefetchList}
      />

      <ReductionStockMaterial
        id={Number(id)}
        openUpdate={openReduction}
        setOpenUpdate={setOpenReduction}
        setRefetchList={setRefetchList}
        dataDetail={data}
      />
    </div>
  );
};

export default DetailMaterial;
