import React from "react";
import Logo from "../../assets/logo.png";
// import { Breadcrumbs } from "@mui/material";

const Header = () => {
  return (
    <>
      <nav className="bg-white p-2  block lg:hidden fixed z-20 w-full top-0 left-0 border-b-2 border-gray-200 ">
        <div className="container flex flex-wrap items-center justify-between mx-auto">
          <img src={Logo} className="h-6 mr-3 sm:h-9" alt="Indolabel Logo" />
        </div>
      </nav>
    </>
  );
};

export default Header;
