import {
  FormControl,
  InputAdornment,
  Select,
  TextField,
  MenuItem,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { MdCalendarMonth } from "react-icons/md";
import SingleCalendar from "../../common/SingleCalendar";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getSupplierList } from "../../../services/supplierApi";
import {
  getMaterialCategoryList,
  getMaterialHeader,
  setMaterial,
  setMaterialCategory,
} from "../../../services/materialApi";
import ModalInput from "../../common/ModalInput";
import DeleteMaterialCategoryModal from "./DeleteMaterialCategoryModal";

const validationSchema = yup.object({
  material_id: yup.string().required(),
  name: yup.string().required(),
  length: yup.number().min(1).required(),
  width: yup.number().min(1).required(),
  lot_number: yup.string().required(),
  po_number: yup.string(),
  expired_date: yup.string().required(),
  incoming_date: yup.string().required(),
  supplier_name: yup.string().required(),
  category_name: yup.string().required(),
  uom: yup.string().required(),
  sell_price: yup.number().min(1).required(),
  quantity_order: yup.number().min(1).required(),
  incoming_order: yup.number().min(1).required(),
});

const AddMaterial = () => {
  const navigate = useNavigate();
  const defaultFilterOptions = createFilterOptions();

  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [anchorElCalendarIncoming, setAnchorElCalendarIncoming] =
    useState(null);
  const [expiredDate, setExpiredDate] = useState(null);
  const [incomingDate, setIncomingDate] = useState(null);
  const [errorSupplierMessage, setErrorSupplierMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openAddCat, setOpenAddCat] = useState(false);
  const [openDeleteCat, setOpenDeleteCat] = useState(false);
  const [errorMessageCreateCat, setErrorMessageCreateCat] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [newCat, setNewCat] = useState("");
  const [refetchList, setRefetchList] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState(null);

  const getMaterialDetail = (id) => {
    const foundMaterial = dataList.find((elem) => elem.material_id === id);
    setSelectedMaterial(id ? foundMaterial : null);
    if (id) {
      formik.setFieldValue("name", foundMaterial.name);
      formik.setFieldValue("length", foundMaterial.length);
      formik.setFieldValue("width", foundMaterial.width);
      formik.setFieldValue("lot_number", foundMaterial.lot_number);
      formik.setFieldValue("uom", foundMaterial.uom);
      formik.setFieldValue("category_name", foundMaterial.category_name);
    }
    if (!id) formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      material_id: "",
      name: "",
      length: 0,
      width: 0,
      lot_number: "",
      po_number: "",
      expired_date: "",
      incoming_date: "2024-01-30",
      supplier_name: "",
      category_name: "",
      uom: "",
      sell_price: 0,
      quantity_order: 0,
      incoming_order: 0,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      mutateNewMaterial(values);
    },
  });

  const {
    mutate: mutateSupplierList,
    data: dataSupplier,
    isPending: isPendingList,
  } = useMutation({
    mutationFn: getSupplierList,
    onSuccess: () => {},
    onError: (err) => {
      setErrorSupplierMessage(err.message.id);
    },
  });

  const { mutate: mutateNewMaterial, isPending: isPendingCreate } = useMutation(
    {
      mutationFn: setMaterial,
      onSuccess: () => {
        navigate("/material");
      },
      onError: (err) => {
        setErrorMessage(err.message.id);
      },
    }
  );

  const { data: dataList } = useQuery({
    queryKey: ["material-list"],
    queryFn: () => getMaterialHeader(),
  });

  const { data: dataCategory } = useQuery({
    queryKey: ["category", isAdd, refetchList],
    queryFn: () => getMaterialCategoryList(),
  });

  const { mutate: mutateNewCategory, isPending: isPendingCreateCat } =
    useMutation({
      mutationFn: setMaterialCategory,
      onSuccess: () => {
        setOpenAddCat(false);
        setIsAdd(false);
        setNewCat("");
      },
      onError: (err) => {
        setErrorMessage(err.message.id);
        setIsAdd(false);
      },
    });

  useEffect(() => {
    if (isAdd) {
      mutateNewCategory(newCat);
    }
    if (!openAddCat) {
      setNewCat("");
    }
  }, [isAdd, openAddCat]);

  useEffect(() => {
    setRefetchList(false);
  }, [dataCategory]);

  useEffect(() => {
    if (anchorElCalendar && !expiredDate) setExpiredDate(new Date());
  }, [anchorElCalendar]);

  useEffect(() => {
    expiredDate
      ? formik.setFieldValue(
          "expired_date",
          format(new Date(expiredDate), "yyyy-MM-dd")
        )
      : formik.setFieldValue("expired_date", "");
  }, [expiredDate]);

  useEffect(() => {
    incomingDate
      ? formik.setFieldValue(
          "incoming_date",
          format(new Date(incomingDate), "yyyy-MM-dd")
        )
      : formik.setFieldValue("incoming_date", "");
  }, [incomingDate]);

  useEffect(() => {
    setErrorMessage("");
  }, [formik.values]);

  useEffect(() => {
    if (formik.values.category_name === "Tambah Kategori") {
      formik.setFieldValue("category_name", 0);
      setOpenAddCat(true);
    }
  }, [formik.values.category_name]);

  useEffect(() => {
    const body = {
      name: "",
      start_date: "",
      end_date: "",
      pkp: 0,
      offset: 1,
      limit: 10000,
    };
    mutateSupplierList(body);
  }, []);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Autocomplete
          sx={{ width: "100%", marginBottom: 2 }}
          disablePortal
          freeSolo
          value={formik.values.material_id}
          onChange={(_, option) => {
            getMaterialDetail(option);
            formik.setFieldValue("material_id", option ? option : "");
          }}
          options={
            dataList && dataList
              ? [...dataList.map((item) => item.material_id)]
              : []
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Kode Material"
              onChange={(e) => {
                formik.setFieldValue("material_id", e.target.value);
                setSelectedMaterial(null);
              }}
            />
          )}
        />

        <TextField
          disabled={selectedMaterial !== null}
          fullWidth
          label="Nama Material"
          sx={{ marginBottom: 2 }}
          value={formik.values.name}
          onChange={(e) => {
            if (e.target.value.length <= 100)
              formik.setFieldValue("name", e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {formik.values.name.length}/100
              </InputAdornment>
            ),
          }}
        />
        <p>Tanggal Masuk</p>
        <div className="mb-4 w-full border-2 rounded-md flex items-center hover:bg-slate-100">
          <div
            className="flex justify-between items-center h-full w-full cursor-pointer p-4"
            onClick={(e) => setAnchorElCalendarIncoming(e.currentTarget)}
          >
            {incomingDate === null ? (
              <p>----/--/--</p>
            ) : (
              <p>{format(new Date(incomingDate), "yyyy-MM-dd")}</p>
            )}
            <MdCalendarMonth className="w-6 h-6" />
          </div>
        </div>
        <TextField
          fullWidth
          label="No. PO"
          sx={{ marginBottom: 2 }}
          value={formik.values.po_number}
          onChange={(e) => {
            if (e.target.value.length <= 100)
              formik.setFieldValue("po_number", e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {formik.values.po_number.length}/100
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          disabled={selectedMaterial !== null}
          label="Length"
          sx={{ marginBottom: 2 }}
          value={formik.values.length}
          onChange={(e) => {
            if (e.target.value.length <= 50 && !isNaN(Number(e.target.value)))
              formik.setFieldValue("length", Number(e.target.value));
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {formik.values.length.toString().length}/50
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          label="Width"
          disabled={selectedMaterial !== null}
          sx={{ marginBottom: 2 }}
          value={formik.values.width}
          onChange={(e) => {
            if (e.target.value.length <= 50 && !isNaN(Number(e.target.value)))
              formik.setFieldValue("width", Number(e.target.value));
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {formik.values.width.toString().length}/50
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          label="Lot Number"
          sx={{ marginBottom: 2 }}
          value={formik.values.lot_number}
          onChange={(e) => formik.setFieldValue("lot_number", e.target.value)}
        />

        <TextField
          fullWidth
          label="Jumlah Order"
          sx={{ marginBottom: 2 }}
          value={formik.values.quantity_order}
          onChange={(e) => {
            if (e.target.value.length <= 50 && !isNaN(Number(e.target.value)))
              formik.setFieldValue("quantity_order", Number(e.target.value));
          }}
        />
        <TextField
          fullWidth
          label="Jumlah Masuk"
          sx={{ marginBottom: 2 }}
          value={formik.values.incoming_order}
          onChange={(e) => {
            if (e.target.value.length <= 50 && !isNaN(Number(e.target.value)))
              formik.setFieldValue("incoming_order", Number(e.target.value));
          }}
        />
        <p>UOM</p>
        <FormControl sx={{ width: "100%", marginBottom: 2 }}>
          <Select
            disabled={selectedMaterial !== null}
            value={formik.values.uom}
            onChange={(e) => formik.setFieldValue("uom", e.target.value)}
          >
            <MenuItem value={""} disabled>
              Pilih UOM
            </MenuItem>
            <MenuItem value={"mm"}>mm</MenuItem>
            <MenuItem value={"mm2"}>mm2</MenuItem>
            <MenuItem value={"cm"}>cm</MenuItem>
            <MenuItem value={"cm2"}>cm2</MenuItem>
            <MenuItem value={"m"}>m</MenuItem>
            <MenuItem value={"m2"}>m2</MenuItem>
            <MenuItem value={"roll"}>Roll</MenuItem>
            <MenuItem value={"box"}>Box</MenuItem>
            <MenuItem value={"each"}>Each</MenuItem>
            <MenuItem value={"pcs"}>Pcs</MenuItem>
            <MenuItem value={"kg"}>kg</MenuItem>
            <MenuItem value={"pack"}>Pack</MenuItem>
          </Select>
        </FormControl>
        <p>Tanggal Kadaluarsa</p>
        <div className="mb-4 border-2 rounded-md w-full flex items-center hover:bg-slate-100">
          <div
            className="flex justify-between items-center h-full w-full cursor-pointer p-4"
            onClick={(e) => setAnchorElCalendar(e.currentTarget)}
          >
            {expiredDate === null ? (
              <p>----/--/--</p>
            ) : (
              <p>{format(new Date(expiredDate), "yyyy-MM-dd")}</p>
            )}
            <MdCalendarMonth className="w-6 h-6" />
          </div>
        </div>
        <Autocomplete
          disabled={selectedMaterial !== null}
          sx={{ width: "100%" }}
          disablePortal
          value={
            dataCategory &&
            dataCategory.material_categories &&
            dataCategory.material_categories.find(
              (item) => item.name === formik.values.category_name
            )
              ? dataCategory.material_categories.find(
                  (item) => item.name === formik.values.category_name
                )
              : null
          }
          onChange={(_, option) => {
            if (option) formik.setFieldValue("category_name", option.name);
          }}
          options={
            dataCategory && dataCategory.material_categories
              ? [
                  ...dataCategory.material_categories,
                  { id: -1, name: "Tambah Kategori" },
                ]
              : []
          }
          getOptionLabel={(option) =>
            option.name && typeof option.name === "string" ? option.name : ""
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Kategori"
              onChange={(e) => setNewCat(e.target.value)}
            />
          )}
          filterOptions={(options, state) => {
            const results = defaultFilterOptions(options, state);
            if (results.length === 0) {
              return [{ id: -1, name: "Tambah Kategori" }];
            }

            return results;
          }}
        />
        <p
          className="text-right w-full text-xs underline text-blue-500 cursor-pointer mb-4"
          onClick={() => setOpenDeleteCat(true)}
        >
          Pengaturan Kategori
        </p>
        <p>Nama Supplier/vendor</p>
        <FormControl sx={{ width: "100%", marginBottom: 2 }}>
          <Select
            value={formik.values.supplier_name}
            onChange={(e) =>
              formik.setFieldValue("supplier_name", e.target.value)
            }
          >
            <MenuItem value={0} disabled>
              Pilih Supplier
            </MenuItem>
            {dataSupplier &&
            dataSupplier.data &&
            dataSupplier.data.length > 0 &&
            !isPendingList ? (
              dataSupplier.data.map((item) => (
                <MenuItem value={item.supplier_name}>
                  {item.supplier_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled value="Tidak ada data">
                {errorSupplierMessage}
              </MenuItem>
            )}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Harga Beli"
          sx={{ marginBottom: 2 }}
          value={formik.values.sell_price}
          onChange={(e) => {
            if (!isNaN(Number(e.target.value)))
              formik.setFieldValue("sell_price", Number(e.target.value));
          }}
        />
        <p className="text-red-500 mb-4 text-right">{errorMessage}</p>
        <div className="flex justify-end w-1/3 gap-2 float-right">
          <button
            className="w-full border border-gray-500 text-black hover:bg-slate-100 cursor-pointer p-2 rounded-md"
            onClick={() => navigate("/material")}
          >
            Cancel
          </button>
          <button
            className="w-full bg-[#18479D] text-white hover:bg-[#163e87] cursor-pointer p-2 rounded-md disabled:bg-gray-200"
            disabled={isPendingCreate || !formik.isValid}
            type="submit"
          >
            Save
          </button>
        </div>
      </form>

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendar}
        setAnchorElCalendarStart={setAnchorElCalendar}
        startDate={expiredDate}
        setStartDate={setExpiredDate}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendarIncoming}
        setAnchorElCalendarStart={setAnchorElCalendarIncoming}
        startDate={incomingDate}
        setStartDate={setIncomingDate}
      />

      <ModalInput
        title="Tambah Categori"
        subtitle={errorMessageCreateCat}
        content={
          <>
            <TextField
              fullWidth
              label="Nama Categori Baru"
              value={newCat}
              onChange={(e) => setNewCat(e.target.value)}
            />
          </>
        }
        open={openAddCat}
        setOpen={setOpenAddCat}
        hasButton={true}
        buttonText="Save"
        setTriggerFunc={setIsAdd}
        isDisable={isPendingCreateCat || newCat === ""}
      />

      {dataCategory ? (
        <DeleteMaterialCategoryModal
          dataCategory={dataCategory}
          openDeleteCat={openDeleteCat}
          setOpenDeleteCat={setOpenDeleteCat}
          setRefetchList={setRefetchList}
        />
      ) : null}
    </div>
  );
};

export default AddMaterial;
