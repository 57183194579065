import { Search } from "@mui/icons-material";
import {
  InputAdornment,
  PaginationItem,
  Popover,
  TextField,
  Pagination,
  CircularProgress,
  FormControl,
  Autocomplete,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import { IoMdAddCircleOutline, IoMdDownload } from "react-icons/io";
import { IoChevronForward, IoFilter } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import RangeCalendar from "../../common/RangeCalendar";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getDoExport,
  getDoList,
  getShipmentMethod,
  setNewStatusDo,
} from "../../../services/deliveryOrder";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { format } from "date-fns";
import PrintDo from "./PrintDo";
import * as XLSX from "xlsx";
import moment from "moment";
import ModalInput from "../../common/ModalInput";

const DeliveryOrderList = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [searchStartDate, setSearchStartDate] = useState(null);
  const [searchEndDate, setSearchEndDate] = useState(null);
  const [selectedDates, setSelectedDates] = useState(["", ""]);
  const [doDates, setDoDates] = useState(["", ""]);
  const [deliveryDates, setDeliveryDates] = useState(["", ""]);
  const [isDo, setIsDo] = useState(false);
  const [delivery, setDelivery] = useState(null);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [selectedDo, setSelectedDo] = useState(null);
  const [isDownload, setIsDownload] = useState(false);
  const [type, setType] = useState(0);
  const [newStatus, setNewStatus] = useState(0);
  const [openChange, setOpenChange] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [isPressedExport, setIsPressedExport] = useState(false);
  const [status, setStatus] = useState(4);

  const open = Boolean(anchorEl);

  const handleChangeStatus = (val, statusType, id) => {
    setNewStatus(val);
    setType(statusType);
    setSelectedId(id);
    setOpenChange(true);
  };

  const handleSelectDoDate = (e) => {
    setSearchStartDate(null);
    setSearchEndDate(null);
    setIsDo(true);
    setAnchorElCalendar(e.currentTarget);
  };

  const handleSelectDeliveryDate = (e) => {
    setSearchStartDate(null);
    setSearchEndDate(null);
    setIsDo(false);
    setAnchorElCalendar(e.currentTarget);
  };

  const exportXlsx = () => {
    const rows = dataExport.data.map((row, i) => ({
      no: i + 1,
      do_number: row.do_number,
      do_date: row.do_date,
      // add no po
      // add internal code
      spk_no: row.spk_no,
      customer_name: row.customer_name,
      label: row.label
        ? [...row.label.map((labelItem) => labelItem.label_name)].join(",")
        : "",
      qty_plan: row.label
        ? row.label.reduce((acc, num) => acc + num.qty_plan, 0)
        : 0,
      qty_actual: row.label
        ? row.label.reduce((acc, num) => acc + num.qty_actual, 0)
        : 0,
      qty_delivery: row.label
        ? row.label.reduce((acc, num) => acc + num.qty_delivery, 0)
        : 0,
      shipment_method: dataShipmentMethod.find(
        (elem) => elem.id === row.shipment
      ).shipment_name,
      carrier_name: row.carrier_name,
      //add delivery status
      //add info status
    }));

    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "Daftar DO " + moment().format("DD-MM-YYYY")
    );

    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          "No",
          "No. DO",
          "Tanggal",
          //add no po
          //add internal code
          "No. SPK",
          "Customer",
          "Label",
          "Qty Plan",
          "Qty Actual",
          "Qty Delivery",
          "Shipment",
          "Petugas Pengiriman",
          //add delivery status
          // addinformation status
        ],
      ],
      {
        origin: "A1",
      }
    );

    const max_width = rows.reduce((w, r) => Math.max(w, r.no.length), 10);
    worksheet["!cols"] = [{ wch: max_width }];

    XLSX.writeFile(
      workbook,
      "Daftar DO " + moment().format("DD_MM_YYYY") + ".xlsx",
      { compression: true }
    );

    setIsPressedExport(false);
  };

  const {
    data: dataExport,
    refetch,
    isFetching: isFetchingExport,
  } = useQuery({
    queryKey: ["do-export"],
    queryFn: () => getDoExport(),
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { mutate: mutateStatus } = useMutation({
    mutationFn: setNewStatusDo,
    onSuccess: () => {
      setOpenChange(false);
      refetchList();
    },
  });

  useEffect(() => {
    if (dataExport && !isFetchingExport && isPressedExport) exportXlsx();
  }, [isFetchingExport]);

  const { data, refetch: refetchList } = useQuery({
    queryKey: [
      "do-list",
      fetchLimit,
      page,
      search,
      doDates[1],
      delivery,
      deliveryDates[1],
      status,
    ],
    queryFn: () =>
      getDoList({
        limit: fetchLimit,
        offset: page,
        search: search,
        status: status,
        start: doDates[0],
        end: doDates[1],
        carrier: delivery ? delivery.id : 0,
        startDelivery: deliveryDates[0],
        endDelivery: deliveryDates[1],
      }),
  });

  const { data: dataShipmentMethod } = useQuery({
    queryKey: ["sm-list"],
    queryFn: () => getShipmentMethod(),
  });

  useEffect(() => {
    isDo ? setDoDates(selectedDates) : setDeliveryDates(selectedDates);
  }, [selectedDates]);

  useEffect(() => {
    if (!isDownload) setSelectedDo(null);
  }, [isDownload]);

  return (
    <>
      <div className="flex mb-4 gap-2 items-center">
        <TextField
          fullWidth
          placeholder="No. SPK / No. DO"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Autocomplete
          sx={{ width: "100%" }}
          disablePortal
          value={delivery}
          onChange={(_, option) => {
            setDelivery(option);
          }}
          options={dataShipmentMethod ? dataShipmentMethod : []}
          getOptionLabel={(option) => option.shipment_name}
          renderInput={(params) => (
            <TextField {...params} label="Metode Pengiriman" />
          )}
        />
        <Select
          sx={{ width: "100%" }}
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          <MenuItem value={4}>Semua Status Delivery</MenuItem>
          <MenuItem value={0}>Undecided</MenuItem>
          <MenuItem value={1}>Delivered</MenuItem>
          <MenuItem value={2}>Cancel</MenuItem>
          <MenuItem value={3}>Partial</MenuItem>
        </Select>
        <div
          className="p-2 w-16 self-stretch my-1 border-2 rounded-md flex items-center justify-center cursor-pointer hover:bg-slate-100"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <IoFilter/>
        </div>
        <button
          onClick={() => navigate("/do/create-do")}
          className="w-1/3 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
        >
          <p>Create Delivery Order</p>
          <IoMdAddCircleOutline className="text-[18px]" />
        </button>
      </div>

      <div className="w-full mb-4">
        <button
          className="rounded-md p-2 px-8 border-2 float-right mb-4 cursor-pointer hover:bg-slate-100 disabled:bg-gray-300 disabled:text-white disabled:cursor-default"
          disabled={isFetchingExport}
          onClick={() => {
            refetch();
            setIsPressedExport(true);
          }}
        >
          {isFetchingExport ? <CircularProgress size={20} /> : "Export DO"}
        </button>
      </div>

      <>
        {" "}
        <div className="rounded-md shadow-md">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <th className="p-3 text-left">No</th>
                <th className="p-3 text-left">No. DO</th>
                <th className="p-3 text-left">No. PO</th>
                <th className="p-3 text-left">Internal Code</th>
                <th className="p-3 text-left">No. SPK</th>
                <th className="p-3 text-left">Customer</th>
                <th className="p-3 text-left">Label</th>
                <th className="p-3 text-left">Qty Plan</th>
                <th className="p-3 text-left">Qty Actual</th>
                <th className="p-3 text-left">Qty Delivery</th>
                <th className="p-3 text-left">Shipment</th>
                <th className="p-3 text-left">Petugas Pengiriman</th>
                <th className="p-3 text-left">Delivery Status</th>
                <th className="p-3 text-left">Information Status</th>
                <th className="p-3 w-[15%] text-left">Action</th>
              </thead>
              <tbody>
                {data && dataShipmentMethod && data.data?.length > 0 ? (
                  data.data.map((item, i) => (
                    <tr className="border-t border-l border-2">
                      <td className="p-3">
                        {(page - 1) * fetchLimit + (i + 1)}
                      </td>
                      <td
                        className="p-3 text-blue-500 cursor-pointer hover:text-slate-500"
                        onClick={() => navigate(`/do/detail/${item.id}`)}
                      >
                        {item.do_number}
                      </td>
                      <td className="p-3">{item.po_number}</td>
                      <td className="p-3">
                        {item.label
                          ? [
                              ...item.label.map(
                                (labelItem) => labelItem.internal_code
                              ),
                            ].join(", ")
                          : null}
                      </td>
                      <td className="p-3">{item.spk_no}</td>
                      <td className="p-3">{item.customer_name}</td>
                      <td className="p-3">
                        {item.label
                          ? [
                              ...item.label.map(
                                (labelItem) => labelItem.label_name
                              ),
                            ].join(", ")
                          : null}
                      </td>
                      <td className="p-3">
                        {item.label
                          ? item.label.reduce(
                              (acc, num) => acc + num.qty_plan,
                              0
                            )
                          : 0}
                      </td>
                      <td className="p-3">
                        {item.label
                          ? item.label.reduce(
                              (acc, num) => acc + num.qty_actual,
                              0
                            )
                          : 0}
                      </td>
                      <td className="p-3">
                        {item.label
                          ? item.label.reduce(
                              (acc, num) => acc + num.qty_delivery,
                              0
                            )
                          : 0}
                      </td>
                      <td className="p-3">
                        {
                          dataShipmentMethod?.find(
                            (elem) => elem.id === item.shipment
                          ).shipment_name
                        }
                      </td>
                      <td className="p-3">{item.carrier_name}</td>
                      <td className="p-3">
                        <Select
                          value={item.delivery_status}
                          fullWidth
                          onChange={(e) => {
                            handleChangeStatus(e.target.value, 1, item.id);
                          }}
                        >
                          <MenuItem value={0}>Pilih Status</MenuItem>
                          <MenuItem value={1}>Delivered</MenuItem>
                          <MenuItem value={2}>Cancel</MenuItem>
                          <MenuItem value={3}>Partial</MenuItem>
                        </Select>
                      </td>
                      <td className="p-3">
                        <Select
                          value={item.information_status}
                          fullWidth
                          onChange={(e) =>
                            handleChangeStatus(e.target.value, 2, item.id)
                          }
                        >
                          <MenuItem value={0}>Pilih Status</MenuItem>
                          <MenuItem value={1}>SJ&JR Completed</MenuItem>
                          <MenuItem value={2}>Hold</MenuItem>
                        </Select>
                      </td>
                      <td className="p-3 w-[15%]">
                        <div className="flex gap-2 items-center">
                          {isDownload && selectedDo === item ? (
                            <CircularProgress className="text-[24px]" />
                          ) : (
                            <IoMdDownload
                              onClick={() => {
                                setIsDownload(true);
                                setSelectedDo(item);
                              }}
                              className="text-indigo-700 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="border-t border-l border-2">
                    <td className="p-3 text-center" colSpan={16}>
                      Tidak ada data
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {data && data.count > 0 ? (
            <div className="w-full flex justify-between pb-8 px-8">
              <button
                onClick={() => {
                  if (page > 1) setPage(page - 1);
                }}
                disabled={page === 1}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowLeftLong /> Previous
              </button>
              <Pagination
                hideNextButton
                hidePrevButton
                shape="rounded"
                page={page}
                count={Math.ceil(data.count / fetchLimit)}
                onChange={(e, page) => setPage(page)}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
              <button
                onClick={() => {
                  if (page < Math.ceil(data.count / fetchLimit))
                    setPage(page + 1);
                }}
                disabled={page === Math.ceil(data.count / fetchLimit)}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowRightLong /> Next
              </button>
            </div>
          ) : null}
        </div>
        {data && data.count > 0 ? (
          <div className="mt-4 text-xs flex gap-3 items-center">
            <p>Show Table</p>
            <p
              onClick={() => setFetchLimit(10)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 10 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              10
            </p>
            <p
              onClick={() => setFetchLimit(50)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 50 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              50
            </p>
            <p
              onClick={() => setFetchLimit(100)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 100 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              100
            </p>
          </div>
        ) : null}
      </>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="">
          <div
            className={`p-3 border-b-2 hover:bg-slate-100 cursor-pointer flex justify-between items-center ${
              doDates[0] !== "" && doDates[1] !== "" ? "bg-slate-100" : ""
            }`}
            onClick={(e) => handleSelectDoDate(e)}
          >
            Tanggal DO
            <IoChevronForward />
          </div>
          <div
            className={`p-3 border-b-2 hover:bg-slate-100 cursor-pointer flex justify-between items-center ${
              deliveryDates[0] !== "" && deliveryDates[1] !== ""
                ? "bg-slate-100"
                : ""
            }`}
            onClick={(e) => handleSelectDeliveryDate(e)}
          >
            Tanggal Delivery
            <IoChevronForward />
          </div>
        </div>
      </Popover>

      {selectedDo !== null ? (
        <PrintDo
          isDownload={isDownload}
          setIsDownload={setIsDownload}
          data={selectedDo}
        />
      ) : null}

      <RangeCalendar
        anchorElCalendar={anchorElCalendar}
        setAnchorElCalendar={setAnchorElCalendar}
        setSelectedDates={setSelectedDates}
        searchStartDate={searchStartDate}
        searchEndDate={searchEndDate}
        setSearchStartDate={setSearchStartDate}
        setSearchEndDate={setSearchEndDate}
      />

      <ModalInput
        title="Update Status DO?"
        subtitle={`Apakah anda yakin ingin mengubah ${
          type === 1 ? "status pengiriman" : "status"
        } menjadi ${
          type === 1
            ? newStatus === 1
              ? "Deliver"
              : newStatus === 2
              ? "Cancel"
              : "Partial"
            : newStatus === 1
            ? "SJ&JR Completed"
            : "Hold"
        }?`}
        content={<></>}
        open={openChange}
        setOpen={setOpenChange}
        buttonText="Yes"
        hasButton={true}
        setTriggerFunc={
          () => mutateStatus({ id: selectedId, status: newStatus, type: type })
          // changeStatus
        }
        isDisable={false}
      />
    </>
  );
};

export default DeliveryOrderList;
