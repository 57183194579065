import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  CircularProgress,
  InputAdornment,
  Pagination,
  PaginationItem,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { MdCalendarMonth, MdCancel } from "react-icons/md";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { IoMdDownload, IoMdAddCircleOutline } from "react-icons/io";
import { format } from "date-fns";
import {
  getInvoiceCategoryList,
  getSupplierInvoiceList,
  setInvoiceCategoryList,
  setNewSupplierInvoice,
} from "../../../services/supplierInvoiceApi";
import PrintInvoice from "./PrintInvoice";
import ModalInput from "../../common/ModalInput";
import { useFormik } from "formik";
import * as yup from "yup";
import SingleCalendar from "../../common/SingleCalendar";
import {
  getSupplierPoDetail,
  getSupplierPoList,
} from "../../../services/supplierPoApi";
import DeleteInvoiceCategoryModal from "./DeleteInvoiceCategoryModal";

const validationSchema = yup.object({
  file: yup.mixed().nullable(),
  json: yup.object({
    invoice_number: yup
      .string()
      .required()
      .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
    po_number: yup
      .string()
      .required()
      .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
    due_date: yup
      .string()
      .required()
      .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
    invoice_date: yup
      .string()
      .required()
      .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
    invoice_receive_date: yup
      .string()
      .required()
      .matches(/^\S\S*(\s?\S*)*/g, "Data tidak boleh diawali dengan spasi"),
    dpp: yup.number().required(),
    faktur: yup.string().required(),
    materials: yup.array().min(1),
  }),
});

const InvoiceList = () => {
  const navigate = useNavigate();
  const defaultFilterOptions = createFilterOptions();

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(0);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isDownload, setIsDownload] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [addErrMessage, setAddErrMessage] = useState("");
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [anchorElCalendarInv, setAnchorElCalendarInv] = useState(null);
  const [anchorElCalendarInvReceived, setAnchorElCalendarInvReceived] =
    useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [invDate, setInvDate] = useState(null);
  const [invDateReceived, setInvDateReceived] = useState(null);
  const [selectedPo, setSelectedPo] = useState(null);
  const [refetchList, setRefetchList] = useState(false);
  const [changeImage, setChangeImage] = useState(false);
  const [openAddCat, setOpenAddCat] = useState(false);
  const [newCat, setNewCat] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openDeleteCat, setOpenDeleteCat] = useState(false);
  const [errorMessageCreateCat, setErrorMessageCreateCat] = useState("");

  const uploadMedia = (e) => {
    const files = e.target.files[0];

    if (!files) return;

    formik.setFieldValue("file", files);
    setChangeImage(true);
  };

  const { mutate: mutateNewInvoice, isPending } = useMutation({
    mutationFn: setNewSupplierInvoice,
    onSuccess: () => {
      setOpenAdd(false);
      setRefetchList(true);
    },
    onError: (err) => setAddErrMessage(err.message.id),
  });

  const { data: dataPoDetail } = useQuery({
    queryKey: ["po-detail", selectedPo],
    queryFn: () => getSupplierPoDetail(selectedPo.id),
    enabled: selectedPo !== undefined || selectedPo !== null,
  });

  const { data, isFetching } = useQuery({
    queryKey: ["invoice-list", search, filter, page, fetchLimit, refetchList],
    queryFn: () =>
      getSupplierInvoiceList({
        search: search,
        status: filter,
        offset: page,
        limit: fetchLimit,
      }),
  });

  const { data: dataPo, isFetching: isFetchingList } = useQuery({
    queryKey: ["po-list"],
    queryFn: () =>
      getSupplierPoList({
        inv: true,
        search: "",
        start: "",
        end: "",
        offset: 1,
        limit: 10000,
      }),
  });

  const { data: dataCategory } = useQuery({
    queryKey: ["category", isAdd, refetchList],
    queryFn: () => getInvoiceCategoryList(),
  });

  const { mutate: mutateNewCategory, isPending: isPendingCreateCat } =
    useMutation({
      mutationFn: setInvoiceCategoryList,
      onSuccess: () => {
        setOpenAddCat(false);
        setIsAdd(false);
        setNewCat("");
      },
      onError: (err) => {
        setErrorMessage(err.message.id);
        setIsAdd(false);
      },
    });

  useEffect(() => {
    if (isAdd) {
      mutateNewCategory(newCat);
    }
    if (!openAddCat) {
      setNewCat("");
    }
  }, [isAdd, openAddCat]);

  useEffect(() => {
    setRefetchList(false);
  }, [dataCategory]);

  const formik = useFormik({
    initialValues: {
      file: null,
      json: {
        invoice_number: "",
        invoice_date: "",
        invoice_receive_date: "",
        po_number: "",
        due_date: "",
        dpp: 0,
        faktur: "",
        materials: [],
        category_id: 0,
        category_name: "",
      },
    },
    validationSchema,
    validateOnMount: true,
  });

  useEffect(() => {
    if (dataPoDetail) {
      const temp = [];
      dataPoDetail.material.forEach((item) => {
        temp.push({
          quantity: item.quantity - item.quantity_receive,
          id: item.material_id,
        });
      });
      formik.setFieldValue("json.materials", temp);
    }
  }, [dataPoDetail]);

  useEffect(() => {
    if (changeImage) {
      var reader = new FileReader();
      var imgtag = document.getElementById("uploaded-img");

      reader.onload = function (event) {
        imgtag.src = event.target.result;
      };

      reader.readAsDataURL(formik.values.file);
      setChangeImage(false);
    }
  }, [changeImage]);

  useEffect(() => {
    if (!isDownload) setSelectedInvoice(null);
  }, [isDownload]);

  useEffect(() => {
    setAddErrMessage("");
  }, [formik.values]);

  useEffect(() => {
    if (refetchList) setRefetchList(false);
  }, [data]);

  useEffect(() => {
    formik.setFieldValue(
      "json.po_number",
      selectedPo ? selectedPo.po_number : ""
    );
  }, [selectedPo]);

  useEffect(() => {
    if (openAdd) {
      formik.resetForm();
      setDueDate(null);
    }
  }, [openAdd]);

  useEffect(() => {
    if (isAdd) {
      mutateNewInvoice(formik.values);
      setIsAdd(false);
    }
  }, [isAdd]);

  useEffect(() => {
    dueDate
      ? formik.setFieldValue(
          "json.due_date",
          format(new Date(dueDate), "yyyy-MM-dd")
        )
      : formik.setFieldValue("json.due_date", "");
  }, [dueDate]);

  useEffect(() => {
    invDate
      ? formik.setFieldValue(
          "json.invoice_date",
          format(new Date(invDate), "yyyy-MM-dd")
        )
      : formik.setFieldValue("json.invoice_date", "");
  }, [invDate]);

  useEffect(() => {
    invDateReceived
      ? formik.setFieldValue(
          "json.invoice_receive_date",
          format(new Date(invDateReceived), "yyyy-MM-dd")
        )
      : formik.setFieldValue("json.invoice_receive_date", "");
  }, [invDateReceived]);

  useEffect(() => {
    setPage(1);
  }, [fetchLimit, search, filter]);

  useEffect(() => {
    if (formik.values.json.category_id === -1) {
      formik.setFieldValue("json.category_id", 0);
      setOpenAddCat(true);
    }
  }, [formik.values.json.category_id]);

  return (
    <div>
      <div className="flex mb-4 gap-2">
        <TextField
          fullWidth
          placeholder="Nama Supplier/Vendor"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <FormControl fullWidth>
          <Select value={filter} onChange={(e) => setFilter(e.target.value)}>
            <MenuItem value={0}>Semua Status</MenuItem>
            <MenuItem value={1}>Sudah Dibayar</MenuItem>
            <MenuItem value={2}>Belum Dibayar</MenuItem>
            <MenuItem value={3}>Terlambat Dibayar</MenuItem>
          </Select>
        </FormControl>
        <button
          onClick={() => setOpenAdd(true)}
          className="w-1/4 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
        >
          <p>Buat Invoice</p>
          <IoMdAddCircleOutline className="text-[18px]" />
        </button>
      </div>
      <>
        <div className="rounded-md shadow-md">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <tr>
                  <th className="p-3 text-left">No</th>
                  <th className="p-3 text-left">No. Invoice</th>
                  <th className="p-3 text-left">Supplier/Vendor</th>
                  <th className="p-3 text-left">Grand Total</th>
                  <th className="p-3 text-left">Jatuh Tempo</th>
                  <th className="p-3 text-left">Status</th>
                  <th className="p-3 w-[15%] text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                {data && data.invoice?.length > 0 && !isFetching ? (
                  data.invoice.map((item, i) => {
                    return (
                      <tr className="border-t border-l border-2" key={i}>
                        <td className="p-3">
                          {(page - 1) * fetchLimit + (i + 1)}
                        </td>
                        <td
                          className="p-3 text-blue-500 cursor-pointer hover:text-slate-500"
                          onClick={() =>
                            navigate(`/sv-invoice/detail/${item.id}`)
                          }
                        >
                          {item.invoice_number}
                        </td>
                        <td className="p-3">{item.supplier_name}</td>
                        <td className="p-3">
                          Rp
                          {item.grand_total.toLocaleString().replace(",", ".")}
                        </td>
                        <td className="p-3">
                          {format(new Date(item.due_date), "dd-MM-yyyy")}
                        </td>
                        <td className="p-3">
                          {item.status === "1"
                            ? "Sudah Dibayar"
                            : item.status === "2"
                            ? "Belum Dibayar"
                            : "Terlambat Dibayar"}
                        </td>
                        <td className="p-3 w-[15%]">
                          <div className="flex gap-2 items-center">
                            <IoMdDownload
                              onClick={() => {
                                setIsDownload(true);
                                setSelectedInvoice(item);
                              }}
                              className="text-blue-500 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : !isFetching ? (
                  <tr className="border-t border-l border-2">
                    <td colSpan={7} className="py-3 text-center">
                      Data material tidak ditemukan
                    </td>
                  </tr>
                ) : (
                  <tr className="border-t border-l border-2">
                    <td colSpan={7} className="py-3 text-center">
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {data && data.total > 0 ? (
            <div className="w-full flex justify-between pb-8 px-8">
              <button
                onClick={() => {
                  if (page > 1) setPage(page - 1);
                }}
                disabled={page === 1}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowLeftLong /> Previous
              </button>
              <Pagination
                hideNextButton
                hidePrevButton
                shape="rounded"
                page={page}
                count={Math.ceil(data.total / fetchLimit)}
                onChange={(e, page) => setPage(page)}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
              <button
                onClick={() => {
                  if (page < Math.ceil(data.total / fetchLimit))
                    setPage(page + 1);
                }}
                disabled={page === Math.ceil(data.total / fetchLimit)}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowRightLong /> Next
              </button>
            </div>
          ) : null}
        </div>
        {data && data.total > 0 ? (
          <div className="mt-4 text-xs flex gap-3 items-center">
            <p>Show Table</p>
            <p
              onClick={() => setFetchLimit(10)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 10 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              10
            </p>
            <p
              onClick={() => setFetchLimit(50)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 50 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              50
            </p>
            <p
              onClick={() => setFetchLimit(100)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 100 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              100
            </p>
          </div>
        ) : null}
      </>

      <ModalInput
        title="Create Supplier Invoice"
        subtitle=""
        content={
          <>
            <TextField
              fullWidth
              sx={{ marginBottom: 2 }}
              label="No. Invoice"
              value={formik.values.json.invoice_number}
              onChange={(e) =>
                formik.setFieldValue("json.invoice_number", e.target.value)
              }
            />
            <Autocomplete
              sx={{ width: "100%", marginBottom: 2 }}
              disablePortal
              value={selectedPo}
              onChange={(_, option) => {
                setSelectedPo(option);
              }}
              options={dataPo && dataPo.data ? dataPo.data : []}
              getOptionLabel={(option) =>
                option.po_number + " - " + option.supplier_name
              }
              renderInput={(params) => <TextField {...params} label="No. PO" />}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === "reset") {
                  setSelectedPo(null);
                  return;
                }
              }}
            />
            {dataPoDetail ? (
              <div className="mb-4 w-full overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="p-2 border">Material Name</th>
                      <th className="p-2 border text-center">Quantity</th>
                      <th className="p-2 border text-center">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataPoDetail.material.map((item, i) => (
                      <tr key={i}>
                        <td className="p-2 border">
                          <p>{item.material_name}</p>
                          {formik.values.json.materials.length > 0 &&
                          formik.values.json.materials[i].quantity >
                            item.quantity - item.quantity_receive ? (
                            <p className="text-red-500">
                              Jumlah material max.{" "}
                              {item.quantity - item.quantity_receive}
                            </p>
                          ) : null}
                        </td>
                        <td className="p-2 border text-center">
                          <TextField
                            fullWidth
                            value={
                              formik.values.json.materials.length > 0
                                ? formik.values.json.materials[i].quantity
                                : 0
                            }
                            onChange={(e) => {
                              if (!isNaN(Number(e.target.value))) {
                                formik.setFieldValue(
                                  "json.materials[" + i + "].quantity",
                                  Number(e.target.value)
                                );
                              }
                            }}
                          />
                        </td>
                        <td className="p-2 border">
                          <p>
                            Rp
                            {item.price.toLocaleString().replace(",", ".")}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : null}
            <Autocomplete
              sx={{ width: "100%" }}
              disablePortal
              value={
                dataCategory &&
                dataCategory.data &&
                dataCategory.data.find(
                  (item) =>
                    item.category_name === formik.values.json.category_name
                )
                  ? dataCategory.data.find(
                      (item) =>
                        item.category_name === formik.values.json.category_name
                    )
                  : null
              }
              onChange={(_, option) => {
                if (option)
                  formik.setFieldValue(
                    "json.category_name",
                    option.category_name
                  );
                formik.setFieldValue("json.category_id", option.id);
              }}
              options={
                dataCategory && dataCategory.data
                  ? [
                      { id: -1, category_name: "Tambah Kategori" },
                      ...dataCategory.data,
                    ]
                  : []
              }
              getOptionLabel={(option) =>
                option.category_name && typeof option.category_name === "string"
                  ? option.category_name
                  : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Kategori"
                  onChange={(e) => setNewCat(e.target.value)}
                />
              )}
              filterOptions={(options, state) => {
                const results = defaultFilterOptions(options, state);
                if (results.length === 0) {
                  return [{ id: -1, category_name: "Tambah Kategori" }];
                }

                return results;
              }}
            />
            <p
              className="text-right w-full text-xs underline text-blue-500 cursor-pointer mb-4"
              onClick={() => setOpenDeleteCat(true)}
            >
              Pengaturan Kategori
            </p>
            <TextField
              fullWidth
              sx={{ marginBottom: 2 }}
              label="DPP"
              value={formik.values.json.dpp}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue("json.dpp", Number(e.target.value));
              }}
            />
            <p>Tanggal Invoice</p>
            <div className="mb-4 w-full border-2 rounded-md flex items-center hover:bg-slate-100">
              <div
                className="flex justify-between items-center h-full w-full p-2 cursor-pointer text-black"
                onClick={(e) => {
                  setAnchorElCalendarInv(e.currentTarget);
                }}
              >
                {invDate === null ? (
                  <p>----/--/--</p>
                ) : (
                  <p>{format(new Date(invDate), "yyyy-MM-dd")}</p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
            <p>Tanggal Terima Invoice</p>
            <div className="mb-4 w-full border-2 rounded-md flex items-center hover:bg-slate-100">
              <div
                className="flex justify-between items-center h-full w-full p-2 cursor-pointer text-black"
                onClick={(e) => {
                  setAnchorElCalendarInvReceived(e.currentTarget);
                }}
              >
                {invDateReceived === null ? (
                  <p>----/--/--</p>
                ) : (
                  <p>{format(new Date(invDateReceived), "yyyy-MM-dd")}</p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
            <TextField
              fullWidth
              sx={{ marginBottom: 2 }}
              label="No. Faktur Pajak"
              value={formik.values.json.faktur}
              onChange={(e) => {
                formik.setFieldValue("json.faktur", e.target.value);
              }}
            />
            <p>Tanggal Jatuh Tempo</p>
            <div className="mb-4 w-full border-2 rounded-md flex items-center hover:bg-slate-100">
              <div
                className="flex justify-between items-center h-full w-full p-2 cursor-pointer text-black"
                onClick={(e) => {
                  setAnchorElCalendar(e.currentTarget);
                }}
              >
                {dueDate === null ? (
                  <p>----/--/--</p>
                ) : (
                  <p>{format(new Date(dueDate), "yyyy-MM-dd")}</p>
                )}
                <MdCalendarMonth className="w-6 h-6" />
              </div>
            </div>
            <div className="w-full border-2 p-4 mb-4 rounded-lg text-center">
              <>
                {isPending ? (
                  <CircularProgress />
                ) : formik.values.file === null ? (
                  <>
                    <label
                      htmlFor="dropzone-file1"
                      className="cursor-pointer flex flex-wrap justify-center my-8 hover:text-slate-400"
                    >
                      <div className="flex items-center justify-center">
                        <AiOutlineCloudUpload className="text-3xl" />
                        <p className="ml-4 font-bold text-lg">Upload File</p>
                      </div>
                      <p className="text-sm rounded-md cursor-pointer w-full mt-2 text-gray-400">
                        Klik untuk mencari file di perangkat anda
                      </p>
                      <input
                        id="dropzone-file1"
                        type="file"
                        accept="image/jpeg, image/jpg, image/png"
                        onChange={(e) => {
                          uploadMedia(e);
                        }}
                        hidden
                      />
                    </label>
                  </>
                ) : (
                  <div className="flex items-center justify-center w-full relative">
                    <img
                      id="uploaded-img"
                      className="max-h-40 object-contain"
                      alt="Img"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        formik.setFieldValue("file", null);
                      }}
                    >
                      <MdCancel className="text-2xl text-red-500 hover:text-red-700 absolute top-0 right-0" />
                    </button>
                  </div>
                )}
              </>
            </div>
            <p className="text-red-500">{addErrMessage}</p>
          </>
        }
        open={openAdd}
        setOpen={setOpenAdd}
        hasButton={true}
        buttonText="Submit"
        setTriggerFunc={setIsAdd}
        isDisable={!formik.isValid || isPending}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendar}
        setAnchorElCalendarStart={setAnchorElCalendar}
        startDate={dueDate}
        setStartDate={setDueDate}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendarInv}
        setAnchorElCalendarStart={setAnchorElCalendarInv}
        startDate={invDate}
        setStartDate={setInvDate}
      />

      <SingleCalendar
        anchorElCalendarStart={anchorElCalendarInvReceived}
        setAnchorElCalendarStart={setAnchorElCalendarInvReceived}
        startDate={invDateReceived}
        setStartDate={setInvDateReceived}
      />

      <PrintInvoice
        isDownload={isDownload}
        setIsDownload={setIsDownload}
        data={selectedInvoice}
        type="supplier"
      />

      <ModalInput
        title="Tambah Categori"
        subtitle={errorMessageCreateCat}
        content={
          <>
            <TextField
              fullWidth
              label="Nama Categori Baru"
              value={newCat}
              onChange={(e) => setNewCat(e.target.value)}
            />
          </>
        }
        open={openAddCat}
        setOpen={setOpenAddCat}
        hasButton={true}
        buttonText="Save"
        setTriggerFunc={setIsAdd}
        isDisable={isPendingCreateCat || newCat === ""}
      />

      {dataCategory ? (
        <DeleteInvoiceCategoryModal
          dataCategory={dataCategory}
          openDeleteCat={openDeleteCat}
          setOpenDeleteCat={setOpenDeleteCat}
          setRefetchList={setRefetchList}
        />
      ) : null}
    </div>
  );
};

export default InvoiceList;
