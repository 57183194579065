import {
  Breadcrumbs,
  CircularProgress,
  Link,
  Tab,
  Tabs,
  TextField,
  CustomTabPanel,
  Box,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getEquipmentDetail } from "../../../services/equipmentApi";
import { format } from "date-fns";

const DetailEquipment = () => {
  const { id } = useParams();

  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const { data, isFetching } = useQuery({
    queryKey: ["equipment-detail", id],
    enabled: id !== null,
    queryFn: () => getEquipmentDetail(id),
  });

  return (
    <div className="mt-12 lg:mt-2">
      <div>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/equipment-management">
            Equipment Management
          </Link>
          <Link underline="hover" color="inherit">
            Detail Equipment
          </Link>
        </Breadcrumbs>
      </div>

      <h1 className="font-bold text-xl mt-8 mb-4">Equipment Detail</h1>
      {data && !isFetching ? (
        <>
          <TextField
            fullWidth
            sx={{ marginBottom: 2 }}
            disabled
            value={data.detail.code}
            label="Kode Equipment"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: 2 }}
            disabled
            value={data.detail.name}
            label="Nama Equipment"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: 2 }}
            disabled
            value={
              format(new Date(data.detail.scheduled_start_date), "dd MMMM yyyy") +
              " s/d " +
              format(new Date(data.detail.scheduled_start_date), "dd MMMM yyyy")
            }
            label="Jadwal Maintenance"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: 2 }}
            disabled
            value={data.detail.status}
            label="Status"
          />

          <p className="font-bold text-xl mt-8 mb-4">Maintenance</p>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Maintenance History" {...a11yProps(0)} />
            <Tab label="Next Maintenance" {...a11yProps(1)} />
          </Tabs>

          <CustomTabPanel value={value} index={0}>
            <div className="rounded-md shadow-md">
              <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
                <table className="w-full">
                  <thead className="bg-[#F4F5FF] border-t border-b border-2">
                    <th className="p-3 text-left">No</th>
                    <th className="p-3 text-left">Start Date</th>
                    <th className="p-3 text-left">End Date</th>
                  </thead>
                  <tbody>
                    {data.history
                      ? data.history.map((item, i) => (
                          <tr>
                            <td className="p-3">{i + 1}</td>
                            <td className="p-3">
                              {format(
                                new Date(item.scheduled_start_date),
                                "dd MMMM yyyy"
                              )}
                            </td>
                            <td className="p-3">
                              {format(
                                new Date(item.scheduled_end_date),
                                "dd MMMM yyyy"
                              )}
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div className="rounded-md shadow-md">
              <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
                <table className="w-full">
                  <thead className="bg-[#F4F5FF] border-t border-b border-2">
                    <th className="p-3 text-left">No</th>
                    <th className="p-3 text-left">Start Date</th>
                    <th className="p-3 text-left">End Date</th>
                  </thead>
                  <tbody>
                    {data.history
                      ? data.history.map((item, i) => (
                          <tr>
                            <td className="p-3">{i + 1}</td>
                            <td className="p-3">
                              {format(
                                new Date(item.planned_start_date),
                                "dd MMMM yyyy"
                              )}
                            </td>
                            <td className="p-3">
                              {format(
                                new Date(item.planned_end_date),
                                "dd MMMM yyyy"
                              )}
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </CustomTabPanel>
        </>
      ) : isFetching ? (
        <CircularProgress />
      ) : null}
    </div>
  );
};

export default DetailEquipment;
