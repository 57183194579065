import { Search } from "@mui/icons-material";
import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  TextField,
} from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import ModalInput from "../../common/ModalInput";
import * as XLSX from "xlsx";
import moment from "moment";
import { getMpsFlexoExport, getMpsFlexoList } from "../../../services/mpsApi";

const MpsFlexoList = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [openDelete, setOpenDelete] = useState(false);
  const [search, setSearch] = useState("");
  const [dieCut, setDieCut] = useState(0);

  const handleExport = () => {
    mutateExportList();
  };

  const exportXlsx = (dataExport) => {
    const rows = dataExport.data.map((row, i) => ({
      no: i + 1,
      job: row.job,
      customer_name: row.customer_name,
      internal_code: row.internal_code,
      label: row.label_name,
      size: row.size,
      material: row.material,
      finishing: row.finishing,
      die: row.die_cut === 1 ? "Flexible" : "Flatbed",
      die_status: row.die_cut_status,
      gear: row.gear,
      job_date: row.job_bag_date
        ? format(new Date(row.job_bag_date), "yyyy-MM-dd")
        : "-",
      machine: row.machine,
      description: row.description,
      job_finish_date: row.job_bag_finished_date
        ? format(new Date(row.job_bag_finished_date), "yyyy-MM-dd")
        : "-",
    }));

    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "Daftar MPS Flexo " + moment().format("DD-MM-YYYY")
    );

    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          "No",
          "Job",
          "Nama Customer",
          "Internal Code",
          "Label Name",
          "Size",
          "Material",
          "Finishing",
          "Die Cut",
          "Die Cut Status",
          "Gear",
          "Tanggal Pemakaian Job Bag",
          "Machine",
          "Description",
          "Tanggal Selesai Job Bag",
        ],
      ],
      {
        origin: "A1",
      }
    );

    const max_width = rows.reduce((w, r) => Math.max(w, r.no.length), 10);
    worksheet["!cols"] = [{ wch: max_width }];

    XLSX.writeFile(
      workbook,
      "Daftar MPS Flexo " + moment().format("DD_MM_YYYY") + ".xlsx",
      { compression: true }
    );
  };

  const { data, isFetching } = useQuery({
    queryKey: ["flexo-list", page, fetchLimit, search, dieCut],
    queryFn: () => getMpsFlexoList(fetchLimit, page, search, dieCut),
  });

  const { mutate: mutateExportList, isPending: isPendingExport } = useMutation({
    mutationFn: () => getMpsFlexoExport(search, dieCut),
    onSuccess: (data) => exportXlsx(data),
  });

  return (
    <>
      <div className="flex mb-4 gap-2">
        <TextField
          fullWidth
          placeholder="Search by customer"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Select
          value={dieCut}
          onChange={(e) => setDieCut(e.target.value)}
          sx={{ width: "100%" }}
        >
          <MenuItem value={0}>Semua Die Cut</MenuItem>
          <MenuItem value={1}>Flexible</MenuItem>
          <MenuItem value={2}>Flatbed</MenuItem>
        </Select>
        <button
          onClick={() => navigate("/mps-flexo/create-mps")}
          className="w-1/4 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
        >
          <p>Add MPS</p>
          <IoMdAddCircleOutline className="text-[18px]" />
        </button>
      </div>

      <div className="w-full mb-4">
        <button
          className="rounded-md p-2 px-8 border-2 float-right mb-4 cursor-pointer hover:bg-slate-100 disabled:bg-gray-300 disabled:text-white disabled:cursor-default"
          disabled={isPendingExport}
          onClick={handleExport}
        >
          {isPendingExport ? (
            <CircularProgress size={20} />
          ) : (
            "Export MPS Flexo"
          )}
        </button>
      </div>

      <div className="rounded-md shadow-md">
        <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
          <table className="w-full">
            <thead className="bg-[#F4F5FF] border-t border-b border-2">
              <th className="p-3 text-left">No</th>
              <th className="p-3 text-left">Job</th>
              <th className="p-3 text-left">Nama Customer</th>
              <th className="p-3 text-left">Internal Code</th>
              <th className="p-3 text-left">Label Name</th>
              <th className="p-3 text-left">Size</th>
              <th className="p-3 text-left">Material</th>
              <th className="p-3 text-left">Finishing</th>
              <th className="p-3 text-left">Die Cut</th>
              <th className="p-3 text-left">Die Cut Status</th>
              <th className="p-3 text-left">Gear</th>
              <th className="p-3 text-left">Tanggal Pemakaian Job Bag</th>
              <th className="p-3 text-left">Machine</th>
              <th className="p-3 text-left">Description</th>
              <th className="p-3 text-left">Tanggal Selesai Job Bag</th>
            </thead>
            <tbody>
              {data && data.count > 0 && !isFetching ? (
                data.data.map((item, i) => {
                  return (
                    <tr>
                      <td className="p-3 text-left">
                        {(page - 1) * fetchLimit + (i + 1)}
                      </td>
                      <td className="p-3 text-left">{item.job}</td>
                      <td className="p-3 text-left">{item.customer_name}</td>
                      <td className="p-3 text-left">{item.internal_code}</td>
                      <td className="p-3 text-left">{item.label_name}</td>
                      <td className="p-3 text-left">{item.size}</td>
                      <td className="p-3 text-left">{item.material}</td>
                      <td className="p-3 text-left">{item.finishing}</td>
                      <td className="p-3 text-left">
                        {item.die_cut === 1 ? "Flexible" : "Flatbed"}
                      </td>
                      <td className="p-3 text-left">{item.die_cut_status}</td>
                      <td className="p-3 text-left">{item.gear}</td>
                      <td className="p-3 text-left">
                        {item.job_bag_date
                          ? format(new Date(item.job_bag_date), "yyyy-MM-dd")
                          : "-"}
                      </td>
                      <td className="p-3 text-left">{item.machine}</td>
                      <td className="p-3 text-left">{item.description}</td>
                      <td className="p-3 text-left">
                        {item.job_bag_finished_date
                          ? format(
                              new Date(item.job_bag_finished_date),
                              "yyyy-MM-dd"
                            )
                          : "-"}
                      </td>
                    </tr>
                  );
                })
              ) : isFetching ? (
                <tr>
                  <td className="p-3 text-center" colSpan={15}>
                    <CircularProgress size={20} />
                  </td>
                </tr>
              ) : (
                <td className="p-3 text-center text-gray-300" colSpan={15}>
                  Tidak ada data MPS Flexo
                </td>
              )}
            </tbody>
          </table>
        </div>

        {data && data.count > 0 ? (
          <div className="w-full flex justify-between pb-8 px-8">
            <button
              onClick={() => {
                if (page > 1) setPage(page - 1);
              }}
              disabled={page === 1}
              className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
            >
              <FaArrowLeftLong /> Previous
            </button>
            <Pagination
              hideNextButton
              hidePrevButton
              shape="rounded"
              page={page}
              count={Math.ceil(data.count / fetchLimit)}
              onChange={(e, page) => setPage(page)}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                />
              )}
            />
            <button
              onClick={() => {
                if (page < Math.ceil(data.count / fetchLimit))
                  setPage(page + 1);
              }}
              disabled={page === Math.ceil(data.count / fetchLimit)}
              className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
            >
              <FaArrowRightLong /> Next
            </button>
          </div>
        ) : null}
      </div>
      {data && data.count > 0 ? (
        <div className="mt-4 text-xs flex gap-3 items-center">
          <p>Show Table</p>
          <p
            onClick={() => setFetchLimit(10)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 10 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            10
          </p>
          <p
            onClick={() => setFetchLimit(50)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 50 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            50
          </p>
          <p
            onClick={() => setFetchLimit(100)}
            className={`p-2.5 cursor-pointer hover:text-gray-400 ${
              fetchLimit === 100 ? "border rounded-md bg-slate-100" : ""
            }`}
          >
            100
          </p>
        </div>
      ) : null}
    </>
  );
};

export default MpsFlexoList;
