import { Autocomplete, TextField } from "@mui/material";
import ModalInput from "../../common/ModalInput";
import { useMutation, useQuery } from "@tanstack/react-query";
import { reducePo, setReduceMaterial } from "../../../services/materialApi";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { getSpkList } from "../../../services/SpkApi";

const validationSchema = yup.object({
  no_spk: yup.string().required(),
  no_po: yup.string().required(),
  amount: yup.number().min(1).required(),
  material_id: yup.number().min(1).required(),
});

const ReductionStockMaterial = ({
  id,
  openUpdate,
  setOpenUpdate,
  setRefetchList,
  dataDetail,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [spkNumber, setSpkNumber] = useState("");

  const formik = useFormik({
    initialValues: {
      offset: 1,
      limit: 10,
      no_spk: "",
      no_po: "",
      amount: 0,
      material_id: +id,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      mutateReduceMaterial(values);
    },
  });

  const {
    mutate: mutateSpkList,
    data: dataSpk,
    isPending,
  } = useMutation({
    mutationFn: getSpkList,
  });

  useEffect(() => {
    mutateSpkList(formik.values);
  }, [formik.values]);

  const { data: dataPo, refetch: refetchPo } = useQuery({
    queryKey: ["data-po", spkNumber],
    queryFn: () => reducePo(id),
    enabled: !!spkNumber,
  });

  const { mutate: mutateReduceMaterial, isLoading: isSubmitting } = useMutation(
    {
      mutationFn: setReduceMaterial,
      onSuccess: () => {
        setOpenUpdate(false);
        setRefetchList(true);
        formik.resetForm();
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    }
  );

  useEffect(() => {
    if (spkNumber) {
      refetchPo();
    }
  }, [spkNumber]);

  return (
    <div>
      <ModalInput
        title="Reduce Stock Material"
        subtitle=""
        content={
          <form onSubmit={formik.handleSubmit}>
            <Autocomplete
              sx={{ width: "100%", marginBottom: 2 }}
              disablePortal
              freeSolo
              value={formik.values.no_spk}
              onChange={(_, option) => {
                formik.setFieldValue("no_spk", option || "");
                setSpkNumber(option || "");
              }}
              options={
                isPending
                  ? []
                  : dataSpk?.data
                  ? dataSpk.data.slice(0, 10).map((item) => item.spk_no)
                  : []
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="No. SPK"
                  onChange={(e) => {
                    formik.setFieldValue("no_spk", e.target.value);
                    setSpkNumber(e.target.value);
                  }}
                />
              )}
            />

            <Autocomplete
              sx={{ width: "100%", marginBottom: 2 }}
              disablePortal
              freeSolo
              value={formik.values.no_po}
              onChange={(_, option) => {
                formik.setFieldValue("no_po", option || "");
              }}
              options={dataPo ? dataPo.map((item) => item.name) : []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="No. PO"
                  onChange={(e) => {
                    formik.setFieldValue("no_po", e.target.value);
                  }}
                />
              )}
            />

            <TextField
              fullWidth
              label="Jumlah Keluar"
              sx={{ marginBottom: 2 }}
              value={formik.values.amount}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  formik.setFieldValue("amount", Number(e.target.value));
              }}
            />

            <p className="text-red-500 my-2">{errorMessage}</p>

            <button
              type="submit"
              disabled={isSubmitting || !formik.isValid}
              className="bg-blue-800 text-white p-2 w-full rounded hover:bg-blue-900 cursor-pointer"
            >
              Save
            </button>
          </form>
        }
        open={openUpdate}
        setOpen={setOpenUpdate}
      />
    </div>
  );
};

export default ReductionStockMaterial;
