import {
  CircularProgress,
  InputAdornment,
  Pagination,
  PaginationItem,
  TextField,
} from "@mui/material";
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { IoPencilOutline } from "react-icons/io5";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Search } from "@mui/icons-material";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createGear,
  deleteGear,
  getGearList,
  updateGear,
} from "../../../services/gearManagementApi";
import ModalInput from "../../common/ModalInput";
import { useFormik } from "formik";
import * as yup from "yup";
import GearModalContent from "./GearModalContent";
import { format } from "date-fns";

const validationSchema = yup.object({
  lvc: yup.number().required("Gear size wajib diisi"),
  distortion: yup.number().min(1, "Distorsi minimal 1"),
  repeat_length: yup.number().min(1, "Panjang minimal 1"),
  quantity: yup.number().min(1, "Quantity minimal 1"),
  vendor: yup.string().required("Vendor wajib diisi"),
  incoming_date: yup.string().required("Vendor wajib diisi"),
});

const GearManagementList = () => {
  const [page, setPage] = useState(1);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [lvc, setLvc] = useState("");
  const [openAdd, setOpenAdd] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedGear, setSelectedGear] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [refetchList, setRefetchList] = useState(true);

  const formik = useFormik({
    initialValues: {
      lvc: 0,
      distortion: 0,
      repeat_length: 0,
      quantity: 0,
      vendor: "",
      incoming_date: null,
    },
    validationSchema,
    validateOnMount: true,
  });

  const { data, isFetching } = useQuery({
    queryKey: ["gear-list", lvc, page, fetchLimit, refetchList],
    // enabled: refetchList,
    queryFn: () =>
      getGearList({ search: lvc, offset: page, limit: fetchLimit }),
  });

  const { mutate: mutateNewGear, isPending: isPendingAdd } = useMutation({
    mutationFn: createGear,
    onSuccess: () => {
      setIsAdd(false);
      setOpenAdd(false);
      setRefetchList(true);
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  const { mutate: mutateUpdateGear, isPending: isPendingEdit } = useMutation({
    mutationFn: updateGear,
    onSuccess: () => {
      setIsEdit(false);
      setOpenDetail(false);
      setRefetchList(true);
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  const { mutate: mutateDeleteGear } = useMutation({
    mutationFn: deleteGear,
    onSuccess: () => {
      setErrorMessage("");
      setOpenDelete(false);
      setRefetchList(true);
      setIsDelete(false);
    },
  });

  useEffect(() => {
    if (data) {
      setRefetchList(false);
    }
  }, [data]);

  useEffect(() => {
    if (openAdd || !openDetail)
      formik.setValues({
        lvc: 0,
        distortion: 0,
        repeat_length: 0,
        quantity: 0,
        vendor: "",
        incoming_date: null,
      });
  }, [openAdd, openDetail]);

  useEffect(() => {
    if (isAdd) {
      mutateNewGear(formik.values);
    }
  }, [isAdd]);

  useEffect(() => {
    if (isEdit) {
      mutateUpdateGear({
        id: selectedGear,
        lvc: formik.values.lvc,
        distortion: formik.values.distortion,
        repeat_length: formik.values.repeat_length,
        quantity: formik.values.quantity,
        vendor: formik.values.vendor,
        incoming_date: formik.values.incoming_date,
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (isDelete) {
      mutateDeleteGear(selectedGear);
    }
  }, [isDelete]);

  useEffect(() => {
    setPage(1);
  }, [fetchLimit, lvc]);

  return (
    <div>
      <div className="flex mb-4 gap-2">
        <TextField
          fullWidth
          placeholder="Search by gear size"
          value={lvc}
          onChange={(e) =>
            isNaN(Number(e.target.value)) && e.target.value !== ""
              ? null
              : setLvc(Number(e.target.value))
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <button
          onClick={() => setOpenAdd(true)}
          className="w-1/4 flex items-center justify-center gap-2 bg-[#18479D] p-2 rounded-md text-white text-xs hover:bg-[#163e87] cursor-pointer"
        >
          <p>Add Gear Data</p>
          <IoMdAddCircleOutline className="text-[18px]" />
        </button>
      </div>
      <>
        {" "}
        <div className="rounded-md shadow-md">
          <div className="block overflow-x-auto w-full lg:w-[calc(100% - 310px)] mb-8 rounded-md">
            <table className="w-full">
              <thead className="bg-[#F4F5FF] border-t border-b border-2">
                <th className="p-3 text-left">No</th>
                <th className="p-3 text-left">Gear Size</th>
                <th className="p-3 text-left">Incoming Date</th>
                <th className="p-3 text-left">Repeat Length (mm)</th>
                <th className="p-3 text-left">Distorsi</th>
                <th className="p-3 text-left">Qty</th>
                <th className="p-3 text-left">Vendor</th>
                <th className="p-3 text-left w-[15%]">Action</th>
              </thead>
              <tbody>
                {data && data?.gears?.length > 0 && !isFetching ? (
                  data.gears.map((item, i) => {
                    return (
                      <tr className="border-t border-l border-2" key={i}>
                        <td className="p-3">
                          {(page - 1) * fetchLimit + (i + 1)}
                        </td>
                        <td className="p-3">{item.lvc}</td>
                        <td className="p-3">
                          {format(item.incoming_date, "dd-MM-yyyy")}
                        </td>
                        <td className="p-3">{item.repeat_length}</td>
                        <td className="p-3">{item.distortion}</td>
                        <td className="p-3">{item.quantity}</td>
                        <td className="p-3">{item.vendor}</td>
                        <td className="p-3 w-[15%]">
                          <div className="flex gap-2 items-center">
                            <FaTrash
                              onClick={() => {
                                setOpenDelete(true);
                                setSelectedGear(item.id);
                              }}
                              className="text-red-500 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                            />
                            <IoPencilOutline
                              onClick={() => {
                                setOpenDetail(true);
                                setSelectedGear(item.id);
                                formik.setValues({
                                  lvc: item.lvc,
                                  distortion: item.distortion,
                                  repeat_length: item.repeat_length,
                                  quantity: item.quantity,
                                  vendor: item.vendor,
                                  incoming_date: format(new Date(item.incoming_date), 'yyyy-MM-dd'),
                                });
                              }}
                              className="text-blue-500 border w-[24px] h-full rounded-md p-1 hover:bg-slate-100 cursor-pointer hover:scale-110 transition ease-in-out"
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : !isFetching ? (
                  <tr className="border-t border-l border-2">
                    <td colSpan={8} className="py-3 text-center">
                      Data gear tidak ditemukan
                    </td>
                  </tr>
                ) : (
                  <tr className="border-t border-l border-2">
                    <td colSpan={8} className="py-3 text-center">
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {data && data.count > 0 ? (
            <div className="w-full flex justify-between pb-8 px-8">
              <button
                onClick={() => {
                  if (page > 1) setPage(page - 1);
                }}
                disabled={page === 1}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowLeftLong /> Previous
              </button>
              <Pagination
                hideNextButton
                hidePrevButton
                shape="rounded"
                page={page}
                count={Math.ceil(data.count / fetchLimit)}
                onChange={(e, page) => setPage(page)}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
              <button
                onClick={() => {
                  if (page < Math.ceil(data.count / fetchLimit))
                    setPage(page + 1);
                }}
                disabled={page === Math.ceil(data.count / fetchLimit)}
                className="disabled:bg-gray-100 disabled:cursor-default disabled:hover:scale-100 flex border rounded-md p-3 items-center justify-center gap-2 basis-1/8 text-xs font-bold transition ease-in-out hover:scale-110 hover:bg-slate-100 cursor-pointer"
              >
                <FaArrowRightLong /> Next
              </button>
            </div>
          ) : null}
        </div>
        {data && data.count > 0 ? (
          <div className="mt-4 text-xs flex gap-3 items-center">
            <p>Show Table</p>
            <p
              onClick={() => setFetchLimit(10)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 10 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              10
            </p>
            <p
              onClick={() => setFetchLimit(50)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 50 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              50
            </p>
            <p
              onClick={() => setFetchLimit(100)}
              className={`p-2.5 cursor-pointer hover:text-gray-400 ${
                fetchLimit === 100 ? "border rounded-md bg-slate-100" : ""
              }`}
            >
              100
            </p>
          </div>
        ) : null}
      </>

      <ModalInput
        title="Tambah Gear"
        subtitle=""
        content={<GearModalContent formik={formik} />}
        open={openAdd}
        setOpen={setOpenAdd}
        hasButton={true}
        buttonText="Save"
        setTriggerFunc={setIsAdd}
        isDisable={!formik.isValid || isPendingAdd}
      />

      <ModalInput
        title="Detail Gear"
        subtitle=""
        content={<GearModalContent formik={formik} />}
        open={openDetail}
        setOpen={setOpenDetail}
        hasButton={true}
        buttonText="Save"
        setTriggerFunc={setIsEdit}
        isDisable={!formik.isValid || isPendingEdit}
      />

      <ModalInput
        title="Hapus Gear"
        subtitle="Apakah anda yakin ingin menghapus gear ini?"
        content={<></>}
        open={openDelete}
        setOpen={setOpenDelete}
        hasButton={true}
        buttonText="Yes"
        setTriggerFunc={setIsDelete}
        isDisable={false}
      />
    </div>
  );
};

export default GearManagementList;
